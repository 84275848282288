import React, {useEffect, useRef, useState} from 'react';
import styles from './css/bmt-aerospace-explanation.module.css'
import scroll from './images/scroll-left.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from "react-swipeable";
import arrowsMobile from './images/down-arrows.png'
import $ from 'jquery';
import GButton from './components/gpagebutton'
import Slider from './components/gpageslider'

const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const [loading, setLoading] = useState(false)
const [index, setIndex] = useState("1")
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', branchesF:''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const handlers = useSwipeable({
    onSwipedRight: () => handleLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
});

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {title, content, branches, logo, button, slider} = x.acf
		if(title === "BMT Additive"){
			setState({
				titleF: title,
				contentF: content,
				logoF: `/images/${logo.url.split('wp-content/')[1]}`,
				branchesF: branches,
				buttonF: button,
				sliderF: slider,
				logoFAlt: logo.alt
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

useEffect(() => {
  	var tl = gsap.timeline();
  	tl.from(`.${styles.title}`, {
        duration: 0.6,
        x: "-10vw",
        rotate: "-90deg"
    }, 0)
  	/*.from(`.${styles.fullpageNext}`, {
        duration: 0.6,
        scale: 1.6,
        transformOrigin: "left"
    }, 0)*/
  	.from(`.${styles.description}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(`.${styles.features}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
}, [loading]);

function handleBack(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-explanation');
  }

function handleKeyDown(e){
	if(e.keyCode === 37){
		e.preventDefault();
    	history.push('/bmt-aerospace-explanation');
	}
}

function handleLeft(e){
	if(e)
		e.preventDefault()
	history.push('/bmt-aerospace-explanation');
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

function createMarkup(content) {
    return {
       __html: content    
    };
 };

 useEffect(() => {
  $('#carouselIndicators').on('slid.bs.carousel', function (e) {
    let index = document.querySelector("#carouselIndicators > div > div.carousel-item.active").getAttribute('index');
    setIndex(index)
  });   
}, [loading])

return(
<>
<div {...handlers}>
<Header/>

<div style={{overflow: "hidden"}}>
<section ref={mainRef} className={styles.fullpageNext} onKeyDown={handleKeyDown} tabIndex="0">
{loading &&
<>
<div className={`container-fluid ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>
		<div className={styles.scrollLeft}>
			<img src={scroll} alt="scroll"/>
		</div>
		<div className={styles.leftBlockContainer} onClick={(e) => handleBack(e)}>
			<div className={`col-md-12 d-flex flex-row align-items-center justify-content-center ${styles.leftBlock}`}>
				<div className="">
					<div className={styles.rightBlockContent}>BMT Aerospace</div>
				</div>
			</div>
		</div>
		
		<div className={`${isDesktop ? 'col-md-6' : 'w-100 align-items-center'} d-flex flex-column ${styles.leftColumn}`}>
	      { !isDesktop &&
		      <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{marginTop: "70px"}}>
		          <div className="d-flex flex-row w-100 align-items-center">
		          	<div className="mr-auto">
			          	<a href="/#" onClick={(e) => handleLeft(e)}>
			          		<img src={arrowsMobile} style={{maxWidth: "25px", transform: "rotate(90deg)"}} alt="down arrows"/>
			          	</a>
		          	</div>
	          
		          	<div className="mr-4 pb-2 d-flex flex-row align-items-end">
	          			<div className="mr-2">
		          			<h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px", marginBottom: "0px"}}>
			            	BMT Aerospace
			          		</h1>
		          		</div>
		          	</div>
		          </div>

		          <div className="d-flex flex-row w-100 align-items-center justify-content-start">
		          	<div className="ml"><h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px"}}>
			            {state.titleF}
			          </h1></div>
		          </div>
		      </div>
		      }

	      {
	        isDesktop &&
	          <h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`}>
	            {state.titleF}
	          </h1>
	      }
			<div className={`${styles.description} mt-4 w-75`} /*style={{wordWrap: "break-word"}}*/>
				<div dangerouslySetInnerHTML={createMarkup(state.contentF)} />
			</div>

			{ !isDesktop &&
	        <div className="d-flex flex-column-reverse align-items-center">  
	    			<GButton state={state} color="purple"/>
	          <Slider state={state} index={index} isDesktop={isDesktop} color="purple" stop={true}/>
	        </div>
	    	}

			{ isDesktop &&
			<div className="d-flex flex-row mt-3">
				<div className={`${styles.features} mr-5`}>
					{ state.branchesF ? state.branchesF.map( x => 
					<h2 className={`${styles.features} mr-5`}>
						{x.branch}
					</h2>)
					: null
					}
				</div>
			</div>
			}
		</div>	
	</div>

	

	{ !isDesktop ?
    <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center`} style={{paddingTop: "60px", paddingBottom: "30px"}}>
      <div className="col" style={{padding: "0px"}}>
      { state.logoF &&
        <img src={state.logoF} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "35%"}}/>
      }
      </div>
      <div className="col" style={{padding: "0px", position: "relative"}}>
      </div>
    </div>
    :
    <div>
		{	state.logoF ?
			<img src={state.logoF} className={styles.logo} alt="bmt logo"/> : null
		}
	</div>
  	}
	
</div>
</>
}
</section>
</div>
</div>
</>
)}

export default Page;