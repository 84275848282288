import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import refs from './css/bmt-drive-solutions-references.module.css'
import Footer from './components/footer'
import arrows from './images/upArrowsBlue.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'

const Page = () => {
const mainRef = useRef(null);
const history = useTransitionHistory();
var tl = gsap.timeline();

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      main_logo: '', main_logoAlt: '', columnF: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/references.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {division_logo, column} = x.acf
		if(x.title.rendered === "OMCO References"){
			setState({
				main_logo: division_logo && division_logo.url && `/images/${division_logo.url.split('wp-content/')[1]}`,
				main_logoAlt: division_logo.alt,
				columnF: column
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleBack(e) {
    e.preventDefault();
    history.push('/bmt-omco-departaments');
}

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/bmt-omco-departaments');
	}
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

useEffect(() => {
    tl
    .from(".animateme", {
    	duration: 1,
    	x: "random(-300, 300)",
    	y: "random(-300, 300)",
    	rotate: "10deg",
    	ease: "power2"
    }, 0)
	  .from(`.${styles.title}`, {
	    	duration: 0.5,
	    	y: "30vh",
	    	ease: "power2"
	    }, 0)
	  .from(`.${styles.refLogo}`, {
	    	duration: 0.7,
	    	y: "40vh",
	    	ease: "power2"
	    }, 0)
}, [loading])


function chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
}

return(
<>

<Header/>

{ isDesktop &&
<div className={`${bc.vertical}`}> 
<span className={bc.destination}>References / </span>
<a href="/bmt-omco-departaments">Departments / </a>
<a href="/bmt-omco-products">Products / </a>
<a href="/bmt-omco-explanation">Explanation / </a>
<a href="/bmt-omco">OMCO </a>
</div>
}

<div className={`container-fluid ${styles.referencesBackground} mb-4`} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" style={{position: "relative", overflow: "hidden"}}>
{loading &&
<>
	<a href='/bmt-omco-departaments' onClick={(e) => handleBack(e)}>
  		<img src={arrows} className={styles.upArrows} style={{transform: "rotate(0deg) translateX(-50%)", height: isDesktop ? "35px" : "28px"}} alt="bmt aerospace arrows"/>
	</a>

	<div className="row" style={{paddingTop: "7.5rem", paddingBottom: "5vh", marginLeft:"auto", marginRight: "auto"}}>
		<div className="col-md-12 d-flex flex-column align-items-center justify-content-center mt-3">
			<h1 className={`${styles.title}`} style={{fontSize: isDesktop ? '1.85rem' : '2.5rem'}}>
				References
			</h1>
			{isDesktop &&
				<>	
				<div className="mt-4">
				{ state.main_logo ?
					<img alt={state.main_logoAlt} src={state.main_logo} className={`${styles.refLogo}`}/> : null
				}
				</div>
				{ state.columnF &&
					chunkArray(state.columnF.logos, 5).map((x, i) => (
						<div key={i} className={`row mt-5 d-flex flex-row justify-content-around ${styles.logosWrapper} ${styles.secondRow}`}>
						{x.map((y, ii) => 
							<div key={ii} className={`animateme col d-flex align-items-center ${styles.logoBox} ${styles.logosBranchCol}`}>
								<img className={`${styles.boxedLogo}`} src={`/images/${y.url.split('wp-content/')[1]}`} alt={y.alt}/>	
							</div>
						)
						}
						</div>	
					))
				}
			</>
			}
		</div>
	</div>

	{!isDesktop &&
	<div className="mb-4" style={{marginLeft: "10%", marginRight:"10%"}}>
		{
			state.main_logo &&
			<div className="row d-flex justify-content-center mb-4">
				<img alt={state.main_logoAlt} src={state.main_logo} className={`${styles.refLogo}`}/>
			</div>
		}

		{ state.columnF &&
			chunkArray(state.columnF.logos, 4).map((x, i) => (
				<div key={i} className="row d-flex flex-row justify-content-center align-items-center mt-3">	
					<div className="row align-items-center mt-3">
						{x.map((y, ii) => 
							<div key={ii} className={`col ${styles.brandCol}`} >
								<img src={`/images/${y.url.split('wp-content/')[1]}`} alt={y.alt} className={`${refs.logoBox} img-fluid`} style={{maxWidth: '64px', maxHeight: '64px'}}/>
							</div>	
						)}
					</div>		
				</div>
			))
		}
	</div>
	}

	{isDesktop &&
	<>
		{	state.main_logo ?
			<img src={state.main_logo} className={styles.refBottomLogo} style={{bottom: "20px"}} alt={state.main_logoAlt}/> : null
		}
		<a href="/bmt-omco-departaments" className={styles.refDownArrows} style={{bottom: "20px", marginBottom: "10px", left:"50%"}} 
			onClick={(e) => handleBack(e)}>
			<img src={arrows} alt="up arrows" style={{transform: "translateX(-50%)"}}/>		
		</a>
	</>
	}
</>
}
</div>

<Footer color="white"/>
</>

)}

export default Page;