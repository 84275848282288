import React, {useRef, useEffect, useState} from "react";
import { usePopper } from 'react-popper';
import styled from 'styled-components'
import './css/popper-style.css'
import styles from './css/subpages.module.css'
import { useMediaQuery } from 'react-responsive';
import getData from './utils/fetchdata'

const MapTooltip = styled.div`
    min-height: 100px;
    min-width: 200px;
    background-color: #c5253d;
`;

const MapTooltipText = styled.div`
    font-weight: 300;
    font-size: 1rem;
    line-height: 25px;
    letter-spacing: 0;
    color: white;
    padding: 10px;
`;

const SVGContainer = styled.div`
    width: 80vw;
`;

const Container = styled.div`
    position: absolute;
    left: calc(50% - 117px);
    top: 20%;
    z-index: 1000;
`;
const Reveal = styled.div`
    text-align: left;
    position: absolute;
    padding: 20px;
    background-color: #f9f9f9;
`;

const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
`;

function SvgBmtDriveSolutionsGlobe(props, svgref) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  const [department, setDepartment] = useState("");
  const [index, setIndex] = useState(0);
  const [showPopper, setShowPopper] = useState(false);
  const [showInfo, setShowInfo] = useState(false)
  const [arrowElement, setArrowElement] = useState(null);
  const [state, setState] = useState({})

  const referenceRef1 = useRef(null);
  const popperRef1 = useRef(null);
  const referenceRef11 = useRef(null);
  const popperRef11 = useRef(null);
  const referenceRef2 = useRef(null);
  const popperRef2 = useRef(null);
  const referenceRef3 = useRef(null);
  const popperRef3 = useRef(null);
  const referenceRef33 = useRef(null);
  const popperRef33 = useRef(null);
  const referenceRef4 = useRef(null);
  const popperRef4 = useRef(null);
  const referenceRef5 = useRef(null);
  const popperRef5 = useRef(null);
  const referenceRef55 = useRef(null);
  const popperRef55 = useRef(null);
  const referenceRef6 = useRef(null);
  const popperRef6 = useRef(null);
  const referenceRef7 = useRef(null);
  const popperRef7 = useRef(null);
  const referenceRef8 = useRef(null);
  const popperRef8 = useRef(null);
  const referenceRef30 = useRef(null);
    const popperRef30 = useRef(null);

  const offset = 10;

  const popInstance1 = usePopper(referenceRef1.current, popperRef1.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance11 = usePopper(referenceRef11.current, popperRef11.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance2 = usePopper(referenceRef2.current, popperRef2.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance3 = usePopper(referenceRef3.current, popperRef3.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance33 = usePopper(referenceRef33.current, popperRef33.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance4 = usePopper(referenceRef4.current, popperRef4.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance5 = usePopper(referenceRef5.current, popperRef5.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance55 = usePopper(referenceRef55.current, popperRef55.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});  
  const popInstance6 = usePopper(referenceRef6.current, popperRef6.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance7 = usePopper(referenceRef7.current, popperRef7.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance8 = usePopper(referenceRef8.current, popperRef8.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});
  const popInstance30 = usePopper(referenceRef30.current, popperRef30.current, { placement: "bottom", strategy: "fixed", modifiers: [{name: "offset",enabled: true,options: {offset: [0, offset]}},{ name: 'arrow', options: { element: arrowElement } }]});

  function handleMouseEnter(department, index){
    if(isDesktop){
      setDepartment(department);
      setIndex(index);  
      setShowPopper(true);
    }
  }

  function handleMouseOut()
  {
    if(isDesktop){
      setShowPopper(false);
      setIndex(0);
    }
  }

  function handleClose(){
    setShowPopper(!showPopper)
    setShowInfo(!showInfo)
  }

  function handleClick(department, index)
  { 
    if(!isDesktop){
      //e.preventDefault();
      setDepartment(department);
      setIndex(index);  
      setShowPopper(!showPopper);
      setShowInfo(!showInfo)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
      .then(data => {
        
        if(Array.isArray(data) && data.length)
        {
          let obj = {}
          data.forEach(x => 
          {
            const {title, department} = x.acf
            if(title === "VCST"){
              obj.title = title
              obj.department = department
              console.log('x', x)
            }
          })
          return obj
        }
      })

      setState({...resp1})
    }
    fetchData();
}, []);

  function createDotInfo(country)
  {
    console.log(state.department);
    let t = {...state.department.find(el => el.country === country)}

    let entries = []
    entries.push(t.company_name)
    entries.push(t.address)
    entries.push(t.postcode)
    entries.push(t.city)
    if(t.phones && t.phones.length > 1)
    {
      t.phones.forEach((el) => entries.push(el.phone))
    }
    else if(t.phones && t.phones.length === 1)
      entries.push(t.phones[0]["phone"])

    entries.push(t.email)
    return entries
  }

  function displayTooltip(){
    let entries = [];

    if(department === "Belgium"){
      //entries = ["Surface Treatment Company BV","Nijverheidslaan 5420","3800 Sint-Truiden", "Belgium - Sint-Truiden", "T + 32 (0) 11 67 02 96", "sales@vcst.com"]
      entries = createDotInfo("Belgium - Sint-Truiden")
    }
    else if(department === "Belgium2"){
      //entries = ["VCST Industrial Products BV","Rellestraat 5030", "3800 Sint-Truiden", "Belgium - Sint-Truiden", "T + 32 (0) 11 67 02 11", "sales@vcst.com"]
      entries = createDotInfo("Belgium - Sint-Truiden 2")
    }
    else if(department === "Germany"){
      //entries = ["VCST Reichenbach GmbH", "Am Fernblick 15", "08499 Mylau", "Germany - Reichenbach", "T + 49 (0) 37 65 795 – 0", "sales@vcst.com"]
      entries = createDotInfo("Germany - Reichenbach")
    }
    else if(department === "Romania"){
      //entries = ["SC VCST Automotive Production Alba SRL", "No. 9, Calea Ciugudului Street", "517240 Alba County, Alba Iulia", "Romania - Alba", "T +40 372 133 700", "officealba@vcst.be"]
      entries = createDotInfo("Romania - Alba")    
    }
    /*else if(department === "Romania2"){
      entries = ["IG Watteeuw Romania S.R.L.", "B-dul Chimiei nr. 6 Area B", "700291 Iasi", "Romania - Iasi", "T +40 374 100 235", "info@bmtdrivesolutions.com"]
    }*/
    else if(department === "Croatia"){
      entries = createDotInfo("Croatia")
    }
    else if(department === "China"){
      //entries = ["VCST Automotive Components (Changzhou) Co, Ltd.", "8 Fushan Road", "Wujin Economic Zone", "Changzhou, 213149", "China - Changzhou", "T +86 519 8169 0996", "vcst.china@vcst.com"]
      entries = createDotInfo("China")
    }
    /*else if(department === "China2"){
      entries = ["IGW (Suzhou) Co., Ltd", "N° 1 Fenghe Road Fengting Avenue, SIP", "Suzhou 215122. P.R.", "China - Suzhou", "T +86 512 6287 5601", "info@bmtdrivesolutions.com"]
    }*/
    else if(department === "USA"){
      //entries = ["IG Watteeuw USA LLC","1000 Linden Avenue, Zanesville","Ohio 43701 – 3098", "USA - Zanesville", "T +1 740 588 1722", "info@bmtdrivesolutions.com"]
      entries = createDotInfo("USA")
    }
    else if(department === "Mexico"){
      //entries = ["VCST De Mexico S De R.L De C.V.","Boulevard Aeropuerto No. 3202 Loc. A", "San Juan de Otates", "C.P. 37670, León, Gto.", "México - Leon", "T +52 477 152 6100", "info@vcst.com.mx"]
      entries = createDotInfo("Mexico")
    }

    let popInstance, popperRef;

    if(index === 1){
      popperRef = popperRef1;
      popInstance = popInstance1;
    }
    else if(index === 11){
      popperRef = popperRef11;
      popInstance = popInstance11;
    }
    else if(index === 2){
      popperRef = popperRef2;
      popInstance = popInstance2;
    }
    else if(index === 3){
      popperRef = popperRef3;
      popInstance = popInstance3;
    }
    else if(index === 33){
      popperRef = popperRef33;
      popInstance = popInstance33;
    }
    else if(index === 4){
      popperRef = popperRef4;
      popInstance = popInstance4;
    }
    else if(index === 5){
      popperRef = popperRef5;
      popInstance = popInstance5;
    }
    else if(index === 55){
      popperRef = popperRef55;
      popInstance = popInstance55;
    }
    else if(index === 6){
      popperRef = popperRef6;
      popInstance = popInstance6;
    }
    else if(index === 7){
      popperRef = popperRef7;
      popInstance = popInstance7;
    }
    else if(index === 8){
      popperRef = popperRef8;
      popInstance = popInstance8;
    }
    else if(index === 30){
        popperRef = popperRef30;
        popInstance = popInstance30;
    }

    return (
    <>
    { !isDesktop &&
      <Container>
        <Reveal>
        <Close onClick={handleClose}>X</Close>
        <MapTooltip className="d-flex flex-column text-center justify-content-center align-self-center">
          <MapTooltipText>
              {entries.map( (x, i) => 
                <div className={i === 0 ? styles.tooltipTitle : null} key={i}>{x}</div>
              )
            }
          </MapTooltipText>
        </MapTooltip>
        </Reveal>
      </Container>
    }  

    {isDesktop && popInstance &&  
    <div ref={popperRef} style={popInstance.styles.popper} {...popInstance.attributes.popper}>
          <div ref={setArrowElement} />   
          <MapTooltip className="d-flex flex-column text-center justify-content-center align-self-center">
              <MapTooltipText>
                {entries.map( (x, i) => 
                  <div className={i === 0 ? styles.tooltipTitle : null} key={i}>{x}</div>
                )
              }
              </MapTooltipText>
            </MapTooltip>
        </div>
    }
    </>
    )
  }

  return (
  <>  
  <SVGContainer>
    <svg
      viewBox="0 0 1094 434.289"
      ref={svgref}
      {...props}
    >
      <g data-name="Group 150">
        <g data-name="Group 44">
          <g data-name="Group 43">
            <g data-name="Group 42" fill="#fdfafa">
              <path
                  data-name="Path 120"
                  d="M274.626 431.936c-.027 1.061 1.208 1.826 2.752 1.72s2.811-1.044 2.836-2.1-1.2-1.838-2.744-1.731-2.817 1.053-2.844 2.111z"
              />
              <path
                  data-name="Path 121"
                  d="M265.729 432.562c-.032 1.061 1.2 1.826 2.744 1.716s2.816-1.051 2.844-2.111-1.195-1.838-2.736-1.73-2.82 1.064-2.852 2.125z"
              />
              <path
                  data-name="Path 122"
                  d="M310.328 423.598c-.027 1.075 1.207 1.863 2.747 1.771s2.807-1.033 2.832-2.106-1.2-1.875-2.741-1.783-2.811 1.045-2.838 2.118z"
              />
              <path
                  data-name="Path 123"
                  d="M265.956 426.507c-.05 1.081 1.165 1.863 2.705 1.755s2.819-1.064 2.864-2.144-1.16-1.877-2.7-1.768-2.822 1.076-2.869 2.157z"
              />
              <path
                  data-name="Path 124"
                  d="M257.089 427.147c-.053 1.081 1.156 1.863 2.7 1.751s2.822-1.071 2.873-2.154-1.15-1.873-2.685-1.763-2.835 1.08-2.888 2.166z"
              />
              <path
                  data-name="Path 125"
                  d="M275.116 419.734c-.06 1.1 1.141 1.9 2.675 1.793s2.822-1.069 2.879-2.167-1.136-1.91-2.669-1.806-2.827 1.081-2.885 2.18z"
              />
              <path
                  data-name="Path 126"
                  d="M266.274 420.345c-.065 1.1 1.131 1.9 2.667 1.791s2.824-1.078 2.886-2.177-1.126-1.91-2.659-1.8-2.829 1.086-2.894 2.186z"
              />
              <path
                  data-name="Path 127"
                  d="M257.435 420.972c-.07 1.1 1.121 1.9 2.655 1.79s2.827-1.086 2.894-2.186-1.115-1.91-2.645-1.8-2.832 1.096-2.904 2.196z"
              />
              <path
                  data-name="Path 128"
                  d="M355.034 408.831c-.037 1.108 1.185 1.938 2.722 1.865s2.811-1.028 2.842-2.136-1.18-1.953-2.716-1.878-2.811 1.039-2.848 2.149z"
              />
              <path
                  data-name="Path 129"
                  d="M346.178 409.279c-.04 1.11 1.178 1.937 2.714 1.86s2.811-1.034 2.847-2.142-1.173-1.952-2.707-1.873-2.815 1.044-2.854 2.155z"
              />
              <path
                  data-name="Path 130"
                  d="M337.324 409.741c-.043 1.111 1.17 1.938 2.707 1.857s2.811-1.041 2.853-2.149-1.166-1.95-2.7-1.87-2.816 1.052-2.86 2.162z"
              />
              <path
                  data-name="Path 131"
                  d="M284.313 412.888c-.07 1.115 1.118 1.933 2.65 1.833s2.822-1.076 2.889-2.192-1.113-1.943-2.642-1.847-2.827 1.09-2.897 2.206z"
              />
              <path
                  data-name="Path 132"
                  d="M275.496 413.473c-.075 1.116 1.11 1.932 2.639 1.83s2.826-1.085 2.9-2.2-1.1-1.943-2.629-1.843-2.834 1.097-2.91 2.213z"
              />
              <path
                  data-name="Path 133"
                  d="M266.687 414.079c-.08 1.118 1.1 1.933 2.627 1.826s2.829-1.09 2.906-2.207-1.091-1.943-2.619-1.838-2.833 1.099-2.914 2.219z"
              />
              <path
                  data-name="Path 134"
                  d="M257.884 414.696c-.089 1.118 1.085 1.932 2.614 1.823s2.832-1.1 2.914-2.214-1.081-1.943-2.605-1.836-2.836 1.108-2.923 2.227z"
              />
              <path
                  data-name="Path 135"
                  d="M346.437 402.954c-.053 1.128 1.153 1.972 2.685 1.9s2.814-1.048 2.864-2.174-1.148-1.985-2.68-1.908-2.817 1.055-2.869 2.182z"
              />
              <path
                  data-name="Path 136"
                  d="M337.607 403.413c-.057 1.128 1.143 1.972 2.675 1.892s2.816-1.054 2.869-2.182-1.14-1.984-2.669-1.9-2.82 1.062-2.875 2.19z"
              />
              <path
                  data-name="Path 137"
                  d="M328.786 403.889c-.062 1.13 1.135 1.97 2.667 1.888s2.817-1.061 2.874-2.189-1.13-1.982-2.659-1.9-2.826 1.074-2.882 2.201z"
              />
              <path
                  data-name="Path 138"
                  d="M284.755 406.531c-.087 1.133 1.085 1.965 2.612 1.868s2.827-1.091 2.909-2.224-1.081-1.977-2.605-1.878-2.831 1.101-2.916 2.234z"
              />
              <path
                  data-name="Path 139"
                  d="M275.97 407.112c-.092 1.133 1.074 1.965 2.6 1.863s2.829-1.1 2.916-2.229-1.069-1.977-2.592-1.877-2.832 1.104-2.924 2.243z"
              />
              <path
                  data-name="Path 140"
                  d="M267.191 407.709c-.1 1.135 1.064 1.963 2.589 1.86s2.831-1.1 2.924-2.238-1.059-1.975-2.58-1.872-2.834 1.112-2.933 2.25z"
              />
              <path
                  data-name="Path 141"
                  d="M258.422 408.321c-.1 1.136 1.051 1.964 2.573 1.857s2.834-1.108 2.934-2.244-1.048-1.977-2.567-1.87-2.833 1.121-2.94 2.257z"
              />
              <path
                  data-name="Path 142"
                  d="M285.283 400.076c-.1 1.151 1.053 2 2.575 1.9s2.829-1.1 2.926-2.253-1.051-2.009-2.568-1.912-2.833 1.116-2.933 2.265z"
              />
              <path
                  data-name="Path 143"
                  d="M276.53 400.651c-.107 1.15 1.043 2 2.562 1.9s2.832-1.11 2.934-2.259-1.038-2.009-2.553-1.908-2.835 1.114-2.943 2.267z"
              />
              <path
                  data-name="Path 144"
                  d="M267.789 401.241c-.114 1.151 1.029 2 2.548 1.893s2.833-1.115 2.943-2.266-1.028-2.009-2.54-1.905-2.842 1.127-2.951 2.278z"
              />
              <path
                  data-name="Path 145"
                  d="M259.051 401.848c-.119 1.153 1.016 2 2.533 1.89s2.836-1.12 2.951-2.273-1.013-2.009-2.525-1.9a3.069 3.069 0 00-2.959 2.283z"
              />
              <path
                  data-name="Path 146"
                  d="M1078.13 412.289a4.434 4.434 0 003.412 2.517c1.429.162 2.211-.705 1.741-1.937a4.481 4.481 0 00-3.417-2.523c-1.419-.159-2.193.713-1.736 1.943z"
              />
              <path
                  data-name="Path 147"
                  d="M1069.907 411.378a4.365 4.365 0 003.392 2.507c1.432.159 2.226-.707 1.775-1.935a4.4 4.4 0 00-3.4-2.513c-1.419-.158-2.214.715-1.767 1.941z"
              />
              <path
                  data-name="Path 148"
                  d="M1061.664 410.481a4.3 4.3 0 003.37 2.5c1.44.157 2.248-.709 1.81-1.933a4.332 4.332 0 00-3.377-2.5c-1.425-.163-2.229.708-1.803 1.933z"
              />
              <path
                  data-name="Path 149"
                  d="M1053.399 409.595a4.224 4.224 0 003.352 2.487c1.439.155 2.264-.707 1.841-1.932a4.265 4.265 0 00-3.355-2.495c-1.43-.152-2.251.719-1.838 1.94z"
              />
              <path
                  data-name="Path 150"
                  d="M1045.113 408.722a4.162 4.162 0 003.332 2.478c1.442.152 2.286-.71 1.875-1.932a4.2 4.2 0 00-3.339-2.485c-1.432-.145-2.268.721-1.868 1.939z"
              />
              <path
                  data-name="Path 151"
                  d="M338.39 390.458c-.082 1.163 1.09 2.035 2.614 1.957s2.821-1.081 2.9-2.244-1.086-2.047-2.607-1.969-2.825 1.093-2.907 2.256z"
              />
              <path
                  data-name="Path 152"
                  d="M294.621 392.973c-.109 1.166 1.034 2.03 2.552 1.937a2.986 2.986 0 002.934-2.276c.105-1.165-1.031-2.04-2.543-1.947a3.015 3.015 0 00-2.943 2.286z"
              />
              <path
                  data-name="Path 153"
                  d="M285.894 393.526c-.115 1.168 1.023 2.029 2.538 1.932a3.01 3.01 0 002.943-2.281c.11-1.166-1.019-2.039-2.532-1.944a3.035 3.035 0 00-2.949 2.293z"
              />
              <path
                  data-name="Path 154"
                  d="M277.178 394.095c-.124 1.166 1.008 2.027 2.523 1.928a3.035 3.035 0 002.949-2.288c.119-1.168-1.006-2.039-2.515-1.94a3.06 3.06 0 00-2.957 2.3z"
              />
              <path
                  data-name="Path 155"
                  d="M268.47 394.68c-.129 1.17 1 2.027 2.508 1.925a3.064 3.064 0 002.959-2.294c.124-1.168-.993-2.039-2.5-1.937a3.089 3.089 0 00-2.967 2.306z"
              />
              <path
                  data-name="Path 156"
                  d="M259.77 395.282c-.135 1.17.983 2.027 2.493 1.922a3.086 3.086 0 002.968-2.3c.132-1.168-.979-2.037-2.485-1.932a3.114 3.114 0 00-2.976 2.31z"
              />
              <path
                  data-name="Path 157"
                  d="M1083.611 406.212a4.545 4.545 0 003.432 2.537c1.409.159 2.152-.715 1.651-1.962a4.588 4.588 0 00-3.437-2.543c-1.399-.159-2.134.725-1.646 1.968z"
              />
              <path
                  data-name="Path 158"
                  d="M1075.497 405.305a4.477 4.477 0 003.412 2.528c1.415.157 2.171-.719 1.688-1.96a4.511 4.511 0 00-3.419-2.533c-1.404-.157-2.152.726-1.681 1.965z"
              />
              <path
                  data-name="Path 159"
                  d="M1067.357 404.411a4.4 4.4 0 003.394 2.518c1.417.155 2.189-.719 1.721-1.958a4.447 4.447 0 00-3.4-2.525c-1.407-.15-2.171.729-1.715 1.965z"
              />
              <path
                  data-name="Path 160"
                  d="M1059.198 403.528a4.332 4.332 0 003.372 2.51c1.422.154 2.211-.722 1.756-1.957a4.372 4.372 0 00-3.381-2.517c-1.409-.151-2.191.729-1.747 1.964z"
              />
              <path
                  data-name="Path 161"
                  d="M1051.012 402.66a4.267 4.267 0 003.356 2.5c1.427.152 2.228-.722 1.788-1.955a4.31 4.31 0 00-3.362-2.508c-1.419-.151-2.209.732-1.782 1.963z"
              />
              <path
                  data-name="Path 162"
                  d="M1042.806 401.802a4.2 4.2 0 003.337 2.492c1.429.149 2.246-.724 1.82-1.955a4.247 4.247 0 00-3.344-2.5c-1.417-.149-2.229.733-1.813 1.963z"
              />
              <path
                  data-name="Path 163"
                  d="M1034.58 400.957a4.139 4.139 0 003.319 2.485c1.434.147 2.264-.727 1.852-1.955a4.184 4.184 0 00-3.325-2.492c-1.428-.145-2.253.736-1.846 1.962z"
              />
              <path
                  data-name="Path 164"
                  d="M303.983 385.802c-.119 1.181 1.016 2.06 2.528 1.97a3 3 0 002.941-2.3c.114-1.181-1.014-2.07-2.522-1.982a3.028 3.028 0 00-2.947 2.312z"
              />
              <path
                  data-name="Path 165"
                  d="M295.281 386.335c-.124 1.183 1 2.059 2.515 1.967a3.028 3.028 0 002.949-2.3c.12-1.18-1-2.069-2.507-1.977a3.057 3.057 0 00-2.957 2.31z"
              />
              <path
                  data-name="Path 166"
                  d="M286.589 386.883c-.13 1.183.991 2.06 2.5 1.964a3.054 3.054 0 002.958-2.309c.127-1.181-.988-2.069-2.493-1.974a3.081 3.081 0 00-2.965 2.319z"
              />
              <path
                  data-name="Path 167"
                  d="M277.91 387.45c-.139 1.183.978 2.057 2.485 1.958a3.082 3.082 0 002.966-2.316c.132-1.183-.976-2.067-2.478-1.968a3.1 3.1 0 00-2.973 2.326z"
              />
              <path
                  data-name="Path 168"
                  d="M269.24 388.031c-.145 1.183.964 2.055 2.468 1.953a3.1 3.1 0 002.974-2.321c.139-1.185-.961-2.065-2.462-1.965a3.129 3.129 0 00-2.98 2.333z"
              />
              <path
                  data-name="Path 169"
                  d="M260.581 388.628c-.15 1.185.949 2.055 2.453 1.952a3.134 3.134 0 002.985-2.329c.145-1.183-.947-2.065-2.446-1.962a3.164 3.164 0 00-2.992 2.339z"
              />
              <path
                  data-name="Path 170"
                  d="M1088.811 400.062a4.65 4.65 0 003.451 2.555c1.39.159 2.09-.729 1.562-1.984a4.694 4.694 0 00-3.456-2.562c-1.378-.156-2.075.736-1.557 1.991z"
              />
              <path
                  data-name="Path 171"
                  d="M1080.808 399.161a4.581 4.581 0 003.431 2.547c1.4.157 2.114-.729 1.6-1.982a4.616 4.616 0 00-3.437-2.553c-1.385-.156-2.097.734-1.594 1.988z"
              />
              <path
                  data-name="Path 172"
                  d="M1072.779 398.268a4.5 4.5 0 003.412 2.54c1.4.155 2.132-.73 1.634-1.98a4.56 4.56 0 00-3.419-2.547c-1.386-.154-2.115.739-1.627 1.987z"
              />
              <path
                  data-name="Path 173"
                  d="M1064.724 397.39a4.439 4.439 0 003.392 2.532c1.4.154 2.152-.732 1.669-1.98a4.486 4.486 0 00-3.4-2.538c-1.392-.152-2.136.739-1.661 1.986z"
              />
              <path
                  data-name="Path 174"
                  d="M1056.643 396.519a4.37 4.37 0 003.376 2.523c1.409.15 2.172-.735 1.7-1.98a4.417 4.417 0 00-3.382-2.53c-1.395-.146-2.154.744-1.694 1.987z"
              />
              <path
                  data-name="Path 175"
                  d="M1048.538 395.666a4.31 4.31 0 003.357 2.515c1.412.149 2.191-.737 1.736-1.979a4.352 4.352 0 00-3.366-2.523c-1.403-.147-2.172.746-1.727 1.987z"
              />
              <path
                  data-name="Path 176"
                  d="M1040.408 394.822a4.239 4.239 0 003.34 2.507c1.414.145 2.211-.737 1.768-1.979a4.288 4.288 0 00-3.349-2.513c-1.403-.146-2.192.745-1.759 1.985z"
              />
              <path
                  data-name="Path 177"
                  d="M1032.254 393.99a4.18 4.18 0 003.325 2.5c1.419.145 2.228-.739 1.8-1.977a4.223 4.223 0 00-3.335-2.509c-1.406-.143-2.208.75-1.79 1.986z"
              />
              <path
                  data-name="Path 178"
                  d="M966.21 387.798a3.766 3.766 0 003.205 2.44c1.449.127 2.361-.76 2.029-1.985a3.8 3.8 0 00-3.214-2.446c-1.44-.127-2.344.767-2.02 1.991z"
              />
              <path
                  data-name="Path 179"
                  d="M313.363 378.564c-.125 1.2 1 2.09 2.508 2a3.017 3.017 0 002.946-2.318c.12-1.195-1-2.1-2.5-2.014a3.04 3.04 0 00-2.954 2.332z"
              />
              <path
                  data-name="Path 180"
                  d="M304.687 379.079c-.132 1.2.986 2.089 2.493 2a3.04 3.04 0 002.954-2.323c.127-1.2-.984-2.1-2.487-2.01a3.067 3.067 0 00-2.96 2.333z"
              />
              <path
                  data-name="Path 181"
                  d="M296.021 379.609c-.139 1.2.974 2.089 2.478 2a3.069 3.069 0 002.963-2.329c.134-1.2-.969-2.1-2.47-2.007a3.1 3.1 0 00-2.971 2.336z"
              />
              <path
                  data-name="Path 182"
                  d="M287.368 380.154c-.145 1.2.959 2.087 2.462 1.992a3.094 3.094 0 002.973-2.34c.139-1.2-.958-2.1-2.456-2a3.12 3.12 0 00-2.979 2.348z"
              />
              <path
                  data-name="Path 183"
                  d="M278.726 380.717c-.152 1.2.946 2.086 2.446 1.987a3.118 3.118 0 002.98-2.343c.147-1.2-.942-2.1-2.438-2a3.146 3.146 0 00-2.988 2.356z"
              />
              <path
                  data-name="Path 184"
                  d="M270.096 381.293c-.16 1.2.931 2.084 2.43 1.984a3.146 3.146 0 002.988-2.348c.154-1.2-.927-2.094-2.421-1.994a3.174 3.174 0 00-2.997 2.358z"
              />
              <path
                  data-name="Path 185"
                  d="M261.478 381.887c-.167 1.2.916 2.084 2.411 1.98a3.181 3.181 0 003-2.354c.16-1.2-.914-2.092-2.406-1.99a3.2 3.2 0 00-3.005 2.364z"
              />
              <path
                  data-name="Path 186"
                  d="M1085.845 392.946a4.686 4.686 0 003.447 2.567c1.374.157 2.054-.739 1.511-2a4.73 4.73 0 00-3.453-2.572c-1.365-.159-2.035.743-1.505 2.005z"
              />
              <path
                  data-name="Path 187"
                  d="M1077.925 392.058a4.611 4.611 0 003.429 2.558c1.382.155 2.074-.74 1.547-2a4.667 4.667 0 00-3.436-2.565c-1.368-.153-2.056.747-1.54 2.007z"
              />
              <path
                  data-name="Path 188"
                  d="M1069.978 391.181a4.548 4.548 0 003.412 2.552c1.385.152 2.094-.744 1.583-2a4.591 4.591 0 00-3.417-2.557c-1.374-.154-2.083.746-1.578 2.005z"
              />
              <path
                  data-name="Path 189"
                  d="M1062.003 390.319a4.48 4.48 0 003.4 2.543c1.389.15 2.112-.744 1.616-2a4.518 4.518 0 00-3.4-2.55c-1.384-.152-2.102.747-1.616 2.007z"
              />
              <path
                  data-name="Path 190"
                  d="M1054.003 389.46a4.415 4.415 0 003.379 2.537c1.392.149 2.134-.745 1.649-2a4.454 4.454 0 00-3.384-2.543c-1.382-.148-2.117.753-1.644 2.006z"
              />
              <path
                  data-name="Path 191"
                  d="M1045.974 388.617a4.346 4.346 0 003.362 2.528c1.4.147 2.152-.747 1.683-2a4.386 4.386 0 00-3.367-2.535c-1.387-.144-2.137.755-1.678 2.007z"
              />
              <path
                  data-name="Path 192"
                  d="M1037.922 387.784a4.283 4.283 0 003.346 2.522c1.4.145 2.171-.749 1.714-2a4.322 4.322 0 00-3.35-2.527c-1.395-.144-2.158.755-1.71 2.005z"
              />
              <path
                  data-name="Path 193"
                  d="M1029.84 386.965a4.231 4.231 0 003.33 2.513c1.405.142 2.191-.752 1.748-2a4.264 4.264 0 00-3.337-2.52c-1.397-.142-2.174.759-1.741 2.007z"
              />
              <path
                  data-name="Path 194"
                  d="M1021.735 386.157a4.172 4.172 0 003.315 2.507c1.41.14 2.209-.754 1.778-2a4.206 4.206 0 00-3.32-2.513c-1.402-.14-2.189.759-1.773 2.006z"
              />
              <path
                  data-name="Path 195"
                  d="M1013.606 385.36a4.113 4.113 0 003.3 2.5c1.415.139 2.228-.755 1.81-2a4.156 4.156 0 00-3.307-2.507c-1.407-.137-2.211.762-1.803 2.007z"
              />
              <path
                  data-name="Path 196"
                  d="M997.275 383.804a4 4 0 003.27 2.485c1.424.134 2.263-.76 1.87-2a4.047 4.047 0 00-3.279-2.493c-1.417-.135-2.246.767-1.861 2.008z"
              />
              <path
                  data-name="Path 197"
                  d="M989.072 383.046a3.958 3.958 0 003.257 2.478c1.429.132 2.279-.764 1.9-2a4 4 0 00-3.265-2.487c-1.417-.138-2.264.769-1.892 2.009z"
              />
              <path
                  data-name="Path 198"
                  d="M980.849 382.3a3.908 3.908 0 003.242 2.472c1.432.129 2.3-.767 1.927-2.005a3.946 3.946 0 00-3.25-2.48c-1.422-.128-2.281.776-1.919 2.013z"
              />
              <path
                  data-name="Path 199"
                  d="M972.602 381.567a3.858 3.858 0 003.23 2.465c1.434.127 2.309-.769 1.952-2.007a3.894 3.894 0 00-3.237-2.471c-1.425-.127-2.296.777-1.945 2.013z"
              />
              <path
                  data-name="Path 200"
                  d="M964.334 380.847a3.808 3.808 0 003.215 2.458c1.439.127 2.328-.772 1.98-2.007a3.85 3.85 0 00-3.224-2.467c-1.428-.123-2.31.784-1.971 2.016z"
              />
              <path
                  data-name="Path 201"
                  d="M322.756 371.264c-.132 1.212.984 2.119 2.488 2.035a3.03 3.03 0 002.949-2.338c.127-1.212-.983-2.129-2.482-2.045a3.053 3.053 0 00-2.955 2.348z"
              />
              <path
                  data-name="Path 202"
                  d="M314.105 371.758c-.139 1.212.971 2.119 2.473 2.032a3.059 3.059 0 002.959-2.345c.134-1.211-.969-2.127-2.468-2.04a3.079 3.079 0 00-2.964 2.353z"
              />
              <path
                  data-name="Path 203"
                  d="M305.467 372.272c-.145 1.212.956 2.116 2.457 2.027a3.084 3.084 0 002.968-2.351c.139-1.212-.956-2.126-2.451-2.037a3.1 3.1 0 00-2.974 2.361z"
              />
              <path
                  data-name="Path 204"
                  d="M296.84 372.798c-.152 1.213.944 2.116 2.441 2.024a3.11 3.11 0 002.976-2.356c.147-1.212-.942-2.126-2.435-2.034a3.134 3.134 0 00-2.982 2.366z"
              />
              <path
                  data-name="Path 205"
                  d="M288.225 373.341c-.159 1.212.927 2.114 2.425 2.019a3.134 3.134 0 002.985-2.361c.154-1.212-.927-2.124-2.418-2.029a3.158 3.158 0 00-2.992 2.371z"
              />
              <path
                  data-name="Path 206"
                  d="M279.623 373.899c-.165 1.213.914 2.114 2.408 2.015a3.159 3.159 0 002.993-2.368c.162-1.212-.911-2.121-2.4-2.024a3.188 3.188 0 00-3.001 2.377z"
              />
              <path
                  data-name="Path 207"
                  d="M271.035 374.474c-.174 1.215.9 2.111 2.39 2.01a3.188 3.188 0 003-2.373c.169-1.211-.9-2.121-2.383-2.02a3.216 3.216 0 00-3.007 2.383z"
              />
              <path
                  data-name="Path 208"
                  d="M1082.8 385.786a4.721 4.721 0 003.446 2.577c1.36.154 2.017-.749 1.462-2.022a4.77 4.77 0 00-3.451-2.582c-1.347-.152-2.001.757-1.457 2.027z"
              />
              <path
                  data-name="Path 209"
                  d="M1074.967 384.91a4.647 4.647 0 003.429 2.57c1.367.154 2.037-.752 1.5-2.02a4.694 4.694 0 00-3.434-2.575c-1.363-.151-2.028.756-1.495 2.025z"
              />
              <path
                  data-name="Path 210"
                  d="M1067.099 384.045a4.58 4.58 0 003.412 2.562c1.369.149 2.055-.752 1.531-2.02a4.633 4.633 0 00-3.417-2.568c-1.361-.149-2.044.76-1.526 2.026z"
              />
              <path
                  data-name="Path 211"
                  d="M1059.202 383.19a4.515 4.515 0 003.4 2.557c1.377.149 2.079-.754 1.566-2.02a4.555 4.555 0 00-3.4-2.562c-1.37-.148-2.067.761-1.566 2.025z"
              />
              <path
                  data-name="Path 212"
                  d="M1051.279 382.348a4.453 4.453 0 003.38 2.548c1.382.147 2.1-.757 1.6-2.02a4.5 4.5 0 00-3.386-2.555c-1.37-.145-2.082.764-1.594 2.027z"
              />
              <path
                  data-name="Path 213"
                  d="M1043.327 381.514a4.385 4.385 0 003.364 2.542c1.387.145 2.117-.757 1.633-2.02a4.43 4.43 0 00-3.37-2.547c-1.377-.144-2.101.765-1.627 2.025z"
              />
              <path
                  data-name="Path 214"
                  d="M1035.348 380.694a4.323 4.323 0 003.349 2.533c1.389.144 2.137-.759 1.666-2.019a4.373 4.373 0 00-3.355-2.542c-1.381-.141-2.123.768-1.66 2.028z"
              />
              <path
                  data-name="Path 215"
                  d="M1027.341 379.883a4.273 4.273 0 003.335 2.528c1.394.14 2.156-.762 1.7-2.022a4.313 4.313 0 00-3.341-2.535c-1.388-.138-2.142.768-1.694 2.029z"
              />
              <path
                  data-name="Path 216"
                  d="M1019.307 379.084a4.214 4.214 0 003.32 2.522c1.4.139 2.174-.765 1.728-2.022a4.256 4.256 0 00-3.325-2.528c-1.39-.139-2.161.773-1.723 2.028z"
              />
              <path
                  data-name="Path 217"
                  d="M1011.248 378.297a4.158 4.158 0 003.305 2.515c1.4.137 2.192-.767 1.76-2.022a4.193 4.193 0 00-3.312-2.522c-1.395-.135-2.18.774-1.753 2.029z"
              />
              <path
                  data-name="Path 218"
                  d="M1003.162 377.522a4.105 4.105 0 003.292 2.508c1.409.135 2.212-.769 1.79-2.022a4.143 4.143 0 00-3.3-2.517c-1.397-.133-2.194.782-1.782 2.031z"
              />
              <path
                  data-name="Path 219"
                  d="M995.052 376.76a4.049 4.049 0 003.279 2.5c1.412.132 2.228-.772 1.818-2.024a4.086 4.086 0 00-3.285-2.51c-1.403-.13-2.211.782-1.812 2.034z"
              />
              <path
                  data-name="Path 220"
                  d="M986.917 376.01a4 4 0 003.264 2.495c1.417.13 2.248-.775 1.848-2.025a4.037 4.037 0 00-3.272-2.5c-1.407-.134-2.23.78-1.84 2.03z"
              />
              <path
                  data-name="Path 221"
                  d="M978.758 375.268a3.952 3.952 0 003.252 2.49c1.419.127 2.261-.779 1.875-2.027a3.986 3.986 0 00-3.259-2.5c-1.412-.121-2.246.791-1.868 2.037z"
              />
              <path
                  data-name="Path 222"
                  d="M970.574 374.546a3.9 3.9 0 003.237 2.483c1.425.125 2.278-.78 1.9-2.029a3.943 3.943 0 00-3.245-2.492c-1.41-.123-2.259.791-1.892 2.038z"
              />
              <path
                  data-name="Path 223"
                  d="M962.366 373.834a3.864 3.864 0 003.227 2.477c1.425.122 2.294-.784 1.93-2.03a3.891 3.891 0 00-3.232-2.485c-1.422-.123-2.279.794-1.925 2.038z"
              />
              <path
                  data-name="Path 224"
                  d="M611.608 357.394a2.671 2.671 0 002.837 2.236 2.382 2.382 0 002.662-2.191 2.687 2.687 0 00-2.839-2.246 2.392 2.392 0 00-2.66 2.201z"
              />
              <path
                  data-name="Path 225"
                  d="M332.156 363.901c-.137 1.223.969 2.147 2.47 2.065a3.039 3.039 0 002.951-2.358c.132-1.225-.969-2.157-2.465-2.077a3.067 3.067 0 00-2.956 2.37z"
              />
              <path
                  data-name="Path 226"
                  d="M323.532 364.377c-.142 1.227.958 2.147 2.455 2.064a3.069 3.069 0 002.961-2.365c.139-1.225-.956-2.156-2.45-2.072a3.089 3.089 0 00-2.966 2.373z"
              />
              <path
                  data-name="Path 227"
                  d="M314.921 364.872c-.15 1.225.942 2.144 2.438 2.057a3.091 3.091 0 002.969-2.37c.145-1.225-.941-2.154-2.431-2.067a3.113 3.113 0 00-2.976 2.38z"
              />
              <path
                  data-name="Path 228"
                  d="M306.321 365.381c-.159 1.227.927 2.142 2.421 2.052a3.116 3.116 0 002.978-2.375c.154-1.225-.926-2.152-2.415-2.062a3.143 3.143 0 00-2.984 2.385z"
              />
              <path
                  data-name="Path 229"
                  d="M297.736 365.906c-.167 1.227.912 2.141 2.4 2.049a3.146 3.146 0 002.988-2.38c.16-1.227-.912-2.151-2.4-2.059a3.168 3.168 0 00-2.988 2.39z"
              />
              <path
                  data-name="Path 230"
                  d="M289.161 366.448c-.174 1.227.9 2.139 2.386 2.044a3.174 3.174 0 003-2.386c.167-1.227-.9-2.147-2.381-2.054a3.2 3.2 0 00-3.005 2.396z"
              />
              <path
                  data-name="Path 231"
                  d="M280.602 367.003c-.18 1.227.881 2.137 2.368 2.04a3.2 3.2 0 003.006-2.393c.174-1.227-.881-2.146-2.363-2.049a3.225 3.225 0 00-3.011 2.402z"
              />
              <path
                  data-name="Path 232"
                  d="M272.055 367.575c-.187 1.227.867 2.137 2.351 2.037a3.23 3.23 0 003.015-2.4c.184-1.227-.866-2.146-2.343-2.045a3.259 3.259 0 00-3.023 2.408z"
              />
              <path
                  data-name="Path 233"
                  d="M1079.684 378.581a4.752 4.752 0 003.442 2.587c1.347.152 1.98-.759 1.412-2.039a4.8 4.8 0 00-3.447-2.592c-1.337-.145-1.965.766-1.407 2.044z"
              />
              <path
                  data-name="Path 234"
                  d="M1071.926 377.717a4.7 4.7 0 003.429 2.58c1.352.15 2-.762 1.446-2.039a4.728 4.728 0 00-3.432-2.585c-1.343-.148-1.984.772-1.443 2.044z"
              />
              <path
                  data-name="Path 235"
                  d="M1064.14 376.864a4.621 4.621 0 003.412 2.573c1.357.149 2.022-.764 1.482-2.039a4.671 4.671 0 00-3.417-2.58c-1.347-.147-2.007.771-1.477 2.046z"
              />
              <path
                  data-name="Path 236"
                  d="M1056.322 376.018a4.558 4.558 0 003.4 2.568c1.362.147 2.042-.765 1.516-2.039a4.605 4.605 0 00-3.4-2.573c-1.354-.145-2.033.772-1.516 2.044z"
              />
              <path
                  data-name="Path 237"
                  d="M1048.473 375.184a4.5 4.5 0 003.382 2.562c1.369.145 2.062-.765 1.551-2.039a4.538 4.538 0 00-3.387-2.567c-1.357-.144-2.049.772-1.546 2.044z"
              />
              <path
                  data-name="Path 238"
                  d="M1040.595 374.362a4.432 4.432 0 003.369 2.553c1.372.144 2.082-.769 1.582-2.039a4.474 4.474 0 00-3.372-2.56c-1.363-.141-2.068.776-1.579 2.046z"
              />
              <path
                  data-name="Path 239"
                  d="M1032.689 373.548a4.371 4.371 0 003.354 2.548c1.375.142 2.1-.77 1.616-2.039a4.413 4.413 0 00-3.359-2.555c-1.367-.14-2.089.778-1.611 2.046z"
              />
              <path
                  data-name="Path 240"
                  d="M1024.755 372.749a4.308 4.308 0 003.339 2.542c1.382.139 2.122-.772 1.648-2.04a4.352 4.352 0 00-3.345-2.548c-1.376-.141-2.107.778-1.642 2.046z"
              />
              <path
                  data-name="Path 241"
                  d="M1016.793 371.957a4.254 4.254 0 003.325 2.537c1.385.137 2.141-.775 1.68-2.042a4.3 4.3 0 00-3.332-2.543c-1.377-.136-2.124.781-1.673 2.048z"
              />
              <path
                  data-name="Path 242"
                  d="M1008.802 371.178a4.205 4.205 0 003.312 2.53c1.39.135 2.159-.779 1.709-2.042a4.239 4.239 0 00-3.317-2.537c-1.385-.135-2.142.789-1.704 2.049z"
              />
              <path
                  data-name="Path 243"
                  d="M1000.783 370.41a4.149 4.149 0 003.3 2.523c1.4.134 2.176-.78 1.741-2.044a4.19 4.19 0 00-3.305-2.53c-1.388-.13-2.163.789-1.736 2.051z"
              />
              <path
                  data-name="Path 244"
                  d="M992.741 369.656a4.1 4.1 0 003.285 2.518c1.4.13 2.194-.784 1.77-2.045a4.134 4.134 0 00-3.292-2.525c-1.391-.13-2.18.79-1.763 2.052z"
              />
              <path
                  data-name="Path 245"
                  d="M984.671 368.913a4.042 4.042 0 003.272 2.512c1.407.13 2.213-.785 1.8-2.047a4.083 4.083 0 00-3.279-2.518c-1.397-.13-2.199.793-1.793 2.053z"
              />
              <path
                  data-name="Path 246"
                  d="M976.575 368.181a4 4 0 003.26 2.507c1.409.127 2.229-.787 1.827-2.049a4.034 4.034 0 00-3.265-2.513c-1.401-.126-2.217.797-1.822 2.055z"
              />
              <path
                  data-name="Path 247"
                  d="M968.455 367.462a3.945 3.945 0 003.247 2.5c1.414.125 2.244-.79 1.855-2.049a3.987 3.987 0 00-3.254-2.508c-1.406-.126-2.229.8-1.848 2.057z"
              />
              <path
                  data-name="Path 248"
                  d="M960.308 366.757a3.9 3.9 0 003.235 2.493c1.417.124 2.263-.794 1.883-2.052a3.945 3.945 0 00-3.242-2.5c-1.408-.122-2.247.8-1.876 2.059z"
              />
              <path
                  data-name="Path 249"
                  d="M952.139 366.06a3.864 3.864 0 003.224 2.488c1.42.12 2.279-.8 1.912-2.054a3.9 3.9 0 00-3.23-2.5c-1.415-.113-2.262.813-1.906 2.066z"
              />
              <path
                  data-name="Path 250"
                  d="M637.491 350.706a2.745 2.745 0 002.861 2.279 2.317 2.317 0 002.61-2.2 2.761 2.761 0 00-2.864-2.289 2.327 2.327 0 00-2.607 2.21z"
              />
              <path
                  data-name="Path 251"
                  d="M628.776 350.591a2.721 2.721 0 002.851 2.274 2.339 2.339 0 002.624-2.206 2.741 2.741 0 00-2.854-2.284 2.347 2.347 0 00-2.621 2.216z"
              />
              <path
                  data-name="Path 252"
                  d="M620.057 350.502a2.7 2.7 0 002.842 2.269 2.36 2.36 0 002.635-2.212 2.719 2.719 0 00-2.846-2.276 2.367 2.367 0 00-2.631 2.219z"
              />
              <path
                  data-name="Path 253"
                  d="M611.332 350.427a2.681 2.681 0 002.834 2.263 2.376 2.376 0 002.645-2.218 2.692 2.692 0 00-2.834-2.271 2.388 2.388 0 00-2.645 2.226z"
              />
              <path
                  data-name="Path 254"
                  d="M602.605 350.367a2.658 2.658 0 002.822 2.258 2.4 2.4 0 002.659-2.222 2.674 2.674 0 00-2.824-2.266 2.408 2.408 0 00-2.657 2.23z"
              />
              <path
                  data-name="Path 255"
                  d="M593.874 350.325a2.638 2.638 0 002.814 2.251 2.416 2.416 0 002.67-2.226 2.652 2.652 0 00-2.816-2.261 2.428 2.428 0 00-2.668 2.236z"
              />
              <path
                  data-name="Path 256"
                  d="M341.558 356.477c-.142 1.24.958 2.174 2.453 2.1a3.049 3.049 0 002.951-2.378c.137-1.238-.959-2.184-2.448-2.107a3.071 3.071 0 00-2.956 2.385z"
              />
              <path
                  data-name="Path 257"
                  d="M332.96 356.938c-.149 1.238.944 2.172 2.436 2.092a3.076 3.076 0 002.961-2.385c.142-1.237-.944-2.181-2.431-2.1a3.1 3.1 0 00-2.966 2.393z"
              />
              <path
                  data-name="Path 258"
                  d="M324.379 357.414c-.157 1.238.927 2.171 2.42 2.087a3.1 3.1 0 002.97-2.388c.15-1.238-.929-2.181-2.415-2.1a3.122 3.122 0 00-2.975 2.401z"
              />
              <path
                  data-name="Path 259"
                  d="M315.808 357.907c-.164 1.238.912 2.167 2.4 2.082a3.125 3.125 0 002.978-2.395c.159-1.238-.914-2.177-2.4-2.091a3.148 3.148 0 00-2.978 2.404z"
              />
              <path
                  data-name="Path 260"
                  d="M307.249 358.415c-.17 1.238.9 2.167 2.386 2.077a3.154 3.154 0 002.988-2.4c.165-1.238-.9-2.177-2.38-2.089a3.182 3.182 0 00-2.994 2.412z"
              />
              <path
                  data-name="Path 261"
                  d="M298.701 358.937c-.179 1.24.882 2.166 2.368 2.074a3.186 3.186 0 003-2.4c.172-1.24-.884-2.174-2.361-2.082a3.209 3.209 0 00-3.007 2.408z"
              />
              <path
                  data-name="Path 262"
                  d="M290.174 359.473c-.185 1.24.867 2.162 2.35 2.069a3.214 3.214 0 003.008-2.41c.179-1.238-.867-2.172-2.344-2.079a3.241 3.241 0 00-3.014 2.42z"
              />
              <path
                  data-name="Path 263"
                  d="M281.654 360.031c-.194 1.24.851 2.162 2.329 2.065a3.241 3.241 0 003.018-2.415c.187-1.24-.851-2.171-2.324-2.074a3.264 3.264 0 00-3.023 2.424z"
              />
              <path
                  data-name="Path 264"
                  d="M273.155 360.601c-.2 1.242.836 2.161 2.311 2.062a3.271 3.271 0 003.026-2.421c.2-1.24-.834-2.169-2.3-2.069a3.3 3.3 0 00-3.037 2.428z"
              />
              <path
                  data-name="Path 265"
                  d="M264.67 361.186c-.211 1.242.817 2.159 2.291 2.057a3.3 3.3 0 003.036-2.425c.2-1.24-.817-2.169-2.284-2.067a3.33 3.33 0 00-3.043 2.435z"
              />
              <path
                  data-name="Path 266"
                  d="M1076.492 371.336a4.8 4.8 0 003.442 2.6c1.334.15 1.942-.769 1.364-2.055a4.85 4.85 0 00-3.446-2.6c-1.323-.154-1.93.77-1.36 2.055z"
              />
              <path
                  data-name="Path 267"
                  d="M1061.105 369.636a4.656 4.656 0 003.412 2.583c1.34.147 1.985-.772 1.434-2.054a4.708 4.708 0 00-3.417-2.59c-1.337-.147-1.972.779-1.429 2.061z"
              />
              <path
                  data-name="Path 268"
                  d="M1053.362 368.8a4.6 4.6 0 003.4 2.578c1.349.145 2.007-.774 1.469-2.055a4.64 4.64 0 00-3.4-2.585c-1.345-.144-1.999.78-1.469 2.062z"
              />
              <path
                  data-name="Path 269"
                  d="M1045.588 367.975a4.537 4.537 0 003.384 2.574c1.355.142 2.027-.775 1.5-2.057a4.582 4.582 0 00-3.391-2.578c-1.339-.143-2.008.781-1.493 2.061z"
              />
              <path
                  data-name="Path 270"
                  d="M1037.784 367.16a4.473 4.473 0 003.37 2.567c1.359.142 2.047-.777 1.534-2.057a4.514 4.514 0 00-3.376-2.572c-1.347-.142-2.031.784-1.528 2.062z"
              />
              <path
                  data-name="Path 271"
                  d="M1029.95 366.354a4.409 4.409 0 003.357 2.562 1.391 1.391 0 001.566-2.057 4.45 4.45 0 00-3.362-2.567c-1.354-.141-2.052.783-1.561 2.062z"
              />
              <path
                  data-name="Path 272"
                  d="M1022.087 365.556a4.356 4.356 0 003.342 2.557c1.372.137 2.087-.782 1.6-2.059a4.393 4.393 0 00-3.349-2.562 1.412 1.412 0 00-1.593 2.064z"
              />
              <path
                  data-name="Path 273"
                  d="M1014.192 364.778a4.306 4.306 0 003.331 2.55c1.375.135 2.105-.784 1.631-2.06a4.348 4.348 0 00-3.335-2.557 1.431 1.431 0 00-1.627 2.067z"
              />
              <path
                  data-name="Path 274"
                  d="M1006.271 364.006a4.24 4.24 0 003.317 2.543c1.379.135 2.124-.787 1.661-2.06a4.283 4.283 0 00-3.324-2.55 1.446 1.446 0 00-1.654 2.067z"
              />
              <path
                  data-name="Path 275"
                  d="M998.321 363.246a4.194 4.194 0 003.305 2.538c1.384.132 2.141-.79 1.691-2.062a4.236 4.236 0 00-3.31-2.547c-1.377-.129-2.129.8-1.686 2.071z"
              />
              <path
                  data-name="Path 276"
                  d="M990.343 362.495a4.137 4.137 0 003.292 2.533c1.389.13 2.162-.792 1.721-2.064a4.182 4.182 0 00-3.3-2.54c-1.377-.128-2.146.799-1.713 2.071z"
              />
              <path
                  data-name="Path 277"
                  d="M982.339 361.759a4.093 4.093 0 003.279 2.528c1.394.127 2.179-.8 1.751-2.067a4.13 4.13 0 00-3.285-2.533c-1.386-.127-2.165.802-1.745 2.072z"
              />
              <path
                  data-name="Path 278"
                  d="M974.305 361.034a4.043 4.043 0 003.269 2.523c1.4.125 2.2-.8 1.78-2.069a4.087 4.087 0 00-3.274-2.53c-1.391-.124-2.183.807-1.775 2.076z"
              />
              <path
                  data-name="Path 279"
                  d="M966.248 360.319a4 4 0 003.255 2.517c1.4.124 2.216-.8 1.808-2.069a4.037 4.037 0 00-3.26-2.525c-1.394-.122-2.201.811-1.803 2.077z"
              />
              <path
                  data-name="Path 280"
                  d="M958.165 359.62a3.946 3.946 0 003.243 2.512c1.407.122 2.231-.8 1.837-2.074a3.991 3.991 0 00-3.25-2.518c-1.398-.121-2.218.813-1.83 2.08z"
              />
              <path
                  data-name="Path 281"
                  d="M950.055 358.931a3.911 3.911 0 003.232 2.507c1.41.119 2.249-.809 1.865-2.076a3.94 3.94 0 00-3.239-2.513c-1.403-.119-2.234.817-1.858 2.082z"
              />
              <path
                  data-name="Path 282"
                  d="M941.922 358.256a3.859 3.859 0 003.22 2.5c1.415.117 2.263-.812 1.892-2.077a3.9 3.9 0 00-3.227-2.508c-1.407-.115-2.251.819-1.885 2.085z"
              />
              <path
                  data-name="Path 283"
                  d="M637.091 343.659a2.757 2.757 0 002.859 2.3 2.31 2.31 0 002.59-2.226 2.771 2.771 0 00-2.861-2.311 2.32 2.32 0 00-2.588 2.237z"
              />
              <path
                  data-name="Path 284"
                  d="M628.412 343.552a2.735 2.735 0 002.849 2.3 2.331 2.331 0 002.6-2.231 2.749 2.749 0 00-2.851-2.308 2.343 2.343 0 00-2.598 2.239z"
              />
              <path
                  data-name="Path 285"
                  d="M619.725 343.457a2.709 2.709 0 002.837 2.293 2.352 2.352 0 002.617-2.236 2.724 2.724 0 00-2.841-2.3 2.36 2.36 0 00-2.613 2.243z"
              />
              <path
                  data-name="Path 286"
                  d="M611.036 343.381a2.69 2.69 0 002.829 2.288 2.373 2.373 0 002.629-2.243 2.7 2.7 0 00-2.829-2.294 2.383 2.383 0 00-2.629 2.249z"
              />
              <path
                  data-name="Path 287"
                  d="M602.346 343.323a2.667 2.667 0 002.817 2.281 2.391 2.391 0 002.642-2.246 2.681 2.681 0 00-2.819-2.289 2.4 2.4 0 00-2.64 2.254z"
              />
              <path
                  data-name="Path 288"
                  d="M342.388 349.439c-.154 1.252.931 2.2 2.42 2.121a3.079 3.079 0 002.958-2.4c.147-1.25-.932-2.208-2.415-2.131a3.1 3.1 0 00-2.963 2.41z"
              />
              <path
                  data-name="Path 289"
                  d="M333.833 349.899c-.16 1.252.916 2.2 2.4 2.116a3.1 3.1 0 002.968-2.406c.154-1.252-.916-2.206-2.4-2.124a3.127 3.127 0 00-2.968 2.414z"
              />
              <path
                  data-name="Path 290"
                  d="M325.291 350.375c-.167 1.252.9 2.194 2.386 2.111a3.134 3.134 0 002.978-2.411c.162-1.252-.9-2.2-2.38-2.121a3.16 3.16 0 00-2.984 2.421z"
              />
              <path
                  data-name="Path 291"
                  d="M316.764 350.867c-.175 1.252.886 2.192 2.368 2.105a3.158 3.158 0 002.986-2.416c.17-1.25-.886-2.2-2.361-2.116a3.187 3.187 0 00-2.993 2.427z"
              />
              <path
                  data-name="Path 292"
                  d="M308.248 351.373c-.182 1.253.871 2.191 2.35 2.1a3.191 3.191 0 003-2.421c.177-1.252-.871-2.2-2.343-2.111a3.216 3.216 0 00-3.007 2.432z"
              />
              <path
                  data-name="Path 293"
                  d="M299.747 351.896c-.19 1.252.854 2.187 2.331 2.1a3.216 3.216 0 003.006-2.426c.185-1.252-.852-2.2-2.324-2.107a3.248 3.248 0 00-3.013 2.433z"
              />
              <path
                  data-name="Path 294"
                  d="M291.262 352.436c-.2 1.252.837 2.187 2.311 2.092a3.25 3.25 0 003.018-2.431c.192-1.252-.837-2.2-2.306-2.1a3.277 3.277 0 00-3.023 2.439z"
              />
              <path
                  data-name="Path 295"
                  d="M282.791 352.987c-.209 1.252.82 2.184 2.291 2.089a3.282 3.282 0 003.028-2.438c.2-1.253-.821-2.192-2.286-2.1a3.306 3.306 0 00-3.033 2.449z"
              />
              <path
                  data-name="Path 296"
                  d="M274.336 353.555c-.217 1.253.8 2.184 2.273 2.084a3.311 3.311 0 003.036-2.443c.209-1.252-.8-2.191-2.266-2.092a3.335 3.335 0 00-3.043 2.451z"
              />
              <path
                  data-name="Path 297"
                  d="M265.894 354.139c-.224 1.253.787 2.181 2.253 2.081a3.341 3.341 0 003.046-2.448c.217-1.252-.785-2.191-2.246-2.089a3.371 3.371 0 00-3.053 2.456z"
              />
              <path
                  data-name="Path 298"
                  d="M1057.994 362.368a4.7 4.7 0 003.412 2.6c1.33.144 1.952-.782 1.387-2.072a4.752 4.752 0 00-3.417-2.6c-1.318-.15-1.94.779-1.382 2.072z"
              />
              <path
                  data-name="Path 299"
                  d="M1050.326 361.535a4.639 4.639 0 003.4 2.59c1.337.142 1.973-.782 1.42-2.072a4.679 4.679 0 00-3.4-2.6c-1.33-.136-1.963.797-1.42 2.082z"
              />
              <path
                  data-name="Path 300"
                  d="M1042.626 360.719a4.575 4.575 0 003.386 2.585c1.342.142 1.994-.784 1.455-2.072a4.621 4.621 0 00-3.391-2.59c-1.333-.142-1.978.79-1.45 2.077z"
              />
              <path
                  data-name="Path 301"
                  d="M1034.894 359.912a4.519 4.519 0 003.374 2.578c1.347.142 2.012-.785 1.487-2.072a4.559 4.559 0 00-3.379-2.585c-1.34-.142-1.998.792-1.482 2.079z"
              />
              <path
                  data-name="Path 302"
                  d="M1027.13 359.113a4.462 4.462 0 003.361 2.575c1.352.137 2.034-.79 1.519-2.076a4.5 4.5 0 00-3.364-2.58c-1.344-.138-2.019.798-1.516 2.081z"
              />
              <path
                  data-name="Path 303"
                  d="M1019.337 358.326a4.4 4.4 0 003.347 2.568c1.357.137 2.054-.79 1.552-2.075a4.441 4.441 0 00-3.354-2.575c-1.345-.135-2.039.8-1.545 2.082z"
              />
              <path
                  data-name="Path 304"
                  d="M1011.512 357.549a4.342 4.342 0 003.334 2.563 1.412 1.412 0 001.584-2.077 4.391 4.391 0 00-3.34-2.57 1.417 1.417 0 00-1.578 2.084z"
              />
              <path
                  data-name="Path 305"
                  d="M1003.657 356.782a4.3 4.3 0 003.324 2.558 1.429 1.429 0 001.614-2.079 4.334 4.334 0 00-3.329-2.563 1.433 1.433 0 00-1.609 2.084z"
              />
              <path
                  data-name="Path 306"
                  d="M995.774 356.026a4.238 4.238 0 003.31 2.555 1.447 1.447 0 001.644-2.082 4.279 4.279 0 00-3.315-2.558 1.45 1.45 0 00-1.639 2.085z"
              />
              <path
                  data-name="Path 307"
                  d="M987.861 355.283a4.184 4.184 0 003.3 2.548 1.464 1.464 0 001.674-2.082 4.231 4.231 0 00-3.3-2.555 1.468 1.468 0 00-1.674 2.089z"
              />
              <path
                  data-name="Path 308"
                  d="M979.923 354.551a4.137 4.137 0 003.287 2.543c1.382.127 2.147-.8 1.7-2.084a4.179 4.179 0 00-3.294-2.55 1.485 1.485 0 00-1.693 2.091z"
              />
              <path
                  data-name="Path 309"
                  d="M971.953 353.835a4.089 4.089 0 003.274 2.538c1.387.124 2.166-.807 1.733-2.087a4.123 4.123 0 00-3.28-2.545 1.5 1.5 0 00-1.727 2.094z"
              />
              <path
                  data-name="Path 310"
                  d="M963.956 353.123a4.042 4.042 0 003.264 2.533c1.39.122 2.181-.812 1.761-2.091a4.078 4.078 0 00-3.269-2.538 1.521 1.521 0 00-1.756 2.096z"
              />
              <path
                  data-name="Path 311"
                  d="M955.933 352.425a4 4 0 003.252 2.528c1.4.12 2.2-.814 1.79-2.092a4.029 4.029 0 00-3.257-2.533c-1.388-.12-2.184.821-1.785 2.097z"
              />
              <path
                  data-name="Path 312"
                  d="M947.886 351.743a3.95 3.95 0 003.24 2.522c1.4.119 2.217-.817 1.818-2.094a3.987 3.987 0 00-3.247-2.528c-1.392-.12-2.204.823-1.811 2.1z"
              />
              <path
                  data-name="Path 313"
                  d="M890.864 347.314a3.66 3.66 0 003.163 2.487c1.429.1 2.324-.844 2-2.116a3.69 3.69 0 00-3.17-2.493c-1.421-.104-2.312.851-1.993 2.122z"
              />
              <path
                  data-name="Path 314"
                  d="M645.304 336.664a2.79 2.79 0 002.866 2.331 2.279 2.279 0 002.555-2.243 2.809 2.809 0 00-2.868-2.341 2.292 2.292 0 00-2.553 2.253z"
              />
              <path
                  data-name="Path 315"
                  d="M636.668 336.539a2.767 2.767 0 002.854 2.326 2.3 2.3 0 002.57-2.249 2.785 2.785 0 00-2.858-2.334 2.312 2.312 0 00-2.566 2.257z"
              />
              <path
                  data-name="Path 316"
                  d="M628.028 336.43a2.743 2.743 0 002.844 2.321 2.324 2.324 0 002.586-2.254 2.758 2.758 0 00-2.846-2.33 2.334 2.334 0 00-2.584 2.263z"
              />
              <path
                  data-name="Path 317"
                  d="M619.386 336.339a2.72 2.72 0 002.833 2.316 2.346 2.346 0 002.6-2.259 2.734 2.734 0 00-2.834-2.324 2.356 2.356 0 00-2.599 2.267z"
              />
              <path
                  data-name="Path 318"
                  d="M610.731 336.263a2.7 2.7 0 002.823 2.309 2.366 2.366 0 002.612-2.264 2.711 2.711 0 00-2.822-2.318 2.378 2.378 0 00-2.613 2.273z"
              />
              <path
                  data-name="Path 319"
                  d="M602.076 336.206a2.675 2.675 0 002.811 2.3 2.388 2.388 0 002.625-2.271 2.686 2.686 0 00-2.811-2.313 2.4 2.4 0 00-2.625 2.284z"
              />
              <path
                  data-name="Path 320"
                  d="M593.418 336.169a2.649 2.649 0 002.8 2.3 2.412 2.412 0 002.64-2.274 2.665 2.665 0 00-2.8-2.308 2.422 2.422 0 00-2.64 2.282z"
              />
              <path
                  data-name="Path 321"
                  d="M584.758 336.141a2.626 2.626 0 002.787 2.293 2.43 2.43 0 002.653-2.277 2.638 2.638 0 00-2.787-2.3 2.442 2.442 0 00-2.653 2.284z"
              />
              <path
                  data-name="Path 322"
                  d="M351.803 341.886c-.155 1.265.922 2.224 2.4 2.149a3.081 3.081 0 002.956-2.42 2.053 2.053 0 00-2.4-2.156 3.1 3.1 0 00-2.956 2.427z"
              />
              <path
                  data-name="Path 323"
                  d="M343.281 342.33c-.164 1.263.906 2.221 2.386 2.142a3.1 3.1 0 002.965-2.423c.159-1.263-.906-2.229-2.381-2.151a3.129 3.129 0 00-2.97 2.432z"
              />
              <path
                  data-name="Path 324"
                  d="M334.769 342.79c-.172 1.263.891 2.219 2.37 2.139a3.139 3.139 0 002.974-2.43c.165-1.263-.892-2.226-2.365-2.146a3.158 3.158 0 00-2.979 2.437z"
              />
              <path
                  data-name="Path 325"
                  d="M326.271 343.264c-.18 1.263.874 2.216 2.35 2.134a3.166 3.166 0 002.986-2.433c.172-1.263-.876-2.226-2.346-2.142a3.19 3.19 0 00-2.99 2.441z"
              />
              <path
                  data-name="Path 326"
                  d="M317.788 343.757c-.189 1.265.857 2.216 2.331 2.129a3.2 3.2 0 003-2.438c.18-1.265-.859-2.223-2.328-2.137a3.218 3.218 0 00-3.003 2.446z"
              />
              <path
                  data-name="Path 327"
                  d="M309.318 344.263c-.2 1.265.842 2.212 2.313 2.124a3.223 3.223 0 003.006-2.443c.189-1.263-.842-2.221-2.308-2.132a3.25 3.25 0 00-3.011 2.451z"
              />
              <path
                  data-name="Path 328"
                  d="M300.865 344.786c-.2 1.263.826 2.209 2.294 2.119a3.253 3.253 0 003.015-2.45c.2-1.263-.824-2.217-2.288-2.127a3.28 3.28 0 00-3.021 2.458z"
              />
              <path
                  data-name="Path 329"
                  d="M292.423 345.322c-.212 1.263.807 2.208 2.274 2.114a3.284 3.284 0 003.025-2.453c.206-1.263-.807-2.217-2.268-2.122a3.312 3.312 0 00-3.031 2.461z"
              />
              <path
                  data-name="Path 330"
                  d="M283.999 345.873c-.221 1.265.789 2.207 2.253 2.111a3.317 3.317 0 003.039-2.458c.214-1.263-.79-2.216-2.248-2.119a3.343 3.343 0 00-3.044 2.466z"
              />
              <path
                  data-name="Path 331"
                  d="M275.59 346.442c-.229 1.263.772 2.206 2.233 2.106a3.347 3.347 0 003.046-2.463c.222-1.265-.772-2.214-2.227-2.116a3.378 3.378 0 00-3.052 2.473z"
              />
              <path
                  data-name="Path 332"
                  d="M267.198 347.024c-.237 1.265.755 2.2 2.213 2.1a3.384 3.384 0 003.056-2.47c.232-1.265-.755-2.211-2.206-2.109a3.409 3.409 0 00-3.063 2.479z"
              />
              <path
                  data-name="Path 333"
                  d="M1062.373 355.887a4.807 4.807 0 003.426 2.612c1.31.145 1.9-.785 1.307-2.085a4.85 4.85 0 00-3.429-2.615c-1.303-.146-1.884.789-1.304 2.088z"
              />
              <path
                  data-name="Path 334"
                  d="M1054.811 355.056a4.74 4.74 0 003.414 2.605c1.317.144 1.918-.787 1.34-2.085a4.787 4.787 0 00-3.417-2.61c-1.308-.139-1.905.794-1.337 2.09z"
              />
              <path
                  data-name="Path 335"
                  d="M1047.215 354.234a4.684 4.684 0 003.4 2.6c1.323.142 1.938-.79 1.374-2.087a4.724 4.724 0 00-3.4-2.605c-1.314-.145-1.928.797-1.374 2.092z"
              />
              <path
                  data-name="Path 336"
                  d="M1039.588 353.422a4.623 4.623 0 003.387 2.6c1.33.142 1.96-.792 1.409-2.087a4.663 4.663 0 00-3.392-2.6c-1.321-.146-1.948.793-1.404 2.087z"
              />
              <path
                  data-name="Path 337"
                  d="M1031.926 352.619a4.563 4.563 0 003.376 2.592c1.335.139 1.979-.8 1.441-2.089a4.6 4.6 0 00-3.379-2.6c-1.326-.135-1.969.804-1.438 2.097z"
              />
              <path
                  data-name="Path 338"
                  d="M1024.232 351.828a4.507 4.507 0 003.364 2.587c1.339.137 2-.8 1.472-2.09a4.538 4.538 0 00-3.367-2.592c-1.33-.14-1.988.802-1.469 2.095z"
              />
              <path
                  data-name="Path 339"
                  d="M1016.507 351.043a4.443 4.443 0 003.351 2.583c1.345.134 2.022-.8 1.506-2.092a4.484 4.484 0 00-3.356-2.589c-1.337-.132-2.011.807-1.501 2.098z"
              />
              <path
                  data-name="Path 340"
                  d="M1008.749 350.272a4.393 4.393 0 003.339 2.578c1.352.134 2.04-.8 1.537-2.094a4.432 4.432 0 00-3.34-2.583c-1.345-.132-2.029.809-1.536 2.099z"
              />
              <path
                  data-name="Path 341"
                  d="M1000.961 349.512a4.335 4.335 0 003.327 2.572c1.357.132 2.059-.8 1.567-2.1a4.375 4.375 0 00-3.332-2.577c-1.345-.128-2.044.815-1.562 2.105z"
              />
              <path
                  data-name="Path 342"
                  d="M993.146 348.761a4.282 4.282 0 003.315 2.567 1.432 1.432 0 001.6-2.1 4.326 4.326 0 00-3.32-2.573 1.438 1.438 0 00-1.595 2.106z"
              />
              <path
                  data-name="Path 343"
                  d="M985.297 348.021a4.239 4.239 0 003.305 2.563 1.45 1.45 0 001.628-2.1 4.273 4.273 0 00-3.309-2.568 1.455 1.455 0 00-1.624 2.105z"
              />
              <path
                  data-name="Path 344"
                  d="M977.416 347.293a4.183 4.183 0 003.3 2.557 1.467 1.467 0 001.659-2.1 4.221 4.221 0 00-3.3-2.565 1.473 1.473 0 00-1.659 2.108z"
              />
              <path
                  data-name="Path 345"
                  d="M969.516 346.577a4.131 4.131 0 003.28 2.553 1.485 1.485 0 001.688-2.106 4.174 4.174 0 00-3.287-2.558 1.488 1.488 0 00-1.681 2.111z"
              />
              <path
                  data-name="Path 346"
                  d="M961.582 345.872a4.086 4.086 0 003.27 2.548 1.5 1.5 0 001.716-2.107 4.12 4.12 0 00-3.275-2.553 1.506 1.506 0 00-1.711 2.112z"
              />
              <path
                  data-name="Path 347"
                  d="M953.623 345.18a4.039 4.039 0 003.259 2.543 1.519 1.519 0 001.743-2.111 4.075 4.075 0 00-3.264-2.55 1.525 1.525 0 00-1.738 2.118z"
              />
              <path
                  data-name="Path 348"
                  d="M889.014 340.09a3.7 3.7 0 003.172 2.5c1.422.1 2.3-.852 1.957-2.136a3.733 3.733 0 00-3.178-2.512 1.675 1.675 0 00-1.951 2.148z"
              />
              <path
                  data-name="Path 349"
                  d="M696.228 330.575a2.956 2.956 0 002.928 2.385 2.14 2.14 0 002.435-2.236 2.978 2.978 0 00-2.931-2.393 2.15 2.15 0 00-2.432 2.244z"
              />
              <path
                  data-name="Path 350"
                  d="M644.814 329.474a2.8 2.8 0 002.863 2.353 2.272 2.272 0 002.533-2.266 2.822 2.822 0 00-2.864-2.361 2.285 2.285 0 00-2.532 2.274z"
              />
              <path
                  data-name="Path 351"
                  d="M636.22 329.348a2.778 2.778 0 002.851 2.348 2.294 2.294 0 002.548-2.271 2.794 2.794 0 00-2.853-2.356 2.306 2.306 0 00-2.546 2.279z"
              />
              <path
                  data-name="Path 352"
                  d="M627.62 329.241a2.749 2.749 0 002.839 2.341 2.317 2.317 0 002.563-2.276 2.766 2.766 0 00-2.841-2.35 2.327 2.327 0 00-2.561 2.285z"
              />
              <path
                  data-name="Path 353"
                  d="M619.016 329.149a2.727 2.727 0 002.823 2.338 2.339 2.339 0 002.578-2.283 2.743 2.743 0 00-2.827-2.345 2.35 2.35 0 00-2.574 2.29z"
              />
              <path
                  data-name="Path 354"
                  d="M610.405 329.075a2.7 2.7 0 002.816 2.331 2.364 2.364 0 002.594-2.286 2.717 2.717 0 00-2.816-2.34 2.372 2.372 0 00-2.594 2.295z"
              />
              <path
                  data-name="Path 355"
                  d="M601.792 329.019a2.675 2.675 0 002.8 2.324 2.386 2.386 0 002.609-2.291 2.7 2.7 0 00-2.8-2.335 2.394 2.394 0 00-2.609 2.302z"
              />
              <path
                  data-name="Path 356"
                  d="M593.174 328.974a2.652 2.652 0 002.791 2.321 2.406 2.406 0 002.622-2.3 2.668 2.668 0 00-2.791-2.328 2.418 2.418 0 00-2.622 2.307z"
              />
              <path
                  data-name="Path 357"
                  d="M584.553 328.953a2.631 2.631 0 002.779 2.316 2.431 2.431 0 002.637-2.3 2.645 2.645 0 00-2.779-2.323 2.441 2.441 0 00-2.637 2.307z"
              />
              <path
                  data-name="Path 358"
                  d="M361.201 334.283a2.048 2.048 0 002.391 2.174 3.076 3.076 0 002.948-2.435 2.056 2.056 0 00-2.386-2.182 3.1 3.1 0 00-2.953 2.443z"
              />
              <path
                  data-name="Path 359"
                  d="M352.712 334.711c-.167 1.275.9 2.244 2.373 2.171a3.1 3.1 0 002.958-2.441 2.034 2.034 0 00-2.368-2.177 3.13 3.13 0 00-2.963 2.447z"
              />
              <path
                  data-name="Path 360"
                  d="M344.233 335.155c-.174 1.275.882 2.243 2.355 2.164a3.138 3.138 0 002.97-2.445 2.013 2.013 0 00-2.35-2.172 3.16 3.16 0 00-2.975 2.453z"
              />
              <path
                  data-name="Path 361"
                  d="M335.769 335.615c-.182 1.275.864 2.239 2.334 2.159a3.164 3.164 0 002.98-2.45 1.99 1.99 0 00-2.329-2.167 3.188 3.188 0 00-2.985 2.458z"
              />
              <path
                  data-name="Path 362"
                  d="M327.317 336.089c-.191 1.275.847 2.239 2.316 2.156a3.2 3.2 0 002.991-2.456 1.971 1.971 0 00-2.311-2.162 3.222 3.222 0 00-2.996 2.462z"
              />
              <path
                  data-name="Path 363"
                  d="M318.878 336.58c-.2 1.275.832 2.234 2.3 2.149a3.225 3.225 0 003-2.46 1.949 1.949 0 00-2.291-2.157 3.252 3.252 0 00-3.009 2.468z"
              />
              <path
                  data-name="Path 364"
                  d="M310.456 337.086c-.207 1.273.814 2.233 2.276 2.144a3.257 3.257 0 003.013-2.465c.2-1.275-.814-2.241-2.271-2.152a3.282 3.282 0 00-3.018 2.473z"
              />
              <path
                  data-name="Path 365"
                  d="M302.049 337.609c-.217 1.275.794 2.231 2.256 2.139a3.288 3.288 0 003.023-2.468c.209-1.275-.8-2.239-2.253-2.149a3.313 3.313 0 00-3.026 2.478z"
              />
              <path
                  data-name="Path 366"
                  d="M293.655 338.145c-.224 1.275.779 2.229 2.238 2.136a3.322 3.322 0 003.033-2.475c.217-1.275-.78-2.238-2.231-2.144a3.35 3.35 0 00-3.04 2.483z"
              />
              <path
                  data-name="Path 367"
                  d="M285.28 338.701c-.232 1.275.76 2.228 2.216 2.131a3.352 3.352 0 003.043-2.48c.227-1.275-.762-2.234-2.209-2.137a3.38 3.38 0 00-3.05 2.486z"
              />
              <path
                  data-name="Path 368"
                  d="M276.921 339.262c-.242 1.275.742 2.226 2.194 2.126a3.384 3.384 0 003.053-2.483c.236-1.275-.742-2.233-2.187-2.134a3.413 3.413 0 00-3.06 2.491z"
              />
              <path
                  data-name="Path 369"
                  d="M268.579 339.845c-.251 1.275.722 2.222 2.172 2.122a3.42 3.42 0 003.065-2.49c.244-1.275-.725-2.231-2.167-2.129a3.447 3.447 0 00-3.07 2.497z"
              />
              <path
                  data-name="Path 370"
                  d="M1044.033 346.892a4.723 4.723 0 003.4 2.612c1.31.142 1.907-.8 1.33-2.1a4.764 4.764 0 00-3.4-2.615c-1.302-.146-1.897.801-1.33 2.103z"
              />
              <path
                  data-name="Path 371"
                  d="M1036.476 346.084a4.664 4.664 0 003.389 2.607c1.317.14 1.928-.8 1.362-2.1a4.706 4.706 0 00-3.392-2.614c-1.307-.139-1.916.805-1.359 2.107z"
              />
              <path
                  data-name="Path 372"
                  d="M1028.884 345.285a4.6 4.6 0 003.377 2.6c1.322.139 1.948-.8 1.4-2.1a4.641 4.641 0 00-3.381-2.609c-1.317-.135-1.941.809-1.396 2.109z"
              />
              <path
                  data-name="Path 373"
                  d="M1021.259 344.498a4.547 4.547 0 003.366 2.6c1.328.137 1.969-.8 1.427-2.106a4.586 4.586 0 00-3.369-2.6c-1.32-.14-1.955.806-1.424 2.106z"
              />
              <path
                  data-name="Path 374"
                  d="M1013.601 343.719a4.493 4.493 0 003.354 2.6c1.333.134 1.989-.809 1.461-2.107a4.535 4.535 0 00-3.359-2.6c-1.326-.139-1.976.807-1.456 2.107z"
              />
              <path
                  data-name="Path 375"
                  d="M1005.911 342.957a4.438 4.438 0 003.344 2.589c1.338.134 2.007-.809 1.49-2.109a4.479 4.479 0 00-3.347-2.6c-1.332-.132-1.995.82-1.487 2.12z"
              />
              <path
                  data-name="Path 376"
                  d="M998.19 342.193a4.378 4.378 0 003.33 2.585c1.345.132 2.029-.812 1.522-2.111a4.422 4.422 0 00-3.335-2.59c-1.337-.125-2.015.818-1.517 2.116z"
              />
              <path
                  data-name="Path 377"
                  d="M990.433 341.451a4.333 4.333 0 003.32 2.582c1.35.129 2.045-.816 1.552-2.114a4.373 4.373 0 00-3.325-2.587c-1.338-.133-2.028.819-1.547 2.119z"
              />
              <path
                  data-name="Path 378"
                  d="M982.654 340.711a4.279 4.279 0 003.309 2.577 1.437 1.437 0 001.583-2.116 4.319 4.319 0 00-3.314-2.582c-1.347-.128-2.052.825-1.578 2.121z"
              />
              <path
                  data-name="Path 379"
                  d="M974.84 339.986a4.232 4.232 0 003.3 2.572 1.453 1.453 0 001.613-2.117 4.267 4.267 0 00-3.3-2.578 1.458 1.458 0 00-1.613 2.123z"
              />
              <path
                  data-name="Path 380"
                  d="M966.998 339.276a4.182 4.182 0 003.289 2.568 1.47 1.47 0 001.641-2.121 4.212 4.212 0 00-3.292-2.573 1.475 1.475 0 00-1.638 2.126z"
              />
              <path
                  data-name="Path 381"
                  d="M895.192 333.398a3.782 3.782 0 003.19 2.525 1.633 1.633 0 001.888-2.149 3.816 3.816 0 00-3.2-2.533 1.638 1.638 0 00-1.878 2.157z"
              />
              <path
                  data-name="Path 382"
                  d="M887.087 332.809a3.741 3.741 0 003.178 2.522 1.653 1.653 0 001.915-2.152 3.775 3.775 0 00-3.185-2.528 1.658 1.658 0 00-1.908 2.158z"
              />
              <path
                  data-name="Path 383"
                  d="M878.959 332.235a3.7 3.7 0 003.168 2.517 1.672 1.672 0 001.94-2.157 3.737 3.737 0 00-3.173-2.523 1.678 1.678 0 00-1.935 2.163z"
              />
              <path
                  data-name="Path 384"
                  d="M703.937 323.556a3 3 0 002.939 2.411 2.109 2.109 0 002.39-2.251 3.03 3.03 0 00-2.943-2.42 2.117 2.117 0 00-2.386 2.26z"
              />
              <path
                  data-name="Path 385"
                  d="M695.445 323.316a2.977 2.977 0 002.928 2.406 2.13 2.13 0 002.406-2.258 3 3 0 00-2.929-2.413 2.138 2.138 0 00-2.405 2.265z"
              />
              <path
                  data-name="Path 386"
                  d="M652.844 322.36a2.837 2.837 0 002.868 2.378 2.24 2.24 0 002.495-2.281 2.856 2.856 0 00-2.871-2.386 2.251 2.251 0 00-2.492 2.289z"
              />
              <path
                  data-name="Path 387"
                  d="M644.299 322.218a2.814 2.814 0 002.856 2.375 2.265 2.265 0 002.512-2.288 2.832 2.832 0 00-2.859-2.381 2.275 2.275 0 00-2.509 2.294z"
              />
              <path
                  data-name="Path 388"
                  d="M635.748 322.094a2.787 2.787 0 002.844 2.368 2.288 2.288 0 002.528-2.293 2.8 2.8 0 00-2.846-2.375 2.3 2.3 0 00-2.526 2.3z"
              />
              <path
                  data-name="Path 389"
                  d="M627.192 321.986a2.758 2.758 0 002.831 2.363 2.309 2.309 0 002.543-2.3 2.774 2.774 0 00-2.833-2.371 2.32 2.32 0 00-2.541 2.308z"
              />
              <path
                  data-name="Path 390"
                  d="M618.629 321.896a2.737 2.737 0 002.819 2.358 2.336 2.336 0 002.56-2.3 2.75 2.75 0 00-2.821-2.365 2.345 2.345 0 00-2.558 2.307z"
              />
              <path
                  data-name="Path 391"
                  d="M610.064 321.822a2.706 2.706 0 002.806 2.351 2.355 2.355 0 002.575-2.306 2.722 2.722 0 00-2.807-2.36 2.366 2.366 0 00-2.574 2.315z"
              />
              <path
                  data-name="Path 392"
                  d="M601.493 321.764a2.682 2.682 0 002.794 2.346 2.38 2.38 0 002.59-2.313 2.7 2.7 0 00-2.8-2.353 2.388 2.388 0 00-2.584 2.32z"
              />
              <path
                  data-name="Path 393"
                  d="M592.919 321.724a2.656 2.656 0 002.781 2.341 2.4 2.4 0 002.605-2.318 2.673 2.673 0 00-2.782-2.348 2.413 2.413 0 00-2.604 2.325z"
              />
              <path
                  data-name="Path 394"
                  d="M584.34 321.703a2.631 2.631 0 002.767 2.335 2.426 2.426 0 002.62-2.323 2.646 2.646 0 00-2.769-2.343 2.438 2.438 0 00-2.618 2.331z"
              />
              <path
                  data-name="Path 395"
                  d="M379.048 326.235a2.073 2.073 0 002.395 2.2 3.045 3.045 0 002.929-2.445 2.081 2.081 0 00-2.391-2.212 3.066 3.066 0 00-2.933 2.457z"
              />
              <path
                  data-name="Path 396"
                  d="M370.579 326.631a2.052 2.052 0 002.378 2.2 3.073 3.073 0 002.939-2.45 2.061 2.061 0 00-2.373-2.207 3.1 3.1 0 00-2.944 2.457z"
              />
              <path
                  data-name="Path 397"
                  d="M362.122 327.044a2.032 2.032 0 002.36 2.194 3.1 3.1 0 002.951-2.455 2.039 2.039 0 00-2.355-2.2 3.127 3.127 0 00-2.956 2.461z"
              />
              <path
                  data-name="Path 398"
                  d="M353.677 327.471a2.009 2.009 0 002.34 2.189 3.134 3.134 0 002.963-2.46 2.016 2.016 0 00-2.336-2.2 3.154 3.154 0 00-2.967 2.471z"
              />
              <path
                  data-name="Path 399"
                  d="M345.245 327.916a1.989 1.989 0 002.321 2.184 3.163 3.163 0 002.973-2.465 2 2 0 00-2.316-2.192 3.189 3.189 0 00-2.978 2.473z"
              />
              <path
                  data-name="Path 400"
                  d="M336.828 328.375a1.967 1.967 0 002.3 2.179 3.194 3.194 0 002.985-2.47 1.974 1.974 0 00-2.3-2.186 3.215 3.215 0 00-2.985 2.477z"
              />
              <path
                  data-name="Path 401"
                  d="M328.423 328.85a1.947 1.947 0 002.283 2.174 3.224 3.224 0 002.995-2.473 1.952 1.952 0 00-2.276-2.182 3.251 3.251 0 00-3.002 2.481z"
              />
              <path
                  data-name="Path 402"
                  d="M320.034 329.345c-.211 1.287.8 2.254 2.261 2.169a3.256 3.256 0 003.006-2.478 1.93 1.93 0 00-2.256-2.177 3.279 3.279 0 00-3.011 2.486z"
              />
              <path
                  data-name="Path 403"
                  d="M311.659 329.846c-.219 1.285.785 2.254 2.241 2.164a3.29 3.29 0 003.018-2.483 1.911 1.911 0 00-2.236-2.172 3.318 3.318 0 00-3.023 2.491z"
              />
              <path
                  data-name="Path 404"
                  d="M303.3 330.369c-.227 1.285.767 2.251 2.219 2.159a3.321 3.321 0 003.03-2.488 1.89 1.89 0 00-2.216-2.167 3.349 3.349 0 00-3.033 2.496z"
              />
              <path
                  data-name="Path 405"
                  d="M294.958 330.905c-.237 1.285.749 2.248 2.2 2.154a3.352 3.352 0 003.04-2.493 1.868 1.868 0 00-2.192-2.162 3.381 3.381 0 00-3.048 2.501z"
              />
              <path
                  data-name="Path 406"
                  d="M286.631 331.457c-.246 1.285.73 2.246 2.177 2.149a3.384 3.384 0 003.05-2.5c.241-1.283-.73-2.253-2.171-2.157a3.417 3.417 0 00-3.056 2.508z"
              />
              <path
                  data-name="Path 407"
                  d="M278.322 332.022c-.254 1.287.712 2.244 2.156 2.147a3.424 3.424 0 003.061-2.5 1.828 1.828 0 00-2.151-2.154 3.449 3.449 0 00-3.066 2.507z"
              />
              <path
                  data-name="Path 408"
                  d="M261.759 333.198c-.274 1.287.673 2.241 2.111 2.137a3.491 3.491 0 003.083-2.512c.266-1.287-.675-2.248-2.106-2.144a3.52 3.52 0 00-3.088 2.519z"
              />
              <path
                  data-name="Path 409"
                  d="M1040.784 339.512a4.76 4.76 0 003.4 2.622c1.3.14 1.875-.805 1.285-2.114a4.805 4.805 0 00-3.4-2.625c-1.293-.141-1.866.808-1.285 2.117z"
              />
              <path
                  data-name="Path 410"
                  d="M1033.294 338.707a4.7 4.7 0 003.389 2.619c1.305.139 1.9-.807 1.319-2.116a4.742 4.742 0 00-3.394-2.622c-1.289-.138-1.882.811-1.314 2.119z"
              />
              <path
                  data-name="Path 411"
                  d="M1018.211 337.129a4.59 4.59 0 003.369 2.61c1.317.135 1.937-.812 1.382-2.119a4.632 4.632 0 00-3.37-2.615c-1.307-.135-1.922.817-1.381 2.124z"
              />
              <path
                  data-name="Path 412"
                  d="M1010.625 336.353a4.532 4.532 0 003.355 2.607c1.323.134 1.957-.814 1.415-2.121a4.577 4.577 0 00-3.361-2.612c-1.316-.131-1.947.82-1.409 2.126z"
              />
              <path
                  data-name="Path 413"
                  d="M1002.997 335.588a4.482 4.482 0 003.345 2.6c1.33.132 1.977-.817 1.447-2.124a4.516 4.516 0 00-3.35-2.607c-1.319-.128-1.964.826-1.442 2.131z"
              />
              <path
                  data-name="Path 414"
                  d="M995.341 334.834a4.428 4.428 0 003.334 2.6c1.333.13 2-.821 1.477-2.126a4.462 4.462 0 00-3.339-2.6c-1.325-.135-1.984.821-1.472 2.126z"
              />
              <path
                  data-name="Path 415"
                  d="M987.652 334.091a4.372 4.372 0 003.324 2.594c1.339.129 2.015-.822 1.509-2.127a4.414 4.414 0 00-3.329-2.6c-1.33-.13-2.003.828-1.504 2.133z"
              />
              <path
                  data-name="Path 416"
                  d="M979.933 333.357a4.324 4.324 0 003.314 2.589c1.344.127 2.034-.826 1.537-2.129a4.36 4.36 0 00-3.317-2.6c-1.336-.121-2.023.835-1.534 2.14z"
              />
              <path
                  data-name="Path 417"
                  d="M948.757 330.536a4.126 4.126 0 003.272 2.572 1.491 1.491 0 001.655-2.142 4.169 4.169 0 00-3.275-2.578 1.5 1.5 0 00-1.652 2.148z"
              />
              <path
                  data-name="Path 418"
                  d="M925.076 328.544a4 4 0 003.238 2.558 1.543 1.543 0 001.74-2.151 4.023 4.023 0 00-3.243-2.565 1.549 1.549 0 00-1.735 2.158z"
              />
              <path
                  data-name="Path 419"
                  d="M917.13 327.904a3.944 3.944 0 003.227 2.555 1.56 1.56 0 001.766-2.154 3.978 3.978 0 00-3.232-2.562 1.567 1.567 0 00-1.761 2.161z"
              />
              <path
                  data-name="Path 420"
                  d="M909.156 327.277a3.906 3.906 0 003.217 2.552 1.581 1.581 0 001.795-2.159 3.938 3.938 0 00-3.222-2.557 1.586 1.586 0 00-1.79 2.164z"
              />
              <path
                  data-name="Path 421"
                  d="M901.158 326.664a3.859 3.859 0 003.205 2.547 1.6 1.6 0 001.821-2.162 3.894 3.894 0 00-3.212-2.553 1.6 1.6 0 00-1.814 2.168z"
              />
              <path
                  data-name="Path 422"
                  d="M893.133 326.065a3.822 3.822 0 003.2 2.542 1.618 1.618 0 001.846-2.167 3.851 3.851 0 00-3.2-2.547 1.622 1.622 0 00-1.846 2.172z"
              />
              <path
                  data-name="Path 423"
                  d="M703.069 316.237a3.023 3.023 0 002.938 2.43 2.094 2.094 0 002.361-2.269 3.04 3.04 0 00-2.939-2.436 2.1 2.1 0 00-2.36 2.275z"
              />
              <path
                  data-name="Path 424"
                  d="M694.623 315.997a2.992 2.992 0 002.926 2.425 2.116 2.116 0 002.38-2.274 3.011 3.011 0 00-2.928-2.431 2.127 2.127 0 00-2.378 2.28z"
              />
              <path
                  data-name="Path 425"
                  d="M660.747 315.201a2.878 2.878 0 002.874 2.4 2.211 2.211 0 002.455-2.3 2.9 2.9 0 00-2.879-2.41 2.217 2.217 0 00-2.45 2.31z"
              />
              <path
                  data-name="Path 426"
                  d="M652.257 315.042a2.848 2.848 0 002.863 2.4 2.233 2.233 0 002.471-2.3 2.871 2.871 0 00-2.866-2.4 2.241 2.241 0 00-2.468 2.3z"
              />
              <path
                  data-name="Path 427"
                  d="M643.759 314.902a2.819 2.819 0 002.849 2.391 2.254 2.254 0 002.488-2.3 2.84 2.84 0 00-2.852-2.4 2.263 2.263 0 00-2.485 2.309z"
              />
              <path
                  data-name="Path 428"
                  d="M635.254 314.779a2.8 2.8 0 002.838 2.386 2.278 2.278 0 002.5-2.311 2.813 2.813 0 00-2.839-2.395 2.291 2.291 0 00-2.499 2.32z"
              />
              <path
                  data-name="Path 429"
                  d="M626.742 314.672a2.766 2.766 0 002.822 2.381 2.3 2.3 0 002.523-2.316 2.784 2.784 0 00-2.826-2.39 2.311 2.311 0 00-2.519 2.325z"
              />
              <path
                  data-name="Path 430"
                  d="M618.227 314.582a2.737 2.737 0 002.811 2.375 2.327 2.327 0 002.54-2.321 2.758 2.758 0 00-2.812-2.383 2.338 2.338 0 00-2.539 2.329z"
              />
              <path
                  data-name="Path 431"
                  d="M609.706 314.508a2.711 2.711 0 002.8 2.37 2.348 2.348 0 002.555-2.326 2.729 2.729 0 00-2.8-2.378 2.362 2.362 0 00-2.555 2.334z"
              />
              <path
                  data-name="Path 432"
                  d="M601.176 314.451a2.686 2.686 0 002.784 2.365 2.372 2.372 0 002.572-2.331 2.7 2.7 0 00-2.784-2.373 2.386 2.386 0 00-2.572 2.339z"
              />
              <path
                  data-name="Path 433"
                  d="M592.652 314.408a2.659 2.659 0 002.771 2.36 2.4 2.4 0 002.587-2.336 2.675 2.675 0 00-2.771-2.368 2.412 2.412 0 00-2.587 2.344z"
              />
              <path
                  data-name="Path 434"
                  d="M584.12 314.389a2.634 2.634 0 002.756 2.355 2.423 2.423 0 002.6-2.343 2.65 2.65 0 00-2.757-2.361 2.431 2.431 0 00-2.599 2.349z"
              />
              <path
                  data-name="Path 435"
                  d="M575.584 314.384a2.607 2.607 0 002.742 2.348 2.445 2.445 0 002.619-2.346 2.62 2.62 0 00-2.742-2.356 2.458 2.458 0 00-2.619 2.354z"
              />
              <path
                  data-name="Path 436"
                  d="M379.925 318.934a2.059 2.059 0 002.365 2.224 3.067 3.067 0 002.929-2.465 2.068 2.068 0 00-2.361-2.231 3.086 3.086 0 00-2.933 2.472z"
              />
              <path
                  data-name="Path 437"
                  d="M371.505 319.33a2.037 2.037 0 002.346 2.217 3.1 3.1 0 002.941-2.468 2.045 2.045 0 00-2.343-2.226 3.117 3.117 0 00-2.944 2.477z"
              />
              <path
                  data-name="Path 438"
                  d="M363.096 319.745a2.015 2.015 0 002.326 2.212 3.124 3.124 0 002.953-2.473 2.022 2.022 0 00-2.323-2.221 3.15 3.15 0 00-2.956 2.482z"
              />
              <path
                  data-name="Path 439"
                  d="M354.699 320.172a2 2 0 002.308 2.209 3.16 3.16 0 002.964-2.48 2 2 0 00-2.3-2.214 3.181 3.181 0 00-2.972 2.485z"
              />
              <path
                  data-name="Path 440"
                  d="M346.319 320.617a1.97 1.97 0 002.286 2.2 3.186 3.186 0 002.976-2.483 1.977 1.977 0 00-2.283-2.209 3.211 3.211 0 00-2.979 2.492z"
              />
              <path
                  data-name="Path 441"
                  d="M337.948 321.078a1.95 1.95 0 002.266 2.2 3.223 3.223 0 002.988-2.488 1.957 1.957 0 00-2.263-2.206 3.246 3.246 0 00-2.991 2.494z"
              />
              <path
                  data-name="Path 442"
                  d="M329.592 321.553a1.928 1.928 0 002.246 2.192 3.253 3.253 0 003-2.493 1.935 1.935 0 00-2.243-2.2 3.278 3.278 0 00-3.003 2.501z"
              />
              <path
                  data-name="Path 443"
                  d="M321.251 322.044a1.906 1.906 0 002.226 2.187 3.283 3.283 0 003.01-2.5 1.912 1.912 0 00-2.219-2.2 3.313 3.313 0 00-3.017 2.513z"
              />
              <path
                  data-name="Path 444"
                  d="M312.927 322.55a1.886 1.886 0 002.206 2.182 3.318 3.318 0 003.021-2.5 1.893 1.893 0 00-2.2-2.191 3.348 3.348 0 00-3.027 2.509z"
              />
              <path
                  data-name="Path 445"
                  d="M304.618 323.072a1.865 1.865 0 002.184 2.177 3.349 3.349 0 003.033-2.507 1.87 1.87 0 00-2.177-2.184 3.381 3.381 0 00-3.04 2.514z"
              />
              <path
                  data-name="Path 446"
                  d="M296.327 323.608a1.846 1.846 0 002.162 2.174 3.391 3.391 0 003.045-2.512 1.851 1.851 0 00-2.157-2.181 3.415 3.415 0 00-3.05 2.519z"
              />
              <path
                  data-name="Path 447"
                  d="M288.053 324.16a1.824 1.824 0 002.139 2.169 3.424 3.424 0 003.056-2.517 1.83 1.83 0 00-2.136-2.176 3.45 3.45 0 00-3.059 2.524z"
              />
              <path
                  data-name="Path 448"
                  d="M279.797 324.726c-.267 1.3.684 2.261 2.117 2.164a3.459 3.459 0 003.068-2.522 1.81 1.81 0 00-2.112-2.171 3.489 3.489 0 00-3.073 2.529z"
              />
              <path
                  data-name="Path 449"
                  d="M271.556 325.305a1.783 1.783 0 002.094 2.159 3.491 3.491 0 003.078-2.527 1.788 1.788 0 00-2.089-2.166 3.521 3.521 0 00-3.083 2.534z"
              />
              <path
                  data-name="Path 450"
                  d="M263.337 325.903c-.286 1.3.643 2.258 2.072 2.154a3.525 3.525 0 003.088-2.53 1.766 1.766 0 00-2.065-2.161 3.559 3.559 0 00-3.095 2.537z"
              />
              <path
                  data-name="Path 451"
                  d="M255.136 326.512c-.3 1.3.622 2.256 2.047 2.149a3.565 3.565 0 003.1-2.533 1.748 1.748 0 00-2.044-2.157 3.593 3.593 0 00-3.103 2.541z"
              />
              <path
                  data-name="Path 452"
                  d="M1030.043 331.295a4.742 4.742 0 003.389 2.629c1.293.139 1.867-.812 1.277-2.127a4.79 4.79 0 00-3.394-2.632c-1.284-.139-1.854.817-1.272 2.13z"
              />
              <path
                  data-name="Path 453"
                  d="M1015.093 329.721a4.632 4.632 0 003.371 2.622c1.305.135 1.9-.817 1.339-2.131a4.672 4.672 0 00-3.372-2.627c-1.3-.135-1.895.822-1.338 2.136z"
              />
              <path
                  data-name="Path 454"
                  d="M1007.568 328.948a4.573 4.573 0 003.359 2.617c1.31.134 1.927-.821 1.37-2.132a4.612 4.612 0 00-3.362-2.622c-1.303-.134-1.913.824-1.367 2.137z"
              />
              <path
                  data-name="Path 455"
                  d="M1000.009 328.186a4.525 4.525 0 003.349 2.614c1.317.132 1.947-.824 1.4-2.137a4.56 4.56 0 00-3.354-2.617c-1.303-.129-1.93.827-1.395 2.14z"
              />
              <path
                  data-name="Path 456"
                  d="M992.417 327.433a4.47 4.47 0 003.339 2.61c1.322.13 1.965-.825 1.434-2.139a4.512 4.512 0 00-3.342-2.614c-1.316-.13-1.955.831-1.431 2.143z"
              />
              <path
                  data-name="Path 457"
                  d="M946.21 323.145a4.17 4.17 0 003.275 2.587 1.479 1.479 0 001.613-2.157 4.212 4.212 0 00-3.28-2.592c-1.347-.119-2.063.852-1.608 2.162z"
              />
              <path
                  data-name="Path 458"
                  d="M914.822 320.518a3.985 3.985 0 003.232 2.57 1.549 1.549 0 001.726-2.171 4.028 4.028 0 00-3.239-2.575 1.553 1.553 0 00-1.719 2.176z"
              />
              <path
                  data-name="Path 459"
                  d="M906.909 319.893a3.949 3.949 0 003.223 2.565 1.566 1.566 0 001.751-2.174 3.975 3.975 0 00-3.227-2.57 1.571 1.571 0 00-1.747 2.179z"
              />
              <path
                  data-name="Path 460"
                  d="M898.967 319.28a3.9 3.9 0 003.212 2.562 1.584 1.584 0 001.778-2.177 3.931 3.931 0 00-3.217-2.567 1.588 1.588 0 00-1.773 2.182z"
              />
              <path
                  data-name="Path 461"
                  d="M891.001 318.683a3.862 3.862 0 003.2 2.557 1.6 1.6 0 001.806-2.182 3.9 3.9 0 00-3.207-2.563 1.609 1.609 0 00-1.799 2.188z"
              />
              <path
                  data-name="Path 462"
                  d="M883.01 318.095a3.821 3.821 0 003.19 2.552 1.621 1.621 0 001.831-2.186 3.851 3.851 0 00-3.2-2.558 1.627 1.627 0 00-1.821 2.192z"
              />
              <path
                  data-name="Path 463"
                  d="M866.957 316.962a3.737 3.737 0 003.168 2.543 1.66 1.66 0 001.883-2.194 3.774 3.774 0 00-3.173-2.55 1.666 1.666 0 00-1.878 2.201z"
              />
              <path
                  data-name="Path 464"
                  d="M702.162 308.863a3.035 3.035 0 002.934 2.446 2.081 2.081 0 002.333-2.288 3.058 3.058 0 00-2.938-2.453 2.089 2.089 0 00-2.329 2.295z"
              />
              <path
                  data-name="Path 465"
                  d="M693.766 308.622a3.007 3.007 0 002.921 2.443 2.106 2.106 0 002.353-2.294 3.028 3.028 0 00-2.924-2.448 2.113 2.113 0 00-2.35 2.299z"
              />
              <path
                  data-name="Path 466"
                  d="M660.084 307.827a2.886 2.886 0 002.871 2.42 2.2 2.2 0 002.428-2.313 2.9 2.9 0 00-2.873-2.426 2.208 2.208 0 00-2.426 2.319z"
              />
              <path
                  data-name="Path 467"
                  d="M651.639 307.669a2.857 2.857 0 002.856 2.415 2.221 2.221 0 002.446-2.318 2.876 2.876 0 00-2.858-2.423 2.232 2.232 0 00-2.444 2.326z"
              />
              <path
                  data-name="Path 468"
                  d="M643.193 307.529a2.831 2.831 0 002.844 2.41 2.246 2.246 0 002.465-2.325 2.849 2.849 0 00-2.846-2.416 2.258 2.258 0 00-2.463 2.331z"
              />
              <path
                  data-name="Path 469"
                  d="M634.737 307.405a2.8 2.8 0 002.829 2.4 2.272 2.272 0 002.483-2.329 2.817 2.817 0 00-2.831-2.411 2.281 2.281 0 00-2.481 2.34z"
              />
              <path
                  data-name="Path 470"
                  d="M626.275 307.298a2.771 2.771 0 002.816 2.4 2.292 2.292 0 002.5-2.333 2.788 2.788 0 00-2.816-2.406 2.3 2.3 0 00-2.5 2.339z"
              />
              <path
                  data-name="Path 471"
                  d="M617.807 307.208a2.742 2.742 0 002.8 2.393 2.318 2.318 0 002.518-2.34 2.76 2.76 0 00-2.8-2.4 2.329 2.329 0 00-2.518 2.347z"
              />
              <path
                  data-name="Path 472"
                  d="M609.333 307.134a2.717 2.717 0 002.787 2.388 2.343 2.343 0 002.535-2.344 2.73 2.73 0 00-2.787-2.395 2.356 2.356 0 00-2.535 2.351z"
              />
              <path
                  data-name="Path 473"
                  d="M600.856 307.078a2.687 2.687 0 002.774 2.381 2.368 2.368 0 002.552-2.35 2.7 2.7 0 00-2.774-2.39 2.381 2.381 0 00-2.552 2.359z"
              />
              <path
                  data-name="Path 474"
                  d="M592.376 307.041a2.663 2.663 0 002.759 2.378 2.393 2.393 0 002.568-2.355 2.676 2.676 0 00-2.759-2.385 2.4 2.4 0 00-2.568 2.362z"
              />
              <path
                  data-name="Path 475"
                  d="M583.891 307.018a2.633 2.633 0 002.744 2.371 2.418 2.418 0 002.585-2.359 2.651 2.651 0 00-2.744-2.38 2.43 2.43 0 00-2.585 2.368z"
              />
              <path
                  data-name="Path 476"
                  d="M575.404 307.013a2.607 2.607 0 002.729 2.366 2.443 2.443 0 002.6-2.365 2.624 2.624 0 00-2.729-2.375 2.458 2.458 0 00-2.6 2.374z"
              />
              <path
                  data-name="Path 477"
                  d="M380.851 311.578a2.043 2.043 0 002.339 2.241 3.088 3.088 0 002.928-2.483 2.05 2.05 0 00-2.329-2.248 3.109 3.109 0 00-2.938 2.49z"
              />
              <path
                  data-name="Path 478"
                  d="M372.479 311.975a2.023 2.023 0 002.314 2.236 3.122 3.122 0 002.943-2.488 2.03 2.03 0 00-2.311-2.243 3.144 3.144 0 00-2.946 2.495z"
              />
              <path
                  data-name="Path 479"
                  d="M364.121 312.389a2 2 0 002.294 2.231 3.151 3.151 0 002.955-2.487 2.006 2.006 0 00-2.291-2.238 3.172 3.172 0 00-2.958 2.494z"
              />
              <path
                  data-name="Path 480"
                  d="M355.775 312.818a1.977 1.977 0 002.274 2.226 3.185 3.185 0 002.966-2.5 1.985 1.985 0 00-2.271-2.233 3.207 3.207 0 00-2.969 2.507z"
              />
              <path
                  data-name="Path 481"
                  d="M347.443 313.262a1.953 1.953 0 002.253 2.221 3.219 3.219 0 002.98-2.5 1.963 1.963 0 00-2.251-2.226 3.238 3.238 0 00-2.982 2.505z"
              />
              <path
                  data-name="Path 482"
                  d="M339.129 313.724a1.934 1.934 0 002.233 2.216 3.247 3.247 0 002.99-2.507 1.938 1.938 0 00-2.228-2.221 3.27 3.27 0 00-2.995 2.512z"
              />
              <path
                  data-name="Path 483"
                  d="M330.821 314.2a1.91 1.91 0 002.211 2.209 3.281 3.281 0 003-2.51 1.918 1.918 0 00-2.207-2.216 3.3 3.3 0 00-3.004 2.517z"
              />
              <path
                  data-name="Path 484"
                  d="M322.533 314.691a1.89 1.89 0 002.189 2.206 3.319 3.319 0 003.015-2.517 1.9 1.9 0 00-2.186-2.211 3.338 3.338 0 00-3.018 2.522z"
              />
              <path
                  data-name="Path 485"
                  d="M314.259 315.199a1.869 1.869 0 002.169 2.2 3.348 3.348 0 003.025-2.52 1.873 1.873 0 00-2.162-2.206 3.375 3.375 0 00-3.032 2.526z"
              />
              <path
                  data-name="Path 486"
                  d="M306.002 315.721a1.847 1.847 0 002.147 2.194 3.382 3.382 0 003.036-2.523 1.853 1.853 0 00-2.141-2.2 3.412 3.412 0 00-3.042 2.529z"
              />
              <path
                  data-name="Path 487"
                  d="M297.764 316.256a1.827 1.827 0 002.124 2.191 3.417 3.417 0 003.048-2.53 1.831 1.831 0 00-2.119-2.2 3.443 3.443 0 00-3.053 2.539z"
              />
              <path
                  data-name="Path 488"
                  d="M289.54 316.81a1.807 1.807 0 002.1 2.184 3.452 3.452 0 003.06-2.533 1.81 1.81 0 00-2.1-2.191 3.483 3.483 0 00-3.06 2.54z"
              />
              <path
                  data-name="Path 489"
                  d="M281.337 317.375a1.785 1.785 0 002.079 2.181 3.489 3.489 0 003.071-2.537 1.791 1.791 0 00-2.074-2.187 3.521 3.521 0 00-3.076 2.543z"
              />
              <path
                  data-name="Path 490"
                  d="M273.152 317.956a1.764 1.764 0 002.055 2.176 3.526 3.526 0 003.083-2.542 1.771 1.771 0 00-2.05-2.182 3.556 3.556 0 00-3.088 2.548z"
              />
              <path
                  data-name="Path 491"
                  d="M264.986 318.554a1.745 1.745 0 002.032 2.171 3.566 3.566 0 003.095-2.547 1.75 1.75 0 00-2.027-2.177 3.594 3.594 0 00-3.1 2.553z"
              />
              <path
                  data-name="Path 492"
                  d="M256.839 319.163a1.724 1.724 0 002.009 2.166 3.6 3.6 0 003.107-2.552 1.73 1.73 0 00-2-2.172 3.635 3.635 0 00-3.116 2.558z"
              />
              <path
                  data-name="Path 493"
                  d="M248.714 319.786a1.7 1.7 0 001.984 2.162 3.638 3.638 0 003.117-2.557 1.709 1.709 0 00-1.979-2.167 3.668 3.668 0 00-3.122 2.562z"
              />
              <path
                  data-name="Path 494"
                  d="M1077.434 329.615a5.209 5.209 0 003.459 2.66c1.235.149 1.683-.8 1-2.124a5.266 5.266 0 00-3.461-2.664c-1.227-.146-1.675.808-.998 2.128z"
              />
              <path
                  data-name="Path 495"
                  d="M1055.928 327.096a5.019 5.019 0 003.429 2.652c1.253.144 1.75-.809 1.1-2.129a5.069 5.069 0 00-3.432-2.655c-1.242-.144-1.742.818-1.097 2.132z"
              />
              <path
                  data-name="Path 496"
                  d="M1034.078 324.649a4.852 4.852 0 003.4 2.64c1.277.14 1.815-.814 1.2-2.136a4.9 4.9 0 00-3.4-2.645c-1.269-.138-1.804.821-1.2 2.141z"
              />
              <path
                  data-name="Path 497"
                  d="M1026.724 323.849a4.781 4.781 0 003.391 2.639c1.282.139 1.835-.817 1.233-2.137a4.832 4.832 0 00-3.394-2.642c-1.274-.139-1.823.822-1.23 2.14z"
              />
              <path
                  data-name="Path 498"
                  d="M943.59 315.714a4.212 4.212 0 003.279 2.6c1.342.119 2.047-.852 1.569-2.171a4.247 4.247 0 00-3.284-2.6c-1.335-.124-2.03.852-1.564 2.171z"
              />
              <path
                  data-name="Path 499"
                  d="M880.864 310.666a3.853 3.853 0 003.2 2.567 1.607 1.607 0 001.79-2.2 3.883 3.883 0 00-3.2-2.572 1.611 1.611 0 00-1.79 2.205z"
              />
              <path
                  data-name="Path 500"
                  d="M12.015 223.51c-.869 1.335-.764 2.266.237 2.08a6.6 6.6 0 003.379-2.756c.866-1.335.757-2.266-.239-2.079a6.629 6.629 0 00-3.377 2.755z"
              />
              <path
                  data-name="Path 501"
                  d="M6.293 224.589c-.876 1.332-.78 2.261.214 2.076a6.655 6.655 0 003.376-2.754c.871-1.335.772-2.264-.217-2.075a6.682 6.682 0 00-3.373 2.753z"
              />
              <path
                  data-name="Path 502"
                  d="M.607 225.67c-.879 1.332-.794 2.259.192 2.072a6.709 6.709 0 003.374-2.752c.876-1.334.787-2.261-.2-2.072a6.737 6.737 0 00-3.366 2.752z"
              />
              <path
                  data-name="Path 503"
                  d="M34.249 212.736c-.854 1.337-.724 2.271.292 2.087a6.461 6.461 0 003.377-2.759c.851-1.339.717-2.274-.294-2.089a6.518 6.518 0 00-3.375 2.761z"
              />
              <path
                  data-name="Path 504"
                  d="M28.439 213.807c-.861 1.337-.74 2.271.269 2.084a6.52 6.52 0 003.376-2.757c.856-1.339.734-2.273-.271-2.085a6.557 6.557 0 00-3.374 2.758z"
              />
              <path
                  data-name="Path 505"
                  d="M22.665 214.883c-.866 1.337-.755 2.268.246 2.08a6.566 6.566 0 003.376-2.757c.861-1.337.749-2.268-.249-2.081a6.6 6.6 0 00-3.373 2.758z"
              />
              <path
                  data-name="Path 506"
                  d="M16.93 215.964c-.871 1.335-.77 2.266.226 2.077a6.625 6.625 0 003.372-2.756c.866-1.337.764-2.266-.229-2.079a6.674 6.674 0 00-3.369 2.758z"
              />
              <path
                  data-name="Path 507"
                  d="M39.08 205.171c-.856 1.337-.732 2.271.277 2.086a6.506 6.506 0 003.372-2.761c.852-1.34.725-2.273-.281-2.086a6.53 6.53 0 00-3.368 2.761z"
              />
              <path
                  data-name="Path 508"
                  d="M33.3 206.248c-.861 1.337-.747 2.269.256 2.082a6.547 6.547 0 003.371-2.761c.857-1.337.74-2.269-.259-2.082a6.6 6.6 0 00-3.368 2.761z"
              />
              <path
                  data-name="Path 509"
                  d="M27.557 207.331c-.866 1.337-.762 2.266.236 2.079a6.605 6.605 0 003.367-2.759c.862-1.337.755-2.268-.237-2.079a6.644 6.644 0 00-3.366 2.759z"
              />
              <path
                  data-name="Path 510"
                  d="M21.851 208.421c-.871 1.334-.777 2.263.214 2.074a6.648 6.648 0 003.366-2.759c.867-1.334.769-2.264-.217-2.074a6.686 6.686 0 00-3.363 2.759z"
              />
              <path
                  data-name="Path 511"
                  d="M16.183 209.514c-.876 1.334-.79 2.261.194 2.07a6.7 6.7 0 003.362-2.757c.872-1.334.784-2.261-.2-2.07a6.739 6.739 0 00-3.356 2.757z"
              />
              <path
                  data-name="Path 512"
                  d="M4.965 211.714c-.886 1.33-.817 2.253.152 2.062a6.789 6.789 0 003.354-2.756c.882-1.332.81-2.254-.155-2.064a6.818 6.818 0 00-3.351 2.758z"
              />
              <path
                  data-name="Path 513"
                  d="M21.136 201.98c-.877 1.332-.8 2.258.184 2.067a6.73 6.73 0 003.356-2.759c.872-1.334.789-2.261-.187-2.069a6.758 6.758 0 00-3.353 2.761z"
              />
              <path
                  data-name="Path 514"
                  d="M701.214 301.436a3.052 3.052 0 002.931 2.463 2.07 2.07 0 002.3-2.3 3.072 3.072 0 00-2.934-2.468 2.076 2.076 0 00-2.297 2.305z"
              />
              <path
                  data-name="Path 515"
                  d="M692.871 301.196a3.021 3.021 0 002.918 2.458 2.094 2.094 0 002.324-2.309 3.042 3.042 0 00-2.921-2.463 2.1 2.1 0 00-2.321 2.314z"
              />
              <path
                  data-name="Path 516"
                  d="M676.151 300.765a2.958 2.958 0 002.889 2.448 2.139 2.139 0 002.365-2.319 2.976 2.976 0 00-2.893-2.453 2.148 2.148 0 00-2.361 2.324z"
              />
              <path
                  data-name="Path 517"
                  d="M667.777 300.573a2.927 2.927 0 002.876 2.443 2.166 2.166 0 002.385-2.324 2.948 2.948 0 00-2.879-2.45 2.174 2.174 0 00-2.382 2.331z"
              />
              <path
                  data-name="Path 518"
                  d="M659.394 300.4a2.894 2.894 0 002.863 2.436 2.186 2.186 0 002.4-2.329 2.913 2.913 0 00-2.864-2.443 2.2 2.2 0 00-2.399 2.336z"
              />
              <path
                  data-name="Path 519"
                  d="M651.003 300.243a2.865 2.865 0 002.849 2.431 2.209 2.209 0 002.421-2.335 2.883 2.883 0 00-2.849-2.438 2.222 2.222 0 00-2.421 2.342z"
              />
              <path
                  data-name="Path 520"
                  d="M642.605 300.102a2.836 2.836 0 002.834 2.426 2.237 2.237 0 002.441-2.339 2.852 2.852 0 00-2.836-2.433 2.246 2.246 0 00-2.439 2.346z"
              />
              <path
                  data-name="Path 521"
                  d="M634.199 299.979a2.806 2.806 0 002.821 2.42 2.259 2.259 0 002.46-2.345 2.822 2.822 0 00-2.822-2.426 2.27 2.27 0 00-2.459 2.351z"
              />
              <path
                  data-name="Path 522"
                  d="M625.788 299.872a2.776 2.776 0 002.8 2.416 2.288 2.288 0 002.48-2.351 2.8 2.8 0 00-2.807-2.421 2.3 2.3 0 00-2.473 2.356z"
              />
              <path
                  data-name="Path 523"
                  d="M617.37 299.782a2.75 2.75 0 002.791 2.411 2.314 2.314 0 002.5-2.356 2.765 2.765 0 00-2.792-2.416 2.322 2.322 0 00-2.499 2.361z"
              />
              <path
                  data-name="Path 524"
                  d="M608.947 299.71a2.721 2.721 0 002.776 2.4 2.338 2.338 0 002.515-2.361 2.737 2.737 0 00-2.777-2.411 2.348 2.348 0 00-2.514 2.372z"
              />
              <path
                  data-name="Path 525"
                  d="M600.52 299.653a2.69 2.69 0 002.761 2.4 2.361 2.361 0 002.533-2.366 2.7 2.7 0 00-2.762-2.4 2.372 2.372 0 00-2.532 2.366z"
              />
              <path
                  data-name="Path 526"
                  d="M592.09 299.615a2.664 2.664 0 002.746 2.395 2.389 2.389 0 002.55-2.371 2.68 2.68 0 00-2.747-2.4 2.4 2.4 0 00-2.549 2.376z"
              />
              <path
                  data-name="Path 527"
                  d="M583.654 299.593a2.637 2.637 0 002.732 2.39 2.415 2.415 0 002.567-2.378 2.65 2.65 0 00-2.731-2.395 2.427 2.427 0 00-2.568 2.383z"
              />
              <path
                  data-name="Path 528"
                  d="M575.218 299.59a2.607 2.607 0 002.714 2.383 2.442 2.442 0 002.585-2.383 2.621 2.621 0 00-2.716-2.39 2.452 2.452 0 00-2.583 2.39z"
              />
              <path
                  data-name="Path 529"
                  d="M381.824 304.168a2.028 2.028 0 002.3 2.258 3.1 3.1 0 002.928-2.5 2.036 2.036 0 00-2.3-2.264 3.13 3.13 0 00-2.928 2.506z"
              />
              <path
                  data-name="Path 530"
                  d="M373.504 304.568a2.006 2.006 0 002.284 2.251 3.138 3.138 0 002.939-2.5 2.012 2.012 0 00-2.279-2.258 3.162 3.162 0 00-2.944 2.507z"
              />
              <path
                  data-name="Path 531"
                  d="M365.201 304.982a1.984 1.984 0 002.263 2.246 3.175 3.175 0 002.954-2.508 1.991 1.991 0 00-2.259-2.253 3.2 3.2 0 00-2.958 2.515z"
              />
              <path
                  data-name="Path 532"
                  d="M356.903 305.412a1.962 1.962 0 002.243 2.241 3.207 3.207 0 002.966-2.513 1.969 1.969 0 00-2.238-2.248 3.233 3.233 0 00-2.971 2.52z"
              />
              <path
                  data-name="Path 533"
                  d="M348.623 305.858a1.939 1.939 0 002.221 2.236 3.237 3.237 0 002.978-2.518 1.946 1.946 0 00-2.216-2.243 3.264 3.264 0 00-2.983 2.525z"
              />
              <path
                  data-name="Path 534"
                  d="M340.358 306.319a1.917 1.917 0 002.2 2.231 3.274 3.274 0 002.991-2.523 1.924 1.924 0 00-2.2-2.236 3.3 3.3 0 00-2.991 2.528z"
              />
              <path
                  data-name="Path 535"
                  d="M332.108 306.796a1.9 1.9 0 002.177 2.226 3.309 3.309 0 003-2.527 1.9 1.9 0 00-2.174-2.231 3.333 3.333 0 00-3.003 2.532z"
              />
              <path
                  data-name="Path 536"
                  d="M323.871 307.288a1.874 1.874 0 002.156 2.221 3.342 3.342 0 003.015-2.532 1.88 1.88 0 00-2.151-2.228 3.37 3.37 0 00-3.02 2.539z"
              />
              <path
                  data-name="Path 537"
                  d="M315.651 307.796a1.851 1.851 0 002.132 2.214 3.374 3.374 0 003.028-2.535 1.856 1.856 0 00-2.127-2.221 3.406 3.406 0 00-3.033 2.542z"
              />
              <path
                  data-name="Path 538"
                  d="M307.45 308.319a1.829 1.829 0 002.109 2.209 3.409 3.409 0 003.04-2.54 1.835 1.835 0 00-2.1-2.216 3.438 3.438 0 00-3.049 2.547z"
              />
              <path
                  data-name="Path 539"
                  d="M299.263 308.856a1.81 1.81 0 002.087 2.206 3.451 3.451 0 003.053-2.545 1.815 1.815 0 00-2.084-2.211 3.477 3.477 0 00-3.056 2.55z"
              />
              <path
                  data-name="Path 540"
                  d="M291.094 309.412a1.789 1.789 0 002.064 2.2 3.488 3.488 0 003.065-2.55 1.793 1.793 0 00-2.059-2.206 3.514 3.514 0 00-3.07 2.556z"
              />
              <path
                  data-name="Path 541"
                  d="M282.945 309.975a1.768 1.768 0 002.04 2.2 3.521 3.521 0 003.076-2.553 1.773 1.773 0 00-2.035-2.2 3.551 3.551 0 00-3.081 2.553z"
              />
              <path
                  data-name="Path 542"
                  d="M274.815 310.557a1.747 1.747 0 002.017 2.191 3.556 3.556 0 003.086-2.557 1.753 1.753 0 00-2.012-2.2 3.592 3.592 0 00-3.091 2.566z"
              />
              <path
                  data-name="Path 543"
                  d="M266.704 311.153a1.727 1.727 0 001.994 2.186 3.6 3.6 0 003.1-2.562 1.732 1.732 0 00-1.987-2.192 3.63 3.63 0 00-3.107 2.568z"
              />
              <path
                  data-name="Path 544"
                  d="M258.613 311.764a1.708 1.708 0 001.969 2.187 3.637 3.637 0 003.11-2.567 1.711 1.711 0 00-1.963-2.187 3.665 3.665 0 00-3.116 2.567z"
              />
              <path
                  data-name="Path 545"
                  d="M250.54 312.388a1.689 1.689 0 001.945 2.177 3.678 3.678 0 003.122-2.572 1.693 1.693 0 00-1.938-2.182 3.709 3.709 0 00-3.129 2.577z"
              />
              <path
                  data-name="Path 546"
                  d="M242.495 313.026a1.668 1.668 0 001.918 2.172 3.716 3.716 0 003.133-2.575 1.673 1.673 0 00-1.913-2.179 3.747 3.747 0 00-3.138 2.582z"
              />
              <path
                  data-name="Path 547"
                  d="M1059.421 320.445a5.127 5.127 0 003.436 2.664c1.237.145 1.7-.81 1.029-2.137a5.176 5.176 0 00-3.439-2.665c-1.226-.147-1.687.813-1.026 2.138z"
              />
              <path
                  data-name="Path 548"
                  d="M1045.1 318.792a5.015 5.015 0 003.419 2.657c1.252.142 1.741-.815 1.095-2.141a5.049 5.049 0 00-3.421-2.66c-1.241-.144-1.729.819-1.093 2.144z"
              />
              <path
                  data-name="Path 549"
                  d="M1037.883 317.977a4.95 4.95 0 003.411 2.654c1.257.14 1.761-.817 1.128-2.142a5 5 0 00-3.412-2.659c-1.249-.141-1.752.822-1.127 2.147z"
              />
              <path
                  data-name="Path 550"
                  d="M1030.632 317.169a4.89 4.89 0 003.4 2.652c1.262.139 1.785-.82 1.16-2.146a4.935 4.935 0 00-3.4-2.655c-1.26-.138-1.777.824-1.16 2.149z"
              />
              <path
                  data-name="Path 551"
                  d="M1023.338 316.368a4.833 4.833 0 003.392 2.647c1.268.139 1.807-.821 1.192-2.147a4.877 4.877 0 00-3.392-2.652c-1.264-.133-1.795.83-1.192 2.152z"
              />
              <path
                  data-name="Path 552"
                  d="M1016.012 315.583a4.773 4.773 0 003.382 2.644c1.275.137 1.826-.824 1.223-2.149a4.814 4.814 0 00-3.384-2.649c-1.263-.137-1.814.829-1.221 2.154z"
              />
              <path
                  data-name="Path 553"
                  d="M1008.651 314.803a4.72 4.72 0 003.37 2.642c1.282.135 1.848-.829 1.257-2.152a4.764 4.764 0 00-3.374-2.647c-1.273-.134-1.834.832-1.253 2.157z"
              />
              <path
                  data-name="Path 554"
                  d="M956.174 309.624a4.355 4.355 0 003.3 2.619c1.322.122 1.98-.851 1.471-2.176a4.392 4.392 0 00-3.309-2.622c-1.31-.123-1.968.854-1.462 2.179z"
              />
              <path
                  data-name="Path 555"
                  d="M917.756 306.258a4.113 4.113 0 003.252 2.6c1.345.112 2.07-.867 1.613-2.192a4.15 4.15 0 00-3.255-2.605c-1.337-.114-2.057.871-1.61 2.197z"
              />
              <path
                  data-name="Path 556"
                  d="M909.991 305.619a4.065 4.065 0 003.24 2.6 1.52 1.52 0 001.641-2.2 4.1 4.1 0 00-3.245-2.6 1.526 1.526 0 00-1.636 2.2z"
              />
              <path
                  data-name="Path 557"
                  d="M708.508 294.219a3.1 3.1 0 002.939 2.483 2.033 2.033 0 002.254-2.314 3.121 3.121 0 00-2.943-2.488 2.041 2.041 0 00-2.25 2.319z"
              />
              <path
                  data-name="Path 558"
                  d="M667.007 293.099a2.937 2.937 0 002.869 2.458 2.151 2.151 0 002.356-2.34 2.951 2.951 0 00-2.871-2.463 2.16 2.16 0 00-2.354 2.345z"
              />
              <path
                  data-name="Path 559"
                  d="M658.677 292.926a2.9 2.9 0 002.854 2.451 2.175 2.175 0 002.378-2.343 2.921 2.921 0 00-2.856-2.458 2.185 2.185 0 00-2.376 2.35z"
              />
              <path
                  data-name="Path 560"
                  d="M650.339 292.768a2.875 2.875 0 002.841 2.447 2.2 2.2 0 002.4-2.35 2.892 2.892 0 00-2.842-2.453 2.213 2.213 0 00-2.399 2.356z"
              />
              <path
                  data-name="Path 561"
                  d="M641.993 292.628a2.843 2.843 0 002.826 2.441 2.227 2.227 0 002.418-2.355 2.862 2.862 0 00-2.827-2.448 2.238 2.238 0 00-2.417 2.362z"
              />
              <path
                  data-name="Path 562"
                  d="M633.641 292.502a2.81 2.81 0 002.811 2.436 2.25 2.25 0 002.436-2.36 2.829 2.829 0 00-2.812-2.443 2.262 2.262 0 00-2.435 2.367z"
              />
              <path
                  data-name="Path 563"
                  d="M625.282 292.399a2.78 2.78 0 002.794 2.43 2.278 2.278 0 002.456-2.366 2.8 2.8 0 00-2.8-2.436 2.289 2.289 0 00-2.45 2.372z"
              />
              <path
                  data-name="Path 564"
                  d="M616.917 292.309a2.751 2.751 0 002.781 2.425 2.3 2.3 0 002.475-2.371 2.77 2.77 0 00-2.782-2.431 2.317 2.317 0 00-2.474 2.377z"
              />
              <path
                  data-name="Path 565"
                  d="M608.546 292.237a2.725 2.725 0 002.766 2.42 2.332 2.332 0 002.493-2.376 2.737 2.737 0 00-2.766-2.426 2.341 2.341 0 00-2.493 2.382z"
              />
              <path
                  data-name="Path 566"
                  d="M600.172 292.18a2.692 2.692 0 002.749 2.415 2.355 2.355 0 002.512-2.381 2.708 2.708 0 00-2.749-2.421 2.368 2.368 0 00-2.512 2.387z"
              />
              <path
                  data-name="Path 567"
                  d="M591.794 292.146a2.663 2.663 0 002.732 2.41 2.382 2.382 0 002.53-2.388 2.679 2.679 0 00-2.732-2.415 2.4 2.4 0 00-2.53 2.393z"
              />
              <path
                  data-name="Path 568"
                  d="M583.411 292.12a2.635 2.635 0 002.717 2.4 2.408 2.408 0 002.548-2.393 2.65 2.65 0 00-2.717-2.41 2.421 2.421 0 00-2.548 2.403z"
              />
              <path
                  data-name="Path 569"
                  d="M391.118 296.33a2.038 2.038 0 002.294 2.276 3.091 3.091 0 002.911-2.508 2.044 2.044 0 00-2.289-2.284 3.116 3.116 0 00-2.916 2.516z"
              />
              <path
                  data-name="Path 570"
                  d="M382.842 296.712a2.012 2.012 0 002.273 2.271 3.125 3.125 0 002.924-2.513 2.02 2.02 0 00-2.268-2.278 3.149 3.149 0 00-2.929 2.52z"
              />
              <path
                  data-name="Path 571"
                  d="M374.577 297.111a1.989 1.989 0 002.251 2.266 3.157 3.157 0 002.938-2.518 2 2 0 00-2.248-2.273 3.18 3.18 0 00-2.941 2.525z"
              />
              <path
                  data-name="Path 572"
                  d="M366.323 297.526a1.967 1.967 0 002.229 2.261 3.193 3.193 0 002.953-2.523 1.973 1.973 0 00-2.226-2.266 3.215 3.215 0 00-2.956 2.528z"
              />
              <path
                  data-name="Path 573"
                  d="M358.083 297.957a1.945 1.945 0 002.209 2.256 3.228 3.228 0 002.965-2.528 1.95 1.95 0 00-2.2-2.261 3.252 3.252 0 00-2.974 2.533z"
              />
              <path
                  data-name="Path 574"
                  d="M349.858 298.4a1.92 1.92 0 002.186 2.249 3.26 3.26 0 002.978-2.532 1.93 1.93 0 00-2.182-2.258 3.285 3.285 0 00-2.982 2.541z"
              />
              <path
                  data-name="Path 575"
                  d="M341.645 298.866a1.9 1.9 0 002.166 2.246 3.3 3.3 0 002.991-2.538 1.907 1.907 0 00-2.161-2.251 3.326 3.326 0 00-2.996 2.543z"
              />
              <path
                  data-name="Path 576"
                  d="M333.446 299.344a1.88 1.88 0 002.142 2.241 3.335 3.335 0 003-2.542 1.884 1.884 0 00-2.137-2.246 3.359 3.359 0 00-3.005 2.547z"
              />
              <path
                  data-name="Path 577"
                  d="M325.267 299.839a1.856 1.856 0 002.119 2.234 3.371 3.371 0 003.018-2.547 1.863 1.863 0 00-2.116-2.241 3.4 3.4 0 00-3.021 2.554z"
              />
              <path
                  data-name="Path 578"
                  d="M317.103 300.347a1.835 1.835 0 002.1 2.229 3.405 3.405 0 003.03-2.55 1.842 1.842 0 00-2.094-2.236 3.432 3.432 0 00-3.036 2.557z"
              />
              <path
                  data-name="Path 579"
                  d="M308.955 300.87a1.814 1.814 0 002.074 2.224 3.437 3.437 0 003.041-2.555 1.82 1.82 0 00-2.069-2.231 3.47 3.47 0 00-3.046 2.562z"
              />
              <path
                  data-name="Path 580"
                  d="M300.825 301.408a1.793 1.793 0 002.049 2.221 3.48 3.48 0 003.055-2.56 1.8 1.8 0 00-2.045-2.226 3.505 3.505 0 00-3.059 2.565z"
              />
              <path
                  data-name="Path 581"
                  d="M292.712 301.963a1.772 1.772 0 002.027 2.214 3.517 3.517 0 003.066-2.563 1.778 1.778 0 00-2.025-2.221 3.544 3.544 0 00-3.068 2.57z"
              />
              <path
                  data-name="Path 582"
                  d="M284.62 302.531a1.751 1.751 0 002 2.209 3.554 3.554 0 003.078-2.568 1.757 1.757 0 00-2-2.216 3.58 3.58 0 00-3.078 2.575z"
              />
              <path
                  data-name="Path 583"
                  d="M276.544 303.112a1.731 1.731 0 001.979 2.206 3.594 3.594 0 003.09-2.573 1.736 1.736 0 00-1.974-2.211 3.621 3.621 0 00-3.095 2.578z"
              />
              <path
                  data-name="Path 584"
                  d="M268.489 303.712a1.712 1.712 0 001.955 2.2 3.635 3.635 0 003.1-2.577 1.717 1.717 0 00-1.95-2.206 3.665 3.665 0 00-3.105 2.583z"
              />
              <path
                  data-name="Path 585"
                  d="M260.456 304.321a1.691 1.691 0 001.928 2.2 3.673 3.673 0 003.115-2.582 1.7 1.7 0 00-1.925-2.2 3.7 3.7 0 00-3.118 2.582z"
              />
              <path
                  data-name="Path 586"
                  d="M252.441 304.946a1.672 1.672 0 001.905 2.191 3.714 3.714 0 003.127-2.585 1.676 1.676 0 00-1.9-2.2 3.744 3.744 0 00-3.132 2.594z"
              />
              <path
                  data-name="Path 587"
                  d="M244.45 305.584a1.651 1.651 0 001.88 2.186 3.749 3.749 0 003.137-2.589 1.657 1.657 0 00-1.875-2.192 3.787 3.787 0 00-3.142 2.595z"
              />
              <path
                  data-name="Path 588"
                  d="M1055.652 312.937a5.165 5.165 0 003.434 2.67c1.227.145 1.669-.814.991-2.144a5.217 5.217 0 00-3.436-2.674c-1.217-.143-1.66.82-.989 2.148z"
              />
              <path
                  data-name="Path 589"
                  d="M1034.308 310.47a4.99 4.99 0 003.409 2.662c1.247.14 1.733-.822 1.088-2.152a5.033 5.033 0 00-3.411-2.665c-1.234-.141-1.722.823-1.086 2.155z"
              />
              <path
                  data-name="Path 590"
                  d="M1027.118 309.663a4.933 4.933 0 003.4 2.66c1.253.139 1.755-.824 1.121-2.154a4.978 4.978 0 00-3.4-2.664c-1.245-.134-1.746.828-1.121 2.158z"
              />
              <path
                  data-name="Path 591"
                  d="M1019.892 308.866a4.875 4.875 0 003.391 2.657c1.26.137 1.773-.827 1.153-2.157a4.921 4.921 0 00-3.394-2.66c-1.25-.138-1.765.83-1.15 2.16z"
              />
              <path
                  data-name="Path 592"
                  d="M1012.63 308.077a4.82 4.82 0 003.378 2.655c1.265.137 1.8-.829 1.185-2.159a4.855 4.855 0 00-3.385-2.659c-1.253-.138-1.78.833-1.178 2.163z"
              />
              <path
                  data-name="Path 593"
                  d="M1005.33 307.297a4.765 4.765 0 003.372 2.652c1.272.135 1.817-.834 1.217-2.162a4.807 4.807 0 00-3.375-2.655c-1.264-.136-1.805.836-1.214 2.165z"
              />
              <path
                  data-name="Path 594"
                  d="M922.86 299.399a4.2 4.2 0 003.265 2.615c1.332.115 2.025-.871 1.544-2.2a4.239 4.239 0 00-3.269-2.62c-1.323-.114-2.013.874-1.54 2.205z"
              />
              <path
                  data-name="Path 595"
                  d="M915.18 298.75a4.151 4.151 0 003.255 2.61c1.337.114 2.042-.872 1.572-2.2a4.186 4.186 0 00-3.259-2.615c-1.331-.117-2.031.871-1.568 2.205z"
              />
              <path
                  data-name="Path 596"
                  d="M899.736 297.486a4.062 4.062 0 003.234 2.6c1.345.109 2.077-.881 1.628-2.212a4.094 4.094 0 00-3.237-2.609c-1.341-.106-2.066.889-1.625 2.221z"
              />
              <path
                  data-name="Path 597"
                  d="M884.179 296.269a3.971 3.971 0 003.212 2.6 1.561 1.561 0 001.684-2.221 4.009 4.009 0 00-3.215-2.6 1.566 1.566 0 00-1.681 2.221z"
              />
              <path
                  data-name="Path 598"
                  d="M674.473 285.771a2.975 2.975 0 002.876 2.477 2.116 2.116 0 002.309-2.345 3 3 0 00-2.878-2.482 2.124 2.124 0 00-2.307 2.35z"
              />
              <path
                  data-name="Path 599"
                  d="M666.208 285.581a2.943 2.943 0 002.861 2.471 2.141 2.141 0 002.331-2.353 2.965 2.965 0 00-2.864-2.477 2.149 2.149 0 00-2.328 2.359z"
              />
              <path
                  data-name="Path 600"
                  d="M657.934 285.407a2.913 2.913 0 002.846 2.467 2.165 2.165 0 002.351-2.36 2.927 2.927 0 00-2.848-2.47 2.175 2.175 0 00-2.349 2.363z"
              />
              <path
                  data-name="Path 601"
                  d="M649.65 285.25a2.881 2.881 0 002.831 2.46 2.19 2.19 0 002.373-2.363 2.9 2.9 0 00-2.832-2.466 2.2 2.2 0 00-2.372 2.369z"
              />
              <path
                  data-name="Path 602"
                  d="M641.359 285.11a2.847 2.847 0 002.816 2.455 2.216 2.216 0 002.393-2.368 2.865 2.865 0 00-2.818-2.462 2.228 2.228 0 00-2.391 2.375z"
              />
              <path
                  data-name="Path 603"
                  d="M633.063 284.987a2.814 2.814 0 002.8 2.45 2.242 2.242 0 002.413-2.375 2.833 2.833 0 00-2.8-2.455 2.253 2.253 0 00-2.413 2.38z"
              />
              <path
                  data-name="Path 604"
                  d="M624.759 284.88a2.783 2.783 0 002.782 2.445 2.266 2.266 0 002.433-2.38 2.8 2.8 0 00-2.784-2.45 2.279 2.279 0 00-2.431 2.385z"
              />
              <path
                  data-name="Path 605"
                  d="M616.449 284.794a2.752 2.752 0 002.767 2.438 2.294 2.294 0 002.451-2.385 2.769 2.769 0 00-2.767-2.445 2.305 2.305 0 00-2.451 2.392z"
              />
              <path
                  data-name="Path 606"
                  d="M608.133 284.719a2.723 2.723 0 002.751 2.433 2.321 2.321 0 002.471-2.39 2.738 2.738 0 00-2.752-2.44 2.333 2.333 0 00-2.47 2.397z"
              />
              <path
                  data-name="Path 607"
                  d="M599.813 284.662a2.692 2.692 0 002.736 2.428 2.345 2.345 0 002.49-2.395 2.705 2.705 0 00-2.736-2.433 2.361 2.361 0 00-2.49 2.4z"
              />
              <path
                  data-name="Path 608"
                  d="M591.485 284.622a2.661 2.661 0 002.719 2.423 2.374 2.374 0 002.51-2.4 2.678 2.678 0 00-2.721-2.428 2.386 2.386 0 00-2.508 2.405z"
              />
              <path
                  data-name="Path 609"
                  d="M583.162 284.603a2.634 2.634 0 002.7 2.416 2.4 2.4 0 002.53-2.4 2.648 2.648 0 00-2.7-2.423 2.417 2.417 0 00-2.53 2.407z"
              />
              <path
                  data-name="Path 610"
                  d="M400.361 288.459a2.045 2.045 0 002.284 2.3 3.074 3.074 0 002.893-2.518 2.055 2.055 0 00-2.283-2.3 3.1 3.1 0 00-2.894 2.518z"
              />
              <path
                  data-name="Path 611"
                  d="M392.126 288.827a2.023 2.023 0 002.264 2.291 3.109 3.109 0 002.906-2.523 2.028 2.028 0 00-2.259-2.3 3.128 3.128 0 00-2.911 2.532z"
              />
              <path
                  data-name="Path 612"
                  d="M383.904 289.211a2 2 0 002.241 2.284 3.142 3.142 0 002.923-2.528 2.006 2.006 0 00-2.239-2.289 3.164 3.164 0 00-2.925 2.533z"
              />
              <path
                  data-name="Path 613"
                  d="M375.694 289.61a1.976 1.976 0 002.219 2.281 3.179 3.179 0 002.936-2.533 1.982 1.982 0 00-2.218-2.284 3.2 3.2 0 00-2.937 2.536z"
              />
              <path
                  data-name="Path 614"
                  d="M367.496 290.028a1.952 1.952 0 002.2 2.274 3.213 3.213 0 002.949-2.538 1.958 1.958 0 00-2.194-2.279 3.235 3.235 0 00-2.955 2.543z"
              />
              <path
                  data-name="Path 615"
                  d="M359.311 290.459a1.93 1.93 0 002.176 2.269 3.248 3.248 0 002.964-2.542 1.936 1.936 0 00-2.172-2.274 3.271 3.271 0 00-2.968 2.547z"
              />
              <path
                  data-name="Path 616"
                  d="M351.141 290.906a1.907 1.907 0 002.154 2.264 3.283 3.283 0 002.976-2.547 1.913 1.913 0 00-2.149-2.269 3.309 3.309 0 00-2.981 2.552z"
              />
              <path
                  data-name="Path 617"
                  d="M342.986 291.368a1.884 1.884 0 002.129 2.259 3.321 3.321 0 002.991-2.552 1.892 1.892 0 00-2.13-2.262 3.348 3.348 0 00-2.99 2.555z"
              />
              <path
                  data-name="Path 618"
                  d="M334.844 291.85a1.861 1.861 0 002.107 2.253 3.353 3.353 0 003-2.555 1.87 1.87 0 00-2.1-2.259 3.385 3.385 0 00-3.007 2.561z"
              />
              <path
                  data-name="Path 619"
                  d="M326.72 292.344a1.84 1.84 0 002.084 2.248 3.392 3.392 0 003.018-2.56 1.846 1.846 0 00-2.081-2.253 3.417 3.417 0 00-3.021 2.565z"
              />
              <path
                  data-name="Path 620"
                  d="M318.612 292.858a1.818 1.818 0 002.06 2.242 3.427 3.427 0 003.03-2.565 1.824 1.824 0 00-2.057-2.248 3.459 3.459 0 00-3.033 2.571z"
              />
              <path
                  data-name="Path 621"
                  d="M310.521 293.378a1.8 1.8 0 002.037 2.238 3.47 3.47 0 003.043-2.568 1.805 1.805 0 00-2.034-2.244 3.5 3.5 0 00-3.046 2.574z"
              />
              <path
                  data-name="Path 622"
                  d="M302.446 293.918a1.777 1.777 0 002.014 2.232 3.508 3.508 0 003.056-2.573 1.782 1.782 0 00-2.01-2.238 3.532 3.532 0 00-3.06 2.579z"
              />
              <path
                  data-name="Path 623"
                  d="M294.392 294.473a1.755 1.755 0 001.989 2.228 3.548 3.548 0 003.072-2.579 1.761 1.761 0 00-1.985-2.233 3.57 3.57 0 00-3.076 2.584z"
              />
              <path
                  data-name="Path 624"
                  d="M286.353 295.042a1.735 1.735 0 001.965 2.223 3.581 3.581 0 003.08-2.582 1.74 1.74 0 00-1.96-2.228 3.615 3.615 0 00-3.085 2.587z"
              />
              <path
                  data-name="Path 625"
                  d="M278.337 295.624a1.715 1.715 0 001.94 2.219 3.622 3.622 0 003.092-2.587 1.719 1.719 0 00-1.935-2.223 3.652 3.652 0 00-3.097 2.591z"
              />
              <path
                  data-name="Path 626"
                  d="M270.34 296.222a1.7 1.7 0 001.915 2.214 3.667 3.667 0 003.1-2.592 1.7 1.7 0 00-1.912-2.217 3.693 3.693 0 00-3.103 2.595z"
              />
              <path
                  data-name="Path 627"
                  d="M262.363 296.834a1.676 1.676 0 001.892 2.209 3.707 3.707 0 003.117-2.6 1.68 1.68 0 00-1.887-2.213 3.738 3.738 0 00-3.122 2.604z"
              />
              <path
                  data-name="Path 628"
                  d="M254.407 297.461a1.656 1.656 0 001.867 2.2 3.747 3.747 0 003.128-2.6 1.661 1.661 0 00-1.862-2.209 3.778 3.778 0 00-3.133 2.609z"
              />
              <path
                  data-name="Path 629"
                  d="M246.474 298.1a1.636 1.636 0 001.84 2.2 3.788 3.788 0 003.14-2.6 1.641 1.641 0 00-1.835-2.2 3.817 3.817 0 00-3.145 2.6z"
              />
              <path
                  data-name="Path 630"
                  d="M1051.829 305.407a5.209 5.209 0 003.431 2.677c1.215.144 1.641-.817.953-2.152a5.256 5.256 0 00-3.431-2.679c-1.207-.146-1.634.819-.953 2.154z"
              />
              <path
                  data-name="Path 631"
                  d="M1009.184 300.544a4.858 4.858 0 003.382 2.662c1.255.137 1.766-.834 1.145-2.167a4.9 4.9 0 00-3.384-2.665c-1.245-.138-1.757.83-1.143 2.17z"
              />
              <path
                  data-name="Path 632"
                  d="M1001.948 299.763a4.81 4.81 0 003.372 2.66c1.26.134 1.788-.837 1.176-2.171a4.839 4.839 0 00-3.378-2.663c-1.247-.135-1.771.839-1.17 2.174z"
              />
              <path
                  data-name="Path 633"
                  d="M980.027 297.481a4.644 4.644 0 003.345 2.65c1.278.13 1.846-.846 1.268-2.179a4.677 4.677 0 00-3.347-2.655c-1.266-.128-1.834.849-1.266 2.184z"
              />
              <path
                  data-name="Path 634"
                  d="M972.658 296.739a4.586 4.586 0 003.335 2.649c1.283.129 1.868-.847 1.3-2.184a4.632 4.632 0 00-3.339-2.652c-1.278-.129-1.856.852-1.296 2.187z"
              />
              <path
                  data-name="Path 635"
                  d="M950.343 294.58a4.432 4.432 0 003.307 2.639c1.3.124 1.923-.859 1.389-2.194a4.48 4.48 0 00-3.312-2.644c-1.292-.122-1.908.864-1.384 2.199z"
              />
              <path
                  data-name="Path 636"
                  d="M935.308 293.196a4.335 4.335 0 003.287 2.632c1.312.119 1.96-.866 1.447-2.2a4.374 4.374 0 00-3.29-2.637c-1.304-.121-1.949.869-1.444 2.205z"
              />
              <path
                  data-name="Path 637"
                  d="M665.381 278.023a2.952 2.952 0 002.853 2.483 2.128 2.128 0 002.3-2.365 2.972 2.972 0 00-2.854-2.488 2.14 2.14 0 00-2.299 2.37z"
              />
              <path
                  data-name="Path 638"
                  d="M657.165 277.849a2.916 2.916 0 002.836 2.478 2.154 2.154 0 002.326-2.371 2.935 2.935 0 00-2.837-2.482 2.164 2.164 0 00-2.325 2.375z"
              />
              <path
                  data-name="Path 639"
                  d="M648.939 277.691a2.884 2.884 0 002.819 2.473 2.181 2.181 0 002.348-2.376 2.9 2.9 0 00-2.822-2.478 2.19 2.19 0 00-2.345 2.381z"
              />
              <path
                  data-name="Path 640"
                  d="M640.707 277.551a2.85 2.85 0 002.8 2.466 2.2 2.2 0 002.368-2.38 2.871 2.871 0 00-2.806-2.473 2.216 2.216 0 00-2.362 2.387z"
              />
              <path
                  data-name="Path 641"
                  d="M632.464 277.428a2.82 2.82 0 002.789 2.462 2.232 2.232 0 002.388-2.386 2.835 2.835 0 00-2.789-2.467 2.246 2.246 0 00-2.388 2.391z"
              />
              <path
                  data-name="Path 642"
                  d="M624.218 277.321a2.784 2.784 0 002.772 2.456 2.257 2.257 0 002.408-2.391 2.8 2.8 0 00-2.772-2.461 2.269 2.269 0 00-2.408 2.396z"
              />
              <path
                  data-name="Path 643"
                  d="M615.964 277.232a2.756 2.756 0 002.756 2.451 2.286 2.286 0 002.43-2.4 2.771 2.771 0 00-2.756-2.456 2.3 2.3 0 00-2.43 2.405z"
              />
              <path
                  data-name="Path 644"
                  d="M607.708 277.161a2.721 2.721 0 002.737 2.445 2.314 2.314 0 002.45-2.4 2.739 2.739 0 00-2.739-2.451 2.323 2.323 0 00-2.448 2.406z"
              />
              <path
                  data-name="Path 645"
                  d="M599.444 277.106a2.69 2.69 0 002.721 2.44 2.341 2.341 0 002.47-2.408 2.71 2.71 0 00-2.722-2.447 2.354 2.354 0 00-2.469 2.415z"
              />
              <path
                  data-name="Path 646"
                  d="M591.177 277.067a2.66 2.66 0 002.7 2.435 2.369 2.369 0 002.49-2.413 2.675 2.675 0 00-2.7-2.44 2.379 2.379 0 00-2.49 2.418z"
              />
              <path
                  data-name="Path 647"
                  d="M582.906 277.045a2.631 2.631 0 002.687 2.43 2.4 2.4 0 002.508-2.418 2.643 2.643 0 00-2.687-2.435 2.408 2.408 0 00-2.508 2.423z"
              />
              <path
                  data-name="Path 648"
                  d="M393.175 281.285a2.006 2.006 0 002.233 2.3 3.123 3.123 0 002.9-2.535 2.015 2.015 0 00-2.229-2.308 3.142 3.142 0 00-2.904 2.543z"
              />
              <path
                  data-name="Path 649"
                  d="M385.009 281.669a1.985 1.985 0 002.211 2.3 3.157 3.157 0 002.916-2.542 1.989 1.989 0 00-2.206-2.3 3.182 3.182 0 00-2.921 2.542z"
              />
              <path
                  data-name="Path 650"
                  d="M376.855 282.07a1.962 1.962 0 002.189 2.293 3.193 3.193 0 002.931-2.547 1.967 1.967 0 00-2.184-2.3 3.218 3.218 0 00-2.936 2.554z"
              />
              <path
                  data-name="Path 651"
                  d="M368.711 282.488a1.937 1.937 0 002.166 2.286 3.232 3.232 0 002.946-2.55 1.944 1.944 0 00-2.162-2.291 3.254 3.254 0 00-2.95 2.555z"
              />
              <path
                  data-name="Path 652"
                  d="M360.59 282.921a1.913 1.913 0 002.142 2.281 3.265 3.265 0 002.959-2.555 1.92 1.92 0 00-2.139-2.286 3.289 3.289 0 00-2.962 2.56z"
              />
              <path
                  data-name="Path 653"
                  d="M352.476 283.37a1.892 1.892 0 002.119 2.276 3.305 3.305 0 002.975-2.56 1.9 1.9 0 00-2.116-2.281 3.326 3.326 0 00-2.978 2.565z"
              />
              <path
                  data-name="Path 654"
                  d="M344.376 283.838a1.868 1.868 0 002.1 2.269 3.338 3.338 0 002.988-2.563 1.873 1.873 0 00-2.092-2.274 3.366 3.366 0 00-2.996 2.568z"
              />
              <path
                  data-name="Path 655"
                  d="M336.294 284.314a1.847 1.847 0 002.074 2.266 3.379 3.379 0 003-2.568 1.852 1.852 0 00-2.069-2.271 3.41 3.41 0 00-3.005 2.573z"
              />
              <path
                  data-name="Path 656"
                  d="M328.227 284.811a1.824 1.824 0 002.049 2.259 3.417 3.417 0 003.016-2.572 1.831 1.831 0 00-2.045-2.266 3.444 3.444 0 00-3.02 2.579z"
              />
              <path
                  data-name="Path 657"
                  d="M320.177 285.322a1.8 1.8 0 002.025 2.254 3.458 3.458 0 003.03-2.578 1.809 1.809 0 00-2.022-2.259 3.482 3.482 0 00-3.033 2.583z"
              />
              <path
                  data-name="Path 658"
                  d="M312.143 285.847a1.78 1.78 0 002 2.249 3.5 3.5 0 003.043-2.58 1.788 1.788 0 00-2-2.256 3.521 3.521 0 00-3.043 2.587z"
              />
              <path
                  data-name="Path 659"
                  d="M304.127 286.389a1.76 1.76 0 001.977 2.244 3.534 3.534 0 003.055-2.585 1.765 1.765 0 00-1.974-2.249 3.559 3.559 0 00-3.058 2.59z"
              />
              <path
                  data-name="Path 660"
                  d="M296.129 286.943a1.739 1.739 0 001.952 2.239 3.574 3.574 0 003.068-2.588 1.744 1.744 0 00-1.948-2.244 3.6 3.6 0 00-3.072 2.593z"
              />
              <path
                  data-name="Path 661"
                  d="M288.15 287.515a1.72 1.72 0 001.928 2.234 3.616 3.616 0 003.081-2.6 1.725 1.725 0 00-1.925-2.239 3.648 3.648 0 00-3.084 2.605z"
              />
              <path
                  data-name="Path 662"
                  d="M280.191 288.1a1.7 1.7 0 001.9 2.229 3.653 3.653 0 003.093-2.6 1.7 1.7 0 00-1.9-2.234 3.685 3.685 0 00-3.093 2.605z"
              />
              <path
                  data-name="Path 663"
                  d="M272.255 288.698a1.679 1.679 0 001.878 2.224 3.7 3.7 0 003.107-2.6 1.684 1.684 0 00-1.873-2.229 3.727 3.727 0 00-3.112 2.605z"
              />
              <path
                  data-name="Path 664"
                  d="M264.334 289.311a1.66 1.66 0 001.852 2.221 3.741 3.741 0 003.12-2.607 1.664 1.664 0 00-1.848-2.224 3.77 3.77 0 00-3.124 2.61z"
              />
              <path
                  data-name="Path 665"
                  d="M256.437 289.94a1.64 1.64 0 001.826 2.214 3.78 3.78 0 003.132-2.61 1.645 1.645 0 00-1.823-2.219 3.809 3.809 0 00-3.135 2.615z"
              />
              <path
                  data-name="Path 666"
                  d="M248.562 290.579a1.621 1.621 0 001.8 2.211 3.825 3.825 0 003.143-2.615 1.625 1.625 0 00-1.8-2.214 3.851 3.851 0 00-3.143 2.618z"
              />
              <path
                  data-name="Path 667"
                  d="M240.708 291.234a1.6 1.6 0 001.775 2.206 3.865 3.865 0 003.155-2.619 1.607 1.607 0 00-1.77-2.211 3.907 3.907 0 00-3.16 2.624z"
              />
              <path
                  data-name="Path 668"
                  d="M984.051 290.669a4.739 4.739 0 003.355 2.664c1.262.132 1.8-.846 1.2-2.186a4.782 4.782 0 00-3.359-2.665c-1.253-.132-1.789.85-1.196 2.187z"
              />
              <path
                  data-name="Path 669"
                  d="M954.737 287.723a4.52 4.52 0 003.319 2.65c1.285.125 1.875-.859 1.318-2.2a4.568 4.568 0 00-3.32-2.655c-1.277-.122-1.865.865-1.317 2.205z"
              />
              <path
                  data-name="Path 670"
                  d="M947.328 287.014a4.476 4.476 0 003.309 2.649c1.29.122 1.895-.862 1.349-2.2a4.511 4.511 0 00-3.312-2.652c-1.282-.128-1.884.863-1.346 2.203z"
              />
              <path
                  data-name="Path 671"
                  d="M939.887 286.314a4.429 4.429 0 003.3 2.645c1.3.12 1.915-.867 1.379-2.207a4.459 4.459 0 00-3.3-2.65c-1.291-.12-1.906.872-1.379 2.212z"
              />
              <path
                  data-name="Path 672"
                  d="M932.417 285.626a4.372 4.372 0 003.289 2.642c1.3.12 1.93-.871 1.407-2.211a4.409 4.409 0 00-3.292-2.645c-1.298-.12-1.922.872-1.404 2.214z"
              />
              <path
                  data-name="Path 673"
                  d="M924.914 284.951a4.323 4.323 0 003.279 2.639c1.3.115 1.95-.874 1.435-2.216a4.359 4.359 0 00-3.282-2.642c-1.297-.117-1.932.877-1.432 2.219z"
              />
              <path
                  data-name="Path 674"
                  d="M917.384 284.286a4.278 4.278 0 003.269 2.635c1.31.115 1.969-.877 1.465-2.219a4.312 4.312 0 00-3.272-2.639c-1.303-.115-1.953.881-1.462 2.223z"
              />
              <path
                  data-name="Path 675"
                  d="M909.826 283.634a4.223 4.223 0 003.257 2.632c1.317.112 1.985-.881 1.494-2.223a4.263 4.263 0 00-3.26-2.637c-1.309-.114-1.978.886-1.491 2.228z"
              />
              <path
                  data-name="Path 676"
                  d="M902.239 282.996a4.174 4.174 0 003.245 2.627c1.322.112 2-.886 1.522-2.228a4.213 4.213 0 00-3.25-2.632c-1.312-.111-1.992.891-1.517 2.233z"
              />
              <path
                  data-name="Path 677"
                  d="M894.622 282.368a4.132 4.132 0 003.237 2.625c1.327.109 2.02-.891 1.551-2.233a4.172 4.172 0 00-3.24-2.629c-1.318-.107-2.01.895-1.548 2.237z"
              />
              <path
                  data-name="Path 678"
                  d="M879.312 281.153a4.042 4.042 0 003.215 2.617c1.335.1 2.054-.9 1.6-2.241a4.072 4.072 0 00-3.217-2.622c-1.322-.104-2.039.902-1.598 2.246z"
              />
              <path
                  data-name="Path 679"
                  d="M664.529 270.431a2.96 2.96 0 002.842 2.495 2.118 2.118 0 002.278-2.376 2.977 2.977 0 00-2.844-2.5 2.129 2.129 0 00-2.276 2.381z"
              />
              <path
                  data-name="Path 680"
                  d="M656.37 270.259a2.922 2.922 0 002.826 2.488 2.142 2.142 0 002.3-2.381 2.942 2.942 0 00-2.827-2.493 2.153 2.153 0 00-2.299 2.386z"
              />
              <path
                  data-name="Path 681"
                  d="M648.204 270.098a2.89 2.89 0 002.809 2.483 2.168 2.168 0 002.321-2.386 2.908 2.908 0 00-2.809-2.49 2.181 2.181 0 00-2.321 2.393z"
              />
              <path
                  data-name="Path 682"
                  d="M640.031 269.958a2.856 2.856 0 002.792 2.478 2.2 2.2 0 002.343-2.393 2.874 2.874 0 00-2.794-2.483 2.207 2.207 0 00-2.341 2.398z"
              />
              <path
                  data-name="Path 683"
                  d="M631.849 269.835a2.818 2.818 0 002.776 2.472 2.22 2.22 0 002.363-2.4 2.837 2.837 0 00-2.776-2.478 2.233 2.233 0 00-2.363 2.406z"
              />
              <path
                  data-name="Path 684"
                  d="M623.662 269.728a2.788 2.788 0 002.759 2.468 2.25 2.25 0 002.385-2.4 2.805 2.805 0 00-2.759-2.473 2.26 2.26 0 00-2.385 2.405z"
              />
              <path
                  data-name="Path 685"
                  d="M615.466 269.637a2.756 2.756 0 002.746 2.463 2.277 2.277 0 002.406-2.408 2.773 2.773 0 00-2.742-2.468 2.289 2.289 0 00-2.41 2.413z"
              />
              <path
                  data-name="Path 686"
                  d="M607.268 269.569a2.721 2.721 0 002.724 2.457 2.3 2.3 0 002.426-2.413 2.738 2.738 0 00-2.724-2.461 2.315 2.315 0 00-2.426 2.417z"
              />
              <path
                  data-name="Path 687"
                  d="M599.063 269.51a2.691 2.691 0 002.706 2.451 2.332 2.332 0 002.448-2.418 2.7 2.7 0 00-2.706-2.457 2.344 2.344 0 00-2.448 2.424z"
              />
              <path
                  data-name="Path 688"
                  d="M590.855 269.472a2.659 2.659 0 002.689 2.446 2.36 2.36 0 002.468-2.425 2.673 2.673 0 00-2.689-2.45 2.372 2.372 0 00-2.468 2.429z"
              />
              <path
                  data-name="Path 689"
                  d="M582.643 269.452a2.628 2.628 0 002.67 2.44 2.387 2.387 0 002.488-2.428 2.644 2.644 0 00-2.67-2.446 2.4 2.4 0 00-2.488 2.434z"
              />
              <path
                  data-name="Path 690"
                  d="M574.434 269.449a2.6 2.6 0 002.65 2.435 2.418 2.418 0 002.508-2.435 2.61 2.61 0 00-2.658-2.435 2.43 2.43 0 00-2.5 2.435z"
              />
              <path
                  data-name="Path 691"
                  d="M394.263 273.707a1.991 1.991 0 002.2 2.313 3.139 3.139 0 002.9-2.548 2 2 0 00-2.2-2.318 3.156 3.156 0 00-2.9 2.553z"
              />
              <path
                  data-name="Path 692"
                  d="M386.157 274.093a1.967 1.967 0 002.177 2.308 3.176 3.176 0 002.913-2.553 1.976 1.976 0 00-2.176-2.313 3.194 3.194 0 00-2.914 2.558z"
              />
              <path
                  data-name="Path 693"
                  d="M378.065 274.489a1.944 1.944 0 002.156 2.3 3.208 3.208 0 002.926-2.557 1.952 1.952 0 00-2.152-2.308 3.236 3.236 0 00-2.93 2.565z"
              />
              <path
                  data-name="Path 694"
                  d="M369.981 274.913a1.92 1.92 0 002.132 2.3 3.247 3.247 0 002.941-2.56 1.928 1.928 0 00-2.129-2.3 3.27 3.27 0 00-2.944 2.56z"
              />
              <path
                  data-name="Path 695"
                  d="M361.912 275.347a1.9 1.9 0 002.111 2.291 3.284 3.284 0 002.956-2.567 1.906 1.906 0 00-2.107-2.3 3.311 3.311 0 00-2.96 2.576z"
              />
              <path
                  data-name="Path 696"
                  d="M353.858 275.797a1.874 1.874 0 002.085 2.286 3.322 3.322 0 002.971-2.57 1.882 1.882 0 00-2.082-2.291 3.347 3.347 0 00-2.974 2.575z"
              />
              <path
                  data-name="Path 697"
                  d="M345.819 276.263a1.853 1.853 0 002.062 2.281 3.361 3.361 0 002.985-2.575 1.859 1.859 0 00-2.059-2.286 3.386 3.386 0 00-2.988 2.58z"
              />
              <path
                  data-name="Path 698"
                  d="M337.794 276.744a1.831 1.831 0 002.039 2.276 3.4 3.4 0 003-2.58 1.838 1.838 0 00-2.035-2.281 3.429 3.429 0 00-3.004 2.585z"
              />
              <path
                  data-name="Path 699"
                  d="M329.787 277.241a1.808 1.808 0 002.014 2.271 3.438 3.438 0 003.013-2.583 1.814 1.814 0 00-2.01-2.276 3.465 3.465 0 00-3.017 2.588z"
              />
              <path
                  data-name="Path 700"
                  d="M321.794 277.754a1.786 1.786 0 001.99 2.264 3.48 3.48 0 003.028-2.589 1.792 1.792 0 00-1.987-2.269 3.508 3.508 0 00-3.031 2.594z"
              />
              <path
                  data-name="Path 701"
                  d="M313.82 278.28a1.766 1.766 0 001.965 2.261 3.519 3.519 0 003.041-2.594 1.771 1.771 0 00-1.962-2.264 3.548 3.548 0 00-3.044 2.597z"
              />
              <path
                  data-name="Path 702"
                  d="M305.864 278.823a1.743 1.743 0 001.94 2.254 3.556 3.556 0 003.055-2.6 1.749 1.749 0 00-1.937-2.259 3.585 3.585 0 00-3.058 2.605z"
              />
              <path
                  data-name="Path 703"
                  d="M297.924 279.38a1.725 1.725 0 001.917 2.251 3.6 3.6 0 003.068-2.6 1.729 1.729 0 00-1.913-2.254 3.628 3.628 0 00-3.072 2.603z"
              />
              <path
                  data-name="Path 704"
                  d="M290.005 279.951a1.7 1.7 0 001.89 2.246 3.643 3.643 0 003.081-2.607 1.709 1.709 0 00-1.887-2.249 3.676 3.676 0 00-3.084 2.61z"
              />
              <path
                  data-name="Path 705"
                  d="M282.104 280.537a1.684 1.684 0 001.867 2.239 3.681 3.681 0 003.093-2.609 1.688 1.688 0 00-1.862-2.244 3.716 3.716 0 00-3.098 2.614z"
              />
              <path
                  data-name="Path 706"
                  d="M274.225 281.134a1.664 1.664 0 001.84 2.236 3.725 3.725 0 003.107-2.614 1.668 1.668 0 00-1.835-2.239 3.756 3.756 0 00-3.112 2.617z"
              />
              <path
                  data-name="Path 707"
                  d="M266.366 281.753a1.644 1.644 0 001.815 2.231 3.771 3.771 0 003.12-2.619 1.649 1.649 0 00-1.811-2.234 3.8 3.8 0 00-3.124 2.622z"
              />
              <path
                  data-name="Path 708"
                  d="M258.528 282.383a1.624 1.624 0 001.788 2.224 3.808 3.808 0 003.132-2.622 1.629 1.629 0 00-1.783-2.229 3.845 3.845 0 00-3.137 2.627z"
              />
              <path
                  data-name="Path 709"
                  d="M250.713 283.025a1.607 1.607 0 001.763 2.221 3.857 3.857 0 003.143-2.625 1.61 1.61 0 00-1.758-2.226 3.892 3.892 0 00-3.148 2.63z"
              />
              <path
                  data-name="Path 710"
                  d="M958.918 280.853a4.612 4.612 0 003.33 2.662c1.268.127 1.83-.859 1.252-2.2a4.659 4.659 0 00-3.33-2.665c-1.264-.13-1.822.858-1.252 2.203z"
              />
              <path
                  data-name="Path 711"
                  d="M936.874 278.722a4.463 4.463 0 003.3 2.654c1.283.122 1.887-.871 1.34-2.216a4.5 4.5 0 00-3.3-2.657c-1.28-.122-1.88.872-1.34 2.219z"
              />
              <path
                  data-name="Path 712"
                  d="M929.462 278.031a4.418 4.418 0 003.29 2.65c1.288.119 1.9-.874 1.369-2.219a4.445 4.445 0 00-3.292-2.654c-1.282-.119-1.896.877-1.367 2.223z"
              />
              <path
                  data-name="Path 713"
                  d="M922.023 277.353a4.363 4.363 0 003.279 2.649c1.295.117 1.922-.877 1.4-2.224a4.4 4.4 0 00-3.282-2.65c-1.292-.12-1.912.879-1.397 2.225z"
              />
              <path
                  data-name="Path 714"
                  d="M914.553 276.688a4.317 4.317 0 003.269 2.645c1.3.114 1.94-.882 1.427-2.228a4.35 4.35 0 00-3.272-2.649c-1.292-.115-1.924.886-1.424 2.232z"
              />
              <path
                  data-name="Path 715"
                  d="M907.055 276.038a4.265 4.265 0 003.259 2.642c1.305.112 1.957-.887 1.454-2.233a4.3 4.3 0 00-3.26-2.644c-1.299-.116-1.946.887-1.453 2.235z"
              />
              <path
                  data-name="Path 716"
                  d="M891.973 274.766a4.172 4.172 0 003.237 2.634c1.315.109 1.994-.894 1.512-2.241a4.205 4.205 0 00-3.24-2.637c-1.308-.109-1.98.895-1.509 2.244z"
              />
              <path
                  data-name="Path 717"
                  d="M884.394 274.15a4.114 4.114 0 003.225 2.63c1.32.107 2.009-.9 1.539-2.244a4.151 4.151 0 00-3.228-2.634c-1.312-.107-1.998.901-1.536 2.248z"
              />
              <path
                  data-name="Path 718"
                  d="M876.787 273.548a4.069 4.069 0 003.213 2.625c1.323.105 2.025-.9 1.568-2.249a4.108 4.108 0 00-3.217-2.63c-1.315-.104-2.015.907-1.564 2.254z"
              />
              <path
                  data-name="Path 719"
                  d="M663.651 262.805a2.962 2.962 0 002.831 2.5 2.1 2.1 0 002.251-2.385 2.982 2.982 0 00-2.832-2.508 2.115 2.115 0 00-2.25 2.393z"
              />
              <path
                  data-name="Path 720"
                  d="M655.556 262.631a2.927 2.927 0 002.814 2.5 2.131 2.131 0 002.273-2.391 2.947 2.947 0 00-2.816-2.5 2.141 2.141 0 00-2.271 2.391z"
              />
              <path
                  data-name="Path 721"
                  d="M647.453 262.472a2.891 2.891 0 002.8 2.493 2.157 2.157 0 002.3-2.4 2.91 2.91 0 00-2.8-2.5 2.169 2.169 0 00-2.3 2.407z"
              />
              <path
                  data-name="Path 722"
                  d="M639.336 262.332a2.855 2.855 0 002.781 2.488 2.183 2.183 0 002.316-2.4 2.875 2.875 0 00-2.781-2.493 2.2 2.2 0 00-2.316 2.405z"
              />
              <path
                  data-name="Path 723"
                  d="M631.216 262.207a2.824 2.824 0 002.762 2.483 2.211 2.211 0 002.338-2.408 2.839 2.839 0 00-2.762-2.487 2.222 2.222 0 00-2.338 2.412z"
              />
              <path
                  data-name="Path 724"
                  d="M623.088 262.1a2.789 2.789 0 002.744 2.478 2.239 2.239 0 002.361-2.413 2.8 2.8 0 00-2.744-2.482 2.251 2.251 0 00-2.361 2.417z"
              />
              <path
                  data-name="Path 725"
                  d="M614.955 262.012a2.754 2.754 0 002.727 2.471 2.267 2.267 0 002.383-2.418 2.773 2.773 0 00-2.727-2.477 2.279 2.279 0 00-2.383 2.424z"
              />
              <path
                  data-name="Path 726"
                  d="M606.817 261.94a2.721 2.721 0 002.709 2.466 2.3 2.3 0 002.4-2.423 2.736 2.736 0 00-2.709-2.471 2.307 2.307 0 00-2.4 2.428z"
              />
              <path
                  data-name="Path 727"
                  d="M598.669 261.885a2.688 2.688 0 002.69 2.46 2.323 2.323 0 002.425-2.428 2.7 2.7 0 00-2.69-2.465 2.335 2.335 0 00-2.425 2.433z"
              />
              <path
                  data-name="Path 728"
                  d="M590.527 261.847a2.652 2.652 0 002.67 2.455 2.351 2.351 0 002.446-2.433 2.67 2.67 0 00-2.672-2.46 2.363 2.363 0 00-2.444 2.438z"
              />
              <path
                  data-name="Path 729"
                  d="M582.376 261.827a2.624 2.624 0 002.652 2.45 2.382 2.382 0 002.468-2.44 2.642 2.642 0 00-2.654-2.455 2.394 2.394 0 00-2.466 2.445z"
              />
              <path
                  data-name="Path 730"
                  d="M574.222 261.824a2.592 2.592 0 002.629 2.445 2.411 2.411 0 002.487-2.445 2.606 2.606 0 00-2.634-2.45 2.423 2.423 0 00-2.482 2.45z"
              />
              <path
                  data-name="Path 731"
                  d="M566.066 261.837a2.561 2.561 0 002.615 2.44 2.44 2.44 0 002.507-2.45 2.574 2.574 0 00-2.612-2.445 2.454 2.454 0 00-2.51 2.455z"
              />
              <path
                  data-name="Path 732"
                  d="M363.28 267.741a1.882 1.882 0 002.077 2.3 3.3 3.3 0 002.951-2.577 1.889 1.889 0 00-2.074-2.3 3.326 3.326 0 00-2.954 2.577z"
              />
              <path
                  data-name="Path 733"
                  d="M355.285 268.192a1.86 1.86 0 002.054 2.3 3.341 3.341 0 002.966-2.58 1.867 1.867 0 00-2.05-2.3 3.366 3.366 0 00-2.97 2.58z"
              />
              <path
                  data-name="Path 734"
                  d="M347.308 268.66a1.835 1.835 0 002.027 2.289 3.375 3.375 0 002.981-2.585 1.843 1.843 0 00-2.025-2.294 3.405 3.405 0 00-2.983 2.59z"
              />
              <path
                  data-name="Path 735"
                  d="M339.343 269.143a1.813 1.813 0 002 2.284 3.419 3.419 0 003-2.59 1.821 1.821 0 00-2-2.289 3.445 3.445 0 00-3 2.595z"
              />
              <path
                  data-name="Path 736"
                  d="M331.396 269.641a1.791 1.791 0 001.979 2.279 3.46 3.46 0 003.011-2.595 1.8 1.8 0 00-1.977-2.284 3.486 3.486 0 00-3.013 2.6z"
              />
              <path
                  data-name="Path 737"
                  d="M323.463 270.154a1.771 1.771 0 001.955 2.274 3.5 3.5 0 003.025-2.6 1.776 1.776 0 00-1.95-2.278 3.529 3.529 0 00-3.03 2.604z"
              />
              <path
                  data-name="Path 738"
                  d="M315.548 270.684a1.749 1.749 0 001.93 2.268 3.542 3.542 0 003.04-2.6 1.757 1.757 0 00-1.927-2.274 3.57 3.57 0 00-3.043 2.606z"
              />
              <path
                  data-name="Path 739"
                  d="M307.653 271.226a1.729 1.729 0 001.905 2.264 3.583 3.583 0 003.053-2.607 1.736 1.736 0 00-1.9-2.269 3.613 3.613 0 00-3.058 2.612z"
              />
              <path
                  data-name="Path 740"
                  d="M299.775 271.785a1.707 1.707 0 001.878 2.259 3.626 3.626 0 003.066-2.612 1.713 1.713 0 00-1.875-2.263 3.652 3.652 0 00-3.069 2.616z"
              />
              <path
                  data-name="Path 741"
                  d="M291.916 272.358a1.687 1.687 0 001.853 2.254 3.668 3.668 0 003.08-2.615 1.693 1.693 0 00-1.85-2.259 3.7 3.7 0 00-3.083 2.62z"
              />
              <path
                  data-name="Path 742"
                  d="M284.076 272.942a1.668 1.668 0 001.828 2.249 3.711 3.711 0 003.093-2.62 1.672 1.672 0 00-1.825-2.253 3.739 3.739 0 00-3.096 2.624z"
              />
              <path
                  data-name="Path 743"
                  d="M276.259 273.548a1.648 1.648 0 001.8 2.244 3.758 3.758 0 003.107-2.624 1.654 1.654 0 00-1.8-2.248 3.785 3.785 0 00-3.107 2.628z"
              />
              <path
                  data-name="Path 744"
                  d="M268.458 274.164a1.629 1.629 0 001.776 2.239 3.8 3.8 0 003.12-2.629 1.634 1.634 0 00-1.773-2.243 3.828 3.828 0 00-3.123 2.633z"
              />
              <path
                  data-name="Path 745"
                  d="M260.683 274.795a1.609 1.609 0 001.75 2.234 3.842 3.842 0 003.132-2.632 1.615 1.615 0 00-1.746-2.238 3.873 3.873 0 00-3.136 2.636z"
              />
              <path
                  data-name="Path 746"
                  d="M252.927 275.436a1.591 1.591 0 001.723 2.229 3.891 3.891 0 003.145-2.637 1.6 1.6 0 00-1.72-2.233 3.92 3.92 0 00-3.148 2.641z"
              />
              <path
                  data-name="Path 747"
                  d="M933.8 271.101a4.5 4.5 0 003.3 2.662c1.275.122 1.857-.872 1.3-2.223a4.537 4.537 0 00-3.3-2.664c-1.266-.116-1.85.877-1.3 2.225z"
              />
              <path
                  data-name="Path 748"
                  d="M926.451 270.411a4.446 4.446 0 003.287 2.659c1.28.119 1.877-.876 1.332-2.226a4.489 4.489 0 00-3.292-2.662c-1.272-.12-1.865.879-1.327 2.229z"
              />
              <path
                  data-name="Path 749"
                  d="M919.071 269.733a4.4 4.4 0 003.277 2.655c1.285.117 1.9-.881 1.36-2.231a4.44 4.44 0 00-3.28-2.659c-1.276-.116-1.885.885-1.357 2.235z"
              />
              <path
                  data-name="Path 750"
                  d="M911.662 269.066a4.35 4.35 0 003.269 2.652c1.288.114 1.912-.886 1.389-2.234a4.381 4.381 0 00-3.27-2.655c-1.283-.117-1.903.887-1.388 2.237z"
              />
              <path
                  data-name="Path 751"
                  d="M904.226 268.411a4.3 4.3 0 003.257 2.649c1.3.112 1.932-.887 1.417-2.239a4.332 4.332 0 00-3.26-2.652c-1.287-.114-1.919.89-1.414 2.242z"
              />
              <path
                  data-name="Path 752"
                  d="M881.749 266.521a4.153 4.153 0 003.223 2.639c1.31.105 1.984-.9 1.5-2.253a4.188 4.188 0 00-3.227-2.642c-1.299-.107-1.968.906-1.496 2.256z"
              />
              <path
                  data-name="Path 753"
                  d="M874.202 265.918a4.105 4.105 0 003.214 2.634c1.313.105 2-.9 1.529-2.256a4.136 4.136 0 00-3.215-2.639c-1.306-.104-1.988.908-1.528 2.261z"
              />
              <path
                  data-name="Path 754"
                  d="M866.631 265.326a4.052 4.052 0 003.2 2.63c1.317.1 2.015-.909 1.557-2.261a4.087 4.087 0 00-3.2-2.634c-1.313-.101-2.008.913-1.557 2.265z"
              />
              <path
                  data-name="Path 755"
                  d="M670.776 255.343a3 3 0 002.836 2.518 2.068 2.068 0 002.2-2.388 3.023 3.023 0 00-2.839-2.522 2.076 2.076 0 00-2.197 2.392z"
              />
              <path
                  data-name="Path 756"
                  d="M662.751 255.152a2.965 2.965 0 002.819 2.512 2.092 2.092 0 002.222-2.393 2.986 2.986 0 00-2.821-2.517 2.1 2.1 0 00-2.22 2.398z"
              />
              <path
                  data-name="Path 757"
                  d="M654.717 254.977a2.929 2.929 0 002.8 2.507 2.118 2.118 0 002.246-2.4 2.947 2.947 0 00-2.8-2.512 2.129 2.129 0 00-2.246 2.405z"
              />
              <path
                  data-name="Path 758"
                  d="M646.674 254.82a2.9 2.9 0 002.784 2.5 2.147 2.147 0 002.269-2.4 2.913 2.913 0 00-2.785-2.51 2.157 2.157 0 00-2.268 2.41z"
              />
              <path
                  data-name="Path 759"
                  d="M638.624 254.678a2.856 2.856 0 002.766 2.5 2.173 2.173 0 002.291-2.41 2.874 2.874 0 00-2.767-2.5 2.183 2.183 0 00-2.29 2.41z"
              />
              <path
                  data-name="Path 760"
                  d="M630.566 254.555a2.82 2.82 0 002.747 2.492 2.2 2.2 0 002.314-2.415 2.84 2.84 0 00-2.749-2.5 2.211 2.211 0 00-2.312 2.423z"
              />
              <path
                  data-name="Path 761"
                  d="M622.502 254.448a2.787 2.787 0 002.729 2.487 2.228 2.228 0 002.336-2.421 2.8 2.8 0 00-2.73-2.49 2.24 2.24 0 00-2.335 2.424z"
              />
              <path
                  data-name="Path 762"
                  d="M614.43 254.358a2.753 2.753 0 002.712 2.48 2.256 2.256 0 002.358-2.426 2.769 2.769 0 00-2.712-2.483 2.269 2.269 0 00-2.358 2.429z"
              />
              <path
                  data-name="Path 763"
                  d="M606.354 254.287a2.719 2.719 0 002.692 2.475 2.284 2.284 0 002.38-2.431 2.735 2.735 0 00-2.692-2.48 2.3 2.3 0 00-2.38 2.436z"
              />
              <path
                  data-name="Path 764"
                  d="M598.274 254.231a2.685 2.685 0 002.674 2.468 2.314 2.314 0 002.4-2.436 2.7 2.7 0 00-2.674-2.473 2.327 2.327 0 00-2.4 2.441z"
              />
              <path
                  data-name="Path 765"
                  d="M590.19 254.193a2.654 2.654 0 002.655 2.465 2.346 2.346 0 002.423-2.443 2.669 2.669 0 00-2.655-2.468 2.357 2.357 0 00-2.423 2.446z"
              />
              <path
                  data-name="Path 766"
                  d="M582.102 254.175a2.619 2.619 0 002.635 2.458 2.372 2.372 0 002.445-2.448 2.633 2.633 0 00-2.635-2.462 2.385 2.385 0 00-2.445 2.452z"
              />
              <path
                  data-name="Path 767"
                  d="M574.012 254.172a2.583 2.583 0 002.614 2.453 2.4 2.4 0 002.468-2.453 2.6 2.6 0 00-2.617-2.456 2.415 2.415 0 00-2.465 2.456z"
              />
              <path
                  data-name="Path 768"
                  d="M565.916 254.185a2.554 2.554 0 002.6 2.446 2.433 2.433 0 002.485-2.456 2.567 2.567 0 00-2.6-2.452 2.447 2.447 0 00-2.485 2.462z"
              />
              <path
                  data-name="Path 769"
                  d="M557.823 254.216a2.523 2.523 0 002.577 2.443 2.466 2.466 0 002.508-2.465 2.534 2.534 0 00-2.577-2.445 2.476 2.476 0 00-2.508 2.467z"
              />
              <path
                  data-name="Path 770"
                  d="M356.76 260.56a1.842 1.842 0 002.019 2.3 3.357 3.357 0 002.961-2.588 1.851 1.851 0 00-2.017-2.308 3.38 3.38 0 00-2.963 2.596z"
              />
              <path
                  data-name="Path 771"
                  d="M348.842 261.028a1.822 1.822 0 001.994 2.3 3.4 3.4 0 002.976-2.6 1.828 1.828 0 00-1.992-2.3 3.422 3.422 0 00-2.978 2.6z"
              />
              <path
                  data-name="Path 772"
                  d="M340.939 261.512a1.8 1.8 0 001.97 2.293 3.44 3.44 0 002.991-2.6 1.806 1.806 0 00-1.967-2.3 3.468 3.468 0 00-2.994 2.607z"
              />
              <path
                  data-name="Path 773"
                  d="M333.054 262.012a1.777 1.777 0 001.945 2.288 3.482 3.482 0 003.006-2.6 1.783 1.783 0 00-1.942-2.291 3.5 3.5 0 00-3.009 2.603z"
              />
              <path
                  data-name="Path 774"
                  d="M325.183 262.527a1.757 1.757 0 001.92 2.283 3.523 3.523 0 003.021-2.609 1.762 1.762 0 00-1.917-2.286 3.55 3.55 0 00-3.024 2.612z"
              />
              <path
                  data-name="Path 775"
                  d="M317.33 263.057a1.735 1.735 0 001.9 2.278 3.561 3.561 0 003.035-2.612 1.739 1.739 0 00-1.89-2.281 3.592 3.592 0 00-3.045 2.615z"
              />
              <path
                  data-name="Path 776"
                  d="M309.496 263.602a1.712 1.712 0 001.868 2.273 3.605 3.605 0 003.05-2.617 1.719 1.719 0 00-1.865-2.276 3.637 3.637 0 00-3.053 2.62z"
              />
              <path
                  data-name="Path 777"
                  d="M301.679 264.161a1.693 1.693 0 001.843 2.268 3.65 3.65 0 003.065-2.62 1.7 1.7 0 00-1.84-2.271 3.679 3.679 0 00-3.068 2.623z"
              />
              <path
                  data-name="Path 778"
                  d="M293.882 264.736a1.672 1.672 0 001.816 2.263 3.691 3.691 0 003.078-2.625 1.677 1.677 0 00-1.815-2.264 3.718 3.718 0 00-3.079 2.626z"
              />
              <path
                  data-name="Path 779"
                  d="M286.103 265.326a1.653 1.653 0 001.791 2.258 3.738 3.738 0 003.091-2.629 1.658 1.658 0 00-1.788-2.261 3.765 3.765 0 00-3.094 2.632z"
              />
              <path
                  data-name="Path 780"
                  d="M278.346 265.929a1.633 1.633 0 001.765 2.253 3.781 3.781 0 003.1-2.634 1.637 1.637 0 00-1.761-2.254 3.809 3.809 0 00-3.104 2.635z"
              />
              <path
                  data-name="Path 781"
                  d="M270.609 266.547a1.614 1.614 0 001.738 2.248 3.829 3.829 0 003.118-2.637 1.619 1.619 0 00-1.735-2.251 3.857 3.857 0 00-3.121 2.64z"
              />
              <path
                  data-name="Path 782"
                  d="M262.892 267.179a1.6 1.6 0 001.713 2.243 3.876 3.876 0 003.132-2.64 1.6 1.6 0 00-1.709-2.246 3.9 3.9 0 00-3.136 2.643z"
              />
              <path
                  data-name="Path 783"
                  d="M952.351 265.608a4.7 4.7 0 003.325 2.677c1.248.127 1.775-.864 1.178-2.216a4.74 4.74 0 00-3.329-2.679c-1.237-.128-1.764.866-1.174 2.218z"
              />
              <path
                  data-name="Path 784"
                  d="M930.671 263.463a4.532 4.532 0 003.3 2.669c1.263.122 1.83-.876 1.265-2.228a4.571 4.571 0 00-3.3-2.67c-1.259-.122-1.828.877-1.265 2.229z"
              />
              <path
                  data-name="Path 785"
                  d="M923.384 262.771a4.482 4.482 0 003.287 2.665c1.27.119 1.848-.879 1.293-2.233a4.519 4.519 0 00-3.29-2.667c-1.263-.119-1.841.882-1.29 2.235z"
              />
              <path
                  data-name="Path 786"
                  d="M879.048 258.871a4.181 4.181 0 003.222 2.645c1.3.107 1.955-.9 1.466-2.258a4.215 4.215 0 00-3.225-2.649c-1.293-.106-1.946.907-1.463 2.262z"
              />
              <path
                  data-name="Path 787"
                  d="M871.563 258.266a4.138 4.138 0 003.212 2.64c1.3.105 1.97-.907 1.492-2.263a4.174 4.174 0 00-3.214-2.645c-1.293-.104-1.962.911-1.49 2.268z"
              />
              <path
                  data-name="Path 788"
                  d="M677.739 247.876a3.041 3.041 0 002.841 2.53 2.026 2.026 0 002.147-2.39 3.064 3.064 0 00-2.842-2.533 2.035 2.035 0 00-2.146 2.393z"
              />
              <path
                  data-name="Path 789"
                  d="M669.787 247.668a3.005 3.005 0 002.822 2.525 2.055 2.055 0 002.172-2.395 3.025 3.025 0 00-2.826-2.528 2.061 2.061 0 00-2.168 2.398z"
              />
              <path
                  data-name="Path 790"
                  d="M661.825 247.475a2.968 2.968 0 002.806 2.52 2.079 2.079 0 002.194-2.4 2.984 2.984 0 00-2.8-2.523 2.088 2.088 0 00-2.2 2.403z"
              />
              <path
                  data-name="Path 791"
                  d="M653.857 247.302a2.929 2.929 0 002.787 2.513 2.106 2.106 0 002.219-2.406 2.949 2.949 0 00-2.791-2.517 2.114 2.114 0 00-2.215 2.41z"
              />
              <path
                  data-name="Path 792"
                  d="M645.878 247.143a2.893 2.893 0 002.771 2.508 2.134 2.134 0 002.243-2.411 2.913 2.913 0 00-2.772-2.512 2.144 2.144 0 00-2.242 2.415z"
              />
              <path
                  data-name="Path 793"
                  d="M637.892 247.007a2.859 2.859 0 002.752 2.5 2.16 2.16 0 002.264-2.416 2.874 2.874 0 00-2.752-2.508 2.173 2.173 0 00-2.264 2.424z"
              />
              <path
                  data-name="Path 794"
                  d="M629.9 246.878a2.82 2.82 0 002.732 2.5 2.189 2.189 0 002.289-2.421 2.837 2.837 0 00-2.734-2.5 2.2 2.2 0 00-2.287 2.421z"
              />
              <path
                  data-name="Path 795"
                  d="M621.9 246.772a2.782 2.782 0 002.714 2.492 2.215 2.215 0 002.311-2.426 2.8 2.8 0 00-2.715-2.5 2.227 2.227 0 00-2.31 2.434z"
              />
              <path
                  data-name="Path 796"
                  d="M613.893 246.682a2.751 2.751 0 002.694 2.49 2.246 2.246 0 002.334-2.433 2.766 2.766 0 00-2.7-2.492 2.257 2.257 0 00-2.328 2.435z"
              />
              <path
                  data-name="Path 797"
                  d="M605.883 246.61a2.715 2.715 0 002.675 2.482 2.274 2.274 0 002.356-2.438 2.732 2.732 0 00-2.675-2.487 2.288 2.288 0 00-2.356 2.443z"
              />
              <path
                  data-name="Path 798"
                  d="M597.866 246.555a2.681 2.681 0 002.655 2.477 2.3 2.3 0 002.38-2.445 2.7 2.7 0 00-2.657-2.48 2.317 2.317 0 00-2.378 2.448z"
              />
              <path
                  data-name="Path 799"
                  d="M589.847 246.516a2.645 2.645 0 002.635 2.471 2.333 2.333 0 002.4-2.45 2.663 2.663 0 00-2.635-2.475 2.346 2.346 0 00-2.4 2.454z"
              />
              <path
                  data-name="Path 800"
                  d="M581.823 246.496a2.616 2.616 0 002.617 2.467 2.366 2.366 0 002.423-2.455 2.629 2.629 0 00-2.617-2.47 2.378 2.378 0 00-2.423 2.458z"
              />
              <path
                  data-name="Path 801"
                  d="M573.796 246.493a2.579 2.579 0 002.6 2.46 2.394 2.394 0 002.445-2.46 2.593 2.593 0 00-2.6-2.463 2.408 2.408 0 00-2.445 2.463z"
              />
              <path
                  data-name="Path 802"
                  d="M565.768 246.508a2.545 2.545 0 002.575 2.455 2.426 2.426 0 002.466-2.467 2.558 2.558 0 00-2.575-2.458 2.44 2.44 0 00-2.466 2.47z"
              />
              <path
                  data-name="Path 803"
                  d="M342.58 253.859a1.784 1.784 0 001.937 2.3 3.457 3.457 0 002.986-2.607 1.791 1.791 0 00-1.933-2.3 3.485 3.485 0 00-2.99 2.607z"
              />
              <path
                  data-name="Path 804"
                  d="M334.762 254.359a1.761 1.761 0 001.91 2.294 3.5 3.5 0 003-2.61 1.769 1.769 0 00-1.908-2.3 3.528 3.528 0 00-3.002 2.616z"
              />
              <path
                  data-name="Path 805"
                  d="M326.951 254.875a1.74 1.74 0 001.885 2.289 3.543 3.543 0 003.016-2.615 1.746 1.746 0 00-1.882-2.293 3.568 3.568 0 00-3.019 2.619z"
              />
              <path
                  data-name="Path 806"
                  d="M319.16 255.407a1.719 1.719 0 001.86 2.284 3.584 3.584 0 003.031-2.62 1.725 1.725 0 00-1.857-2.288 3.615 3.615 0 00-3.034 2.624z"
              />
              <path
                  data-name="Path 807"
                  d="M311.388 255.953a1.7 1.7 0 001.835 2.279 3.627 3.627 0 003.048-2.623 1.7 1.7 0 00-1.83-2.283 3.658 3.658 0 00-3.053 2.627z"
              />
              <path
                  data-name="Path 808"
                  d="M303.634 256.515a1.678 1.678 0 001.808 2.274 3.673 3.673 0 003.06-2.629 1.684 1.684 0 00-1.8-2.278 3.7 3.7 0 00-3.068 2.633z"
              />
              <path
                  data-name="Path 809"
                  d="M295.899 257.091a1.657 1.657 0 001.781 2.269 3.719 3.719 0 003.075-2.632 1.663 1.663 0 00-1.778-2.273 3.748 3.748 0 00-3.078 2.636z"
              />
              <path
                  data-name="Path 810"
                  d="M288.183 257.682a1.637 1.637 0 001.755 2.263 3.759 3.759 0 003.088-2.635 1.642 1.642 0 00-1.751-2.268 3.794 3.794 0 00-3.092 2.64z"
              />
              <path
                  data-name="Path 811"
                  d="M280.486 258.288a1.62 1.62 0 001.73 2.259 3.814 3.814 0 003.1-2.64 1.624 1.624 0 00-1.726-2.263 3.842 3.842 0 00-3.104 2.644z"
              />
              <path
                  data-name="Path 812"
                  d="M272.813 258.908a1.6 1.6 0 001.7 2.253 3.851 3.851 0 003.117-2.644 1.605 1.605 0 00-1.7-2.258 3.884 3.884 0 00-3.117 2.649z"
              />
              <path
                  data-name="Path 813"
                  d="M265.16 259.54c-.408 1.354.344 2.358 1.674 2.249a3.9 3.9 0 003.13-2.649c.4-1.354-.351-2.363-1.673-2.253a3.927 3.927 0 00-3.131 2.653z"
              />
              <path
                  data-name="Path 814"
                  d="M257.525 260.189c-.418 1.354.323 2.356 1.649 2.242a3.943 3.943 0 003.142-2.652c.409-1.352-.326-2.359-1.644-2.246a3.977 3.977 0 00-3.147 2.656z"
              />
              <path
                  data-name="Path 815"
                  d="M242.331 261.525c-.44 1.352.276 2.353 1.594 2.234a4.041 4.041 0 003.168-2.659c.431-1.354-.281-2.355-1.593-2.238a4.074 4.074 0 00-3.169 2.663z"
              />
              <path
                  data-name="Path 816"
                  d="M1045.293 269.179a5.516 5.516 0 003.424 2.709c1.15.15 1.474-.82.725-2.167a5.574 5.574 0 00-3.424-2.71c-1.143-.15-1.467.821-.725 2.168z"
              />
              <path
                  data-name="Path 817"
                  d="M1038.654 268.324a5.471 5.471 0 003.419 2.707c1.156.149 1.5-.822.755-2.171a5.5 5.5 0 00-3.419-2.709c-1.148-.148-1.485.825-.755 2.173z"
              />
              <path
                  data-name="Path 818"
                  d="M1031.974 267.475a5.412 5.412 0 003.414 2.705c1.163.147 1.516-.825.785-2.174a5.446 5.446 0 00-3.412-2.707c-1.156-.149-1.507.827-.787 2.176z"
              />
              <path
                  data-name="Path 819"
                  d="M991.115 262.549a5.061 5.061 0 003.372 2.7c1.2.137 1.636-.844.966-2.2a5.1 5.1 0 00-3.376-2.7c-1.194-.136-1.625.849-.962 2.2z"
              />
              <path
                  data-name="Path 820"
                  d="M948.985 257.958a4.737 4.737 0 003.324 2.684c1.237.127 1.75-.866 1.143-2.219a4.773 4.773 0 00-3.327-2.685c-1.229-.129-1.74.867-1.14 2.22z"
              />
              <path
                  data-name="Path 821"
                  d="M941.85 257.232a4.681 4.681 0 003.314 2.679c1.243.125 1.768-.869 1.171-2.222a4.716 4.716 0 00-3.317-2.684c-1.231-.126-1.756.872-1.168 2.227z"
              />
              <path
                  data-name="Path 822"
                  d="M927.486 255.808a4.568 4.568 0 003.3 2.674c1.253.122 1.8-.877 1.228-2.233a4.6 4.6 0 00-3.3-2.677c-1.248-.12-1.795.879-1.228 2.236z"
              />
              <path
                  data-name="Path 823"
                  d="M876.291 251.202a4.21 4.21 0 003.219 2.65c1.287.107 1.93-.906 1.429-2.263a4.242 4.242 0 00-3.222-2.654c-1.278-.106-1.915.908-1.426 2.267z"
              />
              <path
                  data-name="Path 824"
                  d="M868.873 250.594a4.159 4.159 0 003.207 2.649c1.293.1 1.945-.911 1.457-2.268a4.189 4.189 0 00-3.21-2.65c-1.284-.105-1.936.911-1.454 2.269z"
              />
              <path
                  data-name="Path 825"
                  d="M861.426 250.001a4.114 4.114 0 003.2 2.644c1.3.1 1.964-.914 1.486-2.273a4.148 4.148 0 00-3.2-2.647c-1.292-.102-1.956.917-1.486 2.276z"
              />
              <path
                  data-name="Path 826"
                  d="M692.396 240.651a3.127 3.127 0 002.864 2.547 1.963 1.963 0 002.069-2.385 3.148 3.148 0 00-2.866-2.548 1.969 1.969 0 00-2.067 2.386z"
              />
              <path
                  data-name="Path 827"
                  d="M684.54 240.408a3.084 3.084 0 002.846 2.54 1.987 1.987 0 002.094-2.39 3.109 3.109 0 00-2.848-2.543 2 2 0 00-2.092 2.393z"
              />
              <path
                  data-name="Path 828"
                  d="M676.659 240.183a3.047 3.047 0 002.829 2.535 2.013 2.013 0 002.119-2.395 3.068 3.068 0 00-2.831-2.538 2.022 2.022 0 00-2.117 2.398z"
              />
              <path
                  data-name="Path 829"
                  d="M668.775 239.974a3.009 3.009 0 002.811 2.53 2.039 2.039 0 002.142-2.4 3.029 3.029 0 00-2.812-2.533 2.05 2.05 0 00-2.141 2.403z"
              />
              <path
                  data-name="Path 830"
                  d="M660.881 239.782a2.969 2.969 0 002.791 2.525 2.065 2.065 0 002.167-2.406 2.99 2.99 0 00-2.792-2.528 2.076 2.076 0 00-2.166 2.409z"
              />
              <path
                  data-name="Path 831"
                  d="M652.977 239.607a2.934 2.934 0 002.774 2.52 2.1 2.1 0 002.191-2.411 2.948 2.948 0 00-2.774-2.523 2.1 2.1 0 00-2.191 2.414z"
              />
              <path
                  data-name="Path 832"
                  d="M645.065 239.448a2.9 2.9 0 002.756 2.515 2.123 2.123 0 002.216-2.418 2.91 2.91 0 00-2.757-2.517 2.13 2.13 0 00-2.215 2.42z"
              />
              <path
                  data-name="Path 833"
                  d="M637.145 239.308a2.856 2.856 0 002.736 2.508 2.149 2.149 0 002.234-2.423 2.873 2.873 0 00-2.737-2.512 2.159 2.159 0 00-2.233 2.427z"
              />
              <path
                  data-name="Path 834"
                  d="M629.218 239.182a2.82 2.82 0 002.717 2.5 2.178 2.178 0 002.263-2.428 2.834 2.834 0 00-2.717-2.507 2.188 2.188 0 00-2.263 2.435z"
              />
              <path
                  data-name="Path 835"
                  d="M621.285 239.076a2.778 2.778 0 002.7 2.5 2.205 2.205 0 002.286-2.433 2.8 2.8 0 00-2.7-2.5 2.216 2.216 0 00-2.286 2.433z"
              />
              <path
                  data-name="Path 836"
                  d="M613.344 238.987a2.75 2.75 0 002.679 2.493 2.236 2.236 0 002.309-2.44 2.761 2.761 0 00-2.679-2.495 2.246 2.246 0 00-2.309 2.442z"
              />
              <path
                  data-name="Path 837"
                  d="M605.4 238.914a2.711 2.711 0 002.659 2.488 2.264 2.264 0 002.331-2.445 2.726 2.726 0 00-2.657-2.49 2.277 2.277 0 00-2.333 2.447z"
              />
              <path
                  data-name="Path 838"
                  d="M597.45 238.859a2.676 2.676 0 002.637 2.482 2.3 2.3 0 002.356-2.45 2.689 2.689 0 00-2.639-2.485 2.306 2.306 0 00-2.354 2.453z"
              />
              <path
                  data-name="Path 839"
                  d="M589.496 238.821a2.639 2.639 0 002.619 2.477 2.324 2.324 0 002.378-2.455 2.656 2.656 0 00-2.619-2.48 2.337 2.337 0 00-2.378 2.458z"
              />
              <path
                  data-name="Path 840"
                  d="M581.539 238.797a2.607 2.607 0 002.6 2.472 2.353 2.353 0 002.4-2.461 2.619 2.619 0 00-2.6-2.473 2.369 2.369 0 00-2.4 2.462z"
              />
              <path
                  data-name="Path 841"
                  d="M573.579 238.794a2.574 2.574 0 002.577 2.466 2.387 2.387 0 002.423-2.466 2.587 2.587 0 00-2.577-2.468 2.4 2.4 0 00-2.423 2.468z"
              />
              <path
                  data-name="Path 842"
                  d="M565.616 238.813a2.54 2.54 0 002.557 2.46 2.417 2.417 0 002.445-2.472 2.552 2.552 0 00-2.555-2.463 2.433 2.433 0 00-2.447 2.475z"
              />
              <path
                  data-name="Path 843"
                  d="M557.654 238.845a2.5 2.5 0 002.535 2.455 2.451 2.451 0 002.466-2.477 2.52 2.52 0 00-2.535-2.458 2.463 2.463 0 00-2.466 2.48z"
              />
              <path
                  data-name="Path 844"
                  d="M336.505 246.685a1.746 1.746 0 001.877 2.3 3.515 3.515 0 003-2.617 1.754 1.754 0 00-1.875-2.3 3.545 3.545 0 00-3.002 2.617z"
              />
              <path
                  data-name="Path 845"
                  d="M328.763 247.204a1.725 1.725 0 001.85 2.3 3.559 3.559 0 003.011-2.622 1.731 1.731 0 00-1.848-2.3 3.586 3.586 0 00-3.013 2.622z"
              />
              <path
                  data-name="Path 846"
                  d="M321.036 247.736a1.7 1.7 0 001.825 2.289 3.6 3.6 0 003.026-2.625 1.709 1.709 0 00-1.821-2.293 3.634 3.634 0 00-3.03 2.629z"
              />
              <path
                  data-name="Path 847"
                  d="M313.328 248.284a1.683 1.683 0 001.8 2.284 3.65 3.65 0 003.041-2.63 1.689 1.689 0 00-1.8-2.288 3.679 3.679 0 00-3.041 2.634z"
              />
              <path
                  data-name="Path 848"
                  d="M305.638 248.848a1.663 1.663 0 001.773 2.279 3.7 3.7 0 003.056-2.634 1.668 1.668 0 00-1.77-2.283 3.726 3.726 0 00-3.059 2.638z"
              />
              <path
                  data-name="Path 849"
                  d="M297.966 249.426a1.644 1.644 0 001.746 2.274 3.738 3.738 0 003.071-2.639 1.649 1.649 0 00-1.743-2.278 3.768 3.768 0 00-3.074 2.643z"
              />
              <path
                  data-name="Path 850"
                  d="M290.314 250.019a1.622 1.622 0 001.72 2.268 3.783 3.783 0 003.085-2.642 1.628 1.628 0 00-1.716-2.273 3.813 3.813 0 00-3.089 2.647z"
              />
              <path
                  data-name="Path 851"
                  d="M282.681 250.625c-.393 1.357.366 2.37 1.694 2.264a3.832 3.832 0 003.1-2.647c.384-1.357-.371-2.373-1.689-2.268a3.867 3.867 0 00-3.105 2.651z"
              />
              <path
                  data-name="Path 852"
                  d="M275.073 251.248c-.4 1.357.343 2.368 1.666 2.259a3.88 3.88 0 003.113-2.652c.4-1.357-.348-2.371-1.663-2.263a3.914 3.914 0 00-3.116 2.656z"
              />
              <path
                  data-name="Path 853"
                  d="M267.479 251.882c-.416 1.355.319 2.365 1.639 2.254a3.928 3.928 0 003.125-2.655c.408-1.357-.324-2.368-1.636-2.258a3.958 3.958 0 00-3.128 2.659z"
              />
              <path
                  data-name="Path 854"
                  d="M244.838 253.87c-.45 1.357.249 2.358 1.557 2.239a4.068 4.068 0 003.165-2.665c.441-1.355-.254-2.36-1.554-2.243a4.107 4.107 0 00-3.168 2.669z"
              />
              <path
                  data-name="Path 855"
                  d="M229.86 255.264c-.47 1.355.2 2.353 1.5 2.231a4.17 4.17 0 003.19-2.674c.463-1.355-.209-2.355-1.5-2.233a4.2 4.2 0 00-3.19 2.676z"
              />
              <path
                  data-name="Path 856"
                  d="M1054.129 263.301a5.677 5.677 0 003.427 2.716c1.125.15 1.412-.816.639-2.162a5.726 5.726 0 00-3.429-2.716c-1.118-.154-1.406.811-.637 2.162z"
              />
              <path
                  data-name="Path 857"
                  d="M1047.624 262.427a5.616 5.616 0 003.424 2.714c1.131.152 1.43-.817.667-2.166a5.672 5.672 0 00-3.424-2.716c-1.127-.152-1.426.821-.667 2.168z"
              />
              <path
                  data-name="Path 858"
                  d="M1041.078 261.56a5.569 5.569 0 003.419 2.714c1.14.15 1.452-.822.7-2.169a5.605 5.605 0 00-3.419-2.715c-1.138-.151-1.449.821-.7 2.17z"
              />
              <path
                  data-name="Path 859"
                  d="M1034.493 260.701a5.5 5.5 0 003.416 2.712c1.148.149 1.471-.824.725-2.172a5.552 5.552 0 00-3.414-2.714c-1.14-.151-1.466.824-.727 2.174z"
              />
              <path
                  data-name="Path 860"
                  d="M1027.874 259.847a5.441 5.441 0 003.406 2.71c1.153.147 1.49-.827.755-2.176a5.5 5.5 0 00-3.409-2.714c-1.147-.146-1.484.83-.752 2.18z"
              />
              <path
                  data-name="Path 861"
                  d="M1021.207 259.001a5.4 5.4 0 003.4 2.71c1.16.145 1.512-.831.785-2.181a5.43 5.43 0 00-3.4-2.71c-1.153-.147-1.505.83-.785 2.181z"
              />
              <path
                  data-name="Path 862"
                  d="M1007.772 257.335a5.27 5.27 0 003.392 2.707c1.173.144 1.551-.836.844-2.187a5.321 5.321 0 00-3.392-2.711c-1.167-.142-1.543.839-.844 2.191z"
              />
              <path
                  data-name="Path 863"
                  d="M973.554 253.327a4.991 4.991 0 003.356 2.7c1.2.135 1.651-.852.991-2.206a5.035 5.035 0 00-3.357-2.7c-1.196-.139-1.638.852-.99 2.206z"
              />
              <path
                  data-name="Path 864"
                  d="M966.609 252.552a4.936 4.936 0 003.347 2.7c1.212.134 1.668-.856 1.019-2.211a4.973 4.973 0 00-3.349-2.7c-1.205-.135-1.66.856-1.017 2.211z"
              />
              <path
                  data-name="Path 865"
                  d="M959.627 251.79a4.876 4.876 0 003.339 2.692c1.217.132 1.686-.859 1.049-2.214a4.924 4.924 0 00-3.341-2.7c-1.209-.127-1.675.865-1.047 2.222z"
              />
              <path
                  data-name="Path 866"
                  d="M952.614 251.037a4.828 4.828 0 003.33 2.69c1.222.13 1.706-.862 1.078-2.219a4.861 4.861 0 00-3.332-2.692c-1.212-.131-1.692.865-1.076 2.221z"
              />
              <path
                  data-name="Path 867"
                  d="M945.573 250.296a4.772 4.772 0 003.32 2.687c1.228.129 1.723-.867 1.108-2.223a4.813 4.813 0 00-3.324-2.69c-1.221-.128-1.717.868-1.104 2.226z"
              />
              <path
                  data-name="Path 868"
                  d="M873.489 243.519a4.233 4.233 0 003.215 2.655c1.277.107 1.9-.906 1.392-2.268a4.274 4.274 0 00-3.217-2.659c-1.268-.106-1.889.91-1.39 2.272z"
              />
              <path
                  data-name="Path 869"
                  d="M866.13 242.908a4.191 4.191 0 003.2 2.654c1.28.105 1.918-.912 1.422-2.273a4.224 4.224 0 00-3.207-2.655c-1.268-.105-1.903.914-1.415 2.274z"
              />
              <path
                  data-name="Path 870"
                  d="M691.161 232.944a3.13 3.13 0 002.853 2.55 1.949 1.949 0 002.039-2.39 3.149 3.149 0 00-2.853-2.552 1.957 1.957 0 00-2.039 2.392z"
              />
              <path
                  data-name="Path 871"
                  d="M683.369 232.7a3.087 3.087 0 002.832 2.545 1.973 1.973 0 002.065-2.393 3.109 3.109 0 00-2.836-2.548 1.982 1.982 0 00-2.061 2.396z"
              />
              <path
                  data-name="Path 872"
                  d="M675.558 232.474a3.048 3.048 0 002.814 2.54 2 2 0 002.09-2.4 3.069 3.069 0 00-2.816-2.543 2.009 2.009 0 00-2.088 2.403z"
              />
              <path
                  data-name="Path 873"
                  d="M667.74 232.264a3.006 3.006 0 002.8 2.535 2.027 2.027 0 002.116-2.4 3.028 3.028 0 00-2.8-2.537 2.033 2.033 0 00-2.116 2.402z"
              />
              <path
                  data-name="Path 874"
                  d="M659.913 232.071a2.969 2.969 0 002.777 2.53 2.053 2.053 0 002.139-2.411 2.989 2.989 0 00-2.779-2.532 2.062 2.062 0 00-2.137 2.413z"
              />
              <path
                  data-name="Path 875"
                  d="M652.078 231.896a2.928 2.928 0 002.759 2.525 2.081 2.081 0 002.162-2.416 2.946 2.946 0 00-2.759-2.527 2.09 2.09 0 00-2.162 2.418z"
              />
              <path
                  data-name="Path 876"
                  d="M644.232 231.737a2.892 2.892 0 002.741 2.518 2.105 2.105 0 002.187-2.42 2.906 2.906 0 00-2.741-2.522 2.118 2.118 0 00-2.187 2.424z"
              />
              <path
                  data-name="Path 877"
                  d="M636.381 231.597a2.855 2.855 0 002.719 2.513 2.138 2.138 0 002.214-2.426 2.872 2.872 0 00-2.722-2.517 2.146 2.146 0 00-2.211 2.43z"
              />
              <path
                  data-name="Path 878"
                  d="M628.522 231.472a2.814 2.814 0 002.7 2.508 2.165 2.165 0 002.236-2.431 2.834 2.834 0 00-2.7-2.512 2.177 2.177 0 00-2.236 2.435z"
              />
              <path
                  data-name="Path 879"
                  d="M620.656 231.365a2.778 2.778 0 002.68 2.5 2.193 2.193 0 002.259-2.438 2.8 2.8 0 00-2.68-2.5 2.2 2.2 0 00-2.259 2.438z"
              />
              <path
                  data-name="Path 880"
                  d="M612.784 231.276a2.741 2.741 0 002.66 2.5 2.223 2.223 0 002.284-2.443 2.758 2.758 0 00-2.66-2.5 2.235 2.235 0 00-2.284 2.443z"
              />
              <path
                  data-name="Path 881"
                  d="M604.908 231.203a2.7 2.7 0 002.639 2.492 2.251 2.251 0 002.308-2.448 2.72 2.72 0 00-2.64-2.495 2.264 2.264 0 00-2.307 2.451z"
              />
              <path
                  data-name="Path 882"
                  d="M597.026 231.148a2.668 2.668 0 002.619 2.487 2.283 2.283 0 002.333-2.453 2.687 2.687 0 00-2.62-2.49 2.3 2.3 0 00-2.332 2.456z"
              />
              <path
                  data-name="Path 883"
                  d="M589.141 231.109a2.631 2.631 0 002.6 2.48 2.313 2.313 0 002.355-2.458 2.647 2.647 0 00-2.6-2.483 2.326 2.326 0 00-2.355 2.461z"
              />
              <path
                  data-name="Path 884"
                  d="M581.251 231.091a2.6 2.6 0 002.577 2.475 2.347 2.347 0 002.38-2.465 2.616 2.616 0 00-2.578-2.478 2.359 2.359 0 00-2.379 2.468z"
              />
              <path
                  data-name="Path 885"
                  d="M573.358 231.087a2.563 2.563 0 002.557 2.47 2.377 2.377 0 002.4-2.47 2.579 2.579 0 00-2.557-2.473 2.391 2.391 0 00-2.4 2.473z"
              />
              <path
                  data-name="Path 886"
                  d="M565.464 231.099a2.53 2.53 0 002.535 2.465 2.408 2.408 0 002.423-2.477 2.543 2.543 0 00-2.535-2.466 2.424 2.424 0 00-2.423 2.478z"
              />
              <path
                  data-name="Path 887"
                  d="M557.569 231.134a2.5 2.5 0 002.513 2.46 2.441 2.441 0 002.446-2.482 2.509 2.509 0 00-2.513-2.462 2.456 2.456 0 00-2.446 2.484z"
              />
              <path
                  data-name="Path 888"
                  d="M549.673 231.184a2.48 2.48 0 102.468-2.49 2.488 2.488 0 00-2.468 2.49z"
              />
              <path
                  data-name="Path 889"
                  d="M525.979 231.439a2.366 2.366 0 002.425 2.436 2.576 2.576 0 002.533-2.5 2.379 2.379 0 00-2.425-2.44 2.59 2.59 0 00-2.533 2.504z"
              />
              <path
                  data-name="Path 890"
                  d="M518.087 231.557a2.334 2.334 0 002.4 2.431 2.609 2.609 0 002.555-2.508 2.345 2.345 0 00-2.4-2.433 2.625 2.625 0 00-2.555 2.51z"
              />
              <path
                  data-name="Path 891"
                  d="M502.303 231.848a2.271 2.271 0 002.358 2.42 2.679 2.679 0 002.6-2.518 2.282 2.282 0 00-2.356-2.421 2.7 2.7 0 00-2.602 2.519z"
              />
              <path
                  data-name="Path 892"
                  d="M315.315 240.599a1.668 1.668 0 001.765 2.289 3.668 3.668 0 003.035-2.635 1.674 1.674 0 00-1.761-2.293 3.7 3.7 0 00-3.039 2.639z"
              />
              <path
                  data-name="Path 893"
                  d="M307.69 241.164a1.649 1.649 0 001.738 2.284 3.716 3.716 0 003.05-2.64 1.652 1.652 0 00-1.735-2.286 3.744 3.744 0 00-3.053 2.642z"
              />
              <path
                  data-name="Path 894"
                  d="M300.082 241.744a1.629 1.629 0 001.711 2.279 3.761 3.761 0 003.065-2.645c.371-1.359-.393-2.383-1.708-2.281a3.791 3.791 0 00-3.068 2.647z"
              />
              <path
                  data-name="Path 895"
                  d="M292.493 242.339c-.389 1.362.364 2.378 1.684 2.274a3.807 3.807 0 003.08-2.649c.383-1.36-.369-2.38-1.681-2.276a3.842 3.842 0 00-3.083 2.651z"
              />
              <path
                  data-name="Path 896"
                  d="M284.925 242.947c-.4 1.359.341 2.373 1.658 2.268a3.857 3.857 0 003.095-2.652c.393-1.36-.348-2.378-1.656-2.271a3.884 3.884 0 00-3.097 2.655z"
              />
              <path
                  data-name="Path 897"
                  d="M277.376 243.571c-.413 1.357.318 2.371 1.631 2.263a3.9 3.9 0 003.108-2.657c.4-1.359-.324-2.375-1.628-2.266a3.936 3.936 0 00-3.111 2.66z"
              />
              <path
                  data-name="Path 898"
                  d="M269.849 244.207c-.424 1.359.3 2.37 1.6 2.258a3.952 3.952 0 003.123-2.661c.416-1.359-.3-2.371-1.6-2.261a3.982 3.982 0 00-3.123 2.664z"
              />
              <path
                  data-name="Path 899"
                  d="M232.545 247.599c-.48 1.357.177 2.356 1.467 2.234a4.2 4.2 0 003.187-2.679c.473-1.357-.182-2.36-1.464-2.238a4.235 4.235 0 00-3.19 2.683z"
              />
              <path
                  data-name="Path 900"
                  d="M1043.319 254.801a5.649 5.649 0 003.419 2.717c1.123.154 1.409-.819.638-2.166a5.708 5.708 0 00-3.419-2.72c-1.116-.152-1.403.819-.638 2.169z"
              />
              <path
                  data-name="Path 901"
                  d="M1036.825 253.929a5.6 5.6 0 003.414 2.717c1.133.15 1.43-.819.668-2.171a5.636 5.636 0 00-3.414-2.717c-1.124-.152-1.421.825-.668 2.171z"
              />
              <path
                  data-name="Path 902"
                  d="M1023.725 252.208a5.471 5.471 0 003.4 2.716c1.145.149 1.471-.826.727-2.177a5.532 5.532 0 00-3.406-2.717c-1.133-.15-1.459.826-.721 2.178z"
              />
              <path
                  data-name="Path 903"
                  d="M962.966 244.885a4.962 4.962 0 003.344 2.7c1.2.135 1.643-.856.986-2.212a5 5 0 00-3.346-2.7c-1.191-.138-1.632.855-.984 2.212z"
              />
              <path
                  data-name="Path 904"
                  d="M956.044 244.12a4.911 4.911 0 003.336 2.7c1.2.134 1.659-.861 1.016-2.217a4.949 4.949 0 00-3.339-2.7c-1.197-.134-1.651.858-1.013 2.217z"
              />
              <path
                  data-name="Path 905"
                  d="M942.104 242.621a4.807 4.807 0 003.317 2.69c1.218.129 1.7-.867 1.074-2.224a4.839 4.839 0 00-3.32-2.694c-1.208-.128-1.688.869-1.071 2.228z"
              />
              <path
                  data-name="Path 906"
                  d="M935.087 241.888a4.749 4.749 0 003.309 2.689c1.222.127 1.716-.871 1.1-2.229a4.784 4.784 0 00-3.31-2.692c-1.212-.131-1.705.869-1.099 2.232z"
              />
              <path
                  data-name="Path 907"
                  d="M892.368 237.732a4.421 4.421 0 003.247 2.67c1.253.115 1.823-.894 1.273-2.256a4.458 4.458 0 00-3.249-2.672c-1.244-.113-1.808.898-1.271 2.258z"
              />
              <path
                  data-name="Path 908"
                  d="M885.151 237.084a4.375 4.375 0 003.235 2.667c1.255.112 1.841-.9 1.3-2.261a4.4 4.4 0 00-3.237-2.669c-1.248-.114-1.828.9-1.298 2.263z"
              />
              <path
                  data-name="Path 909"
                  d="M877.907 236.447a4.319 4.319 0 003.224 2.664c1.26.109 1.857-.9 1.33-2.266a4.351 4.351 0 00-3.225-2.665c-1.254-.111-1.849.905-1.329 2.267z"
              />
              <path
                  data-name="Path 910"
                  d="M863.341 235.212a4.214 4.214 0 003.2 2.657c1.27.105 1.892-.912 1.387-2.274a4.247 4.247 0 00-3.2-2.659c-1.265-.108-1.885.914-1.387 2.276z"
              />
              <path
                  data-name="Path 911"
                  d="M804.135 230.826a3.811 3.811 0 003.088 2.625c1.3.085 2.024-.949 1.609-2.314a3.837 3.837 0 00-3.092-2.625c-1.291-.087-2.005.951-1.605 2.314z"
              />
              <path
                  data-name="Path 912"
                  d="M689.899 225.225a3.135 3.135 0 002.839 2.553 1.935 1.935 0 002.009-2.393 3.154 3.154 0 00-2.839-2.555 1.944 1.944 0 00-2.009 2.395z"
              />
              <path
                  data-name="Path 913"
                  d="M682.17 224.981a3.092 3.092 0 002.821 2.548 1.961 1.961 0 002.035-2.4 3.111 3.111 0 00-2.821-2.55 1.97 1.97 0 00-2.035 2.402z"
              />
              <path
                  data-name="Path 914"
                  d="M674.433 224.754a3.05 3.05 0 002.8 2.543 1.986 1.986 0 002.06-2.4 3.071 3.071 0 00-2.8-2.545 1.994 1.994 0 00-2.06 2.402z"
              />
              <path
                  data-name="Path 915"
                  d="M666.685 224.546a3.008 3.008 0 002.782 2.537 2.013 2.013 0 002.085-2.408 3.03 3.03 0 00-2.782-2.54 2.023 2.023 0 00-2.085 2.411z"
              />
              <path
                  data-name="Path 916"
                  d="M658.925 224.351a2.972 2.972 0 002.762 2.533 2.04 2.04 0 002.112-2.413 2.986 2.986 0 00-2.764-2.535 2.048 2.048 0 00-2.11 2.415z"
              />
              <path
                  data-name="Path 917"
                  d="M651.159 224.176a2.929 2.929 0 002.744 2.527 2.066 2.066 0 002.136-2.418 2.946 2.946 0 00-2.744-2.53 2.078 2.078 0 00-2.136 2.421z"
              />
              <path
                  data-name="Path 918"
                  d="M643.385 224.018a2.886 2.886 0 002.722 2.522 2.1 2.1 0 002.162-2.425 2.908 2.908 0 00-2.724-2.523 2.1 2.1 0 00-2.16 2.426z"
              />
              <path
                  data-name="Path 919"
                  d="M635.602 223.876a2.848 2.848 0 002.7 2.517 2.124 2.124 0 002.186-2.43 2.867 2.867 0 00-2.7-2.518 2.132 2.132 0 00-2.186 2.431z"
              />
              <path
                  data-name="Path 920"
                  d="M627.812 223.751a2.813 2.813 0 002.682 2.512 2.154 2.154 0 002.211-2.436 2.828 2.828 0 00-2.684-2.513 2.163 2.163 0 00-2.209 2.437z"
              />
              <path
                  data-name="Path 921"
                  d="M620.014 223.644a2.775 2.775 0 002.664 2.5 2.18 2.18 0 002.234-2.44 2.79 2.79 0 00-2.662-2.508 2.194 2.194 0 00-2.236 2.448z"
              />
              <path
                  data-name="Path 922"
                  d="M612.213 223.554a2.736 2.736 0 002.642 2.5 2.21 2.21 0 002.259-2.445 2.752 2.752 0 00-2.642-2.5 2.223 2.223 0 00-2.259 2.445z"
              />
              <path
                  data-name="Path 923"
                  d="M604.405 223.483a2.7 2.7 0 002.622 2.495 2.241 2.241 0 002.283-2.451 2.715 2.715 0 00-2.622-2.5 2.254 2.254 0 00-2.283 2.456z"
              />
              <path
                  data-name="Path 924"
                  d="M596.595 223.424a2.659 2.659 0 002.6 2.488 2.27 2.27 0 002.308-2.456 2.678 2.678 0 00-2.6-2.492 2.284 2.284 0 00-2.308 2.46z"
              />
              <path
                  data-name="Path 925"
                  d="M588.777 223.389a2.627 2.627 0 002.578 2.485 2.3 2.3 0 002.331-2.463 2.64 2.64 0 00-2.578-2.487 2.318 2.318 0 00-2.331 2.465z"
              />
              <path
                  data-name="Path 926"
                  d="M580.956 223.369a2.59 2.59 0 002.557 2.478 2.335 2.335 0 002.355-2.466 2.605 2.605 0 00-2.557-2.482 2.347 2.347 0 00-2.355 2.47z"
              />
              <path
                  data-name="Path 927"
                  d="M573.136 223.368a2.555 2.555 0 002.535 2.473 2.366 2.366 0 002.378-2.473 2.571 2.571 0 00-2.534-2.474 2.381 2.381 0 00-2.379 2.474z"
              />
              <path
                  data-name="Path 928"
                  d="M565.312 223.383a2.518 2.518 0 002.513 2.466 2.4 2.4 0 002.4-2.478 2.537 2.537 0 00-2.515-2.47 2.412 2.412 0 00-2.398 2.482z"
              />
              <path
                  data-name="Path 929"
                  d="M557.485 223.414a2.486 2.486 0 002.492 2.462 2.433 2.433 0 002.425-2.483 2.5 2.5 0 00-2.492-2.465 2.447 2.447 0 00-2.425 2.486z"
              />
              <path
                  data-name="Path 930"
                  d="M549.658 223.464a2.458 2.458 0 102.448-2.492 2.455 2.455 0 00-2.448 2.492z"
              />
              <path
                  data-name="Path 931"
                  d="M541.83 223.531a2.42 2.42 0 002.446 2.451 2.5 2.5 0 002.47-2.495 2.431 2.431 0 00-2.446-2.453 2.513 2.513 0 00-2.47 2.497z"
              />
              <path
                  data-name="Path 932"
                  d="M534 223.616a2.387 2.387 0 002.425 2.445 2.534 2.534 0 002.492-2.5 2.4 2.4 0 00-2.425-2.448 2.549 2.549 0 00-2.492 2.503z"
              />
              <path
                  data-name="Path 933"
                  d="M526.175 223.722a2.353 2.353 0 002.4 2.44 2.57 2.57 0 002.515-2.507 2.368 2.368 0 00-2.4-2.441 2.584 2.584 0 00-2.515 2.508z"
              />
              <path
                  data-name="Path 934"
                  d="M518.351 223.838a2.323 2.323 0 002.38 2.433 2.6 2.6 0 002.535-2.512 2.334 2.334 0 00-2.378-2.435 2.619 2.619 0 00-2.537 2.514z"
              />
              <path
                  data-name="Path 935"
                  d="M510.526 223.975a2.292 2.292 0 002.356 2.428 2.641 2.641 0 002.558-2.517 2.3 2.3 0 00-2.356-2.431 2.658 2.658 0 00-2.558 2.52z"
              />
              <path
                  data-name="Path 936"
                  d="M502.705 224.13a2.26 2.26 0 002.333 2.423 2.676 2.676 0 002.578-2.522 2.268 2.268 0 00-2.331-2.425 2.693 2.693 0 00-2.58 2.524z"
              />
              <path
                  data-name="Path 937"
                  d="M494.89 224.301a2.231 2.231 0 002.309 2.418 2.713 2.713 0 002.6-2.528 2.242 2.242 0 00-2.308-2.42 2.733 2.733 0 00-2.601 2.53z"
              />
              <path
                  data-name="Path 938"
                  d="M487.075 224.49a2.2 2.2 0 002.286 2.413 2.753 2.753 0 002.622-2.533 2.211 2.211 0 00-2.284-2.413 2.767 2.767 0 00-2.624 2.533z"
              />
              <path
                  data-name="Path 939"
                  d="M242.629 239.212c-.478 1.36.175 2.363 1.457 2.243a4.177 4.177 0 003.172-2.68c.47-1.359-.18-2.365-1.456-2.244a4.217 4.217 0 00-3.173 2.681z"
              />
              <path
                  data-name="Path 940"
                  d="M235.282 239.919c-.49 1.36.152 2.361 1.43 2.238a4.228 4.228 0 003.183-2.682c.481-1.36-.157-2.365-1.429-2.239a4.256 4.256 0 00-3.184 2.683z"
              />
              <path
                  data-name="Path 941"
                  d="M227.955 240.64c-.5 1.359.129 2.358 1.4 2.233a4.284 4.284 0 003.2-2.687c.491-1.359-.134-2.361-1.4-2.236a4.318 4.318 0 00-3.2 2.69z"
              />
              <path
                  data-name="Path 942"
                  d="M220.652 241.372c-.511 1.36.105 2.356 1.375 2.229a4.336 4.336 0 003.208-2.69c.5-1.359-.11-2.358-1.372-2.231a4.369 4.369 0 00-3.211 2.692z"
              />
              <path
                  data-name="Path 943"
                  d="M213.374 242.12c-.521 1.357.082 2.353 1.347 2.223a4.381 4.381 0 003.22-2.692c.515-1.359-.087-2.356-1.344-2.226a4.419 4.419 0 00-3.223 2.695z"
              />
              <path
                  data-name="Path 944"
                  d="M206.124 242.879c-.533 1.357.059 2.349 1.318 2.219a4.437 4.437 0 003.232-2.7c.526-1.359-.064-2.353-1.317-2.221a4.471 4.471 0 00-3.233 2.702z"
              />
              <path
                  data-name="Path 945"
                  d="M1038.979 247.17a5.689 5.689 0 003.414 2.721c1.115.154 1.389-.817.612-2.167a5.732 5.732 0 00-3.412-2.722c-1.109-.153-1.379.818-.614 2.168z"
              />
              <path
                  data-name="Path 946"
                  d="M1032.537 246.292a5.637 5.637 0 003.409 2.72c1.123.152 1.409-.82.642-2.171a5.676 5.676 0 00-3.409-2.72c-1.117-.153-1.402.821-.642 2.171z"
              />
              <path
                  data-name="Path 947"
                  d="M1026.059 245.425a5.577 5.577 0 003.4 2.719c1.128.15 1.427-.824.668-2.174a5.615 5.615 0 00-3.4-2.721c-1.121-.152-1.422.824-.668 2.176z"
              />
              <path
                  data-name="Path 948"
                  d="M1019.54 244.565a5.523 5.523 0 003.4 2.717c1.133.149 1.447-.826.7-2.177a5.561 5.561 0 00-3.4-2.721c-1.13-.149-1.442.827-.7 2.181z"
              />
              <path
                  data-name="Path 949"
                  d="M966.111 237.989a5.062 5.062 0 003.347 2.706c1.185.135 1.6-.854.926-2.211a5.1 5.1 0 00-3.349-2.705c-1.177-.139-1.589.853-.924 2.21z"
              />
              <path
                  data-name="Path 950"
                  d="M952.414 236.443a4.945 4.945 0 003.332 2.7c1.195.132 1.636-.861.983-2.219a4.987 4.987 0 00-3.334-2.7c-1.188-.135-1.626.859-.981 2.219z"
              />
              <path
                  data-name="Path 951"
                  d="M938.591 234.935a4.835 4.835 0 003.314 2.694c1.206.13 1.673-.867 1.041-2.226a4.876 4.876 0 00-3.315-2.7c-1.2-.125-1.667.875-1.04 2.232z"
              />
              <path
                  data-name="Path 952"
                  d="M931.635 234.203a4.781 4.781 0 003.3 2.69c1.212.127 1.693-.871 1.07-2.231a4.822 4.822 0 00-3.307-2.694c-1.2-.13-1.68.87-1.063 2.235z"
              />
              <path
                  data-name="Path 953"
                  d="M889.281 230.033a4.45 4.45 0 003.242 2.674c1.24.115 1.8-.9 1.238-2.258a4.488 4.488 0 00-3.243-2.675c-1.23-.114-1.787.897-1.237 2.259z"
              />
              <path
                  data-name="Path 954"
                  d="M882.126 229.383a4.394 4.394 0 003.23 2.669c1.247.114 1.813-.9 1.267-2.263a4.424 4.424 0 00-3.232-2.67c-1.237-.114-1.803.9-1.265 2.264z"
              />
              <path
                  data-name="Path 955"
                  d="M874.946 228.743a4.338 4.338 0 003.217 2.667c1.252.11 1.831-.9 1.3-2.266a4.379 4.379 0 00-3.22-2.669c-1.249-.112-1.825.906-1.297 2.268z"
              />
              <path
                  data-name="Path 956"
                  d="M860.505 227.507a4.237 4.237 0 003.193 2.659c1.258.105 1.865-.912 1.352-2.276a4.269 4.269 0 00-3.2-2.66c-1.244-.108-1.848.913-1.345 2.277z"
              />
              <path
                  data-name="Path 957"
                  d="M794.392 222.629a3.778 3.778 0 003.066 2.622c1.293.084 2.012-.956 1.6-2.321a3.807 3.807 0 00-3.068-2.624c-1.282-.084-1.997.957-1.598 2.323z"
              />
              <path
                  data-name="Path 958"
                  d="M786.943 222.159a3.728 3.728 0 003.05 2.619c1.3.08 2.029-.961 1.631-2.326a3.756 3.756 0 00-3.051-2.62c-1.291-.081-2.018.961-1.63 2.327z"
              />
              <path
                  data-name="Path 959"
                  d="M703.895 218.038a3.221 3.221 0 002.863 2.565 1.87 1.87 0 001.927-2.383 3.241 3.241 0 00-2.863-2.567 1.878 1.878 0 00-1.927 2.385z"
              />
              <path
                  data-name="Path 960"
                  d="M696.259 217.761a3.174 3.174 0 002.844 2.558 1.894 1.894 0 001.954-2.386 3.2 3.2 0 00-2.846-2.563 1.9 1.9 0 00-1.952 2.391z"
              />
              <path
                  data-name="Path 961"
                  d="M688.612 217.5a3.134 3.134 0 002.824 2.553 1.92 1.92 0 001.98-2.393 3.156 3.156 0 00-2.826-2.557 1.929 1.929 0 00-1.978 2.397z"
              />
              <path
                  data-name="Path 962"
                  d="M680.956 217.256a3.087 3.087 0 002.8 2.548 1.945 1.945 0 002.007-2.4 3.114 3.114 0 00-2.807-2.552 1.954 1.954 0 00-2 2.404z"
              />
              <path
                  data-name="Path 963"
                  d="M673.288 217.029a3.046 3.046 0 002.786 2.543 1.971 1.971 0 002.032-2.4 3.07 3.07 0 00-2.787-2.547 1.981 1.981 0 00-2.031 2.404z"
              />
              <path
                  data-name="Path 964"
                  d="M665.608 216.818a3 3 0 002.766 2.538 2 2 0 002.057-2.408 3.025 3.025 0 00-2.766-2.542 2.007 2.007 0 00-2.057 2.412z"
              />
              <path
                  data-name="Path 965"
                  d="M657.922 216.625a2.963 2.963 0 002.746 2.535 2.027 2.027 0 002.084-2.415 2.989 2.989 0 00-2.747-2.537 2.035 2.035 0 00-2.083 2.417z"
              />
              <path
                  data-name="Path 966"
                  d="M650.225 216.449a2.924 2.924 0 002.726 2.528 2.053 2.053 0 002.109-2.42 2.942 2.942 0 00-2.727-2.53 2.062 2.062 0 00-2.108 2.422z"
              />
              <path
                  data-name="Path 967"
                  d="M642.519 216.29a2.887 2.887 0 002.707 2.523 2.081 2.081 0 002.134-2.426 2.905 2.905 0 00-2.707-2.525 2.093 2.093 0 00-2.134 2.428z"
              />
              <path
                  data-name="Path 968"
                  d="M634.807 216.148a2.849 2.849 0 002.685 2.518 2.112 2.112 0 002.161-2.431 2.865 2.865 0 00-2.687-2.52 2.121 2.121 0 00-2.159 2.433z"
              />
              <path
                  data-name="Path 969"
                  d="M627.089 216.025a2.805 2.805 0 002.665 2.512 2.138 2.138 0 002.184-2.436 2.823 2.823 0 00-2.665-2.515 2.151 2.151 0 00-2.184 2.439z"
              />
              <path
                  data-name="Path 970"
                  d="M619.364 215.915a2.769 2.769 0 002.644 2.507 2.167 2.167 0 002.209-2.441 2.783 2.783 0 00-2.644-2.51 2.181 2.181 0 00-2.209 2.444z"
              />
              <path
                  data-name="Path 971"
                  d="M611.633 215.827a2.729 2.729 0 002.622 2.5 2.2 2.2 0 002.234-2.448 2.745 2.745 0 00-2.624-2.5 2.211 2.211 0 00-2.232 2.448z"
              />
              <path
                  data-name="Path 972"
                  d="M603.896 215.754a2.692 2.692 0 002.6 2.5 2.229 2.229 0 002.258-2.453 2.706 2.706 0 00-2.6-2.5 2.242 2.242 0 00-2.258 2.453z"
              />
              <path
                  data-name="Path 973"
                  d="M596.158 215.701a2.652 2.652 0 002.58 2.49 2.26 2.26 0 002.283-2.458 2.67 2.67 0 00-2.58-2.493 2.272 2.272 0 00-2.283 2.461z"
              />
              <path
                  data-name="Path 974"
                  d="M588.41 215.662a2.617 2.617 0 002.558 2.485 2.292 2.292 0 002.308-2.463 2.634 2.634 0 00-2.56-2.487 2.3 2.3 0 00-2.306 2.465z"
              />
              <path
                  data-name="Path 975"
                  d="M580.661 215.642a2.583 2.583 0 002.537 2.48 2.324 2.324 0 002.331-2.47 2.6 2.6 0 00-2.537-2.482 2.337 2.337 0 00-2.331 2.472z"
              />
              <path
                  data-name="Path 976"
                  d="M572.91 215.64a2.547 2.547 0 002.515 2.473 2.358 2.358 0 002.355-2.475 2.562 2.562 0 00-2.515-2.477 2.37 2.37 0 00-2.355 2.479z"
              />
              <path
                  data-name="Path 977"
                  d="M565.157 215.655a2.51 2.51 0 002.492 2.468 2.391 2.391 0 002.38-2.48 2.525 2.525 0 00-2.493-2.471 2.4 2.4 0 00-2.379 2.483z"
              />
              <path
                  data-name="Path 978"
                  d="M557.401 215.687a2.477 2.477 0 002.47 2.463 2.421 2.421 0 002.4-2.485 2.49 2.49 0 00-2.468-2.466 2.439 2.439 0 00-2.402 2.488z"
              />
              <path
                  data-name="Path 979"
                  d="M549.646 215.737a2.436 2.436 0 102.425-2.493 2.47 2.47 0 00-2.425 2.493z"
              />
              <path
                  data-name="Path 980"
                  d="M541.889 215.804a2.411 2.411 0 002.425 2.453 2.493 2.493 0 002.448-2.5 2.423 2.423 0 00-2.423-2.455 2.506 2.506 0 00-2.45 2.502z"
              />
              <path
                  data-name="Path 981"
                  d="M534.133 215.889a2.374 2.374 0 002.4 2.446 2.526 2.526 0 002.471-2.5 2.385 2.385 0 00-2.4-2.448 2.539 2.539 0 00-2.471 2.502z"
              />
              <path
                  data-name="Path 982"
                  d="M526.378 215.993a2.34 2.34 0 002.376 2.44 2.562 2.562 0 002.495-2.507 2.356 2.356 0 00-2.378-2.443 2.575 2.575 0 00-2.493 2.51z"
              />
              <path
                  data-name="Path 983"
                  d="M518.623 216.111a2.309 2.309 0 002.355 2.436 2.6 2.6 0 002.517-2.513 2.324 2.324 0 00-2.355-2.438 2.614 2.614 0 00-2.517 2.515z"
              />
              <path
                  data-name="Path 984"
                  d="M510.871 216.25a2.279 2.279 0 002.331 2.43 2.635 2.635 0 002.538-2.518 2.292 2.292 0 00-2.33-2.434 2.652 2.652 0 00-2.539 2.522z"
              />
              <path
                  data-name="Path 985"
                  d="M503.121 216.404a2.247 2.247 0 002.308 2.425 2.67 2.67 0 002.56-2.523 2.259 2.259 0 00-2.306-2.426 2.687 2.687 0 00-2.562 2.524z"
              />
              <path
                  data-name="Path 986"
                  d="M495.373 216.576a2.217 2.217 0 002.283 2.42 2.708 2.708 0 002.583-2.53 2.227 2.227 0 00-2.283-2.42 2.726 2.726 0 00-2.583 2.53z"
              />
              <path
                  data-name="Path 987"
                  d="M487.631 216.766a2.185 2.185 0 002.258 2.413 2.748 2.748 0 002.605-2.535 2.2 2.2 0 00-2.258-2.415 2.765 2.765 0 00-2.605 2.537z"
              />
              <path
                  data-name="Path 988"
                  d="M479.893 216.974a2.155 2.155 0 002.234 2.408 2.784 2.784 0 002.625-2.54 2.166 2.166 0 00-2.234-2.41 2.8 2.8 0 00-2.625 2.542z"
              />
              <path
                  data-name="Path 989"
                  d="M472.16 217.197a2.128 2.128 0 002.211 2.4 2.822 2.822 0 002.647-2.543 2.137 2.137 0 00-2.209-2.4 2.843 2.843 0 00-2.649 2.543z"
              />
              <path
                  data-name="Path 990"
                  d="M433.582 218.576a1.987 1.987 0 002.085 2.375 3.025 3.025 0 002.751-2.572 2 2 0 00-2.086-2.376 3.046 3.046 0 00-2.75 2.573z"
              />
              <path
                  data-name="Path 991"
                  d="M402.869 219.987a1.886 1.886 0 001.985 2.353 3.2 3.2 0 002.827-2.592 1.894 1.894 0 00-1.982-2.361 3.22 3.22 0 00-2.83 2.6z"
              />
              <path
                  data-name="Path 992"
                  d="M252.666 230.825c-.475 1.362.175 2.368 1.45 2.249a4.154 4.154 0 003.153-2.679c.466-1.362-.18-2.37-1.447-2.251a4.184 4.184 0 00-3.156 2.681z"
              />
              <path
                  data-name="Path 993"
                  d="M238.066 232.231c-.5 1.362.129 2.363 1.4 2.239a4.254 4.254 0 003.18-2.685c.49-1.362-.134-2.366-1.392-2.243a4.3 4.3 0 00-3.188 2.689z"
              />
              <path
                  data-name="Path 994"
                  d="M230.803 232.953c-.508 1.36.105 2.36 1.369 2.234a4.311 4.311 0 003.192-2.69c.5-1.36-.11-2.363-1.365-2.236a4.339 4.339 0 00-3.196 2.692z"
              />
              <path
                  data-name="Path 995"
                  d="M223.563 233.689c-.52 1.359.08 2.358 1.339 2.229a4.36 4.36 0 003.205-2.694c.513-1.36-.087-2.36-1.337-2.231a4.392 4.392 0 00-3.207 2.696z"
              />
              <path
                  data-name="Path 996"
                  d="M216.348 234.436c-.531 1.36.057 2.356 1.312 2.226a4.417 4.417 0 003.217-2.7c.523-1.359-.064-2.358-1.31-2.228a4.45 4.45 0 00-3.219 2.702z"
              />
              <path
                  data-name="Path 997"
                  d="M209.158 235.199c-.543 1.359.033 2.353 1.283 2.221a4.475 4.475 0 003.229-2.7c.533-1.36-.04-2.354-1.282-2.223a4.5 4.5 0 00-3.23 2.702z"
              />
              <path
                  data-name="Path 998"
                  d="M201.994 235.97c-.553 1.359.01 2.351 1.257 2.216a4.519 4.519 0 003.239-2.7c.545-1.36-.015-2.353-1.253-2.219a4.563 4.563 0 00-3.243 2.703z"
              />
              <path
                  data-name="Path 999"
                  d="M194.858 236.756c-.563 1.359-.013 2.348 1.228 2.211a4.572 4.572 0 003.25-2.705c.556-1.359.008-2.35-1.225-2.214a4.616 4.616 0 00-3.253 2.708z"
              />
              <path
                  data-name="Path 1000"
                  d="M187.749 237.552c-.575 1.359-.037 2.345 1.2 2.208a4.635 4.635 0 003.262-2.711c.565-1.357.03-2.348-1.2-2.209a4.672 4.672 0 00-3.262 2.712z"
              />
              <path
                  data-name="Path 1001"
                  d="M180.667 238.361c-.585 1.357-.06 2.343 1.171 2.2a4.691 4.691 0 003.274-2.712c.577-1.357.052-2.345-1.171-2.2a4.722 4.722 0 00-3.274 2.712z"
              />
              <path
                  data-name="Path 1002"
                  d="M173.614 239.18c-.595 1.357-.082 2.341 1.145 2.2a4.74 4.74 0 003.282-2.714c.587-1.359.077-2.344-1.141-2.2a4.78 4.78 0 00-3.286 2.714z"
              />
              <path
                  data-name="Path 1003"
                  d="M1072.14 244.959a6.087 6.087 0 003.424 2.725c1.066.16 1.253-.8.421-2.146a6.124 6.124 0 00-3.421-2.727c-1.057-.158-1.25.801-.424 2.148z"
              />
              <path
                  data-name="Path 1004"
                  d="M1059.789 243.124a5.963 5.963 0 003.419 2.726c1.079.157 1.292-.805.478-2.154a6.011 6.011 0 00-3.419-2.727c-1.074-.158-1.287.808-.478 2.155z"
              />
              <path
                  data-name="Path 1005"
                  d="M969.065 231.103a5.157 5.157 0 003.352 2.71c1.168.139 1.557-.849.867-2.207a5.2 5.2 0 00-3.352-2.711c-1.161-.138-1.554.851-.867 2.208z"
              />
              <path
                  data-name="Path 1006"
                  d="M962.323 230.312a5.09 5.09 0 003.344 2.705c1.171.137 1.576-.852.894-2.211a5.133 5.133 0 00-3.346-2.707c-1.166-.138-1.565.854-.892 2.213z"
              />
              <path
                  data-name="Path 1007"
                  d="M955.548 229.53a5.042 5.042 0 003.337 2.7c1.18.135 1.593-.856.922-2.214a5.08 5.08 0 00-3.337-2.707c-1.171-.131-1.585.859-.922 2.221z"
              />
              <path
                  data-name="Path 1008"
                  d="M928.137 226.511a4.813 4.813 0 003.3 2.694c1.2.129 1.666-.871 1.036-2.233a4.85 4.85 0 00-3.3-2.7c-1.195-.123-1.658.877-1.036 2.239z"
              />
              <path
                  data-name="Path 1009"
                  d="M886.15 222.33a4.474 4.474 0 003.237 2.675c1.23.115 1.77-.9 1.2-2.258a4.509 4.509 0 00-3.237-2.677c-1.222-.115-1.756.898-1.2 2.26z"
              />
              <path
                  data-name="Path 1010"
                  d="M879.057 221.676a4.417 4.417 0 003.224 2.672c1.235.114 1.788-.9 1.233-2.263a4.451 4.451 0 00-3.225-2.674c-1.225-.115-1.776.903-1.232 2.265z"
              />
              <path
                  data-name="Path 1011"
                  d="M871.939 221.037a4.366 4.366 0 003.212 2.669c1.24.11 1.8-.9 1.262-2.268a4.4 4.4 0 00-3.213-2.67c-1.231-.112-1.796.904-1.261 2.269z"
              />
              <path
                  data-name="Path 1012"
                  d="M864.797 220.41a4.315 4.315 0 003.2 2.665c1.243.107 1.823-.909 1.29-2.273a4.347 4.347 0 00-3.2-2.667c-1.239-.108-1.813.911-1.29 2.275z"
              />
              <path
                  data-name="Path 1013"
                  d="M857.628 219.796a4.26 4.26 0 003.187 2.66c1.248.105 1.84-.912 1.318-2.276a4.294 4.294 0 00-3.188-2.664c-1.238-.104-1.828.92-1.317 2.28z"
              />
              <path
                  data-name="Path 1014"
                  d="M792.104 214.906a3.791 3.791 0 003.056 2.624c1.283.084 1.987-.954 1.571-2.319a3.818 3.818 0 00-3.058-2.625c-1.269-.085-1.969.955-1.569 2.32z"
              />
              <path
                  data-name="Path 1015"
                  d="M784.728 214.437a3.741 3.741 0 003.041 2.62c1.285.08 2-.961 1.6-2.326a3.767 3.767 0 00-3.043-2.62c-1.283-.082-1.993.961-1.598 2.326z"
              />
              <path
                  data-name="Path 1016"
                  d="M777.328 213.982a3.688 3.688 0 003.025 2.615c1.288.079 2.017-.966 1.624-2.331a3.721 3.721 0 00-3.026-2.615c-1.277-.078-2.006.967-1.623 2.331z"
              />
              <path
                  data-name="Path 1017"
                  d="M679.717 209.527a3.09 3.09 0 002.789 2.55 1.932 1.932 0 001.977-2.4 3.111 3.111 0 00-2.791-2.552 1.94 1.94 0 00-1.975 2.402z"
              />
              <path
                  data-name="Path 1018"
                  d="M672.12 209.3a3.048 3.048 0 002.769 2.545 1.958 1.958 0 002-2.4 3.068 3.068 0 00-2.772-2.547 1.965 1.965 0 00-1.997 2.402z"
              />
              <path
                  data-name="Path 1019"
                  d="M664.514 209.086a3 3 0 002.749 2.54 1.984 1.984 0 002.029-2.41 3.021 3.021 0 00-2.751-2.542 1.992 1.992 0 00-2.027 2.412z"
              />
              <path
                  data-name="Path 1020"
                  d="M656.897 208.896a2.964 2.964 0 002.731 2.535 2.01 2.01 0 002.054-2.415 2.98 2.98 0 00-2.731-2.537 2.022 2.022 0 00-2.054 2.417z"
              />
              <path
                  data-name="Path 1021"
                  d="M649.274 208.72a2.919 2.919 0 002.709 2.529 2.038 2.038 0 002.08-2.42 2.941 2.941 0 00-2.71-2.532 2.047 2.047 0 00-2.079 2.423z"
              />
              <path
                  data-name="Path 1022"
                  d="M641.641 208.561a2.883 2.883 0 002.689 2.523 2.069 2.069 0 002.107-2.426 2.9 2.9 0 00-2.69-2.525 2.077 2.077 0 00-2.106 2.428z"
              />
              <path
                  data-name="Path 1023"
                  d="M634.001 208.419a2.842 2.842 0 002.669 2.518 2.1 2.1 0 002.131-2.431 2.855 2.855 0 00-2.667-2.52 2.107 2.107 0 00-2.133 2.433z"
              />
              <path
                  data-name="Path 1024"
                  d="M626.354 208.294a2.8 2.8 0 002.647 2.513 2.124 2.124 0 002.157-2.436 2.819 2.819 0 00-2.647-2.515 2.136 2.136 0 00-2.157 2.438z"
              />
              <path
                  data-name="Path 1025"
                  d="M618.702 208.187a2.759 2.759 0 002.624 2.507 2.155 2.155 0 002.184-2.441 2.777 2.777 0 00-2.625-2.508 2.164 2.164 0 00-2.183 2.442z"
              />
              <path
                  data-name="Path 1026"
                  d="M611.043 208.097a2.717 2.717 0 002.6 2.5 2.186 2.186 0 002.209-2.446 2.738 2.738 0 00-2.6-2.5 2.2 2.2 0 00-2.209 2.446z"
              />
              <path
                  data-name="Path 1027"
                  d="M603.377 208.025a2.686 2.686 0 002.582 2.5 2.215 2.215 0 002.233-2.453 2.7 2.7 0 00-2.582-2.5 2.23 2.23 0 00-2.233 2.453z"
              />
              <path
                  data-name="Path 1028"
                  d="M595.709 207.97a2.649 2.649 0 002.56 2.492 2.248 2.248 0 002.258-2.46 2.661 2.661 0 00-2.56-2.492 2.26 2.26 0 00-2.258 2.46z"
              />
              <path
                  data-name="Path 1029"
                  d="M588.037 207.932a2.608 2.608 0 002.537 2.487 2.279 2.279 0 002.283-2.465 2.624 2.624 0 00-2.537-2.487 2.291 2.291 0 00-2.283 2.465z"
              />
              <path
                  data-name="Path 1030"
                  d="M580.362 207.914a2.571 2.571 0 002.515 2.48 2.312 2.312 0 002.306-2.47 2.585 2.585 0 00-2.515-2.482 2.322 2.322 0 00-2.306 2.472z"
              />
              <path
                  data-name="Path 1031"
                  d="M572.683 207.91a2.537 2.537 0 002.492 2.475 2.344 2.344 0 002.331-2.475 2.551 2.551 0 00-2.492-2.477 2.36 2.36 0 00-2.331 2.477z"
              />
              <path
                  data-name="Path 1032"
                  d="M565.001 207.925a2.5 2.5 0 002.47 2.468 2.375 2.375 0 002.355-2.48 2.512 2.512 0 00-2.468-2.47 2.392 2.392 0 00-2.357 2.482z"
              />
              <path
                  data-name="Path 1033"
                  d="M557.32 207.958a2.464 2.464 0 002.446 2.463 2.414 2.414 0 002.38-2.487 2.48 2.48 0 00-2.447-2.465 2.426 2.426 0 00-2.379 2.489z"
              />
              <path
                  data-name="Path 1034"
                  d="M549.636 208.008a2.414 2.414 0 102.4-2.493 2.46 2.46 0 00-2.4 2.493z"
              />
              <path
                  data-name="Path 1035"
                  d="M541.952 208.075a2.4 2.4 0 002.4 2.453 2.482 2.482 0 002.426-2.5 2.408 2.408 0 00-2.4-2.453 2.5 2.5 0 00-2.426 2.5z"
              />
              <path
                  data-name="Path 1036"
                  d="M534.269 208.16a2.362 2.362 0 002.376 2.446 2.517 2.517 0 002.45-2.5 2.374 2.374 0 00-2.376-2.448 2.53 2.53 0 00-2.45 2.502z"
              />
              <path
                  data-name="Path 1037"
                  d="M526.585 208.264a2.328 2.328 0 002.353 2.44 2.552 2.552 0 002.473-2.507 2.342 2.342 0 00-2.353-2.443 2.568 2.568 0 00-2.473 2.51z"
              />
              <path
                  data-name="Path 1038"
                  d="M518.904 208.382a2.3 2.3 0 002.329 2.436 2.589 2.589 0 002.495-2.513 2.307 2.307 0 00-2.328-2.436 2.6 2.6 0 00-2.496 2.513z"
              />
              <path
                  data-name="Path 1039"
                  d="M511.223 208.521a2.265 2.265 0 002.3 2.43 2.626 2.626 0 002.518-2.518 2.275 2.275 0 00-2.3-2.431 2.642 2.642 0 00-2.518 2.519z"
              />
              <path
                  data-name="Path 1040"
                  d="M503.547 208.674a2.233 2.233 0 002.279 2.425 2.665 2.665 0 002.542-2.525 2.245 2.245 0 00-2.279-2.426 2.682 2.682 0 00-2.542 2.526z"
              />
              <path
                  data-name="Path 1041"
                  d="M495.871 208.849a2.2 2.2 0 002.256 2.42 2.7 2.7 0 002.563-2.53 2.212 2.212 0 00-2.256-2.42 2.717 2.717 0 00-2.563 2.53z"
              />
              <path
                  data-name="Path 1042"
                  d="M488.204 209.039a2.17 2.17 0 002.231 2.413 2.738 2.738 0 002.585-2.535 2.181 2.181 0 00-2.231-2.415 2.757 2.757 0 00-2.585 2.537z"
              />
              <path
                  data-name="Path 1043"
                  d="M480.533 209.246a2.142 2.142 0 002.207 2.408 2.779 2.779 0 002.609-2.54 2.153 2.153 0 00-2.207-2.41 2.8 2.8 0 00-2.609 2.542z"
              />
              <path
                  data-name="Path 1044"
                  d="M472.871 209.47a2.112 2.112 0 002.182 2.4 2.82 2.82 0 002.63-2.545 2.122 2.122 0 00-2.182-2.4 2.84 2.84 0 00-2.63 2.545z"
              />
              <path
                  data-name="Path 1045"
                  d="M419.419 211.524a1.919 1.919 0 002.005 2.363 3.108 3.108 0 002.774-2.582 1.927 1.927 0 00-2-2.366 3.134 3.134 0 00-2.779 2.585z"
              />
              <path
                  data-name="Path 1046"
                  d="M411.817 211.884a1.9 1.9 0 001.98 2.36 3.154 3.154 0 002.794-2.587 1.9 1.9 0 00-1.979-2.361 3.178 3.178 0 00-2.795 2.588z"
              />
              <path
                  data-name="Path 1047"
                  d="M299.302 219.233c-.414 1.364.294 2.383 1.581 2.279a3.862 3.862 0 003.058-2.657c.406-1.364-.3-2.386-1.577-2.281a3.892 3.892 0 00-3.062 2.659z"
              />
              <path
                  data-name="Path 1048"
                  d="M284.583 220.475c-.436 1.362.247 2.38 1.526 2.269a3.962 3.962 0 003.09-2.665c.428-1.364-.252-2.381-1.524-2.273a4 4 0 00-3.092 2.669z"
              />
              <path
                  data-name="Path 1049"
                  d="M277.246 221.116c-.45 1.362.222 2.376 1.5 2.264a4.017 4.017 0 003.1-2.669c.44-1.364-.229-2.38-1.5-2.268a4.048 4.048 0 00-3.1 2.673z"
              />
              <path
                  data-name="Path 1050"
                  d="M269.934 221.769c-.461 1.364.2 2.375 1.47 2.261a4.074 4.074 0 003.12-2.674c.451-1.364-.206-2.376-1.469-2.263a4.1 4.1 0 00-3.121 2.676z"
              />
              <path
                  data-name="Path 1051"
                  d="M255.372 223.127c-.483 1.362.152 2.368 1.415 2.251a4.18 4.18 0 003.148-2.682c.473-1.362-.159-2.371-1.414-2.253a4.211 4.211 0 00-3.149 2.684z"
              />
              <path
                  data-name="Path 1052"
                  d="M233.698 225.262c-.518 1.362.08 2.363 1.332 2.236a4.333 4.333 0 003.188-2.692c.508-1.362-.087-2.363-1.332-2.238a4.373 4.373 0 00-3.188 2.694z"
              />
              <path
                  data-name="Path 1053"
                  d="M226.521 225.997c-.528 1.36.057 2.36 1.305 2.231a4.392 4.392 0 003.2-2.7c.52-1.36-.064-2.363-1.3-2.234a4.425 4.425 0 00-3.205 2.703z"
              />
              <path
                  data-name="Path 1054"
                  d="M198.061 229.073c-.57 1.36-.037 2.351 1.195 2.214a4.613 4.613 0 003.247-2.709c.563-1.36.03-2.353-1.191-2.216a4.649 4.649 0 00-3.251 2.711z"
              />
              <path
                  data-name="Path 1055"
                  d="M191.012 229.873c-.582 1.359-.06 2.346 1.166 2.207a4.668 4.668 0 003.259-2.71c.573-1.36.053-2.35-1.165-2.211a4.7 4.7 0 00-3.26 2.714z"
              />
              <path
                  data-name="Path 1056"
                  d="M183.991 230.683c-.592 1.359-.082 2.346 1.138 2.2a4.719 4.719 0 003.27-2.716c.585-1.357.075-2.346-1.138-2.2a4.759 4.759 0 00-3.27 2.716z"
              />
              <path
                  data-name="Path 1057"
                  d="M176.997 231.505c-.6 1.359-.105 2.343 1.111 2.2a4.778 4.778 0 003.279-2.719c.595-1.357.1-2.343-1.11-2.2a4.819 4.819 0 00-3.28 2.719z"
              />
              <path
                  data-name="Path 1058"
                  d="M1085.554 240.159a6.279 6.279 0 003.416 2.729c1.036.164 1.177-.792.323-2.134a6.34 6.34 0 00-3.414-2.729c-1.031-.163-1.178.791-.325 2.134z"
              />
              <path
                  data-name="Path 1059"
                  d="M1079.569 239.217a6.214 6.214 0 003.417 2.729c1.041.164 1.2-.794.349-2.137a6.271 6.271 0 00-3.416-2.729c-1.04-.162-1.2.794-.35 2.137z"
              />
              <path
                  data-name="Path 1060"
                  d="M1073.533 238.282a6.172 6.172 0 003.419 2.728c1.049.16 1.216-.8.374-2.142a6.217 6.217 0 00-3.414-2.729c-1.047-.163-1.214.797-.379 2.143z"
              />
              <path
                  data-name="Path 1061"
                  d="M1067.463 237.349a6.117 6.117 0 003.416 2.729c1.058.16 1.235-.8.4-2.144a6.145 6.145 0 00-3.414-2.729c-1.052-.163-1.231.799-.402 2.144z"
              />
              <path
                  data-name="Path 1062"
                  d="M1061.35 236.423a6.056 6.056 0 003.416 2.729c1.064.16 1.253-.8.428-2.147a6.1 6.1 0 00-3.412-2.731c-1.06-.159-1.252.802-.432 2.149z"
              />
              <path
                  data-name="Path 1063"
                  d="M1055.203 235.504a6 6 0 003.414 2.729c1.071.159 1.273-.805.454-2.152a6.044 6.044 0 00-3.412-2.729c-1.067-.161-1.274.804-.456 2.152z"
              />
              <path
                  data-name="Path 1064"
                  d="M1049.008 234.591a5.937 5.937 0 003.413 2.732c1.078.157 1.293-.807.481-2.156a5.988 5.988 0 00-3.411-2.729c-1.07-.162-1.289.804-.483 2.153z"
              />
              <path
                  data-name="Path 1065"
                  d="M1023.861 231.005a5.716 5.716 0 003.4 2.727c1.1.152 1.367-.819.59-2.171a5.76 5.76 0 00-3.4-2.727c-1.098-.157-1.364.819-.59 2.171z"
              />
              <path
                  data-name="Path 1066"
                  d="M1017.482 230.13a5.654 5.654 0 003.4 2.724c1.108.15 1.387-.822.617-2.174a5.7 5.7 0 00-3.4-2.726c-1.108-.153-1.382.823-.617 2.176z"
              />
              <path
                  data-name="Path 1067"
                  d="M1004.613 228.399a5.526 5.526 0 003.384 2.722c1.123.149 1.425-.827.672-2.182a5.573 5.573 0 00-3.384-2.722c-1.116-.151-1.417.828-.672 2.182z"
              />
              <path
                  data-name="Path 1068"
                  d="M998.123 227.547a5.472 5.472 0 003.38 2.72c1.13.147 1.442-.831.7-2.186a5.517 5.517 0 00-3.381-2.72c-1.12-.149-1.434.831-.699 2.186z"
              />
              <path
                  data-name="Path 1069"
                  d="M965.183 223.42a5.181 5.181 0 003.347 2.712c1.156.139 1.533-.849.836-2.207a5.223 5.223 0 00-3.347-2.71c-1.154-.137-1.525.852-.836 2.205z"
              />
              <path
                  data-name="Path 1070"
                  d="M958.495 222.631a5.136 5.136 0 003.339 2.709c1.166.137 1.552-.852.866-2.211a5.164 5.164 0 00-3.34-2.71c-1.157-.138-1.543.853-.865 2.212z"
              />
              <path
                  data-name="Path 1071"
                  d="M938.247 220.308a4.959 4.959 0 003.314 2.7c1.182.132 1.606-.864.949-2.224a4.992 4.992 0 00-3.315-2.7c-1.172-.131-1.598.864-.948 2.224z"
              />
              <path
                  data-name="Path 1072"
                  d="M924.599 218.815a4.839 4.839 0 003.294 2.7c1.19.127 1.641-.872 1-2.233a4.875 4.875 0 00-3.3-2.7c-1.174-.128-1.624.877-.994 2.233z"
              />
              <path
                  data-name="Path 1073"
                  d="M875.948 213.969a4.439 4.439 0 003.217 2.672c1.223.114 1.761-.9 1.2-2.263a4.48 4.48 0 00-3.219-2.674c-1.215-.113-1.752.897-1.198 2.265z"
              />
              <path
                  data-name="Path 1074"
                  d="M868.893 213.328a4.388 4.388 0 003.209 2.668c1.228.11 1.78-.9 1.228-2.268a4.423 4.423 0 00-3.205-2.67c-1.223-.11-1.775.906-1.232 2.27z"
              />
              <path
                  data-name="Path 1075"
                  d="M861.814 212.699a4.335 4.335 0 003.192 2.665c1.232.109 1.8-.909 1.257-2.273a4.366 4.366 0 00-3.193-2.665c-1.226-.108-1.785.909-1.256 2.273z"
              />
              <path
                  data-name="Path 1076"
                  d="M854.713 212.084a4.284 4.284 0 003.178 2.662c1.238.105 1.811-.914 1.285-2.278a4.307 4.307 0 00-3.18-2.662c-1.229-.105-1.806.914-1.283 2.278z"
              />
              <path
                  data-name="Path 1077"
                  d="M847.583 211.483a4.227 4.227 0 003.165 2.659c1.241.1 1.83-.917 1.313-2.283a4.259 4.259 0 00-3.167-2.659c-1.23-.104-1.819.917-1.311 2.283z"
              />
              <path
                  data-name="Path 1078"
                  d="M840.434 210.896a4.169 4.169 0 003.15 2.654c1.247.1 1.848-.922 1.342-2.286a4.2 4.2 0 00-3.153-2.655c-1.235-.101-1.835.922-1.339 2.287z"
              />
              <path
                  data-name="Path 1079"
                  d="M789.79 207.186a3.8 3.8 0 003.046 2.624c1.268.084 1.958-.954 1.537-2.319a3.83 3.83 0 00-3.048-2.625c-1.261-.085-1.954.955-1.535 2.32z"
              />
              <path
                  data-name="Path 1080"
                  d="M782.476 206.717a3.754 3.754 0 003.03 2.619c1.275.08 1.973-.959 1.566-2.326a3.775 3.775 0 00-3.031-2.619c-1.266-.082-1.964.961-1.565 2.326z"
              />
              <path
                  data-name="Path 1081"
                  d="M700.968 202.584a3.225 3.225 0 002.832 2.565 1.841 1.841 0 001.867-2.383 3.24 3.24 0 00-2.834-2.565 1.846 1.846 0 00-1.865 2.383z"
              />
              <path
                  data-name="Path 1082"
                  d="M693.475 202.305a3.175 3.175 0 002.814 2.56 1.864 1.864 0 001.893-2.388 3.2 3.2 0 00-2.814-2.56 1.873 1.873 0 00-1.893 2.388z"
              />
              <path
                  data-name="Path 1083"
                  d="M685.972 202.045a3.133 3.133 0 002.794 2.555 1.891 1.891 0 001.92-2.393 3.149 3.149 0 00-2.794-2.555 1.9 1.9 0 00-1.92 2.393z"
              />
              <path
                  data-name="Path 1084"
                  d="M678.459 201.8a3.085 3.085 0 002.772 2.548 1.917 1.917 0 001.948-2.4 3.109 3.109 0 00-2.776-2.55 1.924 1.924 0 00-1.944 2.402z"
              />
              <path
                  data-name="Path 1085"
                  d="M670.934 201.57a3.044 3.044 0 002.752 2.545 1.943 1.943 0 001.975-2.4 3.063 3.063 0 00-2.754-2.545 1.95 1.95 0 00-1.973 2.4z"
              />
              <path
                  data-name="Path 1086"
                  d="M663.401 201.362a3 3 0 002.732 2.538 1.969 1.969 0 002-2.41 3.019 3.019 0 00-2.734-2.538 1.977 1.977 0 00-1.998 2.41z"
              />
              <path
                  data-name="Path 1087"
                  d="M655.858 201.169a2.955 2.955 0 002.712 2.533 2 2 0 002.027-2.415 2.978 2.978 0 00-2.714-2.533 2.005 2.005 0 00-2.025 2.415z"
              />
              <path
                  data-name="Path 1088"
                  d="M648.306 200.992a2.916 2.916 0 002.69 2.528 2.024 2.024 0 002.054-2.42 2.934 2.934 0 00-2.692-2.528 2.033 2.033 0 00-2.052 2.42z"
              />
              <path
                  data-name="Path 1089"
                  d="M640.748 200.833a2.872 2.872 0 002.669 2.523 2.053 2.053 0 002.079-2.425 2.893 2.893 0 00-2.67-2.525 2.062 2.062 0 00-2.078 2.427z"
              />
              <path
                  data-name="Path 1090"
                  d="M633.181 200.691a2.832 2.832 0 002.649 2.518 2.082 2.082 0 002.1-2.431 2.852 2.852 0 00-2.649-2.518 2.092 2.092 0 00-2.1 2.431z"
              />
              <path
                  data-name="Path 1091"
                  d="M625.608 200.567a2.794 2.794 0 002.627 2.512 2.113 2.113 0 002.131-2.436 2.813 2.813 0 00-2.629-2.513 2.122 2.122 0 00-2.129 2.437z"
              />
              <path
                  data-name="Path 1092"
                  d="M618.028 200.459a2.752 2.752 0 002.605 2.507 2.14 2.14 0 002.156-2.44 2.769 2.769 0 00-2.605-2.508 2.151 2.151 0 00-2.156 2.441z"
              />
              <path
                  data-name="Path 1093"
                  d="M610.443 200.37a2.713 2.713 0 002.583 2.5 2.17 2.17 0 002.181-2.446 2.728 2.728 0 00-2.582-2.5 2.183 2.183 0 00-2.182 2.446z"
              />
              <path
                  data-name="Path 1094"
                  d="M602.853 200.296a2.676 2.676 0 002.56 2.5 2.2 2.2 0 002.207-2.453 2.691 2.691 0 00-2.56-2.5 2.216 2.216 0 00-2.207 2.453z"
              />
              <path
                  data-name="Path 1095"
                  d="M595.258 200.241a2.639 2.639 0 002.538 2.492 2.234 2.234 0 002.233-2.458 2.652 2.652 0 00-2.538-2.492 2.248 2.248 0 00-2.233 2.458z"
              />
              <path
                  data-name="Path 1096"
                  d="M587.659 200.204a2.6 2.6 0 002.515 2.485 2.265 2.265 0 002.258-2.463 2.61 2.61 0 00-2.515-2.485 2.278 2.278 0 00-2.258 2.463z"
              />
              <path
                  data-name="Path 1097"
                  d="M580.058 200.187a2.562 2.562 0 002.493 2.48 2.3 2.3 0 002.283-2.47 2.577 2.577 0 00-2.493-2.48 2.312 2.312 0 00-2.283 2.47z"
              />
              <path
                  data-name="Path 1098"
                  d="M572.452 200.184a2.524 2.524 0 002.47 2.473 2.333 2.333 0 002.308-2.475 2.539 2.539 0 00-2.47-2.473 2.346 2.346 0 00-2.308 2.475z"
              />
              <path
                  data-name="Path 1099"
                  d="M564.846 200.198a2.488 2.488 0 002.446 2.468 2.368 2.368 0 002.333-2.48 2.505 2.505 0 00-2.446-2.47 2.381 2.381 0 00-2.333 2.482z"
              />
              <path
                  data-name="Path 1100"
                  d="M557.238 200.23a2.453 2.453 0 002.423 2.463 2.4 2.4 0 002.356-2.485 2.465 2.465 0 00-2.423-2.463 2.413 2.413 0 00-2.356 2.485z"
              />
              <path
                  data-name="Path 1101"
                  d="M549.629 200.28a2.391 2.391 0 102.38-2.492 2.449 2.449 0 00-2.38 2.492z"
              />
              <path
                  data-name="Path 1102"
                  d="M542.019 200.348a2.383 2.383 0 002.375 2.451 2.47 2.47 0 002.4-2.5 2.4 2.4 0 00-2.376-2.451 2.483 2.483 0 00-2.399 2.5z"
              />
              <path
                  data-name="Path 1103"
                  d="M534.409 200.434a2.348 2.348 0 002.351 2.446 2.508 2.508 0 002.428-2.5 2.361 2.361 0 00-2.351-2.446 2.519 2.519 0 00-2.428 2.5z"
              />
              <path
                  data-name="Path 1104"
                  d="M526.799 200.537a2.314 2.314 0 002.328 2.44 2.543 2.543 0 002.451-2.507 2.327 2.327 0 00-2.328-2.441 2.56 2.56 0 00-2.451 2.508z"
              />
              <path
                  data-name="Path 1105"
                  d="M519.191 200.659a2.284 2.284 0 002.3 2.435 2.581 2.581 0 002.475-2.513 2.3 2.3 0 00-2.3-2.435 2.6 2.6 0 00-2.475 2.513z"
              />
              <path
                  data-name="Path 1106"
                  d="M511.585 200.794a2.249 2.249 0 002.278 2.43 2.617 2.617 0 002.5-2.518 2.262 2.262 0 00-2.278-2.43 2.631 2.631 0 00-2.5 2.518z"
              />
              <path
                  data-name="Path 1107"
                  d="M503.981 200.95a2.22 2.22 0 002.254 2.425 2.658 2.658 0 002.522-2.523 2.229 2.229 0 00-2.254-2.425 2.671 2.671 0 00-2.522 2.523z"
              />
              <path
                  data-name="Path 1108"
                  d="M496.38 201.123a2.187 2.187 0 002.229 2.418 2.7 2.7 0 002.543-2.53 2.2 2.2 0 00-2.229-2.418 2.71 2.71 0 00-2.543 2.53z"
              />
              <path
                  data-name="Path 1109"
                  d="M488.782 201.314a2.156 2.156 0 002.206 2.411 2.733 2.733 0 002.565-2.533 2.164 2.164 0 00-2.2-2.413 2.75 2.75 0 00-2.571 2.535z"
              />
              <path
                  data-name="Path 1110"
                  d="M481.187 201.521a2.126 2.126 0 002.181 2.406 2.774 2.774 0 002.589-2.54 2.135 2.135 0 00-2.179-2.406 2.792 2.792 0 00-2.591 2.54z"
              />
              <path
                  data-name="Path 1111"
                  d="M473.601 201.745a2.1 2.1 0 002.154 2.4 2.816 2.816 0 002.612-2.545 2.108 2.108 0 00-2.154-2.4 2.831 2.831 0 00-2.612 2.545z"
              />
              <path
                  data-name="Path 1112"
                  d="M316.323 210.338c-.4 1.365.321 2.39 1.6 2.291a3.777 3.777 0 003.018-2.65c.388-1.364-.328-2.39-1.6-2.291a3.811 3.811 0 00-3.018 2.65z"
              />
              <path
                  data-name="Path 1113"
                  d="M308.982 210.922c-.409 1.365.3 2.386 1.574 2.286a3.83 3.83 0 003.035-2.655c.4-1.364-.3-2.386-1.572-2.286a3.858 3.858 0 00-3.037 2.655z"
              />
              <path
                  data-name="Path 1114"
                  d="M287.065 212.766c-.445 1.364.224 2.38 1.492 2.269a3.984 3.984 0 003.081-2.665c.434-1.364-.231-2.381-1.489-2.273a4.017 4.017 0 00-3.084 2.669z"
              />
              <path
                  data-name="Path 1115"
                  d="M279.798 213.413c-.456 1.364.2 2.378 1.466 2.264a4.035 4.035 0 003.1-2.67c.448-1.364-.207-2.378-1.462-2.266a4.07 4.07 0 00-3.104 2.672z"
              />
              <path
                  data-name="Path 1116"
                  d="M265.324 214.737c-.48 1.364.152 2.373 1.41 2.256a4.144 4.144 0 003.127-2.679c.47-1.362-.16-2.373-1.409-2.256a4.176 4.176 0 00-3.128 2.679z"
              />
              <path
                  data-name="Path 1117"
                  d="M250.937 216.123c-.5 1.364.105 2.368 1.354 2.246a4.246 4.246 0 003.155-2.687c.493-1.362-.112-2.368-1.352-2.246a4.283 4.283 0 00-3.157 2.687z"
              />
              <path
                  data-name="Path 1118"
                  d="M222.432 219.054c-.546 1.362.012 2.358 1.245 2.226a4.471 4.471 0 003.207-2.7c.538-1.362-.018-2.358-1.243-2.228a4.506 4.506 0 00-3.209 2.702z"
              />
              <path
                  data-name="Path 1119"
                  d="M201.311 221.385c-.58 1.36-.06 2.351 1.161 2.214a4.646 4.646 0 003.242-2.711c.57-1.36.053-2.353-1.16-2.216a4.681 4.681 0 00-3.243 2.713z"
              />
              <path
                  data-name="Path 1120"
                  d="M194.323 222.188c-.59 1.36-.082 2.348 1.133 2.209a4.705 4.705 0 003.254-2.714c.582-1.36.075-2.35-1.131-2.211a4.737 4.737 0 00-3.256 2.716z"
              />
              <path
                  data-name="Path 1121"
                  d="M187.361 223.001c-.6 1.359-.105 2.346 1.1 2.2a4.754 4.754 0 003.265-2.717c.592-1.359.1-2.346-1.1-2.206a4.791 4.791 0 00-3.265 2.723z"
              />
              <path
                  data-name="Path 1122"
                  d="M180.426 223.827c-.612 1.359-.127 2.343 1.078 2.2a4.806 4.806 0 003.275-2.719c.6-1.359.122-2.345-1.076-2.2a4.845 4.845 0 00-3.277 2.719z"
              />
              <path
                  data-name="Path 1123"
                  d="M159.794 226.369c-.642 1.359-.2 2.336.994 2.187a4.979 4.979 0 003.3-2.727c.635-1.359.19-2.338-.993-2.189a5.026 5.026 0 00-3.301 2.729z"
              />
              <path
                  data-name="Path 1124"
                  d="M1074.782 231.619a6.253 6.253 0 003.411 2.731c1.034.164 1.183-.792.331-2.136a6.3 6.3 0 00-3.407-2.73c-1.031-.166-1.182.792-.335 2.135z"
              />
              <path
                  data-name="Path 1125"
                  d="M1068.792 230.676a6.2 6.2 0 003.409 2.731c1.043.162 1.2-.8.358-2.139a6.242 6.242 0 00-3.409-2.732c-1.038-.164-1.198.793-.358 2.14z"
              />
              <path
                  data-name="Path 1126"
                  d="M1062.762 229.739a6.155 6.155 0 003.409 2.731c1.051.162 1.221-.8.383-2.144a6.18 6.18 0 00-3.407-2.731c-1.045-.163-1.219.797-.385 2.144z"
              />
              <path
                  data-name="Path 1127"
                  d="M1056.695 228.803a6.078 6.078 0 003.409 2.73c1.056.162 1.238-.8.408-2.146a6.132 6.132 0 00-3.407-2.732c-1.056-.158-1.238.805-.41 2.148z"
              />
              <path
                  data-name="Path 1128"
                  d="M1050.585 227.881a6.028 6.028 0 003.407 2.732c1.063.159 1.257-.8.433-2.151a6.072 6.072 0 00-3.405-2.732c-1.058-.16-1.253.8-.435 2.151z"
              />
              <path
                  data-name="Path 1129"
                  d="M1044.437 226.96a5.978 5.978 0 003.406 2.731c1.069.159 1.277-.807.459-2.154a6.007 6.007 0 00-3.4-2.732c-1.068-.157-1.272.808-.465 2.155z"
              />
              <path
                  data-name="Path 1130"
                  d="M1038.252 226.051a5.928 5.928 0 003.4 2.731c1.076.157 1.294-.809.485-2.159a5.964 5.964 0 00-3.4-2.73c-1.068-.16-1.287.808-.485 2.158z"
              />
              <path
                  data-name="Path 1131"
                  d="M1032.031 225.147a5.856 5.856 0 003.4 2.729c1.081.155 1.313-.81.511-2.162a5.887 5.887 0 00-3.4-2.729c-1.077-.157-1.305.812-.511 2.162z"
              />
              <path
                  data-name="Path 1132"
                  d="M1025.771 224.249a5.8 5.8 0 003.4 2.727c1.09.155 1.332-.814.538-2.164a5.843 5.843 0 00-3.4-2.731c-1.081-.155-1.323.816-.538 2.168z"
              />
              <path
                  data-name="Path 1133"
                  d="M1000.376 220.741a5.575 5.575 0 003.381 2.724c1.115.149 1.4-.827.645-2.182a5.612 5.612 0 00-3.38-2.724c-1.109-.151-1.397.826-.646 2.182z"
              />
              <path
                  data-name="Path 1134"
                  d="M954.63 214.948a5.158 5.158 0 003.334 2.709c1.155.139 1.529-.851.835-2.209a5.2 5.2 0 00-3.334-2.71c-1.148-.139-1.52.851-.835 2.21z"
              />
              <path
                  data-name="Path 1135"
                  d="M893.623 208.27a4.633 4.633 0 003.245 2.682c1.2.12 1.688-.886 1.086-2.248a4.674 4.674 0 00-3.247-2.684c-1.192-.119-1.678.888-1.084 2.25z"
              />
              <path
                  data-name="Path 1136"
                  d="M886.706 207.589a4.578 4.578 0 003.234 2.679c1.2.119 1.7-.891 1.115-2.253a4.619 4.619 0 00-3.235-2.68c-1.198-.119-1.697.892-1.114 2.254z"
              />
              <path
                  data-name="Path 1137"
                  d="M872.798 206.264a4.472 4.472 0 003.21 2.672c1.213.112 1.736-.9 1.168-2.263a4.493 4.493 0 00-3.21-2.672c-1.202-.113-1.728.9-1.168 2.263z"
              />
              <path
                  data-name="Path 1138"
                  d="M865.808 205.62a4.409 4.409 0 003.2 2.669c1.218.11 1.753-.9 1.2-2.266a4.443 4.443 0 00-3.2-2.67c-1.213-.106-1.748.903-1.2 2.267z"
              />
              <path
                  data-name="Path 1139"
                  d="M858.793 204.993a4.355 4.355 0 003.183 2.665c1.22.107 1.771-.909 1.225-2.271a4.386 4.386 0 00-3.185-2.665c-1.213-.113-1.76.906-1.223 2.271z"
              />
              <path
                  data-name="Path 1140"
                  d="M851.754 204.374a4.3 4.3 0 003.17 2.662c1.225.105 1.786-.914 1.253-2.276a4.33 4.33 0 00-3.172-2.662c-1.217-.107-1.781.913-1.251 2.276z"
              />
              <path
                  data-name="Path 1141"
                  d="M844.691 203.772a4.246 4.246 0 003.157 2.657c1.23.105 1.8-.917 1.28-2.281a4.267 4.267 0 00-3.157-2.657c-1.221-.104-1.794.917-1.28 2.281z"
              />
              <path
                  data-name="Path 1142"
                  d="M837.607 203.184a4.193 4.193 0 003.143 2.652c1.233.1 1.82-.921 1.31-2.284a4.218 4.218 0 00-3.145-2.654c-1.223-.102-1.809.922-1.308 2.286z"
              />
              <path
                  data-name="Path 1143"
                  d="M801.871 200.458a3.913 3.913 0 003.066 2.63c1.253.09 1.9-.944 1.45-2.308a3.941 3.941 0 00-3.07-2.632c-1.242-.09-1.889.945-1.446 2.31z"
              />
              <path
                  data-name="Path 1144"
                  d="M794.664 199.955a3.866 3.866 0 003.051 2.627c1.255.085 1.917-.949 1.477-2.314a3.9 3.9 0 00-3.053-2.627c-1.248-.086-1.906.951-1.475 2.314z"
              />
              <path
                  data-name="Path 1145"
                  d="M787.438 199.469a3.809 3.809 0 003.035 2.622c1.258.085 1.933-.954 1.506-2.318a3.838 3.838 0 00-3.036-2.624c-1.249-.083-1.924.955-1.505 2.32z"
              />
              <path
                  data-name="Path 1146"
                  d="M780.194 198.998a3.763 3.763 0 003.018 2.619c1.262.08 1.948-.959 1.534-2.324a3.79 3.79 0 00-3.02-2.619c-1.251-.08-1.938.961-1.532 2.324z"
              />
              <path
                  data-name="Path 1147"
                  d="M772.933 198.543a3.708 3.708 0 003 2.612c1.263.079 1.963-.964 1.561-2.328a3.735 3.735 0 00-3-2.614c-1.257-.078-1.955.969-1.561 2.33z"
              />
              <path
                  data-name="Path 1148"
                  d="M714.269 195.468a3.313 3.313 0 002.856 2.573 1.777 1.777 0 001.781-2.37 3.331 3.331 0 00-2.856-2.573 1.783 1.783 0 00-1.781 2.37z"
              />
              <path
                  data-name="Path 1149"
                  d="M706.877 195.158a3.267 3.267 0 002.837 2.567 1.8 1.8 0 001.81-2.375 3.292 3.292 0 00-2.837-2.568 1.809 1.809 0 00-1.81 2.376z"
              />
              <path
                  data-name="Path 1150"
                  d="M699.471 194.862a3.22 3.22 0 002.816 2.563 1.826 1.826 0 001.838-2.38 3.244 3.244 0 00-2.819-2.563 1.831 1.831 0 00-1.835 2.38z"
              />
              <path
                  data-name="Path 1151"
                  d="M692.051 194.585a3.175 3.175 0 002.8 2.557 1.848 1.848 0 001.863-2.385 3.194 3.194 0 00-2.8-2.558 1.858 1.858 0 00-1.863 2.386z"
              />
              <path
                  data-name="Path 1152"
                  d="M684.622 194.322a3.128 3.128 0 002.776 2.553 1.876 1.876 0 001.892-2.391 3.148 3.148 0 00-2.777-2.552 1.882 1.882 0 00-1.891 2.39z"
              />
              <path
                  data-name="Path 1153"
                  d="M677.183 194.078a3.083 3.083 0 002.757 2.547 1.9 1.9 0 001.917-2.395 3.1 3.1 0 00-2.756-2.548 1.91 1.91 0 00-1.918 2.396z"
              />
              <path
                  data-name="Path 1154"
                  d="M669.731 193.851a3.037 3.037 0 002.735 2.542 1.928 1.928 0 001.945-2.4 3.058 3.058 0 00-2.737-2.542 1.935 1.935 0 00-1.943 2.4z"
              />
              <path
                  data-name="Path 1155"
                  d="M662.271 193.641a3 3 0 002.714 2.537 1.954 1.954 0 001.972-2.406 3.011 3.011 0 00-2.715-2.537 1.962 1.962 0 00-1.971 2.406z"
              />
              <path
                  data-name="Path 1156"
                  d="M654.801 193.447a2.955 2.955 0 002.694 2.532 1.982 1.982 0 002-2.411 2.971 2.971 0 00-2.694-2.532 1.991 1.991 0 00-2 2.411z"
              />
              <path
                  data-name="Path 1157"
                  d="M647.326 193.271a2.911 2.911 0 002.672 2.525 2.008 2.008 0 002.025-2.416 2.926 2.926 0 00-2.674-2.527 2.019 2.019 0 00-2.023 2.418z"
              />
              <path
                  data-name="Path 1158"
                  d="M639.84 193.11a2.868 2.868 0 002.65 2.522 2.038 2.038 0 002.052-2.423 2.886 2.886 0 00-2.652-2.522 2.048 2.048 0 00-2.05 2.423z"
              />
              <path
                  data-name="Path 1159"
                  d="M632.349 192.97a2.825 2.825 0 002.629 2.513 2.064 2.064 0 002.077-2.426 2.845 2.845 0 00-2.629-2.517 2.077 2.077 0 00-2.077 2.43z"
              />
              <path
                  data-name="Path 1160"
                  d="M624.851 192.845a2.785 2.785 0 002.607 2.51 2.1 2.1 0 002.1-2.435 2.8 2.8 0 00-2.607-2.508 2.1 2.1 0 00-2.1 2.433z"
              />
              <path
                  data-name="Path 1161"
                  d="M617.346 192.738a2.742 2.742 0 002.584 2.5 2.126 2.126 0 002.135-2.43 2.761 2.761 0 00-2.585-2.5 2.137 2.137 0 00-2.134 2.43z"
              />
              <path
                  data-name="Path 1162"
                  d="M609.836 192.648a2.7 2.7 0 002.562 2.5 2.157 2.157 0 002.156-2.443 2.72 2.72 0 00-2.562-2.5 2.168 2.168 0 00-2.156 2.443z"
              />
              <path
                  data-name="Path 1163"
                  d="M602.322 192.576a2.664 2.664 0 002.538 2.493 2.188 2.188 0 002.181-2.45 2.677 2.677 0 00-2.538-2.493 2.2 2.2 0 00-2.181 2.45z"
              />
              <path
                  data-name="Path 1164"
                  d="M594.802 192.521a2.623 2.623 0 002.517 2.487 2.22 2.22 0 002.206-2.455 2.643 2.643 0 00-2.517-2.488 2.233 2.233 0 00-2.206 2.456z"
              />
              <path
                  data-name="Path 1165"
                  d="M587.278 192.485a2.588 2.588 0 002.493 2.482 2.254 2.254 0 002.233-2.461 2.6 2.6 0 00-2.495-2.482 2.263 2.263 0 00-2.231 2.461z"
              />
              <path
                  data-name="Path 1166"
                  d="M579.752 192.464a2.549 2.549 0 002.47 2.477 2.287 2.287 0 002.258-2.467 2.562 2.562 0 00-2.47-2.477 2.3 2.3 0 00-2.258 2.467z"
              />
              <path
                  data-name="Path 1167"
                  d="M572.222 192.461a2.513 2.513 0 002.446 2.472 2.32 2.32 0 002.283-2.472 2.526 2.526 0 00-2.446-2.471 2.332 2.332 0 00-2.283 2.471z"
              />
              <path
                  data-name="Path 1168"
                  d="M564.691 192.476a2.477 2.477 0 002.423 2.466 2.354 2.354 0 002.308-2.478 2.49 2.49 0 00-2.423-2.465 2.366 2.366 0 00-2.308 2.477z"
              />
              <path
                  data-name="Path 1169"
                  d="M557.158 192.509a2.439 2.439 0 002.4 2.46 2.388 2.388 0 002.331-2.483 2.452 2.452 0 00-2.4-2.46 2.4 2.4 0 00-2.331 2.483z"
              />
              <path
                  data-name="Path 1170"
                  d="M549.623 192.559a2.369 2.369 0 102.358-2.488 2.437 2.437 0 00-2.358 2.488z"
              />
              <path
                  data-name="Path 1171"
                  d="M542.088 192.628a2.37 2.37 0 002.351 2.448 2.459 2.459 0 002.381-2.493 2.381 2.381 0 00-2.35-2.45 2.475 2.475 0 00-2.382 2.495z"
              />
              <path
                  data-name="Path 1172"
                  d="M534.553 192.713a2.336 2.336 0 002.328 2.443 2.5 2.5 0 002.4-2.5 2.347 2.347 0 00-2.326-2.445 2.513 2.513 0 00-2.402 2.502z"
              />
              <path
                  data-name="Path 1173"
                  d="M527.019 192.813a2.3 2.3 0 002.3 2.438 2.534 2.534 0 002.43-2.5 2.316 2.316 0 00-2.3-2.44 2.55 2.55 0 00-2.43 2.502z"
              />
              <path
                  data-name="Path 1174"
                  d="M519.487 192.937a2.268 2.268 0 002.278 2.431 2.57 2.57 0 002.453-2.51 2.281 2.281 0 00-2.278-2.433 2.586 2.586 0 00-2.453 2.512z"
              />
              <path
                  data-name="Path 1175"
                  d="M511.955 193.074a2.235 2.235 0 002.253 2.426 2.61 2.61 0 002.478-2.515 2.247 2.247 0 00-2.253-2.426 2.623 2.623 0 00-2.478 2.515z"
              />
              <path
                  data-name="Path 1176"
                  d="M504.427 193.23a2.2 2.2 0 002.228 2.421 2.647 2.647 0 002.5-2.52 2.215 2.215 0 00-2.228-2.423 2.665 2.665 0 00-2.5 2.522z"
              />
              <path
                  data-name="Path 1177"
                  d="M496.898 193.403a2.171 2.171 0 002.2 2.415 2.684 2.684 0 002.523-2.525 2.182 2.182 0 00-2.2-2.416 2.7 2.7 0 00-2.523 2.526z"
              />
              <path
                  data-name="Path 1178"
                  d="M489.376 193.594a2.14 2.14 0 002.177 2.41 2.725 2.725 0 002.547-2.532 2.151 2.151 0 00-2.176-2.411 2.744 2.744 0 00-2.548 2.533z"
              />
              <path
                  data-name="Path 1179"
                  d="M481.856 193.801a2.111 2.111 0 002.152 2.4 2.764 2.764 0 002.568-2.537 2.12 2.12 0 00-2.151-2.4 2.78 2.78 0 00-2.569 2.537z"
              />
              <path
                  data-name="Path 1180"
                  d="M474.344 194.027a2.08 2.08 0 002.127 2.4 2.8 2.8 0 002.592-2.542 2.09 2.09 0 00-2.126-2.4 2.826 2.826 0 00-2.593 2.542z"
              />
              <path
                  data-name="Path 1181"
                  d="M399.573 197.218a1.812 1.812 0 001.867 2.344 3.245 3.245 0 002.8-2.6 1.82 1.82 0 00-1.865-2.346 3.27 3.27 0 00-2.802 2.602z"
              />
              <path
                  data-name="Path 1182"
                  d="M392.145 197.629a1.789 1.789 0 001.84 2.339 3.293 3.293 0 002.824-2.6 1.794 1.794 0 00-1.837-2.341 3.32 3.32 0 00-2.827 2.602z"
              />
              <path
                  data-name="Path 1183"
                  d="M268.049 207.036c-.486 1.362.13 2.37 1.377 2.254a4.162 4.162 0 003.118-2.679c.478-1.362-.137-2.373-1.374-2.256a4.2 4.2 0 00-3.121 2.681z"
              />
              <path
                  data-name="Path 1184"
                  d="M260.91 207.724c-.5 1.362.105 2.368 1.349 2.249a4.221 4.221 0 003.133-2.682c.488-1.362-.114-2.371-1.347-2.251a4.25 4.25 0 00-3.135 2.684z"
              />
              <path
                  data-name="Path 1185"
                  d="M253.792 208.423c-.51 1.362.082 2.366 1.32 2.244a4.275 4.275 0 003.148-2.685c.5-1.362-.09-2.368-1.32-2.246a4.3 4.3 0 00-3.148 2.687z"
              />
              <path
                  data-name="Path 1186"
                  d="M204.603 213.698c-.587 1.36-.08 2.35 1.128 2.212a4.67 4.67 0 003.237-2.712c.578-1.359.075-2.351-1.126-2.212a4.7 4.7 0 00-3.239 2.712z"
              />
              <path
                  data-name="Path 1187"
                  d="M197.675 214.502c-.6 1.359-.1 2.348 1.1 2.207a4.721 4.721 0 003.247-2.714c.59-1.36.1-2.349-1.1-2.209a4.762 4.762 0 00-3.247 2.716z"
              />
              <path
                  data-name="Path 1188"
                  d="M190.774 215.317c-.608 1.359-.127 2.344 1.073 2.2a4.788 4.788 0 003.259-2.719c.6-1.359.12-2.346-1.071-2.2a4.821 4.821 0 00-3.261 2.719z"
              />
              <path
                  data-name="Path 1189"
                  d="M183.895 216.145c-.618 1.359-.15 2.343 1.044 2.2a4.835 4.835 0 003.27-2.72c.61-1.359.144-2.343-1.044-2.2a4.881 4.881 0 00-3.27 2.72z"
              />
              <path
                  data-name="Path 1190"
                  d="M177.05 216.984c-.628 1.359-.172 2.339 1.018 2.2a4.9 4.9 0 003.28-2.724c.62-1.359.165-2.341-1.018-2.2a4.931 4.931 0 00-3.28 2.724z"
              />
              <path
                  data-name="Path 1191"
                  d="M170.231 217.834c-.64 1.359-.2 2.338.991 2.191a4.959 4.959 0 003.289-2.725c.632-1.359.189-2.34-.989-2.192a4.994 4.994 0 00-3.291 2.726z"
              />
              <path
                  data-name="Path 1192"
                  d="M1069.984 224.018a6.287 6.287 0 003.4 2.734c1.028.164 1.168-.79.314-2.134a6.323 6.323 0 00-3.4-2.734c-1.021-.165-1.165.791-.314 2.134z"
              />
              <path
                  data-name="Path 1193"
                  d="M1058.042 222.123a6.173 6.173 0 003.4 2.732c1.043.162 1.207-.794.364-2.141a6.22 6.22 0 00-3.4-2.734c-1.037-.157-1.199.799-.364 2.143z"
              />
              <path
                  data-name="Path 1194"
                  d="M1052.014 221.19a6.123 6.123 0 003.4 2.732c1.051.162 1.223-.8.389-2.144a6.161 6.161 0 00-3.4-2.734c-1.042-.162-1.218.801-.389 2.146z"
              />
              <path
                  data-name="Path 1195"
                  d="M1045.948 220.259a6.063 6.063 0 003.4 2.732c1.054.16 1.24-.8.414-2.149a6.1 6.1 0 00-3.4-2.732c-1.049-.162-1.236.801-.414 2.149z"
              />
              <path
                  data-name="Path 1196"
                  d="M1039.843 219.334a6.011 6.011 0 003.4 2.732c1.063.159 1.26-.8.439-2.152a6.041 6.041 0 00-3.4-2.732c-1.055-.16-1.252.804-.439 2.152z"
              />
              <path
                  data-name="Path 1197"
                  d="M1033.702 218.419a5.951 5.951 0 003.4 2.73c1.069.159 1.277-.807.465-2.157a5.991 5.991 0 00-3.4-2.731c-1.06-.158-1.272.808-.465 2.158z"
              />
              <path
                  data-name="Path 1198"
                  d="M1027.526 217.508a5.881 5.881 0 003.394 2.731c1.076.159 1.293-.809.49-2.159a5.934 5.934 0 00-3.392-2.732c-1.072-.157-1.291.813-.492 2.16z"
              />
              <path
                  data-name="Path 1199"
                  d="M1021.313 216.607a5.832 5.832 0 003.391 2.729c1.081.155 1.313-.812.515-2.164a5.884 5.884 0 00-3.389-2.731c-1.077-.156-1.309.815-.517 2.166z"
              />
              <path
                  data-name="Path 1200"
                  d="M1015.063 215.714a5.773 5.773 0 003.387 2.727c1.088.154 1.33-.815.541-2.167a5.819 5.819 0 00-3.386-2.729c-1.08-.156-1.326.815-.542 2.169z"
              />
              <path
                  data-name="Path 1201"
                  d="M1008.781 214.828a5.721 5.721 0 003.382 2.727c1.094.152 1.349-.819.568-2.172a5.758 5.758 0 00-3.382-2.727c-1.091-.154-1.345.818-.568 2.172z"
              />
              <path
                  data-name="Path 1202"
                  d="M1002.461 213.951a5.661 5.661 0 003.377 2.726c1.1.152 1.367-.822.595-2.176a5.706 5.706 0 00-3.379-2.727c-1.094-.148-1.36.823-.593 2.177z"
              />
              <path
                  data-name="Path 1203"
                  d="M989.722 212.223a5.541 5.541 0 003.367 2.722c1.113.149 1.4-.829.647-2.184a5.578 5.578 0 00-3.369-2.722c-1.104-.149-1.395.827-.645 2.184z"
              />
              <path
                  data-name="Path 1204"
                  d="M937.487 205.698a5.06 5.06 0 003.31 2.7c1.155.135 1.542-.857.861-2.216a5.1 5.1 0 00-3.31-2.7c-1.149-.136-1.534.858-.861 2.216z"
              />
              <path
                  data-name="Path 1205"
                  d="M930.826 204.932a5 5 0 003.3 2.7c1.16.132 1.559-.861.889-2.219a5.043 5.043 0 00-3.3-2.7c-1.16-.136-1.556.858-.889 2.219z"
              />
              <path
                  data-name="Path 1206"
                  d="M924.128 204.175a4.946 4.946 0 003.29 2.7c1.166.13 1.577-.864.917-2.224a4.987 4.987 0 00-3.294-2.7c-1.156-.132-1.567.865-.913 2.224z"
              />
              <path
                  data-name="Path 1207"
                  d="M917.405 203.431a4.9 4.9 0 003.282 2.7c1.171.129 1.594-.871.944-2.229a4.93 4.93 0 00-3.282-2.7c-1.162-.131-1.585.869-.944 2.229z"
              />
              <path
                  data-name="Path 1208"
                  d="M897.079 201.269a4.713 4.713 0 003.249 2.685c1.185.122 1.644-.881 1.026-2.243a4.745 4.745 0 00-3.25-2.685c-1.176-.117-1.637.883-1.025 2.243z"
              />
              <path
                  data-name="Path 1209"
                  d="M890.249 200.572a4.654 4.654 0 003.239 2.682c1.188.122 1.661-.886 1.055-2.246a4.7 4.7 0 00-3.24-2.682c-1.182-.12-1.654.886-1.054 2.246z"
              />
              <path
                  data-name="Path 1210"
                  d="M883.396 199.889a4.6 4.6 0 003.225 2.679c1.193.119 1.678-.889 1.083-2.249a4.638 4.638 0 00-3.227-2.68c-1.187-.118-1.668.889-1.081 2.25z"
              />
              <path
                  data-name="Path 1211"
                  d="M876.516 199.219a4.55 4.55 0 003.214 2.675c1.2.115 1.7-.894 1.109-2.256a4.576 4.576 0 00-3.214-2.675c-1.191-.116-1.686.894-1.109 2.256z"
              />
              <path
                  data-name="Path 1212"
                  d="M869.613 198.561a4.485 4.485 0 003.2 2.67c1.2.114 1.714-.9 1.138-2.259a4.514 4.514 0 00-3.2-2.672c-1.195-.112-1.704.899-1.138 2.261z"
              />
              <path
                  data-name="Path 1213"
                  d="M862.686 197.917a4.425 4.425 0 003.187 2.667c1.206.112 1.729-.9 1.166-2.263a4.465 4.465 0 00-3.19-2.669c-1.198-.11-1.718.903-1.163 2.265z"
              />
              <path
                  data-name="Path 1214"
                  d="M855.734 197.287a4.37 4.37 0 003.175 2.664c1.21.109 1.745-.907 1.193-2.269a4.4 4.4 0 00-3.175-2.664c-1.203-.109-1.736.907-1.193 2.269z"
              />
              <path
                  data-name="Path 1215"
                  d="M848.762 196.67a4.315 4.315 0 003.162 2.659c1.213.107 1.761-.911 1.222-2.273a4.347 4.347 0 00-3.163-2.66c-1.206-.105-1.753.912-1.221 2.274z"
              />
              <path
                  data-name="Path 1216"
                  d="M841.764 196.067a4.263 4.263 0 003.148 2.655c1.218.1 1.776-.916 1.248-2.278a4.3 4.3 0 00-3.148-2.657c-1.21-.103-1.768.92-1.248 2.28z"
              />
              <path
                  data-name="Path 1217"
                  d="M834.748 195.477a4.2 4.2 0 003.132 2.65c1.223.1 1.795-.919 1.278-2.281a4.23 4.23 0 00-3.135-2.652c-1.213-.102-1.785.921-1.275 2.283z"
              />
              <path
                  data-name="Path 1218"
                  d="M827.708 194.902a4.148 4.148 0 003.118 2.647c1.227.1 1.811-.924 1.305-2.288a4.175 4.175 0 00-3.118-2.647c-1.217-.098-1.8.922-1.305 2.288z"
              />
              <path
                  data-name="Path 1219"
                  d="M820.644 194.342a4.09 4.09 0 003.1 2.642c1.232.1 1.828-.929 1.334-2.293a4.118 4.118 0 00-3.1-2.642c-1.219-.098-1.814.93-1.334 2.293z"
              />
              <path
                  data-name="Path 1220"
                  d="M806.468 193.264a3.982 3.982 0 003.072 2.634c1.237.09 1.86-.939 1.39-2.3a4.01 4.01 0 00-3.073-2.634c-1.228-.091-1.85.937-1.389 2.3z"
              />
              <path
                  data-name="Path 1221"
                  d="M799.349 192.748a3.928 3.928 0 003.055 2.629c1.24.089 1.875-.944 1.419-2.306a3.961 3.961 0 00-3.058-2.63c-1.23-.089-1.865.943-1.416 2.307z"
              />
              <path
                  data-name="Path 1222"
                  d="M792.211 192.245a3.874 3.874 0 003.04 2.625c1.243.087 1.89-.949 1.445-2.311a3.905 3.905 0 00-3.041-2.625c-1.235-.085-1.88.949-1.444 2.311z"
              />
              <path
                  data-name="Path 1223"
                  d="M785.055 191.763a3.816 3.816 0 003.021 2.62c1.248.084 1.907-.953 1.476-2.316a3.851 3.851 0 00-3.025-2.62c-1.238-.084-1.897.95-1.472 2.316z"
              />
              <path
                  data-name="Path 1224"
                  d="M777.882 191.288a3.765 3.765 0 003.006 2.615c1.248.08 1.922-.958 1.5-2.321a3.8 3.8 0 00-3.006-2.615c-1.241-.08-1.912.957-1.5 2.321z"
              />
              <path
                  data-name="Path 1225"
                  d="M770.689 190.833a3.715 3.715 0 002.99 2.609c1.252.08 1.937-.963 1.529-2.324a3.739 3.739 0 00-2.99-2.61c-1.244-.081-1.927.961-1.529 2.325z"
              />
              <path
                  data-name="Path 1226"
                  d="M763.482 190.392a3.663 3.663 0 002.971 2.605c1.255.075 1.954-.968 1.557-2.329a3.69 3.69 0 00-2.971-2.607c-1.246-.076-1.943.969-1.557 2.331z"
              />
              <path
                  data-name="Path 1227"
                  d="M719.916 188.084a3.358 3.358 0 002.859 2.575 1.738 1.738 0 001.724-2.36 3.389 3.389 0 00-2.861-2.577 1.745 1.745 0 00-1.722 2.362z"
              />
              <path
                  data-name="Path 1228"
                  d="M712.607 187.756a3.315 3.315 0 002.839 2.57 1.763 1.763 0 001.753-2.366 3.333 3.333 0 00-2.841-2.57 1.768 1.768 0 00-1.751 2.366z"
              />
              <path
                  data-name="Path 1229"
                  d="M705.286 187.446a3.261 3.261 0 002.819 2.563 1.784 1.784 0 001.78-2.37 3.286 3.286 0 00-2.821-2.565 1.791 1.791 0 00-1.778 2.372z"
              />
              <path
                  data-name="Path 1230"
                  d="M697.952 187.15a3.216 3.216 0 002.8 2.56 1.809 1.809 0 001.807-2.376 3.241 3.241 0 00-2.8-2.56 1.817 1.817 0 00-1.807 2.376z"
              />
              <path
                  data-name="Path 1231"
                  d="M690.608 186.876a3.167 3.167 0 002.779 2.553 1.834 1.834 0 001.835-2.381 3.19 3.19 0 00-2.781-2.553 1.841 1.841 0 00-1.833 2.381z"
              />
              <path
                  data-name="Path 1232"
                  d="M683.252 186.612a3.125 3.125 0 002.759 2.548 1.858 1.858 0 001.862-2.386 3.147 3.147 0 00-2.759-2.55 1.868 1.868 0 00-1.862 2.388z"
              />
              <path
                  data-name="Path 1233"
                  d="M675.886 186.368a3.08 3.08 0 002.739 2.543 1.884 1.884 0 001.888-2.391 3.1 3.1 0 00-2.739-2.543 1.894 1.894 0 00-1.888 2.391z"
              />
              <path
                  data-name="Path 1234"
                  d="M668.512 186.139a3.032 3.032 0 002.717 2.538 1.911 1.911 0 001.915-2.4 3.055 3.055 0 00-2.717-2.54 1.92 1.92 0 00-1.915 2.402z"
              />
              <path
                  data-name="Path 1235"
                  d="M661.127 185.928a2.987 2.987 0 002.7 2.533 1.939 1.939 0 001.943-2.4 3.009 3.009 0 00-2.7-2.533 1.946 1.946 0 00-1.943 2.4z"
              />
              <path
                  data-name="Path 1236"
                  d="M653.732 185.736a2.945 2.945 0 002.675 2.527 1.965 1.965 0 001.969-2.408 2.965 2.965 0 00-2.674-2.528 1.975 1.975 0 00-1.97 2.409z"
              />
              <path
                  data-name="Path 1237"
                  d="M646.331 185.559a2.9 2.9 0 002.652 2.522 1.993 1.993 0 002-2.413 2.917 2.917 0 00-2.652-2.522 2 2 0 00-2 2.413z"
              />
              <path
                  data-name="Path 1238"
                  d="M638.926 185.4a2.86 2.86 0 002.632 2.517 2.024 2.024 0 002.024-2.42 2.88 2.88 0 00-2.632-2.517 2.033 2.033 0 00-2.024 2.42z"
              />
              <path
                  data-name="Path 1239"
                  d="M631.505 185.258a2.82 2.82 0 002.609 2.512 2.053 2.053 0 002.05-2.425 2.835 2.835 0 00-2.609-2.512 2.063 2.063 0 00-2.05 2.425z"
              />
              <path
                  data-name="Path 1240"
                  d="M624.084 185.133a2.775 2.775 0 002.585 2.507 2.082 2.082 0 002.077-2.43 2.794 2.794 0 00-2.587-2.507 2.092 2.092 0 00-2.075 2.43z"
              />
              <path
                  data-name="Path 1241"
                  d="M616.654 185.026a2.734 2.734 0 002.563 2.5 2.114 2.114 0 002.1-2.436 2.752 2.752 0 00-2.563-2.5 2.123 2.123 0 00-2.1 2.436z"
              />
              <path
                  data-name="Path 1242"
                  d="M609.221 184.938a2.694 2.694 0 002.54 2.495 2.145 2.145 0 002.129-2.441 2.709 2.709 0 00-2.54-2.495 2.154 2.154 0 00-2.129 2.441z"
              />
              <path
                  data-name="Path 1243"
                  d="M601.782 184.864a2.654 2.654 0 002.518 2.49 2.174 2.174 0 002.154-2.446 2.666 2.666 0 00-2.517-2.488 2.185 2.185 0 00-2.155 2.444z"
              />
              <path
                  data-name="Path 1244"
                  d="M594.339 184.811a2.615 2.615 0 002.495 2.483 2.208 2.208 0 002.181-2.451 2.628 2.628 0 00-2.495-2.483 2.217 2.217 0 00-2.181 2.451z"
              />
              <path
                  data-name="Path 1245"
                  d="M586.892 184.773a2.572 2.572 0 002.47 2.478 2.24 2.24 0 002.207-2.456 2.591 2.591 0 00-2.471-2.478 2.25 2.25 0 00-2.206 2.456z"
              />
              <path
                  data-name="Path 1246"
                  d="M579.441 184.753a2.538 2.538 0 002.448 2.473 2.272 2.272 0 002.233-2.461 2.552 2.552 0 00-2.449-2.472 2.285 2.285 0 00-2.232 2.46z"
              />
              <path
                  data-name="Path 1247"
                  d="M571.988 184.75a2.5 2.5 0 002.425 2.467 2.305 2.305 0 002.258-2.467 2.515 2.515 0 00-2.423-2.468 2.32 2.32 0 00-2.26 2.468z"
              />
              <path
                  data-name="Path 1248"
                  d="M564.533 184.765a2.463 2.463 0 002.4 2.461 2.34 2.34 0 002.283-2.473 2.476 2.476 0 00-2.4-2.462 2.355 2.355 0 00-2.283 2.474z"
              />
              <path
                  data-name="Path 1249"
                  d="M557.077 184.799a2.427 2.427 0 002.375 2.457 2.378 2.378 0 002.309-2.48 2.442 2.442 0 00-2.376-2.456 2.39 2.39 0 00-2.308 2.479z"
              />
              <path
                  data-name="Path 1250"
                  d="M549.619 184.849a2.345 2.345 0 102.335-2.485 2.426 2.426 0 00-2.335 2.485z"
              />
              <path
                  data-name="Path 1251"
                  d="M542.161 184.917a2.356 2.356 0 002.326 2.445 2.446 2.446 0 002.358-2.49 2.365 2.365 0 00-2.326-2.445 2.461 2.461 0 00-2.358 2.49z"
              />
              <path
                  data-name="Path 1252"
                  d="M534.703 185.003a2.32 2.32 0 002.3 2.44 2.484 2.484 0 002.383-2.495 2.332 2.332 0 00-2.3-2.44 2.5 2.5 0 00-2.383 2.495z"
              />
              <path
                  data-name="Path 1253"
                  d="M527.246 185.107a2.286 2.286 0 002.276 2.433 2.522 2.522 0 002.408-2.5 2.3 2.3 0 00-2.276-2.435 2.537 2.537 0 00-2.408 2.502z"
              />
              <path
                  data-name="Path 1254"
                  d="M519.791 185.227a2.253 2.253 0 002.251 2.428 2.56 2.56 0 002.431-2.507 2.263 2.263 0 00-2.251-2.428 2.574 2.574 0 00-2.431 2.507z"
              />
              <path
                  data-name="Path 1255"
                  d="M512.334 185.364a2.219 2.219 0 002.226 2.423 2.6 2.6 0 002.455-2.512 2.231 2.231 0 00-2.226-2.423 2.613 2.613 0 00-2.455 2.512z"
              />
              <path
                  data-name="Path 1256"
                  d="M504.881 185.519a2.187 2.187 0 002.2 2.418 2.639 2.639 0 002.48-2.517 2.2 2.2 0 00-2.2-2.418 2.653 2.653 0 00-2.48 2.517z"
              />
              <path
                  data-name="Path 1257"
                  d="M497.431 185.693a2.157 2.157 0 002.176 2.411 2.676 2.676 0 002.5-2.522 2.166 2.166 0 00-2.174-2.413 2.7 2.7 0 00-2.502 2.524z"
              />
              <path
                  data-name="Path 1258"
                  d="M489.983 185.884a2.125 2.125 0 002.151 2.406 2.718 2.718 0 002.527-2.528 2.136 2.136 0 00-2.151-2.406 2.734 2.734 0 00-2.527 2.528z"
              />
              <path
                  data-name="Path 1259"
                  d="M482.54 186.091a2.094 2.094 0 002.126 2.4 2.757 2.757 0 002.548-2.533 2.1 2.1 0 00-2.124-2.4 2.776 2.776 0 00-2.55 2.533z"
              />
              <path
                  data-name="Path 1260"
                  d="M475.103 186.316a2.064 2.064 0 002.1 2.4 2.8 2.8 0 002.572-2.54 2.074 2.074 0 00-2.1-2.395 2.816 2.816 0 00-2.572 2.535z"
              />
              <path
                  data-name="Path 1261"
                  d="M335.325 193.798c-.384 1.362.329 2.39 1.591 2.294a3.689 3.689 0 002.957-2.635c.376-1.364-.336-2.391-1.589-2.3a3.72 3.72 0 00-2.959 2.641z"
              />
              <path
                  data-name="Path 1262"
                  d="M328.087 194.353c-.4 1.362.3 2.388 1.564 2.291a3.748 3.748 0 002.98-2.642c.388-1.362-.312-2.388-1.562-2.291a3.777 3.777 0 00-2.982 2.642z"
              />
              <path
                  data-name="Path 1263"
                  d="M320.866 194.924c-.408 1.362.281 2.385 1.536 2.286a3.8 3.8 0 003-2.647c.4-1.362-.287-2.386-1.534-2.286a3.83 3.83 0 00-3.002 2.647z"
              />
              <path
                  data-name="Path 1264"
                  d="M313.665 195.509c-.421 1.362.256 2.383 1.509 2.281a3.854 3.854 0 003.015-2.65c.411-1.362-.264-2.383-1.507-2.281a3.878 3.878 0 00-3.017 2.65z"
              />
              <path
                  data-name="Path 1265"
                  d="M270.811 199.336c-.493 1.362.11 2.37 1.344 2.253a4.179 4.179 0 003.11-2.677c.485-1.362-.115-2.37-1.342-2.253a4.214 4.214 0 00-3.112 2.677z"
              />
              <path
                  data-name="Path 1266"
                  d="M263.738 200.024c-.5 1.36.085 2.366 1.317 2.248a4.236 4.236 0 003.123-2.68c.5-1.362-.092-2.37-1.313-2.249a4.274 4.274 0 00-3.127 2.681z"
              />
              <path
                  data-name="Path 1267"
                  d="M256.684 200.725c-.516 1.362.062 2.365 1.288 2.243a4.285 4.285 0 003.138-2.685c.508-1.36-.067-2.365-1.287-2.243a4.325 4.325 0 00-3.139 2.685z"
              />
              <path
                  data-name="Path 1268"
                  d="M207.935 206.011c-.593 1.36-.1 2.348 1.1 2.211a4.691 4.691 0 003.229-2.71c.587-1.36.1-2.351-1.095-2.212a4.735 4.735 0 00-3.234 2.711z"
              />
              <path
                  data-name="Path 1269"
                  d="M201.069 206.817c-.6 1.359-.125 2.348 1.068 2.207a4.752 4.752 0 003.242-2.715c.6-1.359.119-2.346-1.068-2.207a4.791 4.791 0 00-3.242 2.715z"
              />
              <path
                  data-name="Path 1270"
                  d="M194.226 207.635c-.615 1.357-.149 2.345 1.041 2.2a4.816 4.816 0 003.254-2.717c.607-1.36.14-2.346-1.041-2.2a4.844 4.844 0 00-3.254 2.717z"
              />
              <path
                  data-name="Path 1271"
                  d="M187.41 208.464c-.625 1.359-.17 2.341 1.014 2.2a4.866 4.866 0 003.264-2.719c.618-1.359.164-2.345-1.013-2.2a4.9 4.9 0 00-3.265 2.719z"
              />
              <path
                  data-name="Path 1272"
                  d="M180.62 209.307c-.635 1.357-.192 2.34.988 2.192a4.926 4.926 0 003.274-2.722c.628-1.359.187-2.341-.986-2.2a4.971 4.971 0 00-3.276 2.73z"
              />
              <path
                  data-name="Path 1273"
                  d="M173.859 210.159c-.647 1.359-.216 2.338.959 2.189a4.989 4.989 0 003.284-2.726c.638-1.359.211-2.34-.958-2.191a5.028 5.028 0 00-3.285 2.728z"
              />
              <path
                  data-name="Path 1274"
                  d="M167.125 211.023c-.657 1.357-.237 2.335.932 2.184a5.038 5.038 0 003.294-2.729c.648-1.357.231-2.335-.932-2.186a5.085 5.085 0 00-3.294 2.731z"
              />
              <path
                  data-name="Path 1275"
                  d="M153.743 212.782c-.677 1.357-.282 2.331.879 2.177a5.165 5.165 0 003.31-2.734c.668-1.357.276-2.333-.879-2.177a5.2 5.2 0 00-3.31 2.734z"
              />
              <path
                  data-name="Path 1276"
                  d="M1053.304 214.515a6.21 6.21 0 003.4 2.736c1.036.164 1.191-.794.346-2.141a6.25 6.25 0 00-3.394-2.734c-1.031-.165-1.191.791-.352 2.139z"
              />
              <path
                  data-name="Path 1277"
                  d="M1047.316 213.573a6.145 6.145 0 003.394 2.734c1.044.164 1.21-.8.371-2.142a6.184 6.184 0 00-3.394-2.734c-1.038-.164-1.205.795-.371 2.142z"
              />
              <path
                  data-name="Path 1278"
                  d="M1041.291 212.638a6.089 6.089 0 003.394 2.734c1.048.162 1.225-.8.394-2.147a6.136 6.136 0 00-3.392-2.734c-1.043-.163-1.223.798-.396 2.147z"
              />
              <path
                  data-name="Path 1279"
                  d="M1035.228 211.708a6.041 6.041 0 003.392 2.734c1.054.159 1.245-.8.419-2.151a6.075 6.075 0 00-3.392-2.734c-1.049-.16-1.238.802-.419 2.151z"
              />
              <path
                  data-name="Path 1280"
                  d="M1029.129 210.788a6 6 0 003.391 2.732c1.061.159 1.262-.805.444-2.154a6.029 6.029 0 00-3.389-2.734c-1.058-.159-1.255.805-.446 2.156z"
              />
              <path
                  data-name="Path 1281"
                  d="M1022.996 209.873a5.918 5.918 0 003.387 2.73c1.068.159 1.277-.807.47-2.157a5.964 5.964 0 00-3.387-2.732c-1.062-.157-1.274.809-.47 2.159z"
              />
              <path
                  data-name="Path 1282"
                  d="M1016.826 208.966a5.874 5.874 0 003.386 2.73c1.073.157 1.3-.81.493-2.162a5.9 5.9 0 00-3.384-2.729c-1.068-.159-1.292.81-.495 2.161z"
              />
              <path
                  data-name="Path 1283"
                  d="M1010.623 208.068a5.8 5.8 0 003.38 2.729c1.081.155 1.312-.814.52-2.166a5.85 5.85 0 00-3.381-2.729c-1.072-.155-1.306.814-.519 2.166z"
              />
              <path
                  data-name="Path 1284"
                  d="M1004.385 207.179a5.756 5.756 0 003.377 2.727c1.086.154 1.33-.817.545-2.169a5.786 5.786 0 00-3.376-2.729c-1.081-.153-1.323.819-.546 2.171z"
              />
              <path
                  data-name="Path 1285"
                  d="M998.115 206.299a5.7 5.7 0 003.372 2.725c1.093.152 1.347-.82.57-2.174a5.723 5.723 0 00-3.372-2.726c-1.085-.153-1.342.821-.57 2.175z"
              />
              <path
                  data-name="Path 1286"
                  d="M991.81 205.426a5.635 5.635 0 003.369 2.724c1.1.15 1.362-.824.595-2.177a5.677 5.677 0 00-3.367-2.726c-1.093-.15-1.357.826-.597 2.179z"
              />
              <path
                  data-name="Path 1287"
                  d="M979.105 203.711a5.526 5.526 0 003.357 2.721c1.108.147 1.4-.831.647-2.186a5.546 5.546 0 00-3.356-2.721c-1.103-.149-1.393.831-.648 2.186z"
              />
              <path
                  data-name="Path 1288"
                  d="M913.758 195.746a4.913 4.913 0 003.274 2.694c1.161.129 1.569-.869.914-2.228a4.955 4.955 0 00-3.275-2.694c-1.152-.128-1.562.869-.913 2.228z"
              />
              <path
                  data-name="Path 1289"
                  d="M907.066 195.01a4.85 4.85 0 003.264 2.69c1.166.127 1.586-.872.941-2.231a4.9 4.9 0 00-3.264-2.69c-1.156-.127-1.577.873-.941 2.231z"
              />
              <path
                  data-name="Path 1290"
                  d="M900.351 194.288a4.8 4.8 0 003.252 2.687c1.17.125 1.6-.876.969-2.234a4.837 4.837 0 00-3.254-2.687c-1.16-.125-1.596.876-.967 2.234z"
              />
              <path
                  data-name="Path 1291"
                  d="M893.608 193.578a4.737 4.737 0 003.242 2.684c1.173.122 1.621-.879 1-2.239a4.776 4.776 0 00-3.242-2.684c-1.169-.117-1.616.883-1 2.239z"
              />
              <path
                  data-name="Path 1292"
                  d="M886.841 192.881a4.675 4.675 0 003.229 2.68c1.178.12 1.638-.884 1.024-2.244a4.711 4.711 0 00-3.23-2.679c-1.17-.119-1.628.885-1.023 2.243z"
              />
              <path
                  data-name="Path 1293"
                  d="M880.049 192.198a4.63 4.63 0 003.219 2.675c1.182.119 1.653-.887 1.051-2.248a4.657 4.657 0 00-3.218-2.677c-1.175-.116-1.645.89-1.052 2.25z"
              />
              <path
                  data-name="Path 1294"
                  d="M873.229 191.526a4.561 4.561 0 003.205 2.672c1.186.117 1.671-.892 1.079-2.253a4.6 4.6 0 00-3.205-2.672c-1.179-.115-1.657.893-1.079 2.253z"
              />
              <path
                  data-name="Path 1295"
                  d="M866.393 190.869a4.5 4.5 0 003.192 2.669c1.192.114 1.688-.9 1.108-2.258a4.536 4.536 0 00-3.193-2.667c-1.185-.116-1.679.895-1.107 2.256z"
              />
              <path
                  data-name="Path 1296"
                  d="M859.534 190.223a4.445 4.445 0 003.178 2.664c1.195.112 1.7-.9 1.135-2.261a4.477 4.477 0 00-3.18-2.664c-1.191-.112-1.697.905-1.133 2.261z"
              />
              <path
                  data-name="Path 1297"
                  d="M852.643 189.591a4.387 4.387 0 003.165 2.66c1.2.11 1.72-.906 1.163-2.266a4.416 4.416 0 00-3.167-2.66c-1.19-.108-1.709.906-1.161 2.266z"
              />
              <path
                  data-name="Path 1298"
                  d="M845.735 188.973a4.33 4.33 0 003.152 2.657c1.2.105 1.736-.909 1.19-2.269a4.36 4.36 0 00-3.152-2.657c-1.195-.107-1.723.909-1.19 2.269z"
              />
              <path
                  data-name="Path 1299"
                  d="M838.806 188.37a4.273 4.273 0 003.136 2.652c1.207.1 1.755-.914 1.218-2.274a4.3 4.3 0 00-3.137-2.652c-1.2-.104-1.744.914-1.217 2.274z"
              />
              <path
                  data-name="Path 1300"
                  d="M831.853 187.779a4.212 4.212 0 003.123 2.649c1.208.1 1.768-.919 1.245-2.279a4.246 4.246 0 00-3.123-2.649c-1.2-.1-1.764.92-1.245 2.279z"
              />
              <path
                  data-name="Path 1301"
                  d="M824.881 187.205a4.161 4.161 0 003.107 2.644c1.213.1 1.786-.922 1.275-2.284a4.19 4.19 0 00-3.108-2.644c-1.205-.099-1.777.925-1.274 2.284z"
              />
              <path
                  data-name="Path 1302"
                  d="M817.89 186.643a4.1 4.1 0 003.092 2.639c1.218.1 1.8-.926 1.3-2.288a4.13 4.13 0 00-3.091-2.639c-1.209-.096-1.791.928-1.301 2.288z"
              />
              <path
                  data-name="Path 1303"
                  d="M810.878 186.098a4.045 4.045 0 003.075 2.635c1.222.094 1.818-.934 1.33-2.293a4.073 4.073 0 00-3.076-2.635c-1.213-.094-1.807.932-1.329 2.293z"
              />
              <path
                  data-name="Path 1304"
                  d="M803.846 185.566a3.987 3.987 0 003.06 2.63c1.227.092 1.833-.937 1.359-2.3a4.022 4.022 0 00-3.062-2.63c-1.215-.09-1.822.94-1.357 2.3z"
              />
              <path
                  data-name="Path 1305"
                  d="M796.796 185.05a3.936 3.936 0 003.043 2.625c1.228.089 1.85-.942 1.387-2.3a3.965 3.965 0 00-3.045-2.625c-1.218-.091-1.84.938-1.385 2.3z"
              />
              <path
                  data-name="Path 1306"
                  d="M789.727 184.546a3.882 3.882 0 003.028 2.622c1.23.085 1.863-.947 1.414-2.308a3.909 3.909 0 00-3.028-2.62c-1.222-.087-1.853.946-1.414 2.306z"
              />
              <path
                  data-name="Path 1307"
                  d="M782.642 184.06a3.823 3.823 0 003.01 2.615c1.235.084 1.878-.951 1.442-2.311a3.851 3.851 0 00-3.01-2.615c-1.226-.084-1.87.951-1.442 2.311z"
              />
              <path
                  data-name="Path 1308"
                  d="M775.538 183.589a3.767 3.767 0 002.993 2.61c1.238.082 1.9-.954 1.471-2.316a3.8 3.8 0 00-2.993-2.61c-1.229-.082-1.886.956-1.471 2.316z"
              />
              <path
                  data-name="Path 1309"
                  d="M768.418 183.133a3.719 3.719 0 002.976 2.607c1.24.078 1.912-.961 1.5-2.321a3.748 3.748 0 00-2.976-2.607c-1.228-.079-1.903.961-1.5 2.321z"
              />
              <path
                  data-name="Path 1310"
                  d="M761.282 182.694a3.666 3.666 0 002.958 2.6c1.243.077 1.925-.966 1.526-2.326a3.69 3.69 0 00-2.958-2.6c-1.234-.076-1.915.965-1.526 2.326z"
              />
              <path
                  data-name="Path 1311"
                  d="M718.155 180.385a3.359 3.359 0 002.843 2.572c1.258.059 2.017-1 1.694-2.356a3.383 3.383 0 00-2.844-2.572c-1.248-.059-2.005.996-1.693 2.356z"
              />
              <path
                  data-name="Path 1312"
                  d="M710.921 180.058a3.311 3.311 0 002.824 2.565 1.745 1.745 0 001.721-2.361 3.334 3.334 0 00-2.824-2.565 1.753 1.753 0 00-1.721 2.361z"
              />
              <path
                  data-name="Path 1313"
                  d="M703.672 179.747a3.265 3.265 0 002.8 2.56 1.77 1.77 0 001.75-2.366 3.281 3.281 0 00-2.8-2.56 1.777 1.777 0 00-1.75 2.366z"
              />
              <path
                  data-name="Path 1314"
                  d="M696.415 179.453a3.21 3.21 0 002.782 2.553 1.794 1.794 0 001.778-2.371 3.234 3.234 0 00-2.784-2.553 1.8 1.8 0 00-1.776 2.371z"
              />
              <path
                  data-name="Path 1315"
                  d="M689.144 179.174a3.168 3.168 0 002.762 2.55 1.818 1.818 0 001.806-2.376 3.191 3.191 0 00-2.764-2.55 1.826 1.826 0 00-1.804 2.376z"
              />
              <path
                  data-name="Path 1316"
                  d="M681.861 178.913a3.119 3.119 0 002.741 2.543 1.843 1.843 0 001.833-2.381 3.137 3.137 0 00-2.742-2.543 1.851 1.851 0 00-1.832 2.381z"
              />
              <path
                  data-name="Path 1317"
                  d="M674.574 178.67a3.073 3.073 0 002.721 2.538 1.869 1.869 0 001.86-2.386 3.09 3.09 0 00-2.72-2.542 1.877 1.877 0 00-1.861 2.39z"
              />
              <path
                  data-name="Path 1318"
                  d="M667.275 178.442a3.027 3.027 0 002.7 2.533 1.9 1.9 0 001.887-2.393 3.045 3.045 0 00-2.7-2.533 1.9 1.9 0 00-1.887 2.393z"
              />
              <path
                  data-name="Path 1319"
                  d="M659.967 178.231a2.982 2.982 0 002.675 2.528 1.923 1.923 0 001.915-2.4 3 3 0 00-2.677-2.528 1.932 1.932 0 00-1.913 2.4z"
              />
              <path
                  data-name="Path 1320"
                  d="M652.65 178.038a2.936 2.936 0 002.655 2.523 1.95 1.95 0 001.942-2.4 2.954 2.954 0 00-2.655-2.522 1.958 1.958 0 00-1.942 2.399z"
              />
              <path
                  data-name="Path 1321"
                  d="M645.323 177.862a2.9 2.9 0 002.634 2.517 1.977 1.977 0 001.969-2.408 2.91 2.91 0 00-2.634-2.517 1.988 1.988 0 00-1.969 2.408z"
              />
              <path
                  data-name="Path 1322"
                  d="M637.992 177.703a2.85 2.85 0 002.61 2.512 2.008 2.008 0 002-2.415 2.865 2.865 0 00-2.61-2.51 2.015 2.015 0 00-2 2.413z"
              />
              <path
                  data-name="Path 1323"
                  d="M630.653 177.561a2.806 2.806 0 002.587 2.507 2.037 2.037 0 002.024-2.42 2.823 2.823 0 00-2.589-2.5 2.046 2.046 0 00-2.022 2.413z"
              />
              <path
                  data-name="Path 1324"
                  d="M623.306 177.438a2.763 2.763 0 002.565 2.5 2.065 2.065 0 002.049-2.425 2.781 2.781 0 00-2.565-2.5 2.077 2.077 0 00-2.049 2.425z"
              />
              <path
                  data-name="Path 1325"
                  d="M615.955 177.331a2.724 2.724 0 002.542 2.495 2.1 2.1 0 002.076-2.43 2.739 2.739 0 00-2.542-2.495 2.106 2.106 0 00-2.076 2.43z"
              />
              <path
                  data-name="Path 1326"
                  d="M608.6 177.241a2.68 2.68 0 002.518 2.49 2.127 2.127 0 002.1-2.435 2.7 2.7 0 00-2.518-2.49 2.137 2.137 0 00-2.1 2.435z"
              />
              <path
                  data-name="Path 1327"
                  d="M601.237 177.169a2.642 2.642 0 002.5 2.483 2.157 2.157 0 002.127-2.44 2.654 2.654 0 00-2.495-2.483 2.17 2.17 0 00-2.132 2.44z"
              />
              <path
                  data-name="Path 1328"
                  d="M593.873 177.113a2.6 2.6 0 002.472 2.478 2.191 2.191 0 002.154-2.446 2.617 2.617 0 00-2.472-2.478 2.2 2.2 0 00-2.154 2.446z"
              />
              <path
                  data-name="Path 1329"
                  d="M586.503 177.077a2.561 2.561 0 002.446 2.473 2.225 2.225 0 002.182-2.451 2.578 2.578 0 00-2.448-2.473 2.236 2.236 0 00-2.18 2.451z"
              />
              <path
                  data-name="Path 1330"
                  d="M579.131 177.056a2.523 2.523 0 002.423 2.468 2.26 2.26 0 002.208-2.458 2.537 2.537 0 00-2.423-2.466 2.27 2.27 0 00-2.208 2.456z"
              />
              <path
                  data-name="Path 1331"
                  d="M571.754 177.055a2.488 2.488 0 002.4 2.461 2.292 2.292 0 002.233-2.463 2.5 2.5 0 00-2.4-2.461 2.306 2.306 0 00-2.233 2.463z"
              />
              <path
                  data-name="Path 1332"
                  d="M564.376 177.07a2.449 2.449 0 002.376 2.456 2.326 2.326 0 002.258-2.468 2.46 2.46 0 00-2.375-2.456 2.342 2.342 0 00-2.259 2.468z"
              />
              <path
                  data-name="Path 1333"
                  d="M556.999 177.103a2.41 2.41 0 002.349 2.45 2.361 2.361 0 002.284-2.473 2.422 2.422 0 00-2.349-2.45 2.374 2.374 0 00-2.284 2.473z"
              />
              <path
                  data-name="Path 1334"
                  d="M549.618 177.154a2.322 2.322 0 102.311-2.48 2.413 2.413 0 00-2.311 2.48z"
              />
              <path
                  data-name="Path 1335"
                  d="M542.238 177.22a2.337 2.337 0 002.3 2.44 2.434 2.434 0 002.335-2.483 2.353 2.353 0 00-2.3-2.44 2.447 2.447 0 00-2.335 2.483z"
              />
              <path
                  data-name="Path 1336"
                  d="M534.857 177.307a2.3 2.3 0 002.276 2.433 2.471 2.471 0 002.36-2.49 2.317 2.317 0 00-2.276-2.433 2.485 2.485 0 00-2.36 2.49z"
              />
              <path
                  data-name="Path 1337"
                  d="M527.478 177.409a2.273 2.273 0 002.251 2.43 2.51 2.51 0 002.385-2.5 2.282 2.282 0 00-2.251-2.428 2.525 2.525 0 00-2.385 2.498z"
              />
              <path
                  data-name="Path 1338"
                  d="M520.097 177.53a2.24 2.24 0 002.226 2.425 2.551 2.551 0 002.41-2.5 2.248 2.248 0 00-2.226-2.423 2.564 2.564 0 00-2.41 2.498z"
              />
              <path
                  data-name="Path 1339"
                  d="M512.72 177.669a2.2 2.2 0 002.2 2.418 2.587 2.587 0 002.433-2.507 2.214 2.214 0 00-2.2-2.418 2.6 2.6 0 00-2.433 2.507z"
              />
              <path
                  data-name="Path 1340"
                  d="M505.344 177.824a2.173 2.173 0 002.174 2.413 2.628 2.628 0 002.458-2.513 2.182 2.182 0 00-2.174-2.411 2.642 2.642 0 00-2.458 2.511z"
              />
              <path
                  data-name="Path 1341"
                  d="M497.972 177.998a2.139 2.139 0 002.149 2.406 2.668 2.668 0 002.482-2.518 2.15 2.15 0 00-2.149-2.406 2.683 2.683 0 00-2.482 2.518z"
              />
              <path
                  data-name="Path 1342"
                  d="M490.603 178.188a2.108 2.108 0 002.122 2.4 2.707 2.707 0 002.5-2.523 2.118 2.118 0 00-2.122-2.4 2.724 2.724 0 00-2.5 2.523z"
              />
              <path
                  data-name="Path 1343"
                  d="M483.236 178.396a2.077 2.077 0 002.1 2.4 2.751 2.751 0 002.53-2.528 2.088 2.088 0 00-2.1-2.4 2.765 2.765 0 00-2.53 2.528z"
              />
              <path
                  data-name="Path 1344"
                  d="M475.874 178.621a2.047 2.047 0 002.07 2.39 2.789 2.789 0 002.553-2.533 2.056 2.056 0 00-2.07-2.39 2.808 2.808 0 00-2.553 2.533z"
              />
              <path
                  data-name="Path 1345"
                  d="M431.821 180.332a1.881 1.881 0 001.912 2.36 3.06 3.06 0 002.689-2.567 1.889 1.889 0 00-1.912-2.36 3.076 3.076 0 00-2.689 2.567z"
              />
              <path
                  data-name="Path 1346"
                  d="M424.503 180.678a1.853 1.853 0 001.885 2.353 3.1 3.1 0 002.71-2.572 1.861 1.861 0 00-1.885-2.353 3.123 3.123 0 00-2.71 2.572z"
              />
              <path
                  data-name="Path 1347"
                  d="M409.894 181.417a1.8 1.8 0 001.831 2.345 3.2 3.2 0 002.752-2.584 1.811 1.811 0 00-1.832-2.343 3.218 3.218 0 00-2.751 2.582z"
              />
              <path
                  data-name="Path 1348"
                  d="M402.603 181.813a1.779 1.779 0 001.8 2.338 3.244 3.244 0 002.772-2.588 1.786 1.786 0 00-1.8-2.338 3.268 3.268 0 00-2.772 2.588z"
              />
              <path
                  data-name="Path 1349"
                  d="M344.685 185.561c-.376 1.36.334 2.388 1.586 2.3a3.649 3.649 0 002.931-2.629c.368-1.362-.343-2.39-1.584-2.3a3.675 3.675 0 00-2.933 2.629z"
              />
              <path
                  data-name="Path 1350"
                  d="M337.505 186.1c-.389 1.36.309 2.386 1.559 2.293a3.7 3.7 0 002.95-2.63c.379-1.36-.317-2.386-1.557-2.291a3.723 3.723 0 00-2.952 2.628z"
              />
              <path
                  data-name="Path 1351"
                  d="M330.338 186.657c-.4 1.36.286 2.385 1.532 2.288a3.757 3.757 0 002.968-2.639c.391-1.36-.292-2.385-1.531-2.288a3.785 3.785 0 00-2.969 2.639z"
              />
              <path
                  data-name="Path 1352"
                  d="M323.192 187.228c-.416 1.36.259 2.381 1.5 2.283a3.81 3.81 0 002.984-2.644c.4-1.36-.267-2.383-1.5-2.283a3.836 3.836 0 00-2.984 2.644z"
              />
              <path
                  data-name="Path 1353"
                  d="M266.602 192.334c-.511 1.359.065 2.365 1.283 2.244a4.256 4.256 0 003.117-2.679c.5-1.359-.072-2.365-1.283-2.244a4.283 4.283 0 00-3.117 2.679z"
              />
              <path
                  data-name="Path 1354"
                  d="M259.613 193.035c-.521 1.36.042 2.363 1.257 2.241a4.307 4.307 0 003.13-2.684c.513-1.359-.048-2.361-1.255-2.239a4.345 4.345 0 00-3.132 2.682z"
              />
              <path
                  data-name="Path 1355"
                  d="M224.993 196.752c-.578 1.359-.077 2.353 1.121 2.217a4.594 4.594 0 003.2-2.7c.572-1.359.07-2.351-1.118-2.217a4.635 4.635 0 00-3.203 2.7z"
              />
              <path
                  data-name="Path 1356"
                  d="M218.139 197.536c-.59 1.359-.1 2.35 1.093 2.212a4.667 4.667 0 003.21-2.705c.582-1.359.092-2.35-1.091-2.214a4.69 4.69 0 00-3.212 2.707z"
              />
              <path
                  data-name="Path 1357"
                  d="M211.307 198.329c-.6 1.359-.122 2.348 1.066 2.209a4.721 4.721 0 003.223-2.709c.592-1.359.115-2.348-1.064-2.209a4.758 4.758 0 00-3.225 2.709z"
              />
              <path
                  data-name="Path 1358"
                  d="M204.499 199.138c-.612 1.357-.145 2.345 1.038 2.2a4.778 4.778 0 003.235-2.714c.6-1.357.139-2.345-1.038-2.2a4.809 4.809 0 00-3.235 2.714z"
              />
              <path
                  data-name="Path 1359"
                  d="M197.717 199.957c-.622 1.359-.169 2.343 1.011 2.2a4.838 4.838 0 003.245-2.717c.613-1.357.162-2.343-1.009-2.2a4.88 4.88 0 00-3.247 2.717z"
              />
              <path
                  data-name="Path 1360"
                  d="M190.959 200.789c-.632 1.357-.19 2.339.984 2.2a4.9 4.9 0 003.257-2.721c.625-1.357.184-2.341-.983-2.2a4.932 4.932 0 00-3.258 2.721z"
              />
              <path
                  data-name="Path 1361"
                  d="M184.228 201.631c-.642 1.359-.212 2.338.958 2.192a4.952 4.952 0 003.267-2.722c.635-1.359.207-2.34-.956-2.192a5 5 0 00-3.269 2.722z"
              />
              <path
                  data-name="Path 1362"
                  d="M177.521 202.487c-.652 1.357-.236 2.336.931 2.187a5.015 5.015 0 003.277-2.726c.645-1.357.229-2.338-.929-2.187a5.052 5.052 0 00-3.279 2.726z"
              />
              <path
                  data-name="Path 1363"
                  d="M170.846 203.349c-.662 1.357-.257 2.333.9 2.184a5.075 5.075 0 003.287-2.729c.655-1.357.251-2.335-.9-2.184a5.126 5.126 0 00-3.287 2.729z"
              />
              <path
                  data-name="Path 1364"
                  d="M164.197 204.23c-.672 1.357-.279 2.331.877 2.179a5.131 5.131 0 003.3-2.73c.665-1.357.272-2.333-.876-2.179a5.164 5.164 0 00-3.301 2.73z"
              />
              <path
                  data-name="Path 1365"
                  d="M157.575 205.119c-.68 1.355-.3 2.328.851 2.174a5.2 5.2 0 003.305-2.734c.675-1.355.294-2.329-.849-2.176a5.239 5.239 0 00-3.307 2.736z"
              />
              <path
                  data-name="Path 1366"
                  d="M1054.463 207.861a6.288 6.288 0 003.391 2.736c1.023.167 1.16-.787.306-2.132a6.319 6.319 0 00-3.387-2.736c-1.018-.167-1.16.787-.31 2.132z"
              />
              <path
                  data-name="Path 1367"
                  d="M1048.55 206.907a6.234 6.234 0 003.389 2.736c1.031.165 1.176-.792.329-2.137a6.273 6.273 0 00-3.387-2.736c-1.025-.164-1.175.79-.331 2.137z"
              />
              <path
                  data-name="Path 1368"
                  d="M1042.599 205.959a6.189 6.189 0 003.389 2.734c1.038.165 1.193-.794.353-2.141a6.216 6.216 0 00-3.387-2.734c-1.032-.165-1.19.794-.355 2.141z"
              />
              <path
                  data-name="Path 1369"
                  d="M1036.612 205.019a6.131 6.131 0 003.389 2.734c1.043.162 1.21-.8.376-2.144a6.158 6.158 0 00-3.387-2.736c-1.037-.162-1.205.799-.378 2.146z"
              />
              <path
                  data-name="Path 1370"
                  d="M1030.59 204.084a6.075 6.075 0 003.386 2.734c1.05.16 1.228-.8.4-2.149a6.114 6.114 0 00-3.386-2.734c-1.043-.161-1.222.801-.4 2.149z"
              />
              <path
                  data-name="Path 1371"
                  d="M1006.158 200.429a5.844 5.844 0 003.376 2.729c1.073.155 1.293-.814.5-2.164a5.876 5.876 0 00-3.374-2.729c-1.068-.156-1.294.812-.502 2.164z"
              />
              <path
                  data-name="Path 1372"
                  d="M999.966 199.534a5.781 5.781 0 003.371 2.727c1.08.155 1.31-.815.523-2.167a5.836 5.836 0 00-3.371-2.727c-1.073-.154-1.307.815-.523 2.167z"
              />
              <path
                  data-name="Path 1373"
                  d="M993.741 198.65a5.721 5.721 0 003.367 2.726c1.085.152 1.327-.819.546-2.171a5.756 5.756 0 00-3.366-2.726c-1.076-.153-1.322.819-.547 2.171z"
              />
              <path
                  data-name="Path 1374"
                  d="M987.486 197.774a5.66 5.66 0 003.36 2.724c1.091.152 1.347-.822.573-2.174a5.7 5.7 0 00-3.361-2.724c-1.082-.152-1.341.822-.572 2.174z"
              />
              <path
                  data-name="Path 1375"
                  d="M981.197 196.909a5.6 5.6 0 003.356 2.722c1.095.149 1.362-.827.6-2.179a5.64 5.64 0 00-3.355-2.722c-1.092-.148-1.358.827-.601 2.179z"
              />
              <path
                  data-name="Path 1376"
                  d="M974.879 196.053a5.539 5.539 0 003.349 2.719c1.1.147 1.379-.829.623-2.182a5.58 5.58 0 00-3.349-2.719c-1.093-.149-1.373.827-.623 2.182z"
              />
              <path
                  data-name="Path 1377"
                  d="M910.076 188.069a4.926 4.926 0 003.265 2.69c1.15.13 1.546-.866.886-2.223a4.983 4.983 0 00-3.267-2.69c-1.142-.13-1.539.87-.884 2.223z"
              />
              <path
                  data-name="Path 1378"
                  d="M903.439 187.334a4.884 4.884 0 003.255 2.687c1.155.127 1.562-.869.914-2.226a4.919 4.919 0 00-3.255-2.689c-1.142-.127-1.549.871-.914 2.228z"
              />
              <path
                  data-name="Path 1379"
                  d="M896.788 186.61a4.821 4.821 0 003.244 2.684c1.16.125 1.579-.874.941-2.231a4.856 4.856 0 00-3.244-2.684c-1.152-.125-1.573.874-.941 2.231z"
              />
              <path
                  data-name="Path 1380"
                  d="M890.107 185.9a4.763 4.763 0 003.232 2.68c1.165.122 1.6-.879.968-2.236a4.8 4.8 0 00-3.233-2.68c-1.156-.124-1.588.876-.967 2.236z"
              />
              <path
                  data-name="Path 1381"
                  d="M883.402 185.201a4.7 4.7 0 003.22 2.677c1.168.12 1.613-.882.994-2.239a4.735 4.735 0 00-3.22-2.677c-1.161-.12-1.605.882-.994 2.239z"
              />
              <path
                  data-name="Path 1382"
                  d="M876.671 184.516a4.629 4.629 0 003.207 2.672c1.173.119 1.629-.886 1.023-2.243a4.667 4.667 0 00-3.208-2.672c-1.166-.118-1.622.886-1.022 2.243z"
              />
              <path
                  data-name="Path 1383"
                  d="M869.919 183.844a4.58 4.58 0 003.193 2.669c1.176.115 1.646-.891 1.049-2.248a4.6 4.6 0 00-3.2-2.669c-1.165-.116-1.63.891-1.042 2.248z"
              />
              <path
                  data-name="Path 1384"
                  d="M863.141 183.186a4.522 4.522 0 003.182 2.664c1.181.114 1.664-.894 1.078-2.253a4.556 4.556 0 00-3.183-2.664c-1.172-.113-1.654.895-1.077 2.253z"
              />
              <path
                  data-name="Path 1385"
                  d="M856.343 182.539a4.465 4.465 0 003.168 2.662c1.183.11 1.678-.9 1.1-2.258a4.5 4.5 0 00-3.17-2.66c-1.17-.112-1.664.9-1.098 2.256z"
              />
              <path
                  data-name="Path 1386"
                  d="M849.522 181.908a4.4 4.4 0 003.155 2.657c1.188.109 1.694-.9 1.131-2.261a4.434 4.434 0 00-3.155-2.657c-1.18-.108-1.688.903-1.131 2.261z"
              />
              <path
                  data-name="Path 1387"
                  d="M842.678 181.291a4.346 4.346 0 003.14 2.652c1.193.105 1.711-.907 1.16-2.266a4.368 4.368 0 00-3.14-2.652c-1.181-.107-1.703.907-1.16 2.266z"
              />
              <path
                  data-name="Path 1388"
                  d="M835.82 180.686a4.279 4.279 0 003.125 2.647c1.195.105 1.73-.911 1.188-2.269a4.316 4.316 0 00-3.127-2.647c-1.186-.105-1.722.911-1.186 2.269z"
              />
              <path
                  data-name="Path 1389"
                  d="M828.93 180.095a4.225 4.225 0 003.11 2.644c1.2.1 1.745-.917 1.217-2.274a4.261 4.261 0 00-3.111-2.644c-1.188-.101-1.736.917-1.216 2.274z"
              />
              <path
                  data-name="Path 1390"
                  d="M822.025 179.522a4.172 4.172 0 003.095 2.639c1.2.1 1.76-.922 1.245-2.279a4.2 4.2 0 00-3.1-2.639c-1.189-.099-1.748.92-1.24 2.279z"
              />
              <path
                  data-name="Path 1391"
                  d="M815.101 178.96a4.113 4.113 0 003.08 2.634c1.2.1 1.776-.926 1.272-2.283a4.135 4.135 0 00-3.08-2.634c-1.197-.096-1.768.925-1.272 2.283z"
              />
              <path
                  data-name="Path 1392"
                  d="M808.157 178.414a4.055 4.055 0 003.063 2.629c1.21.1 1.791-.929 1.3-2.288a4.081 4.081 0 00-3.065-2.629c-1.199-.096-1.781.929-1.298 2.288z"
              />
              <path
                  data-name="Path 1393"
                  d="M801.196 177.882a4 4 0 003.046 2.624c1.213.092 1.808-.934 1.329-2.293a4.025 4.025 0 00-3.048-2.624c-1.204-.09-1.797.934-1.327 2.293z"
              />
              <path
                  data-name="Path 1394"
                  d="M794.215 177.364a3.939 3.939 0 003.03 2.62c1.217.09 1.823-.939 1.357-2.3a3.971 3.971 0 00-3.031-2.62c-1.207-.084-1.814.943-1.356 2.3z"
              />
              <path
                  data-name="Path 1395"
                  d="M787.217 176.863a3.884 3.884 0 003.013 2.615c1.218.087 1.84-.944 1.385-2.3a3.918 3.918 0 00-3.015-2.615c-1.209-.088-1.829.943-1.383 2.3z"
              />
              <path
                  data-name="Path 1396"
                  d="M780.202 176.377a3.829 3.829 0 003 2.61c1.223.084 1.853-.949 1.412-2.306a3.854 3.854 0 00-3-2.61c-1.213-.081-1.845.949-1.412 2.306z"
              />
              <path
                  data-name="Path 1397"
                  d="M773.171 175.905a3.775 3.775 0 002.978 2.605c1.225.082 1.87-.954 1.44-2.311a3.8 3.8 0 00-2.979-2.605c-1.216-.08-1.86.952-1.439 2.311z"
              />
              <path
                  data-name="Path 1398"
                  d="M766.121 175.449a3.725 3.725 0 002.961 2.6c1.228.077 1.885-.959 1.469-2.316a3.747 3.747 0 00-2.963-2.6c-1.222-.077-1.873.959-1.467 2.316z"
              />
              <path
                  data-name="Path 1399"
                  d="M759.058 175.01a3.668 3.668 0 002.943 2.6c1.23.077 1.9-.963 1.5-2.321a3.688 3.688 0 00-2.943-2.593c-1.226-.082-1.896.957-1.5 2.314z"
              />
              <path
                  data-name="Path 1400"
                  d="M751.98 174.585a3.615 3.615 0 002.924 2.59c1.233.074 1.915-.967 1.524-2.324a3.641 3.641 0 00-2.924-2.59c-1.224-.074-1.911.967-1.524 2.324z"
              />
              <path
                  data-name="Path 1401"
                  d="M744.886 174.177a3.561 3.561 0 002.9 2.585c1.237.07 1.932-.973 1.552-2.331a3.586 3.586 0 00-2.906-2.585c-1.22-.068-1.916.973-1.546 2.331z"
              />
              <path
                  data-name="Path 1402"
                  d="M709.214 172.378a3.306 3.306 0 002.806 2.558c1.247.057 2.005-1 1.693-2.355a3.33 3.33 0 00-2.807-2.56c-1.238-.056-1.995 1-1.692 2.357z"
              />
              <path
                  data-name="Path 1403"
                  d="M702.041 172.067a3.259 3.259 0 002.786 2.553 1.754 1.754 0 001.719-2.361 3.279 3.279 0 00-2.786-2.553 1.762 1.762 0 00-1.719 2.361z"
              />
              <path
                  data-name="Path 1404"
                  d="M694.858 171.773a3.211 3.211 0 002.764 2.548 1.78 1.78 0 001.75-2.366 3.235 3.235 0 00-2.766-2.548 1.784 1.784 0 00-1.748 2.366z"
              />
              <path
                  data-name="Path 1405"
                  d="M687.665 171.494a3.159 3.159 0 002.744 2.543 1.8 1.8 0 001.776-2.37 3.183 3.183 0 00-2.746-2.543 1.809 1.809 0 00-1.774 2.37z"
              />
              <path
                  data-name="Path 1406"
                  d="M680.461 171.233a3.112 3.112 0 002.722 2.538 1.827 1.827 0 001.8-2.376 3.129 3.129 0 00-2.722-2.537 1.835 1.835 0 00-1.8 2.375z"
              />
              <path
                  data-name="Path 1407"
                  d="M673.247 170.989a3.067 3.067 0 002.7 2.533 1.854 1.854 0 001.831-2.381 3.082 3.082 0 00-2.7-2.532 1.861 1.861 0 00-1.831 2.38z"
              />
              <path
                  data-name="Path 1408"
                  d="M666.024 170.762a3.018 3.018 0 002.679 2.528 1.88 1.88 0 001.858-2.386 3.036 3.036 0 00-2.679-2.527 1.887 1.887 0 00-1.858 2.385z"
              />
              <path
                  data-name="Path 1409"
                  d="M658.794 170.553a2.974 2.974 0 002.657 2.522 1.906 1.906 0 001.885-2.391 2.991 2.991 0 00-2.657-2.522 1.915 1.915 0 00-1.885 2.391z"
              />
              <path
                  data-name="Path 1410"
                  d="M651.553 170.359a2.928 2.928 0 002.635 2.517 1.933 1.933 0 001.913-2.4 2.948 2.948 0 00-2.635-2.517 1.944 1.944 0 00-1.913 2.4z"
              />
              <path
                  data-name="Path 1411"
                  d="M644.305 170.184a2.881 2.881 0 002.612 2.51 1.961 1.961 0 001.942-2.4 2.9 2.9 0 00-2.612-2.51 1.971 1.971 0 00-1.942 2.4z"
              />
              <path
                  data-name="Path 1412"
                  d="M637.051 170.025a2.838 2.838 0 002.588 2.5 1.991 1.991 0 001.969-2.406 2.855 2.855 0 00-2.589-2.5 2 2 0 00-1.968 2.406z"
              />
              <path
                  data-name="Path 1413"
                  d="M629.791 169.884a2.793 2.793 0 002.565 2.5 2.018 2.018 0 002-2.411 2.813 2.813 0 00-2.567-2.5 2.029 2.029 0 00-1.998 2.411z"
              />
              <path
                  data-name="Path 1414"
                  d="M622.524 169.76a2.751 2.751 0 002.542 2.495 2.05 2.05 0 002.022-2.418 2.766 2.766 0 00-2.542-2.493 2.059 2.059 0 00-2.022 2.416z"
              />
              <path
                  data-name="Path 1415"
                  d="M615.249 169.653a2.71 2.71 0 002.52 2.488 2.08 2.08 0 002.049-2.423 2.725 2.725 0 00-2.52-2.487 2.089 2.089 0 00-2.049 2.422z"
              />
              <path
                  data-name="Path 1416"
                  d="M607.972 169.564a2.668 2.668 0 002.495 2.483 2.114 2.114 0 002.077-2.43 2.686 2.686 0 00-2.5-2.482 2.12 2.12 0 00-2.072 2.429z"
              />
              <path
                  data-name="Path 1417"
                  d="M600.688 169.492a2.63 2.63 0 002.473 2.477 2.142 2.142 0 002.1-2.433 2.643 2.643 0 00-2.473-2.477 2.154 2.154 0 00-2.1 2.433z"
              />
              <path
                  data-name="Path 1418"
                  d="M593.4 169.436a2.587 2.587 0 002.45 2.471 2.177 2.177 0 002.129-2.44 2.6 2.6 0 00-2.448-2.47 2.187 2.187 0 00-2.131 2.439z"
              />
              <path
                  data-name="Path 1419"
                  d="M586.109 169.4a2.551 2.551 0 002.425 2.467 2.21 2.21 0 002.156-2.445 2.563 2.563 0 00-2.425-2.466 2.222 2.222 0 00-2.156 2.444z"
              />
              <path
                  data-name="Path 1420"
                  d="M578.816 169.38a2.511 2.511 0 002.4 2.461 2.242 2.242 0 002.181-2.451 2.524 2.524 0 00-2.4-2.46 2.255 2.255 0 00-2.181 2.45z"
              />
              <path
                  data-name="Path 1421"
                  d="M571.518 169.378a2.471 2.471 0 002.376 2.455 2.277 2.277 0 002.208-2.456 2.484 2.484 0 00-2.376-2.453 2.288 2.288 0 00-2.208 2.454z"
              />
              <path
                  data-name="Path 1422"
                  d="M564.221 169.393a2.431 2.431 0 002.35 2.45 2.312 2.312 0 002.234-2.462 2.445 2.445 0 00-2.351-2.448 2.321 2.321 0 00-2.233 2.46z"
              />
              <path
                  data-name="Path 1423"
                  d="M556.92 169.427a2.4 2.4 0 002.326 2.443 2.347 2.347 0 002.259-2.466 2.409 2.409 0 00-2.326-2.443 2.358 2.358 0 00-2.259 2.466z"
              />
              <path
                  data-name="Path 1424"
                  d="M549.618 169.477a2.36 2.36 0 002.3 2.438 2.46 2.46 0 10-2.3-2.438z"
              />
              <path
                  data-name="Path 1425"
                  d="M542.317 169.545a2.325 2.325 0 002.276 2.433 2.421 2.421 0 002.309-2.478 2.335 2.335 0 00-2.274-2.433 2.435 2.435 0 00-2.311 2.478z"
              />
              <path
                  data-name="Path 1426"
                  d="M535.014 169.63a2.29 2.29 0 002.251 2.428 2.461 2.461 0 002.336-2.485 2.3 2.3 0 00-2.251-2.426 2.472 2.472 0 00-2.336 2.483z"
              />
              <path
                  data-name="Path 1427"
                  d="M527.714 169.732a2.253 2.253 0 002.224 2.423 2.5 2.5 0 002.361-2.49 2.265 2.265 0 00-2.224-2.421 2.512 2.512 0 00-2.361 2.488z"
              />
              <path
                  data-name="Path 1428"
                  d="M520.412 169.854a2.222 2.222 0 002.2 2.416 2.535 2.535 0 002.386-2.495 2.232 2.232 0 00-2.2-2.416 2.551 2.551 0 00-2.386 2.495z"
              />
              <path
                  data-name="Path 1429"
                  d="M513.11 169.991a2.188 2.188 0 002.172 2.411 2.575 2.575 0 002.411-2.5 2.2 2.2 0 00-2.172-2.411 2.592 2.592 0 00-2.411 2.5z"
              />
              <path
                  data-name="Path 1430"
                  d="M505.815 170.147a2.156 2.156 0 002.147 2.406 2.618 2.618 0 002.436-2.507 2.167 2.167 0 00-2.147-2.4 2.632 2.632 0 00-2.436 2.501z"
              />
              <path
                  data-name="Path 1431"
                  d="M498.522 170.321a2.123 2.123 0 002.121 2.4 2.654 2.654 0 002.46-2.512 2.132 2.132 0 00-2.121-2.4 2.673 2.673 0 00-2.46 2.512z"
              />
              <path
                  data-name="Path 1432"
                  d="M491.229 170.509a2.093 2.093 0 002.1 2.4 2.7 2.7 0 002.485-2.517 2.1 2.1 0 00-2.1-2.395 2.715 2.715 0 00-2.485 2.512z"
              />
              <path
                  data-name="Path 1433"
                  d="M483.942 170.718a2.061 2.061 0 002.069 2.39 2.741 2.741 0 002.508-2.523 2.07 2.07 0 00-2.069-2.388 2.756 2.756 0 00-2.508 2.521z"
              />
              <path
                  data-name="Path 1434"
                  d="M346.823 177.879c-.381 1.357.316 2.383 1.556 2.293a3.654 3.654 0 002.916-2.624c.373-1.359-.323-2.383-1.552-2.291a3.676 3.676 0 00-2.92 2.622z"
              />
              <path
                  data-name="Path 1435"
                  d="M339.715 178.42c-.394 1.355.289 2.381 1.527 2.286a3.7 3.7 0 002.936-2.629c.384-1.357-.3-2.381-1.526-2.286a3.735 3.735 0 00-2.937 2.629z"
              />
              <path
                  data-name="Path 1436"
                  d="M325.541 179.546c-.419 1.357.241 2.376 1.472 2.278a3.816 3.816 0 002.971-2.639c.411-1.357-.247-2.376-1.471-2.278a3.843 3.843 0 00-2.972 2.639z"
              />
              <path
                  data-name="Path 1437"
                  d="M262.578 185.357c-.528 1.357.02 2.358 1.225 2.236a4.322 4.322 0 003.12-2.679c.52-1.359-.028-2.36-1.223-2.236a4.356 4.356 0 00-3.122 2.679z"
              />
              <path
                  data-name="Path 1438"
                  d="M255.675 186.074c-.538 1.357 0 2.356 1.2 2.231a4.377 4.377 0 003.135-2.682c.531-1.359 0-2.356-1.2-2.233a4.422 4.422 0 00-3.135 2.684z"
              />
              <path
                  data-name="Path 1439"
                  d="M248.793 186.805c-.55 1.359-.025 2.355 1.171 2.228a4.438 4.438 0 003.148-2.687c.543-1.357.02-2.355-1.168-2.228a4.476 4.476 0 00-3.151 2.687z"
              />
              <path
                  data-name="Path 1440"
                  d="M241.934 187.55c-.561 1.355-.05 2.351 1.143 2.223a4.505 4.505 0 003.163-2.692c.553-1.357.042-2.351-1.143-2.223a4.532 4.532 0 00-3.163 2.692z"
              />
              <path
                  data-name="Path 1441"
                  d="M235.093 188.306c-.573 1.357-.074 2.35 1.118 2.217a4.558 4.558 0 003.175-2.7c.566-1.357.067-2.349-1.115-2.217a4.6 4.6 0 00-3.178 2.7z"
              />
              <path
                  data-name="Path 1442"
                  d="M228.277 189.077c-.583 1.357-.1 2.348 1.09 2.214a4.619 4.619 0 003.19-2.7c.577-1.357.089-2.348-1.09-2.214a4.655 4.655 0 00-3.19 2.7z"
              />
              <path
                  data-name="Path 1443"
                  d="M221.483 189.861c-.595 1.357-.119 2.346 1.063 2.209a4.679 4.679 0 003.2-2.7c.588-1.357.112-2.346-1.061-2.211a4.721 4.721 0 00-3.202 2.702z"
              />
              <path
                  data-name="Path 1444"
                  d="M214.713 190.66c-.607 1.355-.144 2.343 1.034 2.2a4.741 4.741 0 003.215-2.707c.6-1.355.135-2.343-1.034-2.2a4.767 4.767 0 00-3.215 2.707z"
              />
              <path
                  data-name="Path 1445"
                  d="M207.966 191.467c-.617 1.355-.165 2.341 1.008 2.2a4.807 4.807 0 003.227-2.71c.608-1.357.159-2.341-1.008-2.2a4.834 4.834 0 00-3.227 2.71z"
              />
              <path
                  data-name="Path 1446"
                  d="M201.243 192.287c-.628 1.355-.187 2.34.981 2.2a4.858 4.858 0 003.239-2.714c.62-1.355.18-2.338-.981-2.2a4.9 4.9 0 00-3.239 2.714z"
              />
              <path
                  data-name="Path 1447"
                  d="M194.545 193.119c-.638 1.355-.211 2.338.954 2.192a4.915 4.915 0 003.249-2.717c.63-1.355.2-2.336-.952-2.192a4.961 4.961 0 00-3.251 2.717z"
              />
              <path
                  data-name="Path 1448"
                  d="M187.871 193.964c-.648 1.355-.231 2.335.929 2.189a4.989 4.989 0 003.26-2.721c.64-1.355.224-2.336-.927-2.189a5.021 5.021 0 00-3.262 2.721z"
              />
              <path
                  data-name="Path 1449"
                  d="M181.224 194.825c-.657 1.355-.254 2.333.9 2.184a5.041 5.041 0 003.27-2.724c.65-1.355.247-2.333-.9-2.184a5.081 5.081 0 00-3.27 2.724z"
              />
              <path
                  data-name="Path 1450"
                  d="M174.603 195.689c-.668 1.355-.276 2.331.876 2.181a5.1 5.1 0 003.28-2.727c.662-1.355.269-2.331-.874-2.181a5.144 5.144 0 00-3.282 2.727z"
              />
              <path
                  data-name="Path 1451"
                  d="M168.01 196.568c-.678 1.355-.3 2.329.847 2.177a5.168 5.168 0 003.29-2.73c.67-1.354.291-2.329-.849-2.176a5.2 5.2 0 00-3.288 2.729z"
              />
              <path
                  data-name="Path 1452"
                  d="M161.448 197.459c-.688 1.354-.319 2.328.822 2.172a5.23 5.23 0 003.3-2.732c.68-1.354.312-2.328-.822-2.172a5.262 5.262 0 00-3.3 2.732z"
              />
              <path
                  data-name="Path 1453"
                  d="M154.903 198.36c-.7 1.355-.341 2.324.8 2.167a5.275 5.275 0 003.307-2.734c.69-1.354.334-2.326-.8-2.167a5.316 5.316 0 00-3.307 2.734z"
              />
              <path
                  data-name="Path 1454"
                  d="M148.393 199.272c-.707 1.354-.363 2.321.77 2.164a5.348 5.348 0 003.315-2.739c.7-1.352.354-2.323-.77-2.164a5.383 5.383 0 00-3.315 2.739z"
              />
              <path
                  data-name="Path 1455"
                  d="M141.911 200.193c-.715 1.354-.383 2.321.744 2.161a5.409 5.409 0 003.324-2.741c.709-1.354.376-2.319-.744-2.159a5.44 5.44 0 00-3.324 2.739z"
              />
              <path
                  data-name="Path 1456"
                  d="M1037.864 198.348a6.217 6.217 0 003.382 2.736c1.029.164 1.18-.792.336-2.137a6.247 6.247 0 00-3.38-2.736c-1.024-.166-1.178.792-.338 2.137z"
              />
              <path
                  data-name="Path 1457"
                  d="M1031.915 197.402a6.163 6.163 0 003.382 2.736c1.034.164 1.195-.8.358-2.142a6.181 6.181 0 00-3.379-2.734c-1.031-.168-1.191.795-.361 2.14z"
              />
              <path
                  data-name="Path 1458"
                  d="M1001.67 192.793a5.863 5.863 0 003.369 2.727c1.066.157 1.277-.81.476-2.159a5.905 5.905 0 00-3.367-2.725c-1.06-.16-1.272.807-.478 2.157z"
              />
              <path
                  data-name="Path 1459"
                  d="M995.523 191.896a5.81 5.81 0 003.364 2.727c1.07.155 1.3-.815.5-2.164a5.843 5.843 0 00-3.364-2.727c-1.063-.158-1.287.815-.5 2.164z"
              />
              <path
                  data-name="Path 1460"
                  d="M989.345 191.009a5.737 5.737 0 003.359 2.724c1.076.154 1.31-.817.525-2.167a5.783 5.783 0 00-3.359-2.724c-1.068-.154-1.306.817-.525 2.167z"
              />
              <path
                  data-name="Path 1461"
                  d="M983.136 190.132a5.687 5.687 0 003.354 2.722c1.081.152 1.329-.82.55-2.172a5.727 5.727 0 00-3.357-2.719c-1.072-.156-1.319.817-.547 2.169z"
              />
              <path
                  data-name="Path 1462"
                  d="M976.895 189.262a5.63 5.63 0 003.347 2.721c1.086.15 1.344-.824.575-2.176a5.668 5.668 0 00-3.347-2.721c-1.079-.149-1.338.826-.575 2.176z"
              />
              <path
                  data-name="Path 1463"
                  d="M970.626 188.403a5.571 5.571 0 003.342 2.719c1.091.149 1.359-.829.6-2.181a5.606 5.606 0 00-3.34-2.717c-1.088-.149-1.36.829-.602 2.179z"
              />
              <path
                  data-name="Path 1464"
                  d="M938.837 184.265a5.268 5.268 0 003.305 2.7c1.116.139 1.442-.846.727-2.2a5.31 5.31 0 00-3.305-2.7c-1.109-.137-1.434.845-.727 2.2z"
              />
              <path
                  data-name="Path 1465"
                  d="M906.364 180.406a4.968 4.968 0 003.255 2.687c1.14.129 1.526-.864.859-2.219a4.987 4.987 0 00-3.257-2.687c-1.132-.129-1.517.863-.857 2.219z"
              />
              <path
                  data-name="Path 1466"
                  d="M899.792 179.669a4.9 4.9 0 003.245 2.684c1.146.127 1.539-.869.886-2.223a4.929 4.929 0 00-3.245-2.684c-1.138-.127-1.534.868-.886 2.223z"
              />
              <path
                  data-name="Path 1467"
                  d="M893.196 178.945a4.839 4.839 0 003.234 2.679c1.148.125 1.557-.872.912-2.226a4.864 4.864 0 00-3.235-2.679c-1.142-.125-1.549.871-.911 2.226z"
              />
              <path
                  data-name="Path 1468"
                  d="M886.576 178.233a4.771 4.771 0 003.222 2.675c1.153.124 1.574-.874.939-2.229a4.812 4.812 0 00-3.222-2.677c-1.145-.122-1.566.876-.939 2.231z"
              />
              <path
                  data-name="Path 1469"
                  d="M879.931 177.534a4.712 4.712 0 003.208 2.672c1.158.12 1.591-.879.968-2.234a4.751 4.751 0 00-3.212-2.672c-1.147-.119-1.578.881-.964 2.234z"
              />
              <path
                  data-name="Path 1470"
                  d="M873.264 176.849a4.651 4.651 0 003.2 2.667c1.161.119 1.606-.884.993-2.238a4.684 4.684 0 00-3.2-2.669c-1.155-.118-1.6.885-.993 2.24z"
              />
              <path
                  data-name="Path 1471"
                  d="M866.573 176.178a4.6 4.6 0 003.184 2.664c1.166.115 1.623-.889 1.021-2.244a4.625 4.625 0 00-3.185-2.665c-1.155-.115-1.613.89-1.02 2.245z"
              />
              <path
                  data-name="Path 1472"
                  d="M859.858 175.518a4.536 4.536 0 003.17 2.66c1.17.114 1.639-.892 1.049-2.248a4.563 4.563 0 00-3.172-2.659c-1.156-.115-1.628.896-1.047 2.247z"
              />
              <path
                  data-name="Path 1473"
                  d="M853.126 174.872a4.474 4.474 0 003.157 2.655c1.173.112 1.654-.9 1.076-2.251a4.5 4.5 0 00-3.158-2.655c-1.165-.115-1.643.896-1.075 2.251z"
              />
              <path
                  data-name="Path 1474"
                  d="M846.37 174.241a4.412 4.412 0 003.143 2.65c1.177.11 1.671-.9 1.1-2.256a4.442 4.442 0 00-3.143-2.65c-1.165-.108-1.658.901-1.1 2.256z"
              />
              <path
                  data-name="Path 1475"
                  d="M839.594 173.622a4.355 4.355 0 003.128 2.647c1.18.107 1.686-.906 1.13-2.261a4.382 4.382 0 00-3.128-2.645c-1.172-.107-1.678.904-1.13 2.259z"
              />
              <path
                  data-name="Path 1476"
                  d="M832.796 173.019a4.3 4.3 0 003.109 2.638c1.185.1 1.7-.909 1.158-2.266a4.324 4.324 0 00-3.113-2.64c-1.171-.101-1.689.913-1.154 2.268z"
              />
              <path
                  data-name="Path 1477"
                  d="M825.978 172.429a4.237 4.237 0 003.1 2.637c1.188.1 1.719-.914 1.186-2.269a4.269 4.269 0 00-3.1-2.637c-1.178-.102-1.709.914-1.186 2.269z"
              />
              <path
                  data-name="Path 1478"
                  d="M819.141 171.853a4.18 4.18 0 003.088 2.633c1.19.1 1.735-.919 1.213-2.273a4.211 4.211 0 00-3.083-2.634c-1.184-.093-1.73.921-1.218 2.274z"
              />
              <path
                  data-name="Path 1479"
                  d="M812.287 171.293a4.115 4.115 0 003.066 2.627c1.193.1 1.748-.922 1.242-2.278a4.15 4.15 0 00-3.068-2.627c-1.183-.095-1.74.923-1.24 2.278z"
              />
              <path
                  data-name="Path 1480"
                  d="M805.412 170.746a4.06 4.06 0 003.05 2.624c1.2.094 1.768-.927 1.27-2.283a4.086 4.086 0 00-3.051-2.622c-1.187-.095-1.758.928-1.269 2.281z"
              />
              <path
                  data-name="Path 1481"
                  d="M798.518 170.215a4 4 0 003.035 2.619c1.2.092 1.781-.932 1.3-2.286a4.026 4.026 0 00-3.035-2.619c-1.193-.092-1.774.931-1.3 2.286z"
              />
              <path
                  data-name="Path 1482"
                  d="M791.609 169.699a3.947 3.947 0 003.016 2.615c1.2.089 1.8-.937 1.327-2.291a3.977 3.977 0 00-3.018-2.614c-1.193-.089-1.786.935-1.325 2.29z"
              />
              <path
                  data-name="Path 1483"
                  d="M784.683 169.197a3.888 3.888 0 003 2.609c1.2.087 1.811-.941 1.354-2.3a3.918 3.918 0 00-3-2.607c-1.199-.083-1.804.943-1.354 2.298z"
              />
              <path
                  data-name="Path 1484"
                  d="M777.737 168.714a3.836 3.836 0 002.983 2.6c1.208.084 1.828-.946 1.382-2.3a3.861 3.861 0 00-2.983-2.6c-1.196-.086-1.818.944-1.382 2.3z"
              />
              <path
                  data-name="Path 1485"
                  d="M770.777 168.24a3.781 3.781 0 002.964 2.6c1.212.08 1.845-.951 1.41-2.306a3.808 3.808 0 00-2.964-2.6c-1.203-.077-1.834.953-1.41 2.306z"
              />
              <path
                  data-name="Path 1486"
                  d="M763.802 167.786a3.726 3.726 0 002.944 2.594c1.215.079 1.86-.956 1.439-2.309a3.751 3.751 0 00-2.946-2.594c-1.206-.079-1.85.956-1.437 2.309z"
              />
              <path
                  data-name="Path 1487"
                  d="M756.812 167.346a3.668 3.668 0 002.926 2.589c1.218.075 1.875-.959 1.466-2.314a3.7 3.7 0 00-2.928-2.588c-1.208-.073-1.863.959-1.464 2.313z"
              />
              <path
                  data-name="Path 1488"
                  d="M749.806 166.921a3.613 3.613 0 002.908 2.584c1.222.074 1.89-.964 1.494-2.318a3.637 3.637 0 00-2.908-2.583c-1.212-.074-1.881.964-1.494 2.317z"
              />
              <path
                  data-name="Path 1489"
                  d="M742.785 166.514a3.561 3.561 0 002.889 2.578c1.223.072 1.9-.969 1.522-2.323a3.585 3.585 0 00-2.889-2.578c-1.213-.068-1.895.97-1.522 2.323z"
              />
              <path
                  data-name="Path 1490"
                  d="M735.752 166.123a3.51 3.51 0 002.869 2.573c1.225.067 1.92-.976 1.551-2.329a3.531 3.531 0 00-2.869-2.572c-1.215-.067-1.91.974-1.551 2.328z"
              />
              <path
                  data-name="Path 1491"
                  d="M728.705 165.747a3.459 3.459 0 002.849 2.564c1.228.064 1.933-.981 1.579-2.334a3.482 3.482 0 00-2.851-2.567c-1.216-.061-1.923.983-1.577 2.337z"
              />
              <path
                  data-name="Path 1492"
                  d="M721.644 165.388a3.4 3.4 0 002.829 2.562c1.23.062 1.95-.984 1.608-2.338a3.429 3.429 0 00-2.831-2.562c-1.22-.062-1.936.984-1.606 2.338z"
              />
              <path
                  data-name="Path 1493"
                  d="M714.572 165.043a3.35 3.35 0 002.809 2.558c1.232.059 1.965-.991 1.634-2.345a3.372 3.372 0 00-2.809-2.555c-1.223-.059-1.953.99-1.634 2.342z"
              />
              <path
                  data-name="Path 1494"
                  d="M707.489 164.718a3.3 3.3 0 002.787 2.552c1.235.055 1.978-1 1.663-2.348a3.324 3.324 0 00-2.787-2.552c-1.226-.055-1.969.994-1.663 2.348z"
              />
              <path
                  data-name="Path 1495"
                  d="M686.17 163.832a3.153 3.153 0 002.726 2.535 1.786 1.786 0 001.746-2.363 3.172 3.172 0 00-2.725-2.535 1.793 1.793 0 00-1.747 2.363z"
              />
              <path
                  data-name="Path 1496"
                  d="M679.044 163.576a3.1 3.1 0 002.7 2.53 1.812 1.812 0 001.775-2.37 3.124 3.124 0 00-2.7-2.528 1.817 1.817 0 00-1.775 2.368z"
              />
              <path
                  data-name="Path 1497"
                  d="M671.907 163.332a3.056 3.056 0 002.68 2.525 1.838 1.838 0 001.8-2.375 3.076 3.076 0 00-2.682-2.523 1.845 1.845 0 00-1.798 2.373z"
              />
              <path
                  data-name="Path 1498"
                  d="M664.761 163.106a3.01 3.01 0 002.659 2.518 1.861 1.861 0 001.83-2.378 3.024 3.024 0 00-2.655-2.513 1.87 1.87 0 00-1.834 2.373z"
              />
              <path
                  data-name="Path 1499"
                  d="M657.607 162.896a2.964 2.964 0 002.637 2.513 1.89 1.89 0 001.858-2.383 2.981 2.981 0 00-2.637-2.513 1.9 1.9 0 00-1.858 2.383z"
              />
              <path
                  data-name="Path 1500"
                  d="M650.45 162.704a2.916 2.916 0 002.614 2.508 1.917 1.917 0 001.885-2.39 2.935 2.935 0 00-2.614-2.507 1.924 1.924 0 00-1.885 2.389z"
              />
              <path
                  data-name="Path 1501"
                  d="M643.273 162.528a2.871 2.871 0 002.59 2.5 1.945 1.945 0 001.913-2.393 2.889 2.889 0 00-2.592-2.5 1.953 1.953 0 00-1.911 2.393z"
              />
              <path
                  data-name="Path 1502"
                  d="M636.1 162.369a2.828 2.828 0 002.568 2.5 1.974 1.974 0 001.94-2.4 2.844 2.844 0 00-2.568-2.495 1.984 1.984 0 00-1.94 2.395z"
              />
              <path
                  data-name="Path 1503"
                  d="M628.918 162.229a2.784 2.784 0 002.545 2.492 2 2 0 001.967-2.406 2.8 2.8 0 00-2.543-2.49 2.013 2.013 0 00-1.969 2.404z"
              />
              <path
                  data-name="Path 1504"
                  d="M621.73 162.106a2.741 2.741 0 002.522 2.485 2.035 2.035 0 002-2.41 2.757 2.757 0 00-2.522-2.485 2.043 2.043 0 00-2 2.41z"
              />
              <path
                  data-name="Path 1505"
                  d="M614.536 161.999a2.7 2.7 0 002.5 2.48 2.065 2.065 0 002.024-2.415 2.713 2.713 0 00-2.5-2.48 2.075 2.075 0 00-2.024 2.415z"
              />
              <path
                  data-name="Path 1506"
                  d="M607.337 161.909a2.656 2.656 0 002.473 2.475 2.093 2.093 0 002.049-2.42 2.672 2.672 0 00-2.473-2.475 2.107 2.107 0 00-2.049 2.42z"
              />
              <path
                  data-name="Path 1507"
                  d="M600.133 161.838a2.615 2.615 0 002.45 2.468 2.127 2.127 0 002.076-2.426 2.628 2.628 0 00-2.448-2.468 2.14 2.14 0 00-2.078 2.426z"
              />
              <path
                  data-name="Path 1508"
                  d="M592.925 161.781a2.572 2.572 0 002.425 2.463 2.16 2.16 0 002.1-2.431 2.588 2.588 0 00-2.425-2.463 2.171 2.171 0 00-2.1 2.431z"
              />
              <path
                  data-name="Path 1509"
                  d="M585.714 161.746a2.532 2.532 0 002.4 2.458 2.193 2.193 0 002.129-2.438 2.549 2.549 0 00-2.4-2.456 2.2 2.2 0 00-2.129 2.436z"
              />
              <path
                  data-name="Path 1510"
                  d="M578.499 161.726a2.5 2.5 0 002.376 2.453 2.227 2.227 0 002.156-2.443 2.508 2.508 0 00-2.375-2.451 2.239 2.239 0 00-2.157 2.441z"
              />
              <path
                  data-name="Path 1511"
                  d="M571.284 161.724a2.452 2.452 0 002.35 2.446 2.26 2.26 0 002.182-2.448 2.467 2.467 0 00-2.35-2.445 2.272 2.272 0 00-2.182 2.447z"
              />
              <path
                  data-name="Path 1512"
                  d="M564.064 161.739a2.415 2.415 0 002.324 2.441 2.3 2.3 0 002.209-2.453 2.43 2.43 0 00-2.326-2.44 2.306 2.306 0 00-2.207 2.452z"
              />
              <path
                  data-name="Path 1513"
                  d="M556.843 161.773a2.38 2.38 0 002.3 2.436 2.334 2.334 0 002.236-2.46 2.393 2.393 0 00-2.3-2.435 2.345 2.345 0 00-2.236 2.459z"
              />
              <path
                  data-name="Path 1514"
                  d="M549.621 161.823a2.344 2.344 0 002.274 2.431 2.454 2.454 0 10-2.274-2.431z"
              />
              <path
                  data-name="Path 1515"
                  d="M542.399 161.892a2.307 2.307 0 002.249 2.425 2.407 2.407 0 002.288-2.47 2.322 2.322 0 00-2.249-2.425 2.421 2.421 0 00-2.288 2.47z"
              />
              <path
                  data-name="Path 1516"
                  d="M535.176 161.977a2.272 2.272 0 002.224 2.42 2.445 2.445 0 002.313-2.477 2.285 2.285 0 00-2.224-2.418 2.459 2.459 0 00-2.313 2.475z"
              />
              <path
                  data-name="Path 1517"
                  d="M527.954 162.08a2.238 2.238 0 002.2 2.415 2.484 2.484 0 002.338-2.482 2.25 2.25 0 00-2.2-2.413 2.5 2.5 0 00-2.338 2.48z"
              />
              <path
                  data-name="Path 1518"
                  d="M520.733 162.199a2.2 2.2 0 002.172 2.41 2.522 2.522 0 002.365-2.483 2.215 2.215 0 00-2.172-2.408 2.536 2.536 0 00-2.365 2.481z"
              />
              <path
                  data-name="Path 1519"
                  d="M513.513 162.338a2.17 2.17 0 002.147 2.4 2.562 2.562 0 002.388-2.492 2.181 2.181 0 00-2.146-2.4 2.578 2.578 0 00-2.389 2.492z"
              />
              <path
                  data-name="Path 1520"
                  d="M506.295 162.494a2.138 2.138 0 002.121 2.4 2.6 2.6 0 002.413-2.5 2.147 2.147 0 00-2.121-2.4 2.617 2.617 0 00-2.413 2.5z"
              />
              <path
                  data-name="Path 1521"
                  d="M499.08 162.666a2.106 2.106 0 002.094 2.393 2.644 2.644 0 002.438-2.5 2.115 2.115 0 00-2.094-2.391 2.661 2.661 0 00-2.438 2.498z"
              />
              <path
                  data-name="Path 1522"
                  d="M491.867 162.856a2.074 2.074 0 002.067 2.386 2.686 2.686 0 002.463-2.508 2.084 2.084 0 00-2.067-2.386 2.7 2.7 0 00-2.463 2.508z"
              />
              <path
                  data-name="Path 1523"
                  d="M272.43 176.987c-.521 1.354.025 2.355 1.222 2.236a4.287 4.287 0 003.095-2.67c.513-1.355-.035-2.356-1.222-2.236a4.313 4.313 0 00-3.095 2.67z"
              />
              <path
                  data-name="Path 1524"
                  d="M265.574 177.691c-.533 1.355 0 2.353 1.195 2.231a4.336 4.336 0 003.108-2.675c.525-1.354-.008-2.353-1.193-2.231a4.371 4.371 0 00-3.11 2.675z"
              />
              <path
                  data-name="Path 1525"
                  d="M258.738 178.409c-.546 1.354-.022 2.351 1.166 2.226a4.395 4.395 0 003.125-2.679c.536-1.354.015-2.351-1.166-2.226a4.429 4.429 0 00-3.125 2.679z"
              />
              <path
                  data-name="Path 1526"
                  d="M251.919 179.138c-.556 1.355-.045 2.349 1.141 2.223a4.461 4.461 0 003.138-2.682c.548-1.355.038-2.35-1.14-2.223a4.494 4.494 0 00-3.139 2.682z"
              />
              <path
                  data-name="Path 1527"
                  d="M245.123 179.885c-.568 1.354-.068 2.348 1.113 2.217a4.515 4.515 0 003.153-2.687c.56-1.354.062-2.346-1.113-2.217a4.553 4.553 0 00-3.153 2.687z"
              />
              <path
                  data-name="Path 1528"
                  d="M238.347 180.645c-.578 1.354-.092 2.346 1.086 2.212a4.572 4.572 0 003.167-2.69c.572-1.355.085-2.346-1.085-2.214a4.616 4.616 0 00-3.168 2.692z"
              />
              <path
                  data-name="Path 1529"
                  d="M231.594 181.413c-.59 1.355-.117 2.345 1.059 2.209a4.636 4.636 0 003.18-2.7c.582-1.354.107-2.343-1.059-2.209a4.673 4.673 0 00-3.18 2.7z"
              />
              <path
                  data-name="Path 1530"
                  d="M224.862 182.197c-.6 1.354-.139 2.343 1.033 2.206a4.7 4.7 0 003.193-2.7c.592-1.354.13-2.343-1.033-2.206a4.737 4.737 0 00-3.193 2.7z"
              />
              <path
                  data-name="Path 1531"
                  d="M218.151 182.994c-.612 1.355-.162 2.341 1.006 2.2a4.763 4.763 0 003.205-2.7c.6-1.355.154-2.34-1-2.2a4.79 4.79 0 00-3.211 2.7z"
              />
              <path
                  data-name="Path 1532"
                  d="M211.461 183.808c-.622 1.354-.184 2.338.979 2.2a4.829 4.829 0 003.218-2.707c.613-1.355.177-2.338-.979-2.2a4.856 4.856 0 00-3.218 2.707z"
              />
              <path
                  data-name="Path 1533"
                  d="M204.802 184.627c-.633 1.354-.207 2.336.953 2.192a4.882 4.882 0 003.228-2.71c.627-1.355.2-2.334-.951-2.192a4.909 4.909 0 00-3.23 2.71z"
              />
              <path
                  data-name="Path 1534"
                  d="M198.162 185.461c-.643 1.354-.227 2.334.927 2.189a4.947 4.947 0 003.24-2.714c.635-1.355.222-2.334-.926-2.189a4.978 4.978 0 00-3.241 2.714z"
              />
              <path
                  data-name="Path 1535"
                  d="M191.55 186.308c-.653 1.355-.251 2.333.9 2.186a5 5 0 003.252-2.717c.647-1.355.244-2.333-.9-2.186a5.043 5.043 0 00-3.252 2.717z"
              />
              <path
                  data-name="Path 1536"
                  d="M184.957 187.165c-.663 1.354-.272 2.329.874 2.181a5.071 5.071 0 003.262-2.721c.657-1.354.266-2.331-.872-2.181a5.106 5.106 0 00-3.264 2.721z"
              />
              <path
                  data-name="Path 1537"
                  d="M178.393 188.034c-.673 1.354-.294 2.328.847 2.177a5.132 5.132 0 003.272-2.724c.667-1.354.287-2.328-.846-2.177a5.171 5.171 0 00-3.273 2.724z"
              />
              <path
                  data-name="Path 1538"
                  d="M171.854 188.917c-.683 1.352-.316 2.324.822 2.172a5.194 5.194 0 003.282-2.727c.675-1.354.309-2.328-.821-2.172a5.223 5.223 0 00-3.283 2.727z"
              />
              <path
                  data-name="Path 1539"
                  d="M165.341 189.809c-.693 1.354-.336 2.324.8 2.169a5.253 5.253 0 003.292-2.731c.685-1.352.329-2.323-.8-2.167a5.287 5.287 0 00-3.292 2.729z"
              />
              <path
                  data-name="Path 1540"
                  d="M158.856 190.711c-.7 1.352-.358 2.321.77 2.164a5.317 5.317 0 003.3-2.732c.7-1.354.351-2.323-.77-2.166a5.353 5.353 0 00-3.3 2.734z"
              />
              <path
                  data-name="Path 1541"
                  d="M152.398 191.625c-.712 1.352-.379 2.318.744 2.159a5.37 5.37 0 003.309-2.736c.705-1.352.371-2.318-.744-2.161a5.413 5.413 0 00-3.309 2.738z"
              />
              <path
                  data-name="Path 1542"
                  d="M145.968 192.549c-.72 1.35-.4 2.316.719 2.156a5.442 5.442 0 003.317-2.737c.714-1.354.391-2.318-.719-2.157a5.475 5.475 0 00-3.317 2.738z"
              />
              <path
                  data-name="Path 1543"
                  d="M997.161 185.164a5.9 5.9 0 003.36 2.727c1.056.157 1.26-.809.458-2.157a5.93 5.93 0 00-3.361-2.726c-1.05-.156-1.258.808-.457 2.156z"
              />
              <path
                  data-name="Path 1544"
                  d="M991.053 184.266a5.826 5.826 0 003.357 2.724c1.063.157 1.275-.81.48-2.159a5.87 5.87 0 00-3.356-2.724c-1.051-.157-1.268.81-.481 2.159z"
              />
              <path
                  data-name="Path 1545"
                  d="M984.925 183.377a5.78 5.78 0 003.352 2.722c1.066.154 1.293-.815.5-2.164a5.8 5.8 0 00-3.351-2.722c-1.057-.154-1.284.813-.501 2.164z"
              />
              <path
                  data-name="Path 1546"
                  d="M978.763 182.498a5.711 5.711 0 003.345 2.719c1.073.152 1.31-.819.528-2.167a5.746 5.746 0 00-3.346-2.719c-1.066-.156-1.305.815-.527 2.167z"
              />
              <path
                  data-name="Path 1547"
                  d="M972.569 181.625a5.654 5.654 0 003.34 2.717c1.079.15 1.325-.822.553-2.172a5.7 5.7 0 00-3.34-2.715c-1.073-.153-1.32.82-.553 2.17z"
              />
              <path
                  data-name="Path 1548"
                  d="M966.347 180.765a5.589 5.589 0 003.334 2.714c1.083.149 1.342-.824.577-2.174a5.63 5.63 0 00-3.334-2.715c-1.077-.15-1.335.825-.577 2.175z"
              />
              <path
                  data-name="Path 1549"
                  d="M960.097 179.914a5.54 5.54 0 003.329 2.712c1.088.147 1.357-.829.6-2.179a5.574 5.574 0 00-3.327-2.712c-1.082-.147-1.353.829-.602 2.179z"
              />
              <path
                  data-name="Path 1550"
                  d="M953.818 179.074a5.474 5.474 0 003.32 2.709c1.094.145 1.374-.832.627-2.182a5.5 5.5 0 00-3.32-2.709c-1.088-.146-1.367.827-.627 2.182z"
              />
              <path
                  data-name="Path 1551"
                  d="M947.512 178.244a5.412 5.412 0 003.314 2.706c1.1.144 1.389-.836.652-2.186a5.435 5.435 0 00-3.314-2.705c-1.092-.144-1.384.834-.652 2.185z"
              />
              <path
                  data-name="Path 1552"
                  d="M941.179 177.426a5.344 5.344 0 003.3 2.7c1.1.142 1.407-.837.677-2.189a5.368 5.368 0 00-3.3-2.7c-1.099-.142-1.399.837-.677 2.189z"
              />
              <path
                  data-name="Path 1553"
                  d="M934.819 176.618a5.277 5.277 0 003.3 2.7c1.108.14 1.422-.842.7-2.194a5.324 5.324 0 00-3.3-2.7c-1.1-.14-1.412.843-.7 2.194z"
              />
              <path
                  data-name="Path 1554"
                  d="M902.627 172.755a4.987 4.987 0 003.245 2.682c1.133.129 1.5-.862.832-2.214a5.007 5.007 0 00-3.247-2.68c-1.123-.131-1.492.86-.83 2.212z"
              />
              <path
                  data-name="Path 1555"
                  d="M896.112 172.019a4.923 4.923 0 003.235 2.679c1.135.127 1.519-.866.859-2.217a4.952 4.952 0 00-3.235-2.679c-1.124-.129-1.51.865-.859 2.217z"
              />
              <path
                  data-name="Path 1556"
                  d="M889.577 171.295a4.853 4.853 0 003.223 2.674c1.136.125 1.534-.869.884-2.221a4.888 4.888 0 00-3.224-2.674c-1.128-.125-1.524.869-.883 2.221z"
              />
              <path
                  data-name="Path 1557"
                  d="M883.017 170.583a4.794 4.794 0 003.212 2.67c1.143.124 1.549-.874.911-2.226a4.818 4.818 0 00-3.21-2.669c-1.135-.122-1.543.874-.913 2.225z"
              />
              <path
                  data-name="Path 1558"
                  d="M876.434 169.884a4.725 4.725 0 003.2 2.665c1.146.122 1.568-.877.939-2.229a4.773 4.773 0 00-3.2-2.665c-1.138-.12-1.559.877-.939 2.229z"
              />
              <path
                  data-name="Path 1559"
                  d="M869.828 169.199a4.664 4.664 0 003.187 2.662c1.15.119 1.583-.882.964-2.234a4.7 4.7 0 00-3.185-2.66c-1.144-.119-1.576.88-.966 2.232z"
              />
              <path
                  data-name="Path 1560"
                  d="M863.202 168.526a4.6 4.6 0 003.172 2.659c1.155.115 1.6-.886.993-2.238a4.638 4.638 0 00-3.173-2.657c-1.147-.116-1.592.884-.992 2.236z"
              />
              <path
                  data-name="Path 1561"
                  d="M856.553 167.867a4.545 4.545 0 003.158 2.654c1.16.114 1.616-.891 1.019-2.241a4.576 4.576 0 00-3.16-2.654c-1.149-.113-1.605.89-1.017 2.241z"
              />
              <path
                  data-name="Path 1562"
                  d="M849.883 167.223a4.478 4.478 0 003.145 2.649c1.163.112 1.629-.894 1.048-2.244a4.512 4.512 0 00-3.147-2.649c-1.153-.113-1.621.893-1.046 2.244z"
              />
              <path
                  data-name="Path 1563"
                  d="M843.193 166.59a4.421 4.421 0 003.13 2.645c1.166.109 1.646-.9 1.074-2.251a4.455 4.455 0 00-3.132-2.644c-1.156-.107-1.637.899-1.072 2.25z"
              />
              <path
                  data-name="Path 1564"
                  d="M836.479 165.974a4.359 4.359 0 003.115 2.639c1.168.109 1.663-.9 1.1-2.253a4.391 4.391 0 00-3.115-2.64c-1.157-.105-1.649.902-1.1 2.254z"
              />
              <path
                  data-name="Path 1565"
                  d="M829.751 165.369a4.305 4.305 0 003.1 2.637c1.173.1 1.679-.907 1.13-2.259a4.328 4.328 0 00-3.1-2.634c-1.167-.104-1.67.906-1.13 2.256z"
              />
              <path
                  data-name="Path 1566"
                  d="M823.002 164.781a4.242 4.242 0 003.085 2.63c1.176.1 1.694-.909 1.156-2.263a4.268 4.268 0 00-3.085-2.629c-1.166-.101-1.684.912-1.156 2.262z"
              />
              <path
                  data-name="Path 1567"
                  d="M816.233 164.207a4.18 4.18 0 003.068 2.625c1.178.1 1.71-.916 1.185-2.266a4.215 4.215 0 00-3.07-2.625c-1.169-.099-1.699.915-1.183 2.266z"
              />
              <path
                  data-name="Path 1568"
                  d="M809.446 163.647a4.121 4.121 0 003.053 2.62c1.181.1 1.725-.921 1.211-2.271a4.154 4.154 0 00-3.053-2.62c-1.172-.096-1.714.92-1.211 2.271z"
              />
              <path
                  data-name="Path 1569"
                  d="M802.641 163.1a4.069 4.069 0 003.036 2.617c1.185.094 1.74-.926 1.24-2.276a4.091 4.091 0 00-3.036-2.614c-1.173-.095-1.731.923-1.24 2.273z"
              />
              <path
                  data-name="Path 1570"
                  d="M795.818 162.571a4 4 0 003.02 2.61c1.188.094 1.755-.929 1.268-2.279a4.034 4.034 0 00-3.02-2.61c-1.179-.091-1.747.928-1.268 2.279z"
              />
              <path
                  data-name="Path 1571"
                  d="M788.979 162.054a3.953 3.953 0 003 2.607c1.19.089 1.77-.934 1.3-2.284a3.976 3.976 0 00-3-2.605c-1.185-.089-1.767.932-1.3 2.282z"
              />
              <path
                  data-name="Path 1572"
                  d="M782.123 161.554a3.884 3.884 0 002.983 2.6c1.195.087 1.788-.937 1.325-2.288a3.919 3.919 0 00-2.985-2.6c-1.184-.087-1.777.938-1.323 2.288z"
              />
              <path
                  data-name="Path 1573"
                  d="M775.251 161.068a3.836 3.836 0 002.966 2.6c1.2.084 1.8-.944 1.352-2.293a3.858 3.858 0 00-2.966-2.6c-1.188-.082-1.793.944-1.352 2.293z"
              />
              <path
                  data-name="Path 1574"
                  d="M768.361 160.599a3.777 3.777 0 002.946 2.592c1.2.08 1.818-.949 1.38-2.3a3.8 3.8 0 00-2.948-2.59c-1.187-.078-1.802.949-1.378 2.298z"
              />
              <path
                  data-name="Path 1575"
                  d="M761.462 160.144a3.72 3.72 0 002.929 2.587c1.2.077 1.833-.952 1.407-2.3a3.747 3.747 0 00-2.929-2.585c-1.192-.08-1.824.949-1.407 2.298z"
              />
              <path
                  data-name="Path 1576"
                  d="M754.543 159.706a3.666 3.666 0 002.911 2.58c1.2.075 1.848-.958 1.435-2.306a3.689 3.689 0 00-2.911-2.58c-1.194-.076-1.836.957-1.435 2.306z"
              />
              <path
                  data-name="Path 1577"
                  d="M747.611 159.283a3.613 3.613 0 002.891 2.575c1.208.074 1.863-.963 1.464-2.311a3.637 3.637 0 00-2.891-2.575c-1.196-.071-1.853.963-1.464 2.311z"
              />
              <path
                  data-name="Path 1578"
                  d="M740.666 158.876a3.553 3.553 0 002.871 2.57c1.21.07 1.878-.968 1.492-2.316a3.582 3.582 0 00-2.871-2.568c-1.199-.071-1.869.965-1.492 2.314z"
              />
              <path
                  data-name="Path 1579"
                  d="M733.706 158.485a3.506 3.506 0 002.853 2.565c1.212.067 1.893-.973 1.521-2.321a3.527 3.527 0 00-2.853-2.563c-1.203-.066-1.883.97-1.521 2.319z"
              />
              <path
                  data-name="Path 1580"
                  d="M726.734 158.111a3.449 3.449 0 002.832 2.558c1.213.065 1.907-.976 1.549-2.324a3.475 3.475 0 00-2.832-2.558c-1.204-.064-1.898.975-1.549 2.324z"
              />
              <path
                  data-name="Path 1581"
                  d="M719.751 157.751a3.4 3.4 0 002.808 2.55c1.217.064 1.923-.983 1.577-2.329a3.423 3.423 0 00-2.812-2.553c-1.206-.06-1.909.982-1.573 2.332z"
              />
              <path
                  data-name="Path 1582"
                  d="M712.752 157.408a3.344 3.344 0 002.791 2.548c1.218.059 1.937-.986 1.606-2.335a3.367 3.367 0 00-2.791-2.547c-1.206-.058-1.927.988-1.606 2.334z"
              />
              <path
                  data-name="Path 1583"
                  d="M705.747 157.083a3.294 3.294 0 002.769 2.543c1.222.055 1.952-.993 1.633-2.341a3.311 3.311 0 00-2.769-2.54c-1.21-.057-1.939.985-1.633 2.338z"
              />
              <path
                  data-name="Path 1584"
                  d="M698.725 156.773a3.243 3.243 0 002.747 2.537c1.221.053 1.967-1 1.661-2.345a3.264 3.264 0 00-2.747-2.537c-1.209-.053-1.953.998-1.661 2.345z"
              />
              <path
                  data-name="Path 1585"
                  d="M691.699 156.484a3.2 3.2 0 002.727 2.533c1.225.05 1.982-1 1.689-2.351a3.215 3.215 0 00-2.727-2.53c-1.213-.052-1.97.997-1.689 2.348z"
              />
              <path
                  data-name="Path 1586"
                  d="M677.611 155.944a3.092 3.092 0 002.682 2.52 1.794 1.794 0 001.746-2.36 3.116 3.116 0 00-2.684-2.52 1.8 1.8 0 00-1.744 2.36z"
              />
              <path
                  data-name="Path 1587"
                  d="M670.553 155.7a3.047 3.047 0 002.66 2.517 1.819 1.819 0 001.773-2.365 3.063 3.063 0 00-2.66-2.515 1.827 1.827 0 00-1.773 2.363z"
              />
              <path
                  data-name="Path 1588"
                  d="M663.483 155.472a3 3 0 002.639 2.51 1.845 1.845 0 001.8-2.37 3.016 3.016 0 00-2.639-2.508 1.853 1.853 0 00-1.8 2.368z"
              />
              <path
                  data-name="Path 1589"
                  d="M656.412 155.266a2.949 2.949 0 002.614 2.5 1.873 1.873 0 001.83-2.376 2.968 2.968 0 00-2.615-2.5 1.879 1.879 0 00-1.829 2.376z"
              />
              <path
                  data-name="Path 1590"
                  d="M649.329 155.074a2.908 2.908 0 002.592 2.5 1.9 1.9 0 001.858-2.381 2.922 2.922 0 00-2.594-2.5 1.906 1.906 0 00-1.856 2.381z"
              />
              <path
                  data-name="Path 1591"
                  d="M642.239 154.9a2.861 2.861 0 002.57 2.493 1.931 1.931 0 001.885-2.386 2.879 2.879 0 00-2.57-2.492 1.938 1.938 0 00-1.885 2.385z"
              />
              <path
                  data-name="Path 1592"
                  d="M635.143 154.741a2.813 2.813 0 002.545 2.488 1.956 1.956 0 001.912-2.391 2.832 2.832 0 00-2.545-2.487 1.965 1.965 0 00-1.912 2.39z"
              />
              <path
                  data-name="Path 1593"
                  d="M628.039 154.601a2.769 2.769 0 002.522 2.482 1.985 1.985 0 001.94-2.395 2.788 2.788 0 00-2.522-2.477 2 2 0 00-1.94 2.39z"
              />
              <path
                  data-name="Path 1594"
                  d="M620.931 154.477a2.726 2.726 0 002.5 2.477 2.016 2.016 0 001.967-2.4 2.744 2.744 0 00-2.5-2.475 2.028 2.028 0 00-1.967 2.398z"
              />
              <path
                  data-name="Path 1595"
                  d="M613.817 154.371a2.682 2.682 0 002.473 2.47 2.046 2.046 0 002-2.406 2.7 2.7 0 00-2.473-2.47 2.059 2.059 0 00-2 2.406z"
              />
              <path
                  data-name="Path 1596"
                  d="M606.698 154.277a2.64 2.64 0 002.45 2.467 2.078 2.078 0 002.022-2.411 2.658 2.658 0 00-2.45-2.465 2.087 2.087 0 00-2.022 2.409z"
              />
              <path
                  data-name="Path 1597"
                  d="M599.574 154.211a2.6 2.6 0 002.425 2.46 2.11 2.11 0 002.049-2.418 2.611 2.611 0 00-2.425-2.458 2.121 2.121 0 00-2.049 2.416z"
              />
              <path
                  data-name="Path 1598"
                  d="M592.447 154.156a2.559 2.559 0 002.4 2.455 2.145 2.145 0 002.077-2.423 2.575 2.575 0 00-2.4-2.453 2.155 2.155 0 00-2.077 2.421z"
              />
              <path
                  data-name="Path 1599"
                  d="M585.315 154.119a2.518 2.518 0 002.376 2.45 2.178 2.178 0 002.1-2.428 2.534 2.534 0 00-2.376-2.448 2.19 2.19 0 00-2.1 2.426z"
              />
              <path
                  data-name="Path 1600"
                  d="M578.181 154.101a2.479 2.479 0 002.351 2.443 2.212 2.212 0 002.131-2.433 2.493 2.493 0 00-2.351-2.443 2.223 2.223 0 00-2.131 2.433z"
              />
              <path
                  data-name="Path 1601"
                  d="M571.046 154.097a2.438 2.438 0 002.324 2.438 2.245 2.245 0 002.157-2.438 2.452 2.452 0 00-2.326-2.436 2.256 2.256 0 00-2.155 2.436z"
              />
              <path
                  data-name="Path 1602"
                  d="M563.909 154.114a2.4 2.4 0 002.3 2.431 2.282 2.282 0 002.184-2.443 2.413 2.413 0 00-2.3-2.431 2.293 2.293 0 00-2.184 2.443z"
              />
              <path
                  data-name="Path 1603"
                  d="M556.766 154.146a2.364 2.364 0 002.274 2.428 2.319 2.319 0 002.211-2.45 2.378 2.378 0 00-2.274-2.426 2.331 2.331 0 00-2.211 2.448z"
              />
              <path
                  data-name="Path 1604"
                  d="M549.626 154.196a2.324 2.324 0 002.249 2.421 2.446 2.446 0 10-2.249-2.421z"
              />
              <path
                  data-name="Path 1605"
                  d="M542.484 154.265a2.29 2.29 0 002.223 2.416 2.39 2.39 0 002.263-2.462 2.3 2.3 0 00-2.223-2.415 2.4 2.4 0 00-2.263 2.461z"
              />
              <path
                  data-name="Path 1606"
                  d="M535.337 154.35a2.256 2.256 0 002.2 2.411 2.429 2.429 0 002.288-2.467 2.267 2.267 0 00-2.2-2.41 2.442 2.442 0 00-2.288 2.466z"
              />
              <path
                  data-name="Path 1607"
                  d="M528.2 154.454a2.22 2.22 0 002.172 2.4 2.468 2.468 0 002.313-2.471 2.231 2.231 0 00-2.171-2.4 2.484 2.484 0 00-2.314 2.471z"
              />
              <path
                  data-name="Path 1608"
                  d="M521.059 154.567a2.186 2.186 0 002.146 2.4 2.506 2.506 0 002.34-2.477 2.2 2.2 0 00-2.146-2.4 2.522 2.522 0 00-2.34 2.477z"
              />
              <path
                  data-name="Path 1609"
                  d="M513.921 154.711a2.154 2.154 0 002.119 2.395 2.549 2.549 0 002.365-2.483 2.163 2.163 0 00-2.119-2.393 2.563 2.563 0 00-2.365 2.481z"
              />
              <path
                  data-name="Path 1610"
                  d="M506.781 154.865a2.121 2.121 0 002.094 2.39 2.589 2.589 0 002.39-2.488 2.13 2.13 0 00-2.092-2.388 2.607 2.607 0 00-2.392 2.486z"
              />
              <path
                  data-name="Path 1611"
                  d="M499.646 155.037a2.089 2.089 0 002.067 2.385 2.632 2.632 0 002.415-2.495 2.1 2.1 0 00-2.065-2.381 2.645 2.645 0 00-2.417 2.491z"
              />
              <path
                  data-name="Path 1612"
                  d="M492.514 155.228a2.056 2.056 0 002.039 2.378 2.673 2.673 0 002.441-2.5 2.066 2.066 0 00-2.04-2.376 2.688 2.688 0 00-2.44 2.498z"
              />
              <path
                  data-name="Path 1613"
                  d="M282.202 168.651c-.515 1.35.032 2.351 1.22 2.233a4.226 4.226 0 003.065-2.659c.506-1.35-.038-2.35-1.218-2.233a4.26 4.26 0 00-3.067 2.659z"
              />
              <path
                  data-name="Path 1614"
                  d="M275.389 169.34c-.526 1.352.007 2.35 1.191 2.229a4.292 4.292 0 003.081-2.664c.518-1.352-.015-2.348-1.19-2.229a4.324 4.324 0 00-3.082 2.664z"
              />
              <path
                  data-name="Path 1615"
                  d="M268.6 170.043c-.538 1.35-.018 2.346 1.165 2.224a4.347 4.347 0 003.1-2.669c.53-1.35.008-2.346-1.163-2.224a4.383 4.383 0 00-3.102 2.669z"
              />
              <path
                  data-name="Path 1616"
                  d="M261.828 170.76c-.55 1.35-.04 2.346 1.138 2.221a4.409 4.409 0 003.113-2.674c.541-1.35.033-2.344-1.136-2.219a4.446 4.446 0 00-3.115 2.672z"
              />
              <path
                  data-name="Path 1617"
                  d="M255.077 171.49c-.561 1.352-.064 2.345 1.111 2.217a4.468 4.468 0 003.127-2.679c.553-1.35.057-2.343-1.11-2.216a4.5 4.5 0 00-3.128 2.678z"
              />
              <path
                  data-name="Path 1618"
                  d="M248.344 172.236c-.573 1.352-.087 2.341 1.085 2.212a4.538 4.538 0 003.142-2.682c.565-1.352.08-2.341-1.083-2.212a4.568 4.568 0 00-3.144 2.682z"
              />
              <path
                  data-name="Path 1619"
                  d="M241.633 172.993c-.585 1.352-.112 2.341 1.056 2.209a4.6 4.6 0 003.157-2.687c.575-1.35.1-2.338-1.056-2.207a4.627 4.627 0 00-3.157 2.685z"
              />
              <path
                  data-name="Path 1620"
                  d="M234.939 173.764c-.595 1.35-.134 2.338 1.031 2.2a4.651 4.651 0 003.17-2.69c.587-1.352.125-2.338-1.029-2.2a4.692 4.692 0 00-3.172 2.69z"
              />
              <path
                  data-name="Path 1621"
                  d="M228.269 174.549c-.6 1.352-.155 2.338 1 2.2a4.715 4.715 0 003.183-2.694c.6-1.352.149-2.338-1-2.2a4.76 4.76 0 00-3.183 2.694z"
              />
              <path
                  data-name="Path 1622"
                  d="M221.621 175.347c-.617 1.35-.179 2.335.978 2.2a4.779 4.779 0 003.2-2.7c.61-1.352.172-2.333-.976-2.2a4.814 4.814 0 00-3.202 2.7z"
              />
              <path
                  data-name="Path 1623"
                  d="M214.994 176.156c-.627 1.352-.2 2.333.951 2.192a4.831 4.831 0 003.208-2.7c.62-1.352.2-2.333-.949-2.191a4.878 4.878 0 00-3.21 2.699z"
              />
              <path
                  data-name="Path 1624"
                  d="M208.391 176.98c-.638 1.35-.224 2.331.924 2.187a4.907 4.907 0 003.22-2.706c.63-1.352.217-2.331-.924-2.189a4.938 4.938 0 00-3.22 2.708z"
              />
              <path
                  data-name="Path 1625"
                  d="M201.809 177.814c-.648 1.352-.246 2.329.9 2.184a4.965 4.965 0 003.232-2.709c.642-1.352.239-2.329-.9-2.184a5.005 5.005 0 00-3.232 2.709z"
              />
              <path
                  data-name="Path 1626"
                  d="M195.254 178.661c-.658 1.352-.269 2.328.872 2.181a5.034 5.034 0 003.244-2.714c.65-1.352.261-2.326-.872-2.179a5.052 5.052 0 00-3.244 2.712z"
              />
              <path
                  data-name="Path 1627"
                  d="M188.72 179.52c-.668 1.35-.291 2.326.847 2.176a5.09 5.09 0 003.254-2.717c.662-1.35.282-2.324-.846-2.176a5.13 5.13 0 00-3.255 2.717z"
              />
              <path
                  data-name="Path 1628"
                  d="M182.211 180.391c-.678 1.352-.311 2.324.82 2.172a5.157 5.157 0 003.265-2.72c.672-1.352.3-2.323-.82-2.172a5.193 5.193 0 00-3.265 2.72z"
              />
              <path
                  data-name="Path 1629"
                  d="M175.727 181.273c-.687 1.35-.331 2.321.8 2.169a5.213 5.213 0 003.274-2.724c.682-1.35.326-2.321-.794-2.169a5.261 5.261 0 00-3.28 2.724z"
              />
              <path
                  data-name="Path 1630"
                  d="M169.27 182.167c-.7 1.35-.354 2.319.769 2.164a5.277 5.277 0 003.284-2.727c.692-1.352.348-2.319-.769-2.164a5.314 5.314 0 00-3.284 2.727z"
              />
              <path
                  data-name="Path 1631"
                  d="M162.838 183.071c-.707 1.35-.374 2.318.744 2.161a5.337 5.337 0 003.293-2.729c.7-1.35.366-2.316-.745-2.159a5.375 5.375 0 00-3.292 2.727z"
              />
              <path
                  data-name="Path 1632"
                  d="M156.433 183.986c-.717 1.35-.394 2.316.719 2.156a5.4 5.4 0 003.3-2.732c.709-1.35.388-2.316-.72-2.156a5.435 5.435 0 00-3.299 2.732z"
              />
              <path
                  data-name="Path 1633"
                  d="M150.056 184.913c-.725 1.35-.414 2.314.694 2.152a5.456 5.456 0 003.309-2.736c.72-1.35.409-2.314-.693-2.152a5.5 5.5 0 00-3.31 2.736z"
              />
              <path
                  data-name="Path 1634"
                  d="M143.702 185.848c-.735 1.35-.436 2.311.668 2.149a5.529 5.529 0 003.317-2.737c.727-1.35.428-2.311-.67-2.149a5.558 5.558 0 00-3.315 2.737z"
              />
              <path
                  data-name="Path 1635"
                  d="M137.381 186.794c-.744 1.349-.455 2.309.643 2.144a5.574 5.574 0 003.324-2.741c.737-1.349.45-2.309-.643-2.144a5.615 5.615 0 00-3.324 2.741z"
              />
              <path
                  data-name="Path 1636"
                  d="M998.661 178.457a5.99 5.99 0 003.357 2.727c1.044.159 1.232-.8.414-2.151a6.007 6.007 0 00-3.356-2.724c-1.038-.159-1.224.803-.415 2.148z"
              />
              <path
                  data-name="Path 1637"
                  d="M992.632 177.548a5.919 5.919 0 003.354 2.722c1.049.159 1.245-.805.438-2.152a5.96 5.96 0 00-3.354-2.724c-1.043-.157-1.24.805-.438 2.154z"
              />
              <path
                  data-name="Path 1638"
                  d="M986.573 176.645a5.863 5.863 0 003.349 2.722c1.054.155 1.26-.812.461-2.157a5.894 5.894 0 00-3.349-2.72c-1.048-.156-1.255.808-.461 2.155z"
              />
              <path
                  data-name="Path 1639"
                  d="M980.485 175.754a5.793 5.793 0 003.344 2.719c1.061.155 1.275-.812.483-2.159a5.838 5.838 0 00-3.342-2.719c-1.053-.156-1.272.812-.485 2.159z"
              />
              <path
                  data-name="Path 1640"
                  d="M968.221 173.999a5.671 5.671 0 003.334 2.714c1.069.152 1.307-.819.53-2.166a5.708 5.708 0 00-3.332-2.714c-1.06-.146-1.302.819-.532 2.166z"
              />
              <path
                  data-name="Path 1641"
                  d="M955.847 172.286a5.551 5.551 0 003.319 2.707c1.08.147 1.338-.826.578-2.174a5.587 5.587 0 00-3.317-2.707c-1.074-.147-1.333.825-.58 2.174z"
              />
              <path
                  data-name="Path 1642"
                  d="M949.617 171.445a5.5 5.5 0 003.312 2.7c1.086.147 1.355-.831.6-2.177a5.527 5.527 0 00-3.31-2.7c-1.075-.147-1.346.827-.602 2.177z"
              />
              <path
                  data-name="Path 1643"
                  d="M937.08 169.792a5.372 5.372 0 003.3 2.7c1.094.142 1.387-.836.653-2.184a5.409 5.409 0 00-3.3-2.7c-1.086-.138-1.38.838-.653 2.184z"
              />
              <path
                  data-name="Path 1644"
                  d="M924.441 168.19a5.242 5.242 0 003.277 2.692c1.1.137 1.417-.844.7-2.192a5.274 5.274 0 00-3.277-2.69c-1.093-.139-1.407.845-.7 2.19z"
              />
              <path
                  data-name="Path 1645"
                  d="M918.082 167.406a5.181 5.181 0 003.269 2.687c1.106.135 1.434-.847.728-2.2a5.213 5.213 0 00-3.268-2.687c-1.1-.133-1.426.851-.729 2.2z"
              />
              <path
                  data-name="Path 1646"
                  d="M905.294 165.87a5.053 5.053 0 003.247 2.68c1.115.132 1.464-.856.781-2.2a5.092 5.092 0 00-3.247-2.68c-1.11-.133-1.458.853-.781 2.2z"
              />
              <path
                  data-name="Path 1647"
                  d="M898.863 165.122a4.994 4.994 0 003.235 2.677c1.12.13 1.482-.859.805-2.207a5.02 5.02 0 00-3.235-2.675c-1.11-.129-1.471.858-.805 2.205z"
              />
              <path
                  data-name="Path 1648"
                  d="M892.41 164.386a4.929 4.929 0 003.223 2.672c1.125.127 1.5-.864.832-2.211a4.965 4.965 0 00-3.223-2.672c-1.117-.127-1.491.863-.832 2.211z"
              />
              <path
                  data-name="Path 1649"
                  d="M885.933 163.662a4.863 4.863 0 003.212 2.669c1.128.125 1.514-.867.859-2.214a4.9 4.9 0 00-3.212-2.667c-1.122-.131-1.504.869-.859 2.212z"
              />
              <path
                  data-name="Path 1650"
                  d="M879.434 162.952a4.8 4.8 0 003.2 2.664c1.131.122 1.527-.871.884-2.219a4.834 4.834 0 00-3.2-2.662c-1.121-.123-1.521.868-.884 2.217z"
              />
              <path
                  data-name="Path 1651"
                  d="M872.911 162.25a4.738 4.738 0 003.188 2.66c1.135.12 1.544-.876.911-2.223a4.771 4.771 0 00-3.187-2.659c-1.129-.116-1.534.878-.912 2.222z"
              />
              <path
                  data-name="Path 1652"
                  d="M866.369 161.574a4.676 4.676 0 003.173 2.654c1.14.119 1.561-.879.939-2.226a4.709 4.709 0 00-3.175-2.654c-1.129-.123-1.547.873-.937 2.226z"
              />
              <path
                  data-name="Path 1653"
                  d="M859.805 160.902a4.618 4.618 0 003.16 2.65c1.145.115 1.576-.884.966-2.231a4.652 4.652 0 00-3.162-2.65c-1.134-.12-1.566.878-.964 2.231z"
              />
              <path
                  data-name="Path 1654"
                  d="M853.221 160.236a4.554 4.554 0 003.145 2.645c1.148.115 1.593-.886.993-2.234a4.589 4.589 0 00-3.147-2.645c-1.139-.111-1.582.89-.991 2.234z"
              />
              <path
                  data-name="Path 1655"
                  d="M846.616 159.594a4.494 4.494 0 003.132 2.642c1.151.11 1.606-.891 1.019-2.239a4.526 4.526 0 00-3.132-2.64c-1.141-.111-1.596.89-1.019 2.237z"
              />
              <path
                  data-name="Path 1656"
                  d="M839.991 158.964a4.425 4.425 0 003.117 2.637c1.153.109 1.623-.9 1.046-2.243a4.459 4.459 0 00-3.118-2.635c-1.145-.108-1.614.899-1.045 2.241z"
              />
              <path
                  data-name="Path 1657"
                  d="M833.346 158.347a4.374 4.374 0 003.1 2.632c1.156.107 1.638-.9 1.073-2.246a4.4 4.4 0 00-3.1-2.632c-1.147-.105-1.628.899-1.073 2.246z"
              />
              <path
                  data-name="Path 1658"
                  d="M826.683 157.744a4.311 4.311 0 003.087 2.629c1.16.1 1.654-.9 1.1-2.251a4.341 4.341 0 00-3.087-2.627c-1.15-.104-1.643.904-1.1 2.249z"
              />
              <path
                  data-name="Path 1659"
                  d="M820 157.155a4.25 4.25 0 003.071 2.624c1.163.1 1.669-.909 1.128-2.256a4.274 4.274 0 00-3.071-2.62c-1.155-.102-1.662.907-1.128 2.252z"
              />
              <path
                  data-name="Path 1660"
                  d="M813.302 156.582a4.181 4.181 0 003.055 2.617c1.166.1 1.684-.912 1.155-2.258a4.213 4.213 0 00-3.055-2.617c-1.159-.098-1.677.911-1.155 2.258z"
              />
              <path
                  data-name="Path 1661"
                  d="M806.586 156.023a4.126 4.126 0 003.038 2.614c1.17.1 1.7-.917 1.183-2.263a4.154 4.154 0 00-3.038-2.612c-1.164-.097-1.692.915-1.183 2.261z"
              />
              <path
                  data-name="Path 1662"
                  d="M799.849 155.479a4.064 4.064 0 003.02 2.607c1.173.1 1.716-.921 1.211-2.268a4.091 4.091 0 00-3.021-2.605c-1.163-.093-1.706.919-1.21 2.266z"
              />
              <path
                  data-name="Path 1663"
                  d="M793.096 154.95a4.008 4.008 0 003 2.6c1.178.092 1.731-.926 1.24-2.271a4.036 4.036 0 00-3-2.6c-1.168-.09-1.721.926-1.24 2.271z"
              />
              <path
                  data-name="Path 1664"
                  d="M786.328 154.435a3.946 3.946 0 002.986 2.6c1.178.089 1.746-.929 1.267-2.276a3.978 3.978 0 00-2.986-2.6c-1.17-.086-1.732.932-1.267 2.276z"
              />
              <path
                  data-name="Path 1665"
                  d="M779.542 153.936a3.89 3.89 0 002.968 2.592c1.181.087 1.761-.934 1.3-2.279a3.915 3.915 0 00-2.968-2.59c-1.176-.088-1.756.93-1.3 2.277z"
              />
              <path
                  data-name="Path 1666"
                  d="M772.744 153.452a3.826 3.826 0 002.949 2.587c1.183.084 1.778-.939 1.323-2.284a3.864 3.864 0 00-2.951-2.585c-1.174-.085-1.765.938-1.321 2.282z"
              />
              <path
                  data-name="Path 1667"
                  d="M765.929 152.983a3.778 3.778 0 002.931 2.582c1.186.08 1.791-.944 1.35-2.289a3.8 3.8 0 00-2.931-2.58c-1.178-.08-1.783.944-1.35 2.287z"
              />
              <path
                  data-name="Path 1668"
                  d="M759.099 152.53a3.722 3.722 0 002.911 2.577c1.19.077 1.808-.951 1.38-2.294a3.747 3.747 0 00-2.913-2.575c-1.179-.077-1.795.947-1.378 2.292z"
              />
              <path
                  data-name="Path 1669"
                  d="M752.257 152.093a3.659 3.659 0 002.893 2.572c1.192.075 1.821-.954 1.407-2.3a3.689 3.689 0 00-2.894-2.568c-1.182-.076-1.814.952-1.406 2.296z"
              />
              <path
                  data-name="Path 1670"
                  d="M745.398 151.671a3.608 3.608 0 002.873 2.565c1.195.074 1.838-.959 1.435-2.3a3.628 3.628 0 00-2.873-2.563c-1.187-.076-1.827.953-1.435 2.298z"
              />
              <path
                  data-name="Path 1671"
                  d="M738.527 151.265a3.555 3.555 0 002.854 2.56c1.2.07 1.853-.964 1.464-2.308a3.579 3.579 0 00-2.854-2.558c-1.188-.07-1.842.963-1.464 2.306z"
              />
              <path
                  data-name="Path 1672"
                  d="M731.644 150.875a3.5 3.5 0 002.834 2.555c1.2.069 1.867-.968 1.491-2.311a3.52 3.52 0 00-2.832-2.553c-1.191-.068-1.857.965-1.493 2.309z"
              />
              <path
                  data-name="Path 1673"
                  d="M724.749 150.502a3.441 3.441 0 002.812 2.548c1.2.065 1.882-.973 1.519-2.316a3.464 3.464 0 00-2.812-2.547c-1.191-.065-1.871.971-1.519 2.315z"
              />
              <path
                  data-name="Path 1674"
                  d="M717.842 150.145a3.389 3.389 0 002.791 2.543c1.2.062 1.9-.978 1.549-2.321a3.413 3.413 0 00-2.792-2.542c-1.198-.062-1.888.976-1.548 2.32z"
              />
              <path
                  data-name="Path 1675"
                  d="M710.922 149.802a3.336 3.336 0 002.771 2.538c1.2.059 1.912-.983 1.578-2.324a3.359 3.359 0 00-2.772-2.537c-1.196-.059-1.901.981-1.577 2.323z"
              />
              <path
                  data-name="Path 1676"
                  d="M703.991 149.479a3.288 3.288 0 002.749 2.533c1.208.055 1.927-.989 1.6-2.331a3.3 3.3 0 00-2.749-2.532c-1.194-.056-1.912.985-1.6 2.33z"
              />
              <path
                  data-name="Path 1677"
                  d="M697.05 149.169a3.235 3.235 0 002.727 2.528c1.21.053 1.942-.994 1.634-2.336a3.253 3.253 0 00-2.729-2.525c-1.198-.054-1.932.991-1.632 2.333z"
              />
              <path
                  data-name="Path 1678"
                  d="M690.098 148.876a3.18 3.18 0 002.707 2.522c1.21.052 1.953-1 1.661-2.34a3.2 3.2 0 00-2.707-2.52c-1.198-.05-1.945.996-1.661 2.338z"
              />
              <path
                  data-name="Path 1679"
                  d="M683.138 148.602a3.127 3.127 0 002.684 2.515c1.213.048 1.969-1 1.689-2.345a3.156 3.156 0 00-2.685-2.515c-1.202-.046-1.958 1.003-1.688 2.345z"
              />
              <path
                  data-name="Path 1680"
                  d="M676.171 148.342a3.079 3.079 0 002.66 2.51 1.776 1.776 0 001.718-2.35 3.1 3.1 0 00-2.662-2.508 1.783 1.783 0 00-1.716 2.348z"
              />
              <path
                  data-name="Path 1681"
                  d="M669.188 148.1a3.034 3.034 0 002.639 2.5 1.8 1.8 0 001.746-2.355 3.052 3.052 0 00-2.639-2.5 1.809 1.809 0 00-1.746 2.355z"
              />
              <path
                  data-name="Path 1682"
                  d="M662.201 147.874a2.984 2.984 0 002.615 2.5 1.829 1.829 0 001.775-2.36 3.007 3.007 0 00-2.617-2.5 1.835 1.835 0 00-1.773 2.36z"
              />
              <path
                  data-name="Path 1683"
                  d="M655.206 147.667a2.936 2.936 0 002.592 2.493 1.854 1.854 0 001.8-2.365 2.955 2.955 0 00-2.594-2.492 1.862 1.862 0 00-1.798 2.364z"
              />
              <path
                  data-name="Path 1684"
                  d="M648.203 147.475a2.89 2.89 0 002.57 2.488 1.883 1.883 0 001.83-2.37 2.91 2.91 0 00-2.57-2.487 1.891 1.891 0 00-1.83 2.369z"
              />
              <path
                  data-name="Path 1685"
                  d="M641.192 147.301a2.846 2.846 0 002.543 2.487 1.911 1.911 0 001.857-2.376 2.861 2.861 0 00-2.547-2.48 1.918 1.918 0 00-1.853 2.369z"
              />
              <path
                  data-name="Path 1686"
                  d="M634.176 147.144a2.8 2.8 0 002.523 2.477 1.94 1.94 0 001.885-2.38 2.815 2.815 0 00-2.523-2.475 1.947 1.947 0 00-1.885 2.378z"
              />
              <path
                  data-name="Path 1687"
                  d="M627.153 147.004a2.755 2.755 0 002.5 2.471 1.97 1.97 0 001.913-2.386 2.771 2.771 0 00-2.5-2.468 1.976 1.976 0 00-1.913 2.383z"
              />
              <path
                  data-name="Path 1688"
                  d="M613.092 146.775a2.669 2.669 0 002.45 2.461 2.03 2.03 0 001.969-2.4 2.68 2.68 0 00-2.45-2.458 2.039 2.039 0 00-1.969 2.397z"
              />
              <path
                  data-name="Path 1689"
                  d="M606.053 146.687a2.624 2.624 0 002.426 2.455 2.061 2.061 0 002-2.4 2.637 2.637 0 00-2.426-2.451 2.07 2.07 0 00-2 2.396z"
              />
              <path
                  data-name="Path 1690"
                  d="M599.011 146.617a2.579 2.579 0 002.4 2.448 2.092 2.092 0 002.022-2.406 2.6 2.6 0 00-2.4-2.446 2.1 2.1 0 00-2.022 2.404z"
              />
              <path
                  data-name="Path 1691"
                  d="M591.964 146.562a2.542 2.542 0 002.376 2.445 2.127 2.127 0 002.05-2.413 2.557 2.557 0 00-2.376-2.441 2.137 2.137 0 00-2.05 2.409z"
              />
              <path
                  data-name="Path 1692"
                  d="M570.807 146.504a2.423 2.423 0 002.3 2.426 2.228 2.228 0 002.131-2.428 2.434 2.434 0 00-2.3-2.425 2.242 2.242 0 00-2.131 2.427z"
              />
              <path
                  data-name="Path 1693"
                  d="M563.746 146.519a2.385 2.385 0 002.274 2.423 2.265 2.265 0 002.159-2.435 2.4 2.4 0 00-2.274-2.42 2.277 2.277 0 00-2.159 2.432z"
              />
              <path
                  data-name="Path 1694"
                  d="M556.691 146.553a2.347 2.347 0 002.249 2.416 2.3 2.3 0 002.184-2.44 2.356 2.356 0 00-2.249-2.413 2.311 2.311 0 00-2.184 2.437z"
              />
              <path
                  data-name="Path 1695"
                  d="M549.631 146.603a2.311 2.311 0 002.224 2.411 2.437 2.437 0 10-2.224-2.411z"
              />
              <path
                  data-name="Path 1696"
                  d="M542.571 146.67a2.273 2.273 0 002.2 2.406 2.374 2.374 0 002.238-2.45 2.282 2.282 0 00-2.2-2.4 2.386 2.386 0 00-2.238 2.444z"
              />
              <path
                  data-name="Path 1697"
                  d="M535.51 146.755a2.239 2.239 0 002.171 2.4 2.417 2.417 0 002.264-2.456 2.249 2.249 0 00-2.171-2.4 2.427 2.427 0 00-2.264 2.456z"
              />
              <path
                  data-name="Path 1698"
                  d="M528.451 146.859a2.2 2.2 0 002.144 2.395 2.453 2.453 0 002.291-2.461 2.214 2.214 0 00-2.146-2.393 2.466 2.466 0 00-2.289 2.459z"
              />
              <path
                  data-name="Path 1699"
                  d="M521.39 146.977a2.168 2.168 0 002.119 2.39 2.492 2.492 0 002.316-2.467 2.18 2.18 0 00-2.119-2.388 2.508 2.508 0 00-2.316 2.465z"
              />
              <path
                  data-name="Path 1700"
                  d="M514.333 147.114a2.132 2.132 0 002.091 2.385 2.53 2.53 0 002.341-2.471 2.145 2.145 0 00-2.09-2.383 2.546 2.546 0 00-2.342 2.469z"
              />
              <path
                  data-name="Path 1701"
                  d="M507.276 147.27a2.1 2.1 0 002.065 2.378 2.573 2.573 0 002.366-2.477 2.112 2.112 0 00-2.065-2.378 2.591 2.591 0 00-2.366 2.477z"
              />
              <path
                  data-name="Path 1702"
                  d="M500.221 147.442a2.068 2.068 0 002.039 2.373 2.614 2.614 0 002.391-2.483 2.076 2.076 0 00-2.037-2.371 2.63 2.63 0 00-2.393 2.481z"
              />
              <path
                  data-name="Path 1703"
                  d="M285.117 161.024c-.52 1.347.013 2.344 1.19 2.226a4.244 4.244 0 003.053-2.65c.51-1.347-.022-2.345-1.19-2.226a4.266 4.266 0 00-3.053 2.65z"
              />
              <path
                  data-name="Path 1704"
                  d="M278.376 161.713c-.531 1.347-.012 2.343 1.161 2.223a4.3 4.3 0 003.07-2.657c.523-1.347 0-2.341-1.161-2.221a4.327 4.327 0 00-3.07 2.655z"
              />
              <path
                  data-name="Path 1705"
                  d="M271.651 162.414c-.543 1.347-.033 2.341 1.136 2.219a4.362 4.362 0 003.085-2.662c.535-1.347.027-2.34-1.135-2.218a4.389 4.389 0 00-3.086 2.661z"
              />
              <path
                  data-name="Path 1706"
                  d="M264.947 163.132c-.555 1.347-.059 2.339 1.108 2.214a4.424 4.424 0 003.1-2.667c.545-1.347.05-2.338-1.108-2.212a4.453 4.453 0 00-3.1 2.665z"
              />
              <path
                  data-name="Path 1707"
                  d="M258.26 163.862c-.566 1.347-.082 2.336 1.083 2.209a4.478 4.478 0 003.115-2.67c.558-1.347.074-2.336-1.081-2.209a4.515 4.515 0 00-3.117 2.67z"
              />
              <path
                  data-name="Path 1708"
                  d="M251.59 164.605c-.578 1.347-.105 2.336 1.054 2.206a4.546 4.546 0 003.132-2.675c.57-1.347.1-2.334-1.054-2.2a4.575 4.575 0 00-3.132 2.669z"
              />
              <path
                  data-name="Path 1709"
                  d="M244.943 165.363c-.588 1.349-.127 2.334 1.029 2.2a4.611 4.611 0 003.145-2.679c.58-1.349.12-2.334-1.028-2.2a4.644 4.644 0 00-3.146 2.679z"
              />
              <path
                  data-name="Path 1710"
                  d="M238.315 166.135c-.6 1.347-.152 2.331 1 2.2a4.668 4.668 0 003.16-2.684c.592-1.347.144-2.331-1-2.2a4.7 4.7 0 00-3.16 2.684z"
              />
              <path
                  data-name="Path 1711"
                  d="M231.707 166.918c-.61 1.349-.174 2.331.976 2.194a4.73 4.73 0 003.172-2.689c.6-1.347.167-2.329-.974-2.192a4.763 4.763 0 00-3.174 2.687z"
              />
              <path
                  data-name="Path 1712"
                  d="M225.119 167.715c-.622 1.349-.2 2.329.949 2.191a4.794 4.794 0 003.185-2.694c.615-1.349.189-2.328-.947-2.189a4.829 4.829 0 00-3.187 2.692z"
              />
              <path
                  data-name="Path 1713"
                  d="M218.553 168.526c-.633 1.349-.219 2.326.922 2.186a4.852 4.852 0 003.2-2.7c.625-1.349.212-2.326-.922-2.186a4.889 4.889 0 00-3.2 2.7z"
              />
              <path
                  data-name="Path 1714"
                  d="M212.008 169.348c-.642 1.349-.241 2.326.9 2.182a4.92 4.92 0 003.212-2.7c.635-1.349.232-2.324-.9-2.182a4.958 4.958 0 00-3.212 2.7z"
              />
              <path
                  data-name="Path 1715"
                  d="M205.485 170.184c-.653 1.349-.262 2.323.872 2.177a4.98 4.98 0 003.222-2.7c.645-1.349.256-2.323-.871-2.177a5.015 5.015 0 00-3.223 2.7z"
              />
              <path
                  data-name="Path 1716"
                  d="M198.987 171.031c-.663 1.349-.284 2.323.846 2.174a5.041 5.041 0 003.234-2.709c.657-1.347.277-2.321-.846-2.174a5.089 5.089 0 00-3.234 2.709z"
              />
              <path
                  data-name="Path 1717"
                  d="M192.509 171.89c-.673 1.349-.306 2.321.82 2.171a5.109 5.109 0 003.245-2.712c.665-1.347.3-2.319-.82-2.171a5.15 5.15 0 00-3.245 2.712z"
              />
              <path
                  data-name="Path 1718"
                  d="M186.058 172.76c-.683 1.349-.328 2.319.8 2.167a5.172 5.172 0 003.255-2.716c.675-1.349.319-2.318-.8-2.166a5.212 5.212 0 00-3.255 2.715z"
              />
              <path
                  data-name="Path 1719"
                  d="M179.629 173.644c-.692 1.349-.348 2.316.77 2.162a5.236 5.236 0 003.265-2.719c.685-1.349.341-2.316-.77-2.162a5.274 5.274 0 00-3.265 2.719z"
              />
              <path
                  data-name="Path 1720"
                  d="M173.226 174.539c-.7 1.349-.369 2.316.744 2.159a5.293 5.293 0 003.275-2.722c.7-1.349.363-2.314-.744-2.159a5.331 5.331 0 00-3.275 2.722z"
              />
              <path
                  data-name="Path 1721"
                  d="M166.847 175.442c-.712 1.349-.389 2.313.719 2.156a5.359 5.359 0 003.285-2.725c.7-1.349.383-2.313-.72-2.156a5.4 5.4 0 00-3.284 2.725z"
              />
              <path
                  data-name="Path 1722"
                  d="M160.493 176.36c-.722 1.349-.411 2.313.694 2.152a5.425 5.425 0 003.294-2.729c.714-1.347.4-2.311-.7-2.151a5.454 5.454 0 00-3.288 2.728z"
              />
              <path
                  data-name="Path 1723"
                  d="M154.167 177.288c-.73 1.349-.431 2.309.668 2.149a5.49 5.49 0 003.3-2.732c.724-1.347.423-2.309-.67-2.147a5.516 5.516 0 00-3.298 2.73z"
              />
              <path
                  data-name="Path 1724"
                  d="M147.87 178.227c-.739 1.347-.45 2.306.645 2.144a5.55 5.55 0 003.309-2.736c.732-1.347.445-2.308-.645-2.144a5.587 5.587 0 00-3.309 2.736z"
              />
              <path
                  data-name="Path 1725"
                  d="M141.592 179.174c-.749 1.347-.47 2.306.62 2.141a5.61 5.61 0 003.317-2.737c.74-1.347.463-2.306-.622-2.141a5.646 5.646 0 00-3.315 2.737z"
              />
              <path
                  data-name="Path 1726"
                  d="M1011.863 173.644a6.167 6.167 0 003.359 2.729c1.023.164 1.17-.792.331-2.134a6.192 6.192 0 00-3.357-2.729c-1.012-.165-1.165.791-.333 2.134z"
              />
              <path
                  data-name="Path 1727"
                  d="M957.728 165.525a5.629 5.629 0 003.317 2.705c1.066.15 1.3-.819.533-2.166a5.672 5.672 0 00-3.317-2.705c-1.059-.149-1.3.825-.533 2.166z"
              />
              <path
                  data-name="Path 1728"
                  d="M914.127 159.791a5.191 5.191 0 003.257 2.682c1.1.135 1.414-.846.7-2.191a5.232 5.232 0 00-3.257-2.68c-1.086-.135-1.402.844-.7 2.189z"
              />
              <path
                  data-name="Path 1729"
                  d="M895.077 157.511a5.011 5.011 0 003.225 2.67c1.109.129 1.459-.857.78-2.2a5.036 5.036 0 00-3.224-2.669c-1.101-.129-1.452.853-.781 2.199z"
              />
              <path
                  data-name="Path 1730"
                  d="M888.683 156.774a4.94 4.94 0 003.212 2.665c1.115.127 1.474-.861.807-2.2a4.972 4.972 0 00-3.212-2.665c-1.106-.129-1.467.857-.807 2.2z"
              />
              <path
                  data-name="Path 1731"
                  d="M882.267 156.051a4.876 4.876 0 003.2 2.662c1.118.125 1.489-.864.832-2.209a4.9 4.9 0 00-3.2-2.659c-1.11-.125-1.483.863-.832 2.206z"
              />
              <path
                  data-name="Path 1732"
                  d="M875.827 155.341a4.818 4.818 0 003.187 2.657c1.121.124 1.507-.867.859-2.212a4.837 4.837 0 00-3.187-2.654c-1.114-.123-1.499.866-.859 2.209z"
              />
              <path
                  data-name="Path 1733"
                  d="M869.367 154.644a4.749 4.749 0 003.175 2.653c1.125.12 1.522-.871.886-2.216a4.79 4.79 0 00-3.175-2.65c-1.116-.121-1.512.866-.886 2.213z"
              />
              <path
                  data-name="Path 1734"
                  d="M862.889 153.96a4.682 4.682 0 003.16 2.647c1.128.119 1.539-.876.912-2.219a4.72 4.72 0 00-3.162-2.645c-1.119-.122-1.528.878-.91 2.217z"
              />
              <path
                  data-name="Path 1735"
                  d="M856.386 153.291a4.619 4.619 0 003.148 2.642c1.133.117 1.551-.879.938-2.223a4.654 4.654 0 00-3.147-2.64c-1.124-.119-1.544.881-.939 2.221z"
              />
              <path
                  data-name="Path 1736"
                  d="M849.866 152.634a4.561 4.561 0 003.133 2.639c1.136.114 1.569-.884.964-2.228a4.589 4.589 0 00-3.132-2.637c-1.127-.113-1.56.885-.965 2.226z"
              />
              <path
                  data-name="Path 1737"
                  d="M843.327 151.991a4.5 4.5 0 003.118 2.634c1.14.11 1.583-.887.991-2.231a4.524 4.524 0 00-3.118-2.632c-1.13-.111-1.574.887-.991 2.229z"
              />
              <path
                  data-name="Path 1738"
                  d="M836.768 151.362a4.431 4.431 0 003.1 2.627c1.141.109 1.6-.892 1.018-2.234a4.462 4.462 0 00-3.1-2.627c-1.133-.108-1.59.891-1.018 2.234z"
              />
              <path
                  data-name="Path 1739"
                  d="M830.189 150.746a4.375 4.375 0 003.088 2.624c1.146.107 1.614-.9 1.046-2.238a4.4 4.4 0 00-3.088-2.622c-1.138-.105-1.604.894-1.046 2.236z"
              />
              <path
                  data-name="Path 1740"
                  d="M823.593 150.146a4.314 4.314 0 003.071 2.619c1.15.1 1.629-.9 1.073-2.243a4.337 4.337 0 00-3.071-2.617c-1.14-.104-1.621.899-1.073 2.241z"
              />
              <path
                  data-name="Path 1741"
                  d="M816.979 149.56a4.249 4.249 0 003.056 2.612c1.153.1 1.643-.9 1.1-2.246a4.279 4.279 0 00-3.056-2.612c-1.142-.1-1.636.904-1.1 2.246z"
              />
              <path
                  data-name="Path 1742"
                  d="M810.348 148.986a4.186 4.186 0 003.04 2.609c1.155.1 1.661-.909 1.128-2.251a4.215 4.215 0 00-3.04-2.605c-1.145-.099-1.651.907-1.128 2.247z"
              />
              <path
                  data-name="Path 1743"
                  d="M803.699 148.429a4.13 4.13 0 003.023 2.6c1.158.1 1.676-.912 1.155-2.254a4.153 4.153 0 00-3.021-2.6c-1.15-.095-1.668.912-1.157 2.254z"
              />
              <path
                  data-name="Path 1744"
                  d="M797.035 147.886a4.067 4.067 0 003 2.6c1.163.1 1.691-.917 1.183-2.258a4.1 4.1 0 00-3-2.6c-1.153-.093-1.681.918-1.183 2.258z"
              />
              <path
                  data-name="Path 1745"
                  d="M790.354 147.354a4 4 0 002.988 2.592c1.163.092 1.7-.922 1.21-2.263a4.035 4.035 0 00-2.988-2.59c-1.154-.086-1.697.925-1.21 2.261z"
              />
              <path
                  data-name="Path 1746"
                  d="M783.658 146.847a3.942 3.942 0 002.968 2.587c1.166.089 1.723-.926 1.24-2.266a3.978 3.978 0 00-2.97-2.585c-1.158-.09-1.712.922-1.238 2.264z"
              />
              <path
                  data-name="Path 1747"
                  d="M776.945 146.345a3.887 3.887 0 002.951 2.582c1.168.087 1.736-.931 1.267-2.271a3.921 3.921 0 00-2.951-2.58c-1.161-.083-1.728.933-1.267 2.269z"
              />
              <path
                  data-name="Path 1748"
                  d="M770.218 145.865a3.83 3.83 0 002.933 2.577c1.171.084 1.753-.936 1.3-2.274a3.856 3.856 0 00-2.933-2.575c-1.168-.085-1.747.933-1.3 2.272z"
              />
              <path
                  data-name="Path 1749"
                  d="M763.476 145.398a3.772 3.772 0 002.913 2.57c1.176.082 1.766-.941 1.323-2.279a3.8 3.8 0 00-2.917-2.568c-1.16-.08-1.753.94-1.319 2.277z"
              />
              <path
                  data-name="Path 1750"
                  d="M756.72 144.947a3.721 3.721 0 002.894 2.565c1.178.079 1.783-.946 1.35-2.284a3.734 3.734 0 00-2.894-2.563c-1.166-.077-1.771.944-1.35 2.282z"
              />
              <path
                  data-name="Path 1751"
                  d="M749.95 144.511a3.66 3.66 0 002.876 2.56c1.178.075 1.8-.951 1.379-2.289a3.681 3.681 0 00-2.876-2.557c-1.17-.076-1.785.947-1.379 2.286z"
              />
              <path
                  data-name="Path 1752"
                  d="M743.17 144.089a3.6 3.6 0 002.854 2.555c1.183.074 1.812-.954 1.407-2.293a3.628 3.628 0 00-2.856-2.553c-1.171-.068-1.801.953-1.405 2.291z"
              />
              <path
                  data-name="Path 1753"
                  d="M736.373 143.686a3.55 3.55 0 002.836 2.55c1.183.069 1.827-.959 1.434-2.3a3.568 3.568 0 00-2.834-2.547c-1.174-.068-1.817.96-1.436 2.297z"
              />
              <path
                  data-name="Path 1754"
                  d="M729.567 143.298a3.487 3.487 0 002.814 2.543c1.186.067 1.84-.964 1.462-2.3a3.51 3.51 0 00-2.814-2.542c-1.176-.069-1.835.962-1.462 2.299z"
              />
              <path
                  data-name="Path 1755"
                  d="M722.747 142.926a3.434 3.434 0 002.792 2.538c1.19.064 1.857-.969 1.492-2.306a3.462 3.462 0 00-2.794-2.537c-1.179-.063-1.844.966-1.49 2.305z"
              />
              <path
                  data-name="Path 1756"
                  d="M715.918 142.569a3.377 3.377 0 002.772 2.532c1.19.062 1.87-.974 1.519-2.309a3.4 3.4 0 00-2.772-2.532c-1.181-.06-1.861.973-1.519 2.309z"
              />
              <path
                  data-name="Path 1757"
                  d="M709.073 142.229a3.324 3.324 0 002.751 2.527c1.191.058 1.883-.978 1.547-2.314a3.35 3.35 0 00-2.751-2.525c-1.181-.06-1.873.975-1.547 2.312z"
              />
              <path
                  data-name="Path 1758"
                  d="M702.224 141.906a3.272 3.272 0 002.729 2.52c1.195.057 1.9-.983 1.576-2.319a3.3 3.3 0 00-2.729-2.518c-1.187-.058-1.892.98-1.576 2.317z"
              />
              <path
                  data-name="Path 1759"
                  d="M695.358 141.599a3.218 3.218 0 002.712 2.519c1.2.055 1.913-.989 1.6-2.324a3.241 3.241 0 00-2.707-2.513c-1.187-.063-1.904.981-1.605 2.318z"
              />
              <path
                  data-name="Path 1760"
                  d="M688.486 141.308a3.172 3.172 0 002.685 2.51c1.2.05 1.928-.994 1.633-2.329a3.194 3.194 0 00-2.685-2.508c-1.189-.051-1.919.991-1.633 2.327z"
              />
              <path
                  data-name="Path 1761"
                  d="M681.604 141.033a3.119 3.119 0 002.662 2.5c1.2.047 1.943-1 1.661-2.334a3.139 3.139 0 00-2.662-2.5c-1.19-.045-1.934.998-1.661 2.334z"
              />
              <path
                  data-name="Path 1762"
                  d="M674.712 140.775a3.074 3.074 0 002.64 2.5 1.761 1.761 0 001.689-2.34 3.091 3.091 0 00-2.64-2.5c-1.191-.04-1.946 1.004-1.689 2.34z"
              />
              <path
                  data-name="Path 1763"
                  d="M667.813 140.534a3.021 3.021 0 002.617 2.493 1.785 1.785 0 001.718-2.343 3.038 3.038 0 00-2.617-2.492 1.793 1.793 0 00-1.718 2.342z"
              />
              <path
                  data-name="Path 1764"
                  d="M660.906 140.31a2.969 2.969 0 002.594 2.488 1.811 1.811 0 001.745-2.35 2.989 2.989 0 00-2.599-2.485 1.817 1.817 0 00-1.74 2.347z"
              />
              <path
                  data-name="Path 1765"
                  d="M653.99 140.106a2.925 2.925 0 002.572 2.482 1.837 1.837 0 001.773-2.353 2.942 2.942 0 00-2.57-2.48 1.846 1.846 0 00-1.775 2.351z"
              />
              <path
                  data-name="Path 1766"
                  d="M647.068 139.913a2.877 2.877 0 002.547 2.477 1.864 1.864 0 001.8-2.358 2.9 2.9 0 00-2.547-2.475 1.871 1.871 0 00-1.8 2.356z"
              />
              <path
                  data-name="Path 1767"
                  d="M640.138 139.738a2.831 2.831 0 002.523 2.472 1.893 1.893 0 001.83-2.365 2.849 2.849 0 00-2.523-2.468 1.9 1.9 0 00-1.83 2.361z"
              />
              <path
                  data-name="Path 1768"
                  d="M556.618 138.995a2.327 2.327 0 002.223 2.4 2.284 2.284 0 002.159-2.428 2.34 2.34 0 00-2.223-2.4 2.293 2.293 0 00-2.159 2.428z"
              />
              <path
                  data-name="Path 1769"
                  d="M549.639 139.045a2.292 2.292 0 002.2 2.4 2.426 2.426 0 10-2.2-2.4z"
              />
              <path
                  data-name="Path 1770"
                  d="M542.661 139.112a2.254 2.254 0 002.171 2.395 2.358 2.358 0 002.213-2.438 2.266 2.266 0 00-2.168-2.394 2.371 2.371 0 00-2.216 2.437z"
              />
              <path
                  data-name="Path 1771"
                  d="M535.682 139.197a2.216 2.216 0 002.144 2.388 2.394 2.394 0 002.239-2.443 2.229 2.229 0 00-2.144-2.386 2.41 2.41 0 00-2.239 2.441z"
              />
              <path
                  data-name="Path 1772"
                  d="M528.705 139.299a2.184 2.184 0 002.119 2.383 2.435 2.435 0 002.264-2.448 2.2 2.2 0 00-2.117-2.381 2.449 2.449 0 00-2.266 2.446z"
              />
              <path
                  data-name="Path 1773"
                  d="M521.727 139.419a2.148 2.148 0 002.091 2.378 2.476 2.476 0 002.291-2.455 2.158 2.158 0 00-2.091-2.376 2.489 2.489 0 00-2.291 2.453z"
              />
              <path
                  data-name="Path 1774"
                  d="M514.751 139.555a2.115 2.115 0 002.064 2.373 2.517 2.517 0 002.318-2.46 2.125 2.125 0 00-2.064-2.37 2.53 2.53 0 00-2.318 2.457z"
              />
              <path
                  data-name="Path 1775"
                  d="M507.776 139.708a2.082 2.082 0 002.037 2.368 2.557 2.557 0 002.343-2.466 2.094 2.094 0 00-2.037-2.365 2.574 2.574 0 00-2.343 2.463z"
              />
              <path
                  data-name="Path 1776"
                  d="M288.06 153.423c-.523 1.342 0 2.336 1.161 2.219a4.25 4.25 0 003.038-2.644c.515-1.34-.005-2.334-1.16-2.217a4.276 4.276 0 00-3.039 2.642z"
              />
              <path
                  data-name="Path 1777"
                  d="M281.387 154.109c-.536 1.342-.028 2.334 1.133 2.214a4.3 4.3 0 003.056-2.647c.526-1.344.018-2.333-1.135-2.214a4.339 4.339 0 00-3.054 2.647z"
              />
              <path
                  data-name="Path 1778"
                  d="M274.729 154.811c-.546 1.342-.052 2.333 1.108 2.211a4.37 4.37 0 003.071-2.654c.538-1.342.043-2.331-1.106-2.209a4.4 4.4 0 00-3.073 2.652z"
              />
              <path
                  data-name="Path 1779"
                  d="M268.09 155.526c-.558 1.342-.075 2.331 1.08 2.206a4.427 4.427 0 003.088-2.657c.55-1.344.069-2.329-1.079-2.206a4.465 4.465 0 00-3.089 2.657z"
              />
              <path
                  data-name="Path 1780"
                  d="M261.469 156.255c-.572 1.342-.1 2.329 1.053 2.2a4.493 4.493 0 003.1-2.662c.561-1.344.09-2.329-1.053-2.2a4.524 4.524 0 00-3.1 2.662z"
              />
              <path
                  data-name="Path 1781"
                  d="M254.866 156.997c-.582 1.345-.122 2.329 1.028 2.2a4.555 4.555 0 003.118-2.667c.573-1.342.114-2.326-1.028-2.2a4.589 4.589 0 00-3.118 2.667z"
              />
              <path
                  data-name="Path 1782"
                  d="M248.281 157.754c-.593 1.344-.145 2.326 1 2.2a4.619 4.619 0 003.133-2.672c.585-1.344.137-2.326-1-2.194a4.647 4.647 0 00-3.133 2.666z"
              />
              <path
                  data-name="Path 1783"
                  d="M241.713 158.524c-.6 1.345-.167 2.324.976 2.191a4.682 4.682 0 003.147-2.675c.6-1.345.16-2.324-.974-2.191a4.72 4.72 0 00-3.149 2.675z"
              />
              <path
                  data-name="Path 1784"
                  d="M235.169 159.309c-.615 1.344-.191 2.323.947 2.187a4.744 4.744 0 003.162-2.682c.607-1.344.184-2.323-.947-2.186a4.777 4.777 0 00-3.162 2.681z"
              />
              <path
                  data-name="Path 1785"
                  d="M228.644 160.1c-.627 1.345-.214 2.323.921 2.184a4.81 4.81 0 003.175-2.685c.618-1.344.206-2.321-.921-2.182a4.84 4.84 0 00-3.175 2.683z"
              />
              <path
                  data-name="Path 1786"
                  d="M222.139 160.914c-.637 1.344-.236 2.321.9 2.179a4.871 4.871 0 003.187-2.689c.628-1.344.229-2.321-.9-2.179a4.906 4.906 0 00-3.187 2.689z"
              />
              <path
                  data-name="Path 1787"
                  d="M215.652 161.736c-.647 1.344-.257 2.319.871 2.176a4.943 4.943 0 003.2-2.694c.638-1.345.249-2.319-.871-2.176a4.968 4.968 0 00-3.2 2.694z"
              />
              <path
                  data-name="Path 1788"
                  d="M209.189 162.57c-.658 1.345-.281 2.319.844 2.172a5 5 0 003.213-2.7c.648-1.345.271-2.318-.846-2.172a5.031 5.031 0 00-3.211 2.7z"
              />
              <path
                  data-name="Path 1789"
                  d="M202.747 163.418c-.668 1.345-.3 2.316.819 2.169a5.064 5.064 0 003.223-2.7c.66-1.345.294-2.316-.819-2.167a5.087 5.087 0 00-3.223 2.698z"
              />
              <path
                  data-name="Path 1790"
                  d="M196.327 164.277c-.677 1.345-.323 2.316.794 2.166a5.123 5.123 0 003.235-2.707c.67-1.344.316-2.313-.794-2.164a5.165 5.165 0 00-3.235 2.705z"
              />
              <path
                  data-name="Path 1791"
                  d="M189.93 165.149c-.687 1.344-.343 2.313.769 2.161a5.189 5.189 0 003.247-2.711c.68-1.345.336-2.311-.77-2.161a5.227 5.227 0 00-3.246 2.711z"
              />
              <path
                  data-name="Path 1792"
                  d="M183.556 166.031c-.7 1.345-.364 2.311.744 2.157a5.254 5.254 0 003.257-2.714c.69-1.345.356-2.311-.745-2.156a5.285 5.285 0 00-3.256 2.713z"
              />
              <path
                  data-name="Path 1793"
                  d="M177.206 166.925c-.707 1.345-.384 2.311.72 2.156a5.33 5.33 0 003.265-2.719c.7-1.345.378-2.308-.72-2.152a5.353 5.353 0 00-3.265 2.715z"
              />
              <path
                  data-name="Path 1794"
                  d="M170.879 167.831c-.715 1.345-.4 2.309.7 2.152a5.388 5.388 0 003.275-2.722c.709-1.345.4-2.308-.7-2.149a5.427 5.427 0 00-3.275 2.719z"
              />
              <path
                  data-name="Path 1795"
                  d="M164.577 168.748c-.724 1.345-.424 2.306.672 2.147a5.445 5.445 0 003.284-2.724c.719-1.345.418-2.306-.67-2.147a5.49 5.49 0 00-3.286 2.724z"
              />
              <path
                  data-name="Path 1796"
                  d="M158.301 169.677c-.734 1.345-.444 2.3.647 2.144a5.518 5.518 0 003.294-2.729c.727-1.345.436-2.3-.647-2.142a5.546 5.546 0 00-3.294 2.727z"
              />
              <path
                  data-name="Path 1797"
                  d="M152.048 170.617c-.744 1.345-.465 2.3.622 2.139a5.57 5.57 0 003.3-2.731c.737-1.345.458-2.3-.623-2.139a5.6 5.6 0 00-3.299 2.731z"
              />
              <path
                  data-name="Path 1798"
                  d="M44.401 189.087c-.864 1.335-.762 2.264.231 2.075a6.606 6.606 0 003.356-2.762c.861-1.337.755-2.266-.232-2.076a6.638 6.638 0 00-3.355 2.763z"
              />
              <path
                  data-name="Path 1799"
                  d="M977.551 161.442a5.9 5.9 0 003.332 2.714c1.04.157 1.228-.805.423-2.147a5.931 5.931 0 00-3.33-2.714c-1.037-.156-1.225.805-.425 2.147z"
              />
              <path
                  data-name="Path 1800"
                  d="M959.473 158.792a5.717 5.717 0 003.314 2.7c1.054.15 1.273-.816.491-2.157a5.743 5.743 0 00-3.314-2.7c-1.051-.152-1.271.813-.491 2.157z"
              />
              <path
                  data-name="Path 1801"
                  d="M916.398 152.983a5.281 5.281 0 003.255 2.679c1.085.137 1.379-.839.657-2.181a5.3 5.3 0 00-3.255-2.677c-1.075-.137-1.373.839-.657 2.179z"
              />
              <path
                  data-name="Path 1802"
                  d="M910.149 152.199a5.213 5.213 0 003.247 2.674c1.088.137 1.394-.841.68-2.182a5.234 5.234 0 00-3.245-2.674c-1.079-.135-1.387.841-.682 2.182z"
              />
              <path
                  data-name="Path 1803"
                  d="M903.88 151.427a5.138 5.138 0 003.235 2.67c1.091.134 1.409-.846.705-2.187a5.17 5.17 0 00-3.233-2.667c-1.086-.134-1.399.844-.707 2.184z"
              />
              <path
                  data-name="Path 1804"
                  d="M897.587 150.669a5.081 5.081 0 003.223 2.665c1.1.132 1.422-.851.732-2.191a5.119 5.119 0 00-3.223-2.664c-1.086-.13-1.419.85-.732 2.19z"
              />
              <path
                  data-name="Path 1805"
                  d="M891.271 149.922a5.014 5.014 0 003.212 2.66c1.1.13 1.44-.852.757-2.192a5.05 5.05 0 00-3.212-2.66c-1.095-.129-1.43.852-.757 2.192z"
              />
              <path
                  data-name="Path 1806"
                  d="M884.936 149.188a4.949 4.949 0 003.2 2.655c1.1.129 1.452-.856.782-2.2a4.985 4.985 0 00-3.2-2.655c-1.096-.123-1.447.86-.782 2.2z"
              />
              <path
                  data-name="Path 1807"
                  d="M878.58 148.466a4.888 4.888 0 003.187 2.652c1.106.125 1.469-.861.807-2.2a4.913 4.913 0 00-3.187-2.65c-1.098-.124-1.462.854-.807 2.198z"
              />
              <path
                  data-name="Path 1808"
                  d="M872.201 147.758a4.822 4.822 0 003.175 2.647c1.111.124 1.484-.864.834-2.2a4.856 4.856 0 00-3.175-2.645c-1.103-.124-1.477.859-.834 2.198z"
              />
              <path
                  data-name="Path 1809"
                  d="M865.805 147.063a4.751 4.751 0 003.16 2.642c1.115.12 1.5-.867.861-2.207a4.8 4.8 0 00-3.162-2.64c-1.107-.121-1.493.866-.859 2.205z"
              />
              <path
                  data-name="Path 1810"
                  d="M859.386 146.381a4.685 4.685 0 003.148 2.637c1.116.119 1.514-.872.886-2.211a4.725 4.725 0 00-3.148-2.635c-1.11-.119-1.508.87-.886 2.209z"
              />
              <path
                  data-name="Path 1811"
                  d="M852.949 145.711a4.634 4.634 0 003.133 2.632c1.121.115 1.527-.876.912-2.214a4.662 4.662 0 00-3.133-2.632c-1.114-.114-1.52.877-.912 2.214z"
              />
              <path
                  data-name="Path 1812"
                  d="M846.492 145.057a4.565 4.565 0 003.12 2.627c1.125.114 1.544-.879.937-2.217a4.6 4.6 0 00-3.118-2.627c-1.116-.113-1.536.878-.939 2.217z"
              />
              <path
                  data-name="Path 1813"
                  d="M840.018 144.415a4.5 4.5 0 003.1 2.624c1.13.11 1.559-.886.966-2.223a4.538 4.538 0 00-3.1-2.622c-1.12-.107-1.552.884-.966 2.221z"
              />
              <path
                  data-name="Path 1814"
                  d="M833.524 143.789a4.44 4.44 0 003.088 2.617c1.131.109 1.574-.889.993-2.226a4.47 4.47 0 00-3.088-2.615c-1.123-.108-1.568.888-.993 2.224z"
              />
              <path
                  data-name="Path 1815"
                  d="M827.014 143.176a4.374 4.374 0 003.071 2.612c1.135.107 1.591-.892 1.019-2.229a4.4 4.4 0 00-3.073-2.61c-1.124-.106-1.58.89-1.017 2.227z"
              />
              <path
                  data-name="Path 1816"
                  d="M820.485 142.576a4.316 4.316 0 003.056 2.609c1.138.1 1.606-.9 1.046-2.234a4.341 4.341 0 00-3.056-2.605c-1.128-.101-1.598.899-1.046 2.23z"
              />
              <path
                  data-name="Path 1817"
                  d="M813.94 141.991a4.252 4.252 0 003.04 2.6c1.141.1 1.621-.9 1.073-2.238a4.279 4.279 0 00-3.04-2.6c-1.132-.098-1.611.903-1.073 2.238z"
              />
              <path
                  data-name="Path 1818"
                  d="M807.377 141.421a4.185 4.185 0 003.023 2.6c1.143.1 1.636-.9 1.1-2.241a4.217 4.217 0 00-3.023-2.6c-1.134-.095-1.627.906-1.1 2.241z"
              />
              <path
                  data-name="Path 1819"
                  d="M800.799 140.866a4.13 4.13 0 003.006 2.592c1.146.1 1.651-.911 1.128-2.246a4.149 4.149 0 00-3.006-2.588c-1.137-.097-1.641.907-1.128 2.242z"
              />
              <path
                  data-name="Path 1820"
                  d="M794.204 140.325a4.065 4.065 0 002.988 2.587c1.15.094 1.666-.914 1.156-2.249a4.09 4.09 0 00-2.99-2.583c-1.143-.094-1.655.912-1.154 2.245z"
              />
              <path
                  data-name="Path 1821"
                  d="M787.593 139.799a4.009 4.009 0 002.971 2.582c1.151.09 1.681-.919 1.183-2.253a4.027 4.027 0 00-2.971-2.578c-1.143-.093-1.671.915-1.183 2.249z"
              />
              <path
                  data-name="Path 1822"
                  d="M780.969 139.288a3.946 3.946 0 002.953 2.575c1.153.087 1.694-.922 1.21-2.256a3.965 3.965 0 00-2.953-2.573c-1.143-.088-1.686.919-1.21 2.254z"
              />
              <path
                  data-name="Path 1823"
                  d="M774.33 138.793a3.883 3.883 0 002.933 2.57c1.156.085 1.711-.927 1.238-2.261a3.909 3.909 0 00-2.933-2.567c-1.148-.087-1.703.924-1.238 2.258z"
              />
              <path
                  data-name="Path 1824"
                  d="M767.677 138.311a3.821 3.821 0 002.914 2.565c1.16.084 1.726-.932 1.267-2.264a3.849 3.849 0 00-2.914-2.562c-1.153-.084-1.717.929-1.267 2.261z"
              />
              <path
                  data-name="Path 1825"
                  d="M761.009 137.847a3.767 3.767 0 002.894 2.56c1.161.08 1.741-.936 1.3-2.269a3.791 3.791 0 00-2.9-2.557c-1.152-.08-1.732.934-1.294 2.266z"
              />
              <path
                  data-name="Path 1826"
                  d="M754.323 137.398a3.709 3.709 0 002.876 2.553c1.163.079 1.758-.941 1.323-2.273a3.739 3.739 0 00-2.876-2.552c-1.151-.075-1.743.94-1.323 2.272z"
              />
              <path
                  data-name="Path 1827"
                  d="M747.631 136.963a3.654 3.654 0 002.856 2.548c1.166.075 1.771-.946 1.35-2.278a3.673 3.673 0 00-2.856-2.545c-1.154-.075-1.759.945-1.35 2.275z"
              />
              <path
                  data-name="Path 1828"
                  d="M740.925 136.545a3.591 3.591 0 002.836 2.542c1.168.074 1.785-.949 1.379-2.281a3.624 3.624 0 00-2.836-2.54c-1.156-.072-1.776.947-1.379 2.279z"
              />
              <path
                  data-name="Path 1829"
                  d="M734.206 136.142a3.537 3.537 0 002.814 2.537c1.171.07 1.8-.954 1.407-2.286a3.558 3.558 0 00-2.814-2.533c-1.16-.071-1.791.952-1.407 2.282z"
              />
              <path
                  data-name="Path 1830"
                  d="M727.475 135.756a3.481 3.481 0 002.794 2.53c1.171.067 1.815-.959 1.435-2.289a3.5 3.5 0 00-2.8-2.528c-1.158-.068-1.8.955-1.429 2.287z"
              />
              <path
                  data-name="Path 1831"
                  d="M720.732 135.385a3.425 3.425 0 002.772 2.525c1.176.065 1.83-.964 1.464-2.294a3.45 3.45 0 00-2.774-2.523c-1.165-.064-1.818.962-1.462 2.292z"
              />
              <path
                  data-name="Path 1832"
                  d="M713.978 135.031a3.375 3.375 0 002.752 2.52c1.176.06 1.845-.969 1.492-2.3a3.393 3.393 0 00-2.752-2.517c-1.166-.061-1.831.967-1.492 2.297z"
              />
              <path
                  data-name="Path 1833"
                  d="M707.215 134.692a3.315 3.315 0 002.729 2.515c1.18.059 1.86-.974 1.521-2.3a3.344 3.344 0 00-2.731-2.512c-1.168-.063-1.848.969-1.519 2.297z"
              />
              <path
                  data-name="Path 1834"
                  d="M700.441 134.371a3.26 3.26 0 002.707 2.508c1.181.055 1.873-.979 1.549-2.308a3.286 3.286 0 00-2.709-2.507c-1.17-.054-1.863.978-1.547 2.307z"
              />
              <path
                  data-name="Path 1835"
                  d="M693.656 134.065a3.208 3.208 0 002.685 2.5c1.181.053 1.888-.983 1.576-2.311a3.232 3.232 0 00-2.685-2.5c-1.172-.057-1.877.982-1.576 2.311z"
              />
              <path
                  data-name="Path 1836"
                  d="M686.86 133.779a3.162 3.162 0 002.664 2.5c1.183.05 1.9-.989 1.606-2.318a3.184 3.184 0 00-2.665-2.495c-1.172-.055-1.891.98-1.605 2.313z"
              />
              <path
                  data-name="Path 1837"
                  d="M680.058 133.502a3.11 3.11 0 002.642 2.492c1.185.047 1.917-.993 1.633-2.321a3.132 3.132 0 00-2.64-2.49c-1.176-.046-1.908.991-1.635 2.319z"
              />
              <path
                  data-name="Path 1838"
                  d="M673.249 133.246a3.056 3.056 0 002.617 2.487c1.188.043 1.93-1 1.661-2.328a3.077 3.077 0 00-2.619-2.483c-1.175-.044-1.918.996-1.659 2.324z"
              />
              <path
                  data-name="Path 1839"
                  d="M666.429 133.007a3.007 3.007 0 002.6 2.48 1.768 1.768 0 001.688-2.333 3.026 3.026 0 00-2.594-2.477 1.774 1.774 0 00-1.694 2.33z"
              />
              <path
                  data-name="Path 1840"
                  d="M659.601 132.783a2.958 2.958 0 002.572 2.475 1.793 1.793 0 001.718-2.336 2.976 2.976 0 00-2.572-2.472 1.8 1.8 0 00-1.718 2.333z"
              />
              <path
                  data-name="Path 1841"
                  d="M652.766 132.578a2.91 2.91 0 002.548 2.468 1.82 1.82 0 001.746-2.341 2.928 2.928 0 00-2.548-2.465 1.826 1.826 0 00-1.746 2.338z"
              />
              <path
                  data-name="Path 1842"
                  d="M645.925 132.389a2.865 2.865 0 002.525 2.463 1.847 1.847 0 001.773-2.346 2.879 2.879 0 00-2.523-2.461 1.855 1.855 0 00-1.775 2.344z"
              />
              <path
                  data-name="Path 1843"
                  d="M639.077 132.215a2.816 2.816 0 002.5 2.458 1.875 1.875 0 001.8-2.351 2.834 2.834 0 00-2.5-2.455 1.883 1.883 0 00-1.8 2.348z"
              />
              <path
                  data-name="Path 1844"
                  d="M632.223 132.06a2.768 2.768 0 002.475 2.453 1.9 1.9 0 001.83-2.356 2.786 2.786 0 00-2.475-2.45 1.91 1.91 0 00-1.83 2.353z"
              />
              <path
                  data-name="Path 1845"
                  d="M618.499 131.801a2.676 2.676 0 002.426 2.44 1.961 1.961 0 001.885-2.366 2.693 2.693 0 00-2.426-2.436 1.97 1.97 0 00-1.885 2.362z"
              />
              <path
                  data-name="Path 1846"
                  d="M604.755 131.609a2.589 2.589 0 002.376 2.43 2.026 2.026 0 001.942-2.376 2.607 2.607 0 00-2.376-2.428 2.035 2.035 0 00-1.942 2.374z"
              />
              <path
                  data-name="Path 1847"
                  d="M556.544 131.477a2.309 2.309 0 002.2 2.391 2.266 2.266 0 002.134-2.413 2.318 2.318 0 00-2.2-2.388 2.275 2.275 0 00-2.134 2.41z"
              />
              <path
                  data-name="Path 1848"
                  d="M549.649 131.526a2.273 2.273 0 002.171 2.386 2.414 2.414 0 10-2.171-2.386z"
              />
              <path
                  data-name="Path 1849"
                  d="M542.754 131.593a2.235 2.235 0 002.144 2.381 2.341 2.341 0 002.186-2.425 2.245 2.245 0 00-2.142-2.378 2.352 2.352 0 00-2.188 2.422z"
              />
              <path
                  data-name="Path 1850"
                  d="M535.858 131.679a2.2 2.2 0 002.117 2.375 2.379 2.379 0 002.214-2.43 2.211 2.211 0 00-2.117-2.373 2.391 2.391 0 00-2.214 2.428z"
              />
              <path
                  data-name="Path 1851"
                  d="M528.964 131.78a2.162 2.162 0 002.091 2.37 2.417 2.417 0 002.239-2.436 2.172 2.172 0 00-2.09-2.366 2.43 2.43 0 00-2.24 2.432z"
              />
              <path
                  data-name="Path 1852"
                  d="M317.6 143.256c-.478 1.335.077 2.333 1.24 2.226a4 4 0 002.953-2.612c.47-1.335-.085-2.329-1.24-2.223a4.026 4.026 0 00-2.953 2.609z"
              />
              <path
                  data-name="Path 1853"
                  d="M310.933 143.883c-.49 1.335.053 2.331 1.213 2.221a4.06 4.06 0 002.971-2.617c.481-1.335-.062-2.328-1.211-2.219a4.088 4.088 0 00-2.973 2.615z"
              />
              <path
                  data-name="Path 1854"
                  d="M304.282 144.523c-.5 1.337.028 2.331 1.186 2.219a4.13 4.13 0 002.99-2.622c.493-1.337-.038-2.328-1.186-2.216a4.15 4.15 0 00-2.99 2.619z"
              />
              <path
                  data-name="Path 1855"
                  d="M297.647 145.18c-.516 1.337 0 2.328 1.158 2.214a4.188 4.188 0 003.008-2.629c.506-1.335-.013-2.324-1.16-2.211a4.212 4.212 0 00-3.006 2.626z"
              />
              <path
                  data-name="Path 1856"
                  d="M291.026 145.849c-.528 1.337-.02 2.328 1.131 2.211a4.248 4.248 0 003.025-2.634c.518-1.335.012-2.324-1.131-2.207a4.269 4.269 0 00-3.025 2.63z"
              />
              <path
                  data-name="Path 1857"
                  d="M284.421 146.534c-.54 1.337-.045 2.326 1.1 2.206a4.307 4.307 0 003.041-2.637c.53-1.337.037-2.324-1.1-2.2a4.334 4.334 0 00-3.041 2.631z"
              />
              <path
                  data-name="Path 1858"
                  d="M277.834 147.233c-.55 1.339-.067 2.324 1.079 2.2a4.373 4.373 0 003.058-2.642c.541-1.337.058-2.323-1.079-2.2a4.408 4.408 0 00-3.058 2.642z"
              />
              <path
                  data-name="Path 1859"
                  d="M271.258 147.947c-.563 1.339-.092 2.324 1.053 2.2a4.432 4.432 0 003.073-2.649c.555-1.337.084-2.321-1.051-2.2a4.462 4.462 0 00-3.075 2.649z"
              />
              <path
                  data-name="Path 1860"
                  d="M264.701 148.675c-.575 1.337-.115 2.321 1.026 2.194a4.5 4.5 0 003.09-2.654c.565-1.339.105-2.319-1.026-2.192a4.524 4.524 0 00-3.09 2.652z"
              />
              <path
                  data-name="Path 1861"
                  d="M258.163 149.419c-.587 1.339-.139 2.321 1 2.191a4.562 4.562 0 003.1-2.657c.577-1.339.13-2.319-1-2.191a4.592 4.592 0 00-3.1 2.657z"
              />
              <path
                  data-name="Path 1862"
                  d="M251.64 150.171c-.6 1.339-.16 2.319.974 2.187a4.629 4.629 0 003.12-2.664c.588-1.339.154-2.316-.973-2.186a4.667 4.667 0 00-3.121 2.663z"
              />
              <path
                  data-name="Path 1863"
                  d="M245.138 150.94c-.608 1.339-.184 2.316.948 2.184a4.7 4.7 0 003.135-2.669c.6-1.339.175-2.316-.948-2.182a4.727 4.727 0 00-3.135 2.667z"
              />
              <path
                  data-name="Path 1864"
                  d="M238.655 151.722c-.62 1.339-.207 2.316.921 2.179a4.755 4.755 0 003.15-2.672c.61-1.339.2-2.314-.922-2.179a4.793 4.793 0 00-3.149 2.672z"
              />
              <path
                  data-name="Path 1865"
                  d="M232.19 152.517c-.63 1.339-.229 2.314.9 2.176a4.821 4.821 0 003.163-2.677c.622-1.34.221-2.314-.9-2.176a4.854 4.854 0 00-3.163 2.677z"
              />
              <path
                  data-name="Path 1866"
                  d="M225.744 153.324c-.64 1.34-.251 2.313.871 2.172a4.883 4.883 0 003.175-2.68c.633-1.34.244-2.313-.871-2.172a4.915 4.915 0 00-3.175 2.68z"
              />
              <path
                  data-name="Path 1867"
                  d="M219.318 154.144c-.652 1.34-.274 2.313.844 2.171a4.953 4.953 0 003.188-2.687c.645-1.34.266-2.311-.844-2.167a4.981 4.981 0 00-3.188 2.683z"
              />
              <path
                  data-name="Path 1868"
                  d="M212.913 154.979c-.662 1.34-.294 2.311.819 2.166a5.015 5.015 0 003.2-2.69c.653-1.342.286-2.309-.82-2.164a5.049 5.049 0 00-3.199 2.688z"
              />
              <path
                  data-name="Path 1869"
                  d="M206.528 155.826c-.67 1.34-.316 2.309.8 2.161a5.077 5.077 0 003.212-2.694c.665-1.34.309-2.309-.794-2.161a5.111 5.111 0 00-3.218 2.694z"
              />
              <path
                  data-name="Path 1870"
                  d="M200.167 156.683c-.682 1.342-.338 2.308.769 2.159a5.148 5.148 0 003.225-2.7c.673-1.34.329-2.308-.77-2.157a5.17 5.17 0 00-3.224 2.698z"
              />
              <path
                  data-name="Path 1871"
                  d="M193.825 157.553c-.692 1.342-.358 2.308.745 2.156a5.21 5.21 0 003.235-2.7c.683-1.342.349-2.3-.745-2.154a5.243 5.243 0 00-3.235 2.698z"
              />
              <path
                  data-name="Path 1872"
                  d="M187.504 158.438c-.7 1.34-.378 2.3.72 2.151a5.275 5.275 0 003.247-2.707c.693-1.342.371-2.3-.72-2.151a5.309 5.309 0 00-3.247 2.707z"
              />
              <path
                  data-name="Path 1873"
                  d="M181.208 159.331c-.71 1.342-.4 2.3.7 2.147a5.334 5.334 0 003.255-2.71c.7-1.342.393-2.3-.7-2.147a5.371 5.371 0 00-3.255 2.71z"
              />
              <path
                  data-name="Path 1874"
                  d="M174.935 160.239c-.72 1.342-.419 2.3.672 2.144a5.4 5.4 0 003.265-2.716c.714-1.34.413-2.3-.672-2.144a5.436 5.436 0 00-3.265 2.716z"
              />
              <path
                  data-name="Path 1875"
                  d="M168.685 161.153c-.729 1.344-.439 2.3.647 2.142a5.465 5.465 0 003.275-2.719c.722-1.342.433-2.3-.648-2.141a5.5 5.5 0 00-3.274 2.718z"
              />
              <path
                  data-name="Path 1876"
                  d="M162.458 162.082c-.737 1.34-.458 2.3.623 2.137a5.522 5.522 0 003.284-2.722c.732-1.342.451-2.3-.623-2.137a5.565 5.565 0 00-3.284 2.722z"
              />
              <path
                  data-name="Path 1877"
                  d="M156.258 163.022c-.747 1.342-.478 2.3.6 2.134a5.59 5.59 0 003.292-2.726c.74-1.342.47-2.3-.6-2.134a5.627 5.627 0 00-3.292 2.726z"
              />
              <path
                  data-name="Path 1878"
                  d="M150.08 163.971c-.754 1.342-.5 2.3.577 2.132a5.653 5.653 0 003.3-2.731c.749-1.34.49-2.294-.578-2.129a5.687 5.687 0 00-3.299 2.728z"
              />
              <path
                  data-name="Path 1879"
                  d="M143.928 164.932c-.764 1.342-.516 2.294.553 2.127a5.719 5.719 0 003.307-2.732c.757-1.342.508-2.293-.555-2.127a5.751 5.751 0 00-3.305 2.732z"
              />
              <path
                  data-name="Path 1880"
                  d="M49.289 181.536c-.866 1.335-.767 2.263.219 2.074a6.639 6.639 0 003.35-2.764c.862-1.335.762-2.264-.222-2.072a6.653 6.653 0 00-3.347 2.762z"
              />
              <path
                  data-name="Path 1881"
                  d="M984.855 155.681a6.029 6.029 0 003.334 2.714c1.023.16 1.185-.8.363-2.136a6.077 6.077 0 00-3.332-2.714c-1.019-.16-1.182.797-.365 2.136z"
              />
              <path
                  data-name="Path 1882"
                  d="M978.949 154.767a5.969 5.969 0 003.328 2.707c1.029.159 1.2-.8.384-2.139a6.007 6.007 0 00-3.327-2.712c-1.023-.149-1.195.805-.385 2.144z"
              />
              <path
                  data-name="Path 1883"
                  d="M955.068 151.214a5.729 5.729 0 003.305 2.7c1.044.15 1.258-.812.471-2.151a5.762 5.762 0 00-3.3-2.7c-1.041-.152-1.255.812-.476 2.151z"
              />
              <path
                  data-name="Path 1884"
                  d="M930.795 147.829a5.479 5.479 0 003.274 2.684c1.063.144 1.315-.825.563-2.164a5.506 5.506 0 00-3.272-2.68c-1.056-.144-1.308.824-.565 2.16z"
              />
              <path
                  data-name="Path 1885"
                  d="M912.35 145.413a5.282 5.282 0 003.243 2.67c1.075.137 1.359-.836.635-2.172a5.316 5.316 0 00-3.243-2.669c-1.068-.136-1.353.834-.635 2.171z"
              />
              <path
                  data-name="Path 1886"
                  d="M906.156 144.631a5.221 5.221 0 003.235 2.665c1.078.135 1.372-.839.658-2.176a5.252 5.252 0 00-3.234-2.664c-1.068-.134-1.366.835-.659 2.175z"
              />
              <path
                  data-name="Path 1887"
                  d="M899.941 143.862a5.159 5.159 0 003.223 2.66c1.081.134 1.389-.842.683-2.179a5.179 5.179 0 00-3.222-2.659c-1.075-.133-1.379.841-.684 2.178z"
              />
              <path
                  data-name="Path 1888"
                  d="M893.704 143.103a5.084 5.084 0 003.21 2.657c1.086.132 1.4-.847.709-2.182a5.122 5.122 0 00-3.21-2.655c-1.078-.131-1.395.845-.709 2.18z"
              />
              <path
                  data-name="Path 1889"
                  d="M887.45 142.361a5.022 5.022 0 003.2 2.65c1.091.13 1.419-.849.734-2.184a5.05 5.05 0 00-3.2-2.65c-1.088-.129-1.413.848-.734 2.184z"
              />
              <path
                  data-name="Path 1890"
                  d="M881.172 141.629a4.968 4.968 0 003.187 2.647c1.093.125 1.432-.854.759-2.189a4.985 4.985 0 00-3.187-2.645c-1.086-.126-1.426.852-.759 2.187z"
              />
              <path
                  data-name="Path 1891"
                  d="M874.875 140.908a4.889 4.889 0 003.173 2.642c1.1.125 1.449-.857.784-2.192a4.926 4.926 0 00-3.173-2.64c-1.088-.128-1.441.855-.784 2.19z"
              />
              <path
                  data-name="Path 1892"
                  d="M868.558 140.206a4.828 4.828 0 003.16 2.637c1.1.122 1.462-.861.809-2.2a4.857 4.857 0 00-3.16-2.635c-1.091-.121-1.454.86-.809 2.198z"
              />
              <path
                  data-name="Path 1893"
                  d="M862.222 139.509a4.765 4.765 0 003.147 2.632c1.1.12 1.477-.866.836-2.2a4.794 4.794 0 00-3.147-2.63c-1.097-.118-1.469.865-.836 2.198z"
              />
              <path
                  data-name="Path 1894"
                  d="M855.867 138.829a4.692 4.692 0 003.133 2.627c1.106.119 1.492-.869.861-2.2a4.724 4.724 0 00-3.133-2.625c-1.1-.12-1.484.866-.861 2.198z"
              />
              <path
                  data-name="Path 1895"
                  d="M849.493 138.162a4.632 4.632 0 003.118 2.624c1.111.115 1.507-.874.887-2.206a4.66 4.66 0 00-3.118-2.62c-1.101-.116-1.501.87-.887 2.202z"
              />
              <path
                  data-name="Path 1896"
                  d="M843.101 137.511a4.568 4.568 0 003.1 2.617c1.113.114 1.522-.876.914-2.209a4.605 4.605 0 00-3.1-2.615c-1.106-.112-1.514.875-.914 2.207z"
              />
              <path
                  data-name="Path 1897"
                  d="M836.691 136.872a4.5 4.5 0 003.088 2.612c1.116.11 1.537-.881.941-2.212a4.537 4.537 0 00-3.088-2.61c-1.108-.111-1.529.878-.941 2.21z"
              />
              <path
                  data-name="Path 1898"
                  d="M830.265 136.247a4.445 4.445 0 003.073 2.607c1.121.107 1.552-.886.966-2.216a4.467 4.467 0 00-3.073-2.605c-1.11-.107-1.543.883-.966 2.214z"
              />
              <path
                  data-name="Path 1899"
                  d="M823.821 135.636a4.37 4.37 0 003.056 2.6c1.123.105 1.566-.887.993-2.219a4.4 4.4 0 00-3.056-2.6c-1.113-.102-1.56.889-.993 2.219z"
              />
              <path
                  data-name="Path 1900"
                  d="M817.36 135.039a4.307 4.307 0 003.04 2.6c1.126.1 1.583-.892 1.019-2.223a4.336 4.336 0 00-3.04-2.594c-1.116-.106-1.572.889-1.019 2.217z"
              />
              <path
                  data-name="Path 1901"
                  d="M810.883 134.458a4.243 4.243 0 003.023 2.59c1.13.1 1.6-.9 1.046-2.226a4.273 4.273 0 00-3.023-2.588c-1.119-.1-1.587.895-1.046 2.224z"
              />
              <path
                  data-name="Path 1902"
                  d="M804.389 133.891a4.183 4.183 0 003.006 2.583c1.131.1 1.613-.9 1.075-2.229a4.219 4.219 0 00-3.006-2.583c-1.123-.097-1.603.896-1.075 2.229z"
              />
              <path
                  data-name="Path 1903"
                  d="M797.88 133.335a4.124 4.124 0 002.99 2.578c1.135.1 1.626-.906 1.1-2.234a4.152 4.152 0 00-2.99-2.577c-1.122-.09-1.616.907-1.1 2.233z"
              />
              <path
                  data-name="Path 1904"
                  d="M791.356 132.8a4.066 4.066 0 002.971 2.573c1.136.094 1.643-.911 1.128-2.238a4.086 4.086 0 00-2.97-2.572c-1.127-.091-1.632.909-1.129 2.237z"
              />
              <path
                  data-name="Path 1905"
                  d="M784.82 132.275a4 4 0 002.953 2.568c1.138.092 1.658-.914 1.155-2.241a4.022 4.022 0 00-2.953-2.565c-1.126-.09-1.647.911-1.155 2.238z"
              />
              <path
                  data-name="Path 1906"
                  d="M778.266 131.768a3.941 3.941 0 002.934 2.563c1.143.087 1.673-.917 1.183-2.246a3.963 3.963 0 00-2.933-2.56c-1.129-.087-1.662.916-1.184 2.243z"
              />
              <path
                  data-name="Path 1907"
                  d="M771.699 131.274a3.874 3.874 0 002.914 2.557c1.143.087 1.686-.922 1.212-2.249a3.9 3.9 0 00-2.916-2.553c-1.133-.086-1.677.918-1.21 2.245z"
              />
              <path
                  data-name="Path 1908"
                  d="M765.119 130.797a3.82 3.82 0 002.9 2.552c1.146.082 1.7-.927 1.238-2.253a3.845 3.845 0 00-2.9-2.55c-1.136-.082-1.69.926-1.238 2.251z"
              />
              <path
                  data-name="Path 1909"
                  d="M758.525 130.334a3.762 3.762 0 002.876 2.547c1.15.079 1.716-.932 1.267-2.258a3.787 3.787 0 00-2.876-2.543c-1.14-.08-1.707.929-1.267 2.254z"
              />
              <path
                  data-name="Path 1910"
                  d="M751.917 129.885a3.705 3.705 0 002.856 2.542c1.153.077 1.731-.937 1.3-2.261a3.725 3.725 0 00-2.856-2.538c-1.146-.077-1.726.932-1.3 2.257z"
              />
              <path
                  data-name="Path 1911"
                  d="M745.3 129.454a3.645 3.645 0 002.836 2.535c1.153.074 1.746-.941 1.323-2.266a3.666 3.666 0 00-2.836-2.532c-1.145-.073-1.737.938-1.323 2.263z"
              />
              <path
                  data-name="Path 1912"
                  d="M738.667 129.038a3.583 3.583 0 002.816 2.528c1.156.074 1.76-.944 1.35-2.268a3.606 3.606 0 00-2.816-2.527c-1.144-.069-1.749.944-1.35 2.267z"
              />
              <path
                  data-name="Path 1913"
                  d="M732.025 128.639a3.525 3.525 0 002.794 2.523c1.158.069 1.775-.951 1.379-2.274a3.548 3.548 0 00-2.794-2.52c-1.148-.068-1.765.946-1.379 2.271z"
              />
              <path
                  data-name="Path 1914"
                  d="M725.371 128.255a3.473 3.473 0 002.774 2.517c1.158.067 1.788-.954 1.407-2.278a3.494 3.494 0 00-2.774-2.515c-1.15-.065-1.78.953-1.407 2.276z"
              />
              <path
                  data-name="Path 1915"
                  d="M718.705 127.886a3.414 3.414 0 002.752 2.512c1.161.064 1.8-.959 1.436-2.281a3.433 3.433 0 00-2.752-2.508c-1.152-.064-1.794.955-1.436 2.277z"
              />
              <path
                  data-name="Path 1916"
                  d="M712.029 127.533a3.361 3.361 0 002.731 2.507c1.163.06 1.818-.964 1.464-2.286a3.382 3.382 0 00-2.73-2.5c-1.154-.069-1.809.957-1.465 2.279z"
              />
              <path
                  data-name="Path 1917"
                  d="M705.343 127.197a3.305 3.305 0 002.709 2.5c1.166.059 1.833-.968 1.492-2.291a3.326 3.326 0 00-2.709-2.5c-1.154-.058-1.823.969-1.492 2.291z"
              />
              <path
                  data-name="Path 1918"
                  d="M698.647 126.878a3.251 3.251 0 002.687 2.493c1.166.055 1.846-.973 1.521-2.294a3.27 3.27 0 00-2.687-2.492c-1.157-.055-1.837.971-1.521 2.293z"
              />
              <path
                  data-name="Path 1919"
                  d="M691.942 126.573a3.2 3.2 0 002.664 2.488c1.168.052 1.862-.978 1.549-2.3a3.218 3.218 0 00-2.665-2.487c-1.156-.048-1.85.978-1.548 2.299z"
              />
              <path
                  data-name="Path 1920"
                  d="M685.228 126.286a3.142 3.142 0 002.642 2.482c1.17.05 1.877-.983 1.576-2.3a3.163 3.163 0 00-2.642-2.48c-1.158-.053-1.865.976-1.576 2.298z"
              />
              <path
                  data-name="Path 1921"
                  d="M678.506 126.014a3.1 3.1 0 002.619 2.478c1.171.047 1.89-.989 1.6-2.309a3.111 3.111 0 00-2.617-2.473c-1.158-.043-1.878.984-1.602 2.304z"
              />
              <path
                  data-name="Path 1922"
                  d="M671.775 125.763a3.042 3.042 0 002.6 2.472c1.173.043 1.905-.993 1.634-2.313a3.059 3.059 0 00-2.6-2.468c-1.163-.047-1.893.986-1.634 2.309z"
              />
              <path
                  data-name="Path 1923"
                  d="M665.037 125.523a2.993 2.993 0 002.572 2.465c1.173.04 1.917-1 1.661-2.318a3.013 3.013 0 00-2.572-2.463c-1.163-.042-1.908.995-1.661 2.316z"
              />
              <path
                  data-name="Path 1924"
                  d="M658.29 125.3a2.942 2.942 0 002.548 2.46 1.775 1.775 0 001.691-2.323 2.964 2.964 0 00-2.548-2.456 1.782 1.782 0 00-1.691 2.319z"
              />
              <path
                  data-name="Path 1925"
                  d="M651.537 125.097a2.9 2.9 0 002.525 2.453 1.8 1.8 0 001.718-2.328 2.91 2.91 0 00-2.525-2.45 1.809 1.809 0 00-1.718 2.325z"
              />
              <path
                  data-name="Path 1926"
                  d="M644.772 124.907a2.842 2.842 0 002.5 2.448 1.827 1.827 0 001.746-2.331 2.861 2.861 0 00-2.5-2.445 1.833 1.833 0 00-1.746 2.328z"
              />
              <path
                  data-name="Path 1927"
                  d="M638.011 124.737a2.8 2.8 0 002.477 2.441 1.853 1.853 0 001.769-2.336 2.813 2.813 0 00-2.475-2.44 1.863 1.863 0 00-1.771 2.335z"
              />
              <path
                  data-name="Path 1928"
                  d="M631.239 124.579a2.753 2.753 0 002.453 2.438 1.884 1.884 0 001.8-2.343 2.766 2.766 0 00-2.452-2.433 1.892 1.892 0 00-1.801 2.338z"
              />
              <path
                  data-name="Path 1929"
                  d="M624.462 124.445a2.7 2.7 0 002.426 2.431 1.913 1.913 0 001.83-2.348 2.718 2.718 0 00-2.426-2.428 1.922 1.922 0 00-1.83 2.345z"
              />
              <path
                  data-name="Path 1930"
                  d="M617.679 124.325a2.657 2.657 0 002.4 2.425 1.944 1.944 0 001.86-2.351 2.678 2.678 0 00-2.4-2.423 1.952 1.952 0 00-1.86 2.349z"
              />
              <path
                  data-name="Path 1931"
                  d="M597.305 124.06a2.53 2.53 0 002.326 2.41 2.038 2.038 0 001.942-2.368 2.543 2.543 0 00-2.326-2.406 2.046 2.046 0 00-1.942 2.364z"
              />
              <path
                  data-name="Path 1932"
                  d="M590.505 124.01a2.488 2.488 0 002.3 2.4 2.072 2.072 0 001.97-2.373 2.5 2.5 0 00-2.3-2.4 2.08 2.08 0 00-1.97 2.373z"
              />
              <path
                  data-name="Path 1933"
                  d="M563.283 123.97a2.326 2.326 0 002.2 2.383 2.21 2.21 0 002.08-2.395 2.339 2.339 0 00-2.2-2.378 2.219 2.219 0 00-2.08 2.39z"
              />
              <path
                  data-name="Path 1934"
                  d="M522.412 124.424a2.109 2.109 0 002.037 2.35 2.441 2.441 0 002.241-2.426 2.118 2.118 0 00-2.037-2.346 2.452 2.452 0 00-2.241 2.422z"
              />
              <path
                  data-name="Path 1935"
                  d="M515.601 124.558a2.076 2.076 0 002.01 2.346 2.483 2.483 0 002.268-2.433 2.086 2.086 0 00-2.01-2.341 2.5 2.5 0 00-2.268 2.428z"
              />
              <path
                  data-name="Path 1936"
                  d="M508.792 124.712a2.042 2.042 0 001.982 2.339 2.524 2.524 0 002.294-2.438 2.052 2.052 0 00-1.982-2.336 2.538 2.538 0 00-2.294 2.435z"
              />
              <path
                  data-name="Path 1937"
                  d="M501.984 124.881a2.01 2.01 0 001.955 2.334 2.566 2.566 0 002.321-2.443 2.021 2.021 0 00-1.955-2.331 2.582 2.582 0 00-2.321 2.44z"
              />
              <path
                  data-name="Path 1938"
                  d="M307.13 136.988c-.506 1.33.012 2.318 1.158 2.207a4.127 4.127 0 002.973-2.61c.5-1.33-.022-2.316-1.158-2.206a4.146 4.146 0 00-2.973 2.609z"
              />
              <path
                  data-name="Path 1939"
                  d="M300.563 137.641c-.518 1.33-.012 2.316 1.131 2.2a4.183 4.183 0 002.991-2.615c.508-1.33 0-2.314-1.131-2.2a4.216 4.216 0 00-2.991 2.615z"
              />
              <path
                  data-name="Path 1940"
                  d="M294.011 138.308c-.531 1.332-.037 2.318 1.1 2.2a4.251 4.251 0 003.01-2.622c.52-1.33.027-2.313-1.1-2.2a4.28 4.28 0 00-3.01 2.622z"
              />
              <path
                  data-name="Path 1941"
                  d="M287.474 138.991c-.543 1.33-.06 2.314 1.078 2.2a4.31 4.31 0 003.026-2.627c.533-1.332.05-2.313-1.078-2.194a4.338 4.338 0 00-3.026 2.621z"
              />
              <path
                  data-name="Path 1942"
                  d="M280.951 139.688c-.555 1.332-.083 2.313 1.051 2.192a4.376 4.376 0 003.043-2.632c.546-1.332.075-2.313-1.051-2.191a4.4 4.4 0 00-3.043 2.631z"
              />
              <path
                  data-name="Path 1943"
                  d="M274.444 140.399c-.566 1.332-.107 2.313 1.026 2.189a4.443 4.443 0 003.06-2.637c.556-1.332.1-2.311-1.026-2.187a4.47 4.47 0 00-3.06 2.635z"
              />
              <path
                  data-name="Path 1944"
                  d="M267.958 141.124c-.578 1.333-.13 2.313 1 2.186a4.5 4.5 0 003.075-2.642c.57-1.333.122-2.309-1-2.184a4.536 4.536 0 00-3.075 2.64z"
              />
              <path
                  data-name="Path 1945"
                  d="M261.48 141.862c-.59 1.334-.154 2.311.973 2.182a4.57 4.57 0 003.091-2.647c.58-1.333.145-2.309-.973-2.181a4.6 4.6 0 00-3.091 2.646z"
              />
              <path
                  data-name="Path 1946"
                  d="M255.022 142.616c-.6 1.334-.177 2.309.947 2.177a4.638 4.638 0 003.107-2.652c.592-1.335.167-2.308-.947-2.177a4.657 4.657 0 00-3.107 2.652z"
              />
              <path
                  data-name="Path 1947"
                  d="M248.583 143.381c-.612 1.335-.2 2.309.922 2.176a4.7 4.7 0 003.12-2.659c.6-1.334.192-2.306-.921-2.172a4.726 4.726 0 00-3.121 2.655z"
              />
              <path
                  data-name="Path 1948"
                  d="M242.161 144.162c-.622 1.335-.221 2.308.9 2.171a4.76 4.76 0 003.137-2.662c.613-1.335.212-2.306-.9-2.171a4.806 4.806 0 00-3.137 2.662z"
              />
              <path
                  data-name="Path 1949"
                  d="M235.757 144.955c-.633 1.333-.244 2.306.871 2.167a4.834 4.834 0 003.15-2.669c.625-1.334.236-2.3-.871-2.166a4.86 4.86 0 00-3.15 2.668z"
              />
              <path
                  data-name="Path 1950"
                  d="M229.372 145.761c-.643 1.335-.266 2.3.846 2.164a4.9 4.9 0 003.163-2.672c.635-1.335.257-2.3-.846-2.162a4.921 4.921 0 00-3.163 2.67z"
              />
              <path
                  data-name="Path 1951"
                  d="M223.005 146.58c-.653 1.335-.287 2.3.82 2.162a4.961 4.961 0 003.177-2.679c.647-1.335.279-2.3-.82-2.159a4.994 4.994 0 00-3.177 2.676z"
              />
              <path
                  data-name="Path 1952"
                  d="M216.658 147.412c-.663 1.337-.309 2.3.8 2.157a5.016 5.016 0 003.188-2.682c.658-1.335.3-2.3-.794-2.156a5.055 5.055 0 00-3.194 2.681z"
              />
              <path
                  data-name="Path 1953"
                  d="M210.331 148.256c-.673 1.337-.329 2.3.77 2.156a5.093 5.093 0 003.2-2.687c.667-1.337.323-2.3-.77-2.154a5.129 5.129 0 00-3.2 2.685z"
              />
              <path
                  data-name="Path 1954"
                  d="M204.025 149.114c-.685 1.337-.351 2.3.747 2.152a5.154 5.154 0 003.212-2.692c.678-1.337.344-2.3-.745-2.151a5.2 5.2 0 00-3.214 2.691z"
              />
              <path
                  data-name="Path 1955"
                  d="M197.74 149.982c-.7 1.337-.373 2.3.72 2.149a5.229 5.229 0 003.225-2.7c.687-1.337.364-2.3-.722-2.147a5.254 5.254 0 00-3.223 2.698z"
              />
              <path
                  data-name="Path 1956"
                  d="M191.475 150.864c-.705 1.337-.393 2.3.7 2.144a5.291 5.291 0 003.235-2.7c.7-1.337.384-2.3-.7-2.144a5.31 5.31 0 00-3.235 2.7z"
              />
              <path
                  data-name="Path 1957"
                  d="M185.232 151.757c-.714 1.337-.413 2.3.672 2.142a5.361 5.361 0 003.247-2.7c.707-1.337.4-2.3-.673-2.141a5.385 5.385 0 00-3.246 2.699z"
              />
              <path
                  data-name="Path 1958"
                  d="M179.011 152.662c-.724 1.337-.433 2.294.648 2.137a5.412 5.412 0 003.255-2.707c.717-1.339.426-2.3-.648-2.137a5.441 5.441 0 00-3.255 2.707z"
              />
              <path
                  data-name="Path 1959"
                  d="M172.811 153.578c-.732 1.339-.453 2.294.627 2.136a5.482 5.482 0 003.264-2.712c.727-1.339.446-2.294-.625-2.136a5.521 5.521 0 00-3.266 2.712z"
              />
              <path
                  data-name="Path 1960"
                  d="M166.635 154.506c-.74 1.337-.471 2.293.6 2.132a5.551 5.551 0 003.274-2.717c.734-1.338.465-2.291-.6-2.131a5.585 5.585 0 00-3.274 2.716z"
              />
              <path
                  data-name="Path 1961"
                  d="M160.482 155.445c-.749 1.339-.49 2.291.58 2.127a5.606 5.606 0 003.282-2.719c.744-1.339.483-2.291-.58-2.127a5.638 5.638 0 00-3.282 2.719z"
              />
              <path
                  data-name="Path 1962"
                  d="M154.353 156.392c-.757 1.339-.508 2.291.556 2.126a5.676 5.676 0 003.29-2.724c.752-1.339.5-2.289-.556-2.124a5.711 5.711 0 00-3.29 2.722z"
              />
              <path
                  data-name="Path 1963"
                  d="M148.248 157.353c-.767 1.34-.528 2.289.533 2.122a5.739 5.739 0 003.3-2.726c.759-1.339.52-2.289-.535-2.122a5.771 5.771 0 00-3.298 2.726z"
              />
              <path
                  data-name="Path 1964"
                  d="M65.542 171.796c-.857 1.335-.749 2.266.244 2.077a6.557 6.557 0 003.347-2.761c.852-1.335.742-2.266-.249-2.077a6.581 6.581 0 00-3.342 2.761z"
              />
              <path
                  data-name="Path 1965"
                  d="M54.183 173.99c-.866 1.335-.774 2.263.209 2.07a6.642 6.642 0 003.344-2.762c.864-1.334.769-2.263-.211-2.07a6.684 6.684 0 00-3.342 2.762z"
              />
              <path
                  data-name="Path 1966"
                  d="M37.386 177.332c-.877 1.332-.809 2.254.157 2.06a6.791 6.791 0 003.335-2.764c.876-1.334.8-2.256-.159-2.06a6.81 6.81 0 00-3.333 2.764z"
              />
              <path
                  data-name="Path 1967"
                  d="M991.884 149.972a6.163 6.163 0 003.332 2.717c1.008.162 1.143-.79.307-2.126a6.192 6.192 0 00-3.332-2.716c-1.002-.162-1.141.79-.307 2.125z"
              />
              <path
                  data-name="Path 1968"
                  d="M932.629 141.107a5.557 5.557 0 003.27 2.679c1.05.144 1.283-.82.52-2.154a5.572 5.572 0 00-3.269-2.677c-1.047-.146-1.276.817-.521 2.152z"
              />
              <path
                  data-name="Path 1969"
                  d="M926.576 140.28a5.475 5.475 0 003.262 2.674c1.054.145 1.3-.822.541-2.156a5.519 5.519 0 00-3.26-2.674c-1.043-.141-1.29.827-.543 2.156z"
              />
              <path
                  data-name="Path 1970"
                  d="M920.501 139.464a5.429 5.429 0 003.252 2.67c1.058.14 1.31-.827.566-2.159a5.452 5.452 0 00-3.252-2.669c-1.051-.14-1.303.826-.566 2.158z"
              />
              <path
                  data-name="Path 1971"
                  d="M914.405 138.661a5.347 5.347 0 003.242 2.665c1.061.14 1.325-.829.59-2.162a5.4 5.4 0 00-3.24-2.665c-1.057-.136-1.323.83-.592 2.162z"
              />
              <path
                  data-name="Path 1972"
                  d="M908.287 137.87a5.3 5.3 0 003.232 2.66c1.064.135 1.339-.832.613-2.166a5.322 5.322 0 00-3.232-2.659c-1.055-.134-1.332.832-.613 2.165z"
              />
              <path
                  data-name="Path 1973"
                  d="M902.147 137.09a5.233 5.233 0 003.222 2.657c1.068.135 1.354-.837.637-2.169a5.244 5.244 0 00-3.22-2.654c-1.062-.134-1.35.834-.639 2.166z"
              />
              <path
                  data-name="Path 1974"
                  d="M895.989 136.323a5.158 5.158 0 003.21 2.652c1.071.132 1.367-.841.66-2.171a5.188 5.188 0 00-3.208-2.65c-1.064-.132-1.362.839-.662 2.169z"
              />
              <path
                  data-name="Path 1975"
                  d="M889.81 135.569a5.089 5.089 0 003.2 2.645c1.075.13 1.382-.844.685-2.174a5.128 5.128 0 00-3.2-2.644c-1.068-.13-1.374.843-.685 2.173z"
              />
              <path
                  data-name="Path 1976"
                  d="M883.612 134.827a5.027 5.027 0 003.185 2.64c1.078.129 1.4-.846.71-2.177a5.058 5.058 0 00-3.185-2.639c-1.069-.128-1.391.846-.71 2.176z"
              />
              <path
                  data-name="Path 1977"
                  d="M877.392 134.097a4.97 4.97 0 003.173 2.637c1.083.127 1.414-.851.735-2.181a4.992 4.992 0 00-3.172-2.634c-1.074-.125-1.405.849-.736 2.178z"
              />
              <path
                  data-name="Path 1978"
                  d="M871.16 133.38a4.892 4.892 0 003.16 2.632c1.084.124 1.427-.854.76-2.182a4.924 4.924 0 00-3.16-2.629c-1.082-.123-1.423.852-.76 2.179z"
              />
              <path
                  data-name="Path 1979"
                  d="M864.899 132.678a4.83 4.83 0 003.146 2.625c1.089.122 1.441-.857.785-2.186a4.864 4.864 0 00-3.147-2.624c-1.08-.121-1.432.857-.784 2.185z"
              />
              <path
                  data-name="Path 1980"
                  d="M858.624 131.988a4.763 4.763 0 003.133 2.62c1.093.12 1.457-.861.81-2.189a4.8 4.8 0 00-3.132-2.619c-1.084-.118-1.446.86-.811 2.188z"
              />
              <path
                  data-name="Path 1981"
                  d="M852.333 131.311a4.7 4.7 0 003.118 2.615c1.1.117 1.47-.864.836-2.192a4.73 4.73 0 00-3.117-2.614c-1.088-.115-1.462.865-.837 2.191z"
              />
              <path
                  data-name="Path 1982"
                  d="M846.022 130.648a4.629 4.629 0 003.1 2.61c1.1.115 1.486-.869.862-2.2a4.662 4.662 0 00-3.1-2.607c-1.089-.109-1.477.872-.862 2.197z"
              />
              <path
                  data-name="Path 1983"
                  d="M839.695 129.999a4.563 4.563 0 003.086 2.6c1.1.114 1.5-.872.889-2.2a4.6 4.6 0 00-3.088-2.6c-1.094-.108-1.49.873-.887 2.2z"
              />
              <path
                  data-name="Path 1984"
                  d="M833.351 129.362a4.5 4.5 0 003.073 2.6c1.1.11 1.514-.877.914-2.2a4.527 4.527 0 00-3.073-2.6c-1.095-.111-1.506.875-.914 2.2z"
              />
              <path
                  data-name="Path 1985"
                  d="M826.991 128.741a4.437 4.437 0 003.056 2.6c1.108.107 1.529-.881.941-2.206a4.464 4.464 0 00-3.056-2.592c-1.1-.112-1.521.874-.941 2.198z"
              />
              <path
                  data-name="Path 1986"
                  d="M820.613 128.135a4.374 4.374 0 003.04 2.589c1.109.1 1.544-.886.966-2.209a4.4 4.4 0 00-3.04-2.585c-1.101-.106-1.534.881-.966 2.205z"
              />
              <path
                  data-name="Path 1987"
                  d="M814.22 127.541a4.308 4.308 0 003.023 2.582c1.113.1 1.559-.889.994-2.211a4.336 4.336 0 00-3.023-2.58c-1.104-.102-1.549.884-.994 2.209z"
              />
              <path
                  data-name="Path 1988"
                  d="M807.812 126.961a4.251 4.251 0 003.006 2.578c1.116.1 1.573-.894 1.021-2.216a4.273 4.273 0 00-3.006-2.575c-1.108-.099-1.568.891-1.021 2.213z"
              />
              <path
                  data-name="Path 1989"
                  d="M801.389 126.398a4.178 4.178 0 002.99 2.57c1.12.1 1.587-.9 1.046-2.218a4.213 4.213 0 00-2.988-2.57c-1.111-.096-1.58.896-1.048 2.218z"
              />
              <path
                  data-name="Path 1990"
                  d="M794.95 125.847a4.119 4.119 0 002.971 2.567c1.123.1 1.6-.9 1.075-2.223a4.146 4.146 0 00-2.97-2.563c-1.118-.096-1.594.899-1.076 2.219z"
              />
              <path
                  data-name="Path 1991"
                  d="M788.497 125.316a4.051 4.051 0 002.953 2.56c1.123.094 1.618-.9 1.1-2.226a4.081 4.081 0 00-2.953-2.557c-1.113-.096-1.606.899-1.1 2.223z"
              />
              <path
                  data-name="Path 1992"
                  d="M782.028 124.792a3.992 3.992 0 002.934 2.555c1.125.09 1.633-.909 1.13-2.231a4.02 4.02 0 00-2.934-2.55c-1.114-.09-1.62.906-1.13 2.226z"
              />
              <path
                  data-name="Path 1993"
                  d="M775.55 124.288a3.928 3.928 0 002.914 2.548c1.13.087 1.648-.912 1.158-2.233a3.96 3.96 0 00-2.916-2.547c-1.119-.086-1.637.912-1.156 2.232z"
              />
              <path
                  data-name="Path 1994"
                  d="M769.056 123.797a3.867 3.867 0 002.9 2.543c1.131.085 1.661-.916 1.183-2.236a3.889 3.889 0 00-2.894-2.54c-1.127-.085-1.657.914-1.189 2.233z"
              />
              <path
                  data-name="Path 1995"
                  d="M762.549 123.321a3.809 3.809 0 002.876 2.537c1.133.084 1.678-.921 1.213-2.239a3.839 3.839 0 00-2.878-2.535c-1.125-.082-1.666.921-1.211 2.237z"
              />
              <path
                  data-name="Path 1996"
                  d="M756.03 122.862a3.748 3.748 0 002.856 2.532c1.136.08 1.691-.926 1.24-2.244a3.771 3.771 0 00-2.856-2.528c-1.126-.081-1.683.921-1.24 2.24z"
              />
              <path
                  data-name="Path 1997"
                  d="M749.498 122.418a3.686 3.686 0 002.836 2.525c1.138.079 1.706-.929 1.268-2.248a3.714 3.714 0 00-2.836-2.522c-1.127-.076-1.696.928-1.268 2.245z"
              />
              <path
                  data-name="Path 1998"
                  d="M742.957 121.99a3.628 3.628 0 002.816 2.518c1.141.075 1.72-.936 1.3-2.251a3.649 3.649 0 00-2.816-2.517c-1.138-.075-1.718.932-1.3 2.25z"
              />
              <path
                  data-name="Path 1999"
                  d="M736.4 121.575a3.571 3.571 0 002.794 2.515c1.141.07 1.736-.939 1.324-2.258a3.6 3.6 0 00-2.794-2.51c-1.134-.071-1.727.938-1.324 2.253z"
              />
              <path
                  data-name="Path 2000"
                  d="M729.833 121.177a3.512 3.512 0 002.774 2.508c1.145.069 1.75-.944 1.352-2.259a3.538 3.538 0 00-2.774-2.5c-1.135-.073-1.74.936-1.352 2.251z"
              />
              <path
                  data-name="Path 2001"
                  d="M723.257 120.797a3.457 3.457 0 002.754 2.5c1.145.065 1.763-.947 1.379-2.264a3.476 3.476 0 00-2.752-2.5c-1.138-.062-1.755.949-1.381 2.264z"
              />
              <path
                  data-name="Path 2002"
                  d="M716.668 120.43a3.4 3.4 0 002.732 2.5c1.148.063 1.778-.954 1.407-2.269a3.423 3.423 0 00-2.731-2.492c-1.138-.066-1.768.946-1.408 2.261z"
              />
              <path
                  data-name="Path 2003"
                  d="M710.068 120.081a3.347 3.347 0 002.709 2.49c1.15.06 1.793-.958 1.437-2.273a3.367 3.367 0 00-2.711-2.487c-1.135-.059-1.778.955-1.435 2.27z"
              />
              <path
                  data-name="Path 2004"
                  d="M703.463 119.748a3.293 3.293 0 002.687 2.485c1.153.057 1.806-.964 1.464-2.278a3.307 3.307 0 00-2.685-2.48c-1.144-.059-1.799.957-1.466 2.273z"
              />
              <path
                  data-name="Path 2005"
                  d="M696.848 119.433a3.236 3.236 0 002.664 2.478c1.153.053 1.823-.968 1.492-2.281a3.255 3.255 0 00-2.664-2.475c-1.141-.058-1.81.961-1.492 2.278z"
              />
              <path
                  data-name="Path 2006"
                  d="M690.222 119.128a3.18 3.18 0 002.642 2.473c1.155.052 1.835-.973 1.521-2.286a3.2 3.2 0 00-2.642-2.468c-1.145-.052-1.825.964-1.521 2.281z"
              />
              <path
                  data-name="Path 2007"
                  d="M683.588 118.842a3.127 3.127 0 002.619 2.467c1.156.05 1.85-.976 1.549-2.289a3.148 3.148 0 00-2.619-2.463c-1.147-.05-1.84.973-1.549 2.285z"
              />
              <path
                  data-name="Path 2008"
                  d="M676.945 118.573a3.075 3.075 0 002.6 2.462c1.158.045 1.865-.981 1.577-2.294a3.093 3.093 0 00-2.6-2.456c-1.143-.048-1.853.976-1.577 2.288z"
              />
              <path
                  data-name="Path 2009"
                  d="M670.294 118.32a3.026 3.026 0 002.573 2.455c1.158.045 1.877-.986 1.6-2.3a3.04 3.04 0 00-2.572-2.451c-1.143-.043-1.861.986-1.601 2.296z"
              />
              <path
                  data-name="Path 2010"
                  d="M663.639 118.085a2.978 2.978 0 002.548 2.45c1.161.04 1.893-.991 1.634-2.3a2.992 2.992 0 00-2.548-2.445c-1.15-.046-1.884.983-1.634 2.295z"
              />
              <path
                  data-name="Path 2011"
                  d="M656.972 117.864a2.926 2.926 0 002.525 2.445 1.757 1.757 0 001.663-2.308 2.948 2.948 0 00-2.525-2.441c-1.15-.037-1.895.994-1.663 2.304z"
              />
              <path
                  data-name="Path 2012"
                  d="M650.301 117.662a2.874 2.874 0 002.5 2.438 1.782 1.782 0 001.691-2.313 2.892 2.892 0 00-2.5-2.435 1.787 1.787 0 00-1.691 2.31z"
              />
              <path
                  data-name="Path 2013"
                  d="M643.624 117.475a2.827 2.827 0 002.475 2.433 1.81 1.81 0 001.719-2.318 2.839 2.839 0 00-2.477-2.428 1.815 1.815 0 00-1.717 2.313z"
              />
              <path
                  data-name="Path 2014"
                  d="M636.939 117.304a2.776 2.776 0 002.451 2.426 1.835 1.835 0 001.746-2.321 2.793 2.793 0 00-2.451-2.423 1.843 1.843 0 00-1.746 2.318z"
              />
              <path
                  data-name="Path 2015"
                  d="M630.25 117.152a2.73 2.73 0 002.427 2.42 1.863 1.863 0 001.775-2.326 2.746 2.746 0 00-2.426-2.416 1.871 1.871 0 00-1.776 2.322z"
              />
              <path
                  data-name="Path 2016"
                  d="M623.554 117.015a2.687 2.687 0 002.4 2.415 1.893 1.893 0 001.8-2.331 2.7 2.7 0 00-2.4-2.411 1.9 1.9 0 00-1.8 2.327z"
              />
              <path
                  data-name="Path 2017"
                  d="M616.855 116.895a2.641 2.641 0 002.378 2.41 1.924 1.924 0 001.831-2.336 2.654 2.654 0 00-2.378-2.4 1.931 1.931 0 00-1.831 2.326z"
              />
              <path
                  data-name="Path 2018"
                  d="M610.15 116.793a2.6 2.6 0 002.353 2.4 1.953 1.953 0 001.86-2.341 2.612 2.612 0 00-2.353-2.4 1.963 1.963 0 00-1.86 2.341z"
              />
              <path
                  data-name="Path 2019"
                  d="M603.443 116.706a2.552 2.552 0 002.326 2.4 1.985 1.985 0 001.888-2.346 2.565 2.565 0 00-2.326-2.395 1.993 1.993 0 00-1.888 2.341z"
              />
              <path
                  data-name="Path 2020"
                  d="M596.732 116.638a2.509 2.509 0 002.3 2.393 2.015 2.015 0 001.915-2.351 2.523 2.523 0 00-2.3-2.39 2.028 2.028 0 00-1.915 2.348z"
              />
              <path
                  data-name="Path 2021"
                  d="M590.016 116.586a2.467 2.467 0 002.274 2.388 2.05 2.05 0 001.943-2.358 2.481 2.481 0 00-2.274-2.383 2.061 2.061 0 00-1.943 2.353z"
              />
              <path
                  data-name="Path 2022"
                  d="M576.577 116.536a2.385 2.385 0 002.219 2.372 2.119 2.119 0 002-2.366 2.4 2.4 0 00-2.222-2.373 2.127 2.127 0 00-1.997 2.367z"
              />
              <path
                  data-name="Path 2023"
                  d="M569.855 116.534a2.345 2.345 0 002.2 2.371 2.155 2.155 0 002.027-2.373 2.358 2.358 0 00-2.2-2.366 2.162 2.162 0 00-2.027 2.368z"
              />
              <path
                  data-name="Path 2024"
                  d="M529.487 116.879a2.122 2.122 0 002.035 2.34 2.383 2.383 0 002.191-2.4 2.132 2.132 0 00-2.037-2.336 2.392 2.392 0 00-2.189 2.396z"
              />
              <path
                  data-name="Path 2025"
                  d="M522.76 116.996a2.088 2.088 0 002.01 2.335 2.421 2.421 0 002.216-2.41 2.1 2.1 0 00-2.009-2.331 2.437 2.437 0 00-2.217 2.406z"
              />
              <path
                  data-name="Path 2026"
                  d="M516.034 117.132a2.054 2.054 0 001.982 2.329 2.464 2.464 0 002.243-2.416 2.064 2.064 0 00-1.982-2.326 2.475 2.475 0 00-2.243 2.413z"
              />
              <path
                  data-name="Path 2027"
                  d="M509.308 117.282a2.021 2.021 0 001.953 2.324 2.5 2.5 0 002.269-2.421 2.029 2.029 0 00-1.953-2.321 2.519 2.519 0 00-2.269 2.418z"
              />
              <path
                  data-name="Path 2028"
                  d="M502.578 117.451a1.99 1.99 0 001.928 2.319 2.55 2.55 0 002.3-2.426 2 2 0 00-1.928-2.316 2.563 2.563 0 00-2.3 2.423z"
              />
              <path
                  data-name="Path 2029"
                  d="M342.669 126.46c-.444 1.32.124 2.313 1.267 2.216a3.812 3.812 0 002.859-2.568c.436-1.32-.132-2.309-1.265-2.212a3.841 3.841 0 00-2.861 2.564z"
              />
              <path
                  data-name="Path 2030"
                  d="M336.11 127.035c-.458 1.322.1 2.313 1.238 2.212a3.878 3.878 0 002.881-2.575c.448-1.32-.107-2.308-1.238-2.207a3.894 3.894 0 00-2.881 2.57z"
              />
              <path
                  data-name="Path 2031"
                  d="M329.562 127.627c-.471 1.322.072 2.309 1.212 2.208a3.936 3.936 0 002.9-2.58c.461-1.322-.082-2.308-1.212-2.2a3.958 3.958 0 00-2.9 2.572z"
              />
              <path
                  data-name="Path 2032"
                  d="M323.027 128.231c-.485 1.322.047 2.309 1.185 2.2a3.992 3.992 0 002.918-2.587c.475-1.32-.055-2.3-1.183-2.2a4.014 4.014 0 00-2.92 2.587z"
              />
              <path
                  data-name="Path 2033"
                  d="M316.504 128.852c-.5 1.324.023 2.309 1.158 2.2a4.057 4.057 0 002.938-2.592c.486-1.322-.032-2.3-1.158-2.2a4.087 4.087 0 00-2.938 2.592z"
              />
              <path
                  data-name="Path 2034"
                  d="M309.992 129.488c-.508 1.324 0 2.308 1.131 2.2a4.115 4.115 0 002.956-2.6c.5-1.324-.007-2.3-1.13-2.194a4.15 4.15 0 00-2.957 2.594z"
              />
              <path
                  data-name="Path 2035"
                  d="M303.496 130.138c-.52 1.323-.025 2.306 1.1 2.192a4.189 4.189 0 002.976-2.6c.511-1.322.017-2.3-1.1-2.189a4.213 4.213 0 00-2.976 2.597z"
              />
              <path
                  data-name="Path 2036"
                  d="M297.013 130.804c-.533 1.324-.052 2.3 1.078 2.189a4.253 4.253 0 002.993-2.609c.523-1.325.042-2.3-1.078-2.187a4.274 4.274 0 00-2.993 2.607z"
              />
              <path
                  data-name="Path 2037"
                  d="M290.54 131.482c-.545 1.325-.074 2.3 1.053 2.186a4.309 4.309 0 003.01-2.614c.536-1.325.065-2.3-1.051-2.184a4.343 4.343 0 00-3.012 2.612z"
              />
              <path
                  data-name="Path 2038"
                  d="M284.089 132.175c-.558 1.327-.1 2.3 1.024 2.182a4.375 4.375 0 003.028-2.619c.548-1.325.089-2.3-1.026-2.181a4.4 4.4 0 00-3.026 2.618z"
              />
              <path
                  data-name="Path 2039"
                  d="M277.654 132.884c-.57 1.325-.122 2.3 1 2.179a4.443 4.443 0 003.043-2.625c.561-1.325.114-2.3-1-2.177a4.473 4.473 0 00-3.043 2.623z"
              />
              <path
                  data-name="Path 2040"
                  d="M271.224 133.606c-.58 1.327-.145 2.3.974 2.176a4.51 4.51 0 003.06-2.63c.572-1.327.137-2.3-.973-2.174a4.538 4.538 0 00-3.061 2.628z"
              />
              <path
                  data-name="Path 2041"
                  d="M264.815 134.341c-.592 1.329-.169 2.3.947 2.172a4.572 4.572 0 003.076-2.635c.583-1.327.159-2.3-.947-2.171a4.6 4.6 0 00-3.076 2.634z"
              />
              <path
                  data-name="Path 2042"
                  d="M258.42 135.091c-.6 1.329-.191 2.3.922 2.169a4.641 4.641 0 003.092-2.642c.595-1.327.182-2.3-.922-2.166a4.662 4.662 0 00-3.092 2.639z"
              />
              <path
                  data-name="Path 2043"
                  d="M252.045 135.855c-.613 1.328-.212 2.3.9 2.166a4.7 4.7 0 003.107-2.647c.607-1.328.206-2.3-.9-2.164a4.74 4.74 0 00-3.107 2.645z"
              />
              <path
                  data-name="Path 2044"
                  d="M245.686 136.631c-.627 1.328-.237 2.3.871 2.162a4.772 4.772 0 003.122-2.652c.617-1.328.227-2.3-.871-2.161a4.8 4.8 0 00-3.122 2.651z"
              />
              <path
                  data-name="Path 2045"
                  d="M239.346 137.423c-.637 1.33-.257 2.3.846 2.159a4.837 4.837 0 003.137-2.657c.628-1.33.249-2.294-.846-2.157a4.864 4.864 0 00-3.137 2.655z"
              />
              <path
                  data-name="Path 2046"
                  d="M233.018 138.223c-.647 1.33-.279 2.294.82 2.156a4.9 4.9 0 003.15-2.662c.64-1.332.272-2.294-.82-2.154a4.927 4.927 0 00-3.15 2.66z"
              />
              <path
                  data-name="Path 2047"
                  d="M226.712 139.042c-.658 1.332-.3 2.3.8 2.154a4.96 4.96 0 003.163-2.669c.65-1.33.294-2.293-.8-2.151a5 5 0 00-3.163 2.666z"
              />
              <path
                  data-name="Path 2048"
                  d="M220.423 139.872c-.668 1.33-.323 2.293.772 2.149a5.033 5.033 0 003.175-2.672c.66-1.33.316-2.293-.77-2.147a5.059 5.059 0 00-3.177 2.67z"
              />
              <path
                  data-name="Path 2049"
                  d="M214.154 140.715c-.677 1.332-.344 2.294.747 2.147a5.1 5.1 0 003.188-2.677c.672-1.332.338-2.291-.745-2.146a5.133 5.133 0 00-3.19 2.676z"
              />
              <path
                  data-name="Path 2050"
                  d="M207.899 141.569c-.688 1.332-.364 2.293.722 2.144a5.169 5.169 0 003.2-2.682c.68-1.332.356-2.291-.724-2.142a5.2 5.2 0 00-3.198 2.68z"
              />
              <path
                  data-name="Path 2051"
                  d="M201.674 142.436c-.7 1.332-.386 2.291.7 2.141a5.237 5.237 0 003.213-2.687c.69-1.332.378-2.289-.7-2.139a5.265 5.265 0 00-3.213 2.685z"
              />
              <path
                  data-name="Path 2052"
                  d="M195.463 143.315c-.707 1.334-.406 2.289.675 2.137a5.3 5.3 0 003.224-2.692c.7-1.332.4-2.288-.675-2.136a5.333 5.333 0 00-3.224 2.691z"
              />
              <path
                  data-name="Path 2053"
                  d="M189.273 144.21c-.717 1.334-.426 2.289.65 2.136a5.371 5.371 0 003.235-2.7c.71-1.334.418-2.288-.652-2.132a5.4 5.4 0 00-3.233 2.696z"
              />
              <path
                  data-name="Path 2054"
                  d="M183.103 145.109c-.725 1.334-.444 2.288.628 2.131a5.428 5.428 0 003.244-2.7c.719-1.332.438-2.288-.628-2.129a5.454 5.454 0 00-3.244 2.698z"
              />
              <path
                  data-name="Path 2055"
                  d="M176.955 146.024c-.735 1.334-.465 2.288.6 2.129a5.5 5.5 0 003.254-2.705c.729-1.334.458-2.286-.6-2.127a5.53 5.53 0 00-3.254 2.703z"
              />
              <path
                  data-name="Path 2056"
                  d="M170.829 146.949c-.744 1.335-.485 2.288.582 2.126a5.557 5.557 0 003.264-2.709c.737-1.334.476-2.284-.582-2.124a5.587 5.587 0 00-3.264 2.707z"
              />
              <path
                  data-name="Path 2057"
                  d="M164.724 147.886c-.752 1.335-.5 2.286.558 2.122a5.625 5.625 0 003.274-2.712c.745-1.335.495-2.284-.56-2.122a5.662 5.662 0 00-3.272 2.712z"
              />
              <path
                  data-name="Path 2058"
                  d="M158.642 148.836c-.76 1.335-.52 2.283.536 2.119a5.689 5.689 0 003.282-2.717c.754-1.333.513-2.283-.538-2.117a5.722 5.722 0 00-3.28 2.715z"
              />
              <path
                  data-name="Path 2059"
                  d="M152.585 149.795c-.769 1.335-.54 2.283.513 2.116a5.743 5.743 0 003.289-2.721c.764-1.335.531-2.281-.515-2.116a5.781 5.781 0 00-3.287 2.721z"
              />
              <path
                  data-name="Path 2060"
                  d="M146.549 150.764c-.777 1.335-.556 2.281.493 2.114a5.825 5.825 0 003.3-2.724c.77-1.337.55-2.283-.493-2.112a5.846 5.846 0 00-3.3 2.722z"
              />
              <path
                  data-name="Path 2061"
                  d="M140.536 151.745c-.784 1.335-.573 2.281.471 2.111a5.881 5.881 0 003.3-2.727c.779-1.335.568-2.281-.471-2.111a5.911 5.911 0 00-3.3 2.727z"
              />
              <path
                  data-name="Path 2062"
                  d="M134.548 152.736c-.792 1.337-.592 2.279.45 2.107a5.944 5.944 0 003.309-2.731c.785-1.337.583-2.279-.451-2.107a5.965 5.965 0 00-3.308 2.731z"
              />
              <path
                  data-name="Path 2063"
                  d="M70.387 164.248c-.859 1.335-.754 2.263.236 2.074a6.571 6.571 0 003.339-2.759c.856-1.335.749-2.263-.236-2.074a6.59 6.59 0 00-3.339 2.759z"
              />
              <path
                  data-name="Path 2064"
                  d="M64.721 165.346c-.862 1.334-.767 2.261.217 2.07a6.61 6.61 0 003.339-2.761c.859-1.333.762-2.263-.219-2.072a6.653 6.653 0 00-3.337 2.763z"
              />
              <path
                  data-name="Path 2065"
                  d="M59.085 166.448c-.869 1.333-.78 2.259.2 2.069a6.675 6.675 0 003.337-2.762c.864-1.334.774-2.261-.2-2.069a6.692 6.692 0 00-3.337 2.762z"
              />
              <path
                  data-name="Path 2066"
                  d="M53.477 167.557c-.872 1.334-.79 2.258.182 2.065a6.713 6.713 0 003.335-2.764c.869-1.334.785-2.258-.184-2.065a6.738 6.738 0 00-3.333 2.764z"
              />
              <path
                  data-name="Path 2067"
                  d="M47.899 168.678c-.876 1.334-.8 2.254.165 2.062a6.76 6.76 0 003.334-2.764c.872-1.334.8-2.256-.169-2.062a6.79 6.79 0 00-3.33 2.764z"
              />
              <path
                  data-name="Path 2068"
                  d="M42.352 169.803c-.879 1.332-.812 2.253.15 2.059a6.815 6.815 0 003.329-2.766c.877-1.332.807-2.253-.152-2.059a6.833 6.833 0 00-3.327 2.766z"
              />
              <path
                  data-name="Path 2069"
                  d="M36.836 170.934c-.882 1.33-.824 2.251.134 2.055a6.852 6.852 0 003.327-2.767c.879-1.33.817-2.251-.137-2.054a6.882 6.882 0 00-3.324 2.766z"
              />
              <path
                  data-name="Path 2070"
                  d="M1004.355 145.272a6.342 6.342 0 003.332 2.721c.989.169 1.093-.779.237-2.112a6.364 6.364 0 00-3.331-2.721c-.98-.164-1.087.781-.238 2.112z"
              />
              <path
                  data-name="Path 2071"
                  d="M952.146 136.987a5.81 5.81 0 003.292 2.687c1.024.152 1.212-.8.413-2.136a5.837 5.837 0 00-3.289-2.685c-1.021-.153-1.207.804-.416 2.134z"
              />
              <path
                  data-name="Path 2072"
                  d="M946.228 136.118a5.757 5.757 0 003.284 2.684c1.033.15 1.225-.809.436-2.139a5.783 5.783 0 00-3.284-2.682c-1.023-.149-1.215.805-.436 2.137z"
              />
              <path
                  data-name="Path 2073"
                  d="M940.289 135.262a5.7 5.7 0 003.28 2.679c1.033.149 1.237-.812.456-2.142a5.723 5.723 0 00-3.275-2.677c-1.03-.148-1.234.808-.461 2.14z"
              />
              <path
                  data-name="Path 2074"
                  d="M934.329 134.414a5.617 5.617 0 003.267 2.674c1.039.147 1.252-.814.48-2.144a5.658 5.658 0 00-3.267-2.672c-1.03-.145-1.247.812-.48 2.142z"
              />
              <path
                  data-name="Path 2075"
                  d="M928.346 133.579a5.552 5.552 0 003.259 2.669c1.043.145 1.265-.817.5-2.146a5.592 5.592 0 00-3.259-2.667c-1.033-.144-1.257.815-.5 2.144z"
              />
              <path
                  data-name="Path 2076"
                  d="M922.343 132.755a5.493 5.493 0 003.249 2.665c1.044.142 1.28-.82.523-2.149a5.52 5.52 0 00-3.249-2.664c-1.036-.141-1.273.819-.523 2.148z"
              />
              <path
                  data-name="Path 2077"
                  d="M916.32 131.943a5.427 5.427 0 003.239 2.66c1.049.14 1.3-.824.547-2.152a5.448 5.448 0 00-3.239-2.657c-1.042-.141-1.287.82-.547 2.149z"
              />
              <path
                  data-name="Path 2078"
                  d="M910.275 131.143a5.359 5.359 0 003.229 2.655c1.053.139 1.308-.827.57-2.154a5.4 5.4 0 00-3.229-2.654c-1.044-.138-1.3.826-.57 2.153z"
              />
              <path
                  data-name="Path 2079"
                  d="M904.211 130.353a5.287 5.287 0 003.219 2.65c1.056.139 1.32-.829.593-2.156a5.329 5.329 0 00-3.219-2.649c-1.049-.133-1.315.828-.593 2.155z"
              />
              <path
                  data-name="Path 2080"
                  d="M898.127 129.578a5.229 5.229 0 003.207 2.645c1.06.135 1.337-.832.617-2.159a5.255 5.255 0 00-3.207-2.644c-1.052-.133-1.329.833-.617 2.158z"
              />
              <path
                  data-name="Path 2081"
                  d="M892.02 128.815a5.157 5.157 0 003.2 2.64c1.064.132 1.349-.836.64-2.162a5.188 5.188 0 00-3.2-2.637c-1.051-.132-1.34.834-.64 2.159z"
              />
              <path
                  data-name="Path 2082"
                  d="M885.901 128.064a5.092 5.092 0 003.183 2.634c1.066.13 1.364-.839.664-2.164a5.123 5.123 0 00-3.182-2.632c-1.061-.131-1.357.841-.665 2.162z"
              />
              <path
                  data-name="Path 2083"
                  d="M879.76 127.325a5.02 5.02 0 003.172 2.629c1.07.129 1.377-.842.687-2.167a5.054 5.054 0 00-3.17-2.627c-1.062-.128-1.371.842-.689 2.165z"
              />
              <path
                  data-name="Path 2084"
                  d="M873.6 126.6a4.959 4.959 0 003.158 2.624c1.071.125 1.392-.846.712-2.171a4.987 4.987 0 00-3.157-2.622c-1.066-.123-1.383.846-.713 2.169z"
              />
              <path
                  data-name="Path 2085"
                  d="M867.422 125.887a4.9 4.9 0 003.145 2.62c1.076.122 1.407-.851.737-2.174a4.927 4.927 0 00-3.143-2.617c-1.066-.122-1.402.85-.739 2.171z"
              />
              <path
                  data-name="Path 2086"
                  d="M861.227 125.184a4.832 4.832 0 003.132 2.614c1.08.12 1.417-.854.762-2.176a4.863 4.863 0 00-3.132-2.612c-1.069-.116-1.414.856-.762 2.174z"
              />
              <path
                  data-name="Path 2087"
                  d="M855.015 124.502a4.765 4.765 0 003.117 2.609c1.081.119 1.435-.857.787-2.179a4.787 4.787 0 00-3.115-2.605c-1.075-.119-1.428.853-.789 2.175z"
              />
              <path
                  data-name="Path 2088"
                  d="M848.787 123.828a4.693 4.693 0 003.1 2.6c1.085.115 1.449-.861.812-2.182a4.722 4.722 0 00-3.1-2.6c-1.077-.109-1.443.862-.812 2.182z"
              />
              <path
                  data-name="Path 2089"
                  d="M842.538 123.171a4.634 4.634 0 003.088 2.6c1.088.115 1.462-.866.837-2.184a4.66 4.66 0 00-3.086-2.6c-1.078-.116-1.456.863-.839 2.184z"
              />
              <path
                  data-name="Path 2090"
                  d="M836.273 122.524a4.564 4.564 0 003.07 2.59c1.091.112 1.479-.867.866-2.187a4.591 4.591 0 00-3.072-2.589c-1.079-.11-1.464.872-.864 2.186z"
              />
              <path
                  data-name="Path 2091"
                  d="M829.996 121.893a4.5 4.5 0 003.056 2.585c1.094.109 1.491-.872.889-2.191a4.527 4.527 0 00-3.055-2.583c-1.084-.108-1.483.871-.89 2.189z"
              />
              <path
                  data-name="Path 2092"
                  d="M823.704 121.275a4.43 4.43 0 003.038 2.58c1.1.107 1.507-.876.917-2.194a4.464 4.464 0 00-3.04-2.577c-1.086-.105-1.495.874-.915 2.191z"
              />
              <path
                  data-name="Path 2093"
                  d="M817.394 120.671a4.362 4.362 0 003.021 2.573c1.1.105 1.521-.879.942-2.2a4.391 4.391 0 00-3.021-2.572c-1.089-.099-1.512.882-.942 2.199z"
              />
              <path
                  data-name="Path 2094"
                  d="M811.067 120.082a4.305 4.305 0 003 2.568c1.1.1 1.536-.884.969-2.2a4.323 4.323 0 00-3-2.565c-1.091-.101-1.525.882-.969 2.197z"
              />
              <path
                  data-name="Path 2095"
                  d="M804.725 119.506a4.239 4.239 0 002.988 2.563c1.1.1 1.552-.889 1-2.2a4.268 4.268 0 00-2.988-2.56c-1.1-.1-1.541.884-1 2.197z"
              />
              <path
                  data-name="Path 2096"
                  d="M798.377 118.947a4.176 4.176 0 002.969 2.557c1.108.1 1.566-.892 1.023-2.207a4.2 4.2 0 00-2.969-2.553c-1.1-.098-1.56.888-1.023 2.203z"
              />
              <path
                  data-name="Path 2097"
                  d="M792.006 118.4a4.11 4.11 0 002.953 2.55c1.11.1 1.579-.9 1.049-2.209a4.143 4.143 0 00-2.953-2.548c-1.099-.094-1.57.893-1.049 2.207z"
              />
              <path
                  data-name="Path 2098"
                  d="M785.624 117.87a4.049 4.049 0 002.934 2.545c1.111.092 1.593-.9 1.076-2.212a4.069 4.069 0 00-2.933-2.542c-1.102-.093-1.586.895-1.077 2.209z"
              />
              <path
                  data-name="Path 2099"
                  d="M779.23 117.352a3.985 3.985 0 002.916 2.538c1.113.09 1.608-.9 1.1-2.216a4.011 4.011 0 00-2.914-2.537c-1.102-.087-1.597.904-1.102 2.215z"
              />
              <path
                  data-name="Path 2100"
                  d="M772.823 116.852a3.919 3.919 0 002.9 2.532c1.116.087 1.624-.907 1.13-2.219a3.946 3.946 0 00-2.894-2.53c-1.113-.086-1.619.905-1.136 2.217z"
              />
              <path
                  data-name="Path 2101"
                  d="M766.403 116.363a3.86 3.86 0 002.876 2.528c1.12.084 1.636-.912 1.158-2.224a3.885 3.885 0 00-2.876-2.523c-1.11-.084-1.628.909-1.158 2.219z"
              />
              <path
                  data-name="Path 2102"
                  d="M759.969 115.892a3.8 3.8 0 002.856 2.522c1.121.082 1.653-.916 1.187-2.228a3.82 3.82 0 00-2.856-2.517c-1.112-.081-1.644.913-1.187 2.223z"
              />
              <path
                  data-name="Path 2103"
                  d="M753.524 115.436a3.739 3.739 0 002.837 2.515c1.123.08 1.666-.919 1.213-2.229a3.76 3.76 0 00-2.836-2.512c-1.114-.079-1.657.917-1.214 2.226z"
              />
              <path
                  data-name="Path 2104"
                  d="M747.069 114.995a3.681 3.681 0 002.816 2.51c1.125.077 1.679-.926 1.24-2.234a3.7 3.7 0 00-2.814-2.5c-1.117-.081-1.674.915-1.242 2.224z"
              />
              <path
                  data-name="Path 2105"
                  d="M740.6 114.571a3.623 3.623 0 002.8 2.5c1.126.074 1.695-.931 1.268-2.238a3.636 3.636 0 00-2.794-2.5c-1.121-.07-1.69.929-1.274 2.238z"
              />
              <path
                  data-name="Path 2106"
                  d="M734.12 114.159a3.562 3.562 0 002.774 2.5c1.13.07 1.709-.934 1.3-2.243a3.581 3.581 0 00-2.774-2.493c-1.121-.072-1.702.929-1.3 2.236z"
              />
              <path
                  data-name="Path 2107"
                  d="M727.632 113.765a3.5 3.5 0 002.752 2.492c1.131.069 1.725-.937 1.325-2.246a3.525 3.525 0 00-2.752-2.487c-1.121-.068-1.714.936-1.325 2.241z"
              />
              <path
                  data-name="Path 2108"
                  d="M721.133 113.387a3.442 3.442 0 002.731 2.485c1.133.065 1.738-.942 1.352-2.249a3.462 3.462 0 00-2.731-2.482c-1.122-.063-1.728.939-1.352 2.246z"
              />
              <path
                  data-name="Path 2109"
                  d="M714.622 113.024a3.385 3.385 0 002.709 2.478c1.136.064 1.753-.948 1.382-2.253a3.407 3.407 0 00-2.709-2.475c-1.124-.062-1.743.944-1.382 2.25z"
              />
              <path
                  data-name="Path 2110"
                  d="M708.103 112.677a3.332 3.332 0 002.687 2.473c1.136.06 1.766-.953 1.409-2.258a3.354 3.354 0 00-2.687-2.47c-1.125-.058-1.756.95-1.409 2.255z"
              />
              <path
                  data-name="Path 2111"
                  d="M701.576 112.347a3.272 3.272 0 002.664 2.466c1.138.057 1.781-.956 1.437-2.261a3.294 3.294 0 00-2.664-2.463c-1.127-.057-1.771.952-1.437 2.258z"
              />
              <path
                  data-name="Path 2112"
                  d="M695.037 112.031a3.219 3.219 0 002.642 2.461c1.138.055 1.795-.961 1.464-2.266a3.237 3.237 0 00-2.64-2.456c-1.128-.054-1.783.956-1.466 2.261z"
              />
              <path
                  data-name="Path 2113"
                  d="M688.494 111.731a3.167 3.167 0 002.619 2.456c1.141.05 1.81-.966 1.494-2.271a3.186 3.186 0 00-2.619-2.451c-1.132-.05-1.8.962-1.494 2.266z"
              />
              <path
                  data-name="Path 2114"
                  d="M681.94 111.449a3.112 3.112 0 002.6 2.45c1.141.048 1.825-.971 1.522-2.274a3.128 3.128 0 00-2.6-2.445c-1.131-.048-1.813.966-1.522 2.269z"
              />
              <path
                  data-name="Path 2115"
                  d="M675.377 111.181a3.062 3.062 0 002.572 2.441c1.143.045 1.836-.976 1.549-2.279a3.08 3.08 0 00-2.572-2.44c-1.131-.042-1.826.976-1.549 2.278z"
              />
              <path
                  data-name="Path 2116"
                  d="M668.809 110.931a3.009 3.009 0 002.548 2.438c1.146.043 1.852-.979 1.579-2.283a3.024 3.024 0 00-2.548-2.433c-1.134-.041-1.841.976-1.579 2.278z"
              />
              <path
                  data-name="Path 2117"
                  d="M662.233 110.697a2.957 2.957 0 002.525 2.433c1.145.04 1.865-.986 1.606-2.288a2.972 2.972 0 00-2.523-2.428c-1.137-.04-1.857.981-1.608 2.283z"
              />
              <path
                  data-name="Path 2118"
                  d="M655.651 110.479a2.9 2.9 0 002.5 2.426c1.148.037 1.88-.989 1.634-2.291a2.921 2.921 0 00-2.5-2.423c-1.136-.036-1.868.986-1.634 2.288z"
              />
              <path
                  data-name="Path 2119"
                  d="M649.06 110.277a2.858 2.858 0 002.477 2.421 1.761 1.761 0 001.663-2.3 2.871 2.871 0 00-2.475-2.416 1.769 1.769 0 00-1.665 2.295z"
              />
              <path
                  data-name="Path 2120"
                  d="M642.466 110.093a2.808 2.808 0 002.451 2.415 1.79 1.79 0 001.691-2.3 2.825 2.825 0 00-2.451-2.41 1.793 1.793 0 00-1.691 2.295z"
              />
              <path
                  data-name="Path 2121"
                  d="M635.863 109.924a2.763 2.763 0 002.426 2.41 1.818 1.818 0 001.721-2.306 2.777 2.777 0 00-2.426-2.4 1.824 1.824 0 00-1.721 2.296z"
              />
              <path
                  data-name="Path 2122"
                  d="M629.258 109.773a2.711 2.711 0 002.4 2.4 1.843 1.843 0 001.748-2.309 2.724 2.724 0 00-2.4-2.4 1.851 1.851 0 00-1.748 2.309z"
              />
              <path
                  data-name="Path 2123"
                  d="M622.645 109.639a2.667 2.667 0 002.378 2.4 1.874 1.874 0 001.776-2.314 2.681 2.681 0 00-2.376-2.393 1.883 1.883 0 00-1.778 2.307z"
              />
              <path
                  data-name="Path 2124"
                  d="M616.034 109.52a2.621 2.621 0 002.351 2.391 1.9 1.9 0 001.8-2.319 2.633 2.633 0 00-2.351-2.388 1.912 1.912 0 00-1.8 2.316z"
              />
              <path
                  data-name="Path 2125"
                  d="M609.408 109.418a2.577 2.577 0 002.326 2.386 1.934 1.934 0 001.833-2.324 2.592 2.592 0 00-2.325-2.384 1.943 1.943 0 00-1.834 2.322z"
              />
              <path
                  data-name="Path 2126"
                  d="M602.784 109.333a2.532 2.532 0 002.3 2.38 1.964 1.964 0 001.86-2.328 2.547 2.547 0 00-2.3-2.378 1.974 1.974 0 00-1.86 2.326z"
              />
              <path
                  data-name="Path 2127"
                  d="M596.157 109.265a2.488 2.488 0 002.274 2.375 2 2 0 001.888-2.334 2.5 2.5 0 00-2.274-2.37 2 2 0 00-1.888 2.329z"
              />
              <path
                  data-name="Path 2128"
                  d="M589.524 109.208a2.447 2.447 0 002.249 2.37 2.03 2.03 0 001.917-2.339 2.458 2.458 0 00-2.248-2.365 2.039 2.039 0 00-1.918 2.334z"
              />
              <path
                  data-name="Path 2129"
                  d="M576.255 109.161a2.362 2.362 0 002.2 2.358 2.1 2.1 0 001.972-2.35 2.375 2.375 0 00-2.2-2.355 2.107 2.107 0 00-1.972 2.347z"
              />
              <path
                  data-name="Path 2130"
                  d="M562.974 109.174a2.284 2.284 0 002.144 2.348 2.166 2.166 0 002.027-2.36 2.3 2.3 0 00-2.142-2.345 2.182 2.182 0 00-2.029 2.357z"
              />
              <path
                  data-name="Path 2131"
                  d="M556.332 109.208a2.248 2.248 0 002.117 2.343 2.207 2.207 0 002.055-2.366 2.256 2.256 0 00-2.117-2.338 2.216 2.216 0 00-2.055 2.361z"
              />
              <path
                  data-name="Path 2133"
                  d="M529.757 109.506a2.1 2.1 0 002.009 2.323 2.359 2.359 0 002.164-2.386 2.112 2.112 0 00-2.009-2.319 2.374 2.374 0 00-2.164 2.382z"
              />
              <path
                  data-name="Path 2134"
                  d="M523.113 109.622a2.067 2.067 0 001.982 2.318 2.4 2.4 0 002.191-2.393 2.077 2.077 0 00-1.982-2.313 2.413 2.413 0 00-2.191 2.388z"
              />
              <path
                  data-name="Path 2135"
                  d="M364.707 117.396c-.408 1.31.184 2.3 1.32 2.213a3.62 3.62 0 002.779-2.533c.4-1.31-.194-2.3-1.32-2.209a3.642 3.642 0 00-2.779 2.529z"
              />
              <path
                  data-name="Path 2136"
                  d="M358.191 117.922c-.421 1.31.157 2.3 1.292 2.209a3.684 3.684 0 002.8-2.542c.411-1.31-.167-2.3-1.292-2.206a3.706 3.706 0 00-2.8 2.539z"
              />
              <path
                  data-name="Path 2137"
                  d="M345.187 119.019c-.446 1.312.107 2.3 1.238 2.2a3.8 3.8 0 002.841-2.553c.438-1.312-.117-2.294-1.238-2.2a3.825 3.825 0 00-2.841 2.553z"
              />
              <path
                  data-name="Path 2138"
                  d="M325.763 120.78c-.485 1.313.033 2.3 1.158 2.191a3.987 3.987 0 002.9-2.572c.476-1.313-.042-2.291-1.158-2.187a4.016 4.016 0 00-2.9 2.568z"
              />
              <path
                  data-name="Path 2139"
                  d="M319.312 121.397c-.5 1.315.008 2.294 1.131 2.187a4.053 4.053 0 002.919-2.577c.488-1.315-.018-2.291-1.131-2.184a4.074 4.074 0 00-2.919 2.574z"
              />
              <path
                  data-name="Path 2140"
                  d="M312.872 122.029c-.511 1.315-.017 2.294 1.1 2.184a4.116 4.116 0 002.939-2.583c.5-1.315.007-2.291-1.1-2.181a4.144 4.144 0 00-2.939 2.58z"
              />
              <path
                  data-name="Path 2141"
                  d="M306.445 122.675c-.523 1.315-.042 2.294 1.078 2.181a4.183 4.183 0 002.958-2.589c.515-1.317.032-2.291-1.078-2.179a4.207 4.207 0 00-2.958 2.587z"
              />
              <path
                  data-name="Path 2142"
                  d="M300.031 123.335c-.536 1.317-.065 2.293 1.051 2.177a4.247 4.247 0 002.976-2.594c.526-1.317.055-2.289-1.053-2.176a4.27 4.27 0 00-2.974 2.593z"
              />
              <path
                  data-name="Path 2143"
                  d="M293.63 124.011c-.548 1.318-.089 2.293 1.026 2.176a4.309 4.309 0 002.993-2.6c.54-1.318.08-2.289-1.026-2.172a4.339 4.339 0 00-2.993 2.596z"
              />
              <path
                  data-name="Path 2144"
                  d="M287.243 124.701c-.56 1.318-.112 2.293 1 2.172a4.378 4.378 0 003.011-2.607c.55-1.318.1-2.289-1-2.169a4.4 4.4 0 00-3.011 2.604z"
              />
              <path
                  data-name="Path 2145"
                  d="M280.869 125.405c-.571 1.318-.135 2.289.974 2.167a4.439 4.439 0 003.028-2.612c.561-1.318.127-2.288-.974-2.166a4.47 4.47 0 00-3.028 2.611z"
              />
              <path
                  data-name="Path 2146"
                  d="M274.511 126.122c-.583 1.32-.159 2.291.948 2.166a4.51 4.51 0 003.045-2.617c.575-1.32.15-2.288-.948-2.162a4.534 4.534 0 00-3.045 2.613z"
              />
              <path
                  data-name="Path 2147"
                  d="M268.167 126.856c-.595 1.32-.182 2.289.921 2.161a4.569 4.569 0 003.061-2.622c.587-1.322.174-2.288-.922-2.161a4.6 4.6 0 00-3.06 2.622z"
              />
              <path
                  data-name="Path 2148"
                  d="M261.837 127.602c-.6 1.322-.2 2.289.9 2.159a4.643 4.643 0 003.076-2.629c.6-1.322.2-2.286-.9-2.157a4.671 4.671 0 00-3.076 2.627z"
              />
              <path
                  data-name="Path 2149"
                  d="M255.525 128.362c-.617 1.322-.227 2.288.872 2.156a4.713 4.713 0 003.091-2.635c.608-1.322.219-2.284-.872-2.152a4.735 4.735 0 00-3.091 2.631z"
              />
              <path
                  data-name="Path 2150"
                  d="M249.227 129.136c-.628 1.322-.251 2.286.846 2.152a4.78 4.78 0 003.108-2.64c.62-1.322.241-2.284-.847-2.151a4.8 4.8 0 00-3.107 2.639z"
              />
              <path
                  data-name="Path 2151"
                  d="M242.946 129.921c-.638 1.324-.271 2.288.822 2.151a4.839 4.839 0 003.122-2.645c.63-1.324.262-2.284-.822-2.147a4.866 4.866 0 00-3.122 2.641z"
              />
              <path
                  data-name="Path 2152"
                  d="M236.681 130.721c-.65 1.325-.292 2.286.8 2.147a4.915 4.915 0 003.137-2.65c.642-1.325.284-2.284-.8-2.146a4.933 4.933 0 00-3.137 2.649z"
              />
              <path
                  data-name="Path 2153"
                  d="M230.435 131.539c-.66 1.324-.316 2.286.772 2.144a4.978 4.978 0 003.15-2.657c.652-1.323.306-2.283-.774-2.141a4.991 4.991 0 00-3.148 2.654z"
              />
              <path
                  data-name="Path 2154"
                  d="M224.204 132.36c-.67 1.325-.336 2.284.749 2.141a5.042 5.042 0 003.163-2.66c.663-1.325.328-2.283-.749-2.139a5.076 5.076 0 00-3.163 2.658z"
              />
              <path
                  data-name="Path 2155"
                  d="M217.992 133.203c-.68 1.327-.356 2.284.724 2.139a5.114 5.114 0 003.177-2.667c.673-1.327.348-2.283-.725-2.136a5.136 5.136 0 00-3.176 2.664z"
              />
              <path
                  data-name="Path 2156"
                  d="M211.799 134.052c-.692 1.327-.378 2.283.7 2.134a5.172 5.172 0 003.188-2.67c.683-1.327.369-2.283-.7-2.134a5.2 5.2 0 00-3.188 2.67z"
              />
              <path
                  data-name="Path 2157"
                  d="M205.622 134.916c-.7 1.327-.4 2.283.677 2.132a5.252 5.252 0 003.2-2.677c.692-1.327.389-2.279-.677-2.131a5.269 5.269 0 00-3.2 2.676z"
              />
              <path
                  data-name="Path 2158"
                  d="M199.467 135.791c-.71 1.329-.418 2.283.653 2.129a5.305 5.305 0 003.21-2.68c.7-1.328.411-2.281-.653-2.129a5.34 5.34 0 00-3.21 2.68z"
              />
              <path
                  data-name="Path 2159"
                  d="M193.332 136.68c-.719 1.329-.438 2.281.63 2.126a5.372 5.372 0 003.223-2.685c.712-1.329.43-2.279-.632-2.126a5.41 5.41 0 00-3.221 2.685z"
              />
              <path
                  data-name="Path 2160"
                  d="M187.212 137.579c-.729 1.328-.458 2.283.607 2.126a5.436 5.436 0 003.232-2.692c.722-1.328.451-2.278-.607-2.122a5.477 5.477 0 00-3.232 2.688z"
              />
              <path
                  data-name="Path 2161"
                  d="M181.118 138.492c-.737 1.33-.476 2.281.583 2.122a5.506 5.506 0 003.244-2.7c.732-1.33.47-2.279-.585-2.121a5.537 5.537 0 00-3.242 2.699z"
              />
              <path
                  data-name="Path 2162"
                  d="M175.039 139.416c-.747 1.33-.5 2.279.561 2.119a5.579 5.579 0 003.252-2.7c.74-1.33.488-2.278-.561-2.117a5.6 5.6 0 00-3.252 2.698z"
              />
              <path
                  data-name="Path 2163"
                  d="M168.983 140.352c-.755 1.33-.515 2.278.538 2.116a5.639 5.639 0 003.262-2.705c.749-1.33.506-2.276-.54-2.114a5.661 5.661 0 00-3.26 2.703z"
              />
              <path
                  data-name="Path 2164"
                  d="M162.946 141.298c-.762 1.332-.531 2.278.518 2.112a5.7 5.7 0 003.27-2.707c.757-1.332.525-2.278-.518-2.112a5.731 5.731 0 00-3.27 2.707z"
              />
              <path
                  data-name="Path 2165"
                  d="M156.934 142.255c-.772 1.332-.55 2.276.5 2.111a5.777 5.777 0 003.279-2.714c.765-1.332.541-2.274-.5-2.109a5.8 5.8 0 00-3.279 2.712z"
              />
              <path
                  data-name="Path 2166"
                  d="M150.943 143.222c-.779 1.332-.566 2.278.475 2.109a5.835 5.835 0 003.285-2.717c.774-1.334.56-2.274-.475-2.107a5.861 5.861 0 00-3.285 2.715z"
              />
              <path
                  data-name="Path 2167"
                  d="M144.974 144.197c-.785 1.334-.583 2.276.455 2.106a5.892 5.892 0 003.292-2.722c.78-1.33.577-2.273-.455-2.1a5.922 5.922 0 00-3.292 2.716z"
              />
              <path
                  data-name="Path 2168"
                  d="M139.028 145.192c-.794 1.334-.6 2.274.433 2.1a5.954 5.954 0 003.3-2.724c.787-1.332.593-2.274-.434-2.1a6 6 0 00-3.299 2.724z"
              />
              <path
                  data-name="Path 2169"
                  d="M133.107 146.192c-.8 1.332-.617 2.273.413 2.1a6.019 6.019 0 003.305-2.729c.8-1.334.61-2.271-.414-2.1a6.055 6.055 0 00-3.304 2.729z"
              />
              <path
                  data-name="Path 2170"
                  d="M92.332 153.459c-.846 1.334-.722 2.264.277 2.08a6.431 6.431 0 003.33-2.751c.841-1.333.715-2.266-.279-2.081a6.453 6.453 0 00-3.328 2.752z"
              />
              <path
                  data-name="Path 2171"
                  d="M86.607 154.531c-.849 1.334-.734 2.263.259 2.077a6.482 6.482 0 003.332-2.754c.847-1.334.73-2.263-.261-2.076a6.5 6.5 0 00-3.33 2.753z"
              />
              <path
                  data-name="Path 2172"
                  d="M80.916 155.617c-.856 1.332-.749 2.261.241 2.074a6.534 6.534 0 003.334-2.756c.851-1.334.74-2.261-.244-2.074a6.547 6.547 0 00-3.331 2.756z"
              />
              <path
                  data-name="Path 2173"
                  d="M75.244 156.702c-.859 1.334-.76 2.261.224 2.072a6.588 6.588 0 003.334-2.757c.856-1.334.754-2.261-.226-2.07a6.614 6.614 0 00-3.332 2.755z"
              />
              <path
                  data-name="Path 2174"
                  d="M69.603 157.806c-.864 1.334-.772 2.259.207 2.069a6.652 6.652 0 003.334-2.761c.861-1.332.767-2.259-.211-2.067a6.659 6.659 0 00-3.33 2.759z"
              />
              <path
                  data-name="Path 2175"
                  d="M63.989 158.912c-.869 1.332-.784 2.258.19 2.065a6.691 6.691 0 003.332-2.761c.866-1.334.779-2.258-.194-2.065a6.712 6.712 0 00-3.328 2.761z"
              />
              <path
                  data-name="Path 2176"
                  d="M58.404 160.032c-.874 1.332-.8 2.254.174 2.062a6.731 6.731 0 003.33-2.764c.869-1.332.789-2.256-.177-2.062a6.754 6.754 0 00-3.327 2.764z"
              />
              <path
                  data-name="Path 2177"
                  d="M52.846 161.148c-.876 1.332-.805 2.254.159 2.059a6.773 6.773 0 003.329-2.764c.872-1.333.8-2.254-.162-2.06a6.809 6.809 0 00-3.326 2.765z"
              />
              <path
                  data-name="Path 2178"
                  d="M47.32 162.276c-.879 1.33-.815 2.251.142 2.055a6.823 6.823 0 003.325-2.766c.877-1.332.81-2.253-.145-2.057a6.843 6.843 0 00-3.322 2.768z"
              />
              <path
                  data-name="Path 2179"
                  d="M36.353 164.552c-.884 1.33-.834 2.246.114 2.049a6.9 6.9 0 003.317-2.767c.884-1.33.831-2.248-.115-2.05a6.93 6.93 0 00-3.316 2.768z"
              />
              <path
                  data-name="Path 2180"
                  d="M1005.182 138.706a6.417 6.417 0 003.327 2.719c.978.169 1.071-.774.209-2.107a6.444 6.444 0 00-3.325-2.719c-.974-.169-1.068.774-.211 2.107z"
              />
              <path
                  data-name="Path 2181"
                  d="M993.862 136.779a6.308 6.308 0 003.322 2.712c.986.165 1.094-.78.244-2.111a6.325 6.325 0 00-3.32-2.712c-.983-.165-1.092.781-.246 2.111z"
              />
              <path
                  data-name="Path 2182"
                  d="M988.167 135.831a6.24 6.24 0 003.319 2.707c.989.165 1.108-.782.261-2.114a6.273 6.273 0 00-3.317-2.707c-.987-.163-1.106.782-.263 2.114z"
              />
              <path
                  data-name="Path 2183"
                  d="M982.449 134.892a6.2 6.2 0 003.314 2.7c1 .164 1.121-.784.281-2.116a6.213 6.213 0 00-3.314-2.7c-.991-.162-1.116.784-.281 2.116z"
              />
              <path
                  data-name="Path 2184"
                  d="M941.793 128.607a5.759 5.759 0 003.272 2.674c1.023.15 1.21-.8.419-2.131a5.782 5.782 0 00-3.272-2.672c-1.015-.15-1.202.804-.419 2.129z"
              />
              <path
                  data-name="Path 2185"
                  d="M935.904 127.753a5.684 5.684 0 003.263 2.669c1.026.149 1.223-.807.44-2.134a5.721 5.721 0 00-3.264-2.667c-1.022-.147-1.221.808-.439 2.132z"
              />
              <path
                  data-name="Path 2186"
                  d="M929.987 126.91a5.629 5.629 0 003.255 2.665c1.029.145 1.237-.812.461-2.137a5.655 5.655 0 00-3.255-2.662c-1.023-.146-1.23.814-.461 2.134z"
              />
              <path
                  data-name="Path 2187"
                  d="M924.055 126.079a5.566 5.566 0 003.247 2.659c1.033.145 1.247-.814.483-2.137a5.589 5.589 0 00-3.247-2.659c-1.028-.144-1.245.813-.483 2.137z"
              />
              <path
                  data-name="Path 2188"
                  d="M918.099 125.258a5.5 5.5 0 003.237 2.655c1.038.142 1.263-.817.5-2.142a5.522 5.522 0 00-3.235-2.652c-1.025-.14-1.253.816-.502 2.139z"
              />
              <path
                  data-name="Path 2189"
                  d="M912.126 124.452a5.436 5.436 0 003.227 2.649c1.039.139 1.275-.82.526-2.144a5.463 5.463 0 00-3.225-2.647c-1.032-.139-1.268.818-.528 2.142z"
              />
              <path
                  data-name="Path 2190"
                  d="M906.134 123.655a5.366 5.366 0 003.217 2.644c1.043.139 1.29-.822.55-2.144a5.39 5.39 0 00-3.215-2.642c-1.037-.139-1.284.82-.552 2.142z"
              />
              <path
                  data-name="Path 2191"
                  d="M900.124 122.871a5.3 5.3 0 003.205 2.639c1.046.137 1.3-.827.573-2.147a5.326 5.326 0 00-3.2-2.637c-1.039-.136-1.3.823-.578 2.145z"
              />
              <path
                  data-name="Path 2192"
                  d="M894.094 122.098a5.233 5.233 0 003.193 2.634c1.051.134 1.317-.829.6-2.151a5.26 5.26 0 00-3.193-2.63c-1.048-.134-1.312.827-.6 2.147z"
              />
              <path
                  data-name="Path 2193"
                  d="M888.046 121.339a5.171 5.171 0 003.182 2.629c1.053.13 1.33-.834.618-2.154a5.184 5.184 0 00-3.18-2.625c-1.045-.131-1.323.832-.62 2.15z"
              />
              <path
                  data-name="Path 2194"
                  d="M881.981 120.593a5.1 5.1 0 003.17 2.622c1.055.13 1.344-.836.642-2.154a5.123 5.123 0 00-3.168-2.62c-1.049-.129-1.335.834-.644 2.152z"
              />
              <path
                  data-name="Path 2195"
                  d="M875.898 119.859a5.028 5.028 0 003.157 2.617c1.058.127 1.357-.839.667-2.157a5.06 5.06 0 00-3.157-2.615c-1.049-.126-1.35.838-.667 2.155z"
              />
              <path
                  data-name="Path 2196"
                  d="M869.797 119.137a4.965 4.965 0 003.143 2.612c1.063.125 1.372-.844.69-2.159a4.987 4.987 0 00-3.142-2.609c-1.054-.12-1.365.839-.691 2.156z"
              />
              <path
                  data-name="Path 2197"
                  d="M863.679 118.431a4.9 4.9 0 003.13 2.605c1.063.122 1.385-.846.715-2.162a4.924 4.924 0 00-3.128-2.6c-1.058-.126-1.379.84-.717 2.157z"
              />
              <path
                  data-name="Path 2198"
                  d="M857.544 117.735a4.829 4.829 0 003.115 2.6c1.068.12 1.4-.849.74-2.166a4.857 4.857 0 00-3.115-2.6c-1.059-.116-1.39.851-.74 2.166z"
              />
              <path
                  data-name="Path 2199"
                  d="M851.395 117.054a4.756 4.756 0 003.1 2.594c1.071.119 1.414-.852.765-2.167a4.787 4.787 0 00-3.1-2.59c-1.064-.118-1.407.849-.765 2.163z"
              />
              <path
                  data-name="Path 2200"
                  d="M845.227 116.385a4.691 4.691 0 003.085 2.588c1.076.115 1.429-.856.791-2.169a4.721 4.721 0 00-3.085-2.587c-1.061-.113-1.421.855-.791 2.168z"
              />
              <path
                  data-name="Path 2201"
                  d="M839.046 115.731a4.622 4.622 0 003.068 2.582c1.078.114 1.444-.861.815-2.172a4.651 4.651 0 00-3.068-2.58c-1.069-.114-1.433.857-.815 2.17z"
              />
              <path
                  data-name="Path 2202"
                  d="M832.848 115.09a4.555 4.555 0 003.053 2.577c1.079.11 1.456-.864.841-2.176a4.585 4.585 0 00-3.053-2.573c-1.07-.111-1.45.86-.841 2.172z"
              />
              <path
                  data-name="Path 2203"
                  d="M826.633 114.463a4.5 4.5 0 003.038 2.57c1.083.109 1.471-.867.867-2.177a4.522 4.522 0 00-3.038-2.568c-1.073-.111-1.462.865-.867 2.175z"
              />
              <path
                  data-name="Path 2204"
                  d="M820.407 113.851a4.423 4.423 0 003.021 2.565c1.085.105 1.484-.871.891-2.182a4.455 4.455 0 00-3.02-2.56c-1.076-.106-1.475.868-.892 2.177z"
              />
              <path
                  data-name="Path 2205"
                  d="M814.164 113.251a4.361 4.361 0 003 2.558c1.086.1 1.5-.876.917-2.184a4.388 4.388 0 00-3-2.557c-1.077-.101-1.49.875-.917 2.183z"
              />
              <path
                  data-name="Path 2206"
                  d="M807.907 112.667a4.3 4.3 0 002.986 2.552c1.09.1 1.514-.877.944-2.187a4.314 4.314 0 00-2.986-2.548c-1.081-.101-1.502.875-.944 2.183z"
              />
              <path
                  data-name="Path 2207"
                  d="M801.635 112.096a4.232 4.232 0 002.97 2.547c1.093.1 1.527-.882.971-2.189a4.251 4.251 0 00-2.968-2.543c-1.087-.1-1.519.878-.973 2.185z"
              />
              <path
                  data-name="Path 2208"
                  d="M795.352 111.539a4.167 4.167 0 002.951 2.542c1.1.1 1.541-.887 1-2.194a4.194 4.194 0 00-2.951-2.537c-1.09-.096-1.535.884-1 2.189z"
              />
              <path
                  data-name="Path 2209"
                  d="M789.056 110.998a4.1 4.1 0 002.931 2.535c1.1.094 1.556-.891 1.024-2.2a4.127 4.127 0 00-2.931-2.532c-1.088-.087-1.547.892-1.024 2.197z"
              />
              <path
                  data-name="Path 2210"
                  d="M782.744 110.467a4.041 4.041 0 002.914 2.528c1.1.092 1.569-.894 1.051-2.2a4.062 4.062 0 00-2.913-2.525c-1.089-.085-1.562.897-1.052 2.197z"
              />
              <path
                  data-name="Path 2211"
                  d="M776.422 109.961a3.972 3.972 0 002.894 2.522c1.1.089 1.584-.9 1.078-2.2a3.994 3.994 0 00-2.894-2.518c-1.093-.092-1.576.896-1.078 2.196z"
              />
              <path
                  data-name="Path 2212"
                  d="M770.087 109.459a3.91 3.91 0 002.874 2.517c1.1.085 1.6-.9 1.106-2.206a3.937 3.937 0 00-2.876-2.513c-1.094-.083-1.589.902-1.104 2.202z"
              />
              <path
                  data-name="Path 2213"
                  d="M763.741 108.979a3.84 3.84 0 002.854 2.51c1.106.085 1.613-.906 1.133-2.207a3.867 3.867 0 00-2.856-2.507c-1.096-.084-1.602.902-1.131 2.204z"
              />
              <path
                  data-name="Path 2214"
                  d="M757.381 108.513a3.786 3.786 0 002.836 2.5c1.108.08 1.628-.911 1.16-2.212a3.8 3.8 0 00-2.836-2.5c-1.097-.077-1.618.91-1.16 2.212z"
              />
              <path
                  data-name="Path 2215"
                  d="M751.011 108.06a3.726 3.726 0 002.816 2.5c1.11.077 1.641-.916 1.186-2.216a3.746 3.746 0 00-2.814-2.495c-1.1-.078-1.631.911-1.188 2.211z"
              />
              <path
                  data-name="Path 2216"
                  d="M744.63 107.623a3.666 3.666 0 002.795 2.493c1.111.075 1.656-.921 1.215-2.219a3.683 3.683 0 00-2.794-2.488c-1.104-.077-1.647.914-1.216 2.214z"
              />
              <path
                  data-name="Path 2217"
                  d="M738.239 107.201a3.607 3.607 0 002.774 2.487c1.113.072 1.671-.924 1.242-2.224a3.622 3.622 0 00-2.772-2.48c-1.104-.074-1.662.919-1.244 2.217z"
              />
              <path
                  data-name="Path 2218"
                  d="M731.836 106.795a3.544 3.544 0 002.752 2.48c1.115.07 1.684-.927 1.27-2.226a3.565 3.565 0 00-2.752-2.477c-1.106-.068-1.673.924-1.27 2.223z"
              />
              <path
                  data-name="Path 2219"
                  d="M725.423 106.404a3.486 3.486 0 002.731 2.473c1.118.069 1.7-.931 1.3-2.229a3.509 3.509 0 00-2.731-2.47c-1.106-.067-1.689.929-1.3 2.226z"
              />
              <path
                  data-name="Path 2220"
                  d="M719.002 106.029a3.426 3.426 0 002.709 2.466c1.118.065 1.713-.936 1.325-2.233a3.441 3.441 0 00-2.707-2.463c-1.109-.064-1.703.934-1.327 2.23z"
              />
              <path
                  data-name="Path 2221"
                  d="M712.568 105.67a3.372 3.372 0 002.687 2.46c1.121.064 1.728-.941 1.354-2.236a3.391 3.391 0 00-2.685-2.458c-1.109-.062-1.714.938-1.356 2.234z"
              />
              <path
                  data-name="Path 2222"
                  d="M706.13 105.326a3.314 3.314 0 002.665 2.455c1.123.06 1.741-.946 1.382-2.241a3.335 3.335 0 00-2.664-2.451c-1.111-.06-1.729.94-1.383 2.237z"
              />
              <path
                  data-name="Path 2223"
                  d="M699.682 104.999a3.255 3.255 0 002.642 2.448c1.125.057 1.755-.949 1.41-2.244a3.276 3.276 0 00-2.642-2.445c-1.113-.057-1.744.946-1.41 2.241z"
              />
              <path
                  data-name="Path 2224"
                  d="M693.224 104.686a3.2 3.2 0 002.619 2.443c1.125.053 1.77-.956 1.439-2.249a3.22 3.22 0 00-2.619-2.438c-1.115-.054-1.756.949-1.439 2.244z"
              />
              <path
                  data-name="Path 2225"
                  d="M686.759 104.39a3.148 3.148 0 002.6 2.436c1.128.052 1.783-.959 1.467-2.253a3.165 3.165 0 00-2.6-2.433c-1.112-.049-1.773.955-1.467 2.25z"
              />
              <path
                  data-name="Path 2226"
                  d="M680.288 104.109a3.093 3.093 0 002.572 2.431c1.126.048 1.8-.964 1.494-2.258a3.112 3.112 0 00-2.572-2.426c-1.118-.048-1.787.96-1.494 2.253z"
              />
              <path
                  data-name="Path 2227"
                  d="M673.807 103.845a3.037 3.037 0 002.547 2.425c1.13.045 1.813-.968 1.524-2.261a3.059 3.059 0 00-2.548-2.421c-1.12-.046-1.802.964-1.523 2.257z"
              />
              <path
                  data-name="Path 2228"
                  d="M667.315 103.599a2.99 2.99 0 002.525 2.418c1.131.043 1.825-.973 1.551-2.264a3 3 0 00-2.523-2.415c-1.117-.044-1.812.967-1.553 2.261z"
              />
              <path
                  data-name="Path 2229"
                  d="M660.824 103.365a2.937 2.937 0 002.5 2.413c1.131.04 1.84-.978 1.579-2.269a2.955 2.955 0 00-2.5-2.41c-1.121-.038-1.828.975-1.579 2.266z"
              />
              <path
                  data-name="Path 2230"
                  d="M654.324 103.151a2.881 2.881 0 002.475 2.406c1.133.038 1.853-.981 1.609-2.273a2.9 2.9 0 00-2.477-2.4c-1.121-.039-1.843.975-1.607 2.267z"
              />
              <path
                  data-name="Path 2231"
                  d="M647.817 102.952a2.837 2.837 0 002.451 2.4c1.135.033 1.867-.988 1.636-2.279a2.853 2.853 0 00-2.451-2.4c-1.123-.031-1.855.987-1.636 2.279z"
              />
              <path
                  data-name="Path 2232"
                  d="M641.303 102.768a2.788 2.788 0 002.428 2.4 1.769 1.769 0 001.664-2.283 2.8 2.8 0 00-2.426-2.391 1.775 1.775 0 00-1.666 2.274z"
              />
              <path
                  data-name="Path 2233"
                  d="M634.786 102.603a2.738 2.738 0 002.4 2.39 1.8 1.8 0 001.693-2.288 2.755 2.755 0 00-2.4-2.386 1.8 1.8 0 00-1.693 2.284z"
              />
              <path
                  data-name="Path 2234"
                  d="M628.262 102.452a2.693 2.693 0 002.376 2.385 1.824 1.824 0 001.721-2.293 2.7 2.7 0 00-2.375-2.38 1.832 1.832 0 00-1.722 2.288z"
              />
              <path
                  data-name="Path 2235"
                  d="M621.734 102.318a2.645 2.645 0 002.351 2.378 1.853 1.853 0 001.75-2.3 2.661 2.661 0 00-2.351-2.375 1.86 1.86 0 00-1.75 2.297z"
              />
              <path
                  data-name="Path 2236"
                  d="M615.201 102.201a2.6 2.6 0 002.324 2.373 1.882 1.882 0 001.778-2.3 2.614 2.614 0 00-2.324-2.37 1.889 1.889 0 00-1.778 2.297z"
              />
              <path
                  data-name="Path 2237"
                  d="M608.665 102.099a2.553 2.553 0 002.3 2.368 1.911 1.911 0 001.805-2.302 2.568 2.568 0 00-2.3-2.363 1.921 1.921 0 00-1.805 2.297z"
              />
              <path
                  data-name="Path 2238"
                  d="M602.124 102.015a2.512 2.512 0 002.274 2.363 1.946 1.946 0 001.835-2.311 2.523 2.523 0 00-2.274-2.358 1.951 1.951 0 00-1.835 2.306z"
              />
              <path
                  data-name="Path 2239"
                  d="M595.58 101.949a2.468 2.468 0 002.245 2.352 1.976 1.976 0 001.862-2.316 2.478 2.478 0 00-2.248-2.351 1.985 1.985 0 00-1.859 2.315z"
              />
              <path
                  data-name="Path 2240"
                  d="M589.033 101.897a2.424 2.424 0 002.223 2.351 2.008 2.008 0 001.89-2.321 2.436 2.436 0 00-2.221-2.346 2.018 2.018 0 00-1.892 2.316z"
              />
              <path
                  data-name="Path 2241"
                  d="M582.485 101.862a2.381 2.381 0 002.2 2.345 2.04 2.04 0 001.918-2.324 2.394 2.394 0 00-2.2-2.341 2.051 2.051 0 00-1.918 2.32z"
              />
              <path
                  data-name="Path 2242"
                  d="M575.937 101.845a2.344 2.344 0 002.169 2.341 2.077 2.077 0 001.947-2.331 2.355 2.355 0 00-2.169-2.336 2.087 2.087 0 00-1.947 2.326z"
              />
              <path
                  data-name="Path 2243"
                  d="M569.379 101.845a2.3 2.3 0 002.142 2.335 2.11 2.11 0 001.974-2.336 2.311 2.311 0 00-2.142-2.329 2.12 2.12 0 00-1.974 2.33z"
              />
              <path
                  data-name="Path 2244"
                  d="M562.822 101.86a2.261 2.261 0 002.116 2.329 2.148 2.148 0 002-2.341 2.272 2.272 0 00-2.116-2.324 2.156 2.156 0 00-2 2.336z"
              />
              <path
                  data-name="Path 2245"
                  d="M556.265 101.894a2.222 2.222 0 002.089 2.323 2.182 2.182 0 002.029-2.346 2.234 2.234 0 00-2.089-2.319 2.194 2.194 0 00-2.029 2.342z"
              />
              <path
                  data-name="Path 2246"
                  d="M549.706 101.942a2.186 2.186 0 002.062 2.319 2.224 2.224 0 002.057-2.353 2.2 2.2 0 00-2.062-2.314 2.233 2.233 0 00-2.057 2.348z"
              />
              <path
                  data-name="Path 2247"
                  d="M543.147 102.008a2.15 2.15 0 002.035 2.314 2.259 2.259 0 002.084-2.356 2.159 2.159 0 00-2.035-2.309 2.27 2.27 0 00-2.084 2.351z"
              />
              <path
                  data-name="Path 2248"
                  d="M536.586 102.091a2.113 2.113 0 002.009 2.308 2.3 2.3 0 002.112-2.361 2.124 2.124 0 00-2.009-2.3 2.311 2.311 0 00-2.112 2.353z"
              />
              <path
                  data-name="Path 2249"
                  d="M530.028 102.189a2.08 2.08 0 001.982 2.3 2.34 2.34 0 002.137-2.368 2.086 2.086 0 00-1.98-2.3 2.35 2.35 0 00-2.139 2.368z"
              />
              <path
                  data-name="Path 2251"
                  d="M367.011 110.015c-.408 1.3.17 2.286 1.293 2.2a3.61 3.61 0 002.757-2.517c.4-1.3-.181-2.283-1.293-2.194a3.635 3.635 0 00-2.757 2.511z"
              />
              <path
                  data-name="Path 2252"
                  d="M347.717 111.626c-.45 1.3.094 2.283 1.212 2.187a3.791 3.791 0 002.821-2.537c.439-1.3-.1-2.279-1.212-2.184a3.818 3.818 0 00-2.821 2.534z"
              />
              <path
                  data-name="Path 2253"
                  d="M341.305 112.192c-.463 1.305.069 2.284 1.185 2.184a3.851 3.851 0 002.841-2.542c.453-1.305-.077-2.279-1.185-2.181a3.878 3.878 0 00-2.841 2.539z"
              />
              <path
                  data-name="Path 2254"
                  d="M334.904 112.776c-.475 1.305.043 2.283 1.158 2.181a3.917 3.917 0 002.861-2.548c.466-1.305-.052-2.279-1.158-2.177a3.939 3.939 0 00-2.861 2.544z"
              />
              <path
                  data-name="Path 2255"
                  d="M328.511 113.372c-.486 1.307.02 2.283 1.133 2.177a3.979 3.979 0 002.881-2.553c.478-1.307-.028-2.278-1.131-2.174a4.011 4.011 0 00-2.883 2.55z"
              />
              <path
                  data-name="Path 2256"
                  d="M322.133 113.986c-.5 1.307-.007 2.281 1.1 2.174a4.044 4.044 0 002.9-2.562c.491-1.305 0-2.276-1.106-2.171a4.065 4.065 0 00-2.894 2.559z"
              />
              <path
                  data-name="Path 2257"
                  d="M315.763 114.612c-.513 1.307-.03 2.281 1.08 2.171a4.106 4.106 0 002.919-2.567c.5-1.307.022-2.278-1.078-2.167a4.133 4.133 0 00-2.921 2.563z"
              />
              <path
                  data-name="Path 2258"
                  d="M309.406 115.254c-.525 1.308-.053 2.279 1.053 2.169a4.181 4.181 0 002.939-2.573c.516-1.308.045-2.276-1.053-2.166a4.2 4.2 0 00-2.939 2.57z"
              />
              <path
                  data-name="Path 2259"
                  d="M303.061 115.911c-.536 1.31-.077 2.279 1.028 2.166a4.239 4.239 0 002.956-2.58c.53-1.308.07-2.276-1.026-2.161a4.267 4.267 0 00-2.958 2.575z"
              />
              <path
                  data-name="Path 2260"
                  d="M296.729 116.582c-.55 1.31-.1 2.278 1 2.161a4.3 4.3 0 002.975-2.585c.541-1.308.094-2.274-1-2.159a4.329 4.329 0 00-2.975 2.583z"
              />
              <path
                  data-name="Path 2261"
                  d="M284.105 117.966c-.575 1.312-.15 2.279.948 2.157a4.442 4.442 0 003.011-2.6c.565-1.312.14-2.274-.949-2.152a4.46 4.46 0 00-3.01 2.595z"
              />
              <path
                  data-name="Path 2262"
                  d="M277.811 118.679c-.587 1.313-.172 2.279.922 2.154a4.5 4.5 0 003.028-2.6c.577-1.312.164-2.274-.922-2.151a4.534 4.534 0 00-3.028 2.597z"
              />
              <path
                  data-name="Path 2263"
                  d="M271.531 119.408c-.6 1.313-.194 2.278.9 2.151a4.569 4.569 0 003.043-2.61c.59-1.312.187-2.274-.9-2.147a4.6 4.6 0 00-3.043 2.606z"
              />
              <path
                  data-name="Path 2264"
                  d="M265.265 120.15c-.608 1.314-.217 2.276.874 2.147a4.638 4.638 0 003.06-2.615c.6-1.313.209-2.274-.872-2.144a4.662 4.662 0 00-3.062 2.612z"
              />
              <path
                  data-name="Path 2265"
                  d="M259.016 120.905c-.62 1.315-.239 2.276.847 2.144a4.7 4.7 0 003.076-2.62c.612-1.315.232-2.274-.847-2.142a4.734 4.734 0 00-3.076 2.618z"
              />
              <path
                  data-name="Path 2266"
                  d="M252.782 121.674c-.63 1.315-.262 2.276.822 2.142a4.775 4.775 0 003.093-2.627c.622-1.317.254-2.273-.824-2.139a4.8 4.8 0 00-3.091 2.624z"
              />
              <path
                  data-name="Path 2267"
                  d="M246.562 122.456c-.642 1.317-.284 2.276.8 2.139a4.838 4.838 0 003.107-2.632c.633-1.317.276-2.273-.8-2.137a4.87 4.87 0 00-3.107 2.63z"
              />
              <path
                  data-name="Path 2268"
                  d="M240.357 123.252c-.652 1.318-.306 2.276.774 2.137a4.9 4.9 0 003.122-2.639c.645-1.317.3-2.273-.774-2.134a4.937 4.937 0 00-3.122 2.636z"
              />
              <path
                  data-name="Path 2269"
                  d="M234.169 124.06c-.662 1.32-.326 2.274.75 2.134a4.974 4.974 0 003.135-2.644c.655-1.317.319-2.273-.75-2.131a5.009 5.009 0 00-3.135 2.641z"
              />
              <path
                  data-name="Path 2270"
                  d="M227.998 124.883c-.673 1.32-.348 2.274.725 2.132a5.043 5.043 0 003.15-2.65c.665-1.318.339-2.271-.727-2.129a5.076 5.076 0 00-3.148 2.647z"
              />
              <path
                  data-name="Path 2271"
                  d="M221.843 125.718c-.684 1.32-.369 2.274.7 2.127a5.1 5.1 0 003.162-2.654c.675-1.32.361-2.271-.7-2.127a5.142 5.142 0 00-3.162 2.654z"
              />
              <path
                  data-name="Path 2272"
                  d="M215.705 126.566c-.694 1.322-.389 2.274.678 2.126a5.177 5.177 0 003.175-2.66c.685-1.32.381-2.271-.678-2.124a5.208 5.208 0 00-3.175 2.658z"
              />
              <path
                  data-name="Path 2273"
                  d="M209.587 127.424c-.7 1.322-.409 2.274.653 2.124a5.244 5.244 0 003.188-2.665c.7-1.322.4-2.271-.657-2.121a5.271 5.271 0 00-3.184 2.662z"
              />
              <path
                  data-name="Path 2274"
                  d="M203.484 128.296c-.714 1.323-.429 2.274.632 2.122a5.321 5.321 0 003.2-2.67c.705-1.323.423-2.271-.632-2.119a5.339 5.339 0 00-3.2 2.667z"
              />
              <path
                  data-name="Path 2275"
                  d="M197.399 129.185c-.722 1.324-.45 2.273.608 2.119a5.374 5.374 0 003.21-2.677c.715-1.322.443-2.269-.608-2.116a5.409 5.409 0 00-3.21 2.674z"
              />
              <path
                  data-name="Path 2276"
                  d="M191.333 130.079c-.73 1.323-.468 2.271.587 2.116a5.458 5.458 0 003.222-2.68c.724-1.325.46-2.271-.588-2.116a5.482 5.482 0 00-3.221 2.68z"
              />
              <path
                  data-name="Path 2277"
                  d="M185.292 130.987c-.74 1.325-.488 2.273.563 2.114a5.507 5.507 0 003.232-2.685c.734-1.325.48-2.271-.565-2.112a5.549 5.549 0 00-3.23 2.683z"
              />
              <path
                  data-name="Path 2278"
                  d="M179.26 131.908c-.749 1.327-.506 2.271.541 2.111a5.577 5.577 0 003.242-2.69c.742-1.325.5-2.269-.543-2.109a5.616 5.616 0 00-3.24 2.688z"
              />
              <path
                  data-name="Path 2279"
                  d="M173.251 132.845c-.757 1.327-.525 2.271.52 2.109a5.648 5.648 0 003.25-2.7c.75-1.327.518-2.269-.52-2.107a5.666 5.666 0 00-3.25 2.698z"
              />
              <path
                  data-name="Path 2280"
                  d="M167.265 133.782c-.765 1.327-.543 2.271.5 2.107a5.71 5.71 0 003.26-2.7c.759-1.327.535-2.269-.5-2.106a5.744 5.744 0 00-3.26 2.699z"
              />
              <path
                  data-name="Path 2281"
                  d="M161.298 134.738c-.774 1.327-.56 2.269.478 2.1a5.786 5.786 0 003.267-2.705c.767-1.329.553-2.268-.478-2.1a5.8 5.8 0 00-3.267 2.705z"
              />
              <path
                  data-name="Path 2282"
                  d="M155.35 135.703c-.78 1.328-.577 2.269.458 2.1a5.85 5.85 0 003.275-2.709c.775-1.329.57-2.268-.458-2.1a5.877 5.877 0 00-3.275 2.709z"
              />
              <path
                  data-name="Path 2283"
                  d="M149.426 136.679c-.789 1.329-.595 2.269.436 2.1a5.9 5.9 0 003.282-2.714c.784-1.329.587-2.268-.438-2.1a5.934 5.934 0 00-3.28 2.714z"
              />
              <path
                  data-name="Path 2284"
                  d="M143.522 137.668c-.8 1.329-.61 2.268.416 2.1a5.968 5.968 0 003.289-2.717c.79-1.33.6-2.268-.416-2.1a5.986 5.986 0 00-3.289 2.717z"
              />
              <path
                  data-name="Path 2285"
                  d="M137.64 138.665c-.8 1.328-.627 2.268.4 2.094a6.042 6.042 0 003.3-2.722c.8-1.33.62-2.266-.4-2.094a6.059 6.059 0 00-3.3 2.722z"
              />
              <path
                  data-name="Path 2286"
                  d="M131.78 139.675c-.809 1.33-.642 2.266.378 2.091a6.079 6.079 0 003.3-2.726c.805-1.33.635-2.266-.378-2.091a6.118 6.118 0 00-3.3 2.726z"
              />
              <path
                  data-name="Path 2287"
                  d="M125.942 140.691c-.815 1.33-.657 2.266.358 2.089a6.16 6.16 0 003.307-2.729c.81-1.332.65-2.266-.359-2.089a6.19 6.19 0 00-3.306 2.729z"
              />
              <path
                  data-name="Path 2288"
                  d="M120.129 141.719c-.822 1.332-.672 2.266.338 2.087a6.215 6.215 0 003.312-2.734c.819-1.332.665-2.264-.339-2.085a6.244 6.244 0 00-3.311 2.732z"
              />
              <path
                  data-name="Path 2289"
                  d="M114.339 142.757c-.829 1.33-.687 2.264.319 2.084a6.27 6.27 0 003.315-2.737c.824-1.33.68-2.263-.321-2.082a6.288 6.288 0 00-3.313 2.735z"
              />
              <path
                  data-name="Path 2290"
                  d="M108.572 143.804c-.836 1.33-.7 2.263.3 2.081a6.32 6.32 0 003.319-2.741c.831-1.33.7-2.263-.3-2.081a6.356 6.356 0 00-3.319 2.741z"
              />
              <path
                  data-name="Path 2291"
                  d="M102.825 144.86c-.841 1.33-.714 2.263.282 2.079a6.389 6.389 0 003.322-2.744c.837-1.332.709-2.263-.284-2.079a6.413 6.413 0 00-3.32 2.744z"
              />
              <path
                  data-name="Path 2292"
                  d="M97.109 145.925c-.846 1.332-.727 2.261.266 2.077a6.444 6.444 0 003.324-2.747c.842-1.332.722-2.261-.267-2.076a6.46 6.46 0 00-3.323 2.746z"
              />
              <path
                  data-name="Path 2293"
                  d="M91.417 147c-.851 1.332-.74 2.261.249 2.074a6.5 6.5 0 003.324-2.749c.849-1.332.735-2.261-.249-2.074A6.519 6.519 0 0091.417 147z"
              />
              <path
                  data-name="Path 2294"
                  d="M85.748 148.082c-.856 1.332-.754 2.259.231 2.07a6.537 6.537 0 003.327-2.751c.852-1.332.747-2.259-.232-2.072a6.58 6.58 0 00-3.326 2.753z"
              />
              <path
                  data-name="Path 2295"
                  d="M80.105 149.177c-.861 1.332-.764 2.258.216 2.069a6.584 6.584 0 003.325-2.754c.859-1.332.762-2.259-.216-2.069a6.624 6.624 0 00-3.325 2.754z"
              />
              <path
                  data-name="Path 2296"
                  d="M74.489 150.273c-.864 1.332-.777 2.258.2 2.067a6.66 6.66 0 003.327-2.757c.862-1.333.77-2.258-.2-2.067a6.684 6.684 0 00-3.327 2.757z"
              />
              <path
                  data-name="Path 2297"
                  d="M68.899 151.38c-.869 1.332-.787 2.256.182 2.064a6.693 6.693 0 003.325-2.761c.867-1.332.784-2.256-.184-2.062a6.713 6.713 0 00-3.323 2.759z"
              />
              <path
                  data-name="Path 2298"
                  d="M63.336 152.497c-.874 1.33-.8 2.253.165 2.06a6.748 6.748 0 003.325-2.762c.871-1.332.794-2.254-.169-2.06a6.754 6.754 0 00-3.321 2.762z"
              />
              <path
                  data-name="Path 2299"
                  d="M57.798 153.62c-.876 1.332-.809 2.251.152 2.057a6.775 6.775 0 003.322-2.764c.876-1.332.805-2.253-.154-2.057a6.808 6.808 0 00-3.32 2.764z"
              />
              <path
                  data-name="Path 2300"
                  d="M52.29 154.749c-.881 1.332-.819 2.251.137 2.055a6.835 6.835 0 003.319-2.766c.877-1.332.815-2.251-.139-2.055a6.854 6.854 0 00-3.317 2.766z"
              />
              <path
                  data-name="Path 2301"
                  d="M46.808 155.888c-.884 1.332-.829 2.248.122 2.052a6.882 6.882 0 003.317-2.767c.881-1.332.824-2.249-.125-2.052a6.891 6.891 0 00-3.314 2.767z"
              />
              <path
                  data-name="Path 2302"
                  d="M983.405 128.314a6.245 6.245 0 003.31 2.7c.984.164 1.1-.78.249-2.109a6.274 6.274 0 00-3.309-2.7c-.979-.162-1.092.782-.25 2.109z"
              />
              <path
                  data-name="Path 2303"
                  d="M977.721 127.377a6.187 6.187 0 003.305 2.7c.988.162 1.108-.784.266-2.112a6.212 6.212 0 00-3.3-2.7c-.986-.157-1.108.785-.271 2.112z"
              />
              <path
                  data-name="Path 2304"
                  d="M972.016 126.453a6.134 6.134 0 003.3 2.692c.991.16 1.12-.785.284-2.112a6.15 6.15 0 00-3.3-2.692c-.986-.16-1.115.786-.284 2.112z"
              />
              <path
                  data-name="Path 2305"
                  d="M937.351 121.122a5.753 5.753 0 003.26 2.664c1.014.15 1.193-.8.4-2.124a5.783 5.783 0 00-3.259-2.662c-1.006-.149-1.186.802-.401 2.122z"
              />
              <path
                  data-name="Path 2306"
                  d="M931.503 120.274a5.7 5.7 0 003.253 2.659c1.016.147 1.2-.805.423-2.127a5.729 5.729 0 00-3.252-2.657c-1.012-.146-1.199.804-.424 2.125z"
              />
              <path
                  data-name="Path 2307"
                  d="M925.637 119.434a5.628 5.628 0 003.248 2.657c1.019.144 1.218-.809.443-2.129a5.651 5.651 0 00-3.242-2.65c-1.019-.148-1.219.804-.449 2.122z"
              />
              <path
                  data-name="Path 2308"
                  d="M919.752 118.608a5.565 5.565 0 003.234 2.649c1.026.144 1.232-.81.464-2.131a5.588 5.588 0 00-3.23-2.645c-1.02-.144-1.229.809-.468 2.127z"
              />
              <path
                  data-name="Path 2309"
                  d="M913.848 117.794a5.5 5.5 0 003.224 2.642c1.028.142 1.245-.814.488-2.132a5.527 5.527 0 00-3.222-2.64c-1.021-.141-1.242.812-.49 2.13z"
              />
              <path
                  data-name="Path 2310"
                  d="M907.928 116.991a5.424 5.424 0 003.212 2.637c1.031.14 1.258-.817.51-2.134a5.467 5.467 0 00-3.212-2.635c-1.027-.138-1.252.815-.51 2.132z"
              />
              <path
                  data-name="Path 2311"
                  d="M901.987 116.199a5.37 5.37 0 003.2 2.632c1.034.137 1.27-.82.531-2.137a5.4 5.4 0 00-3.2-2.629c-1.026-.137-1.265.824-.531 2.134z"
              />
              <path
                  data-name="Path 2312"
                  d="M896.029 115.421a5.3 5.3 0 003.19 2.625c1.038.135 1.287-.822.553-2.137a5.319 5.319 0 00-3.188-2.624c-1.031-.135-1.28.821-.555 2.136z"
              />
              <path
                  data-name="Path 2313"
                  d="M890.054 114.654a5.22 5.22 0 003.178 2.62c1.043.134 1.3-.826.577-2.139a5.25 5.25 0 00-3.177-2.619c-1.033-.131-1.292.825-.578 2.138z"
              />
              <path
                  data-name="Path 2314"
                  d="M884.063 113.9a5.161 5.161 0 003.165 2.615c1.044.13 1.313-.829.6-2.142a5.192 5.192 0 00-3.165-2.612c-1.036-.131-1.305.827-.6 2.139z"
              />
              <path
                  data-name="Path 2315"
                  d="M878.051 113.16a5.107 5.107 0 003.159 2.606c1.044.129 1.325-.832.622-2.146a5.114 5.114 0 00-3.152-2.605c-1.043-.126-1.324.833-.629 2.145z"
              />
              <path
                  data-name="Path 2316"
                  d="M872.027 112.431a5.019 5.019 0 003.14 2.6c1.05.127 1.337-.836.647-2.146a5.051 5.051 0 00-3.14-2.6c-1.041-.123-1.332.835-.647 2.146z"
              />
              <path
                  data-name="Path 2317"
                  d="M865.985 111.717a4.962 4.962 0 003.127 2.6c1.053.124 1.354-.841.67-2.149a4.986 4.986 0 00-3.125-2.6c-1.045-.121-1.347.838-.672 2.149z"
              />
              <path
                  data-name="Path 2318"
                  d="M859.927 111.013a4.894 4.894 0 003.118 2.589c1.054.122 1.365-.842.693-2.152a4.913 4.913 0 00-3.112-2.587c-1.052-.12-1.362.839-.699 2.15z"
              />
              <path
                  data-name="Path 2319"
                  d="M853.855 110.325a4.825 4.825 0 003.1 2.585c1.058.119 1.379-.846.719-2.154a4.847 4.847 0 00-3.1-2.582c-1.05-.119-1.373.842-.719 2.151z"
              />
              <path
                  data-name="Path 2320"
                  d="M847.77 109.652a4.75 4.75 0 003.083 2.579c1.061.119 1.392-.849.744-2.156a4.782 4.782 0 00-3.083-2.575c-1.056-.122-1.391.842-.744 2.152z"
              />
              <path
                  data-name="Path 2321"
                  d="M841.661 108.985a4.688 4.688 0 003.068 2.573c1.063.115 1.407-.852.767-2.157a4.713 4.713 0 00-3.067-2.57c-1.054-.115-1.398.848-.768 2.154z"
              />
              <path
                  data-name="Path 2322"
                  d="M835.543 108.336a4.615 4.615 0 003.051 2.567c1.065.114 1.42-.856.792-2.161a4.643 4.643 0 00-3.05-2.563c-1.057-.112-1.412.853-.793 2.157z"
              />
              <path
                  data-name="Path 2323"
                  d="M829.412 107.7a4.553 4.553 0 003.036 2.56c1.07.11 1.434-.859.819-2.162a4.579 4.579 0 00-3.035-2.557c-1.062-.11-1.431.856-.82 2.159z"
              />
              <path
                  data-name="Path 2324"
                  d="M823.263 107.079a4.486 4.486 0 003.02 2.555c1.071.107 1.447-.864.842-2.166a4.507 4.507 0 00-3.018-2.552c-1.059-.107-1.441.861-.844 2.163z"
              />
              <path
                  data-name="Path 2325"
                  d="M817.101 106.473a4.416 4.416 0 003 2.548c1.073.105 1.462-.866.871-2.167a4.447 4.447 0 00-3-2.545c-1.065-.106-1.456.864-.871 2.164z"
              />
              <path
                  data-name="Path 2326"
                  d="M810.925 105.877a4.354 4.354 0 002.986 2.542c1.076.1 1.476-.871.894-2.171a4.38 4.38 0 00-2.985-2.538c-1.066-.1-1.467.867-.895 2.167z"
              />
              <path
                  data-name="Path 2327"
                  d="M804.737 105.3a4.288 4.288 0 002.968 2.537c1.078.1 1.489-.874.921-2.174a4.312 4.312 0 00-2.966-2.532c-1.07-.102-1.483.869-.923 2.169z"
              />
              <path
                  data-name="Path 2328"
                  d="M798.535 104.733a4.222 4.222 0 002.949 2.53c1.081.1 1.506-.879.947-2.177a4.241 4.241 0 00-2.949-2.525c-1.07-.097-1.495.874-.947 2.172z"
              />
              <path
                  data-name="Path 2329"
                  d="M792.322 104.182a4.145 4.145 0 002.931 2.523c1.081.1 1.516-.881.972-2.179a4.174 4.174 0 00-2.931-2.518c-1.072-.096-1.508.877-.972 2.174z"
              />
              <path
                  data-name="Path 2330"
                  d="M786.095 103.645a4.094 4.094 0 002.913 2.518c1.086.092 1.534-.886 1-2.182a4.117 4.117 0 00-2.913-2.513c-1.074-.092-1.523.882-1 2.177z"
              />
              <path
                  data-name="Path 2331"
                  d="M779.857 103.122a4.021 4.021 0 002.894 2.51c1.086.09 1.547-.887 1.026-2.184a4.049 4.049 0 00-2.893-2.507c-1.078-.087-1.537.887-1.027 2.181z"
              />
              <path
                  data-name="Path 2332"
                  d="M773.607 102.618a3.959 3.959 0 002.874 2.5c1.088.089 1.559-.892 1.053-2.187a3.987 3.987 0 00-2.874-2.5c-1.08-.085-1.551.887-1.053 2.187z"
              />
              <path
                  data-name="Path 2333"
                  d="M767.343 102.119a3.9 3.9 0 002.854 2.5c1.091.085 1.574-.9 1.081-2.191a3.918 3.918 0 00-2.854-2.493c-1.081-.082-1.564.895-1.081 2.184z"
              />
              <path
                  data-name="Path 2334"
                  d="M761.071 101.646a3.829 3.829 0 002.834 2.492c1.093.084 1.589-.9 1.108-2.192a3.857 3.857 0 00-2.834-2.488c-1.083-.083-1.58.896-1.108 2.188z"
              />
              <path
                  data-name="Path 2335"
                  d="M754.787 101.183a3.773 3.773 0 002.812 2.487c1.1.08 1.6-.906 1.135-2.2a3.8 3.8 0 00-2.812-2.482c-1.087-.076-1.594.905-1.135 2.195z"
              />
              <path
                  data-name="Path 2336"
                  d="M748.49 100.736a3.708 3.708 0 002.794 2.48c1.1.077 1.618-.909 1.161-2.2a3.73 3.73 0 00-2.792-2.475c-1.088-.076-1.608.906-1.163 2.195z"
              />
              <path
                  data-name="Path 2337"
                  d="M742.185 100.305a3.649 3.649 0 002.772 2.472c1.1.075 1.631-.912 1.19-2.2a3.666 3.666 0 00-2.772-2.468c-1.088-.077-1.623.906-1.19 2.196z"
              />
              <path
                  data-name="Path 2338"
                  d="M735.871 99.887a3.585 3.585 0 002.751 2.467c1.1.072 1.646-.917 1.217-2.206a3.609 3.609 0 00-2.751-2.463c-1.09-.07-1.637.914-1.217 2.202z"
              />
              <path
                  data-name="Path 2339"
                  d="M729.545 99.484a3.527 3.527 0 002.729 2.461c1.1.069 1.659-.922 1.245-2.211a3.547 3.547 0 00-2.729-2.455c-1.091-.068-1.651.916-1.245 2.205z"
              />
              <path
                  data-name="Path 2340"
                  d="M723.211 99.096a3.47 3.47 0 002.707 2.455c1.1.067 1.674-.926 1.273-2.214a3.5 3.5 0 00-2.707-2.45c-1.095-.063-1.664.925-1.273 2.209z"
              />
              <path
                  data-name="Path 2341"
                  d="M716.867 98.727a3.411 3.411 0 002.685 2.448c1.1.063 1.688-.931 1.3-2.217a3.431 3.431 0 00-2.685-2.443c-1.094-.064-1.678.925-1.3 2.212z"
              />
              <path
                  data-name="Path 2342"
                  d="M710.514 98.371a3.356 3.356 0 002.664 2.441c1.106.062 1.7-.934 1.328-2.219a3.374 3.374 0 00-2.664-2.438c-1.101-.062-1.691.931-1.328 2.216z"
              />
              <path
                  data-name="Path 2343"
                  d="M704.154 98.032a3.292 3.292 0 002.64 2.435c1.108.058 1.716-.937 1.355-2.224a3.311 3.311 0 00-2.64-2.43c-1.098-.058-1.705.932-1.355 2.219z"
              />
              <path
                  data-name="Path 2344"
                  d="M697.783 97.707a3.239 3.239 0 002.619 2.428c1.11.057 1.73-.943 1.384-2.228a3.258 3.258 0 00-2.617-2.425c-1.101-.051-1.72.94-1.386 2.225z"
              />
              <path
                  data-name="Path 2345"
                  d="M691.407 97.398a3.182 3.182 0 002.6 2.423c1.111.053 1.743-.948 1.41-2.231a3.2 3.2 0 00-2.594-2.42c-1.106-.051-1.737.945-1.416 2.228z"
              />
              <path
                  data-name="Path 2346"
                  d="M685.022 97.106a3.127 3.127 0 002.572 2.416c1.111.05 1.758-.951 1.439-2.236a3.143 3.143 0 00-2.57-2.411c-1.104-.05-1.747.947-1.441 2.231z"
              />
              <path
                  data-name="Path 2347"
                  d="M678.63 96.829a3.076 3.076 0 002.547 2.411c1.115.048 1.771-.956 1.469-2.239a3.089 3.089 0 00-2.547-2.406c-1.103-.047-1.762.95-1.469 2.234z"
              />
              <path
                  data-name="Path 2348"
                  d="M672.23 96.568a3.018 3.018 0 002.523 2.4c1.115.045 1.785-.961 1.5-2.243a3.036 3.036 0 00-2.523-2.4c-1.108-.04-1.779.959-1.5 2.243z"
              />
              <path
                  data-name="Path 2349"
                  d="M665.824 96.322a2.966 2.966 0 002.5 2.4c1.116.042 1.8-.966 1.524-2.248a2.985 2.985 0 00-2.5-2.395c-1.105-.041-1.787.961-1.524 2.243z"
              />
              <path
                  data-name="Path 2350"
                  d="M659.412 96.093a2.912 2.912 0 002.475 2.393c1.12.04 1.813-.969 1.552-2.251a2.928 2.928 0 00-2.475-2.388c-1.106-.04-1.801.965-1.552 2.246z"
              />
              <path
                  data-name="Path 2351"
                  d="M652.994 95.88a2.863 2.863 0 002.45 2.388c1.12.037 1.828-.974 1.582-2.256a2.88 2.88 0 00-2.451-2.381c-1.108-.037-1.815.969-1.581 2.249z"
              />
              <path
                  data-name="Path 2352"
                  d="M646.57 95.684a2.814 2.814 0 002.425 2.381c1.12.033 1.841-.979 1.611-2.261a2.829 2.829 0 00-2.426-2.375c-1.11-.035-1.831.978-1.61 2.255z"
              />
              <path
                  data-name="Path 2353"
                  d="M640.14 95.504a2.765 2.765 0 002.4 2.375 1.748 1.748 0 001.638-2.264 2.779 2.779 0 00-2.4-2.37 1.753 1.753 0 00-1.638 2.259z"
              />
              <path
                  data-name="Path 2354"
                  d="M633.704 95.338a2.715 2.715 0 002.376 2.37 1.775 1.775 0 001.666-2.268 2.729 2.729 0 00-2.375-2.365 1.781 1.781 0 00-1.667 2.263z"
              />
              <path
                  data-name="Path 2355"
                  d="M627.266 95.189a2.668 2.668 0 002.35 2.365 1.8 1.8 0 001.694-2.273 2.683 2.683 0 00-2.349-2.36 1.81 1.81 0 00-1.695 2.268z"
              />
              <path
                  data-name="Path 2356"
                  d="M620.82 95.057a2.624 2.624 0 002.326 2.36 1.833 1.833 0 001.723-2.278 2.636 2.636 0 00-2.326-2.353 1.839 1.839 0 00-1.723 2.271z"
              />
              <path
                  data-name="Path 2357"
                  d="M614.373 94.942a2.576 2.576 0 002.3 2.353 1.861 1.861 0 001.75-2.283 2.588 2.588 0 00-2.3-2.346 1.866 1.866 0 00-1.75 2.276z"
              />
              <path
                  data-name="Path 2358"
                  d="M607.92 94.843a2.53 2.53 0 002.274 2.346 1.89 1.89 0 001.78-2.286 2.543 2.543 0 00-2.274-2.341 1.9 1.9 0 00-1.78 2.281z"
              />
              <path
                  data-name="Path 2359"
                  d="M601.465 94.76a2.485 2.485 0 002.248 2.341 1.922 1.922 0 001.808-2.291 2.5 2.5 0 00-2.248-2.336 1.928 1.928 0 00-1.808 2.286z"
              />
              <path
                  data-name="Path 2360"
                  d="M595.004 94.695a2.445 2.445 0 002.223 2.336 1.952 1.952 0 001.835-2.3 2.454 2.454 0 00-2.221-2.331 1.964 1.964 0 00-1.837 2.295z"
              />
              <path
                  data-name="Path 2361"
                  d="M588.542 94.642a2.4 2.4 0 002.2 2.331 1.986 1.986 0 001.858-2.3 2.409 2.409 0 00-2.194-2.324 2 2 0 00-1.864 2.293z"
              />
              <path
                  data-name="Path 2362"
                  d="M582.079 94.609a2.359 2.359 0 002.169 2.326 2.021 2.021 0 001.892-2.306 2.373 2.373 0 00-2.169-2.321 2.03 2.03 0 00-1.892 2.301z"
              />
              <path
                  data-name="Path 2363"
                  d="M575.608 94.593a2.319 2.319 0 002.144 2.319 2.053 2.053 0 001.918-2.311 2.329 2.329 0 00-2.142-2.314 2.065 2.065 0 00-1.92 2.306z"
              />
              <path
                  data-name="Path 2364"
                  d="M569.141 94.593a2.279 2.279 0 002.116 2.314 2.09 2.09 0 001.948-2.316 2.287 2.287 0 00-2.116-2.309 2.1 2.1 0 00-1.948 2.311z"
              />
              <path
                  data-name="Path 2365"
                  d="M562.67 94.608a2.24 2.24 0 002.089 2.309 2.125 2.125 0 001.975-2.321 2.25 2.25 0 00-2.089-2.3 2.135 2.135 0 00-1.975 2.312z"
              />
              <path
                  data-name="Path 2366"
                  d="M556.198 94.639a2.2 2.2 0 002.062 2.3 2.161 2.161 0 002-2.326 2.212 2.212 0 00-2.062-2.3 2.172 2.172 0 00-2 2.326z"
              />
              <path
                  data-name="Path 2367"
                  d="M549.725 94.69a2.163 2.163 0 002.035 2.3 2.2 2.2 0 002.03-2.331 2.175 2.175 0 00-2.035-2.294 2.21 2.21 0 00-2.03 2.325z"
              />
              <path
                  data-name="Path 2368"
                  d="M543.249 94.755a2.126 2.126 0 002.009 2.293 2.238 2.238 0 002.059-2.336 2.138 2.138 0 00-2.009-2.289 2.251 2.251 0 00-2.059 2.332z"
              />
              <path
                  data-name="Path 2369"
                  d="M536.775 94.834a2.089 2.089 0 001.982 2.288 2.274 2.274 0 002.084-2.341 2.1 2.1 0 00-1.98-2.284 2.288 2.288 0 00-2.086 2.337z"
              />
              <path
                  data-name="Path 2371"
                  d="M523.826 95.049a2.021 2.021 0 001.927 2.279 2.359 2.359 0 002.139-2.353 2.03 2.03 0 00-1.927-2.274 2.368 2.368 0 00-2.139 2.348z"
              />
              <path
                  data-name="Path 2372"
                  d="M517.351 95.181a1.99 1.99 0 001.9 2.274 2.4 2.4 0 002.167-2.36 2 2 0 00-1.9-2.269 2.413 2.413 0 00-2.167 2.355z"
              />
              <path
                  data-name="Path 2373"
                  d="M350.258 104.282c-.451 1.3.08 2.268 1.185 2.172a3.779 3.779 0 002.8-2.518c.441-1.293-.09-2.263-1.186-2.167a3.8 3.8 0 00-2.799 2.513z"
              />
              <path
                  data-name="Path 2374"
                  d="M343.918 104.845c-.465 1.3.055 2.268 1.158 2.169a3.842 3.842 0 002.821-2.525c.454-1.3-.065-2.263-1.158-2.164a3.861 3.861 0 00-2.821 2.52z"
              />
              <path
                  data-name="Path 2375"
                  d="M337.59 105.422c-.478 1.3.03 2.266 1.131 2.166a3.9 3.9 0 002.842-2.53c.466-1.3-.04-2.263-1.133-2.162a3.929 3.929 0 00-2.84 2.526z"
              />
              <path
                  data-name="Path 2376"
                  d="M331.272 106.014c-.49 1.3.005 2.266 1.1 2.162a3.97 3.97 0 002.867-2.535c.48-1.3-.015-2.263-1.106-2.159a3.988 3.988 0 00-2.861 2.532z"
              />
              <path
                  data-name="Path 2377"
                  d="M324.964 106.621c-.5 1.3-.02 2.266 1.079 2.161a4.037 4.037 0 002.881-2.543c.493-1.3.008-2.263-1.079-2.157a4.058 4.058 0 00-2.881 2.539z"
              />
              <path
                  data-name="Path 2378"
                  d="M318.666 107.242c-.515 1.3-.043 2.268 1.053 2.157a4.1 4.1 0 002.9-2.548c.506-1.3.035-2.263-1.053-2.154a4.12 4.12 0 00-2.9 2.545z"
              />
              <path
                  data-name="Path 2379"
                  d="M312.379 107.882c-.528 1.3-.067 2.266 1.028 2.156a4.168 4.168 0 002.919-2.557c.518-1.3.059-2.261-1.028-2.151a4.187 4.187 0 00-2.919 2.552z"
              />
              <path
                  data-name="Path 2380"
                  d="M306.104 108.531c-.54 1.3-.092 2.266 1 2.152a4.23 4.23 0 002.939-2.563c.53-1.3.082-2.261-1-2.147a4.252 4.252 0 00-2.939 2.558z"
              />
              <path
                  data-name="Path 2381"
                  d="M299.839 109.198c-.551 1.3-.114 2.264.976 2.149a4.3 4.3 0 002.958-2.57c.541-1.3.105-2.261-.976-2.146a4.329 4.329 0 00-2.958 2.567z"
              />
              <path
                  data-name="Path 2382"
                  d="M293.588 109.876c-.563 1.3-.139 2.266.951 2.147a4.364 4.364 0 002.974-2.575c.555-1.3.13-2.261-.949-2.142a4.387 4.387 0 00-2.976 2.57z"
              />
              <path
                  data-name="Path 2383"
                  d="M281.121 111.28c-.587 1.305-.184 2.264.9 2.141a4.5 4.5 0 003.011-2.587c.578-1.305.175-2.261-.9-2.139a4.53 4.53 0 00-3.011 2.585z"
              />
              <path
                  data-name="Path 2384"
                  d="M274.909 112.002c-.6 1.307-.207 2.266.874 2.139a4.567 4.567 0 003.026-2.594c.592-1.307.2-2.261-.874-2.136a4.588 4.588 0 00-3.026 2.591z"
              />
              <path
                  data-name="Path 2385"
                  d="M268.707 112.739c-.61 1.307-.229 2.264.851 2.136a4.635 4.635 0 003.043-2.6c.6-1.307.222-2.261-.849-2.132a4.663 4.663 0 00-3.045 2.596z"
              />
              <path
                  data-name="Path 2386"
                  d="M262.521 113.489c-.622 1.308-.252 2.263.824 2.132a4.7 4.7 0 003.061-2.605c.612-1.308.244-2.261-.826-2.131a4.732 4.732 0 00-3.059 2.604z"
              />
              <path
                  data-name="Path 2387"
                  d="M256.348 114.251c-.632 1.31-.274 2.266.8 2.132a4.776 4.776 0 003.076-2.614c.623-1.308.264-2.259-.8-2.127a4.8 4.8 0 00-3.076 2.609z"
              />
              <path
                  data-name="Path 2388"
                  d="M250.19 115.027c-.643 1.31-.3 2.264.775 2.129a4.838 4.838 0 003.091-2.619c.635-1.308.287-2.259-.777-2.124a4.861 4.861 0 00-3.089 2.614z"
              />
              <path
                  data-name="Path 2389"
                  d="M244.047 115.819c-.655 1.312-.319 2.266.75 2.127a4.909 4.909 0 003.107-2.625c.645-1.31.309-2.259-.752-2.122a4.934 4.934 0 00-3.105 2.62z"
              />
              <path
                  data-name="Path 2390"
                  d="M237.918 116.622c-.665 1.313-.339 2.264.727 2.124a4.974 4.974 0 003.122-2.63c.657-1.312.329-2.259-.729-2.121a5.006 5.006 0 00-3.12 2.627z"
              />
              <path
                  data-name="Path 2391"
                  d="M231.8 117.439c-.675 1.313-.361 2.264.7 2.122a5.041 5.041 0 003.133-2.637c.667-1.312.353-2.261-.7-2.119a5.068 5.068 0 00-3.133 2.634z"
              />
              <path
                  data-name="Path 2392"
                  d="M225.707 118.27c-.685 1.313-.381 2.263.68 2.119a5.118 5.118 0 003.148-2.642c.677-1.313.371-2.261-.682-2.117a5.145 5.145 0 00-3.146 2.64z"
              />
              <path
                  data-name="Path 2393"
                  d="M219.625 119.112c-.694 1.315-.4 2.264.658 2.117a5.179 5.179 0 003.16-2.649c.688-1.315.393-2.259-.658-2.114a5.216 5.216 0 00-3.16 2.646z"
              />
              <path
                  data-name="Path 2394"
                  d="M213.561 119.966c-.705 1.317-.421 2.264.635 2.116a5.249 5.249 0 003.173-2.654c.7-1.315.411-2.259-.637-2.112a5.277 5.277 0 00-3.171 2.65z"
              />
              <path
                  data-name="Path 2395"
                  d="M207.513 120.84c-.715 1.317-.441 2.263.612 2.112a5.326 5.326 0 003.185-2.659c.707-1.317.431-2.261-.613-2.111a5.345 5.345 0 00-3.184 2.658z"
              />
              <path
                  data-name="Path 2396"
                  d="M201.48 121.714c-.724 1.318-.46 2.264.59 2.111a5.386 5.386 0 003.2-2.664c.717-1.317.451-2.261-.59-2.109a5.414 5.414 0 00-3.2 2.662z"
              />
              <path
                  data-name="Path 2397"
                  d="M195.466 122.607c-.732 1.32-.478 2.263.568 2.109a5.461 5.461 0 003.208-2.67c.725-1.318.47-2.261-.568-2.106a5.487 5.487 0 00-3.208 2.667z"
              />
              <path
                  data-name="Path 2398"
                  d="M189.47 123.508c-.742 1.32-.5 2.264.545 2.106a5.521 5.521 0 003.22-2.674c.734-1.32.49-2.261-.546-2.1a5.547 5.547 0 00-3.219 2.668z"
              />
              <path
                  data-name="Path 2399"
                  d="M183.492 124.426c-.75 1.322-.516 2.264.523 2.1a5.583 5.583 0 003.229-2.68c.744-1.318.51-2.259-.523-2.1a5.61 5.61 0 00-3.229 2.68z"
              />
              <path
                  data-name="Path 2400"
                  d="M177.532 125.356c-.757 1.32-.533 2.263.5 2.1a5.644 5.644 0 003.239-2.685c.752-1.32.526-2.259-.5-2.1a5.686 5.686 0 00-3.239 2.685z"
              />
              <path
                  data-name="Path 2401"
                  d="M171.591 126.294c-.767 1.324-.551 2.261.481 2.1a5.719 5.719 0 003.249-2.69c.76-1.322.545-2.261-.483-2.1a5.747 5.747 0 00-3.247 2.69z"
              />
              <path
                  data-name="Path 2402"
                  d="M165.671 127.245c-.775 1.322-.57 2.263.46 2.1a5.787 5.787 0 003.257-2.7c.769-1.323.561-2.259-.461-2.1a5.8 5.8 0 00-3.256 2.7z"
              />
              <path
                  data-name="Path 2403"
                  d="M159.769 128.206c-.784 1.325-.587 2.263.44 2.1a5.848 5.848 0 003.265-2.7c.775-1.323.578-2.259-.441-2.094a5.874 5.874 0 00-3.264 2.694z"
              />
              <path
                  data-name="Path 2404"
                  d="M153.886 129.181c-.79 1.323-.6 2.261.419 2.092a5.909 5.909 0 003.272-2.705c.785-1.323.6-2.259-.421-2.091a5.939 5.939 0 00-3.27 2.704z"
              />
              <path
                  data-name="Path 2405"
                  d="M148.024 130.164c-.8 1.325-.618 2.261.4 2.09a5.969 5.969 0 003.28-2.709c.792-1.325.612-2.261-.4-2.089a6 6 0 00-3.28 2.708z"
              />
              <path
                  data-name="Path 2406"
                  d="M142.182 131.161c-.805 1.327-.635 2.263.381 2.089a6.043 6.043 0 003.285-2.714c.8-1.325.628-2.261-.381-2.087a6.059 6.059 0 00-3.285 2.712z"
              />
              <path
                  data-name="Path 2407"
                  d="M136.362 132.163c-.812 1.327-.65 2.261.361 2.085a6.1 6.1 0 003.292-2.719c.805-1.325.643-2.259-.363-2.084a6.119 6.119 0 00-3.29 2.718z"
              />
              <path
                  data-name="Path 2408"
                  d="M130.561 133.178c-.817 1.329-.665 2.261.343 2.084a6.15 6.15 0 003.3-2.722c.814-1.327.66-2.259-.343-2.082a6.189 6.189 0 00-3.3 2.72z"
              />
              <path
                  data-name="Path 2409"
                  d="M124.784 134.203c-.826 1.327-.68 2.259.324 2.082a6.233 6.233 0 003.3-2.727c.819-1.327.673-2.259-.326-2.08a6.234 6.234 0 00-3.298 2.725z"
              />
              <path
                  data-name="Path 2410"
                  d="M119.027 135.238c-.831 1.328-.693 2.261.306 2.081a6.288 6.288 0 003.307-2.731c.825-1.328.688-2.259-.307-2.079a6.3 6.3 0 00-3.306 2.729z"
              />
              <path
                  data-name="Path 2411"
                  d="M113.294 136.284c-.836 1.329-.707 2.258.287 2.077a6.336 6.336 0 003.31-2.736c.834-1.328.7-2.256-.289-2.075a6.358 6.358 0 00-3.308 2.734z"
              />
              <path
                  data-name="Path 2412"
                  d="M107.584 137.339c-.842 1.328-.722 2.258.269 2.075a6.4 6.4 0 003.314-2.739c.839-1.329.715-2.258-.272-2.074a6.4 6.4 0 00-3.311 2.738z"
              />
              <path
                  data-name="Path 2413"
                  d="M101.895 138.403c-.847 1.329-.735 2.258.252 2.072a6.456 6.456 0 003.317-2.742c.844-1.329.729-2.258-.256-2.072a6.473 6.473 0 00-3.313 2.742z"
              />
              <path
                  data-name="Path 2414"
                  d="M96.231 139.476c-.852 1.33-.747 2.256.237 2.07a6.509 6.509 0 003.317-2.746c.849-1.33.742-2.256-.239-2.07a6.532 6.532 0 00-3.315 2.746z"
              />
              <path
                  data-name="Path 2415"
                  d="M90.589 140.557c-.857 1.332-.759 2.256.221 2.069a6.564 6.564 0 003.32-2.749c.852-1.33.754-2.256-.222-2.067a6.575 6.575 0 00-3.319 2.747z"
              />
              <path
                  data-name="Path 2416"
                  d="M84.972 141.648c-.862 1.33-.77 2.256.2 2.065a6.6 6.6 0 003.32-2.751c.859-1.33.765-2.254-.206-2.065a6.626 6.626 0 00-3.314 2.751z"
              />
              <path
                  data-name="Path 2417"
                  d="M79.38 142.748c-.866 1.332-.78 2.254.189 2.064a6.657 6.657 0 003.323-2.756c.864-1.33.777-2.253-.19-2.062a6.677 6.677 0 00-3.322 2.754z"
              />
              <path
                  data-name="Path 2418"
                  d="M73.812 143.855c-.869 1.33-.792 2.254.174 2.06a6.7 6.7 0 003.32-2.757c.867-1.33.787-2.253-.175-2.06a6.73 6.73 0 00-3.319 2.757z"
              />
              <path
                  data-name="Path 2419"
                  d="M68.271 144.972c-.874 1.33-.8 2.253.159 2.059a6.771 6.771 0 003.319-2.761c.871-1.33.8-2.253-.162-2.059a6.778 6.778 0 00-3.316 2.761z"
              />
              <path
                  data-name="Path 2420"
                  d="M62.753 146.095c-.877 1.332-.812 2.251.145 2.057a6.8 6.8 0 003.317-2.764c.874-1.332.807-2.249-.147-2.055a6.82 6.82 0 00-3.315 2.762z"
              />
              <path
                  data-name="Path 2421"
                  d="M57.262 147.227c-.881 1.332-.822 2.249.13 2.054a6.834 6.834 0 003.315-2.766c.877-1.33.817-2.249-.134-2.052a6.847 6.847 0 00-3.311 2.764z"
              />
              <path
                  data-name="Path 2422"
                  d="M1006.574 125.62a6.533 6.533 0 003.315 2.717c.961.17 1.029-.767.157-2.1a6.55 6.55 0 00-3.314-2.715c-.956-.168-1.027.769-.158 2.098z"
              />
              <path
                  data-name="Path 2423"
                  d="M984.267 121.758a6.31 6.31 0 003.3 2.7c.974.165 1.075-.777.22-2.1a6.32 6.32 0 00-3.3-2.7c-.969-.166-1.069.774-.22 2.1z"
              />
              <path
                  data-name="Path 2424"
                  d="M978.637 120.816a6.246 6.246 0 003.3 2.694c.979.164 1.086-.779.239-2.1a6.279 6.279 0 00-3.3-2.694c-.974-.163-1.081.775-.239 2.1z"
              />
              <path
                  data-name="Path 2425"
                  d="M972.989 119.886a6.2 6.2 0 003.3 2.689c.983.16 1.1-.782.256-2.106a6.214 6.214 0 00-3.294-2.689c-.987-.16-1.098.782-.262 2.106z"
              />
              <path
                  data-name="Path 2426"
                  d="M915.445 111.17a5.567 5.567 0 003.22 2.637c1.016.142 1.215-.809.448-2.122a5.581 5.581 0 00-3.219-2.634c-1.009-.142-1.209.807-.449 2.119z"
              />
              <path
                  data-name="Path 2427"
                  d="M909.591 110.361a5.5 5.5 0 003.208 2.63c1.019.14 1.23-.81.47-2.122a5.514 5.514 0 00-3.207-2.629c-1.014-.138-1.224.81-.471 2.121z"
              />
              <path
                  data-name="Path 2428"
                  d="M903.721 109.566a5.427 5.427 0 003.2 2.624c1.023.139 1.242-.814.49-2.126a5.449 5.449 0 00-3.2-2.62c-1.016-.139-1.234.812-.49 2.122z"
              />
              <path
                  data-name="Path 2429"
                  d="M897.835 108.78a5.364 5.364 0 003.187 2.619c1.026.135 1.255-.817.513-2.127a5.39 5.39 0 00-3.185-2.615c-1.02-.136-1.253.815-.515 2.123z"
              />
              <path
                  data-name="Path 2430"
                  d="M891.928 108.003a5.3 5.3 0 003.175 2.612c1.028.134 1.268-.82.535-2.129a5.321 5.321 0 00-3.173-2.609c-1.021-.129-1.262.826-.537 2.126z"
              />
              <path
                  data-name="Path 2431"
                  d="M886.008 107.248a5.217 5.217 0 003.163 2.605c1.031.134 1.28-.822.557-2.129a5.246 5.246 0 00-3.162-2.6c-1.023-.134-1.271.817-.558 2.124z"
              />
              <path
                  data-name="Path 2432"
                  d="M880.07 106.497a5.163 5.163 0 003.152 2.6c1.033.129 1.292-.827.58-2.132a5.183 5.183 0 00-3.15-2.6c-1.026-.122-1.287.832-.582 2.132z"
              />
              <path
                  data-name="Path 2433"
                  d="M874.118 105.765a5.086 5.086 0 003.138 2.594c1.036.127 1.305-.829.6-2.134a5.114 5.114 0 00-3.137-2.59c-1.026-.127-1.296.827-.601 2.13z"
              />
              <path
                  data-name="Path 2434"
                  d="M868.15 105.044a5.019 5.019 0 003.123 2.587c1.039.125 1.318-.832.627-2.134a5.04 5.04 0 00-3.122-2.585c-1.032-.126-1.311.828-.628 2.132z"
              />
              <path
                  data-name="Path 2435"
                  d="M862.168 104.335a4.949 4.949 0 003.11 2.58c1.043.124 1.332-.836.65-2.137a4.975 4.975 0 00-3.11-2.577c-1.033-.122-1.325.832-.65 2.134z"
              />
              <path
                  data-name="Path 2436"
                  d="M856.171 103.638a4.881 4.881 0 003.095 2.575c1.044.12 1.345-.837.673-2.139a4.909 4.909 0 00-3.095-2.572c-1.036-.12-1.338.836-.673 2.136z"
              />
              <path
                  data-name="Path 2437"
                  d="M850.158 102.956a4.819 4.819 0 003.08 2.568c1.046.119 1.358-.842.7-2.142a4.835 4.835 0 00-3.078-2.565c-1.043-.119-1.355.839-.702 2.139z"
              />
              <path
                  data-name="Path 2438"
                  d="M844.131 102.286a4.752 4.752 0 003.065 2.562c1.049.117 1.374-.844.722-2.144a4.767 4.767 0 00-3.065-2.558c-1.044-.116-1.366.842-.722 2.14z"
              />
              <path
                  data-name="Path 2439"
                  d="M838.088 101.629a4.678 4.678 0 003.05 2.557c1.053.114 1.385-.849.745-2.146a4.694 4.694 0 00-3.048-2.552c-1.047-.113-1.376.849-.747 2.141z"
              />
              <path
                  data-name="Path 2440"
                  d="M832.035 100.988a4.6 4.6 0 003.033 2.548c1.053.112 1.4-.851.77-2.147a4.632 4.632 0 00-3.033-2.545c-1.045-.112-1.391.847-.77 2.144z"
              />
              <path
                  data-name="Path 2441"
                  d="M825.966 100.358a4.543 4.543 0 003.016 2.543c1.06.109 1.414-.854.8-2.149a4.564 4.564 0 00-3.016-2.54c-1.052-.108-1.409.853-.8 2.146z"
              />
              <path
                  data-name="Path 2442"
                  d="M819.89 99.743a4.472 4.472 0 003 2.537c1.059.107 1.427-.859.82-2.152a4.49 4.49 0 00-3-2.532c-1.054-.108-1.42.853-.82 2.147z"
              />
              <path
                  data-name="Path 2443"
                  d="M813.789 99.142a4.405 4.405 0 002.983 2.53c1.063.105 1.44-.862.847-2.154a4.43 4.43 0 00-2.981-2.527c-1.051-.102-1.435.859-.849 2.151z"
              />
              <path
                  data-name="Path 2444"
                  d="M807.683 98.553a4.34 4.34 0 002.964 2.525c1.065.1 1.456-.866.872-2.157a4.358 4.358 0 00-2.965-2.518c-1.055-.105-1.444.86-.871 2.15z"
              />
              <path
                  data-name="Path 2445"
                  d="M801.562 97.98a4.276 4.276 0 002.948 2.518c1.066.1 1.467-.869.9-2.159a4.29 4.29 0 00-2.946-2.513c-1.059-.099-1.464.865-.902 2.154z"
              />
              <path
                  data-name="Path 2446"
                  d="M795.43 97.422a4.2 4.2 0 002.928 2.51c1.071.1 1.484-.871.924-2.161a4.221 4.221 0 00-2.928-2.507c-1.059-.095-1.467.87-.924 2.158z"
              />
              <path
                  data-name="Path 2447"
                  d="M789.286 96.877a4.141 4.141 0 002.911 2.5c1.069.1 1.494-.876.949-2.164a4.159 4.159 0 00-2.909-2.5c-1.063-.091-1.487.876-.951 2.164z"
              />
              <path
                  data-name="Path 2448"
                  d="M783.13 96.346a4.076 4.076 0 002.893 2.5c1.073.092 1.509-.879.976-2.166a4.1 4.1 0 00-2.891-2.493c-1.065-.093-1.501.874-.978 2.159z"
              />
              <path
                  data-name="Path 2449"
                  d="M776.964 95.829a4.009 4.009 0 002.873 2.492c1.074.09 1.522-.882 1-2.169a4.035 4.035 0 00-2.871-2.487c-1.066-.088-1.513.879-1.002 2.164z"
              />
              <path
                  data-name="Path 2450"
                  d="M770.784 95.328a3.948 3.948 0 002.854 2.485c1.076.087 1.536-.886 1.028-2.171a3.961 3.961 0 00-2.851-2.48c-1.066-.087-1.529.881-1.031 2.166z"
              />
              <path
                  data-name="Path 2451"
                  d="M764.596 94.842a3.881 3.881 0 002.832 2.478c1.078.084 1.552-.891 1.056-2.174a3.9 3.9 0 00-2.832-2.473c-1.07-.084-1.543.886-1.056 2.169z"
              />
              <path
                  data-name="Path 2452"
                  d="M758.397 94.369a3.811 3.811 0 002.812 2.472c1.08.082 1.564-.894 1.083-2.176a3.842 3.842 0 00-2.812-2.468c-1.07-.08-1.556.891-1.083 2.172z"
              />
              <path
                  data-name="Path 2453"
                  d="M752.187 93.913a3.754 3.754 0 002.791 2.465c1.083.079 1.579-.9 1.111-2.179a3.778 3.778 0 00-2.792-2.461c-1.072-.08-1.57.888-1.11 2.175z"
              />
              <path
                  data-name="Path 2454"
                  d="M745.967 93.47a3.687 3.687 0 002.771 2.458c1.085.077 1.591-.9 1.136-2.182a3.709 3.709 0 00-2.769-2.453c-1.076-.077-1.584.897-1.138 2.177z"
              />
              <path
                  data-name="Path 2455"
                  d="M739.737 93.042a3.632 3.632 0 002.749 2.453c1.086.074 1.606-.907 1.165-2.186a3.653 3.653 0 00-2.749-2.448c-1.076-.073-1.598.903-1.165 2.181z"
              />
              <path
                  data-name="Path 2456"
                  d="M733.497 92.629a3.571 3.571 0 002.729 2.446c1.088.074 1.621-.909 1.192-2.189a3.589 3.589 0 00-2.727-2.44c-1.079-.07-1.612.904-1.194 2.183z"
              />
              <path
                  data-name="Path 2457"
                  d="M727.251 92.233a3.51 3.51 0 002.707 2.44c1.09.069 1.636-.914 1.218-2.192a3.525 3.525 0 00-2.706-2.435c-1.078-.069-1.625.91-1.219 2.187z"
              />
              <path
                  data-name="Path 2458"
                  d="M720.993 91.85a3.446 3.446 0 002.684 2.433c1.091.067 1.649-.917 1.247-2.194a3.466 3.466 0 00-2.682-2.428c-1.082-.068-1.64.913-1.249 2.189z"
              />
              <path
                  data-name="Path 2459"
                  d="M714.729 91.484a3.386 3.386 0 002.662 2.426c1.091.063 1.663-.922 1.273-2.2a3.41 3.41 0 00-2.66-2.423c-1.084-.06-1.654.922-1.275 2.197z"
              />
              <path
                  data-name="Path 2460"
                  d="M708.454 91.134a3.329 3.329 0 002.64 2.42c1.095.062 1.676-.926 1.3-2.2a3.352 3.352 0 00-2.639-2.416c-1.083-.062-1.666.921-1.301 2.196z"
              />
              <path
                  data-name="Path 2461"
                  d="M702.171 90.798a3.279 3.279 0 002.617 2.415c1.1.057 1.69-.932 1.33-2.206a3.293 3.293 0 00-2.617-2.41c-1.083-.057-1.679.926-1.33 2.201z"
              />
              <path
                  data-name="Path 2462"
                  d="M695.882 90.477a3.217 3.217 0 002.594 2.408c1.1.055 1.7-.936 1.359-2.209a3.236 3.236 0 00-2.594-2.4c-1.087-.058-1.693.928-1.359 2.201z"
              />
              <path
                  data-name="Path 2463"
                  d="M689.585 90.172a3.159 3.159 0 002.57 2.4c1.1.053 1.718-.939 1.385-2.213a3.176 3.176 0 00-2.569-2.4c-1.087-.047-1.708.94-1.386 2.213z"
              />
              <path
                  data-name="Path 2464"
                  d="M683.28 89.884a3.107 3.107 0 002.547 2.395c1.1.05 1.731-.944 1.414-2.216a3.12 3.12 0 00-2.545-2.39c-1.089-.05-1.722.939-1.416 2.211z"
              />
              <path
                  data-name="Path 2465"
                  d="M676.97 89.61a3.049 3.049 0 002.522 2.39c1.1.047 1.746-.947 1.442-2.219a3.067 3.067 0 00-2.522-2.385c-1.089-.047-1.736.944-1.442 2.214z"
              />
              <path
                  data-name="Path 2466"
                  d="M670.65 89.352a3 3 0 002.5 2.383c1.1.045 1.76-.953 1.47-2.223a3.011 3.011 0 00-2.5-2.378c-1.089-.043-1.747.945-1.47 2.218z"
              />
              <path
                  data-name="Path 2467"
                  d="M664.328 89.11a2.944 2.944 0 002.473 2.378c1.1.042 1.775-.958 1.5-2.228a2.956 2.956 0 00-2.473-2.371c-1.094-.041-1.764.951-1.5 2.221z"
              />
              <path
                  data-name="Path 2468"
                  d="M657.999 88.888a2.892 2.892 0 002.45 2.371c1.1.038 1.786-.961 1.526-2.231a2.909 2.909 0 00-2.45-2.366c-1.092-.042-1.777.952-1.526 2.226z"
              />
              <path
                  data-name="Path 2469"
                  d="M651.661 88.674a2.844 2.844 0 002.425 2.366c1.106.035 1.8-.966 1.556-2.236a2.857 2.857 0 00-2.425-2.36c-1.093-.036-1.791.958-1.556 2.23z"
              />
              <path
                  data-name="Path 2470"
                  d="M645.32 88.48a2.792 2.792 0 002.4 2.36c1.1.033 1.815-.971 1.582-2.239a2.808 2.808 0 00-2.4-2.355c-1.092-.034-1.801.965-1.582 2.234z"
              />
              <path
                  data-name="Path 2471"
                  d="M638.975 88.301a2.743 2.743 0 002.375 2.355c1.106.03 1.827-.976 1.611-2.244a2.754 2.754 0 00-2.375-2.348c-1.094-.031-1.818.97-1.611 2.237z"
              />
              <path
                  data-name="Path 2472"
                  d="M632.623 88.139a2.693 2.693 0 002.349 2.348 1.752 1.752 0 001.639-2.248 2.707 2.707 0 00-2.35-2.343 1.76 1.76 0 00-1.638 2.243z"
              />
              <path
                  data-name="Path 2473"
                  d="M626.266 87.994a2.645 2.645 0 002.324 2.341 1.781 1.781 0 001.669-2.251 2.661 2.661 0 00-2.324-2.338 1.788 1.788 0 00-1.669 2.248z"
              />
              <path
                  data-name="Path 2474"
                  d="M619.907 87.868a2.6 2.6 0 002.3 2.336 1.809 1.809 0 001.7-2.256 2.611 2.611 0 00-2.3-2.331 1.817 1.817 0 00-1.7 2.251z"
              />
              <path
                  data-name="Path 2475"
                  d="M613.543 87.75a2.55 2.55 0 002.273 2.329 1.839 1.839 0 001.725-2.261 2.567 2.567 0 00-2.273-2.324 1.846 1.846 0 00-1.725 2.256z"
              />
              <path
                  data-name="Path 2476"
                  d="M607.179 87.651a2.509 2.509 0 002.248 2.326 1.87 1.87 0 001.753-2.266 2.518 2.518 0 00-2.248-2.319 1.875 1.875 0 00-1.753 2.259z"
              />
              <path
                  data-name="Path 2477"
                  d="M600.802 87.569a2.463 2.463 0 002.223 2.319 1.9 1.9 0 001.78-2.269 2.473 2.473 0 00-2.221-2.314 1.908 1.908 0 00-1.782 2.264z"
              />
              <path
                  data-name="Path 2478"
                  d="M594.429 87.504a2.419 2.419 0 002.194 2.314 1.933 1.933 0 001.81-2.274 2.435 2.435 0 00-2.2-2.309 1.94 1.94 0 00-1.804 2.269z"
              />
              <path
                  data-name="Path 2479"
                  d="M588.05 87.455a2.377 2.377 0 002.169 2.308 1.962 1.962 0 001.837-2.279 2.386 2.386 0 00-2.167-2.3 1.973 1.973 0 00-1.839 2.271z"
              />
              <path
                  data-name="Path 2480"
                  d="M581.672 87.422a2.333 2.333 0 002.142 2.3 2 2 0 001.865-2.284 2.346 2.346 0 00-2.142-2.3 2 2 0 00-1.865 2.284z"
              />
              <path
                  data-name="Path 2481"
                  d="M575.289 87.405a2.294 2.294 0 002.117 2.3 2.031 2.031 0 001.892-2.289 2.306 2.306 0 00-2.116-2.293 2.042 2.042 0 00-1.893 2.282z"
              />
              <path
                  data-name="Path 2482"
                  d="M568.906 87.405a2.254 2.254 0 002.089 2.293 2.067 2.067 0 001.922-2.294 2.265 2.265 0 00-2.089-2.288 2.076 2.076 0 00-1.922 2.289z"
              />
              <path
                  data-name="Path 2483"
                  d="M562.518 87.42a2.217 2.217 0 002.064 2.288 2.1 2.1 0 001.948-2.3 2.228 2.228 0 00-2.062-2.283 2.114 2.114 0 00-1.95 2.295z"
              />
              <path
                  data-name="Path 2484"
                  d="M556.131 87.452a2.176 2.176 0 002.035 2.283 2.139 2.139 0 001.977-2.3 2.185 2.185 0 00-2.035-2.276 2.147 2.147 0 00-1.977 2.293z"
              />
              <path
                  data-name="Path 2485"
                  d="M549.743 87.501a2.138 2.138 0 002.009 2.278 2.177 2.177 0 002.005-2.309 2.152 2.152 0 00-2.009-2.273 2.188 2.188 0 00-2.005 2.304z"
              />
              <path
                  data-name="Path 2486"
                  d="M543.355 87.565a2.1 2.1 0 001.982 2.273 2.215 2.215 0 002.032-2.314 2.114 2.114 0 00-1.982-2.268 2.226 2.226 0 00-2.032 2.309z"
              />
              <path
                  data-name="Path 2487"
                  d="M536.966 87.648a2.066 2.066 0 001.953 2.268 2.256 2.256 0 002.059-2.321 2.077 2.077 0 00-1.953-2.263 2.264 2.264 0 00-2.059 2.316z"
              />
              <path
                  data-name="Path 2488"
                  d="M517.797 87.986a1.965 1.965 0 001.872 2.253 2.378 2.378 0 002.141-2.336 1.973 1.973 0 00-1.872-2.248 2.386 2.386 0 00-2.141 2.331z"
              />
              <path
                  data-name="Path 2489"
                  d="M352.803 96.992c-.451 1.285.067 2.251 1.16 2.156a3.764 3.764 0 002.779-2.5c.443-1.283-.077-2.246-1.16-2.151a3.788 3.788 0 00-2.779 2.495z"
              />
              <path
                  data-name="Path 2490"
                  d="M346.539 97.549c-.465 1.285.043 2.251 1.135 2.154a3.823 3.823 0 002.8-2.5c.456-1.283-.052-2.246-1.133-2.149a3.854 3.854 0 00-2.802 2.495z"
              />
              <path
                  data-name="Path 2491"
                  d="M340.284 98.12c-.478 1.287.018 2.253 1.108 2.151a3.89 3.89 0 002.821-2.51c.468-1.287-.028-2.246-1.108-2.147a3.919 3.919 0 00-2.821 2.506z"
              />
              <path
                  data-name="Path 2492"
                  d="M334.039 98.707c-.491 1.288-.008 2.251 1.079 2.149a3.963 3.963 0 002.843-2.518c.481-1.287 0-2.246-1.081-2.144a3.982 3.982 0 00-2.841 2.513z"
              />
              <path
                  data-name="Path 2493"
                  d="M327.803 99.308c-.5 1.29-.032 2.251 1.054 2.146a4.021 4.021 0 002.861-2.525c.495-1.287.022-2.244-1.054-2.141a4.046 4.046 0 00-2.861 2.52z"
              />
              <path
                  data-name="Path 2494"
                  d="M321.576 99.925c-.516 1.29-.057 2.251 1.028 2.142a4.087 4.087 0 002.881-2.53c.506-1.288.047-2.246-1.028-2.139a4.107 4.107 0 00-2.881 2.527z"
              />
              <path
                  data-name="Path 2495"
                  d="M315.36 100.556c-.53 1.29-.08 2.249 1 2.139a4.158 4.158 0 002.9-2.538c.518-1.29.07-2.244-1-2.136a4.178 4.178 0 00-2.9 2.535z"
              />
              <path
                  data-name="Path 2496"
                  d="M309.154 101.205c-.541 1.292-.1 2.249.978 2.137a4.219 4.219 0 002.919-2.545c.531-1.29.094-2.244-.978-2.132a4.242 4.242 0 00-2.919 2.54z"
              />
              <path
                  data-name="Path 2497"
                  d="M302.959 101.86c-.553 1.293-.127 2.251.951 2.136a4.286 4.286 0 002.939-2.552c.543-1.292.117-2.244-.953-2.131a4.316 4.316 0 00-2.937 2.547z"
              />
              <path
                  data-name="Path 2498"
                  d="M296.776 102.534c-.566 1.293-.152 2.249.926 2.132a4.359 4.359 0 002.956-2.558c.556-1.293.142-2.244-.926-2.129a4.383 4.383 0 00-2.956 2.555z"
              />
              <path
                  data-name="Path 2499"
                  d="M247.744 108.428c-.655 1.3-.328 2.251.73 2.114a4.9 4.9 0 003.09-2.609c.647-1.3.319-2.248-.73-2.111a4.927 4.927 0 00-3.09 2.606z"
              />
              <path
                  data-name="Path 2500"
                  d="M241.674 109.224c-.665 1.305-.349 2.251.707 2.112a4.962 4.962 0 003.1-2.615c.658-1.3.343-2.248-.707-2.109a5 5 0 00-3.1 2.612z"
              />
              <path
                  data-name="Path 2501"
                  d="M235.62 110.035c-.677 1.307-.371 2.253.683 2.111a5.037 5.037 0 003.118-2.62c.668-1.307.361-2.248-.685-2.109a5.065 5.065 0 00-3.116 2.618z"
              />
              <path
                  data-name="Path 2502"
                  d="M229.579 110.859c-.685 1.307-.391 2.253.66 2.109a5.111 5.111 0 003.132-2.627c.678-1.307.384-2.248-.66-2.106a5.137 5.137 0 00-3.132 2.624z"
              />
              <path
                  data-name="Path 2503"
                  d="M223.555 111.696c-.7 1.308-.413 2.251.637 2.106a5.174 5.174 0 003.145-2.634c.688-1.307.4-2.249-.638-2.1a5.2 5.2 0 00-3.144 2.628z"
              />
              <path
                  data-name="Path 2504"
                  d="M217.544 112.545c-.707 1.308-.431 2.253.615 2.1a5.245 5.245 0 003.158-2.639c.7-1.31.423-2.249-.615-2.1a5.268 5.268 0 00-3.158 2.639z"
              />
              <path
                  data-name="Path 2505"
                  d="M211.547 113.407c-.715 1.31-.451 2.253.592 2.1a5.323 5.323 0 003.172-2.645c.709-1.31.441-2.249-.593-2.1a5.336 5.336 0 00-3.171 2.645z"
              />
              <path
                  data-name="Path 2506"
                  d="M205.57 114.281c-.724 1.31-.47 2.253.57 2.1a5.387 5.387 0 003.183-2.652c.719-1.312.461-2.249-.572-2.1a5.408 5.408 0 00-3.181 2.652z"
              />
              <path
                  data-name="Path 2507"
                  d="M199.609 115.165c-.734 1.313-.488 2.254.548 2.1a5.458 5.458 0 003.2-2.657c.727-1.312.48-2.249-.55-2.1a5.476 5.476 0 00-3.198 2.657z"
              />
              <path
                  data-name="Path 2508"
                  d="M193.662 116.066c-.744 1.313-.508 2.253.526 2.1a5.519 5.519 0 003.205-2.664c.737-1.313.5-2.251-.526-2.094a5.545 5.545 0 00-3.205 2.658z"
              />
              <path
                  data-name="Path 2509"
                  d="M187.734 116.977c-.752 1.315-.525 2.254.506 2.1a5.591 5.591 0 003.215-2.669c.745-1.315.518-2.251-.506-2.094a5.616 5.616 0 00-3.215 2.663z"
              />
              <path
                  data-name="Path 2510"
                  d="M181.821 117.899c-.76 1.317-.543 2.254.485 2.092a5.651 5.651 0 003.227-2.674c.754-1.315.535-2.251-.486-2.091a5.684 5.684 0 00-3.226 2.673z"
              />
              <path
                  data-name="Path 2511"
                  d="M175.927 118.833c-.769 1.317-.561 2.254.465 2.09a5.723 5.723 0 003.235-2.679c.762-1.317.553-2.251-.465-2.09a5.745 5.745 0 00-3.235 2.679z"
              />
              <path
                  data-name="Path 2512"
                  d="M170.054 119.779c-.777 1.317-.578 2.254.443 2.089a5.785 5.785 0 003.245-2.685c.77-1.317.572-2.251-.444-2.087a5.8 5.8 0 00-3.244 2.683z"
              />
              <path
                  data-name="Path 2513"
                  d="M164.197 120.735c-.784 1.32-.595 2.254.424 2.089a5.859 5.859 0 003.254-2.69c.777-1.318.587-2.253-.426-2.085a5.867 5.867 0 00-3.252 2.686z"
              />
              <path
                  data-name="Path 2514"
                  d="M158.354 121.704c-.792 1.318-.612 2.254.4 2.085a5.911 5.911 0 003.262-2.7c.785-1.318.6-2.253-.406-2.084a5.933 5.933 0 00-3.256 2.699z"
              />
              <path
                  data-name="Path 2515"
                  d="M152.533 122.684c-.8 1.32-.627 2.254.386 2.084a5.985 5.985 0 003.269-2.7c.792-1.32.62-2.253-.386-2.082a6 6 0 00-3.269 2.698z"
              />
              <path
                  data-name="Path 2516"
                  d="M146.731 123.673c-.8 1.322-.642 2.256.366 2.082a6.039 6.039 0 003.277-2.705c.8-1.32.635-2.253-.368-2.08a6.078 6.078 0 00-3.275 2.703z"
              />
              <path
                  data-name="Path 2517"
                  d="M140.948 124.673c-.812 1.324-.657 2.254.348 2.08a6.106 6.106 0 003.282-2.71c.807-1.322.652-2.253-.348-2.079a6.13 6.13 0 00-3.282 2.709z"
              />
              <path
                  data-name="Path 2518"
                  d="M135.187 125.684c-.819 1.324-.672 2.254.329 2.079a6.17 6.17 0 003.289-2.715c.814-1.322.665-2.253-.331-2.077a6.187 6.187 0 00-3.287 2.713z"
              />
              <path
                  data-name="Path 2519"
                  d="M129.445 126.708c-.826 1.324-.687 2.254.311 2.075a6.224 6.224 0 003.294-2.719c.82-1.325.68-2.253-.312-2.075a6.249 6.249 0 00-3.293 2.719z"
              />
              <path
                  data-name="Path 2520"
                  d="M123.72 127.738c-.831 1.325-.7 2.254.294 2.075a6.288 6.288 0 003.3-2.724c.827-1.325.7-2.254-.294-2.074a6.3 6.3 0 00-3.3 2.723z"
              />
              <path
                  data-name="Path 2521"
                  d="M118.027 128.781c-.837 1.325-.714 2.253.276 2.072a6.352 6.352 0 003.3-2.729c.832-1.323.709-2.253-.277-2.072a6.372 6.372 0 00-3.299 2.729z"
              />
              <path
                  data-name="Path 2522"
                  d="M112.343 129.832c-.842 1.327-.727 2.254.259 2.07a6.4 6.4 0 003.305-2.732c.839-1.327.722-2.253-.261-2.07a6.418 6.418 0 00-3.303 2.732z"
              />
              <path
                  data-name="Path 2523"
                  d="M106.685 130.893c-.847 1.327-.74 2.254.242 2.069a6.454 6.454 0 003.309-2.737c.844-1.325.735-2.253-.244-2.067a6.472 6.472 0 00-3.307 2.735z"
              />
              <path
                  data-name="Path 2524"
                  d="M101.048 131.964c-.852 1.327-.752 2.253.227 2.067a6.514 6.514 0 003.31-2.741c.849-1.327.747-2.253-.229-2.067a6.534 6.534 0 00-3.308 2.741z"
              />
              <path
                  data-name="Path 2525"
                  d="M95.436 133.044c-.859 1.329-.764 2.253.211 2.065a6.57 6.57 0 003.312-2.746c.854-1.327.759-2.251-.212-2.064a6.58 6.58 0 00-3.311 2.745z"
              />
              <path
                  data-name="Path 2526"
                  d="M89.844 134.134c-.864 1.329-.775 2.251.2 2.062a6.617 6.617 0 003.314-2.747c.859-1.329.769-2.253-.2-2.064a6.638 6.638 0 00-3.314 2.749z"
              />
              <path
                  data-name="Path 2527"
                  d="M84.274 135.231c-.866 1.328-.785 2.251.18 2.06a6.669 6.669 0 003.315-2.752c.862-1.329.78-2.251-.184-2.06a6.675 6.675 0 00-3.311 2.752z"
              />
              <path
                  data-name="Path 2528"
                  d="M78.727 136.338c-.869 1.329-.8 2.251.167 2.059a6.719 6.719 0 003.314-2.756c.867-1.329.792-2.251-.167-2.059a6.73 6.73 0 00-3.314 2.756z"
              />
              <path
                  data-name="Path 2529"
                  d="M1045.062 126.246a6.894 6.894 0 003.3 2.746c.927.182.946-.75.047-2.084a6.9 6.9 0 00-3.3-2.744c-.924-.184-.942.751-.047 2.082z"
              />
              <path
                  data-name="Path 2530"
                  d="M1039.71 125.2a6.862 6.862 0 003.307 2.741c.929.18.954-.752.057-2.084a6.868 6.868 0 00-3.3-2.742c-.932-.181-.96.754-.064 2.085z"
              />
              <path
                  data-name="Path 2531"
                  d="M1028.941 123.131a6.77 6.77 0 003.31 2.732c.938.179.974-.755.08-2.087a6.768 6.768 0 00-3.307-2.732c-.935-.179-.972.757-.083 2.087z"
              />
              <path
                  data-name="Path 2532"
                  d="M1018.088 121.098a6.675 6.675 0 003.31 2.724c.946.175.993-.76.107-2.089a6.688 6.688 0 00-3.309-2.726c-.942-.173-.991.764-.108 2.091z"
              />
              <path
                  data-name="Path 2533"
                  d="M1007.152 119.1a6.579 6.579 0 003.308 2.716c.954.172 1.013-.765.134-2.092a6.581 6.581 0 00-3.307-2.716c-.947-.172-1.01.766-.135 2.092z"
              />
              <path
                  data-name="Path 2534"
                  d="M1001.655 118.114a6.521 6.521 0 003.307 2.712c.956.17 1.021-.767.149-2.094a6.54 6.54 0 00-3.307-2.71c-.949-.17-1.02.767-.149 2.092z"
              />
              <path
                  data-name="Path 2535"
                  d="M996.137 117.141a6.463 6.463 0 003.3 2.705c.961.169 1.033-.769.164-2.094a6.485 6.485 0 00-3.3-2.705c-.959-.166-1.031.769-.164 2.094z"
              />
              <path
                  data-name="Path 2536"
                  d="M990.599 116.174a6.413 6.413 0 003.3 2.7c.963.167 1.041-.772.177-2.1a6.436 6.436 0 00-3.3-2.7c-.955-.161-1.039.777-.177 2.1z"
              />
              <path
                  data-name="Path 2537"
                  d="M985.04 115.221a6.374 6.374 0 003.3 2.7c.966.165 1.051-.774.194-2.1a6.377 6.377 0 00-3.3-2.7c-.961-.163-1.049.776-.194 2.1z"
              />
              <path
                  data-name="Path 2538"
                  d="M979.464 114.275a6.308 6.308 0 003.3 2.69c.969.164 1.063-.775.209-2.1a6.317 6.317 0 00-3.294-2.689c-.969-.162-1.064.777-.215 2.099z"
              />
              <path
                  data-name="Path 2539"
                  d="M962.621 111.499a6.129 6.129 0 003.28 2.675c.978.159 1.1-.784.259-2.1a6.16 6.16 0 00-3.277-2.674c-.98-.158-1.092.783-.262 2.099z"
              />
              <path
                  data-name="Path 2540"
                  d="M951.302 109.703a6.007 6.007 0 003.267 2.664c.988.157 1.118-.787.3-2.1a6.029 6.029 0 00-3.265-2.662c-.986-.158-1.119.787-.302 2.098z"
              />
              <path
                  data-name="Path 2541"
                  d="M916.925 104.581a5.628 5.628 0 003.215 2.629c1 .142 1.188-.8.411-2.112a5.64 5.64 0 00-3.214-2.625c-.999-.14-1.185.799-.412 2.108z"
              />
              <path
                  data-name="Path 2542"
                  d="M911.136 103.767a5.551 5.551 0 003.205 2.622c1.008.142 1.2-.805.433-2.112a5.586 5.586 0 00-3.205-2.62c-1.006-.139-1.195.805-.433 2.11z"
              />
              <path
                  data-name="Path 2543"
                  d="M905.331 102.965a5.488 5.488 0 003.2 2.617c1.009.139 1.213-.809.453-2.114a5.51 5.51 0 00-3.193-2.614c-1.01-.138-1.214.806-.46 2.111z"
              />
              <path
                  data-name="Path 2544"
                  d="M899.51 102.176a5.427 5.427 0 003.183 2.61c1.014.137 1.227-.81.475-2.116a5.438 5.438 0 00-3.182-2.607c-1.007-.134-1.219.81-.476 2.113z"
              />
              <path
                  data-name="Path 2545"
                  d="M893.675 101.399a5.362 5.362 0 003.172 2.6c1.016.135 1.237-.814.5-2.117a5.381 5.381 0 00-3.172-2.6c-1.011-.128-1.235.815-.5 2.117z"
              />
              <path
                  data-name="Path 2546"
                  d="M887.824 100.634a5.284 5.284 0 003.158 2.6c1.019.134 1.25-.815.518-2.117a5.308 5.308 0 00-3.158-2.594c-1.011-.135-1.245.811-.518 2.111z"
              />
              <path
                  data-name="Path 2547"
                  d="M881.957 99.881a5.212 5.212 0 003.148 2.59c1.019.13 1.262-.819.538-2.119a5.235 5.235 0 00-3.145-2.587c-1.014-.13-1.257.816-.541 2.116z"
              />
              <path
                  data-name="Path 2548"
                  d="M876.077 99.141a5.14 5.14 0 003.133 2.583c1.026.129 1.277-.82.561-2.121a5.165 5.165 0 00-3.132-2.58c-1.015-.126-1.269.823-.562 2.118z"
              />
              <path
                  data-name="Path 2549"
                  d="M870.181 98.414a5.072 5.072 0 003.12 2.577c1.028.127 1.288-.826.585-2.122a5.1 5.1 0 00-3.12-2.573c-1.02-.126-1.282.822-.585 2.118z"
              />
              <path
                  data-name="Path 2550"
                  d="M864.271 97.699a5.012 5.012 0 003.107 2.568c1.029.124 1.3-.827.607-2.124a5.024 5.024 0 00-3.1-2.567c-1.027-.119-1.299.828-.614 2.123z"
              />
              <path
                  data-name="Path 2551"
                  d="M858.346 96.999a4.945 4.945 0 003.091 2.563c1.033.122 1.315-.831.632-2.126a4.964 4.964 0 00-3.092-2.56c-1.022-.119-1.306.828-.631 2.123z"
              />
              <path
                  data-name="Path 2552"
                  d="M852.409 96.308a4.867 4.867 0 003.076 2.557c1.034.12 1.327-.832.653-2.127a4.889 4.889 0 00-3.075-2.553c-1.027-.117-1.319.834-.654 2.123z"
              />
              <path
                  data-name="Path 2553"
                  d="M846.457 95.636a4.793 4.793 0 003.063 2.552c1.036.117 1.34-.837.677-2.129a4.823 4.823 0 00-3.061-2.547c-1.028-.119-1.332.832-.679 2.124z"
              />
              <path
                  data-name="Path 2554"
                  d="M840.493 94.97a4.738 4.738 0 003.046 2.545c1.039.115 1.352-.841.7-2.131a4.75 4.75 0 00-3.045-2.54c-1.03-.113-1.347.838-.701 2.126z"
              />
              <path
                  data-name="Path 2555"
                  d="M834.513 94.323a4.661 4.661 0 003.031 2.537c1.041.114 1.365-.842.725-2.131a4.688 4.688 0 00-3.03-2.535c-1.031-.11-1.357.841-.726 2.129z"
              />
              <path
                  data-name="Path 2556"
                  d="M828.523 93.689a4.6 4.6 0 003.015 2.53c1.043.112 1.38-.846.75-2.134a4.627 4.627 0 00-3.013-2.527c-1.037-.105-1.373.844-.752 2.131z"
              />
              <path
                  data-name="Path 2557"
                  d="M822.518 93.067a4.53 4.53 0 003 2.523c1.046.109 1.392-.849.775-2.136a4.553 4.553 0 00-3-2.52c-1.035-.107-1.383.848-.775 2.133z"
              />
              <path
                  data-name="Path 2558"
                  d="M816.503 92.458a4.461 4.461 0 002.981 2.517c1.048.105 1.405-.852.8-2.137a4.478 4.478 0 00-2.978-2.513c-1.04-.104-1.401.849-.803 2.133z"
              />
              <path
                  data-name="Path 2559"
                  d="M810.476 91.864a4.392 4.392 0 002.963 2.512c1.051.1 1.42-.857.826-2.141a4.425 4.425 0 00-2.963-2.507c-1.042-.1-1.413.852-.826 2.136z"
              />
              <path
                  data-name="Path 2560"
                  d="M804.436 91.285a4.321 4.321 0 002.944 2.5c1.053.1 1.432-.859.851-2.141a4.348 4.348 0 00-2.945-2.5c-1.042-.097-1.424.858-.85 2.141z"
              />
              <path
                  data-name="Path 2561"
                  d="M798.384 90.718a4.256 4.256 0 002.928 2.5c1.054.1 1.445-.862.876-2.142a4.279 4.279 0 00-2.926-2.493c-1.047-.101-1.44.855-.878 2.135z"
              />
              <path
                  data-name="Path 2562"
                  d="M792.321 90.164a4.191 4.191 0 002.908 2.492c1.058.1 1.459-.867.9-2.146a4.21 4.21 0 00-2.908-2.487c-1.046-.095-1.45.863-.9 2.141z"
              />
              <path
                  data-name="Path 2563"
                  d="M786.249 89.626a4.121 4.121 0 002.888 2.485c1.061.092 1.474-.869.927-2.149a4.147 4.147 0 00-2.888-2.478c-1.049-.092-1.466.865-.927 2.142z"
              />
              <path
                  data-name="Path 2564"
                  d="M780.163 89.106a4.064 4.064 0 002.871 2.477c1.059.09 1.487-.872.952-2.149a4.079 4.079 0 00-2.869-2.473c-1.054-.094-1.478.865-.954 2.145z"
              />
              <path
                  data-name="Path 2565"
                  d="M774.068 88.594a3.99 3.99 0 002.849 2.47c1.063.089 1.5-.877.979-2.152a4.011 4.011 0 00-2.849-2.465c-1.052-.089-1.492.87-.979 2.147z"
              />
              <path
                  data-name="Path 2566"
                  d="M767.962 88.101a3.926 3.926 0 002.831 2.463c1.063.085 1.514-.881 1-2.154a3.946 3.946 0 00-2.829-2.46c-1.051-.087-1.502.874-1.002 2.151z"
              />
              <path
                  data-name="Path 2567"
                  d="M761.846 87.617a3.863 3.863 0 002.811 2.456c1.066.084 1.527-.882 1.031-2.156a3.88 3.88 0 00-2.809-2.453c-1.059-.083-1.52.882-1.033 2.153z"
              />
              <path
                  data-name="Path 2568"
                  d="M755.72 87.151a3.794 3.794 0 002.789 2.45c1.071.082 1.542-.886 1.06-2.159a3.817 3.817 0 00-2.789-2.445c-1.059-.078-1.533.882-1.06 2.154z"
              />
              <path
                  data-name="Path 2569"
                  d="M749.585 86.7a3.734 3.734 0 002.769 2.443c1.069.079 1.554-.891 1.085-2.161a3.756 3.756 0 00-2.767-2.44c-1.061-.076-1.547.888-1.087 2.158z"
              />
              <path
                  data-name="Path 2570"
                  d="M743.441 86.261a3.67 3.67 0 002.747 2.436c1.071.077 1.569-.894 1.113-2.164a3.693 3.693 0 00-2.747-2.433c-1.061-.07-1.559.894-1.113 2.161z"
              />
              <path
                  data-name="Path 2571"
                  d="M737.286 85.841a3.608 3.608 0 002.727 2.431c1.073.074 1.582-.9 1.14-2.167a3.636 3.636 0 00-2.726-2.426c-1.063-.072-1.574.895-1.141 2.162z"
              />
              <path
                  data-name="Path 2572"
                  d="M731.123 85.435a3.555 3.555 0 002.705 2.425c1.075.07 1.6-.9 1.166-2.171a3.568 3.568 0 00-2.7-2.42c-1.067-.068-1.592.899-1.171 2.166z"
              />
              <path
                  data-name="Path 2573"
                  d="M724.954 85.044a3.485 3.485 0 002.682 2.416c1.076.069 1.609-.906 1.195-2.172a3.509 3.509 0 00-2.682-2.413c-1.067-.067-1.602.904-1.195 2.169z"
              />
              <path
                  data-name="Path 2574"
                  d="M718.774 84.667a3.426 3.426 0 002.66 2.411c1.076.065 1.624-.911 1.222-2.176a3.447 3.447 0 00-2.66-2.406c-1.067-.063-1.615.906-1.222 2.171z"
              />
              <path
                  data-name="Path 2575"
                  d="M712.585 84.305a3.369 3.369 0 002.639 2.4c1.078.064 1.636-.916 1.248-2.179a3.386 3.386 0 00-2.637-2.4c-1.067-.057-1.627.916-1.25 2.179z"
              />
              <path
                  data-name="Path 2576"
                  d="M706.391 83.959a3.31 3.31 0 002.615 2.4c1.079.06 1.651-.919 1.277-2.182a3.323 3.323 0 00-2.614-2.393c-1.069-.062-1.642.912-1.278 2.175z"
              />
              <path
                  data-name="Path 2577"
                  d="M700.188 83.629a3.252 3.252 0 002.592 2.391c1.081.057 1.664-.922 1.305-2.186a3.267 3.267 0 00-2.592-2.386c-1.071-.053-1.655.917-1.305 2.181z"
              />
              <path
                  data-name="Path 2578"
                  d="M693.979 83.313a3.193 3.193 0 002.568 2.385c1.083.055 1.679-.927 1.333-2.189a3.212 3.212 0 00-2.568-2.38c-1.073-.054-1.669.922-1.333 2.184z"
              />
              <path
                  data-name="Path 2579"
                  d="M687.762 83.012a3.139 3.139 0 002.545 2.38c1.083.05 1.693-.932 1.36-2.192a3.158 3.158 0 00-2.545-2.375c-1.073-.05-1.683.926-1.36 2.187z"
              />
              <path
                  data-name="Path 2580"
                  d="M681.539 82.73a3.082 3.082 0 002.522 2.373c1.084.05 1.706-.936 1.387-2.2a3.1 3.1 0 00-2.52-2.366c-1.075-.048-1.698.93-1.389 2.193z"
              />
              <path
                  data-name="Path 2581"
                  d="M675.309 82.458a3.025 3.025 0 002.5 2.366c1.086.048 1.72-.939 1.417-2.2a3.05 3.05 0 00-2.5-2.361c-1.076-.043-1.709.936-1.417 2.195z"
              />
              <path
                  data-name="Path 2582"
                  d="M669.072 82.203a2.978 2.978 0 002.473 2.361c1.086.043 1.733-.944 1.444-2.2a2.993 2.993 0 00-2.473-2.356c-1.076-.044-1.723.937-1.444 2.195z"
              />
              <path
                  data-name="Path 2583"
                  d="M662.829 81.965a2.921 2.921 0 002.45 2.355c1.088.042 1.746-.948 1.472-2.206a2.933 2.933 0 00-2.448-2.348c-1.078-.046-1.738.942-1.474 2.199z"
              />
              <path
                  data-name="Path 2584"
                  d="M656.583 81.743a2.87 2.87 0 002.423 2.35c1.091.037 1.763-.954 1.5-2.211a2.885 2.885 0 00-2.423-2.343c-1.078-.037-1.749.947-1.5 2.204z"
              />
              <path
                  data-name="Path 2585"
                  d="M650.33 81.535a2.816 2.816 0 002.4 2.343c1.089.035 1.773-.958 1.527-2.214a2.831 2.831 0 00-2.4-2.336c-1.078-.036-1.761.95-1.527 2.207z"
              />
              <path
                  data-name="Path 2586"
                  d="M644.071 81.345a2.766 2.766 0 002.375 2.336c1.091.033 1.788-.961 1.556-2.218a2.78 2.78 0 00-2.373-2.331c-1.08-.031-1.778.956-1.558 2.213z"
              />
              <path
                  data-name="Path 2587"
                  d="M637.808 81.169a2.718 2.718 0 002.35 2.331c1.091.028 1.8-.968 1.584-2.223a2.731 2.731 0 00-2.348-2.324c-1.081-.03-1.791.961-1.586 2.216z"
              />
              <path
                  data-name="Path 2588"
                  d="M631.54 81.008a2.668 2.668 0 002.324 2.324 1.731 1.731 0 001.613-2.226 2.682 2.682 0 00-2.323-2.318 1.736 1.736 0 00-1.614 2.22z"
              />
              <path
                  data-name="Path 2589"
                  d="M625.268 80.868a2.621 2.621 0 002.3 2.319 1.76 1.76 0 001.643-2.231 2.636 2.636 0 00-2.3-2.313 1.765 1.765 0 00-1.643 2.225z"
              />
              <path
                  data-name="Path 2590"
                  d="M618.992 80.737a2.575 2.575 0 002.273 2.313 1.787 1.787 0 001.669-2.234 2.587 2.587 0 00-2.271-2.308 1.794 1.794 0 00-1.671 2.229z"
              />
              <path
                  data-name="Path 2591"
                  d="M612.712 80.626a2.526 2.526 0 002.248 2.306 1.815 1.815 0 001.7-2.238 2.542 2.542 0 00-2.248-2.3 1.823 1.823 0 00-1.7 2.232z"
              />
              <path
                  data-name="Path 2592"
                  d="M606.429 80.528a2.484 2.484 0 002.221 2.3 1.847 1.847 0 001.726-2.244 2.5 2.5 0 00-2.221-2.3 1.854 1.854 0 00-1.726 2.244z"
              />
              <path
                  data-name="Path 2593"
                  d="M600.143 80.449a2.435 2.435 0 002.194 2.3 1.876 1.876 0 001.754-2.255 2.451 2.451 0 00-2.194-2.291 1.884 1.884 0 00-1.754 2.246z"
              />
              <path
                  data-name="Path 2594"
                  d="M593.852 80.386a2.4 2.4 0 002.169 2.291 1.909 1.909 0 001.781-2.253 2.407 2.407 0 00-2.167-2.286 1.916 1.916 0 00-1.783 2.248z"
              />
              <path
                  data-name="Path 2595"
                  d="M587.561 80.337a2.353 2.353 0 002.142 2.286 1.942 1.942 0 001.81-2.258 2.362 2.362 0 00-2.142-2.279 1.946 1.946 0 00-1.81 2.251z"
              />
              <path
                  data-name="Path 2596"
                  d="M581.266 80.305a2.311 2.311 0 002.116 2.279 1.975 1.975 0 001.838-2.263 2.324 2.324 0 00-2.116-2.274 1.983 1.983 0 00-1.838 2.258z"
              />
              <path
                  data-name="Path 2597"
                  d="M574.969 80.288a2.269 2.269 0 002.089 2.274 2.007 2.007 0 001.867-2.266 2.28 2.28 0 00-2.089-2.269 2.016 2.016 0 00-1.867 2.261z"
              />
              <path
                  data-name="Path 2598"
                  d="M568.67 80.288a2.231 2.231 0 002.062 2.269 2.043 2.043 0 001.895-2.271 2.239 2.239 0 00-2.062-2.264 2.052 2.052 0 00-1.895 2.266z"
              />
              <path
                  data-name="Path 2599"
                  d="M562.369 80.303a2.19 2.19 0 002.035 2.266 2.079 2.079 0 001.922-2.278 2.2 2.2 0 00-2.035-2.259 2.09 2.09 0 00-1.922 2.271z"
              />
              <path
                  data-name="Path 2600"
                  d="M556.068 80.335a2.153 2.153 0 002.009 2.261 2.118 2.118 0 001.95-2.283 2.165 2.165 0 00-2.009-2.254 2.126 2.126 0 00-1.95 2.276z"
              />
              <path
                  data-name="Path 2601"
                  d="M549.765 80.384a2.116 2.116 0 001.982 2.256 2.153 2.153 0 001.977-2.288 2.123 2.123 0 00-1.98-2.249 2.164 2.164 0 00-1.979 2.281z"
              />
              <path
                  data-name="Path 2602"
                  d="M543.461 80.449a2.079 2.079 0 001.953 2.249 2.191 2.191 0 002.005-2.293 2.086 2.086 0 00-1.954-2.244 2.2 2.2 0 00-2.004 2.288z"
              />
              <path
                  data-name="Path 2603"
                  d="M537.156 80.529a2.043 2.043 0 001.927 2.244 2.232 2.232 0 002.034-2.3 2.052 2.052 0 00-1.927-2.239 2.242 2.242 0 00-2.034 2.295z"
              />
              <path
                  data-name="Path 2604"
                  d="M530.853 80.624a2.008 2.008 0 001.9 2.241 2.27 2.27 0 002.06-2.3 2.015 2.015 0 00-1.9-2.234 2.278 2.278 0 00-2.06 2.293z"
              />
              <path
                  data-name="Path 2605"
                  d="M524.548 80.736a1.973 1.973 0 001.872 2.236 2.31 2.31 0 002.087-2.308 1.98 1.98 0 00-1.872-2.229 2.321 2.321 0 00-2.087 2.301z"
              />
              <path
                  data-name="Path 2606"
                  d="M518.243 80.865a1.944 1.944 0 001.845 2.233 2.356 2.356 0 002.116-2.314 1.95 1.95 0 00-1.845-2.226 2.366 2.366 0 00-2.116 2.307z"
              />
              <path
                  data-name="Path 2607"
                  d="M505.637 81.171a1.879 1.879 0 001.791 2.223 2.439 2.439 0 002.167-2.326 1.883 1.883 0 00-1.79-2.216 2.454 2.454 0 00-2.168 2.319z"
              />
              <path
                  data-name="Path 2608"
                  d="M349.167 90.311c-.466 1.275.03 2.234 1.108 2.137a3.813 3.813 0 002.779-2.485c.456-1.273-.042-2.228-1.11-2.131a3.829 3.829 0 00-2.777 2.479z"
              />
              <path
                  data-name="Path 2609"
                  d="M342.985 90.882c-.478 1.277.007 2.233 1.083 2.134a3.875 3.875 0 002.8-2.492c.47-1.275-.015-2.228-1.083-2.129a3.9 3.9 0 00-2.8 2.487z"
              />
              <path
                  data-name="Path 2610"
                  d="M336.812 91.458c-.491 1.277-.018 2.233 1.056 2.131a3.937 3.937 0 002.821-2.5c.483-1.277.008-2.229-1.056-2.127a3.963 3.963 0 00-2.821 2.496z"
              />
              <path
                  data-name="Path 2611"
                  d="M330.649 92.053c-.5 1.278-.043 2.233 1.029 2.129a4.006 4.006 0 002.841-2.5c.495-1.278.033-2.228-1.031-2.124a4.025 4.025 0 00-2.839 2.495z"
              />
              <path
                  data-name="Path 2612"
                  d="M324.493 92.661c-.516 1.28-.067 2.234 1 2.127a4.071 4.071 0 002.861-2.512c.508-1.278.057-2.228-1-2.122a4.093 4.093 0 00-2.861 2.507z"
              />
              <path
                  data-name="Path 2613"
                  d="M318.346 93.286c-.528 1.28-.09 2.233.979 2.124a4.14 4.14 0 002.881-2.518c.52-1.28.082-2.229-.979-2.121a4.165 4.165 0 00-2.881 2.515z"
              />
              <path
                  data-name="Path 2614"
                  d="M312.212 93.924c-.543 1.282-.117 2.234.953 2.122a4.212 4.212 0 002.9-2.525c.533-1.282.107-2.229-.954-2.119a4.229 4.229 0 00-2.899 2.522z"
              />
              <path
                  data-name="Path 2615"
                  d="M306.086 94.576c-.555 1.285-.139 2.234.927 2.121a4.27 4.27 0 002.918-2.532c.545-1.283.13-2.229-.927-2.116a4.3 4.3 0 00-2.918 2.527z"
              />
              <path
                  data-name="Path 2616"
                  d="M299.97 95.243c-.567 1.285-.162 2.236.9 2.119a4.348 4.348 0 002.938-2.538c.558-1.287.152-2.229-.9-2.114a4.363 4.363 0 00-2.938 2.533z"
              />
              <path
                  data-name="Path 2617"
                  d="M251.448 101.078c-.655 1.3-.339 2.239.709 2.1a4.889 4.889 0 003.073-2.592c.648-1.3.331-2.234-.71-2.1a4.926 4.926 0 00-3.072 2.592z"
              />
              <path
                  data-name="Path 2618"
                  d="M245.439 101.868c-.667 1.3-.361 2.239.685 2.1a4.962 4.962 0 003.088-2.6c.658-1.3.351-2.234-.687-2.1a4.991 4.991 0 00-3.086 2.6z"
              />
              <path
                  data-name="Path 2619"
                  d="M239.442 102.669c-.677 1.3-.381 2.239.663 2.1a5.034 5.034 0 003.1-2.605c.67-1.3.373-2.236-.663-2.1a5.051 5.051 0 00-3.1 2.605z"
              />
              <path
                  data-name="Path 2620"
                  d="M233.457 103.488c-.689 1.3-.4 2.241.64 2.1a5.1 5.1 0 003.115-2.612c.682-1.3.393-2.236-.64-2.1a5.13 5.13 0 00-3.115 2.612z"
              />
              <path
                  data-name="Path 2621"
                  d="M227.49 104.318c-.7 1.3-.421 2.241.617 2.1a5.174 5.174 0 003.13-2.619c.69-1.3.413-2.236-.618-2.092a5.2 5.2 0 00-3.129 2.611z"
              />
              <path
                  data-name="Path 2622"
                  d="M221.535 105.16c-.707 1.3-.441 2.241.595 2.094a5.235 5.235 0 003.143-2.625c.7-1.3.433-2.238-.6-2.091a5.265 5.265 0 00-3.138 2.622z"
              />
              <path
                  data-name="Path 2623"
                  d="M215.594 106.015c-.717 1.305-.46 2.243.573 2.094a5.322 5.322 0 003.157-2.632c.709-1.3.451-2.238-.575-2.091a5.338 5.338 0 00-3.155 2.629z"
              />
              <path
                  data-name="Path 2624"
                  d="M209.668 106.882c-.725 1.305-.48 2.243.551 2.092a5.382 5.382 0 003.168-2.639c.719-1.305.471-2.238-.553-2.089a5.409 5.409 0 00-3.166 2.636z"
              />
              <path
                  data-name="Path 2625"
                  d="M203.756 107.762c-.734 1.307-.5 2.243.531 2.089a5.455 5.455 0 003.18-2.644c.729-1.307.49-2.239-.531-2.087a5.472 5.472 0 00-3.18 2.642z"
              />
              <path
                  data-name="Path 2626"
                  d="M197.862 108.651c-.745 1.31-.516 2.244.508 2.089a5.517 5.517 0 003.192-2.65c.739-1.307.51-2.239-.51-2.085a5.536 5.536 0 00-3.19 2.646z"
              />
              <path
                  data-name="Path 2627"
                  d="M191.982 109.557c-.752 1.31-.535 2.244.488 2.087a5.586 5.586 0 003.2-2.655c.745-1.308.526-2.241-.49-2.085a5.615 5.615 0 00-3.198 2.653z"
              />
              <path
                  data-name="Path 2628"
                  d="M186.118 110.477c-.76 1.312-.551 2.246.468 2.085a5.659 5.659 0 003.213-2.662c.754-1.31.545-2.241-.47-2.082a5.678 5.678 0 00-3.211 2.659z"
              />
              <path
                  data-name="Path 2629"
                  d="M180.271 111.401c-.769 1.312-.57 2.246.448 2.084a5.708 5.708 0 003.222-2.667c.764-1.312.561-2.243-.448-2.082a5.747 5.747 0 00-3.222 2.665z"
              />
              <path
                  data-name="Path 2630"
                  d="M174.439 112.342c-.777 1.312-.587 2.246.428 2.082a5.792 5.792 0 003.234-2.674c.77-1.312.578-2.244-.429-2.081a5.809 5.809 0 00-3.233 2.673z"
              />
              <path
                  data-name="Path 2631"
                  d="M168.625 113.292c-.785 1.315-.6 2.246.408 2.08a5.853 5.853 0 003.242-2.68c.779-1.313.595-2.243-.409-2.079a5.872 5.872 0 00-3.241 2.679z"
              />
              <path
                  data-name="Path 2632"
                  d="M162.828 114.253c-.792 1.317-.618 2.248.389 2.08a5.92 5.92 0 003.249-2.685c.787-1.315.612-2.244-.389-2.077a5.939 5.939 0 00-3.249 2.682z"
              />
              <path
                  data-name="Path 2633"
                  d="M157.048 115.227c-.8 1.317-.633 2.248.371 2.077a5.972 5.972 0 003.257-2.69c.8-1.315.628-2.244-.371-2.075a6.007 6.007 0 00-3.257 2.688z"
              />
              <path
                  data-name="Path 2634"
                  d="M151.286 116.211c-.805 1.317-.648 2.248.353 2.075a6.045 6.045 0 003.265-2.7c.8-1.317.642-2.244-.354-2.074a6.065 6.065 0 00-3.264 2.699z"
              />
              <path
                  data-name="Path 2635"
                  d="M145.542 117.208c-.812 1.318-.663 2.248.334 2.074a6.11 6.11 0 003.272-2.7c.809-1.318.658-2.246-.336-2.074a6.127 6.127 0 00-3.27 2.7z"
              />
              <path
                  data-name="Path 2636"
                  d="M139.817 118.213c-.819 1.32-.678 2.248.316 2.072a6.162 6.162 0 003.279-2.705c.815-1.32.672-2.248-.318-2.072a6.193 6.193 0 00-3.277 2.705z"
              />
              <path
                  data-name="Path 2637"
                  d="M134.112 119.229c-.827 1.322-.693 2.249.3 2.072a6.23 6.23 0 003.284-2.712c.822-1.32.687-2.246-.3-2.07a6.254 6.254 0 00-3.284 2.71z"
              />
              <path
                  data-name="Path 2638"
                  d="M128.424 120.257c-.832 1.322-.707 2.248.282 2.07a6.285 6.285 0 003.287-2.717c.829-1.322.7-2.248-.282-2.069a6.3 6.3 0 00-3.287 2.716z"
              />
              <path
                  data-name="Path 2639"
                  d="M122.756 121.296c-.837 1.323-.72 2.251.266 2.069a6.347 6.347 0 003.292-2.721c.834-1.322.715-2.249-.266-2.069a6.37 6.37 0 00-3.292 2.721z"
              />
              <path
                  data-name="Path 2640"
                  d="M117.107 122.341c-.842 1.323-.732 2.249.249 2.067a6.406 6.406 0 003.3-2.726c.839-1.324.727-2.248-.251-2.067a6.428 6.428 0 00-3.298 2.726z"
              />
              <path
                  data-name="Path 2641"
                  d="M111.48 123.399c-.849 1.324-.745 2.249.232 2.065a6.467 6.467 0 003.3-2.731c.846-1.325.74-2.249-.234-2.065a6.479 6.479 0 00-3.298 2.731z"
              />
              <path
                  data-name="Path 2642"
                  d="M105.872 124.463c-.854 1.325-.757 2.249.217 2.064a6.506 6.506 0 003.3-2.736c.851-1.324.752-2.248-.217-2.062a6.526 6.526 0 00-3.3 2.734z"
              />
              <path
                  data-name="Path 2643"
                  d="M100.283 125.541c-.857 1.327-.767 2.249.2 2.062a6.569 6.569 0 003.305-2.739c.856-1.327.764-2.249-.2-2.062a6.6 6.6 0 00-3.305 2.739z"
              />
              <path
                  data-name="Path 2644"
                  d="M89.172 127.721c-.867 1.329-.79 2.249.172 2.059a6.664 6.664 0 003.307-2.747c.864-1.327.785-2.249-.174-2.059a6.688 6.688 0 00-3.305 2.747z"
              />
              <path
                  data-name="Path 2645"
                  d="M1034.645 117.67a6.852 6.852 0 003.3 2.739c.927.18.949-.754.052-2.085a6.855 6.855 0 00-3.3-2.739c-.921-.181-.948.754-.052 2.085z"
              />
              <path
                  data-name="Path 2646"
                  d="M1029.287 116.635a6.8 6.8 0 003.3 2.734c.931.179.959-.754.064-2.085a6.807 6.807 0 00-3.3-2.734c-.928-.179-.956.755-.064 2.085z"
              />
              <path
                  data-name="Path 2647"
                  d="M1023.909 115.612a6.756 6.756 0 003.3 2.729c.936.177.969-.757.075-2.087a6.758 6.758 0 00-3.3-2.729c-.93-.177-.965.757-.075 2.087z"
              />
              <path
                  data-name="Path 2648"
                  d="M1018.513 114.596a6.7 6.7 0 003.3 2.724c.939.177.976-.759.089-2.087a6.716 6.716 0 00-3.3-2.724c-.936-.176-.977.758-.089 2.087z"
              />
              <path
                  data-name="Path 2649"
                  d="M1013.094 113.588a6.67 6.67 0 003.305 2.721c.941.174.984-.76.1-2.089a6.683 6.683 0 00-3.3-2.719c-.942-.174-.987.755-.105 2.087z"
              />
              <path
                  data-name="Path 2650"
                  d="M1007.66 112.59a6.608 6.608 0 003.3 2.715c.946.174 1-.762.114-2.089a6.635 6.635 0 00-3.3-2.715c-.941-.172-.993.764-.114 2.089z"
              />
              <path
                  data-name="Path 2651"
                  d="M1002.206 111.603a6.564 6.564 0 003.3 2.71c.949.17 1-.765.127-2.091a6.579 6.579 0 00-3.3-2.709c-.944-.169-1.001.766-.127 2.09z"
              />
              <path
                  data-name="Path 2652"
                  d="M996.733 110.625a6.519 6.519 0 003.3 2.7c.953.17 1.014-.767.14-2.09a6.539 6.539 0 00-3.3-2.7c-.947-.169-1.013.767-.14 2.09z"
              />
              <path
                  data-name="Path 2653"
                  d="M991.241 109.655a6.453 6.453 0 003.3 2.7c.954.169 1.021-.769.154-2.091a6.483 6.483 0 00-3.3-2.7c-.949-.166-1.02.769-.154 2.091z"
              />
              <path
                  data-name="Path 2654"
                  d="M985.732 108.698a6.4 6.4 0 003.294 2.692c.958.167 1.033-.769.169-2.092a6.427 6.427 0 00-3.292-2.692c-.954-.166-1.031.77-.171 2.092z"
              />
              <path
                  data-name="Path 2655"
                  d="M980.205 107.749a6.367 6.367 0 003.29 2.689c.961.164 1.043-.774.184-2.094a6.373 6.373 0 00-3.287-2.687c-.958-.164-1.042.773-.187 2.092z"
              />
              <path
                  data-name="Path 2656"
                  d="M974.66 106.811a6.309 6.309 0 003.285 2.682c.964.162 1.053-.775.2-2.094a6.321 6.321 0 00-3.282-2.682c-.962-.162-1.052.776-.203 2.094z"
              />
              <path
                  data-name="Path 2657"
                  d="M969.096 105.884a6.239 6.239 0 003.279 2.675c.968.16 1.063-.777.217-2.1a6.267 6.267 0 00-3.279-2.674c-.961-.157-1.058.782-.217 2.099z"
              />
              <path
                  data-name="Path 2658"
                  d="M963.519 104.966a6.2 6.2 0 003.274 2.67c.971.159 1.075-.78.232-2.1a6.211 6.211 0 00-3.272-2.67c-.966-.152-1.071.784-.234 2.1z"
              />
              <path
                  data-name="Path 2659"
                  d="M957.923 104.059a6.13 6.13 0 003.269 2.665c.973.157 1.084-.782.247-2.1a6.141 6.141 0 00-3.265-2.664c-.97-.152-1.083.786-.251 2.099z"
              />
              <path
                  data-name="Path 2660"
                  d="M952.309 103.168a6.065 6.065 0 003.26 2.659c.978.155 1.1-.784.267-2.1a6.085 6.085 0 00-3.26-2.657c-.972-.156-1.091.781-.267 2.098z"
              />
              <path
                  data-name="Path 2661"
                  d="M946.68 102.278a6 6 0 003.254 2.652c.979.154 1.106-.785.284-2.1a6.031 6.031 0 00-3.253-2.65c-.976-.15-1.104.788-.285 2.098z"
              />
              <path
                  data-name="Path 2662"
                  d="M941.033 101.404a5.934 5.934 0 003.247 2.647c.983.152 1.116-.789.3-2.1a5.953 5.953 0 00-3.245-2.644c-.977-.151-1.113.789-.302 2.097z"
              />
              <path
                  data-name="Path 2663"
                  d="M935.373 100.541a5.877 5.877 0 003.239 2.64c.986.15 1.128-.79.319-2.1a5.886 5.886 0 00-3.237-2.637c-.981-.149-1.128.791-.321 2.097z"
              />
              <path
                  data-name="Path 2664"
                  d="M929.692 99.691a5.822 5.822 0 003.23 2.634c.989.147 1.14-.794.339-2.1a5.839 5.839 0 00-3.229-2.632c-.982-.146-1.135.791-.34 2.098z"
              />
              <path
                  data-name="Path 2665"
                  d="M923.998 98.851a5.746 5.746 0 003.22 2.627c.991.145 1.15-.8.358-2.1a5.774 5.774 0 00-3.218-2.625c-.987-.146-1.144.793-.36 2.098z"
              />
              <path
                  data-name="Path 2666"
                  d="M918.287 98.022a5.676 5.676 0 003.21 2.62c.994.144 1.163-.8.378-2.1a5.7 5.7 0 00-3.21-2.619c-.986-.142-1.156.797-.378 2.099z"
              />
              <path
                  data-name="Path 2667"
                  d="M912.563 97.206a5.619 5.619 0 003.2 2.614c1 .142 1.173-.8.4-2.1a5.623 5.623 0 00-3.2-2.612c-.99-.143-1.17.796-.4 2.098z"
              />
              <path
                  data-name="Path 2668"
                  d="M906.822 96.401a5.544 5.544 0 003.19 2.607c1 .14 1.186-.8.418-2.1a5.569 5.569 0 00-3.188-2.605c-.995-.141-1.182.798-.42 2.098z"
              />
              <path
                  data-name="Path 2669"
                  d="M901.069 95.607a5.469 5.469 0 003.178 2.6c1 .137 1.2-.805.436-2.1a5.5 5.5 0 00-3.177-2.6c-.993-.138-1.191.803-.437 2.1z"
              />
              <path
                  data-name="Path 2670"
                  d="M895.298 94.826a5.417 5.417 0 003.167 2.6c1.006.135 1.21-.807.458-2.106a5.43 5.43 0 00-3.165-2.592c-.998-.138-1.203.803-.46 2.098z"
              />
              <path
                  data-name="Path 2671"
                  d="M889.516 94.058a5.346 5.346 0 003.155 2.589c1.008.134 1.222-.81.48-2.107a5.365 5.365 0 00-3.153-2.583c-1.006-.133-1.219.806-.482 2.101z"
              />
              <path
                  data-name="Path 2672"
                  d="M883.717 93.307a5.278 5.278 0 003.143 2.58c1.009.13 1.233-.812.5-2.107a5.3 5.3 0 00-3.142-2.577c-1.001-.134-1.228.804-.501 2.104z"
              />
              <path
                  data-name="Path 2673"
                  d="M877.907 92.559a5.2 5.2 0 003.13 2.573c1.011.129 1.245-.817.521-2.109a5.232 5.232 0 00-3.128-2.57c-1.005-.126-1.24.814-.523 2.106z"
              />
              <path
                  data-name="Path 2674"
                  d="M872.081 91.827a5.133 5.133 0 003.115 2.567c1.016.127 1.258-.817.545-2.109a5.154 5.154 0 00-3.115-2.563c-1.007-.126-1.252.815-.545 2.105z"
              />
              <path
                  data-name="Path 2675"
                  d="M866.24 91.108a5.071 5.071 0 003.1 2.56c1.016.125 1.27-.821.567-2.111a5.1 5.1 0 00-3.1-2.557c-1.008-.121-1.262.825-.567 2.108z"
              />
              <path
                  data-name="Path 2676"
                  d="M860.389 90.403a5 5 0 003.088 2.552c1.021.124 1.283-.824.59-2.111a5.019 5.019 0 00-3.086-2.548c-1.013-.122-1.277.821-.592 2.107z"
              />
              <path
                  data-name="Path 2677"
                  d="M854.523 89.71a4.938 4.938 0 003.075 2.547c1.023.12 1.3-.827.612-2.114a4.954 4.954 0 00-3.073-2.542c-1.013-.119-1.289.824-.614 2.109z"
              />
              <path
                  data-name="Path 2678"
                  d="M848.645 89.03a4.85 4.85 0 003.058 2.538c1.023.119 1.307-.829.635-2.112a4.879 4.879 0 00-3.058-2.535c-1.014-.117-1.301.825-.635 2.109z"
              />
              <path
                  data-name="Path 2679"
                  d="M842.756 88.363a4.783 4.783 0 003.043 2.532c1.026.117 1.32-.832.658-2.114a4.811 4.811 0 00-3.043-2.528c-1.016-.115-1.313.829-.658 2.11z"
              />
              <path
                  data-name="Path 2680"
                  d="M836.852 87.71a4.721 4.721 0 003.028 2.525c1.028.114 1.333-.836.682-2.117a4.743 4.743 0 00-3.026-2.52c-1.022-.112-1.326.832-.684 2.112z"
              />
              <path
                  data-name="Path 2681"
                  d="M830.938 87.069a4.644 4.644 0 003.01 2.517c1.031.112 1.347-.837.707-2.117a4.67 4.67 0 00-3.01-2.513c-1.023-.111-1.339.835-.707 2.113z"
              />
              <path
                  data-name="Path 2682"
                  d="M825.01 86.441a4.572 4.572 0 002.993 2.508c1.033.109 1.36-.841.73-2.119a4.6 4.6 0 00-2.993-2.507c-1.024-.104-1.35.842-.73 2.118z"
              />
              <path
                  data-name="Path 2683"
                  d="M819.072 85.828a4.514 4.514 0 002.976 2.5c1.036.107 1.374-.844.755-2.121a4.533 4.533 0 00-2.976-2.5c-1.026-.105-1.364.846-.755 2.121z"
              />
              <path
                  data-name="Path 2684"
                  d="M813.121 85.228a4.445 4.445 0 002.961 2.5c1.036.1 1.385-.847.777-2.122a4.462 4.462 0 00-2.958-2.493c-1.029-.104-1.377.842-.78 2.115z"
              />
              <path
                  data-name="Path 2685"
                  d="M807.16 84.642a4.371 4.371 0 002.943 2.492c1.038.1 1.4-.851.8-2.124a4.4 4.4 0 00-2.941-2.487c-1.028-.098-1.387.847-.802 2.119z"
              />
              <path
                  data-name="Path 2686"
                  d="M801.188 84.07a4.309 4.309 0 002.924 2.483c1.039.1 1.412-.854.827-2.126a4.328 4.328 0 00-2.921-2.478c-1.03-.098-1.405.851-.83 2.121z"
              />
              <path
                  data-name="Path 2687"
                  d="M795.205 83.512a4.235 4.235 0 002.9 2.475c1.043.1 1.424-.856.854-2.126a4.254 4.254 0 00-2.9-2.472c-1.034-.096-1.417.853-.854 2.123z"
              />
              <path
                  data-name="Path 2688"
                  d="M789.211 82.968a4.17 4.17 0 002.886 2.468c1.044.1 1.439-.859.879-2.129a4.187 4.187 0 00-2.888-2.463c-1.032-.093-1.425.855-.877 2.124z"
              />
              <path
                  data-name="Path 2689"
                  d="M783.207 82.437a4.107 4.107 0 002.867 2.463c1.046.092 1.451-.864.9-2.131a4.121 4.121 0 00-2.866-2.458c-1.027-.091-1.437.86-.901 2.126z"
              />
              <path
                  data-name="Path 2690"
                  d="M777.193 81.92a4.042 4.042 0 002.847 2.456c1.049.089 1.464-.867.931-2.134a4.055 4.055 0 00-2.846-2.45c-1.039-.088-1.455.863-.932 2.128z"
              />
              <path
                  data-name="Path 2691"
                  d="M771.17 81.418a3.97 3.97 0 002.827 2.448c1.049.087 1.477-.871.956-2.136a4 4 0 00-2.826-2.443c-1.04-.085-1.472.867-.957 2.131z"
              />
              <path
                  data-name="Path 2692"
                  d="M765.136 80.93a3.91 3.91 0 002.807 2.441c1.053.084 1.492-.874.983-2.137a3.922 3.922 0 00-2.806-2.436c-1.043-.083-1.484.869-.984 2.132z"
              />
              <path
                  data-name="Path 2693"
                  d="M759.094 80.455a3.841 3.841 0 002.787 2.435c1.053.082 1.506-.877 1.009-2.139a3.856 3.856 0 00-2.786-2.428c-1.044-.084-1.497.872-1.01 2.132z"
              />
              <path
                  data-name="Path 2694"
                  d="M753.042 79.997a3.771 3.771 0 002.766 2.426c1.054.08 1.517-.881 1.036-2.141a3.8 3.8 0 00-2.766-2.421c-1.046-.079-1.511.877-1.036 2.136z"
              />
              <path
                  data-name="Path 2695"
                  d="M746.982 79.553a3.709 3.709 0 002.746 2.42c1.056.077 1.531-.884 1.061-2.142a3.731 3.731 0 00-2.744-2.416c-1.047-.077-1.525.879-1.063 2.138z"
              />
              <path
                  data-name="Path 2696"
                  d="M740.912 79.122a3.65 3.65 0 002.724 2.415c1.058.075 1.546-.887 1.09-2.146a3.677 3.677 0 00-2.724-2.41c-1.049-.071-1.538.885-1.09 2.141z"
              />
              <path
                  data-name="Path 2697"
                  d="M734.835 78.708a3.587 3.587 0 002.7 2.406c1.059.072 1.557-.891 1.116-2.147a3.605 3.605 0 00-2.7-2.4c-1.05-.073-1.549.884-1.116 2.141z"
              />
              <path
                  data-name="Path 2698"
                  d="M728.747 78.306a3.528 3.528 0 002.68 2.4c1.061.069 1.572-.9 1.143-2.151a3.55 3.55 0 00-2.68-2.4c-1.05-.063-1.562.896-1.143 2.151z"
              />
              <path
                  data-name="Path 2699"
                  d="M722.654 77.916a3.469 3.469 0 002.659 2.395c1.061.067 1.586-.9 1.17-2.154a3.48 3.48 0 00-2.657-2.388c-1.053-.063-1.576.898-1.172 2.147z"
              />
              <path
                  data-name="Path 2700"
                  d="M716.557 77.549a3.405 3.405 0 002.635 2.388c1.065.064 1.6-.9 1.2-2.156a3.419 3.419 0 00-2.635-2.381c-1.059-.063-1.6.898-1.2 2.149z"
              />
              <path
                  data-name="Path 2701"
                  d="M710.443 77.193a3.349 3.349 0 002.614 2.381c1.065.062 1.613-.907 1.225-2.159a3.363 3.363 0 00-2.612-2.375c-1.057-.061-1.605.902-1.227 2.153z"
              />
              <path
                  data-name="Path 2702"
                  d="M704.328 76.853a3.286 3.286 0 002.59 2.375c1.066.06 1.628-.911 1.253-2.162a3.308 3.308 0 00-2.59-2.368c-1.056-.059-1.618.905-1.253 2.155z"
              />
              <path
                  data-name="Path 2703"
                  d="M698.204 76.526a3.226 3.226 0 002.567 2.368c1.068.057 1.641-.914 1.28-2.164a3.244 3.244 0 00-2.567-2.361c-1.056-.057-1.631.907-1.28 2.157z"
              />
              <path
                  data-name="Path 2704"
                  d="M692.075 76.216a3.169 3.169 0 002.543 2.361c1.069.055 1.653-.917 1.307-2.167a3.184 3.184 0 00-2.542-2.355c-1.059-.054-1.642.912-1.308 2.161z"
              />
              <path
                  data-name="Path 2705"
                  d="M685.939 75.921a3.113 3.113 0 002.52 2.355c1.069.05 1.668-.922 1.335-2.171a3.132 3.132 0 00-2.52-2.35c-1.059-.049-1.657.918-1.335 2.166z"
              />
              <path
                  data-name="Path 2706"
                  d="M679.796 75.641a3.058 3.058 0 002.5 2.348c1.07.048 1.681-.926 1.362-2.174a3.077 3.077 0 00-2.495-2.343c-1.063-.047-1.674.92-1.367 2.169z"
              />
              <path
                  data-name="Path 2707"
                  d="M673.649 75.375a3.006 3.006 0 002.473 2.343c1.071.045 1.694-.931 1.39-2.177a3.02 3.02 0 00-2.472-2.336c-1.063-.046-1.691.925-1.391 2.17z"
              />
              <path
                  data-name="Path 2708"
                  d="M667.493 75.126a2.95 2.95 0 002.446 2.336c1.073.043 1.708-.936 1.419-2.181a2.965 2.965 0 00-2.446-2.331c-1.063-.041-1.698.933-1.419 2.176z"
              />
              <path
                  data-name="Path 2709"
                  d="M661.332 74.888a2.9 2.9 0 002.423 2.329c1.074.04 1.721-.941 1.447-2.184a2.915 2.915 0 00-2.423-2.324c-1.063-.036-1.711.939-1.447 2.179z"
              />
              <path
                  data-name="Path 2710"
                  d="M655.167 74.672a2.844 2.844 0 002.4 2.324c1.075.038 1.735-.944 1.476-2.187a2.858 2.858 0 00-2.4-2.318c-1.065-.036-1.725.938-1.476 2.181z"
              />
              <path
                  data-name="Path 2711"
                  d="M648.997 74.47a2.793 2.793 0 002.375 2.318c1.074.035 1.748-.948 1.5-2.192a2.806 2.806 0 00-2.373-2.311c-1.063-.036-1.738.942-1.502 2.185z"
              />
              <path
                  data-name="Path 2712"
                  d="M642.821 74.28a2.744 2.744 0 002.348 2.313c1.078.032 1.763-.952 1.531-2.2a2.755 2.755 0 00-2.346-2.306c-1.071-.027-1.752.951-1.533 2.193z"
              />
              <path
                  data-name="Path 2713"
                  d="M636.642 74.109a2.693 2.693 0 002.323 2.306c1.078.03 1.776-.956 1.559-2.2a2.7 2.7 0 00-2.323-2.3c-1.066-.029-1.764.953-1.559 2.194z"
              />
              <path
                  data-name="Path 2714"
                  d="M630.459 73.952a2.641 2.641 0 002.3 2.3 1.708 1.708 0 001.587-2.2 2.659 2.659 0 00-2.3-2.294c-1.067-.031-1.777.952-1.587 2.194z"
              />
              <path
                  data-name="Path 2715"
                  d="M624.271 73.812a2.592 2.592 0 002.271 2.293 1.736 1.736 0 001.616-2.206 2.609 2.609 0 00-2.271-2.289 1.743 1.743 0 00-1.616 2.202z"
              />
              <path
                  data-name="Path 2716"
                  d="M618.078 73.684a2.55 2.55 0 002.246 2.289 1.766 1.766 0 001.644-2.211 2.562 2.562 0 00-2.246-2.283 1.77 1.77 0 00-1.644 2.205z"
              />
              <path
                  data-name="Path 2717"
                  d="M611.883 73.574a2.5 2.5 0 002.219 2.284 1.794 1.794 0 001.673-2.216 2.515 2.515 0 00-2.221-2.278 1.8 1.8 0 00-1.671 2.21z"
              />
              <path
                  data-name="Path 2718"
                  d="M605.68 73.48a2.458 2.458 0 002.194 2.278 1.824 1.824 0 001.7-2.221 2.467 2.467 0 00-2.193-2.271 1.831 1.831 0 00-1.701 2.214z"
              />
              <path
                  data-name="Path 2719"
                  d="M599.483 73.402a2.412 2.412 0 002.167 2.272 1.856 1.856 0 001.728-2.226 2.421 2.421 0 00-2.167-2.264 1.86 1.86 0 00-1.728 2.218z"
              />
              <path
                  data-name="Path 2720"
                  d="M593.277 73.339a2.37 2.37 0 002.142 2.268 1.884 1.884 0 001.755-2.229 2.381 2.381 0 00-2.141-2.261 1.893 1.893 0 00-1.756 2.222z"
              />
              <path
                  data-name="Path 2721"
                  d="M587.072 73.292a2.324 2.324 0 002.114 2.261 1.917 1.917 0 001.785-2.233 2.336 2.336 0 00-2.116-2.254 1.923 1.923 0 00-1.783 2.226z"
              />
              <path
                  data-name="Path 2722"
                  d="M580.862 73.26a2.281 2.281 0 002.087 2.256 1.95 1.95 0 001.813-2.238 2.294 2.294 0 00-2.089-2.249 1.958 1.958 0 00-1.811 2.231z"
              />
              <path
                  data-name="Path 2723"
                  d="M574.65 73.245a2.242 2.242 0 002.06 2.251 1.983 1.983 0 001.84-2.243 2.251 2.251 0 00-2.06-2.244 1.99 1.99 0 00-1.84 2.236z"
              />
              <path
                  data-name="Path 2724"
                  d="M568.436 73.245a2.207 2.207 0 002.035 2.246 2.019 2.019 0 001.868-2.248 2.214 2.214 0 00-2.035-2.239 2.027 2.027 0 00-1.868 2.241z"
              />
              <path
                  data-name="Path 2725"
                  d="M562.221 73.262a2.167 2.167 0 002.011 2.245 2.055 2.055 0 001.895-2.254 2.172 2.172 0 00-2.007-2.233 2.061 2.061 0 00-1.899 2.242z"
              />
              <path
                  data-name="Path 2726"
                  d="M556.004 73.293a2.128 2.128 0 001.982 2.236 2.092 2.092 0 001.923-2.258 2.136 2.136 0 00-1.982-2.229 2.1 2.1 0 00-1.923 2.251z"
              />
              <path
                  data-name="Path 2727"
                  d="M549.788 73.34a2.088 2.088 0 001.953 2.231 2.128 2.128 0 001.952-2.263 2.1 2.1 0 00-1.955-2.224 2.135 2.135 0 00-1.95 2.256z"
              />
              <path
                  data-name="Path 2728"
                  d="M518.693 73.814a1.917 1.917 0 001.818 2.207 2.33 2.33 0 002.089-2.289 1.925 1.925 0 00-1.818-2.2 2.339 2.339 0 00-2.089 2.282z"
              />
              <path
                  data-name="Path 2729"
                  d="M512.475 73.958a1.886 1.886 0 001.79 2.2 2.372 2.372 0 002.116-2.3 1.891 1.891 0 00-1.79-2.2 2.381 2.381 0 00-2.116 2.3z"
              />
              <path
                  data-name="Path 2730"
                  d="M345.69 83.694c-.478 1.265-.005 2.214 1.058 2.116a3.855 3.855 0 002.777-2.468c.47-1.265 0-2.209-1.058-2.112a3.881 3.881 0 00-2.777 2.464z"
              />
              <path
                  data-name="Path 2731"
                  d="M321.34 86.072c-.531 1.27-.1 2.216.956 2.107a4.121 4.121 0 002.859-2.5c.521-1.268.092-2.211-.958-2.1a4.144 4.144 0 00-2.857 2.493z"
              />
              <path
                  data-name="Path 2732"
                  d="M315.271 86.699c-.541 1.272-.125 2.216.931 2.106a4.185 4.185 0 002.879-2.5c.533-1.272.115-2.211-.931-2.1a4.219 4.219 0 00-2.879 2.494z"
              />
              <path
                  data-name="Path 2733"
                  d="M309.216 87.347c-.555 1.273-.149 2.216.906 2.1a4.254 4.254 0 002.9-2.51c.545-1.273.139-2.212-.906-2.1a4.282 4.282 0 00-2.9 2.51z"
              />
              <path
                  data-name="Path 2734"
                  d="M303.168 88.005c-.567 1.275-.174 2.217.879 2.1a4.325 4.325 0 002.918-2.517c.558-1.275.164-2.212-.881-2.1a4.351 4.351 0 00-2.916 2.517z"
              />
              <path
                  data-name="Path 2735"
                  d="M285.084 90.066c-.6 1.28-.241 2.221.807 2.1a4.534 4.534 0 002.971-2.54c.593-1.278.232-2.214-.807-2.094a4.557 4.557 0 00-2.971 2.534z"
              />
              <path
                  data-name="Path 2736"
                  d="M267.095 92.252c-.635 1.283-.307 2.223.735 2.092a4.744 4.744 0 003.023-2.56c.627-1.283.3-2.218-.737-2.089a4.766 4.766 0 00-3.021 2.557z"
              />
              <path
                  data-name="Path 2737"
                  d="M261.12 93.004c-.647 1.287-.328 2.223.712 2.092a4.816 4.816 0 003.038-2.568c.64-1.285.319-2.217-.712-2.087a4.831 4.831 0 00-3.038 2.563z"
              />
              <path
                  data-name="Path 2738"
                  d="M255.158 93.775c-.657 1.287-.349 2.224.688 2.091a4.881 4.881 0 003.055-2.575c.648-1.287.339-2.219-.69-2.085a4.9 4.9 0 00-3.053 2.569z"
              />
              <path
                  data-name="Path 2739"
                  d="M249.207 94.558c-.668 1.288-.369 2.226.665 2.089a4.957 4.957 0 003.071-2.582c.658-1.287.361-2.221-.668-2.086a4.977 4.977 0 00-3.068 2.579z"
              />
              <path
                  data-name="Path 2740"
                  d="M243.268 95.353c-.677 1.29-.389 2.226.643 2.087a5.019 5.019 0 003.085-2.588c.67-1.288.383-2.223-.643-2.084a5.045 5.045 0 00-3.085 2.585z"
              />
              <path
                  data-name="Path 2741"
                  d="M237.343 96.158c-.688 1.292-.409 2.228.622 2.087a5.1 5.1 0 003.1-2.6c.682-1.292.4-2.223-.622-2.084a5.125 5.125 0 00-3.1 2.597z"
              />
              <path
                  data-name="Path 2742"
                  d="M231.43 96.985c-.7 1.293-.431 2.228.6 2.084a5.16 5.16 0 003.113-2.6c.69-1.292.421-2.224-.6-2.08a5.18 5.18 0 00-3.113 2.596z"
              />
              <path
                  data-name="Path 2743"
                  d="M225.53 97.817c-.709 1.3-.45 2.229.577 2.084a5.241 5.241 0 003.128-2.61c.7-1.293.441-2.224-.578-2.079a5.248 5.248 0 00-3.127 2.605z"
              />
              <path
                  data-name="Path 2744"
                  d="M219.643 98.662c-.717 1.3-.47 2.231.555 2.082a5.3 5.3 0 003.142-2.615c.709-1.3.46-2.226-.556-2.079a5.327 5.327 0 00-3.141 2.612z"
              />
              <path
                  data-name="Path 2745"
                  d="M213.769 99.521c-.725 1.3-.486 2.233.535 2.082a5.381 5.381 0 003.153-2.622c.719-1.3.48-2.228-.535-2.079a5.407 5.407 0 00-3.153 2.619z"
              />
              <path
                  data-name="Path 2746"
                  d="M207.91 100.393c-.735 1.3-.506 2.233.513 2.08a5.443 5.443 0 003.165-2.629c.729-1.3.5-2.229-.513-2.077a5.462 5.462 0 00-3.165 2.626z"
              />
              <path
                  data-name="Path 2747"
                  d="M202.065 101.277c-.744 1.3-.523 2.233.493 2.079a5.51 5.51 0 003.177-2.635c.737-1.3.516-2.229-.493-2.075a5.53 5.53 0 00-3.177 2.631z"
              />
              <path
                  data-name="Path 2748"
                  d="M196.234 102.172c-.754 1.3-.543 2.234.471 2.079a5.579 5.579 0 003.188-2.642c.747-1.3.535-2.231-.473-2.075a5.6 5.6 0 00-3.186 2.638z"
              />
              <path
                  data-name="Path 2749"
                  d="M190.418 103.082c-.76 1.3-.558 2.234.453 2.075a5.638 5.638 0 003.2-2.649c.755-1.3.553-2.231-.453-2.074a5.675 5.675 0 00-3.2 2.648z"
              />
              <path
                  data-name="Path 2750"
                  d="M184.617 104.001c-.77 1.307-.577 2.236.431 2.075a5.719 5.719 0 003.21-2.654c.764-1.305.57-2.233-.433-2.074a5.735 5.735 0 00-3.208 2.653z"
              />
              <path
                  data-name="Path 2751"
                  d="M178.83 104.933c-.777 1.307-.592 2.236.414 2.074a5.778 5.778 0 003.218-2.66c.772-1.307.587-2.236-.414-2.072a5.8 5.8 0 00-3.218 2.658z"
              />
              <path
                  data-name="Path 2752"
                  d="M173.055 105.876c-.785 1.31-.608 2.238.394 2.074a5.854 5.854 0 003.229-2.667c.78-1.308.6-2.234-.394-2.072a5.883 5.883 0 00-3.229 2.665z"
              />
              <path
                  data-name="Path 2753"
                  d="M167.307 106.832c-.794 1.31-.625 2.238.374 2.072a5.92 5.92 0 003.238-2.674c.787-1.308.617-2.236-.378-2.07a5.929 5.929 0 00-3.234 2.672z"
              />
              <path
                  data-name="Path 2754"
                  d="M161.568 107.795c-.8 1.312-.642 2.239.356 2.07a5.978 5.978 0 003.247-2.679c.794-1.312.633-2.236-.359-2.069a6 6 0 00-3.244 2.678z"
              />
              <path
                  data-name="Path 2755"
                  d="M155.846 108.775c-.807 1.312-.657 2.241.338 2.07a6.047 6.047 0 003.254-2.685c.8-1.312.65-2.238-.339-2.069a6.059 6.059 0 00-3.253 2.684z"
              />
              <path
                  data-name="Path 2756"
                  d="M1029.582 110.144a6.845 6.845 0 003.3 2.736c.926.179.948-.754.05-2.086a6.839 6.839 0 00-3.3-2.737c-.923-.177-.944.757-.05 2.087z"
              />
              <path
                  data-name="Path 2757"
                  d="M1024.237 109.116a6.8 6.8 0 003.3 2.731c.929.177.956-.755.06-2.085a6.808 6.808 0 00-3.3-2.732c-.924-.178-.95.757-.06 2.086z"
              />
              <path
                  data-name="Path 2758"
                  d="M1018.878 108.099a6.74 6.74 0 003.3 2.724c.931.177.961-.757.07-2.085a6.768 6.768 0 00-3.3-2.726c-.927-.176-.961.758-.07 2.087z"
              />
              <path
                  data-name="Path 2759"
                  d="M1013.499 107.089a6.709 6.709 0 003.3 2.72c.934.174.969-.76.082-2.087a6.71 6.71 0 00-3.3-2.72c-.931-.174-.97.76-.082 2.087z"
              />
              <path
                  data-name="Path 2760"
                  d="M1008.103 106.09a6.667 6.667 0 003.3 2.714c.937.174.981-.76.094-2.087a6.659 6.659 0 00-3.3-2.714c-.934-.174-.978.761-.094 2.087z"
              />
              <path
                  data-name="Path 2761"
                  d="M1002.688 105.1a6.619 6.619 0 003.3 2.709c.939.17.988-.764.105-2.089a6.632 6.632 0 00-3.294-2.709c-.936-.17-.99.764-.111 2.089z"
              />
              <path
                  data-name="Path 2762"
                  d="M997.259 104.119a6.559 6.559 0 003.294 2.7c.944.17 1-.765.119-2.089a6.568 6.568 0 00-3.292-2.7c-.941-.167-.995.767-.121 2.089z"
              />
              <path
                  data-name="Path 2763"
                  d="M991.812 103.151a6.518 6.518 0 003.29 2.7c.947.169 1.008-.765.132-2.087a6.515 6.515 0 00-3.289-2.7c-.944-.169-1.004.765-.133 2.087z"
              />
              <path
                  data-name="Path 2764"
                  d="M986.347 102.189a6.454 6.454 0 003.289 2.69c.949.167 1.014-.769.146-2.089a6.475 6.475 0 00-3.287-2.69c-.947-.165-1.013.769-.148 2.089z"
              />
              <path
                  data-name="Path 2765"
                  d="M980.866 101.239a6.41 6.41 0 003.285 2.685c.953.165 1.023-.77.159-2.089a6.41 6.41 0 00-3.282-2.684c-.947-.163-1.023.771-.162 2.088z"
              />
              <path
                  data-name="Path 2766"
                  d="M975.37 100.299a6.344 6.344 0 003.28 2.679c.956.164 1.033-.772.174-2.09a6.372 6.372 0 00-3.277-2.677c-.951-.161-1.033.773-.177 2.088z"
              />
              <path
                  data-name="Path 2767"
                  d="M969.857 99.37a6.286 6.286 0 003.274 2.672c.958.16 1.044-.774.19-2.09a6.3 6.3 0 00-3.274-2.67c-.951-.161-1.04.775-.19 2.088z"
              />
              <path
                  data-name="Path 2768"
                  d="M964.327 98.451a6.236 6.236 0 003.269 2.665c.961.16 1.054-.775.205-2.089a6.249 6.249 0 00-3.267-2.665c-.955-.159-1.055.777-.207 2.089z"
              />
              <path
                  data-name="Path 2769"
                  d="M958.783 97.542a6.17 6.17 0 003.264 2.66c.964.157 1.063-.779.22-2.091a6.193 6.193 0 00-3.262-2.659c-.959-.155-1.059.779-.222 2.09z"
              />
              <path
                  data-name="Path 2770"
                  d="M953.226 96.645a6.106 6.106 0 003.255 2.654c.968.157 1.073-.78.237-2.091a6.133 6.133 0 00-3.254-2.652c-.965-.154-1.073.78-.238 2.089z"
              />
              <path
                  data-name="Path 2771"
                  d="M947.647 95.759a6.062 6.062 0 003.249 2.647c.971.154 1.085-.784.256-2.092a6.075 6.075 0 00-3.249-2.644c-.966-.153-1.08.783-.256 2.089z"
              />
              <path
                  data-name="Path 2772"
                  d="M942.055 94.883a5.988 5.988 0 003.242 2.64c.974.152 1.095-.785.272-2.092a6.014 6.014 0 00-3.24-2.637c-.969-.148-1.091.784-.274 2.089z"
              />
              <path
                  data-name="Path 2773"
                  d="M936.451 94.02a5.921 5.921 0 003.234 2.632c.974.15 1.1-.787.289-2.091a5.961 5.961 0 00-3.232-2.632c-.971-.147-1.103.787-.291 2.091z"
              />
              <path
                  data-name="Path 2774"
                  d="M930.829 93.166a5.868 5.868 0 003.225 2.627c.978.149 1.115-.789.307-2.092a5.886 5.886 0 00-3.224-2.624c-.969-.147-1.111.787-.308 2.089z"
              />
              <path
                  data-name="Path 2775"
                  d="M925.19 92.325a5.793 5.793 0 003.215 2.619c.981.147 1.128-.79.326-2.092a5.813 5.813 0 00-3.215-2.617c-.968-.145-1.118.79-.326 2.09z"
              />
              <path
                  data-name="Path 2776"
                  d="M919.545 91.494a5.737 5.737 0 003.207 2.612c.983.145 1.136-.794.343-2.092a5.753 5.753 0 00-3.2-2.61c-.982-.142-1.137.792-.35 2.09z"
              />
              <path
                  data-name="Path 2777"
                  d="M913.881 90.676a5.657 5.657 0 003.2 2.605c.984.142 1.146-.8.361-2.092a5.677 5.677 0 00-3.193-2.6c-.985-.144-1.148.79-.368 2.087z"
              />
              <path
                  data-name="Path 2778"
                  d="M908.202 89.869a5.6 5.6 0 003.185 2.6c.988.14 1.158-.8.383-2.092a5.619 5.619 0 00-3.183-2.6c-.983-.137-1.155.799-.385 2.092z"
              />
              <path
                  data-name="Path 2779"
                  d="M902.511 89.073a5.531 5.531 0 003.175 2.592c.991.139 1.17-.8.4-2.094a5.547 5.547 0 00-3.172-2.588c-.983-.136-1.163.798-.403 2.09z"
              />
              <path
                  data-name="Path 2780"
                  d="M896.81 88.291a5.475 5.475 0 003.163 2.585c.993.135 1.181-.8.421-2.1a5.485 5.485 0 00-3.16-2.58c-.991-.131-1.18.803-.424 2.095z"
              />
              <path
                  data-name="Path 2781"
                  d="M891.087 87.521a5.393 5.393 0 003.152 2.577c1 .134 1.193-.8.441-2.094a5.414 5.414 0 00-3.148-2.575c-.988-.132-1.188.804-.445 2.092z"
              />
              <path
                  data-name="Path 2782"
                  d="M885.356 86.762a5.334 5.334 0 003.138 2.57c1 .132 1.206-.807.463-2.1a5.345 5.345 0 00-3.137-2.567c-.99-.125-1.199.81-.464 2.097z"
              />
              <path
                  data-name="Path 2783"
                  d="M879.613 86.015a5.261 5.261 0 003.125 2.563c1 .13 1.216-.809.484-2.1a5.275 5.275 0 00-3.125-2.56c-.994-.124-1.211.812-.484 2.097z"
              />
              <path
                  data-name="Path 2784"
                  d="M873.855 85.282a5.183 5.183 0 003.113 2.555c1 .129 1.228-.812.5-2.1a5.211 5.211 0 00-3.11-2.552c-.992-.122-1.219.813-.503 2.097z"
              />
              <path
                  data-name="Path 2785"
                  d="M868.085 84.56a5.117 5.117 0 003.1 2.548c1 .125 1.24-.814.526-2.1a5.141 5.141 0 00-3.1-2.543c-.995-.121-1.232.813-.526 2.095z"
              />
              <path
                  data-name="Path 2786"
                  d="M862.302 83.851a5.056 5.056 0 003.085 2.542c1.008.122 1.253-.817.548-2.1a5.067 5.067 0 00-3.081-2.538c-1-.12-1.248.816-.552 2.096z"
              />
              <path
                  data-name="Path 2787"
                  d="M856.509 83.156a4.987 4.987 0 003.07 2.533c1.009.12 1.263-.82.572-2.1a5 5 0 00-3.07-2.53c-1.002-.118-1.259.818-.572 2.097z"
              />
              <path
                  data-name="Path 2788"
                  d="M850.701 82.472a4.912 4.912 0 003.055 2.527c1.011.119 1.277-.824.593-2.1a4.924 4.924 0 00-3.053-2.523c-1.004-.114-1.266.821-.595 2.096z"
              />
              <path
                  data-name="Path 2789"
                  d="M844.883 81.802a4.843 4.843 0 003.04 2.518c1.014.117 1.288-.826.617-2.1a4.861 4.861 0 00-3.038-2.515c-1.003-.112-1.282.822-.619 2.097z"
              />
              <path
                  data-name="Path 2790"
                  d="M839.054 81.144a4.772 4.772 0 003.023 2.513c1.016.114 1.3-.829.64-2.1a4.79 4.79 0 00-3.023-2.507c-1.008-.115-1.294.823-.64 2.094z"
              />
              <path
                  data-name="Path 2791"
                  d="M833.212 80.501a4.7 4.7 0 003.008 2.5c1.018.112 1.313-.831.663-2.1a4.72 4.72 0 00-3.006-2.5c-1.009-.108-1.307.83-.665 2.1z"
              />
              <path
                  data-name="Path 2792"
                  d="M827.36 79.869a4.634 4.634 0 002.991 2.5c1.018.109 1.327-.834.687-2.1a4.649 4.649 0 00-2.99-2.492c-1.01-.114-1.318.823-.688 2.092z"
              />
              <path
                  data-name="Path 2793"
                  d="M821.497 79.252a4.563 4.563 0 002.973 2.49c1.021.107 1.339-.837.71-2.1a4.575 4.575 0 00-2.971-2.485c-1.012-.111-1.333.827-.712 2.095z"
              />
              <path
                  data-name="Path 2794"
                  d="M815.624 78.648a4.5 4.5 0 002.956 2.483c1.024.105 1.352-.839.734-2.106a4.513 4.513 0 00-2.954-2.478c-1.014-.103-1.345.836-.736 2.101z"
              />
              <path
                  data-name="Path 2795"
                  d="M809.74 78.057a4.419 4.419 0 002.938 2.475c1.028.1 1.365-.842.758-2.106a4.443 4.443 0 00-2.938-2.471c-1.015-.1-1.356.839-.758 2.102z"
              />
              <path
                  data-name="Path 2796"
                  d="M803.845 77.479a4.355 4.355 0 002.921 2.468c1.028.1 1.377-.844.784-2.107a4.382 4.382 0 00-2.919-2.463c-1.02-.099-1.371.84-.786 2.102z"
              />
              <path
                  data-name="Path 2797"
                  d="M797.94 76.917a4.288 4.288 0 002.9 2.461c1.03.1 1.392-.849.809-2.109a4.308 4.308 0 00-2.9-2.456c-1.019-.096-1.383.844-.809 2.104z"
              />
              <path
                  data-name="Path 2798"
                  d="M792.025 76.367a4.216 4.216 0 002.884 2.453c1.031.1 1.4-.851.832-2.109a4.237 4.237 0 00-2.883-2.448c-1.02-.096-1.395.845-.833 2.104z"
              />
              <path
                  data-name="Path 2799"
                  d="M786.101 75.83a4.154 4.154 0 002.864 2.446c1.033.094 1.417-.854.859-2.111a4.168 4.168 0 00-2.864-2.441c-1.022-.091-1.407.849-.859 2.106z"
              />
              <path
                  data-name="Path 2800"
                  d="M780.167 75.309a4.083 4.083 0 002.846 2.438c1.034.092 1.429-.856.882-2.112a4.094 4.094 0 00-2.842-2.433c-1.026-.091-1.421.85-.886 2.107z"
              />
              <path
                  data-name="Path 2801"
                  d="M774.225 74.8a4.017 4.017 0 002.826 2.431c1.036.089 1.442-.859.907-2.114a4.031 4.031 0 00-2.822-2.426c-1.026-.087-1.436.856-.911 2.109z"
              />
              <path
                  data-name="Path 2802"
                  d="M768.272 74.306a3.952 3.952 0 002.806 2.425c1.038.087 1.454-.862.934-2.116a3.968 3.968 0 00-2.8-2.42c-1.03-.083-1.451.859-.94 2.111z"
              />
              <path
                  data-name="Path 2803"
                  d="M762.311 73.826a3.886 3.886 0 002.784 2.418c1.039.084 1.469-.867.961-2.119a3.9 3.9 0 00-2.782-2.411c-1.031-.082-1.461.862-.963 2.112z"
              />
              <path
                  data-name="Path 2804"
                  d="M756.342 73.36a3.819 3.819 0 002.764 2.411c1.041.08 1.48-.871.986-2.121a3.838 3.838 0 00-2.762-2.4c-1.031-.083-1.474.861-.988 2.11z"
              />
              <path
                  data-name="Path 2805"
                  d="M750.365 72.909a3.747 3.747 0 002.742 2.4c1.043.079 1.5-.872 1.013-2.121a3.766 3.766 0 00-2.742-2.4c-1.031-.072-1.486.875-1.013 2.121z"
              />
              <path
                  data-name="Path 2806"
                  d="M744.379 72.473a3.694 3.694 0 002.721 2.4c1.044.075 1.509-.877 1.039-2.124a3.707 3.707 0 00-2.721-2.391c-1.032-.078-1.498.868-1.039 2.115z"
              />
              <path
                  data-name="Path 2807"
                  d="M738.385 72.05a3.626 3.626 0 002.7 2.39c1.046.074 1.522-.881 1.066-2.126a3.644 3.644 0 00-2.7-2.385c-1.036-.071-1.512.876-1.066 2.121z"
              />
              <path
                  data-name="Path 2808"
                  d="M732.383 71.64a3.564 3.564 0 002.679 2.383c1.046.072 1.536-.884 1.091-2.127a3.576 3.576 0 00-2.675-2.376c-1.036-.071-1.528.877-1.095 2.12z"
              />
              <path
                  data-name="Path 2809"
                  d="M726.373 71.246a3.5 3.5 0 002.655 2.376c1.048.067 1.549-.887 1.12-2.131a3.518 3.518 0 00-2.655-2.37c-1.036-.064-1.539.886-1.12 2.125z"
              />
              <path
                  data-name="Path 2810"
                  d="M720.358 70.868a3.439 3.439 0 002.634 2.37c1.048.065 1.561-.892 1.146-2.134a3.459 3.459 0 00-2.634-2.363c-1.038-.063-1.552.886-1.146 2.127z"
              />
              <path
                  data-name="Path 2811"
                  d="M714.333 70.504a3.38 3.38 0 002.61 2.363c1.049.062 1.576-.9 1.175-2.136a3.4 3.4 0 00-2.61-2.356c-1.043-.062-1.567.889-1.175 2.129z"
              />
              <path
                  data-name="Path 2812"
                  d="M708.302 70.155a3.316 3.316 0 002.589 2.355c1.051.062 1.589-.9 1.2-2.137a3.336 3.336 0 00-2.587-2.35c-1.041-.06-1.579.892-1.202 2.132z"
              />
              <path
                  data-name="Path 2813"
                  d="M702.265 69.819a3.262 3.262 0 002.565 2.35c1.053.059 1.6-.9 1.228-2.141a3.28 3.28 0 00-2.563-2.343c-1.043-.057-1.594.897-1.23 2.134z"
              />
              <path
                  data-name="Path 2814"
                  d="M696.223 69.5a3.2 3.2 0 002.542 2.341c1.053.057 1.614-.906 1.255-2.142a3.223 3.223 0 00-2.542-2.336c-1.043-.055-1.605.901-1.255 2.137z"
              />
              <path
                  data-name="Path 2815"
                  d="M690.176 69.194a3.146 3.146 0 002.518 2.336c1.054.053 1.629-.911 1.283-2.146a3.161 3.161 0 00-2.518-2.329c-1.047-.052-1.623.904-1.283 2.139z"
              />
              <path
                  data-name="Path 2816"
                  d="M684.115 68.905a3.088 3.088 0 002.495 2.328c1.056.05 1.643-.912 1.31-2.149a3.1 3.1 0 00-2.493-2.323c-1.044-.049-1.633.907-1.312 2.144z"
              />
              <path
                  data-name="Path 2817"
                  d="M678.054 68.629a3.028 3.028 0 002.47 2.323c1.058.047 1.656-.917 1.337-2.152a3.047 3.047 0 00-2.468-2.316c-1.046-.047-1.646.912-1.339 2.145z"
              />
              <path
                  data-name="Path 2818"
                  d="M671.983 68.369a2.977 2.977 0 002.451 2.312c1.058.045 1.668-.921 1.365-2.154a2.988 2.988 0 00-2.445-2.309c-1.047-.037-1.659.918-1.371 2.151z"
              />
              <path
                  data-name="Path 2819"
                  d="M665.915 68.123a2.922 2.922 0 002.421 2.311c1.058.042 1.683-.926 1.394-2.159a2.939 2.939 0 00-2.421-2.3c-1.048-.044-1.673.917-1.394 2.148z"
              />
              <path
                  data-name="Path 2820"
                  d="M659.837 67.894a2.868 2.868 0 002.4 2.3c1.059.04 1.7-.929 1.422-2.161a2.885 2.885 0 00-2.4-2.3c-1.05-.033-1.686.93-1.422 2.161z"
              />
              <path
                  data-name="Path 2821"
                  d="M653.754 67.679a2.816 2.816 0 002.371 2.3c1.061.037 1.71-.934 1.449-2.164a2.828 2.828 0 00-2.371-2.291c-1.049-.039-1.698.925-1.449 2.155z"
              />
              <path
                  data-name="Path 2822"
                  d="M647.666 67.48a2.764 2.764 0 002.346 2.291c1.063.035 1.723-.937 1.477-2.167a2.779 2.779 0 00-2.346-2.286c-1.049-.034-1.717.932-1.477 2.162z"
              />
              <path
                  data-name="Path 2823"
                  d="M641.574 67.294a2.717 2.717 0 002.323 2.286c1.061.032 1.735-.942 1.5-2.171a2.725 2.725 0 00-2.319-2.279c-1.048-.03-1.723.936-1.504 2.164z"
              />
              <path
                  data-name="Path 2824"
                  d="M635.477 67.125a2.664 2.664 0 002.3 2.279c1.064.03 1.75-.946 1.534-2.174a2.677 2.677 0 00-2.3-2.274c-1.051-.027-1.737.941-1.534 2.169z"
              />
              <path
                  data-name="Path 2825"
                  d="M629.378 66.972a2.616 2.616 0 002.271 2.274 1.687 1.687 0 001.561-2.179 2.632 2.632 0 00-2.269-2.268c-1.053-.025-1.753.944-1.563 2.173z"
              />
              <path
                  data-name="Path 2826"
                  d="M623.274 66.833a2.567 2.567 0 002.244 2.268 1.713 1.713 0 001.591-2.182 2.582 2.582 0 00-2.244-2.261 1.718 1.718 0 00-1.591 2.175z"
              />
              <path
                  data-name="Path 2827"
                  d="M617.166 66.709a2.521 2.521 0 002.219 2.263 1.74 1.74 0 001.618-2.186 2.532 2.532 0 00-2.219-2.256 1.746 1.746 0 00-1.618 2.179z"
              />
              <path
                  data-name="Path 2828"
                  d="M611.054 66.602a2.477 2.477 0 002.194 2.258 1.771 1.771 0 001.644-2.192 2.485 2.485 0 00-2.192-2.249 1.775 1.775 0 00-1.646 2.183z"
              />
              <path
                  data-name="Path 2829"
                  d="M604.942 66.51a2.428 2.428 0 002.166 2.251 1.8 1.8 0 001.674-2.2 2.443 2.443 0 00-2.167-2.244 1.805 1.805 0 00-1.673 2.193z"
              />
              <path
                  data-name="Path 2830"
                  d="M598.826 66.434a2.387 2.387 0 002.141 2.246 1.831 1.831 0 001.7-2.2 2.4 2.4 0 00-2.139-2.238 1.835 1.835 0 00-1.702 2.192z"
              />
              <path
                  data-name="Path 2831"
                  d="M592.705 66.372a2.343 2.343 0 002.114 2.241 1.861 1.861 0 001.73-2.2 2.352 2.352 0 00-2.114-2.234 1.867 1.867 0 00-1.73 2.193z"
              />
              <path
                  data-name="Path 2832"
                  d="M586.582 66.325a2.3 2.3 0 002.089 2.236 1.891 1.891 0 001.756-2.207 2.31 2.31 0 00-2.087-2.229 1.9 1.9 0 00-1.758 2.2z"
              />
              <path
                  data-name="Path 2833"
                  d="M580.454 66.295a2.261 2.261 0 002.062 2.231 1.926 1.926 0 001.785-2.214 2.268 2.268 0 00-2.06-2.223 1.932 1.932 0 00-1.787 2.206z"
              />
              <path
                  data-name="Path 2834"
                  d="M574.331 66.28a2.22 2.22 0 002.035 2.226 1.959 1.959 0 001.813-2.217 2.229 2.229 0 00-2.034-2.219 1.968 1.968 0 00-1.814 2.21z"
              />
              <path
                  data-name="Path 2835"
                  d="M568.204 66.28a2.179 2.179 0 002.009 2.221 2 2 0 001.842-2.223 2.188 2.188 0 00-2.009-2.212 2 2 0 00-1.842 2.214z"
              />
              <path
                  data-name="Path 2836"
                  d="M513.009 66.985a1.86 1.86 0 001.763 2.179 2.347 2.347 0 002.091-2.269 1.866 1.866 0 00-1.763-2.171 2.354 2.354 0 00-2.091 2.261z"
              />
              <path
                  data-name="Path 2837"
                  d="M506.876 67.14a1.829 1.829 0 001.736 2.174 2.388 2.388 0 002.116-2.274 1.833 1.833 0 00-1.735-2.167 2.4 2.4 0 00-2.117 2.267z"
              />
              <path
                  data-name="Path 2838"
                  d="M396.85 72.585c-.371 1.242.185 2.191 1.245 2.116a3.336 3.336 0 002.575-2.388c.363-1.242-.2-2.186-1.245-2.111a3.349 3.349 0 00-2.575 2.383z"
              />
              <path
                  data-name="Path 2839"
                  d="M390.774 73.03c-.386 1.243.159 2.192 1.218 2.114a3.4 3.4 0 002.6-2.4c.376-1.242-.169-2.184-1.218-2.107a3.414 3.414 0 00-2.6 2.393z"
              />
              <path
                  data-name="Path 2840"
                  d="M324.332 78.918c-.53 1.26-.112 2.2.934 2.091a4.1 4.1 0 002.837-2.473c.52-1.26.1-2.191-.934-2.087a4.129 4.129 0 00-2.837 2.469z"
              />
              <path
                  data-name="Path 2841"
                  d="M294.435 82.169c-.59 1.267-.227 2.2.81 2.084a4.448 4.448 0 002.933-2.512c.583-1.267.219-2.2-.81-2.079a4.471 4.471 0 00-2.933 2.507z"
              />
              <path
                  data-name="Path 2842"
                  d="M288.486 82.86c-.6 1.268-.251 2.2.787 2.082a4.52 4.52 0 002.951-2.518c.593-1.268.241-2.2-.787-2.079a4.541 4.541 0 00-2.951 2.515z"
              />
              <path
                  data-name="Path 2843"
                  d="M282.54 83.565c-.613 1.272-.272 2.2.762 2.081a4.589 4.589 0 002.97-2.525c.6-1.272.262-2.2-.764-2.077a4.608 4.608 0 00-2.968 2.521z"
              />
              <path
                  data-name="Path 2844"
                  d="M276.608 84.284c-.623 1.273-.294 2.206.739 2.08a4.661 4.661 0 002.988-2.533c.617-1.273.284-2.2-.74-2.075a4.678 4.678 0 00-2.987 2.528z"
              />
              <path
                  data-name="Path 2845"
                  d="M270.684 85.016c-.635 1.275-.316 2.207.715 2.08a4.726 4.726 0 003-2.542c.628-1.273.307-2.2-.715-2.074a4.748 4.748 0 00-3 2.536z"
              />
              <path
                  data-name="Path 2846"
                  d="M264.772 85.762c-.647 1.275-.338 2.207.692 2.077a4.8 4.8 0 003.02-2.547c.638-1.277.327-2.2-.692-2.074a4.817 4.817 0 00-3.02 2.544z"
              />
              <path
                  data-name="Path 2847"
                  d="M258.871 86.522c-.658 1.278-.358 2.209.67 2.077a4.869 4.869 0 003.035-2.555c.65-1.277.349-2.2-.67-2.074a4.893 4.893 0 00-3.035 2.552z"
              />
              <path
                  data-name="Path 2848"
                  d="M252.98 87.295c-.668 1.28-.379 2.211.647 2.076a4.942 4.942 0 003.051-2.563c.66-1.278.369-2.206-.648-2.072a4.956 4.956 0 00-3.05 2.559z"
              />
              <path
                  data-name="Path 2849"
                  d="M247.099 88.08c-.678 1.282-.4 2.212.625 2.074a5.008 5.008 0 003.066-2.57c.67-1.28.389-2.206-.625-2.07a5.029 5.029 0 00-3.066 2.566z"
              />
              <path
                  data-name="Path 2850"
                  d="M241.23 88.879c-.688 1.283-.419 2.213.6 2.074a5.088 5.088 0 003.081-2.578c.68-1.282.409-2.208-.6-2.069a5.1 5.1 0 00-3.081 2.573z"
              />
              <path
                  data-name="Path 2851"
                  d="M235.372 89.69c-.7 1.285-.438 2.216.582 2.074a5.16 5.16 0 003.1-2.585c.69-1.283.429-2.209-.583-2.069a5.178 5.178 0 00-3.099 2.58z"
              />
              <path
                  data-name="Path 2852"
                  d="M229.527 90.515c-.707 1.287-.456 2.216.56 2.072a5.217 5.217 0 003.11-2.592c.7-1.285.45-2.213-.56-2.069a5.239 5.239 0 00-3.11 2.589z"
              />
              <path
                  data-name="Path 2853"
                  d="M223.695 91.352c-.719 1.288-.478 2.218.538 2.07a5.288 5.288 0 003.123-2.6c.71-1.287.47-2.213-.54-2.067a5.309 5.309 0 00-3.121 2.597z"
              />
              <path
                  data-name="Path 2854"
                  d="M217.873 92.201c-.725 1.292-.495 2.219.518 2.07a5.352 5.352 0 003.137-2.605c.72-1.29.488-2.216-.518-2.067a5.375 5.375 0 00-3.137 2.602z"
              />
              <path
                  data-name="Path 2855"
                  d="M212.066 93.064c-.735 1.292-.515 2.221.5 2.07a5.435 5.435 0 003.15-2.614c.729-1.292.506-2.216-.5-2.067a5.458 5.458 0 00-3.15 2.611z"
              />
              <path
                  data-name="Path 2856"
                  d="M206.271 93.937c-.744 1.3-.531 2.223.476 2.07a5.5 5.5 0 003.162-2.62c.739-1.293.525-2.217-.478-2.067a5.533 5.533 0 00-3.16 2.617z"
              />
              <path
                  data-name="Path 2857"
                  d="M200.484 94.825c-.754 1.3-.55 2.224.456 2.069a5.572 5.572 0 003.173-2.627c.747-1.3.541-2.219-.458-2.065a5.6 5.6 0 00-3.171 2.623z"
              />
              <path
                  data-name="Path 2858"
                  d="M194.721 95.724c-.762 1.3-.567 2.224.436 2.067a5.63 5.63 0 003.185-2.634c.755-1.3.56-2.219-.438-2.064a5.66 5.66 0 00-3.183 2.631z"
              />
              <path
                  data-name="Path 2859"
                  d="M177.498 98.493c-.785 1.3-.617 2.229.379 2.065a5.847 5.847 0 003.217-2.654c.779-1.3.608-2.226-.381-2.064a5.859 5.859 0 00-3.215 2.653z"
              />
              <path
                  data-name="Path 2860"
                  d="M171.787 99.439c-.792 1.305-.632 2.229.361 2.065a5.906 5.906 0 003.225-2.662c.787-1.3.625-2.226-.363-2.062a5.928 5.928 0 00-3.223 2.659z"
              />
              <path
                  data-name="Path 2861"
                  d="M166.09 100.396c-.8 1.307-.647 2.231.343 2.064a5.973 5.973 0 003.234-2.667c.8-1.305.64-2.229-.344-2.062a5.986 5.986 0 00-3.233 2.665z"
              />
              <path
                  data-name="Path 2862"
                  d="M1013.846 100.592a6.74 6.74 0 003.292 2.72c.929.176.958-.76.067-2.087a6.736 6.736 0 00-3.292-2.721c-.926-.173-.956.761-.067 2.088z"
              />
              <path
                  data-name="Path 2863"
                  d="M1008.486 99.593a6.687 6.687 0 003.292 2.714c.931.174.964-.76.075-2.087a6.693 6.693 0 00-3.29-2.714c-.928-.17-.963.761-.077 2.087z"
              />
              <path
                  data-name="Path 2864"
                  d="M1003.112 98.601a6.649 6.649 0 003.29 2.709c.934.172.973-.764.087-2.087a6.648 6.648 0 00-3.289-2.709c-.931-.17-.97.764-.088 2.087z"
              />
              <path
                  data-name="Path 2865"
                  d="M997.721 97.622a6.591 6.591 0 003.289 2.7c.937.172.979-.765.1-2.087a6.6 6.6 0 00-3.287-2.7c-.934-.169-.981.765-.102 2.087z"
              />
              <path
                  data-name="Path 2866"
                  d="M992.313 96.655a6.559 6.559 0 003.287 2.7c.939.169.989-.767.112-2.087a6.558 6.558 0 00-3.285-2.7c-.936-.171-.988.763-.114 2.087z"
              />
              <path
                  data-name="Path 2867"
                  d="M986.892 95.69a6.5 6.5 0 003.282 2.689c.943.167 1-.769.125-2.087a6.512 6.512 0 00-3.282-2.687c-.939-.166-.993.765-.125 2.085z"
              />
              <path
                  data-name="Path 2868"
                  d="M981.455 94.74a6.434 6.434 0 003.279 2.682c.946.165 1.008-.769.139-2.085a6.463 6.463 0 00-3.277-2.682c-.943-.166-1.005.77-.141 2.085z"
              />
              <path
                  data-name="Path 2869"
                  d="M976.003 93.801a6.392 6.392 0 003.273 2.675c.949.162 1.016-.77.152-2.087a6.4 6.4 0 00-3.271-2.677c-.946-.159-1.013.775-.154 2.089z"
              />
              <path
                  data-name="Path 2870"
                  d="M970.536 92.872a6.352 6.352 0 003.27 2.669c.951.16 1.024-.774.165-2.087a6.343 6.343 0 00-3.267-2.667c-.947-.161-1.022.773-.168 2.085z"
              />
              <path
                  data-name="Path 2871"
                  d="M965.055 91.953a6.286 6.286 0 003.264 2.662c.954.16 1.034-.775.18-2.087a6.294 6.294 0 00-3.262-2.66c-.949-.16-1.031.774-.182 2.085z"
              />
              <path
                  data-name="Path 2872"
                  d="M959.563 91.044a6.219 6.219 0 003.259 2.655c.956.159 1.043-.777.2-2.085a6.23 6.23 0 00-3.255-2.654c-.962-.154-1.051.777-.204 2.084z"
              />
              <path
                  data-name="Path 2873"
                  d="M954.049 90.145a6.165 6.165 0 003.252 2.649c.958.155 1.053-.779.211-2.086a6.167 6.167 0 00-3.248-2.647c-.955-.153-1.052.784-.215 2.084z"
              />
              <path
                  data-name="Path 2874"
                  d="M948.524 89.26a6.107 6.107 0 003.245 2.64c.961.154 1.061-.78.227-2.086a6.108 6.108 0 00-3.244-2.639c-.955-.152-1.058.78-.228 2.085z"
              />
              <path
                  data-name="Path 2875"
                  d="M942.987 88.384a6.049 6.049 0 003.237 2.634c.964.152 1.073-.782.244-2.085a6.055 6.055 0 00-3.235-2.632c-.958-.151-1.07.78-.246 2.083z"
              />
              <path
                  data-name="Path 2876"
                  d="M937.435 87.519a5.978 5.978 0 003.229 2.627c.966.15 1.083-.784.261-2.086a5.991 5.991 0 00-3.227-2.624c-.961-.144-1.08.785-.263 2.083z"
              />
              <path
                  data-name="Path 2877"
                  d="M931.871 86.667a5.911 5.911 0 003.22 2.619c.968.149 1.091-.785.276-2.084a5.916 5.916 0 00-3.219-2.617c-.961-.147-1.086.784-.277 2.082z"
              />
              <path
                  data-name="Path 2878"
                  d="M926.29 85.825a5.854 5.854 0 003.212 2.612c.969.145 1.1-.787.294-2.084a5.871 5.871 0 00-3.21-2.61c-.961-.144-1.098.785-.296 2.082z"
              />
              <path
                  data-name="Path 2879"
                  d="M920.7 84.994a5.794 5.794 0 003.2 2.605c.974.144 1.111-.79.313-2.085a5.8 5.8 0 00-3.2-2.6c-.967-.144-1.107.787-.313 2.08z"
              />
              <path
                  data-name="Path 2880"
                  d="M915.095 84.175a5.709 5.709 0 003.192 2.6c.972.142 1.123-.79.329-2.084a5.729 5.729 0 00-3.188-2.6c-.97-.142-1.12.792-.333 2.084z"
              />
              <path
                  data-name="Path 2881"
                  d="M909.477 83.368a5.662 5.662 0 003.18 2.59c.979.139 1.135-.794.349-2.085a5.659 5.659 0 00-3.178-2.585c-.971-.141-1.13.792-.351 2.08z"
              />
              <path
                  data-name="Path 2882"
                  d="M903.847 82.573a5.581 5.581 0 003.168 2.582c.981.139 1.146-.8.369-2.084a5.6 5.6 0 00-3.168-2.578c-.973-.138-1.14.793-.369 2.08z"
              />
              <path
                  data-name="Path 2883"
                  d="M898.204 81.792a5.517 5.517 0 003.157 2.575c.984.135 1.156-.8.388-2.084a5.528 5.528 0 00-3.155-2.572c-.977-.137-1.153.793-.39 2.081z"
              />
              <path
                  data-name="Path 2884"
                  d="M892.547 81.016a5.441 5.441 0 003.147 2.567c.984.134 1.168-.8.408-2.084a5.471 5.471 0 00-3.145-2.563c-.98-.132-1.158.799-.41 2.08z"
              />
              <path
                  data-name="Path 2885"
                  d="M886.88 80.258a5.374 5.374 0 003.133 2.558c.988.134 1.176-.8.428-2.084a5.4 5.4 0 00-3.133-2.555c-.981-.129-1.172.801-.428 2.081z"
              />
              <path
                  data-name="Path 2886"
                  d="M881.2 79.511a5.319 5.319 0 003.122 2.552c.988.13 1.188-.805.448-2.084a5.322 5.322 0 00-3.12-2.548c-.983-.129-1.183.8-.45 2.08z"
              />
              <path
                  data-name="Path 2887"
                  d="M875.508 78.776a5.235 5.235 0 003.108 2.543c.991.129 1.2-.805.468-2.084a5.25 5.25 0 00-3.1-2.54c-.989-.125-1.201.804-.476 2.081z"
              />
              <path
                  data-name="Path 2888"
                  d="M869.807 78.053a5.161 5.161 0 003.093 2.54c.993.125 1.21-.809.49-2.084a5.194 5.194 0 00-3.093-2.533c-.988-.127-1.205.804-.49 2.077z"
              />
              <path
                  data-name="Path 2889"
                  d="M864.093 77.342a5.1 5.1 0 003.08 2.53c1 .124 1.223-.812.511-2.085a5.132 5.132 0 00-3.078-2.525c-.991-.123-1.22.808-.513 2.08z"
              />
              <path
                  data-name="Path 2890"
                  d="M858.367 76.646a5.04 5.04 0 003.065 2.522c1 .12 1.235-.814.533-2.086a5.053 5.053 0 00-3.065-2.517c-.989-.12-1.228.811-.533 2.081z"
              />
              <path
                  data-name="Path 2891"
                  d="M852.63 75.96a4.963 4.963 0 003.05 2.513c1 .119 1.248-.815.555-2.084a4.976 4.976 0 00-3.05-2.51c-.991-.116-1.242.813-.555 2.081z"
              />
              <path
                  data-name="Path 2892"
                  d="M846.881 75.288a4.895 4.895 0 003.036 2.5c1 .119 1.257-.819.577-2.085a4.916 4.916 0 00-3.035-2.5c-.992-.112-1.251.82-.578 2.085z"
              />
              <path
                  data-name="Path 2893"
                  d="M841.124 74.628a4.815 4.815 0 003.018 2.5c1 .115 1.272-.82.6-2.085a4.841 4.841 0 00-3.018-2.493c-.994-.116-1.265.815-.6 2.078z"
              />
              <path
                  data-name="Path 2894"
                  d="M835.356 73.982a4.753 4.753 0 003 2.492c1.006.112 1.282-.824.622-2.087a4.769 4.769 0 00-3-2.485c-.996-.112-1.275.819-.622 2.08z"
              />
              <path
                  data-name="Path 2895"
                  d="M829.575 73.349a4.682 4.682 0 002.988 2.483c1.006.11 1.293-.826.643-2.087a4.7 4.7 0 00-2.984-2.478c-.999-.109-1.289.822-.647 2.082z"
              />
              <path
                  data-name="Path 2896"
                  d="M823.787 72.729a4.608 4.608 0 002.969 2.475c1.008.109 1.308-.829.668-2.087a4.629 4.629 0 00-2.968-2.47c-1-.108-1.301.823-.669 2.082z"
              />
              <path
                  data-name="Path 2897"
                  d="M817.988 72.122a4.54 4.54 0 002.951 2.467c1.011.105 1.318-.831.692-2.087a4.553 4.553 0 00-2.951-2.463c-1.001-.104-1.312.826-.692 2.083z"
              />
              <path
                  data-name="Path 2898"
                  d="M812.18 71.527a4.47 4.47 0 002.934 2.46c1.011.1 1.33-.832.715-2.087a4.494 4.494 0 00-2.934-2.456c-1.008-.103-1.323.828-.715 2.083z"
              />
              <path
                  data-name="Path 2899"
                  d="M806.361 70.947a4.4 4.4 0 002.916 2.451c1.014.1 1.345-.836.739-2.089a4.411 4.411 0 00-2.914-2.446c-1.006-.1-1.339.832-.741 2.084z"
              />
              <path
                  data-name="Path 2900"
                  d="M800.533 70.379a4.328 4.328 0 002.9 2.445c1.014.1 1.357-.837.762-2.089a4.348 4.348 0 00-2.9-2.44c-1.003-.097-1.347.834-.762 2.084z"
              />
              <path
                  data-name="Path 2901"
                  d="M794.695 69.826a4.268 4.268 0 002.879 2.438c1.018.1 1.369-.842.789-2.092a4.284 4.284 0 00-2.878-2.431c-1.006-.096-1.362.837-.79 2.085z"
              />
              <path
                  data-name="Path 2902"
                  d="M788.85 69.286a4.2 4.2 0 002.861 2.43c1.018.094 1.382-.846.812-2.092a4.216 4.216 0 00-2.859-2.425c-1.01-.092-1.376.84-.814 2.087z"
              />
              <path
                  data-name="Path 2903"
                  d="M782.994 68.76a4.126 4.126 0 002.841 2.421c1.021.092 1.4-.847.837-2.092a4.135 4.135 0 00-2.839-2.416c-1.012-.091-1.387.842-.839 2.087z"
              />
              <path
                  data-name="Path 2904"
                  d="M777.131 68.246a4.061 4.061 0 002.822 2.415c1.023.09 1.407-.851.862-2.094a4.077 4.077 0 00-2.821-2.41c-1.012-.087-1.399.846-.863 2.089z"
              />
              <path
                  data-name="Path 2905"
                  d="M771.258 67.753a3.991 3.991 0 002.8 2.406c1.024.087 1.419-.854.887-2.1a4 4 0 00-2.8-2.4c-1.01-.086-1.41.848-.887 2.094z"
              />
              <path
                  data-name="Path 2906"
                  d="M765.38 67.264a3.924 3.924 0 002.781 2.4c1.024.084 1.432-.857.912-2.1a3.939 3.939 0 00-2.779-2.395c-1.018-.08-1.426.855-.914 2.095z"
              />
              <path
                  data-name="Path 2907"
                  d="M759.491 66.793a3.856 3.856 0 002.761 2.391c1.026.082 1.447-.859.939-2.1a3.877 3.877 0 00-2.759-2.388c-1.018-.077-1.439.859-.941 2.097z"
              />
              <path
                  data-name="Path 2908"
                  d="M753.595 66.335a3.785 3.785 0 002.739 2.385c1.028.08 1.459-.861.964-2.1a3.809 3.809 0 00-2.737-2.38c-1.019-.078-1.457.858-.966 2.095z"
              />
              <path
                  data-name="Path 2909"
                  d="M747.692 65.892a3.724 3.724 0 002.717 2.378c1.029.077 1.472-.866.991-2.1a3.745 3.745 0 00-2.717-2.371c-1.019-.077-1.464.858-.991 2.093z"
              />
              <path
                  data-name="Path 2910"
                  d="M741.78 65.463a3.663 3.663 0 002.7 2.371c1.029.075 1.486-.869 1.016-2.1a3.678 3.678 0 00-2.7-2.365c-1.02-.075-1.474.864-1.016 2.094z"
              />
              <path
                  data-name="Path 2911"
                  d="M735.861 65.048a3.6 3.6 0 002.675 2.365c1.031.072 1.5-.872 1.043-2.106a3.616 3.616 0 00-2.674-2.358c-1.023-.071-1.489.868-1.044 2.099z"
              />
              <path
                  data-name="Path 2912"
                  d="M729.935 64.649a3.535 3.535 0 002.654 2.356c1.033.07 1.511-.876 1.069-2.107a3.552 3.552 0 00-2.652-2.35c-1.023-.068-1.503.871-1.071 2.101z"
              />
              <path
                  data-name="Path 2913"
                  d="M724.002 64.263a3.477 3.477 0 002.63 2.35c1.034.067 1.524-.881 1.1-2.109a3.5 3.5 0 00-2.629-2.345c-1.027-.065-1.52.874-1.101 2.104z"
              />
              <path
                  data-name="Path 2914"
                  d="M718.059 63.89a3.411 3.411 0 002.607 2.343c1.036.064 1.539-.884 1.123-2.111a3.425 3.425 0 00-2.607-2.336c-1.023-.063-1.523.876-1.123 2.104z"
              />
              <path
                  data-name="Path 2915"
                  d="M712.116 63.533a3.353 3.353 0 002.587 2.336c1.036.062 1.551-.886 1.15-2.112a3.37 3.37 0 00-2.585-2.329c-1.026-.063-1.541.878-1.152 2.105z"
              />
              <path
                  data-name="Path 2916"
                  d="M706.165 63.19a3.292 3.292 0 002.562 2.329c1.039.06 1.564-.891 1.176-2.116a3.308 3.308 0 00-2.562-2.323c-1.026-.056-1.553.885-1.176 2.11z"
              />
              <path
                  data-name="Path 2917"
                  d="M700.207 62.862a3.233 3.233 0 002.538 2.321c1.039.057 1.577-.892 1.2-2.117a3.251 3.251 0 00-2.538-2.316c-1.023-.055-1.565.889-1.2 2.112z"
              />
              <path
                  data-name="Path 2918"
                  d="M694.242 62.548a3.176 3.176 0 002.515 2.316c1.039.053 1.591-.9 1.232-2.121a3.194 3.194 0 00-2.515-2.309c-1.03-.054-1.581.892-1.232 2.114z"
              />
              <path
                  data-name="Path 2919"
                  d="M688.272 62.249a3.121 3.121 0 002.492 2.309c1.041.052 1.6-.9 1.258-2.124a3.129 3.129 0 00-2.49-2.3c-1.031-.052-1.596.894-1.26 2.115z"
              />
              <path
                  data-name="Path 2920"
                  d="M682.296 61.965a3.06 3.06 0 002.468 2.3c1.041.05 1.618-.9 1.287-2.124a3.078 3.078 0 00-2.468-2.3c-1.032-.044-1.608.904-1.287 2.124z"
              />
              <path
                  data-name="Path 2921"
                  d="M676.317 61.696a3 3 0 002.443 2.294c1.043.047 1.631-.907 1.313-2.127a3.017 3.017 0 00-2.441-2.289c-1.035-.045-1.625.902-1.315 2.122z"
              />
              <path
                  data-name="Path 2922"
                  d="M670.329 61.441a2.951 2.951 0 002.42 2.289c1.043.043 1.643-.912 1.34-2.131a2.962 2.962 0 00-2.418-2.283c-1.035-.043-1.635.906-1.342 2.125z"
              />
              <path
                  data-name="Path 2923"
                  d="M664.339 61.2a2.89 2.89 0 002.395 2.283c1.044.042 1.656-.916 1.369-2.134a2.909 2.909 0 00-2.395-2.274c-1.035-.042-1.647.909-1.369 2.125z"
              />
              <path
                  data-name="Path 2924"
                  d="M658.342 60.974a2.84 2.84 0 002.37 2.278c1.046.038 1.669-.919 1.4-2.137a2.858 2.858 0 00-2.37-2.269c-1.034-.039-1.664.912-1.4 2.128z"
              />
              <path
                  data-name="Path 2925"
                  d="M652.342 60.765a2.789 2.789 0 002.344 2.269c1.046.037 1.683-.922 1.424-2.139a2.8 2.8 0 00-2.343-2.262c-1.036-.037-1.674.916-1.425 2.132z"
              />
              <path
                  data-name="Path 2926"
                  d="M646.337 60.57a2.738 2.738 0 002.319 2.264c1.048.033 1.7-.929 1.452-2.144a2.751 2.751 0 00-2.316-2.258c-1.039-.033-1.69.921-1.455 2.138z"
              />
              <path
                  data-name="Path 2927"
                  d="M640.329 60.389a2.685 2.685 0 002.294 2.258c1.048.032 1.709-.932 1.481-2.147a2.7 2.7 0 00-2.294-2.251c-1.037-.031-1.7.93-1.481 2.14z"
              />
              <path
                  data-name="Path 2928"
                  d="M634.316 60.224a2.633 2.633 0 002.269 2.251c1.048.028 1.723-.936 1.507-2.149a2.65 2.65 0 00-2.269-2.246c-1.038-.028-1.713.932-1.507 2.144z"
              />
              <path
                  data-name="Path 2929"
                  d="M628.299 60.074a2.589 2.589 0 002.244 2.246c1.049.025 1.736-.941 1.536-2.154a2.6 2.6 0 00-2.243-2.239c-1.038-.026-1.726.932-1.537 2.147z"
              />
              <path
                  data-name="Path 2930"
                  d="M622.279 59.936a2.539 2.539 0 002.218 2.241 1.69 1.69 0 001.564-2.157 2.553 2.553 0 00-2.218-2.233 1.693 1.693 0 00-1.564 2.149z"
              />
              <path
                  data-name="Path 2931"
                  d="M616.255 59.816a2.494 2.494 0 002.193 2.236 1.717 1.717 0 001.591-2.161 2.5 2.5 0 00-2.191-2.229 1.723 1.723 0 00-1.593 2.154z"
              />
              <path
                  data-name="Path 2932"
                  d="M610.229 59.71a2.447 2.447 0 002.166 2.231 1.747 1.747 0 001.619-2.166 2.458 2.458 0 00-2.166-2.223 1.75 1.75 0 00-1.619 2.158z"
              />
              <path
                  data-name="Path 2933"
                  d="M604.2 59.622a2.4 2.4 0 002.139 2.223 1.774 1.774 0 001.648-2.169 2.411 2.411 0 00-2.139-2.216 1.78 1.78 0 00-1.648 2.162z"
              />
              <path
                  data-name="Path 2934"
                  d="M598.163 59.547a2.355 2.355 0 002.114 2.217 1.8 1.8 0 001.674-2.172 2.369 2.369 0 00-2.112-2.212 1.811 1.811 0 00-1.676 2.167z"
              />
              <path
                  data-name="Path 2935"
                  d="M592.133 59.487a2.312 2.312 0 002.085 2.213 1.835 1.835 0 001.7-2.177 2.325 2.325 0 00-2.087-2.206 1.841 1.841 0 00-1.698 2.17z"
              />
              <path
                  data-name="Path 2936"
                  d="M586.096 59.442a2.271 2.271 0 002.06 2.207 1.865 1.865 0 001.731-2.181 2.28 2.28 0 00-2.06-2.2 1.873 1.873 0 00-1.731 2.174z"
              />
              <path
                  data-name="Path 2937"
                  d="M580.057 59.412a2.232 2.232 0 002.034 2.2 1.9 1.9 0 001.76-2.187 2.24 2.24 0 00-2.034-2.2 1.908 1.908 0 00-1.76 2.187z"
              />
              <path
                  data-name="Path 2938"
                  d="M574.015 59.398a2.19 2.19 0 002.007 2.2 1.934 1.934 0 001.788-2.191 2.2 2.2 0 00-2.007-2.191 1.941 1.941 0 00-1.788 2.182z"
              />
              <path
                  data-name="Path 2939"
                  d="M567.973 59.398a2.151 2.151 0 001.98 2.194 1.967 1.967 0 001.815-2.2 2.158 2.158 0 00-1.98-2.186 1.975 1.975 0 00-1.815 2.192z"
              />
              <path
                  data-name="Path 2940"
                  d="M561.928 59.415a2.111 2.111 0 001.953 2.187 2 2 0 001.843-2.2 2.121 2.121 0 00-1.953-2.181 2.01 2.01 0 00-1.843 2.194z"
              />
              <path
                  data-name="Path 2941"
                  d="M555.886 59.446a2.074 2.074 0 001.927 2.182 2.039 2.039 0 001.872-2.2 2.082 2.082 0 00-1.927-2.176 2.048 2.048 0 00-1.872 2.194z"
              />
              <path
                  data-name="Path 2942"
                  d="M549.837 59.495a2.037 2.037 0 001.9 2.179 2.078 2.078 0 001.9-2.211 2.046 2.046 0 00-1.9-2.171 2.084 2.084 0 00-1.9 2.203z"
              />
              <path
                  data-name="Path 2943"
                  d="M519.595 59.955a1.867 1.867 0 001.763 2.157 2.274 2.274 0 002.035-2.236 1.871 1.871 0 00-1.763-2.149 2.287 2.287 0 00-2.035 2.228z"
              />
              <path
                  data-name="Path 2944"
                  d="M404.955 65.173c-.359 1.228.2 2.167 1.247 2.1a3.246 3.246 0 002.525-2.355c.349-1.227-.209-2.162-1.247-2.09a3.265 3.265 0 00-2.525 2.345z"
              />
              <path
                  data-name="Path 2945"
                  d="M398.951 65.598c-.371 1.228.174 2.169 1.22 2.094a3.306 3.306 0 002.55-2.363c.363-1.228-.184-2.161-1.22-2.087a3.322 3.322 0 00-2.55 2.356z"
              />
              <path
                  data-name="Path 2946"
                  d="M392.951 66.038c-.386 1.23.149 2.169 1.195 2.091a3.372 3.372 0 002.573-2.368c.374-1.23-.159-2.162-1.195-2.086a3.381 3.381 0 00-2.573 2.363z"
              />
              <path
                  data-name="Path 2947"
                  d="M345.15 70.076c-.493 1.243-.052 2.174.986 2.077a3.878 3.878 0 002.752-2.428c.483-1.242.042-2.167-.986-2.07a3.894 3.894 0 00-2.752 2.421z"
              />
              <path
                  data-name="Path 2948"
                  d="M339.202 70.647c-.5 1.245-.074 2.176.963 2.075a3.948 3.948 0 002.774-2.435c.495-1.243.063-2.169-.963-2.07a3.97 3.97 0 00-2.774 2.43z"
              />
              <path
                  data-name="Path 2949"
                  d="M333.26 71.231c-.518 1.247-.1 2.176.936 2.074a4.012 4.012 0 002.8-2.443c.508-1.245.089-2.169-.937-2.069a4.032 4.032 0 00-2.799 2.438z"
              />
              <path
                  data-name="Path 2950"
                  d="M327.326 71.83c-.53 1.248-.122 2.177.911 2.072a4.083 4.083 0 002.816-2.45c.521-1.247.112-2.172-.912-2.069a4.1 4.1 0 00-2.815 2.447z"
              />
              <path
                  data-name="Path 2951"
                  d="M321.4 72.441c-.543 1.25-.145 2.179.887 2.072a4.151 4.151 0 002.834-2.458c.535-1.25.137-2.172-.886-2.067a4.174 4.174 0 00-2.835 2.453z"
              />
              <path
                  data-name="Path 2952"
                  d="M315.481 73.068c-.555 1.252-.169 2.181.862 2.07a4.219 4.219 0 002.854-2.467c.546-1.25.16-2.174-.862-2.065a4.244 4.244 0 00-2.854 2.462z"
              />
              <path
                  data-name="Path 2953"
                  d="M297.77 75.03c-.592 1.257-.239 2.184.789 2.067a4.427 4.427 0 002.913-2.488c.582-1.258.227-2.179-.79-2.064a4.446 4.446 0 00-2.912 2.485z"
              />
              <path
                  data-name="Path 2954"
                  d="M291.883 75.71c-.6 1.26-.261 2.186.765 2.067a4.5 4.5 0 002.931-2.5c.593-1.257.249-2.179-.767-2.062a4.524 4.524 0 00-2.929 2.495z"
              />
              <path
                  data-name="Path 2955"
                  d="M286.005 76.405c-.613 1.262-.281 2.187.742 2.067a4.57 4.57 0 002.949-2.5c.6-1.26.271-2.182-.744-2.06a4.588 4.588 0 00-2.947 2.493z"
              />
              <path
                  data-name="Path 2956"
                  d="M280.134 77.113c-.623 1.263-.3 2.189.72 2.065a4.638 4.638 0 002.966-2.512c.617-1.262.294-2.182-.72-2.06a4.661 4.661 0 00-2.966 2.507z"
              />
              <path
                  data-name="Path 2957"
                  d="M274.273 77.831c-.635 1.265-.324 2.192.7 2.065a4.714 4.714 0 002.983-2.52c.628-1.265.316-2.186-.7-2.06a4.738 4.738 0 00-2.983 2.515z"
              />
              <path
                  data-name="Path 2958"
                  d="M268.423 78.57c-.647 1.268-.346 2.192.673 2.065a4.782 4.782 0 003-2.528c.64-1.265.338-2.187-.673-2.059a4.8 4.8 0 00-3 2.522z"
              />
              <path
                  data-name="Path 2959"
                  d="M262.581 79.32c-.657 1.27-.366 2.2.652 2.064a4.848 4.848 0 003.016-2.535c.65-1.268.358-2.187-.652-2.059a4.879 4.879 0 00-3.016 2.53z"
              />
              <path
                  data-name="Path 2960"
                  d="M256.751 80.081c-.668 1.272-.388 2.2.628 2.064a4.928 4.928 0 003.033-2.543c.66-1.27.378-2.189-.63-2.059a4.939 4.939 0 00-3.031 2.538z"
              />
              <path
                  data-name="Path 2961"
                  d="M250.929 80.857c-.678 1.273-.408 2.2.607 2.062a4.994 4.994 0 003.048-2.55c.67-1.272.4-2.191-.607-2.059a5.017 5.017 0 00-3.048 2.547z"
              />
              <path
                  data-name="Path 2962"
                  d="M245.116 81.644c-.688 1.275-.426 2.2.585 2.06a5.068 5.068 0 003.065-2.557c.68-1.275.418-2.194-.587-2.059a5.086 5.086 0 00-3.063 2.556z"
              />
              <path
                  data-name="Path 2963"
                  d="M239.317 82.445c-.7 1.278-.446 2.2.563 2.06a5.132 5.132 0 003.078-2.565c.69-1.277.438-2.2-.565-2.057a5.158 5.158 0 00-3.076 2.562z"
              />
              <path
                  data-name="Path 2964"
                  d="M233.526 83.26c-.709 1.278-.466 2.2.541 2.06a5.2 5.2 0 003.093-2.573c.7-1.278.458-2.2-.543-2.057a5.226 5.226 0 00-3.091 2.57z"
              />
              <path
                  data-name="Path 2965"
                  d="M227.746 84.085c-.717 1.282-.483 2.206.521 2.06a5.275 5.275 0 003.108-2.58c.71-1.28.476-2.2-.523-2.057a5.312 5.312 0 00-3.106 2.577z"
              />
              <path
                  data-name="Path 2966"
                  d="M221.979 84.924c-.727 1.283-.5 2.207.5 2.06a5.352 5.352 0 003.122-2.588c.72-1.282.495-2.2-.5-2.055a5.361 5.361 0 00-3.122 2.583z"
              />
              <path
                  data-name="Path 2967"
                  d="M216.222 85.776c-.735 1.285-.52 2.209.481 2.059a5.419 5.419 0 003.133-2.6c.729-1.283.513-2.2-.481-2.055a5.434 5.434 0 00-3.133 2.596z"
              />
              <path
                  data-name="Path 2968"
                  d="M210.477 86.64c-.744 1.287-.538 2.211.461 2.059a5.487 5.487 0 003.147-2.6c.737-1.285.53-2.206-.463-2.055a5.511 5.511 0 00-3.145 2.596z"
              />
              <path
                  data-name="Path 2969"
                  d="M204.745 87.515c-.754 1.29-.556 2.212.441 2.059a5.548 5.548 0 003.157-2.61c.747-1.288.55-2.208-.441-2.055a5.576 5.576 0 00-3.157 2.606z"
              />
              <path
                  data-name="Path 2970"
                  d="M187.621 90.217c-.777 1.3-.607 2.218.384 2.057a5.76 5.76 0 003.192-2.632c.772-1.3.6-2.214-.386-2.055a5.775 5.775 0 00-3.19 2.63z"
              />
              <path
                  data-name="Path 2971"
                  d="M181.939 91.142c-.787 1.3-.623 2.219.366 2.057a5.821 5.821 0 003.2-2.639c.78-1.3.615-2.216-.366-2.057a5.846 5.846 0 00-3.2 2.639z"
              />
              <path
                  data-name="Path 2972"
                  d="M1003.473 92.104a6.67 6.67 0 003.285 2.707c.927.172.958-.762.07-2.087a6.668 6.668 0 00-3.284-2.707c-.921-.17-.954.764-.071 2.087z"
              />
              <path
                  data-name="Path 2973"
                  d="M987.37 89.196a6.526 6.526 0 003.277 2.687c.936.167.983-.767.105-2.085a6.521 6.521 0 00-3.275-2.685c-.931-.166-.981.767-.107 2.083z"
              />
              <path
                  data-name="Path 2974"
                  d="M976.564 87.306a6.426 6.426 0 003.269 2.672c.941.164 1-.77.13-2.084a6.434 6.434 0 00-3.267-2.672c-.935-.156-.996.776-.132 2.084z"
              />
              <path
                  data-name="Path 2975"
                  d="M971.142 86.382a6.371 6.371 0 003.264 2.665c.943.16 1.008-.772.144-2.084a6.376 6.376 0 00-3.262-2.664c-.941-.159-1.007.773-.146 2.083z"
              />
              <path
                  data-name="Path 2976"
                  d="M965.707 85.466a6.311 6.311 0 003.257 2.657c.946.16 1.016-.774.159-2.082a6.327 6.327 0 00-3.257-2.657c-.941-.158-1.012.774-.159 2.082z"
              />
              <path
                  data-name="Path 2977"
                  d="M960.255 84.558a6.258 6.258 0 003.254 2.65c.946.159 1.024-.775.172-2.082a6.274 6.274 0 00-3.252-2.649c-.944-.155-1.021.776-.174 2.081z"
              />
              <path
                  data-name="Path 2978"
                  d="M954.794 83.663a6.2 6.2 0 003.245 2.644c.949.155 1.033-.777.187-2.082a6.208 6.208 0 00-3.245-2.64c-.945-.155-1.032.774-.187 2.078z"
              />
              <path
                  data-name="Path 2979"
                  d="M949.319 82.778a6.131 6.131 0 003.24 2.634c.953.155 1.041-.777.2-2.08a6.151 6.151 0 00-3.237-2.632c-.947-.153-1.039.777-.203 2.078z"
              />
              <path
                  data-name="Path 2980"
                  d="M943.83 81.904a6.085 6.085 0 003.232 2.627c.954.152 1.051-.78.217-2.081a6.1 6.1 0 00-3.23-2.625c-.949-.15-1.048.781-.219 2.079z"
              />
              <path
                  data-name="Path 2981"
                  d="M938.331 81.04a6.02 6.02 0 003.224 2.62c.958.15 1.059-.782.232-2.079a6.027 6.027 0 00-3.222-2.619c-.951-.147-1.057.783-.234 2.078z"
              />
              <path
                  data-name="Path 2982"
                  d="M932.818 80.188a5.951 5.951 0 003.215 2.612c.959.149 1.07-.782.249-2.077a5.971 5.971 0 00-3.214-2.61c-.952-.145-1.066.782-.25 2.075z"
              />
              <path
                  data-name="Path 2983"
                  d="M927.293 79.349a5.9 5.9 0 003.205 2.6c.962.147 1.079-.785.266-2.077a5.892 5.892 0 00-3.2-2.6c-.958-.142-1.08.786-.271 2.077z"
              />
              <path
                  data-name="Path 2984"
                  d="M921.757 78.519a5.827 5.827 0 003.2 2.6c.964.144 1.09-.787.284-2.077a5.851 5.851 0 00-3.2-2.594c-.958-.145-1.086.782-.284 2.071z"
              />
              <path
                  data-name="Path 2985"
                  d="M916.207 77.702a5.761 5.761 0 003.187 2.589c.964.142 1.1-.789.3-2.075a5.767 5.767 0 00-3.185-2.585c-.959-.143-1.094.786-.302 2.071z"
              />
              <path
                  data-name="Path 2986"
                  d="M910.65 76.896a5.7 5.7 0 003.175 2.58c.969.14 1.11-.79.319-2.075a5.713 5.713 0 00-3.175-2.577c-.963-.139-1.107.788-.319 2.072z"
              />
              <path
                  data-name="Path 2987"
                  d="M905.077 76.102a5.635 5.635 0 003.163 2.572c.971.137 1.12-.792.338-2.075a5.643 5.643 0 00-3.163-2.568c-.963-.136-1.117.79-.338 2.071z"
              />
              <path
                  data-name="Path 2988"
                  d="M899.493 75.319a5.563 5.563 0 003.152 2.565c.971.137 1.13-.8.356-2.074a5.578 5.578 0 00-3.152-2.562c-.97-.134-1.124.792-.356 2.071z"
              />
              <path
                  data-name="Path 2989"
                  d="M893.898 74.548a5.5 5.5 0 003.14 2.557c.974.134 1.143-.8.374-2.074a5.5 5.5 0 00-3.138-2.553c-.967-.132-1.138.794-.376 2.07z"
              />
              <path
                  data-name="Path 2990"
                  d="M888.289 73.785a5.429 5.429 0 003.128 2.548c.978.132 1.153-.8.394-2.074a5.438 5.438 0 00-3.127-2.543c-.965-.125-1.144.799-.395 2.069z"
              />
              <path
                  data-name="Path 2991"
                  d="M882.676 73.043a5.358 5.358 0 003.117 2.54c.979.13 1.163-.8.413-2.072a5.374 5.374 0 00-3.115-2.537c-.972-.129-1.159.797-.415 2.069z"
              />
              <path
                  data-name="Path 2992"
                  d="M877.049 72.307a5.291 5.291 0 003.1 2.533c.981.127 1.173-.8.435-2.072a5.3 5.3 0 00-3.1-2.528c-.973-.127-1.168.798-.435 2.067z"
              />
              <path
                  data-name="Path 2993"
                  d="M871.411 71.586a5.214 5.214 0 003.088 2.525c.983.125 1.185-.8.454-2.072a5.228 5.228 0 00-3.088-2.52c-.974-.124-1.178.8-.454 2.067z"
              />
              <path
                  data-name="Path 2994"
                  d="M865.759 70.875a5.144 5.144 0 003.075 2.517c.984.124 1.195-.805.475-2.072a5.174 5.174 0 00-3.073-2.512c-.977-.122-1.189.802-.477 2.067z"
              />
              <path
                  data-name="Path 2995"
                  d="M860.104 70.178a5.078 5.078 0 003.06 2.508c.986.12 1.206-.807.495-2.072a5.1 5.1 0 00-3.058-2.5c-.978-.123-1.202.803-.497 2.064z"
              />
              <path
                  data-name="Path 2996"
                  d="M854.434 69.493a5.01 5.01 0 003.046 2.5c.988.119 1.218-.809.516-2.072a5.03 5.03 0 00-3.045-2.495c-.978-.117-1.21.805-.517 2.067z"
              />
              <path
                  data-name="Path 2997"
                  d="M848.756 68.819a4.935 4.935 0 003.03 2.492c.989.117 1.232-.81.538-2.07a4.956 4.956 0 00-3.028-2.487c-.982-.115-1.225.807-.54 2.065z"
              />
              <path
                  data-name="Path 2998"
                  d="M843.063 68.159a4.865 4.865 0 003.015 2.485c.989.115 1.24-.814.56-2.072a4.887 4.887 0 00-3.013-2.478c-.98-.114-1.231.81-.562 2.065z"
              />
              <path
                  data-name="Path 2999"
                  d="M837.369 67.513a4.792 4.792 0 003 2.475c.993.114 1.253-.815.581-2.07a4.807 4.807 0 00-3-2.47c-.982-.112-1.244.812-.581 2.065z"
              />
              <path
                  data-name="Path 3000"
                  d="M831.661 66.878a4.728 4.728 0 002.983 2.468c.994.11 1.263-.819.6-2.07a4.739 4.739 0 00-2.98-2.463c-.982-.109-1.253.815-.603 2.065z"
              />
              <path
                  data-name="Path 3001"
                  d="M825.945 66.257a4.651 4.651 0 002.966 2.46c1 .109 1.275-.82.627-2.07a4.674 4.674 0 00-2.964-2.455c-.988-.106-1.273.816-.629 2.065z"
              />
              <path
                  data-name="Path 3002"
                  d="M820.22 65.648a4.581 4.581 0 002.948 2.451c1 .107 1.287-.822.648-2.07a4.6 4.6 0 00-2.945-2.446c-.991-.104-1.281.818-.651 2.065z"
              />
              <path
                  data-name="Path 3003"
                  d="M814.484 65.054a4.514 4.514 0 002.929 2.443c1 .1 1.3-.824.674-2.07a4.528 4.528 0 00-2.929-2.438c-.99-.103-1.292.82-.674 2.065z"
              />
              <path
                  data-name="Path 3004"
                  d="M808.739 64.47a4.447 4.447 0 002.913 2.436c1 .1 1.313-.827.7-2.072a4.465 4.465 0 00-2.911-2.43c-.994-.098-1.309.825-.702 2.066z"
              />
              <path
                  data-name="Path 3005"
                  d="M802.987 63.902a4.374 4.374 0 002.894 2.426c1 .1 1.323-.829.72-2.07a4.4 4.4 0 00-2.893-2.423c-.993-.097-1.317.825-.721 2.067z"
              />
              <path
                  data-name="Path 3006"
                  d="M797.225 63.346a4.311 4.311 0 002.876 2.42c1 .1 1.335-.832.744-2.072a4.325 4.325 0 00-2.873-2.415c-.994-.094-1.33.828-.747 2.067z"
              />
              <path
                  data-name="Path 3007"
                  d="M791.457 62.803a4.24 4.24 0 002.856 2.413c1.006.094 1.35-.836.769-2.074a4.258 4.258 0 00-2.856-2.406c-.995-.092-1.342.83-.769 2.067z"
              />
              <path
                  data-name="Path 3008"
                  d="M785.678 62.274a4.17 4.17 0 002.838 2.4c1.008.092 1.362-.837.792-2.074a4.186 4.186 0 00-2.834-2.4c-.998-.085-1.354.839-.796 2.074z"
              />
              <path
                  data-name="Path 3009"
                  d="M779.893 61.756a4.1 4.1 0 002.817 2.4c1.008.09 1.374-.841.817-2.074a4.118 4.118 0 00-2.816-2.391c-1-.09-1.368.836-.818 2.065z"
              />
              <path
                  data-name="Path 3010"
                  d="M774.1 61.254a4.035 4.035 0 002.8 2.39c1.009.087 1.385-.844.841-2.076a4.05 4.05 0 00-2.8-2.383c-.999-.084-1.376.84-.841 2.069z"
              />
              <path
                  data-name="Path 3011"
                  d="M768.296 60.767a3.966 3.966 0 002.777 2.381c1.011.085 1.4-.847.867-2.076a3.99 3.99 0 00-2.776-2.376c-.998-.083-1.388.842-.868 2.071z"
              />
              <path
                  data-name="Path 3012"
                  d="M762.491 60.293a3.9 3.9 0 002.757 2.373c1.013.082 1.412-.849.891-2.077a3.906 3.906 0 00-2.754-2.366c-1.004-.083-1.404.844-.894 2.07z"
              />
              <path
                  data-name="Path 3013"
                  d="M756.675 59.831a3.826 3.826 0 002.736 2.365c1.013.082 1.424-.852.917-2.077a3.847 3.847 0 00-2.734-2.36c-1.004-.078-1.417.847-.919 2.072z"
              />
              <path
                  data-name="Path 3014"
                  d="M750.852 59.384a3.759 3.759 0 002.716 2.358c1.014.079 1.435-.854.943-2.079a3.781 3.781 0 00-2.714-2.353c-1.006-.075-1.43.85-.945 2.074z"
              />
              <path
                  data-name="Path 3015"
                  d="M745.021 58.948a3.7 3.7 0 002.694 2.351c1.016.075 1.451-.857.969-2.08a3.723 3.723 0 00-2.692-2.345c-1.007-.073-1.442.852-.971 2.074z"
              />
              <path
                  data-name="Path 3016"
                  d="M739.184 58.529a3.632 3.632 0 002.672 2.343c1.018.074 1.464-.861 1-2.081a3.652 3.652 0 00-2.672-2.338c-1.011-.071-1.459.856-1 2.076z"
              />
              <path
                  data-name="Path 3017"
                  d="M733.34 58.123a3.573 3.573 0 002.65 2.336c1.019.07 1.476-.864 1.021-2.082a3.581 3.581 0 00-2.649-2.329c-1.009-.071-1.465.857-1.022 2.075z"
              />
              <path
                  data-name="Path 3018"
                  d="M727.491 57.736a3.505 3.505 0 002.627 2.328c1.021.069 1.487-.866 1.048-2.084a3.526 3.526 0 00-2.627-2.323c-1.009-.07-1.479.859-1.048 2.079z"
              />
              <path
                  data-name="Path 3019"
                  d="M721.635 57.355a3.448 3.448 0 002.605 2.321c1.021.067 1.5-.871 1.074-2.085a3.465 3.465 0 00-2.605-2.316c-1.011-.064-1.492.865-1.074 2.08z"
              />
              <path
                  data-name="Path 3020"
                  d="M715.773 56.991a3.387 3.387 0 002.582 2.314c1.023.063 1.516-.874 1.1-2.087a3.4 3.4 0 00-2.582-2.308c-1.01-.062-1.505.867-1.1 2.081z"
              />
              <path
                  data-name="Path 3021"
                  d="M709.904 56.641a3.33 3.33 0 002.56 2.308c1.023.06 1.527-.877 1.128-2.091a3.346 3.346 0 00-2.558-2.3c-1.015-.062-1.52.871-1.13 2.083z"
              />
              <path
                  data-name="Path 3022"
                  d="M704.03 56.305a3.265 3.265 0 002.537 2.3c1.024.058 1.541-.881 1.153-2.092a3.278 3.278 0 00-2.535-2.293c-1.014-.059-1.532.875-1.155 2.085z"
              />
              <path
                  data-name="Path 3023"
                  d="M698.151 55.984a3.206 3.206 0 002.512 2.294c1.026.055 1.554-.884 1.181-2.094a3.217 3.217 0 00-2.512-2.288c-1.016-.053-1.544.88-1.181 2.088z"
              />
              <path
                  data-name="Path 3024"
                  d="M692.265 55.677a3.144 3.144 0 002.49 2.288c1.026.053 1.568-.887 1.206-2.1a3.165 3.165 0 00-2.488-2.281c-1.014-.046-1.56.886-1.208 2.093z"
              />
              <path
                  data-name="Path 3025"
                  d="M686.375 55.389a3.089 3.089 0 002.467 2.281c1.026.052 1.577-.891 1.233-2.1a3.1 3.1 0 00-2.463-2.273c-1.018-.053-1.571.881-1.237 2.092z"
              />
              <path
                  data-name="Path 3026"
                  d="M680.48 55.107a3.035 3.035 0 002.441 2.269c1.029.047 1.593-.9 1.262-2.1a3.043 3.043 0 00-2.44-2.266c-1.016-.044-1.582.892-1.263 2.097z"
              />
              <path
                  data-name="Path 3027"
                  d="M674.579 54.843a2.977 2.977 0 002.418 2.268c1.028.045 1.606-.9 1.288-2.1a2.988 2.988 0 00-2.415-2.259c-1.017-.048-1.597.886-1.291 2.091z"
              />
              <path
                  data-name="Path 3028"
                  d="M668.675 54.594a2.919 2.919 0 002.391 2.261c1.031.043 1.621-.9 1.317-2.106a2.929 2.929 0 00-2.391-2.253c-1.019-.043-1.609.895-1.317 2.098z"
              />
              <path
                  data-name="Path 3029"
                  d="M662.764 54.36a2.868 2.868 0 002.368 2.254c1.031.04 1.633-.906 1.345-2.109a2.875 2.875 0 00-2.368-2.246c-1.019-.04-1.622.9-1.345 2.101z"
              />
              <path
                  data-name="Path 3030"
                  d="M656.852 54.139a2.812 2.812 0 002.343 2.248c1.031.038 1.644-.909 1.37-2.111a2.827 2.827 0 00-2.341-2.241c-1.015-.036-1.636.903-1.372 2.104z"
              />
              <path
                  data-name="Path 3031"
                  d="M650.935 53.934a2.761 2.761 0 002.318 2.243c1.031.035 1.658-.914 1.4-2.116a2.768 2.768 0 00-2.316-2.233c-1.025-.035-1.652.906-1.402 2.106z"
              />
              <path
                  data-name="Path 3032"
                  d="M645.011 53.743a2.709 2.709 0 002.293 2.236c1.033.032 1.671-.917 1.427-2.117a2.719 2.719 0 00-2.291-2.228c-1.023-.033-1.661.909-1.429 2.109z"
              />
              <path
                  data-name="Path 3033"
                  d="M639.082 53.568a2.657 2.657 0 002.268 2.229c1.033.03 1.684-.922 1.454-2.121a2.668 2.668 0 00-2.266-2.223c-1.02-.027-1.67.915-1.456 2.115z"
              />
              <path
                  data-name="Path 3034"
                  d="M633.156 53.406a2.6 2.6 0 002.241 2.223c1.034.028 1.7-.924 1.482-2.122a2.615 2.615 0 00-2.241-2.216c-1.022-.029-1.688.917-1.482 2.115z"
              />
              <path
                  data-name="Path 3035"
                  d="M627.223 53.259a2.561 2.561 0 002.216 2.217c1.036.025 1.711-.929 1.511-2.127a2.569 2.569 0 00-2.214-2.209c-1.025-.025-1.702.921-1.513 2.119z"
              />
              <path
                  data-name="Path 3036"
                  d="M621.287 53.126a2.511 2.511 0 002.191 2.213 1.666 1.666 0 001.537-2.131 2.52 2.52 0 00-2.189-2.2 1.668 1.668 0 00-1.539 2.118z"
              />
              <path
                  data-name="Path 3037"
                  d="M615.347 53.009a2.462 2.462 0 002.164 2.206 1.692 1.692 0 001.566-2.134 2.475 2.475 0 00-2.164-2.2 1.7 1.7 0 00-1.566 2.128z"
              />
              <path
                  data-name="Path 3038"
                  d="M609.405 52.906a2.416 2.416 0 002.137 2.2 1.72 1.72 0 001.594-2.137 2.425 2.425 0 00-2.137-2.192 1.725 1.725 0 00-1.594 2.129z"
              />
              <path
                  data-name="Path 3039"
                  d="M603.46 52.819a2.37 2.37 0 002.112 2.194 1.748 1.748 0 001.622-2.141 2.384 2.384 0 00-2.112-2.187 1.754 1.754 0 00-1.622 2.134z"
              />
              <path
                  data-name="Path 3040"
                  d="M597.513 52.745a2.33 2.33 0 002.086 2.191 1.781 1.781 0 001.651-2.146 2.337 2.337 0 00-2.086-2.182 1.784 1.784 0 00-1.651 2.137z"
              />
              <path
                  data-name="Path 3041"
                  d="M591.564 52.687a2.285 2.285 0 002.059 2.186 1.811 1.811 0 001.678-2.151 2.292 2.292 0 00-2.059-2.176 1.815 1.815 0 00-1.678 2.141z"
              />
              <path
                  data-name="Path 3042"
                  d="M585.611 52.644a2.245 2.245 0 002.032 2.181 1.841 1.841 0 001.706-2.154 2.252 2.252 0 00-2.034-2.172 1.847 1.847 0 00-1.704 2.145z"
              />
              <path
                  data-name="Path 3043"
                  d="M579.657 52.615a2.2 2.2 0 002.005 2.176 1.875 1.875 0 001.735-2.159 2.212 2.212 0 00-2.007-2.166 1.879 1.879 0 00-1.733 2.149z"
              />
              <path
                  data-name="Path 3044"
                  d="M573.701 52.602a2.161 2.161 0 001.979 2.171 1.908 1.908 0 001.761-2.164 2.17 2.17 0 00-1.979-2.161 1.913 1.913 0 00-1.761 2.154z"
              />
              <path
                  data-name="Path 3045"
                  d="M567.744 52.604a2.118 2.118 0 001.952 2.164 1.94 1.94 0 001.79-2.166 2.132 2.132 0 00-1.953-2.157 1.947 1.947 0 00-1.789 2.159z"
              />
              <path
                  data-name="Path 3046"
                  d="M561.785 52.62a2.083 2.083 0 001.925 2.159 1.975 1.975 0 001.816-2.171 2.09 2.09 0 00-1.925-2.152 1.982 1.982 0 00-1.816 2.164z"
              />
              <path
                  data-name="Path 3047"
                  d="M555.824 52.652a2.045 2.045 0 001.9 2.154 2.011 2.011 0 001.845-2.176 2.053 2.053 0 00-1.9-2.147 2.019 2.019 0 00-1.845 2.169z"
              />
              <path
                  data-name="Path 3048"
                  d="M549.863 52.697a2.009 2.009 0 001.872 2.151 2.048 2.048 0 001.872-2.181 2.015 2.015 0 00-1.872-2.142 2.056 2.056 0 00-1.872 2.172z"
              />
              <path
                  data-name="Path 3049"
                  d="M543.901 52.759a1.973 1.973 0 001.845 2.146 2.087 2.087 0 001.9-2.186 1.981 1.981 0 00-1.845-2.139 2.1 2.1 0 00-1.9 2.179z"
              />
              <path
                  data-name="Path 3050"
                  d="M472.357 54.655c-.2 1.2.48 2.144 1.519 2.1a2.619 2.619 0 002.223-2.254c.189-1.2-.49-2.136-1.519-2.094a2.628 2.628 0 00-2.223 2.248z"
              />
              <path
                  data-name="Path 3051"
                  d="M466.401 54.911c-.216 1.2.453 2.142 1.491 2.1a2.666 2.666 0 002.249-2.259c.2-1.2-.463-2.134-1.491-2.091a2.68 2.68 0 00-2.249 2.25z"
              />
              <path
                  data-name="Path 3052"
                  d="M412.904 57.869c-.344 1.213.214 2.144 1.248 2.075a3.158 3.158 0 002.477-2.319c.334-1.213-.226-2.136-1.25-2.069a3.177 3.177 0 00-2.475 2.313z"
              />
              <path
                  data-name="Path 3053"
                  d="M406.975 58.272c-.359 1.213.189 2.144 1.222 2.072a3.219 3.219 0 002.5-2.326c.348-1.213-.2-2.137-1.223-2.067a3.236 3.236 0 00-2.499 2.321z"
              />
              <path
                  data-name="Path 3054"
                  d="M401.05 58.688c-.373 1.215.164 2.144 1.2 2.07a3.277 3.277 0 002.525-2.333c.361-1.215-.174-2.137-1.2-2.065a3.3 3.3 0 00-2.525 2.328z"
              />
              <path
                  data-name="Path 3055"
                  d="M395.129 59.119c-.386 1.218.137 2.146 1.17 2.069a3.34 3.34 0 002.548-2.341c.374-1.215-.149-2.137-1.17-2.062a3.358 3.358 0 00-2.548 2.334z"
              />
              <path
                  data-name="Path 3056"
                  d="M389.211 59.566c-.4 1.218.114 2.146 1.143 2.067a3.4 3.4 0 002.573-2.35c.389-1.217-.124-2.137-1.145-2.06a3.423 3.423 0 00-2.571 2.343z"
              />
              <path
                  data-name="Path 3057"
                  d="M383.299 60.024c-.413 1.22.087 2.147 1.118 2.065a3.466 3.466 0 002.6-2.356c.4-1.218-.1-2.139-1.118-2.059a3.479 3.479 0 00-2.6 2.35z"
              />
              <path
                  data-name="Path 3058"
                  d="M347.927 63.084c-.491 1.23-.06 2.154.966 2.059a3.862 3.862 0 002.729-2.4c.483-1.228.05-2.146-.966-2.05a3.875 3.875 0 00-2.729 2.391z"
              />
              <path
                  data-name="Path 3059"
                  d="M342.052 63.645c-.5 1.233-.084 2.156.939 2.057a3.923 3.923 0 002.751-2.41c.5-1.23.075-2.147-.939-2.05a3.942 3.942 0 00-2.751 2.403z"
              />
              <path
                  data-name="Path 3060"
                  d="M336.183 64.218c-.518 1.235-.109 2.157.914 2.055a3.985 3.985 0 002.771-2.416c.508-1.233.1-2.149-.914-2.049a4 4 0 00-2.771 2.41z"
              />
              <path
                  data-name="Path 3061"
                  d="M330.319 64.806c-.53 1.237-.132 2.157.889 2.055A4.064 4.064 0 00334 64.435c.521-1.233.122-2.149-.891-2.049a4.078 4.078 0 00-2.79 2.42z"
              />
              <path
                  data-name="Path 3062"
                  d="M324.463 65.408c-.543 1.238-.155 2.159.866 2.054a4.128 4.128 0 002.812-2.433c.533-1.237.144-2.152-.867-2.047a4.139 4.139 0 00-2.811 2.426z"
              />
              <path
                  data-name="Path 3063"
                  d="M318.612 66.02c-.555 1.242-.179 2.161.841 2.054a4.2 4.2 0 002.832-2.441c.545-1.238.169-2.154-.842-2.047a4.216 4.216 0 00-2.831 2.434z"
              />
              <path
                  data-name="Path 3064"
                  d="M306.931 67.296c-.578 1.243-.224 2.164.794 2.05a4.328 4.328 0 002.871-2.456c.57-1.242.214-2.157-.794-2.045a4.35 4.35 0 00-2.871 2.451z"
              />
              <path
                  data-name="Path 3065"
                  d="M301.103 67.951c-.59 1.247-.246 2.166.769 2.052a4.405 4.405 0 002.891-2.465c.582-1.245.236-2.159-.77-2.045a4.422 4.422 0 00-2.89 2.458z"
              />
              <path
                  data-name="Path 3066"
                  d="M289.467 69.304c-.613 1.252-.289 2.171.722 2.05a4.545 4.545 0 002.928-2.48c.6-1.25.281-2.164-.724-2.045a4.562 4.562 0 00-2.926 2.475z"
              />
              <path
                  data-name="Path 3067"
                  d="M283.66 70.003c-.623 1.252-.311 2.171.7 2.049a4.623 4.623 0 002.946-2.488c.617-1.252.3-2.166-.7-2.045a4.642 4.642 0 00-2.946 2.484z"
              />
              <path
                  data-name="Path 3068"
                  d="M272.076 71.436c-.645 1.255-.353 2.176.655 2.049a4.766 4.766 0 002.981-2.5c.637-1.257.343-2.171-.657-2.045a4.787 4.787 0 00-2.979 2.496z"
              />
              <path
                  data-name="Path 3069"
                  d="M266.292 72.172c-.657 1.26-.374 2.179.632 2.05a4.837 4.837 0 003-2.513c.648-1.258.364-2.172-.633-2.045a4.856 4.856 0 00-2.999 2.508z"
              />
              <path
                  data-name="Path 3070"
                  d="M260.519 72.923c-.667 1.262-.394 2.181.612 2.049a4.9 4.9 0 003.013-2.52c.66-1.26.386-2.176-.612-2.045a4.931 4.931 0 00-3.013 2.516z"
              />
              <path
                  data-name="Path 3071"
                  d="M254.756 73.686c-.677 1.263-.413 2.182.59 2.049a4.975 4.975 0 003.03-2.53c.67-1.262.4-2.176-.59-2.044a5 5 0 00-3.03 2.525z"
              />
              <path
                  data-name="Path 3072"
                  d="M249.002 74.462c-.687 1.267-.435 2.184.568 2.049a5.042 5.042 0 003.045-2.537c.68-1.265.424-2.179-.568-2.045a5.071 5.071 0 00-3.045 2.533z"
              />
              <path
                  data-name="Path 3073"
                  d="M237.522 76.052c-.707 1.27-.471 2.189.526 2.049a5.188 5.188 0 003.075-2.553c.7-1.27.465-2.184-.526-2.045a5.215 5.215 0 00-3.075 2.549z"
              />
              <path
                  data-name="Path 3074"
                  d="M231.796 76.865c-.717 1.273-.491 2.191.506 2.049a5.253 5.253 0 003.088-2.562c.71-1.272.483-2.184-.506-2.044a5.273 5.273 0 00-3.088 2.557z"
              />
              <path
                  data-name="Path 3075"
                  d="M220.377 78.532c-.735 1.278-.526 2.2.466 2.049a5.4 5.4 0 003.117-2.577c.729-1.277.518-2.191-.468-2.045a5.425 5.425 0 00-3.115 2.573z"
              />
              <path
                  data-name="Path 3076"
                  d="M214.686 79.384c-.745 1.28-.546 2.2.444 2.049a5.466 5.466 0 003.13-2.585c.737-1.278.536-2.192-.446-2.045a5.484 5.484 0 00-3.128 2.581z"
              />
              <path
                  data-name="Path 3077"
                  d="M197.665 82.013c-.77 1.287-.6 2.206.388 2.05a5.68 5.68 0 003.167-2.61c.764-1.283.588-2.2-.391-2.045a5.685 5.685 0 00-3.164 2.605z"
              />
              <path
                  data-name="Path 3078"
                  d="M982.429 81.76a6.521 6.521 0 003.269 2.677c.931.164.974-.769.1-2.085a6.515 6.515 0 00-3.269-2.675c-.927-.164-.971.768-.1 2.083z"
              />
              <path
                  data-name="Path 3079"
                  d="M977.061 80.824a6.462 6.462 0 003.264 2.67c.934.162.983-.77.11-2.084a6.457 6.457 0 00-3.264-2.669c-.928-.159-.98.773-.11 2.083z"
              />
              <path
                  data-name="Path 3080"
                  d="M971.678 79.901a6.4 6.4 0 003.259 2.662c.936.16.989-.772.124-2.082a6.4 6.4 0 00-3.259-2.66c-.931-.16-.986.771-.124 2.08z"
              />
              <path
                  data-name="Path 3081"
                  d="M966.284 78.987a6.357 6.357 0 003.254 2.654c.936.159 1-.772.135-2.08a6.35 6.35 0 00-3.252-2.652c-.934-.16-.996.773-.137 2.078z"
              />
              <path
                  data-name="Path 3082"
                  d="M960.878 78.085a6.3 6.3 0 003.247 2.645c.941.157 1.006-.775.15-2.08a6.311 6.311 0 00-3.247-2.644c-.935-.156-1.004.775-.15 2.079z"
              />
              <path
                  data-name="Path 3083"
                  d="M955.46 77.192a6.252 6.252 0 003.242 2.637c.943.155 1.013-.775.162-2.079a6.235 6.235 0 00-3.239-2.635c-.936-.154-1.012.775-.165 2.077z"
              />
              <path
                  data-name="Path 3084"
                  d="M950.036 76.31a6.179 6.179 0 003.234 2.629c.944.154 1.024-.777.177-2.075a6.2 6.2 0 00-3.232-2.629c-.944-.151-1.024.776-.179 2.075z"
              />
              <path
                  data-name="Path 3085"
                  d="M944.592 75.444a6.116 6.116 0 003.227 2.62c.948.152 1.031-.777.192-2.075a6.134 6.134 0 00-3.223-2.619c-.943-.153-1.033.774-.196 2.074z"
              />
              <path
                  data-name="Path 3086"
                  d="M939.141 74.58a6.067 6.067 0 003.219 2.612c.947.15 1.039-.779.207-2.074a6.069 6.069 0 00-3.215-2.61c-.944-.148-1.038.779-.211 2.072z"
              />
              <path
                  data-name="Path 3087"
                  d="M933.68 73.731a5.987 5.987 0 003.208 2.6c.949.147 1.049-.78.224-2.072a6.009 6.009 0 00-3.208-2.6c-.946-.143-1.044.782-.224 2.072z"
              />
              <path
                  data-name="Path 3088"
                  d="M928.207 72.894a5.926 5.926 0 003.2 2.6c.951.147 1.058-.782.239-2.07a5.939 5.939 0 00-3.2-2.594c-.947-.149-1.054.775-.239 2.064z"
              />
              <path
                  data-name="Path 3089"
                  d="M922.723 72.067a5.864 5.864 0 003.19 2.589c.954.144 1.068-.784.256-2.07a5.878 5.878 0 00-3.188-2.585c-.951-.142-1.065.782-.258 2.066z"
              />
              <path
                  data-name="Path 3090"
                  d="M917.228 71.253a5.8 5.8 0 003.18 2.578c.956.142 1.078-.785.272-2.069a5.817 5.817 0 00-3.178-2.575c-.952-.14-1.074.78-.274 2.066z"
              />
              <path
                  data-name="Path 3091"
                  d="M911.724 70.445a5.733 5.733 0 003.17 2.572c.958.14 1.086-.787.287-2.067a5.746 5.746 0 00-3.168-2.568c-.951-.137-1.083.789-.289 2.063z"
              />
              <path
                  data-name="Path 3092"
                  d="M906.208 69.659a5.678 5.678 0 003.158 2.562c.961.137 1.1-.787.307-2.067a5.687 5.687 0 00-3.158-2.558c-.954-.136-1.093.786-.307 2.063z"
              />
              <path
                  data-name="Path 3093"
                  d="M900.681 68.878a5.6 5.6 0 003.148 2.553c.961.137 1.106-.789.324-2.065a5.616 5.616 0 00-3.147-2.55c-.955-.134-1.1.789-.325 2.062z"
              />
              <path
                  data-name="Path 3094"
                  d="M895.147 68.107a5.545 5.545 0 003.135 2.545c.964.134 1.116-.792.343-2.065a5.562 5.562 0 00-3.133-2.542c-.959-.126-1.113.794-.345 2.062z"
              />
              <path
                  data-name="Path 3095"
                  d="M889.601 67.355a5.471 5.471 0 003.123 2.537c.964.132 1.126-.794.361-2.064a5.472 5.472 0 00-3.122-2.532c-.959-.13-1.121.79-.362 2.059z"
              />
              <path
                  data-name="Path 3096"
                  d="M884.044 66.606a5.387 5.387 0 003.112 2.528c.968.13 1.135-.794.379-2.062a5.409 5.409 0 00-3.108-2.523c-.961-.124-1.131.795-.383 2.057z"
              />
              <path
                  data-name="Path 3097"
                  d="M878.479 65.877a5.329 5.329 0 003.1 2.52c.969.127 1.148-.8.4-2.06a5.338 5.338 0 00-3.095-2.517c-.966-.126-1.145.792-.405 2.057z"
              />
              <path
                  data-name="Path 3098"
                  d="M872.904 65.157a5.268 5.268 0 003.085 2.512c.971.125 1.158-.8.418-2.06a5.273 5.273 0 00-3.081-2.507c-.964-.124-1.153.795-.422 2.055z"
              />
              <path
                  data-name="Path 3099"
                  d="M867.32 64.448a5.184 5.184 0 003.07 2.5c.971.122 1.166-.8.438-2.059a5.2 5.2 0 00-3.068-2.5c-.967-.117-1.162.802-.44 2.059z"
              />
              <path
                  data-name="Path 3100"
                  d="M861.725 63.753a5.11 5.11 0 003.056 2.493c.972.122 1.178-.8.458-2.057a5.131 5.131 0 00-3.053-2.49c-.967-.118-1.173.797-.461 2.054z"
              />
              <path
                  data-name="Path 3101"
                  d="M856.122 63.07a5.052 5.052 0 003.041 2.485c.976.119 1.19-.8.479-2.057a5.068 5.068 0 00-3.038-2.482c-.968-.117-1.186.8-.482 2.054z"
              />
              <path
                  data-name="Path 3102"
                  d="M850.511 62.398a4.98 4.98 0 003.025 2.477c.978.117 1.2-.805.5-2.057a5 5 0 00-3.023-2.472c-.971-.115-1.196.802-.502 2.052z"
              />
              <path
                  data-name="Path 3103"
                  d="M844.89 61.74a4.914 4.914 0 003.01 2.468c.979.115 1.213-.807.523-2.057a4.923 4.923 0 00-3.008-2.463c-.973-.112-1.207.804-.525 2.052z"
              />
              <path
                  data-name="Path 3104"
                  d="M839.261 61.095a4.834 4.834 0 002.993 2.46c.981.114 1.223-.809.543-2.055a4.851 4.851 0 00-2.991-2.455c-.972-.113-1.216.803-.545 2.05z"
              />
              <path
                  data-name="Path 3105"
                  d="M833.623 60.459a4.763 4.763 0 002.976 2.451c.983.11 1.235-.812.565-2.055a4.78 4.78 0 00-2.974-2.446c-.974-.109-1.23.807-.567 2.05z"
              />
              <path
                  data-name="Path 3106"
                  d="M827.975 59.838a4.7 4.7 0 002.961 2.443c.983.109 1.245-.814.587-2.055a4.7 4.7 0 00-2.958-2.436c-.976-.108-1.238.808-.59 2.048z"
              />
              <path
                  data-name="Path 3107"
                  d="M822.322 59.229a4.626 4.626 0 002.943 2.435c.984.105 1.257-.815.61-2.054a4.639 4.639 0 00-2.943-2.43c-.976-.105-1.25.812-.61 2.049z"
              />
              <path
                  data-name="Path 3108"
                  d="M816.657 58.633a4.552 4.552 0 002.926 2.426c.988.105 1.27-.817.633-2.054a4.575 4.575 0 00-2.924-2.42c-.979-.101-1.262.813-.635 2.048z"
              />
              <path
                  data-name="Path 3109"
                  d="M810.987 58.052a4.489 4.489 0 002.908 2.418c.988.1 1.28-.82.655-2.054a4.5 4.5 0 00-2.906-2.413c-.981-.099-1.274.815-.657 2.049z"
              />
              <path
                  data-name="Path 3110"
                  d="M805.307 57.482a4.421 4.421 0 002.889 2.41c.991.1 1.293-.822.678-2.054a4.43 4.43 0 00-2.888-2.4c-.98-.102-1.284.814-.679 2.044z"
              />
              <path
                  data-name="Path 3111"
                  d="M799.62 56.923a4.35 4.35 0 002.871 2.4c.991.1 1.305-.824.7-2.054a4.362 4.362 0 00-2.869-2.4c-.981-.088-1.295.828-.702 2.054z"
              />
              <path
                  data-name="Path 3112"
                  d="M793.926 56.381a4.27 4.27 0 002.851 2.393c.993.1 1.317-.825.725-2.054a4.287 4.287 0 00-2.849-2.386c-.989-.094-1.31.822-.727 2.047z"
              />
              <path
                  data-name="Path 3113"
                  d="M788.225 55.85a4.207 4.207 0 002.832 2.385c.991.092 1.329-.829.749-2.054a4.221 4.221 0 00-2.831-2.38c-.986-.09-1.322.826-.75 2.049z"
              />
              <path
                  data-name="Path 3114"
                  d="M782.515 55.332a4.139 4.139 0 002.812 2.378c1 .089 1.342-.832.774-2.055a4.155 4.155 0 00-2.811-2.37c-.985-.088-1.335.826-.775 2.047z"
              />
              <path
                  data-name="Path 3115"
                  d="M776.8 54.827a4.069 4.069 0 002.794 2.37c1 .087 1.352-.834.8-2.054a4.081 4.081 0 00-2.794-2.362c-.989-.088-1.348.828-.8 2.046z"
              />
              <path
                  data-name="Path 3116"
                  d="M771.075 54.336a4.008 4.008 0 002.774 2.363c1 .085 1.365-.837.822-2.055a4.016 4.016 0 00-2.772-2.355c-.988-.085-1.357.831-.824 2.047z"
              />
              <path
                  data-name="Path 3117"
                  d="M765.345 53.861a3.94 3.94 0 002.752 2.353c1 .084 1.379-.839.847-2.055a3.95 3.95 0 00-2.751-2.346c-.984-.083-1.37.833-.848 2.048z"
              />
              <path
                  data-name="Path 3118"
                  d="M759.61 53.396a3.867 3.867 0 002.732 2.346c1 .08 1.39-.842.872-2.057a3.885 3.885 0 00-2.731-2.338c-.992-.08-1.383.836-.873 2.049z"
              />
              <path
                  data-name="Path 3119"
                  d="M753.864 52.945a3.8 3.8 0 002.71 2.338c1 .079 1.4-.844.9-2.055a3.814 3.814 0 00-2.709-2.331c-.994-.078-1.397.838-.901 2.048z"
              />
              <path
                  data-name="Path 3120"
                  d="M748.114 52.508a3.739 3.739 0 002.69 2.329c1 .077 1.414-.847.922-2.057a3.754 3.754 0 00-2.689-2.323c-.99-.074-1.406.841-.923 2.051z"
              />
              <path
                  data-name="Path 3121"
                  d="M742.357 52.084a3.674 3.674 0 002.669 2.323c1 .074 1.427-.849.947-2.059a3.684 3.684 0 00-2.665-2.314c-.994-.072-1.418.845-.951 2.05z"
              />
              <path
                  data-name="Path 3122"
                  d="M736.596 51.675a3.608 3.608 0 002.647 2.314c1 .072 1.439-.852.973-2.059a3.62 3.62 0 00-2.644-2.308c-.995-.07-1.433.846-.976 2.053z"
              />
              <path
                  data-name="Path 3123"
                  d="M730.828 51.278a3.545 3.545 0 002.625 2.308c1 .069 1.452-.856 1-2.06a3.56 3.56 0 00-2.624-2.3c-.995-.072-1.444.849-1.001 2.052z"
              />
              <path
                  data-name="Path 3124"
                  d="M725.058 50.901a3.477 3.477 0 002.6 2.3c1.008.067 1.464-.857 1.026-2.06a3.493 3.493 0 00-2.6-2.293c-1-.071-1.46.847-1.026 2.053z"
              />
              <path
                  data-name="Path 3125"
                  d="M719.274 50.527a3.417 3.417 0 002.578 2.293c1.008.065 1.479-.861 1.053-2.062a3.43 3.43 0 00-2.578-2.286c-.996-.063-1.469.855-1.053 2.055z"
              />
              <path
                  data-name="Path 3126"
                  d="M713.488 50.172a3.353 3.353 0 002.557 2.284c1.008.062 1.491-.864 1.078-2.064a3.366 3.366 0 00-2.555-2.278c-.998-.059-1.483.858-1.08 2.058z"
              />
              <path
                  data-name="Path 3127"
                  d="M707.699 49.831a3.292 3.292 0 002.533 2.278c1.009.06 1.5-.867 1.1-2.065a3.308 3.308 0 00-2.533-2.273c-1-.057-1.491.863-1.1 2.06z"
              />
              <path
                  data-name="Path 3128"
                  d="M701.901 49.503a3.237 3.237 0 002.51 2.271c1.011.057 1.517-.871 1.131-2.067a3.249 3.249 0 00-2.508-2.264c-1.001-.055-1.508.864-1.133 2.06z"
              />
              <path
                  data-name="Path 3129"
                  d="M696.1 49.191a3.17 3.17 0 002.487 2.263c1.011.055 1.529-.872 1.158-2.069a3.189 3.189 0 00-2.485-2.256c-1.003-.054-1.521.867-1.16 2.062z"
              />
              <path
                  data-name="Path 3130"
                  d="M690.295 48.893a3.111 3.111 0 002.461 2.258c1.013.052 1.544-.877 1.185-2.07a3.129 3.129 0 00-2.461-2.249c-1.001-.055-1.533.867-1.185 2.061z"
              />
              <path
                  data-name="Path 3131"
                  d="M684.483 48.606a3.06 3.06 0 002.44 2.251c1.013.048 1.554-.881 1.212-2.074a3.073 3.073 0 00-2.438-2.243c-1.006-.048-1.546.875-1.214 2.066z"
              />
              <path
                  data-name="Path 3132"
                  d="M678.668 48.336a3 3 0 002.415 2.243c1.014.047 1.569-.884 1.238-2.074a3.009 3.009 0 00-2.413-2.236c-1.003-.047-1.558.875-1.24 2.067z"
              />
              <path
                  data-name="Path 3133"
                  d="M672.849 48.078a2.946 2.946 0 002.39 2.238c1.014.043 1.581-.889 1.265-2.079a2.956 2.956 0 00-2.388-2.229c-1.004-.045-1.573.88-1.267 2.07z"
              />
              <path
                  data-name="Path 3134"
                  d="M667.025 47.836a2.886 2.886 0 002.364 2.229c1.016.043 1.594-.891 1.293-2.079a2.9 2.9 0 00-2.365-2.223c-1.003-.041-1.583.885-1.292 2.073z"
              />
              <path
                  data-name="Path 3135"
                  d="M661.197 47.607a2.837 2.837 0 002.341 2.224c1.016.038 1.606-.9 1.32-2.082a2.847 2.847 0 00-2.339-2.216c-1.006-.04-1.598.888-1.322 2.074z"
              />
              <path
                  data-name="Path 3136"
                  d="M655.365 47.392a2.782 2.782 0 002.316 2.219c1.016.037 1.619-.9 1.347-2.085a2.793 2.793 0 00-2.314-2.209c-1.002-.037-1.612.89-1.349 2.075z"
              />
              <path
                  data-name="Path 3137"
                  d="M649.529 47.193a2.728 2.728 0 002.289 2.211c1.018.035 1.633-.9 1.375-2.087a2.743 2.743 0 00-2.289-2.2c-1.006-.038-1.624.887-1.375 2.076z"
              />
              <path
                  data-name="Path 3138"
                  d="M643.689 47.006a2.675 2.675 0 002.266 2.206c1.018.032 1.646-.906 1.4-2.09a2.689 2.689 0 00-2.264-2.2c-1.005-.028-1.634.901-1.402 2.084z"
              />
              <path
                  data-name="Path 3139"
                  d="M637.845 46.835a2.63 2.63 0 002.241 2.2c1.019.03 1.659-.909 1.43-2.094a2.638 2.638 0 00-2.239-2.191c-1.008-.029-1.649.902-1.432 2.085z"
              />
              <path
                  data-name="Path 3140"
                  d="M632 46.678a2.577 2.577 0 002.214 2.192c1.019.027 1.671-.912 1.457-2.1a2.586 2.586 0 00-2.212-2.186c-1.01-.022-1.663.91-1.459 2.094z"
              />
              <path
                  data-name="Path 3141"
                  d="M626.15 46.536a2.53 2.53 0 002.189 2.187c1.021.025 1.686-.917 1.486-2.1a2.537 2.537 0 00-2.187-2.179c-1.01-.023-1.675.909-1.488 2.092z"
              />
              <path
                  data-name="Path 3142"
                  d="M620.297 46.406a2.482 2.482 0 002.162 2.182 1.64 1.64 0 001.514-2.1 2.491 2.491 0 00-2.162-2.174 1.645 1.645 0 00-1.514 2.092z"
              />
              <path
                  data-name="Path 3143"
                  d="M614.442 46.292a2.433 2.433 0 002.137 2.176 1.667 1.667 0 001.541-2.106 2.442 2.442 0 00-2.136-2.167 1.671 1.671 0 00-1.542 2.097z"
              />
              <path
                  data-name="Path 3144"
                  d="M608.584 46.194a2.389 2.389 0 002.112 2.171 1.694 1.694 0 001.568-2.109 2.4 2.4 0 00-2.111-2.162 1.7 1.7 0 00-1.569 2.1z"
              />
              <path
                  data-name="Path 3145"
                  d="M602.723 46.107a2.343 2.343 0 002.086 2.166 1.723 1.723 0 001.6-2.114 2.351 2.351 0 00-2.084-2.156 1.728 1.728 0 00-1.602 2.104z"
              />
              <path
                  data-name="Path 3146"
                  d="M596.861 46.036a2.3 2.3 0 002.059 2.159 1.753 1.753 0 001.624-2.116 2.311 2.311 0 00-2.059-2.152 1.758 1.758 0 00-1.624 2.109z"
              />
              <path
                  data-name="Path 3147"
                  d="M590.995 45.979a2.257 2.257 0 002.032 2.156 1.783 1.783 0 001.651-2.121 2.263 2.263 0 00-2.03-2.147 1.788 1.788 0 00-1.653 2.112z"
              />
              <path
                  data-name="Path 3148"
                  d="M585.128 45.938a2.215 2.215 0 002 2.148 1.815 1.815 0 001.679-2.126 2.221 2.221 0 00-2.005-2.141 1.82 1.82 0 00-1.674 2.119z"
              />
              <path
                  data-name="Path 3149"
                  d="M579.259 45.911a2.169 2.169 0 001.979 2.144 1.847 1.847 0 001.708-2.129 2.183 2.183 0 00-1.979-2.136 1.852 1.852 0 00-1.708 2.121z"
              />
              <path
                  data-name="Path 3150"
                  d="M573.387 45.9a2.132 2.132 0 001.953 2.139 1.881 1.881 0 001.735-2.134 2.14 2.14 0 00-1.952-2.131 1.887 1.887 0 00-1.736 2.126z"
              />
              <path
                  data-name="Path 3151"
                  d="M567.515 45.901a2.092 2.092 0 001.927 2.134 1.915 1.915 0 001.763-2.137 2.105 2.105 0 00-1.927-2.127 1.922 1.922 0 00-1.763 2.13z"
              />
              <path
                  data-name="Path 3152"
                  d="M561.641 45.918a2.054 2.054 0 001.9 2.129 1.948 1.948 0 001.791-2.142 2.06 2.06 0 00-1.9-2.121 1.956 1.956 0 00-1.791 2.134z"
              />
              <path
                  data-name="Path 3153"
                  d="M555.766 45.948a2.019 2.019 0 001.873 2.126 1.985 1.985 0 001.818-2.147 2.026 2.026 0 00-1.872-2.117 1.994 1.994 0 00-1.819 2.138z"
              />
              <path
                  data-name="Path 3154"
                  d="M491.109 47.251a1.667 1.667 0 001.574 2.084 2.442 2.442 0 002.117-2.206 1.668 1.668 0 00-1.574-2.074 2.451 2.451 0 00-2.117 2.196z"
              />
              <path
                  data-name="Path 3155"
                  d="M485.233 47.461a1.64 1.64 0 001.546 2.08 2.488 2.488 0 002.144-2.211 1.643 1.643 0 00-1.546-2.072 2.5 2.5 0 00-2.144 2.203z"
              />
              <path
                  data-name="Path 3156"
                  d="M479.357 47.678c-.185 1.185.495 2.117 1.519 2.077a2.538 2.538 0 002.171-2.217c.174-1.183-.506-2.107-1.521-2.069a2.55 2.55 0 00-2.169 2.209z"
              />
              <path
                  data-name="Path 3157"
                  d="M473.482 47.913c-.2 1.185.468 2.116 1.492 2.074a2.586 2.586 0 002.2-2.223c.189-1.185-.48-2.107-1.492-2.067a2.6 2.6 0 00-2.2 2.216z"
              />
              <path
                  data-name="Path 3158"
                  d="M467.61 48.162c-.214 1.186.441 2.116 1.466 2.072a2.636 2.636 0 002.223-2.231c.2-1.185-.453-2.106-1.465-2.062a2.651 2.651 0 00-2.224 2.221z"
              />
              <path
                  data-name="Path 3159"
                  d="M426.552 50.314c-.316 1.195.257 2.116 1.278 2.054a3.015 3.015 0 002.4-2.276c.3-1.193-.269-2.107-1.28-2.047a3.03 3.03 0 00-2.398 2.269z"
              />
              <path
                  data-name="Path 3160"
                  d="M420.695 50.68c-.329 1.195.232 2.117 1.252 2.052a3.076 3.076 0 002.426-2.284c.319-1.195-.242-2.107-1.252-2.044a3.089 3.089 0 00-2.426 2.276z"
              />
              <path
                  data-name="Path 3161"
                  d="M414.842 51.06c-.344 1.2.2 2.117 1.225 2.05a3.133 3.133 0 002.451-2.291c.333-1.2-.217-2.109-1.227-2.044a3.147 3.147 0 00-2.449 2.285z"
              />
              <path
                  data-name="Path 3162"
                  d="M408.992 51.452c-.356 1.2.179 2.119 1.2 2.049a3.19 3.19 0 002.475-2.3c.346-1.2-.19-2.109-1.2-2.04a3.2 3.2 0 00-2.475 2.291z"
              />
              <path
                  data-name="Path 3163"
                  d="M403.145 51.86c-.371 1.2.154 2.119 1.173 2.047a3.25 3.25 0 002.5-2.3c.361-1.2-.165-2.111-1.173-2.04a3.269 3.269 0 00-2.5 2.293z"
              />
              <path
                  data-name="Path 3164"
                  d="M397.303 52.283c-.386 1.2.129 2.121 1.146 2.045a3.311 3.311 0 002.523-2.313c.374-1.2-.139-2.111-1.148-2.039a3.327 3.327 0 00-2.521 2.307z"
              />
              <path
                  data-name="Path 3165"
                  d="M391.463 52.719c-.4 1.2.1 2.121 1.121 2.044a3.374 3.374 0 002.547-2.319c.388-1.2-.114-2.112-1.121-2.037a3.387 3.387 0 00-2.547 2.312z"
              />
              <path
                  data-name="Path 3166"
                  d="M385.627 53.17c-.413 1.2.079 2.121 1.095 2.042a3.435 3.435 0 002.57-2.328c.4-1.2-.089-2.112-1.095-2.035a3.454 3.454 0 00-2.57 2.321z"
              />
              <path
                  data-name="Path 3167"
                  d="M344.9 56.716c-.5 1.22-.094 2.132.917 2.037a3.9 3.9 0 002.727-2.383c.495-1.218.082-2.124-.919-2.03a3.911 3.911 0 00-2.725 2.376z"
              />
              <path
                  data-name="Path 3168"
                  d="M339.101 57.279c-.516 1.22-.117 2.134.894 2.035a3.962 3.962 0 002.747-2.391c.508-1.22.107-2.126-.894-2.029a3.979 3.979 0 00-2.747 2.385z"
              />
              <path
                  data-name="Path 3169"
                  d="M333.31 57.858c-.528 1.223-.14 2.137.869 2.035a4.033 4.033 0 002.769-2.4c.52-1.222.13-2.129-.871-2.029a4.051 4.051 0 00-2.767 2.394z"
              />
              <path
                  data-name="Path 3170"
                  d="M327.52 58.444c-.541 1.225-.164 2.139.844 2.035a4.1 4.1 0 002.791-2.406c.531-1.223.152-2.131-.847-2.029a4.116 4.116 0 00-2.788 2.4z"
              />
              <path
                  data-name="Path 3171"
                  d="M321.739 59.047c-.553 1.228-.187 2.141.82 2.035a4.176 4.176 0 002.811-2.415c.545-1.227.175-2.132-.822-2.029a4.184 4.184 0 00-2.809 2.409z"
              />
              <path
                  data-name="Path 3172"
                  d="M310.193 60.295c-.577 1.232-.231 2.144.774 2.034a4.311 4.311 0 002.849-2.431c.57-1.23.222-2.137-.774-2.027a4.33 4.33 0 00-2.849 2.424z"
              />
              <path
                  data-name="Path 3173"
                  d="M304.432 60.939c-.59 1.235-.254 2.147.75 2.034a4.376 4.376 0 002.868-2.44c.58-1.232.244-2.139-.75-2.027a4.4 4.4 0 00-2.868 2.433z"
              />
              <path
                  data-name="Path 3174"
                  d="M298.676 61.597c-.6 1.237-.276 2.149.727 2.034a4.455 4.455 0 002.888-2.448c.592-1.235.266-2.142-.729-2.029a4.471 4.471 0 00-2.886 2.443z"
              />
              <path
                  data-name="Path 3175"
                  d="M287.183 62.953c-.623 1.242-.319 2.152.682 2.034a4.6 4.6 0 002.924-2.465c.615-1.24.309-2.147-.683-2.029a4.614 4.614 0 00-2.923 2.46z"
              />
              <path
                  data-name="Path 3176"
                  d="M269.997 65.083c-.655 1.25-.379 2.161.617 2.035a4.813 4.813 0 002.976-2.492c.648-1.247.371-2.152-.617-2.029a4.832 4.832 0 00-2.976 2.486z"
              />
              <path
                  data-name="Path 3177"
                  d="M258.581 66.57c-.677 1.253-.421 2.166.572 2.034a4.951 4.951 0 003.011-2.507c.668-1.252.411-2.159-.575-2.03a4.977 4.977 0 00-3.008 2.503z"
              />
              <path
                  data-name="Path 3178"
                  d="M235.844 69.697c-.717 1.263-.5 2.176.49 2.035a5.234 5.234 0 003.071-2.54c.709-1.262.488-2.171-.491-2.032a5.239 5.239 0 00-3.07 2.537z"
              />
              <path
                  data-name="Path 3179"
                  d="M230.18 70.509c-.725 1.267-.515 2.179.471 2.037a5.307 5.307 0 003.085-2.548c.719-1.265.506-2.174-.471-2.034a5.321 5.321 0 00-3.085 2.545z"
              />
              <path
                  data-name="Path 3180"
                  d="M224.527 71.334c-.734 1.27-.533 2.182.451 2.039a5.374 5.374 0 003.1-2.558c.727-1.267.526-2.177-.451-2.034a5.4 5.4 0 00-3.1 2.553z"
              />
              <path
                  data-name="Path 3181"
                  d="M218.884 72.173c-.744 1.272-.551 2.184.431 2.039a5.444 5.444 0 003.112-2.567c.737-1.27.543-2.179-.433-2.035a5.466 5.466 0 00-3.11 2.563z"
              />
              <path
                  data-name="Path 3182"
                  d="M950.674 69.851a6.209 6.209 0 003.229 2.624c.936.152 1-.777.154-2.074a6.205 6.205 0 00-3.225-2.622c-.934-.151-1.006.775-.158 2.072z"
              />
              <path
                  data-name="Path 3183"
                  d="M945.279 68.985a6.145 6.145 0 003.22 2.615c.938.15 1.013-.777.169-2.072a6.154 6.154 0 00-3.218-2.614c-.934-.148-1.011.779-.171 2.071z"
              />
              <path
                  data-name="Path 3184"
                  d="M939.873 68.131a6.09 6.09 0 003.214 2.605c.941.149 1.021-.779.182-2.07a6.087 6.087 0 00-3.21-2.6c-.934-.149-1.018.775-.186 2.065z"
              />
              <path
                  data-name="Path 3185"
                  d="M934.461 67.287a6.03 6.03 0 003.2 2.6c.941.147 1.029-.78.2-2.069a6.027 6.027 0 00-3.2-2.594c-.937-.15-1.029.777-.2 2.063z"
              />
              <path
                  data-name="Path 3186"
                  d="M929.036 66.456a5.963 5.963 0 003.2 2.589c.944.145 1.038-.782.212-2.067a5.977 5.977 0 00-3.193-2.585c-.941-.143-1.041.779-.219 2.063z"
              />
              <path
                  data-name="Path 3187"
                  d="M923.602 65.635a5.9 5.9 0 003.185 2.578c.948.144 1.048-.782.229-2.064a5.9 5.9 0 00-3.183-2.577c-.941-.14-1.043.783-.231 2.063z"
              />
              <path
                  data-name="Path 3188"
                  d="M918.159 64.824a5.846 5.846 0 003.175 2.57c.949.142 1.054-.784.246-2.062a5.847 5.847 0 00-3.173-2.567c-.943-.14-1.053.783-.248 2.059z"
              />
              <path
                  data-name="Path 3189"
                  d="M912.706 64.027a5.778 5.778 0 003.165 2.56c.949.14 1.066-.784.261-2.06a5.781 5.781 0 00-3.164-2.559c-.94-.137-1.059.784-.262 2.059z"
              />
              <path
                  data-name="Path 3190"
                  d="M907.246 63.24a5.7 5.7 0 003.155 2.552c.951.137 1.071-.785.277-2.059a5.708 5.708 0 00-3.152-2.548c-.947-.132-1.071.783-.28 2.055z"
              />
              <path
                  data-name="Path 3191"
                  d="M901.776 62.465a5.639 5.639 0 003.142 2.543c.952.135 1.084-.787.294-2.057a5.644 5.644 0 00-3.14-2.54c-.946-.133-1.08.786-.296 2.054z"
              />
              <path
                  data-name="Path 3192"
                  d="M896.297 61.701a5.565 5.565 0 003.13 2.533c.952.134 1.093-.787.313-2.055a5.583 5.583 0 00-3.128-2.53c-.948-.13-1.09.788-.315 2.052z"
              />
              <path
                  data-name="Path 3193"
                  d="M890.808 60.949a5.515 5.515 0 003.118 2.525c.956.132 1.1-.789.331-2.054a5.519 5.519 0 00-3.117-2.522c-.95-.128-1.098.788-.332 2.051z"
              />
              <path
                  data-name="Path 3194"
                  d="M885.311 60.208a5.438 5.438 0 003.1 2.517c.956.129 1.111-.792.349-2.054a5.456 5.456 0 00-3.1-2.512c-.951-.126-1.108.789-.349 2.049z"
              />
              <path
                  data-name="Path 3195"
                  d="M879.804 59.481a5.365 5.365 0 003.092 2.507c.959.129 1.123-.792.368-2.05a5.382 5.382 0 00-3.09-2.5c-.953-.128-1.118.786-.37 2.043z"
              />
              <path
                  data-name="Path 3196"
                  d="M874.291 58.761a5.306 5.306 0 003.078 2.5c.961.125 1.133-.794.386-2.049a5.306 5.306 0 00-3.075-2.493c-.953-.122-1.128.791-.389 2.042z"
              />
              <path
                  data-name="Path 3197"
                  d="M868.769 58.06a5.223 5.223 0 003.063 2.488c.962.124 1.143-.8.406-2.047a5.246 5.246 0 00-3.063-2.485c-.954-.12-1.138.794-.406 2.044z"
              />
              <path
                  data-name="Path 3198"
                  d="M863.239 57.367a5.156 5.156 0 003.05 2.48c.961.122 1.153-.8.424-2.045a5.163 5.163 0 00-3.048-2.475c-.957-.119-1.149.793-.426 2.04z"
              />
              <path
                  data-name="Path 3199"
                  d="M857.698 56.686a5.089 5.089 0 003.035 2.471c.966.119 1.163-.8.445-2.045a5.1 5.1 0 00-3.033-2.467c-.958-.116-1.159.796-.447 2.041z"
              />
              <path
                  data-name="Path 3200"
                  d="M852.15 56.018a5.038 5.038 0 003.02 2.463c.966.115 1.175-.8.466-2.044a5.032 5.032 0 00-3.018-2.458c-.957-.118-1.168.797-.468 2.039z"
              />
              <path
                  data-name="Path 3201"
                  d="M846.596 55.363a4.952 4.952 0 003 2.453c.966.114 1.183-.8.486-2.044a4.959 4.959 0 00-3-2.446c-.957-.112-1.178.797-.486 2.037z"
              />
              <path
                  data-name="Path 3202"
                  d="M841.032 54.72a4.881 4.881 0 002.988 2.443c.969.112 1.195-.8.508-2.04a4.9 4.9 0 00-2.986-2.44c-.962-.111-1.19.8-.51 2.037z"
              />
              <path
                  data-name="Path 3203"
                  d="M835.463 54.088a4.8 4.8 0 002.971 2.435c.969.11 1.207-.805.528-2.04a4.824 4.824 0 00-2.97-2.43c-.963-.109-1.199.802-.529 2.035z"
              />
              <path
                  data-name="Path 3204"
                  d="M829.885 53.468a4.724 4.724 0 002.954 2.426c.971.109 1.216-.805.55-2.039a4.751 4.751 0 00-2.953-2.421c-.964-.105-1.212.806-.551 2.034z"
              />
              <path
                  data-name="Path 3205"
                  d="M824.298 52.861a4.664 4.664 0 002.938 2.418c.974.105 1.228-.809.571-2.039a4.683 4.683 0 00-2.936-2.411c-.964-.103-1.223.806-.573 2.032z"
              />
              <path
                  data-name="Path 3206"
                  d="M818.705 52.268a4.6 4.6 0 002.921 2.408c.974.1 1.238-.809.593-2.037a4.615 4.615 0 00-2.919-2.4c-.966-.103-1.232.804-.595 2.029z"
              />
              <path
                  data-name="Path 3207"
                  d="M813.106 51.686a4.514 4.514 0 002.9 2.4c.974.1 1.252-.812.615-2.035a4.531 4.531 0 00-2.9-2.395c-.964-.099-1.243.807-.615 2.03z"
              />
              <path
                  data-name="Path 3208"
                  d="M807.5 51.118a4.459 4.459 0 002.884 2.391c.978.1 1.263-.814.638-2.035a4.467 4.467 0 00-2.883-2.386c-.97-.097-1.257.81-.639 2.03z"
              />
              <path
                  data-name="Path 3209"
                  d="M801.885 50.561a4.387 4.387 0 002.866 2.383c.978.1 1.273-.815.66-2.035a4.4 4.4 0 00-2.863-2.376c-.971-.095-1.267.81-.663 2.028z"
              />
              <path
                  data-name="Path 3210"
                  d="M796.263 50.018a4.312 4.312 0 002.848 2.375c.979.1 1.285-.819.683-2.035a4.33 4.33 0 00-2.844-2.368c-.972-.092-1.28.813-.687 2.028z"
              />
              <path
                  data-name="Path 3211"
                  d="M790.634 49.489a4.244 4.244 0 002.827 2.365c.981.092 1.3-.819.709-2.034a4.259 4.259 0 00-2.826-2.36c-.972-.09-1.289.816-.71 2.029z"
              />
              <path
                  data-name="Path 3212"
                  d="M785 48.97a4.179 4.179 0 002.809 2.358c.983.09 1.308-.822.732-2.035a4.187 4.187 0 00-2.807-2.35c-.973-.088-1.302.818-.734 2.027z"
              />
              <path
                  data-name="Path 3213"
                  d="M779.36 48.466a4.106 4.106 0 002.789 2.35c.983.089 1.32-.826.754-2.034a4.118 4.118 0 00-2.786-2.343c-.975-.085-1.314.819-.757 2.027z"
              />
              <path
                  data-name="Path 3214"
                  d="M773.712 47.975a4.048 4.048 0 002.769 2.341c.984.085 1.333-.827.779-2.034a4.046 4.046 0 00-2.766-2.334c-.976-.083-1.325.821-.782 2.027z"
              />
              <path
                  data-name="Path 3215"
                  d="M768.058 47.497a3.981 3.981 0 002.749 2.333c.986.082 1.344-.829.8-2.034a3.982 3.982 0 00-2.747-2.326c-.972-.082-1.333.824-.802 2.027z"
              />
              <path
                  data-name="Path 3216"
                  d="M762.4 47.031a3.9 3.9 0 002.726 2.328c.986.082 1.357-.831.827-2.034a3.922 3.922 0 00-2.726-2.318c-.974-.082-1.346.824-.827 2.024z"
              />
              <path
                  data-name="Path 3217"
                  d="M756.734 46.578a3.841 3.841 0 002.707 2.318c.988.079 1.369-.834.852-2.035a3.849 3.849 0 00-2.705-2.308c-.978-.075-1.361.825-.854 2.025z"
              />
              <path
                  data-name="Path 3218"
                  d="M751.062 46.14a3.772 3.772 0 002.685 2.308c.989.077 1.382-.836.877-2.034a3.779 3.779 0 00-2.684-2.3c-.976-.076-1.371.829-.878 2.026z"
              />
              <path
                  data-name="Path 3219"
                  d="M745.385 45.714a3.706 3.706 0 002.664 2.3c.989.074 1.394-.839.9-2.035a3.713 3.713 0 00-2.662-2.293c-.981-.073-1.381.833-.902 2.028z"
              />
              <path
                  data-name="Path 3220"
                  d="M739.704 45.301a3.636 3.636 0 002.642 2.293c.991.072 1.405-.842.927-2.035a3.652 3.652 0 00-2.64-2.284c-.981-.073-1.399.834-.929 2.026z"
              />
              <path
                  data-name="Path 3221"
                  d="M734.015 44.904a3.576 3.576 0 002.62 2.286c.991.069 1.417-.846.953-2.037a3.587 3.587 0 00-2.619-2.278c-.98-.071-1.41.837-.954 2.029z"
              />
              <path
                  data-name="Path 3222"
                  d="M728.322 44.516a3.508 3.508 0 002.6 2.278c.993.067 1.43-.847.979-2.037a3.522 3.522 0 00-2.6-2.269c-.981-.066-1.42.84-.979 2.028z"
              />
              <path
                  data-name="Path 3223"
                  d="M722.624 44.143a3.444 3.444 0 002.575 2.271c.993.065 1.442-.851 1-2.039a3.462 3.462 0 00-2.573-2.261c-.98-.063-1.43.842-1.002 2.029z"
              />
              <path
                  data-name="Path 3224"
                  d="M716.919 43.786a3.382 3.382 0 002.553 2.261c.994.064 1.456-.852 1.029-2.037a3.393 3.393 0 00-2.55-2.254c-.984-.063-1.446.845-1.032 2.03z"
              />
              <path
                  data-name="Path 3225"
                  d="M711.206 43.439a3.321 3.321 0 002.53 2.254c1 .062 1.469-.856 1.056-2.039a3.334 3.334 0 00-2.528-2.248c-.98-.057-1.458.85-1.058 2.033z"
              />
              <path
                  data-name="Path 3226"
                  d="M705.497 43.107a3.263 3.263 0 002.507 2.248c1 .058 1.481-.857 1.083-2.04a3.273 3.273 0 00-2.5-2.239c-.992-.058-1.478.85-1.09 2.031z"
              />
              <path
                  data-name="Path 3227"
                  d="M699.78 42.79a3.2 3.2 0 002.482 2.239c1 .057 1.492-.861 1.11-2.04a3.214 3.214 0 00-2.482-2.233c-.988-.054-1.484.854-1.11 2.034z"
              />
              <path
                  data-name="Path 3228"
                  d="M694.057 42.485a3.142 3.142 0 002.46 2.233c1 .053 1.506-.864 1.135-2.044a3.156 3.156 0 00-2.458-2.224c-.987-.052-1.498.857-1.137 2.035z"
              />
              <path
                  data-name="Path 3229"
                  d="M688.332 42.195a3.085 3.085 0 002.435 2.226c1 .05 1.519-.867 1.163-2.045a3.094 3.094 0 00-2.435-2.218c-.99-.05-1.511.856-1.163 2.037z"
              />
              <path
                  data-name="Path 3230"
                  d="M682.598 41.917a3.027 3.027 0 002.411 2.219c1 .048 1.532-.871 1.19-2.047a3.038 3.038 0 00-2.411-2.211c-.989-.046-1.52.863-1.19 2.039z"
              />
              <path
                  data-name="Path 3231"
                  d="M676.863 41.653a2.97 2.97 0 002.388 2.212c1 .045 1.542-.874 1.215-2.049a2.981 2.981 0 00-2.386-2.2c-.989-.048-1.534.864-1.217 2.037z"
              />
              <path
                  data-name="Path 3232"
                  d="M671.124 41.402a2.909 2.909 0 002.361 2.206c1 .043 1.557-.877 1.243-2.05a2.923 2.923 0 00-2.361-2.2c-.99-.04-1.547.873-1.243 2.044z"
              />
              <path
                  data-name="Path 3233"
                  d="M665.382 41.167a2.856 2.856 0 002.338 2.2c1 .042 1.569-.881 1.27-2.052a2.872 2.872 0 00-2.338-2.191c-.991-.041-1.561.875-1.27 2.043z"
              />
              <path
                  data-name="Path 3234"
                  d="M659.635 40.945a2.8 2.8 0 002.313 2.192c1 .038 1.581-.882 1.3-2.054a2.814 2.814 0 00-2.313-2.184c-.995-.038-1.576.876-1.3 2.046z"
              />
              <path
                  data-name="Path 3235"
                  d="M653.883 40.737a2.751 2.751 0 002.289 2.186c1 .035 1.594-.887 1.323-2.057a2.759 2.759 0 00-2.288-2.177c-.99-.036-1.59.878-1.324 2.048z"
              />
              <path
                  data-name="Path 3236"
                  d="M648.129 40.545a2.7 2.7 0 002.263 2.181c1 .033 1.609-.891 1.352-2.06a2.71 2.71 0 00-2.263-2.171c-.993-.037-1.598.879-1.352 2.05z"
              />
              <path
                  data-name="Path 3237"
                  d="M642.373 40.361a2.645 2.645 0 002.238 2.174c1 .032 1.621-.894 1.379-2.062a2.653 2.653 0 00-2.238-2.164c-.993-.031-1.612.886-1.379 2.052z"
              />
              <path
                  data-name="Path 3238"
                  d="M636.612 40.196a2.6 2.6 0 002.213 2.167c1 .028 1.634-.9 1.405-2.065a2.606 2.606 0 00-2.211-2.159c-.994-.026-1.624.891-1.407 2.057z"
              />
              <path
                  data-name="Path 3239"
                  d="M630.848 40.041a2.546 2.546 0 002.186 2.162c1.006.025 1.648-.9 1.434-2.067a2.556 2.556 0 00-2.186-2.154c-.994-.024-1.638.895-1.434 2.059z"
              />
              <path
                  data-name="Path 3240"
                  d="M613.541 39.67a2.4 2.4 0 002.109 2.144 1.643 1.643 0 001.516-2.077 2.411 2.411 0 00-2.109-2.136 1.646 1.646 0 00-1.516 2.069z"
              />
              <path
                  data-name="Path 3241"
                  d="M607.766 39.573a2.356 2.356 0 002.084 2.139 1.67 1.67 0 001.544-2.08 2.367 2.367 0 00-2.08-2.13 1.673 1.673 0 00-1.548 2.071z"
              />
              <path
                  data-name="Path 3242"
                  d="M601.989 39.491a2.31 2.31 0 002.057 2.132 1.7 1.7 0 001.572-2.082 2.323 2.323 0 00-2.057-2.126 1.7 1.7 0 00-1.572 2.076z"
              />
              <path
                  data-name="Path 3243"
                  d="M596.211 39.422a2.268 2.268 0 002.032 2.127 1.725 1.725 0 001.6-2.087 2.275 2.275 0 00-2.03-2.119 1.733 1.733 0 00-1.602 2.079z"
              />
              <path
                  data-name="Path 3244"
                  d="M590.431 39.368a2.226 2.226 0 002.005 2.124 1.757 1.757 0 001.626-2.09 2.233 2.233 0 00-2-2.114 1.76 1.76 0 00-1.631 2.08z"
              />
              <path
                  data-name="Path 3245"
                  d="M584.647 39.327a2.184 2.184 0 001.979 2.119 1.788 1.788 0 001.654-2.1 2.191 2.191 0 00-1.977-2.109 1.8 1.8 0 00-1.656 2.09z"
              />
              <path
                  data-name="Path 3246"
                  d="M578.863 39.302a2.141 2.141 0 001.952 2.112 1.818 1.818 0 001.681-2.1 2.151 2.151 0 00-1.95-2.106 1.825 1.825 0 00-1.683 2.094z"
              />
              <path
                  data-name="Path 3247"
                  d="M573.076 39.29a2.1 2.1 0 001.925 2.107 1.851 1.851 0 001.709-2.1 2.11 2.11 0 00-1.925-2.1 1.858 1.858 0 00-1.709 2.093z"
              />
              <path
                  data-name="Path 3248"
                  d="M567.29 39.292a2.063 2.063 0 001.9 2.1 1.885 1.885 0 001.736-2.107 2.069 2.069 0 00-1.9-2.094 1.891 1.891 0 00-1.736 2.101z"
              />
              <path
                  data-name="Path 3249"
                  d="M451.454 42.322c-.259 1.173.351 2.087 1.36 2.037a2.764 2.764 0 002.273-2.217c.247-1.171-.363-2.079-1.36-2.029a2.773 2.773 0 00-2.273 2.209z"
              />
              <path
                  data-name="Path 3250"
                  d="M445.668 42.623c-.272 1.175.326 2.087 1.334 2.034a2.816 2.816 0 002.3-2.224c.261-1.173-.336-2.077-1.334-2.025a2.829 2.829 0 00-2.3 2.215z"
              />
              <path
                  data-name="Path 3251"
                  d="M439.886 42.937c-.287 1.175.3 2.087 1.307 2.032a2.871 2.871 0 002.324-2.231c.276-1.175-.311-2.079-1.307-2.024a2.883 2.883 0 00-2.324 2.223z"
              />
              <path
                  data-name="Path 3252"
                  d="M434.106 43.266c-.3 1.176.272 2.087 1.28 2.029a2.926 2.926 0 002.35-2.238c.291-1.175-.284-2.079-1.282-2.022a2.938 2.938 0 00-2.348 2.231z"
              />
              <path
                  data-name="Path 3253"
                  d="M428.327 43.609c-.314 1.178.247 2.087 1.255 2.027a2.984 2.984 0 002.375-2.244c.3-1.176-.257-2.081-1.255-2.02a3 3 0 00-2.375 2.237z"
              />
              <path
                  data-name="Path 3254"
                  d="M422.551 43.964c-.329 1.18.221 2.091 1.228 2.027a3.045 3.045 0 002.4-2.253c.317-1.178-.232-2.08-1.228-2.019a3.056 3.056 0 00-2.4 2.245z"
              />
              <path
                  data-name="Path 3255"
                  d="M416.777 44.335c-.343 1.181.2 2.091 1.2 2.025a3.1 3.1 0 002.425-2.261c.333-1.18-.207-2.08-1.2-2.017a3.117 3.117 0 00-2.425 2.253z"
              />
              <path
                  data-name="Path 3256"
                  d="M411.006 44.72c-.356 1.183.17 2.092 1.176 2.024a3.157 3.157 0 002.448-2.268c.346-1.181-.18-2.082-1.176-2.015a3.176 3.176 0 00-2.448 2.259z"
              />
              <path
                  data-name="Path 3257"
                  d="M405.242 45.117c-.371 1.185.145 2.092 1.15 2.024a3.223 3.223 0 002.473-2.274c.361-1.185-.154-2.084-1.15-2.015a3.237 3.237 0 00-2.473 2.265z"
              />
              <path
                  data-name="Path 3258"
                  d="M399.472 45.53c-.384 1.186.119 2.094 1.125 2.022a3.283 3.283 0 002.5-2.283c.374-1.186-.13-2.084-1.125-2.014a3.3 3.3 0 00-2.5 2.275z"
              />
              <path
                  data-name="Path 3259"
                  d="M393.706 45.956c-.4 1.188.094 2.1 1.1 2.02a3.339 3.339 0 002.52-2.289c.389-1.188-.1-2.085-1.1-2.012a3.357 3.357 0 00-2.52 2.281z"
              />
              <path
                  data-name="Path 3260"
                  d="M382.195 46.85c-.424 1.193.045 2.1 1.048 2.019a3.465 3.465 0 002.567-2.3c.414-1.191-.053-2.089-1.048-2.01a3.481 3.481 0 00-2.567 2.291z"
              />
              <path
                  data-name="Path 3261"
                  d="M319.152 52.749c-.563 1.217-.216 2.121.779 2.015a4.213 4.213 0 002.806-2.4c.556-1.215.206-2.112-.779-2.009a4.227 4.227 0 00-2.806 2.394z"
              />
              <path
                  data-name="Path 3262"
                  d="M313.45 53.365c-.577 1.22-.239 2.124.755 2.015a4.28 4.28 0 002.826-2.4c.566-1.217.229-2.116-.757-2.009a4.3 4.3 0 00-2.824 2.394z"
              />
              <path
                  data-name="Path 3263"
                  d="M307.753 53.996c-.587 1.222-.261 2.127.732 2.015a4.351 4.351 0 002.846-2.413c.578-1.22.251-2.117-.734-2.009a4.369 4.369 0 00-2.844 2.407z"
              />
              <path
                  data-name="Path 3264"
                  d="M302.062 54.641c-.6 1.223-.282 2.127.709 2.015a4.419 4.419 0 002.866-2.421c.592-1.223.272-2.121-.71-2.01a4.443 4.443 0 00-2.865 2.416z"
              />
              <path
                  data-name="Path 3265"
                  d="M296.377 55.298c-.61 1.227-.3 2.131.687 2.015a4.5 4.5 0 002.884-2.43c.6-1.225.292-2.124-.689-2.01a4.5 4.5 0 00-2.882 2.425z"
              />
              <path
                  data-name="Path 3266"
                  d="M285.025 56.653c-.633 1.233-.346 2.139.642 2.019a4.643 4.643 0 002.921-2.448c.623-1.23.336-2.131-.643-2.012a4.649 4.649 0 00-2.92 2.441z"
              />
              <path
                  data-name="Path 3267"
                  d="M273.699 58.054c-.655 1.238-.388 2.144.6 2.019a4.774 4.774 0 002.956-2.465c.647-1.237.376-2.136-.6-2.014a4.8 4.8 0 00-2.956 2.46z"
              />
              <path
                  data-name="Path 3268"
                  d="M268.048 58.777c-.667 1.242-.408 2.147.577 2.02a4.855 4.855 0 002.973-2.475c.657-1.238.4-2.139-.578-2.014a4.861 4.861 0 00-2.972 2.469z"
              />
              <path
                  data-name="Path 3269"
                  d="M245.501 61.792c-.705 1.252-.483 2.159.5 2.024a5.138 5.138 0 003.036-2.51c.7-1.25.475-2.152-.5-2.019a5.153 5.153 0 00-3.036 2.505z"
              />
              <path
                  data-name="Path 3270"
                  d="M228.673 64.187c-.734 1.26-.538 2.169.436 2.027a5.366 5.366 0 003.081-2.538c.725-1.258.528-2.162-.439-2.022a5.37 5.37 0 00-3.078 2.533z"
              />
              <path
                  data-name="Path 3271"
                  d="M223.078 65.01c-.742 1.263-.555 2.172.418 2.029a5.425 5.425 0 003.095-2.547c.737-1.262.548-2.166-.419-2.024a5.441 5.441 0 00-3.094 2.542z"
              />
              <path
                  data-name="Path 3272"
                  d="M935.162 60.855a6.046 6.046 0 003.2 2.59c.934.147 1.013-.78.175-2.065a6.053 6.053 0 00-3.2-2.587c-.927-.145-1.006.779-.175 2.062z"
              />
              <path
                  data-name="Path 3273"
                  d="M924.4 59.217a5.925 5.925 0 003.18 2.57c.936.142 1.026-.782.2-2.06a5.955 5.955 0 00-3.178-2.568c-.928-.139-1.02.777-.202 2.058z"
              />
              <path
                  data-name="Path 3274"
                  d="M919.006 58.415a5.879 5.879 0 003.17 2.562c.939.14 1.036-.782.219-2.059a5.884 5.884 0 00-3.167-2.558c-.938-.139-1.032.781-.222 2.055z"
              />
              <path
                  data-name="Path 3275"
                  d="M913.606 57.624a5.8 5.8 0 003.158 2.55c.941.139 1.044-.782.236-2.055a5.8 5.8 0 00-3.157-2.547c-.936-.138-1.041.781-.237 2.052z"
              />
              <path
                  data-name="Path 3276"
                  d="M908.196 56.844a5.742 5.742 0 003.148 2.542c.941.137 1.053-.784.251-2.054a5.755 5.755 0 00-3.147-2.538c-.934-.134-1.049.783-.252 2.05z"
              />
              <path
                  data-name="Path 3277"
                  d="M902.778 56.079a5.667 5.667 0 003.137 2.532c.943.134 1.061-.785.267-2.05a5.685 5.685 0 00-3.135-2.528c-.937-.138-1.063.778-.269 2.046z"
              />
              <path
                  data-name="Path 3278"
                  d="M897.353 55.317a5.611 5.611 0 003.125 2.522c.944.132 1.071-.785.284-2.047a5.616 5.616 0 00-3.122-2.52c-.939-.129-1.067.785-.287 2.045z"
              />
              <path
                  data-name="Path 3279"
                  d="M891.92 54.572a5.536 5.536 0 003.113 2.512c.944.132 1.078-.787.3-2.045a5.545 5.545 0 00-3.11-2.508c-.939-.13-1.075.784-.303 2.041z"
              />
              <path
                  data-name="Path 3280"
                  d="M886.48 53.837a5.458 5.458 0 003.1 2.5c.948.129 1.088-.787.319-2.044a5.474 5.474 0 00-3.1-2.5c-.939-.12-1.084.792-.319 2.044z"
              />
              <path
                  data-name="Path 3281"
                  d="M881.031 53.115a5.4 5.4 0 003.086 2.493c.948.127 1.1-.789.336-2.04a5.405 5.405 0 00-3.083-2.49c-.942-.124-1.094.787-.339 2.037z"
              />
              <path
                  data-name="Path 3282"
                  d="M875.581 52.405a5.328 5.328 0 003.072 2.483c.949.125 1.108-.79.356-2.039a5.352 5.352 0 00-3.072-2.48c-.946-.122-1.109.787-.356 2.036z"
              />
              <path
                  data-name="Path 3283"
                  d="M870.112 51.704a5.262 5.262 0 003.06 2.475c.949.122 1.118-.79.373-2.037a5.278 5.278 0 00-3.056-2.47c-.943-.118-1.114.791-.377 2.032z"
              />
              <path
                  data-name="Path 3284"
                  d="M864.643 51.017a5.185 5.185 0 003.043 2.465c.954.12 1.13-.792.393-2.034a5.2 5.2 0 00-3.041-2.461c-.946-.117-1.122.79-.395 2.03z"
              />
              <path
                  data-name="Path 3285"
                  d="M859.166 50.343a5.13 5.13 0 003.03 2.456c.954.117 1.138-.794.411-2.034a5.12 5.12 0 00-3.026-2.45c-.948-.114-1.132.791-.415 2.028z"
              />
              <path
                  data-name="Path 3286"
                  d="M853.681 49.679a5.055 5.055 0 003.015 2.446c.954.115 1.148-.794.431-2.03a5.067 5.067 0 00-3.011-2.441c-.948-.114-1.143.792-.435 2.025z"
              />
              <path
                  data-name="Path 3287"
                  d="M848.19 49.029a4.991 4.991 0 003 2.436c.956.114 1.158-.8.453-2.03a5 5 0 00-3-2.431c-.95-.111-1.15.793-.453 2.025z"
              />
              <path
                  data-name="Path 3288"
                  d="M842.692 48.39a4.919 4.919 0 002.983 2.428c.956.112 1.168-.8.471-2.029a4.923 4.923 0 00-2.979-2.421c-.951-.109-1.165.793-.475 2.022z"
              />
              <path
                  data-name="Path 3289"
                  d="M837.188 47.763a4.841 4.841 0 002.964 2.418c.959.11 1.18-.8.493-2.025a4.851 4.851 0 00-2.963-2.413c-.95-.109-1.173.795-.494 2.02z"
              />
              <path
                  data-name="Path 3290"
                  d="M831.677 47.148a4.766 4.766 0 002.948 2.408c.961.109 1.19-.8.515-2.024a4.787 4.787 0 00-2.948-2.4c-.953-.108-1.184.794-.515 2.016z"
              />
              <path
                  data-name="Path 3291"
                  d="M826.156 46.544a4.7 4.7 0 002.933 2.4c.962.105 1.2-.8.535-2.022a4.708 4.708 0 00-2.931-2.395c-.951-.102-1.193.799-.537 2.017z"
              />
              <path
                  data-name="Path 3292"
                  d="M820.632 45.954a4.637 4.637 0 002.914 2.39c.962.1 1.212-.8.556-2.02a4.636 4.636 0 00-2.913-2.385c-.951-.1-1.204.799-.557 2.015z"
              />
              <path
                  data-name="Path 3293"
                  d="M815.1 45.377a4.56 4.56 0 002.9 2.381c.964.1 1.223-.805.578-2.02a4.573 4.573 0 00-2.9-2.375c-.952-.099-1.213.8-.578 2.014z"
              />
              <path
                  data-name="Path 3294"
                  d="M809.564 44.81a4.489 4.489 0 002.879 2.373c.964.1 1.232-.807.6-2.019a4.507 4.507 0 00-2.878-2.366c-.955-.097-1.226.802-.601 2.012z"
              />
              <path
                  data-name="Path 3295"
                  d="M804.022 44.257a4.414 4.414 0 002.859 2.363c.966.1 1.245-.807.623-2.017a4.431 4.431 0 00-2.859-2.356c-.956-.096-1.241.804-.623 2.01z"
              />
              <path
                  data-name="Path 3296"
                  d="M798.472 43.715a4.346 4.346 0 002.841 2.355c.967.1 1.255-.81.645-2.015a4.359 4.359 0 00-2.839-2.348c-.959-.092-1.248.805-.647 2.008z"
              />
              <path
                  data-name="Path 3297"
                  d="M792.915 43.187a4.283 4.283 0 002.822 2.346c.969.092 1.267-.812.668-2.015a4.3 4.3 0 00-2.821-2.34c-.958-.09-1.259.808-.669 2.009z"
              />
              <path
                  data-name="Path 3298"
                  d="M787.355 42.673a4.215 4.215 0 002.8 2.336c.969.09 1.277-.814.69-2.014a4.224 4.224 0 00-2.8-2.331c-.958-.087-1.268.809-.69 2.009z"
              />
              <path
                  data-name="Path 3299"
                  d="M781.788 42.169a4.148 4.148 0 002.784 2.328c.969.087 1.288-.815.714-2.014a4.152 4.152 0 00-2.782-2.321c-.961-.085-1.282.811-.716 2.007z"
              />
              <path
                  data-name="Path 3300"
                  d="M776.215 41.678a4.071 4.071 0 002.764 2.319c.971.085 1.3-.817.737-2.012a4.079 4.079 0 00-2.761-2.313c-.963-.082-1.295.813-.74 2.006z"
              />
              <path
                  data-name="Path 3301"
                  d="M770.637 41.2a4.005 4.005 0 002.744 2.311c.973.084 1.312-.82.76-2.012a4.017 4.017 0 00-2.741-2.3c-.964-.086-1.306.812-.763 2.001z"
              />
              <path
                  data-name="Path 3302"
                  d="M765.054 40.739a3.939 3.939 0 002.722 2.3c.973.08 1.324-.822.785-2.012a3.957 3.957 0 00-2.721-2.3c-.963-.076-1.317.818-.786 2.012z"
              />
              <path
                  data-name="Path 3303"
                  d="M759.464 40.282a3.869 3.869 0 002.7 2.294c.974.079 1.335-.824.809-2.012a3.881 3.881 0 00-2.7-2.286c-.962-.076-1.326.82-.809 2.004z"
              />
              <path
                  data-name="Path 3304"
                  d="M753.871 39.843a3.8 3.8 0 002.68 2.286c.974.077 1.347-.826.834-2.01a3.817 3.817 0 00-2.679-2.279c-.965-.075-1.339.82-.835 2.003z"
              />
              <path
                  data-name="Path 3305"
                  d="M748.271 39.417a3.735 3.735 0 002.659 2.278c.976.074 1.36-.827.859-2.01a3.751 3.751 0 00-2.657-2.271c-.972-.072-1.354.822-.861 2.003z"
              />
              <path
                  data-name="Path 3306"
                  d="M742.666 39.004a3.672 3.672 0 002.637 2.269c.978.072 1.372-.831.884-2.012A3.685 3.685 0 00743.552 37c-.967-.07-1.364.824-.886 2.004z"
              />
              <path
                  data-name="Path 3307"
                  d="M737.059 38.603a3.6 3.6 0 002.615 2.261c.978.07 1.384-.832.907-2.01a3.616 3.616 0 00-2.614-2.254c-.967-.069-1.375.827-.908 2.003z"
              />
              <path
                  data-name="Path 3308"
                  d="M731.443 38.216a3.541 3.541 0 002.594 2.254c.978.067 1.4-.836.933-2.012a3.551 3.551 0 00-2.59-2.246c-.972-.065-1.389.829-.937 2.004z"
              />
              <path
                  data-name="Path 3309"
                  d="M725.825 37.842a3.474 3.474 0 002.57 2.246c.981.067 1.409-.839.959-2.012a3.487 3.487 0 00-2.57-2.238c-.969-.059-1.398.831-.959 2.004z"
              />
              <path
                  data-name="Path 3310"
                  d="M720.202 37.481a3.413 3.413 0 002.548 2.238c.979.062 1.42-.841.984-2.012a3.424 3.424 0 00-2.547-2.229c-.97-.063-1.411.831-.985 2.003z"
              />
              <path
                  data-name="Path 3311"
                  d="M714.574 37.131a3.347 3.347 0 002.525 2.231c.983.06 1.434-.842 1.011-2.014a3.361 3.361 0 00-2.525-2.221c-.971-.058-1.423.836-1.011 2.004z"
              />
              <path
                  data-name="Path 3312"
                  d="M708.943 36.797a3.287 3.287 0 002.5 2.223c.983.058 1.445-.846 1.036-2.014a3.3 3.3 0 00-2.5-2.214c-.971-.057-1.437.839-1.036 2.005z"
              />
              <path
                  data-name="Path 3313"
                  d="M703.306 36.474a3.226 3.226 0 002.48 2.216c.983.055 1.455-.849 1.061-2.015a3.238 3.238 0 00-2.478-2.206c-.973-.055-1.449.841-1.063 2.005z"
              />
              <path
                  data-name="Path 3314"
                  d="M697.666 36.167a3.164 3.164 0 002.455 2.207c.984.053 1.471-.851 1.088-2.015a3.177 3.177 0 00-2.455-2.2c-.972-.054-1.46.846-1.088 2.008z"
              />
              <path
                  data-name="Path 3315"
                  d="M692.022 35.871a3.109 3.109 0 002.431 2.2c.986.05 1.482-.854 1.115-2.017a3.12 3.12 0 00-2.431-2.192c-.973-.048-1.475.848-1.115 2.009z"
              />
              <path
                  data-name="Path 3316"
                  d="M686.373 35.588a3.045 3.045 0 002.408 2.192c.984.05 1.5-.856 1.14-2.017a3.059 3.059 0 00-2.406-2.184c-.975-.048-1.486.85-1.142 2.009z"
              />
              <path
                  data-name="Path 3317"
                  d="M680.722 35.32a2.99 2.99 0 002.383 2.186c.986.047 1.507-.859 1.166-2.019a3 3 0 00-2.381-2.177c-.976-.046-1.499.854-1.168 2.01z"
              />
              <path
                  data-name="Path 3318"
                  d="M675.066 35.061a2.935 2.935 0 002.359 2.179c.986.045 1.521-.862 1.193-2.02a2.943 2.943 0 00-2.358-2.171c-.976-.038-1.51.859-1.194 2.012z"
              />
              <path
                  data-name="Path 3319"
                  d="M669.407 34.822a2.879 2.879 0 002.334 2.174c.986.042 1.532-.866 1.22-2.024a2.885 2.885 0 00-2.335-2.162c-.976-.047-1.522.853-1.219 2.012z"
              />
              <path
                  data-name="Path 3320"
                  d="M663.743 34.595a2.825 2.825 0 002.311 2.166c.986.04 1.546-.869 1.247-2.024a2.832 2.832 0 00-2.31-2.157c-.977-.041-1.535.86-1.248 2.015z"
              />
              <path
                  data-name="Path 3321"
                  d="M658.077 34.384a2.773 2.773 0 002.286 2.159c.988.037 1.557-.872 1.273-2.025a2.778 2.778 0 00-2.284-2.151c-.978-.041-1.554.859-1.275 2.017z"
              />
              <path
                  data-name="Path 3322"
                  d="M652.409 34.177a2.715 2.715 0 002.261 2.152c.988.037 1.571-.876 1.3-2.027a2.728 2.728 0 00-2.259-2.144c-.98-.033-1.561.868-1.302 2.019z"
              />
              <path
                  data-name="Path 3323"
                  d="M646.735 33.989a2.663 2.663 0 002.236 2.146c.989.033 1.584-.879 1.328-2.03a2.674 2.674 0 00-2.236-2.137c-.977-.031-1.572.87-1.328 2.021z"
              />
              <path
                  data-name="Path 3324"
                  d="M635.382 33.654a2.564 2.564 0 002.186 2.135c.991.027 1.609-.886 1.382-2.035a2.572 2.572 0 00-2.184-2.124c-.979-.028-1.599.876-1.384 2.024z"
              />
              <path
                  data-name="Path 3325"
                  d="M629.702 33.505a2.511 2.511 0 002.159 2.129c.991.025 1.621-.889 1.409-2.037a2.521 2.521 0 00-2.154-2.119c-.983-.025-1.616.881-1.414 2.027z"
              />
              <path
                  data-name="Path 3326"
                  d="M624.019 33.374a2.46 2.46 0 002.132 2.122c.993.023 1.634-.892 1.437-2.039a2.474 2.474 0 00-2.132-2.114c-.981-.024-1.62.882-1.437 2.031z"
              />
              <path
                  data-name="Path 3327"
                  d="M618.332 33.251a2.416 2.416 0 002.107 2.117 1.589 1.589 0 001.464-2.042 2.424 2.424 0 00-2.107-2.109 1.592 1.592 0 00-1.464 2.034z"
              />
              <path
                  data-name="Path 3328"
                  d="M612.644 33.144a2.371 2.371 0 002.082 2.112 1.616 1.616 0 001.491-2.047 2.379 2.379 0 00-2.08-2.1 1.618 1.618 0 00-1.493 2.035z"
              />
              <path
                  data-name="Path 3329"
                  d="M606.953 33.052a2.328 2.328 0 002.057 2.106 1.644 1.644 0 001.519-2.049 2.336 2.336 0 00-2.055-2.1 1.648 1.648 0 00-1.521 2.043z"
              />
              <path
                  data-name="Path 3330"
                  d="M601.26 32.972a2.279 2.279 0 002.029 2.1 1.671 1.671 0 001.547-2.052 2.288 2.288 0 00-2.03-2.091 1.673 1.673 0 00-1.546 2.043z"
              />
              <path
                  data-name="Path 3331"
                  d="M595.566 32.907a2.238 2.238 0 002 2.1 1.7 1.7 0 001.574-2.055 2.247 2.247 0 00-2-2.087 1.7 1.7 0 00-1.574 2.042z"
              />
              <path
                  data-name="Path 3332"
                  d="M589.869 32.858a2.191 2.191 0 001.977 2.089 1.728 1.728 0 001.6-2.059 2.2 2.2 0 00-1.977-2.08 1.734 1.734 0 00-1.6 2.05z"
              />
              <path
                  data-name="Path 3333"
                  d="M584.171 32.817a2.151 2.151 0 001.95 2.085 1.761 1.761 0 001.629-2.064 2.16 2.16 0 00-1.95-2.075 1.763 1.763 0 00-1.629 2.054z"
              />
              <path
                  data-name="Path 3334"
                  d="M578.471 32.791a2.109 2.109 0 001.923 2.08 1.789 1.789 0 001.656-2.067 2.119 2.119 0 00-1.923-2.07 1.8 1.8 0 00-1.656 2.057z"
              />
              <path
                  data-name="Path 3335"
                  d="M464.313 35.196c-.229 1.153.393 2.057 1.389 2.012a2.626 2.626 0 002.2-2.171c.216-1.153-.406-2.047-1.39-2a2.635 2.635 0 00-2.199 2.159z"
              />
              <path
                  data-name="Path 3336"
                  d="M458.606 35.457c-.242 1.153.368 2.057 1.364 2.009a2.678 2.678 0 002.221-2.177c.231-1.153-.379-2.047-1.364-2a2.689 2.689 0 00-2.221 2.168z"
              />
              <path
                  data-name="Path 3337"
                  d="M452.903 35.741c-.257 1.155.341 2.057 1.335 2.007a2.73 2.73 0 002.248-2.182c.246-1.155-.353-2.047-1.337-2a2.737 2.737 0 00-2.246 2.175z"
              />
              <path
                  data-name="Path 3338"
                  d="M447.2 36.033c-.272 1.156.314 2.057 1.308 2.005a2.782 2.782 0 002.273-2.191c.261-1.156-.326-2.049-1.31-2a2.792 2.792 0 00-2.271 2.186z"
              />
              <path
                  data-name="Path 3339"
                  d="M441.497 36.339c-.286 1.158.287 2.059 1.283 2a2.84 2.84 0 002.3-2.2c.274-1.158-.3-2.049-1.285-2a2.848 2.848 0 00-2.298 2.2z"
              />
              <path
                  data-name="Path 3340"
                  d="M435.798 36.659c-.3 1.161.262 2.06 1.257 2a2.9 2.9 0 002.323-2.206c.289-1.158-.274-2.049-1.258-1.994a2.905 2.905 0 00-2.322 2.2z"
              />
              <path
                  data-name="Path 3341"
                  d="M430.097 36.992c-.312 1.163.237 2.06 1.232 2a2.955 2.955 0 002.348-2.213c.3-1.161-.249-2.05-1.232-1.994a2.968 2.968 0 00-2.348 2.207z"
              />
              <path
                  data-name="Path 3342"
                  d="M424.4 37.34c-.328 1.163.212 2.06 1.2 2a3.01 3.01 0 002.373-2.219c.317-1.163-.222-2.052-1.2-1.992a3.023 3.023 0 00-2.373 2.211z"
              />
              <path
                  data-name="Path 3343"
                  d="M418.708 37.701c-.343 1.165.185 2.062 1.178 2a3.067 3.067 0 002.4-2.228c.331-1.163-.2-2.052-1.18-1.99a3.082 3.082 0 00-2.398 2.218z"
              />
              <path
                  data-name="Path 3344"
                  d="M413.014 38.075c-.356 1.168.16 2.064 1.153 2a3.124 3.124 0 002.421-2.234c.346-1.166-.17-2.054-1.153-1.99a3.144 3.144 0 00-2.421 2.224z"
              />
              <path
                  data-name="Path 3345"
                  d="M407.325 38.465c-.369 1.168.134 2.064 1.126 2a3.184 3.184 0 002.446-2.243c.359-1.168-.145-2.055-1.128-1.989a3.2 3.2 0 00-2.444 2.232z"
              />
              <path
                  data-name="Path 3346"
                  d="M401.636 38.865c-.383 1.171.11 2.067 1.1 2a3.256 3.256 0 002.471-2.251c.373-1.17-.122-2.055-1.1-1.987a3.264 3.264 0 00-2.471 2.238z"
              />
              <path
                  data-name="Path 3347"
                  d="M395.951 39.282c-.4 1.173.085 2.069 1.076 2a3.309 3.309 0 002.493-2.258c.388-1.171-.1-2.059-1.076-1.987a3.322 3.322 0 00-2.493 2.245z"
              />
              <path
                  data-name="Path 3348"
                  d="M390.267 39.711c-.409 1.175.06 2.069 1.051 1.994a3.375 3.375 0 002.518-2.266c.4-1.173-.072-2.06-1.051-1.985a3.385 3.385 0 00-2.518 2.257z"
              />
              <path
                  data-name="Path 3349"
                  d="M384.587 40.154c-.423 1.176.035 2.07 1.026 1.994a3.44 3.44 0 002.542-2.274c.413-1.175-.047-2.062-1.028-1.985a3.449 3.449 0 00-2.54 2.265z"
              />
              <path
                  data-name="Path 3350"
                  d="M378.911 40.61c-.436 1.178.01 2.074 1 1.992a3.5 3.5 0 002.565-2.281c.428-1.176-.022-2.065-1-1.985a3.513 3.513 0 00-2.565 2.274z"
              />
              <path
                  data-name="Path 3351"
                  d="M311.067 47.124c-.587 1.21-.267 2.106.715 2a4.312 4.312 0 002.821-2.385c.578-1.207.257-2.1-.715-1.99a4.336 4.336 0 00-2.821 2.375z"
              />
              <path
                  data-name="Path 3352"
                  d="M288.595 49.717c-.632 1.22-.351 2.117.625 2a4.606 4.606 0 002.9-2.421c.622-1.218.341-2.111-.627-2a4.619 4.619 0 00-2.898 2.421z"
              />
              <path
                  data-name="Path 3353"
                  d="M277.391 51.091c-.653 1.227-.393 2.124.583 2a4.749 4.749 0 002.933-2.44c.645-1.223.383-2.117-.583-2a4.761 4.761 0 00-2.933 2.44z"
              />
              <path
                  data-name="Path 3354"
                  d="M266.205 52.515c-.675 1.233-.433 2.131.54 2.005a4.895 4.895 0 002.968-2.458c.667-1.23.423-2.124-.541-2a4.9 4.9 0 00-2.967 2.453z"
              />
              <path
                  data-name="Path 3355"
                  d="M249.48 54.746c-.7 1.242-.488 2.142.481 2.01a5.1 5.1 0 003.014-2.484c.7-1.238.48-2.134-.481-2.005a5.127 5.127 0 00-3.014 2.479z"
              />
              <path
                  data-name="Path 3356"
                  d="M887.556 47.492a5.507 5.507 0 003.093 2.492c.939.127 1.068-.787.291-2.037a5.5 5.5 0 00-3.091-2.485c-.931-.122-1.063.783-.293 2.03z"
              />
              <path
                  data-name="Path 3357"
                  d="M882.165 46.778a5.436 5.436 0 003.08 2.48c.939.125 1.076-.787.307-2.034a5.437 5.437 0 00-3.078-2.475c-.931-.122-1.073.784-.309 2.029z"
              />
              <path
                  data-name="Path 3358"
                  d="M849.677 42.738a5.025 5.025 0 002.993 2.42c.946.112 1.131-.792.419-2.017a5.024 5.024 0 00-2.991-2.415c-.938-.113-1.128.786-.421 2.012z"
              />
              <path
                  data-name="Path 3359"
                  d="M844.243 42.102a4.945 4.945 0 002.976 2.41c.946.112 1.143-.792.438-2.014a4.952 4.952 0 00-2.973-2.4c-.939-.113-1.138.787-.441 2.004z"
              />
              <path
                  data-name="Path 3360"
                  d="M838.802 41.482a4.879 4.879 0 002.959 2.4c.946.109 1.153-.794.458-2.012a4.877 4.877 0 00-2.958-2.395c-.94-.105-1.146.791-.459 2.007z"
              />
              <path
                  data-name="Path 3361"
                  d="M833.356 40.873a4.805 4.805 0 002.943 2.391c.949.105 1.161-.8.48-2.01a4.811 4.811 0 00-2.941-2.385c-.945-.103-1.159.792-.482 2.004z"
              />
              <path
                  data-name="Path 3362"
                  d="M822.443 39.691a4.662 4.662 0 002.909 2.371c.949.1 1.183-.8.52-2.005a4.677 4.677 0 00-2.906-2.366c-.944-.099-1.178.794-.523 2z"
              />
              <path
                  data-name="Path 3363"
                  d="M816.981 39.118a4.593 4.593 0 002.889 2.361c.953.1 1.195-.8.542-2a4.593 4.593 0 00-2.888-2.355c-.944-.1-1.188.791-.543 1.994z"
              />
              <path
                  data-name="Path 3364"
                  d="M811.511 38.557a4.517 4.517 0 002.873 2.353c.953.1 1.2-.8.563-2a4.533 4.533 0 00-2.871-2.346c-.946-.096-1.198.793-.565 1.993z"
              />
              <path
                  data-name="Path 3365"
                  d="M806.037 38.008a4.447 4.447 0 002.854 2.343c.954.1 1.215-.8.585-2a4.464 4.464 0 00-2.853-2.336c-.946-.094-1.21.797-.586 1.993z"
              />
              <path
                  data-name="Path 3366"
                  d="M800.558 37.473a4.379 4.379 0 002.836 2.333c.954.094 1.225-.8.607-2a4.393 4.393 0 00-2.834-2.326c-.947-.091-1.221.799-.609 1.993z"
              />
              <path
                  data-name="Path 3367"
                  d="M795.073 36.948a4.313 4.313 0 002.816 2.324c.956.092 1.238-.805.628-2a4.317 4.317 0 00-2.814-2.318c-.948-.085-1.23.804-.63 1.994z"
              />
              <path
                  data-name="Path 3368"
                  d="M789.582 36.434a4.245 4.245 0 002.8 2.316c.957.089 1.248-.805.652-2a4.25 4.25 0 00-2.8-2.308c-.947-.082-1.239.806-.652 1.992z"
              />
              <path
                  data-name="Path 3369"
                  d="M784.087 35.935a4.17 4.17 0 002.777 2.306c.959.087 1.26-.807.675-1.994a4.183 4.183 0 00-2.776-2.3c-.951-.083-1.251.803-.676 1.988z"
              />
              <path
                  data-name="Path 3370"
                  d="M778.588 35.447a4.1 4.1 0 002.759 2.3c.958.085 1.268-.809.7-1.992a4.111 4.111 0 00-2.757-2.289c-.953-.086-1.266.799-.702 1.981z"
              />
              <path
                  data-name="Path 3371"
                  d="M773.083 34.973a4.031 4.031 0 002.737 2.288c.959.084 1.282-.809.72-1.99a4.041 4.041 0 00-2.735-2.281c-.949-.083-1.275.805-.722 1.983z"
              />
              <path
                  data-name="Path 3372"
                  d="M767.574 34.509a3.965 3.965 0 002.717 2.279c.961.082 1.294-.812.744-1.99a3.973 3.973 0 00-2.715-2.271c-.951-.079-1.284.805-.746 1.982z"
              />
              <path
                  data-name="Path 3373"
                  d="M762.061 34.06a3.893 3.893 0 002.7 2.269c.961.079 1.305-.814.767-1.989a3.907 3.907 0 00-2.694-2.263c-.955-.076-1.301.81-.773 1.983z"
              />
              <path
                  data-name="Path 3374"
                  d="M756.542 33.622a3.83 3.83 0 002.675 2.261c.963.077 1.317-.815.792-1.989a3.847 3.847 0 00-2.674-2.254c-.952-.074-1.308.81-.793 1.982z"
              />
              <path
                  data-name="Path 3375"
                  d="M751.019 33.196a3.765 3.765 0 002.654 2.253c.964.075 1.328-.817.815-1.987a3.775 3.775 0 00-2.652-2.246c-.954-.072-1.32.812-.817 1.98z"
              />
              <path
                  data-name="Path 3376"
                  d="M745.491 32.783a3.7 3.7 0 002.634 2.244c.963.072 1.339-.819.839-1.987a3.706 3.706 0 00-2.63-2.236c-.955-.07-1.333.813-.843 1.979z"
              />
              <path
                  data-name="Path 3377"
                  d="M739.96 32.384a3.637 3.637 0 002.61 2.236c.964.07 1.352-.822.866-1.987a3.646 3.646 0 00-2.609-2.228c-.956-.068-1.344.816-.867 1.979z"
              />
              <path
                  data-name="Path 3378"
                  d="M734.425 31.996a3.567 3.567 0 002.587 2.228c.966.067 1.364-.824.889-1.987a3.575 3.575 0 00-2.587-2.219c-.954-.067-1.354.817-.889 1.978z"
              />
              <path
                  data-name="Path 3379"
                  d="M728.887 31.62a3.505 3.505 0 002.567 2.221c.966.065 1.375-.826.914-1.987a3.514 3.514 0 00-2.565-2.211c-.96-.063-1.369.819-.916 1.977z"
              />
              <path
                  data-name="Path 3380"
                  d="M723.339 31.257a3.442 3.442 0 002.545 2.213c.966.063 1.385-.829.939-1.985a3.451 3.451 0 00-2.543-2.2c-.956-.067-1.377.817-.941 1.972z"
              />
              <path
                  data-name="Path 3381"
                  d="M717.791 30.905a3.375 3.375 0 002.522 2.2c.968.062 1.4-.831.964-1.985a3.384 3.384 0 00-2.52-2.2c-.956-.049-1.389.835-.966 1.985z"
              />
              <path
                  data-name="Path 3382"
                  d="M712.24 30.57a3.311 3.311 0 002.5 2.2c.968.058 1.41-.832.989-1.987a3.325 3.325 0 00-2.5-2.187c-.956-.059-1.4.824-.989 1.974z"
              />
              <path
                  data-name="Path 3383"
                  d="M706.688 30.249a3.25 3.25 0 002.472 2.191c.969.055 1.42-.836 1.014-1.987a3.262 3.262 0 00-2.473-2.181c-.959-.057-1.413.827-1.013 1.977z"
              />
              <path
                  data-name="Path 3384"
                  d="M673.277 28.573a2.9 2.9 0 002.331 2.146c.973.043 1.5-.851 1.171-1.992a2.911 2.911 0 00-2.331-2.136c-.961-.041-1.487.844-1.171 1.982z"
              />
              <path
                  data-name="Path 3385"
                  d="M667.697 28.339a2.845 2.845 0 002.306 2.139c.973.04 1.509-.854 1.2-1.994a2.85 2.85 0 00-2.306-2.127c-.962-.04-1.5.846-1.2 1.982z"
              />
              <path
                  data-name="Path 3386"
                  d="M594.924 26.495a2.2 2.2 0 001.975 2.06 1.67 1.67 0 001.549-2.022 2.211 2.211 0 00-1.975-2.052 1.675 1.675 0 00-1.549 2.014z"
              />
              <path
                  data-name="Path 3387"
                  d="M476.845 28.252c-.2 1.133.436 2.025 1.417 1.987a2.489 2.489 0 002.116-2.124c.189-1.131-.448-2.014-1.417-1.977a2.5 2.5 0 00-2.116 2.114z"
              />
              <path
                  data-name="Path 3388"
                  d="M471.218 28.485c-.212 1.133.411 2.024 1.392 1.982a2.539 2.539 0 002.142-2.129c.2-1.133-.421-2.015-1.392-1.974a2.549 2.549 0 00-2.142 2.121z"
              />
              <path
                  data-name="Path 3389"
                  d="M465.593 28.729c-.226 1.135.384 2.024 1.365 1.982a2.594 2.594 0 002.169-2.137c.216-1.135-.4-2.014-1.365-1.972a2.6 2.6 0 00-2.169 2.127z"
              />
              <path
                  data-name="Path 3390"
                  d="M459.97 28.987c-.242 1.136.358 2.025 1.339 1.98a2.649 2.649 0 002.194-2.144c.231-1.135-.369-2.015-1.34-1.97a2.654 2.654 0 00-2.193 2.134z"
              />
              <path
                  data-name="Path 3391"
                  d="M454.347 29.257c-.256 1.138.331 2.025 1.312 1.979a2.694 2.694 0 002.219-2.151c.246-1.136-.343-2.015-1.312-1.969a2.705 2.705 0 00-2.219 2.141z"
              />
              <path
                  data-name="Path 3392"
                  d="M448.727 29.541c-.269 1.14.306 2.027 1.287 1.977a2.751 2.751 0 002.244-2.157c.261-1.138-.316-2.015-1.287-1.967a2.763 2.763 0 00-2.244 2.147z"
              />
              <path
                  data-name="Path 3393"
                  d="M443.102 29.839c-.284 1.141.279 2.029 1.26 1.975a2.8 2.8 0 002.271-2.164c.274-1.14-.291-2.017-1.26-1.965a2.817 2.817 0 00-2.271 2.154z"
              />
              <path
                  data-name="Path 3394"
                  d="M437.482 30.15c-.3 1.143.254 2.03 1.233 1.974a2.859 2.859 0 002.3-2.171c.289-1.14-.264-2.019-1.233-1.964a2.87 2.87 0 00-2.3 2.161z"
              />
              <path
                  data-name="Path 3395"
                  d="M431.864 30.474c-.312 1.145.227 2.03 1.207 1.974a2.913 2.913 0 002.321-2.179c.3-1.143-.239-2.019-1.208-1.964a2.928 2.928 0 00-2.32 2.169z"
              />
              <path
                  data-name="Path 3396"
                  d="M426.246 30.811c-.326 1.146.2 2.032 1.181 1.972a2.97 2.97 0 002.346-2.186c.317-1.145-.214-2.02-1.181-1.962a2.988 2.988 0 00-2.346 2.176z"
              />
              <path
                  data-name="Path 3397"
                  d="M420.629 31.162c-.339 1.148.177 2.034 1.156 1.972a3.032 3.032 0 002.371-2.194c.331-1.146-.189-2.022-1.156-1.962a3.049 3.049 0 00-2.371 2.184z"
              />
              <path
                  data-name="Path 3398"
                  d="M415.016 31.527c-.354 1.15.152 2.035 1.13 1.97a3.091 3.091 0 002.4-2.2c.344-1.148-.164-2.024-1.131-1.962a3.1 3.1 0 00-2.399 2.192z"
              />
              <path
                  data-name="Path 3399"
                  d="M409.403 31.905c-.368 1.153.127 2.035 1.1 1.97a3.152 3.152 0 002.42-2.209c.359-1.15-.137-2.025-1.1-1.96a3.162 3.162 0 00-2.42 2.199z"
              />
              <path
                  data-name="Path 3400"
                  d="M403.794 32.295c-.383 1.153.1 2.039 1.079 1.969a3.214 3.214 0 002.443-2.216c.373-1.151-.112-2.029-1.081-1.96a3.222 3.222 0 00-2.441 2.207z"
              />
              <path
                  data-name="Path 3401"
                  d="M398.184 32.697c-.394 1.156.077 2.04 1.054 1.97a3.28 3.28 0 002.468-2.224c.384-1.155-.087-2.03-1.056-1.962a3.29 3.29 0 00-2.466 2.216z"
              />
              <path
                  data-name="Path 3402"
                  d="M392.577 33.116c-.408 1.158.052 2.042 1.029 1.969a3.338 3.338 0 002.492-2.233c.4-1.156-.064-2.032-1.031-1.96a3.353 3.353 0 00-2.49 2.224z"
              />
              <path
                  data-name="Path 3403"
                  d="M386.972 33.545c-.421 1.161.029 2.045 1 1.969a3.4 3.4 0 002.517-2.239c.411-1.16-.038-2.035-1.006-1.96a3.415 3.415 0 00-2.511 2.23z"
              />
              <path
                  data-name="Path 3404"
                  d="M381.37 33.99c-.435 1.163 0 2.047.979 1.969a3.467 3.467 0 002.538-2.249c.426-1.161-.013-2.037-.981-1.96a3.476 3.476 0 00-2.536 2.24z"
              />
              <path
                  data-name="Path 3405"
                  d="M375.771 34.446c-.448 1.166-.02 2.049.956 1.969a3.532 3.532 0 002.562-2.258c.438-1.163.008-2.039-.958-1.96a3.545 3.545 0 00-2.56 2.249z"
              />
              <path
                  data-name="Path 3406"
                  d="M370.175 34.916c-.461 1.168-.045 2.052.931 1.969a3.6 3.6 0 002.584-2.266c.451-1.166.033-2.042-.931-1.96a3.611 3.611 0 00-2.584 2.257z"
              />
              <path
                  data-name="Path 3407"
                  d="M364.583 35.398c-.473 1.17-.07 2.055.906 1.969a3.656 3.656 0 002.607-2.274c.465-1.168.058-2.044-.907-1.96a3.673 3.673 0 00-2.606 2.265z"
              />
              <path
                  data-name="Path 3408"
                  d="M358.989 35.894c-.488 1.173-.092 2.057.882 1.969a3.726 3.726 0 002.629-2.283c.478-1.171.082-2.047-.882-1.962a3.742 3.742 0 00-2.629 2.276z"
              />
              <path
                  data-name="Path 3409"
                  d="M353.4 36.403c-.5 1.176-.117 2.06.857 1.97a3.8 3.8 0 002.652-2.291c.49-1.175.105-2.05-.861-1.964a3.807 3.807 0 00-2.648 2.285z"
              />
              <path
                  data-name="Path 3410"
                  d="M347.814 36.924c-.511 1.18-.139 2.064.834 1.972a3.866 3.866 0 002.674-2.3c.5-1.175.129-2.052-.836-1.963a3.881 3.881 0 00-2.672 2.291z"
              />
              <path
                  data-name="Path 3411"
                  d="M331.077 38.568c-.548 1.186-.207 2.072.765 1.974a4.075 4.075 0 002.737-2.328c.54-1.185.2-2.062-.767-1.965a4.088 4.088 0 00-2.735 2.319z"
              />
              <path
                  data-name="Path 3412"
                  d="M325.505 39.141c-.56 1.19-.229 2.075.742 1.975a4.142 4.142 0 002.757-2.336c.551-1.188.219-2.067-.744-1.969a4.162 4.162 0 00-2.755 2.33z"
              />
              <path
                  data-name="Path 3413"
                  d="M319.937 39.728c-.573 1.193-.252 2.08.719 1.977a4.217 4.217 0 002.777-2.346c.565-1.19.241-2.07-.72-1.969a4.218 4.218 0 00-2.776 2.338z"
              />
              <path
                  data-name="Path 3414"
                  d="M314.372 40.328c-.585 1.195-.272 2.082.7 1.977a4.281 4.281 0 002.8-2.355c.575-1.193.262-2.074-.7-1.97a4.3 4.3 0 00-2.8 2.348z"
              />
              <path
                  data-name="Path 3415"
                  d="M308.812 40.941c-.595 1.2-.294 2.085.675 1.979a4.361 4.361 0 002.817-2.366c.588-1.2.284-2.077-.677-1.972a4.374 4.374 0 00-2.815 2.359z"
              />
              <path
                  data-name="Path 3416"
                  d="M303.255 41.567c-.607 1.2-.316 2.089.652 1.979a4.425 4.425 0 002.837-2.373c.6-1.2.306-2.082-.653-1.974a4.432 4.432 0 00-2.836 2.368z"
              />
              <path
                  data-name="Path 3417"
                  d="M297.702 42.203c-.617 1.2-.336 2.094.632 1.982a4.5 4.5 0 002.856-2.383c.61-1.2.326-2.085-.632-1.975a4.505 4.505 0 00-2.856 2.376z"
              />
              <path
                  data-name="Path 3418"
                  d="M292.154 42.853c-.628 1.21-.356 2.1.61 1.984a4.562 4.562 0 002.874-2.393c.622-1.2.348-2.089-.61-1.977a4.58 4.58 0 00-2.874 2.386z"
              />
              <path
                  data-name="Path 3419"
                  d="M286.612 43.516c-.64 1.211-.378 2.1.588 1.985a4.643 4.643 0 002.893-2.4c.633-1.208.368-2.092-.59-1.979a4.657 4.657 0 00-2.891 2.394z"
              />
              <path
                  data-name="Path 3420"
                  d="M281.072 44.194c-.65 1.213-.4 2.1.568 1.985a4.715 4.715 0 002.911-2.411c.643-1.213.388-2.1-.57-1.98a4.723 4.723 0 00-2.909 2.406z"
              />
              <path
                  data-name="Path 3421"
                  d="M275.537 44.881c-.66 1.217-.416 2.107.546 1.987a4.783 4.783 0 002.929-2.421c.653-1.215.408-2.1-.548-1.984a4.8 4.8 0 00-2.927 2.418z"
              />
              <path
                  data-name="Path 3422"
                  d="M824.143 33.476a4.691 4.691 0 002.9 2.351c.939.1 1.156-.792.486-1.99a4.7 4.7 0 00-2.9-2.346c-.93-.099-1.149.789-.486 1.985z"
              />
              <path
                  data-name="Path 3423"
                  d="M818.745 32.905a4.628 4.628 0 002.884 2.343c.939.1 1.166-.794.506-1.989a4.633 4.633 0 00-2.883-2.336c-.931-.092-1.154.794-.507 1.982z"
              />
              <path
                  data-name="Path 3424"
                  d="M813.343 32.357a4.55 4.55 0 002.866 2.331c.943.1 1.176-.794.528-1.985a4.57 4.57 0 00-2.864-2.326c-.935-.093-1.172.79-.53 1.98z"
              />
              <path
                  data-name="Path 3425"
                  d="M807.935 31.814a4.478 4.478 0 002.847 2.323c.943.094 1.188-.8.55-1.984a4.488 4.488 0 00-2.846-2.314c-.934-.094-1.181.788-.551 1.975z"
              />
              <path
                  data-name="Path 3426"
                  d="M802.524 31.284a4.4 4.4 0 002.829 2.311c.943.094 1.2-.8.57-1.98a4.419 4.419 0 00-2.828-2.3c-.935-.095-1.191.786-.571 1.969z"
              />
              <path
                  data-name="Path 3427"
                  d="M797.108 30.765a4.337 4.337 0 002.811 2.3c.944.092 1.206-.8.592-1.979a4.351 4.351 0 00-2.809-2.294c-.935-.086-1.202.795-.594 1.973z"
              />
              <path
                  data-name="Path 3428"
                  d="M791.687 30.258a4.266 4.266 0 002.792 2.293c.944.089 1.218-.8.613-1.975a4.276 4.276 0 00-2.789-2.286c-.935-.088-1.212.791-.616 1.968z"
              />
              <path
                  data-name="Path 3429"
                  d="M786.264 29.763a4.2 4.2 0 002.772 2.284c.946.085 1.23-.8.635-1.975a4.209 4.209 0 00-2.769-2.276c-.936-.083-1.22.796-.638 1.967z"
              />
              <path
                  data-name="Path 3430"
                  d="M780.835 29.281a4.126 4.126 0 002.752 2.274c.944.085 1.24-.8.657-1.972a4.133 4.133 0 00-2.749-2.266c-.937-.083-1.233.796-.66 1.964z"
              />
              <path
                  data-name="Path 3431"
                  d="M775.403 28.811a4.064 4.064 0 002.732 2.264c.946.082 1.25-.8.68-1.97a4.072 4.072 0 00-2.729-2.258c-.937-.08-1.246.795-.683 1.964z"
              />
              <path
                  data-name="Path 3432"
                  d="M769.967 28.351a4 4 0 002.711 2.256c.947.08 1.262-.8.7-1.968a4 4 0 00-2.709-2.248c-.935-.079-1.254.793-.702 1.96z"
              />
              <path
                  data-name="Path 3433"
                  d="M764.526 27.905a3.931 3.931 0 002.69 2.246c.949.079 1.273-.805.727-1.967a3.931 3.931 0 00-2.689-2.239c-.938-.075-1.266.799-.728 1.96z"
              />
              <path
                  data-name="Path 3434"
                  d="M759.082 27.471a3.864 3.864 0 002.67 2.238c.949.075 1.283-.807.749-1.967a3.869 3.869 0 00-2.667-2.229c-.946-.074-1.279.8-.752 1.958z"
              />
              <path
                  data-name="Path 3435"
                  d="M753.632 27.047a3.8 3.8 0 002.649 2.229c.951.074 1.3-.809.774-1.965a3.8 3.8 0 00-2.645-2.221c-.942-.071-1.29.803-.778 1.957z"
              />
              <path
                  data-name="Path 3436"
                  d="M748.181 26.637a3.726 3.726 0 002.627 2.221c.951.072 1.308-.81.8-1.964a3.726 3.726 0 00-2.625-2.211c-.944-.07-1.303.803-.802 1.954z"
              />
              <path
                  data-name="Path 3437"
                  d="M742.725 26.239a3.653 3.653 0 002.605 2.211c.951.07 1.318-.81.822-1.962a3.67 3.67 0 00-2.6-2.2c-.944-.069-1.313.801-.827 1.951z"
              />
              <path
                  data-name="Path 3438"
                  d="M720.872 24.77a3.4 3.4 0 002.515 2.177c.954.06 1.367-.821.921-1.96a3.413 3.413 0 00-2.515-2.169c-.949-.058-1.361.812-.921 1.952z"
              />
              <path
                  data-name="Path 3439"
                  d="M715.393 24.433a3.337 3.337 0 002.495 2.169c.954.058 1.375-.821.944-1.959a3.35 3.35 0 00-2.492-2.161c-.944-.056-1.37.815-.947 1.951z"
              />
              <path
                  data-name="Path 3440"
                  d="M709.917 24.108a3.275 3.275 0 002.472 2.161c.954.057 1.389-.822.969-1.958a3.286 3.286 0 00-2.468-2.152c-.947-.056-1.383.814-.973 1.949z"
              />
              <path
                  data-name="Path 3441"
                  d="M671.498 22.185a2.857 2.857 0 002.3 2.109c.959.042 1.474-.839 1.15-1.962a2.872 2.872 0 00-2.3-2.1c-.948-.039-1.463.832-1.15 1.953z"
              />
              <path
                  data-name="Path 3442"
                  d="M665.997 21.961a2.81 2.81 0 002.279 2.1c.959.038 1.486-.842 1.176-1.964a2.816 2.816 0 00-2.278-2.092c-.948-.036-1.477.836-1.177 1.956z"
              />
              <path
                  data-name="Path 3443"
                  d="M483.501 21.694c-.182 1.113.453 1.99 1.42 1.955a2.4 2.4 0 002.062-2.081c.172-1.113-.465-1.98-1.42-1.947a2.418 2.418 0 00-2.062 2.073z"
              />
              <path
                  data-name="Path 3444"
                  d="M472.413 22.128c-.211 1.115.4 1.992 1.367 1.952a2.507 2.507 0 002.116-2.094c.2-1.115-.413-1.98-1.369-1.942a2.513 2.513 0 00-2.114 2.084z"
              />
              <path
                  data-name="Path 3445"
                  d="M466.869 22.364c-.226 1.116.374 1.994 1.342 1.95a2.558 2.558 0 002.141-2.1c.216-1.116-.386-1.982-1.342-1.942a2.567 2.567 0 00-2.141 2.092z"
              />
              <path
                  data-name="Path 3446"
                  d="M461.327 22.615c-.241 1.118.348 1.992 1.315 1.948a2.609 2.609 0 002.166-2.107c.231-1.116-.358-1.982-1.315-1.938a2.616 2.616 0 00-2.166 2.097z"
              />
              <path
                  data-name="Path 3447"
                  d="M455.784 22.877c-.254 1.12.323 1.994 1.29 1.947a2.663 2.663 0 002.191-2.114c.244-1.118-.334-1.984-1.29-1.937a2.672 2.672 0 00-2.191 2.104z"
              />
              <path
                  data-name="Path 3448"
                  d="M450.242 23.151c-.269 1.121.3 2 1.263 1.947a2.716 2.716 0 002.217-2.121c.259-1.12-.307-1.985-1.263-1.937a2.725 2.725 0 00-2.217 2.111z"
              />
              <path
                  data-name="Path 3449"
                  d="M444.702 23.44c-.282 1.123.271 2 1.237 1.945a2.769 2.769 0 002.244-2.127c.272-1.123-.282-1.985-1.238-1.937a2.78 2.78 0 00-2.243 2.119z"
              />
              <path
                  data-name="Path 3450"
                  d="M439.162 23.741c-.3 1.125.244 2 1.21 1.945a2.826 2.826 0 002.269-2.136c.287-1.123-.256-1.987-1.212-1.935a2.835 2.835 0 00-2.267 2.126z"
              />
              <path
                  data-name="Path 3451"
                  d="M433.622 24.055c-.311 1.126.219 2 1.185 1.943a2.882 2.882 0 002.294-2.142c.3-1.125-.231-1.989-1.186-1.933a2.891 2.891 0 00-2.293 2.132z"
              />
              <path
                  data-name="Path 3452"
                  d="M428.083 24.383c-.324 1.128.194 2 1.16 1.943a2.939 2.939 0 002.319-2.151c.314-1.128-.206-1.99-1.161-1.933a2.951 2.951 0 00-2.318 2.141z"
              />
              <path
                  data-name="Path 3453"
                  d="M422.546 24.721c-.339 1.131.167 2 1.133 1.943a3 3 0 002.345-2.159c.329-1.128-.18-1.99-1.135-1.932a3 3 0 00-2.343 2.148z"
              />
              <path
                  data-name="Path 3454"
                  d="M417.01 25.075c-.353 1.133.142 2.005 1.108 1.943a3.058 3.058 0 002.368-2.166c.342-1.131-.154-1.994-1.11-1.933a3.065 3.065 0 00-2.366 2.156z"
              />
              <path
                  data-name="Path 3455"
                  d="M411.474 25.44c-.366 1.135.119 2.007 1.084 1.942a3.121 3.121 0 002.393-2.172c.356-1.135-.13-2-1.086-1.933a3.128 3.128 0 00-2.391 2.163z"
              />
              <path
                  data-name="Path 3456"
                  d="M405.941 25.818c-.379 1.136.094 2.01 1.058 1.943a3.182 3.182 0 002.421-2.178c.369-1.135-.105-2-1.061-1.932a3.186 3.186 0 00-2.418 2.167z"
              />
              <path
                  data-name="Path 3457"
                  d="M400.408 26.21c-.393 1.138.07 2.01 1.034 1.942a3.244 3.244 0 002.44-2.189c.384-1.138-.08-2-1.034-1.933a3.251 3.251 0 00-2.44 2.18z"
              />
              <path
                  data-name="Path 3458"
                  d="M394.878 26.613c-.408 1.143.043 2.014 1.008 1.943a3.3 3.3 0 002.465-2.2c.4-1.141-.055-2-1.009-1.933a3.308 3.308 0 00-2.464 2.19z"
              />
              <path
                  data-name="Path 3459"
                  d="M389.345 27.031c-.421 1.145.02 2.015.984 1.942a3.367 3.367 0 002.488-2.206c.409-1.141-.032-2.005-.986-1.933a3.373 3.373 0 00-2.486 2.197z"
              />
              <path
                  data-name="Path 3460"
                  d="M383.822 27.461c-.433 1.146 0 2.019.959 1.943a3.432 3.432 0 002.512-2.214c.424-1.145-.007-2.009-.961-1.935a3.446 3.446 0 00-2.51 2.206z"
              />
              <path
                  data-name="Path 3461"
                  d="M378.299 27.902c-.446 1.148-.028 2.02.934 1.943a3.5 3.5 0 002.535-2.223c.438-1.148.017-2.012-.936-1.935a3.5 3.5 0 00-2.533 2.215z"
              />
              <path
                  data-name="Path 3462"
                  d="M372.772 28.359c-.46 1.153-.052 2.025.911 1.945a3.562 3.562 0 002.558-2.233c.45-1.15.04-2.014-.912-1.935a3.573 3.573 0 00-2.557 2.223z"
              />
              <path
                  data-name="Path 3463"
                  d="M367.255 28.826c-.473 1.155-.075 2.029.887 1.945a3.621 3.621 0 002.58-2.239c.463-1.153.065-2.017-.889-1.937a3.634 3.634 0 00-2.578 2.231z"
              />
              <path
                  data-name="Path 3464"
                  d="M361.735 29.307c-.485 1.158-.1 2.03.864 1.947a3.692 3.692 0 002.6-2.251c.476-1.155.089-2.02-.864-1.937a3.7 3.7 0 00-2.6 2.241z"
              />
              <path
                  data-name="Path 3465"
                  d="M350.696 30.307c-.51 1.163-.145 2.039.817 1.948a3.827 3.827 0 002.647-2.268c.5-1.161.134-2.027-.817-1.94a3.842 3.842 0 00-2.647 2.26z"
              />
              <path
                  data-name="Path 3466"
                  d="M345.187 30.826c-.523 1.165-.169 2.04.792 1.948a3.889 3.889 0 002.669-2.276c.513-1.163.157-2.03-.794-1.942a3.908 3.908 0 00-2.667 2.27z"
              />
              <path
                  data-name="Path 3467"
                  d="M334.169 31.902c-.546 1.171-.212 2.047.747 1.952a4.042 4.042 0 002.712-2.3c.536-1.17.2-2.039-.749-1.945a4.045 4.045 0 00-2.71 2.293z"
              />
              <path
                  data-name="Path 3468"
                  d="M328.666 32.459c-.558 1.175-.234 2.052.724 1.953a4.107 4.107 0 002.734-2.3c.55-1.173.224-2.042-.727-1.947a4.113 4.113 0 00-2.731 2.294z"
              />
              <path
                  data-name="Path 3469"
                  d="M323.164 33.027c-.57 1.178-.257 2.057.7 1.957a4.18 4.18 0 002.752-2.314c.561-1.176.247-2.047-.7-1.948a4.181 4.181 0 00-2.752 2.305z"
              />
              <path
                  data-name="Path 3470"
                  d="M317.665 33.61c-.582 1.18-.277 2.059.68 1.957a4.245 4.245 0 002.774-2.324c.573-1.178.267-2.05-.682-1.95a4.254 4.254 0 00-2.772 2.317z"
              />
              <path
                  data-name="Path 3471"
                  d="M312.17 34.203c-.593 1.185-.3 2.064.657 1.96a4.319 4.319 0 002.794-2.335c.585-1.181.289-2.054-.66-1.952a4.322 4.322 0 00-2.791 2.327z"
              />
              <path
                  data-name="Path 3472"
                  d="M306.678 34.812c-.6 1.188-.321 2.067.637 1.96a4.392 4.392 0 002.812-2.343c.6-1.186.311-2.059-.638-1.955a4.4 4.4 0 00-2.811 2.338z"
              />
              <path
                  data-name="Path 3473"
                  d="M295.701 36.064c-.627 1.195-.361 2.077.595 1.965a4.531 4.531 0 002.851-2.365c.62-1.191.351-2.067-.595-1.958a4.543 4.543 0 00-2.851 2.358z"
              />
              <path
                  data-name="Path 3474"
                  d="M799.029 24.64a4.362 4.362 0 002.8 2.279c.932.09 1.181-.79.555-1.96a4.363 4.363 0 00-2.8-2.273c-.921-.09-1.172.786-.555 1.954z"
              />
              <path
                  data-name="Path 3475"
                  d="M793.676 24.14a4.3 4.3 0 002.786 2.271c.931.087 1.19-.792.577-1.958a4.3 4.3 0 00-2.782-2.263c-.925-.085-1.186.786-.581 1.95z"
              />
              <path
                  data-name="Path 3476"
                  d="M788.321 23.652a4.232 4.232 0 002.766 2.261c.934.085 1.2-.792.6-1.955a4.23 4.23 0 00-2.762-2.253c-.929-.082-1.198.787-.604 1.947z"
              />
              <path
                  data-name="Path 3477"
                  d="M782.961 23.177a4.157 4.157 0 002.746 2.249c.936.085 1.211-.794.622-1.952a4.164 4.164 0 00-2.744-2.243c-.93-.081-1.206.788-.624 1.946z"
              />
              <path
                  data-name="Path 3478"
                  d="M777.601 22.713a4.094 4.094 0 002.724 2.241c.936.08 1.222-.8.643-1.95a4.088 4.088 0 00-2.724-2.233c-.926-.08-1.213.789-.643 1.942z"
              />
              <path
                  data-name="Path 3479"
                  d="M772.234 22.26a4.02 4.02 0 002.7 2.231c.936.079 1.233-.8.665-1.948a4.021 4.021 0 00-2.7-2.223c-.924-.074-1.225.789-.665 1.94z"
              />
              <path
                  data-name="Path 3480"
                  d="M761.493 21.39a3.881 3.881 0 002.659 2.209c.938.074 1.255-.8.71-1.943a3.888 3.888 0 00-2.66-2.2c-.925-.073-1.242.791-.709 1.934z"
              />
              <path
                  data-name="Path 3481"
                  d="M750.742 20.57a3.754 3.754 0 002.622 2.192c.937.072 1.275-.8.757-1.94a3.754 3.754 0 00-2.62-2.184c-.931-.068-1.272.791-.759 1.932z"
              />
              <path
                  data-name="Path 3482"
                  d="M745.359 20.174a3.682 3.682 0 002.6 2.184c.937.069 1.285-.8.78-1.938a3.688 3.688 0 00-2.6-2.176c-.926-.067-1.278.795-.78 1.93z"
              />
              <path
                  data-name="Path 3483"
                  d="M739.975 19.791a3.616 3.616 0 002.577 2.176c.939.067 1.3-.8.806-1.937a3.626 3.626 0 00-2.577-2.167c-.93-.066-1.291.797-.806 1.928z"
              />
              <path
                  data-name="Path 3484"
                  d="M702.205 17.446a3.171 3.171 0 002.418 2.117c.943.052 1.379-.815.976-1.93a3.18 3.18 0 00-2.418-2.107c-.931-.05-1.369.805-.976 1.92z"
              />
              <path
                  data-name="Path 3485"
                  d="M696.797 17.159a3.115 3.115 0 002.4 2.109c.943.05 1.389-.815 1-1.93a3.125 3.125 0 00-2.395-2.1c-.935-.048-1.381.81-1.005 1.921z"
              />
              <path
                  data-name="Path 3486"
                  d="M685.977 16.621a3 3 0 002.348 2.094c.942.045 1.414-.82 1.051-1.93a3 3 0 00-2.346-2.084c-.933-.044-1.406.813-1.053 1.92z"
              />
              <path
                  data-name="Path 3487"
                  d="M680.563 16.37a2.938 2.938 0 002.324 2.086c.943.043 1.425-.824 1.076-1.93a2.945 2.945 0 00-2.321-2.075c-.935-.045-1.416.812-1.079 1.919z"
              />
              <path
                  data-name="Path 3488"
                  d="M675.147 16.129a2.875 2.875 0 002.3 2.079c.944.042 1.439-.824 1.1-1.93a2.886 2.886 0 00-2.3-2.067c-.931-.042-1.424.815-1.1 1.918z"
              />
              <path
                  data-name="Path 3489"
                  d="M473.602 15.88c-.211 1.095.389 1.958 1.344 1.918a2.466 2.466 0 002.087-2.055c.2-1.095-.4-1.947-1.343-1.908a2.475 2.475 0 00-2.088 2.045z"
              />
              <path
                  data-name="Path 3490"
                  d="M468.139 16.109c-.224 1.1.366 1.958 1.317 1.917a2.517 2.517 0 002.114-2.062c.216-1.1-.376-1.947-1.317-1.908a2.529 2.529 0 00-2.114 2.053z"
              />
              <path
                  data-name="Path 3491"
                  d="M462.679 16.35c-.239 1.1.338 1.96 1.29 1.917a2.575 2.575 0 002.141-2.07c.227-1.1-.351-1.947-1.293-1.907a2.579 2.579 0 00-2.138 2.06z"
              />
              <path
                  data-name="Path 3492"
                  d="M457.215 16.602c-.254 1.1.314 1.962 1.265 1.917a2.623 2.623 0 002.166-2.077c.242-1.1-.324-1.948-1.267-1.905a2.633 2.633 0 00-2.164 2.065z"
              />
              <path
                  data-name="Path 3493"
                  d="M451.755 16.868c-.267 1.1.286 1.962 1.24 1.915a2.677 2.677 0 002.189-2.082c.257-1.1-.3-1.952-1.24-1.905a2.684 2.684 0 00-2.189 2.072z"
              />
              <path
                  data-name="Path 3494"
                  d="M446.292 17.147c-.281 1.1.262 1.964 1.215 1.913a2.732 2.732 0 002.216-2.09c.271-1.1-.274-1.952-1.215-1.9a2.742 2.742 0 00-2.216 2.077z"
              />
              <path
                  data-name="Path 3495"
                  d="M440.831 17.438c-.294 1.106.237 1.965 1.19 1.913a2.788 2.788 0 002.241-2.1c.286-1.1-.247-1.953-1.19-1.905a2.8 2.8 0 00-2.241 2.092z"
              />
              <path
                  data-name="Path 3496"
                  d="M435.371 17.74c-.311 1.108.211 1.967 1.163 1.913a2.843 2.843 0 002.266-2.106c.3-1.106-.222-1.955-1.165-1.9a2.855 2.855 0 00-2.264 2.093z"
              />
              <path
                  data-name="Path 3497"
                  d="M429.911 18.056c-.323 1.11.187 1.969 1.138 1.913a2.9 2.9 0 002.291-2.112c.314-1.108-.2-1.958-1.138-1.9a2.91 2.91 0 00-2.291 2.099z"
              />
              <path
                  data-name="Path 3498"
                  d="M424.451 18.384c-.336 1.113.162 1.972 1.113 1.913a2.959 2.959 0 002.316-2.121c.328-1.11-.172-1.96-1.113-1.9a2.971 2.971 0 00-2.316 2.108z"
              />
              <path
                  data-name="Path 3499"
                  d="M418.994 18.724c-.351 1.115.135 1.974 1.088 1.913a3.017 3.017 0 002.339-2.129c.341-1.111-.147-1.962-1.088-1.9a3.026 3.026 0 00-2.339 2.116z"
              />
              <path
                  data-name="Path 3500"
                  d="M413.535 19.077c-.364 1.116.11 1.977 1.063 1.913a3.077 3.077 0 002.365-2.136c.354-1.116-.124-1.965-1.064-1.9a3.085 3.085 0 00-2.364 2.123z"
              />
              <path
                  data-name="Path 3501"
                  d="M408.078 19.443c-.378 1.118.087 1.979 1.038 1.913a3.139 3.139 0 002.39-2.144c.368-1.118-.1-1.967-1.039-1.905a3.149 3.149 0 00-2.389 2.136z"
              />
              <path
                  data-name="Path 3502"
                  d="M402.621 19.82c-.391 1.121.062 1.98 1.013 1.915a3.207 3.207 0 002.415-2.154c.381-1.12-.074-1.969-1.014-1.905a3.21 3.21 0 00-2.414 2.144z"
              />
              <path
                  data-name="Path 3503"
                  d="M397.166 20.212c-.4 1.123.038 1.984.989 1.913a3.263 3.263 0 002.438-2.161c.394-1.121-.05-1.972-.991-1.905a3.274 3.274 0 00-2.436 2.153z"
              />
              <path
                  data-name="Path 3504"
                  d="M391.713 20.614c-.418 1.126.013 1.987.964 1.915a3.327 3.327 0 002.46-2.171c.409-1.125-.023-1.975-.964-1.905a3.332 3.332 0 00-2.46 2.161z"
              />
              <path
                  data-name="Path 3505"
                  d="M386.26 21.029c-.431 1.13-.012 1.99.939 1.917a3.393 3.393 0 002.485-2.179c.421-1.128 0-1.979-.941-1.907a3.4 3.4 0 00-2.483 2.169z"
              />
              <path
                  data-name="Path 3506"
                  d="M380.808 21.457c-.445 1.131-.033 1.994.916 1.917a3.455 3.455 0 002.508-2.187c.434-1.13.023-1.982-.917-1.908a3.464 3.464 0 00-2.507 2.178z"
              />
              <path
                  data-name="Path 3507"
                  d="M375.357 21.901c-.456 1.135-.058 2 .892 1.918a3.525 3.525 0 002.532-2.2c.448-1.133.047-1.985-.894-1.91a3.534 3.534 0 00-2.53 2.192z"
              />
              <path
                  data-name="Path 3508"
                  d="M369.909 22.348c-.47 1.138-.082 2 .867 1.92a3.585 3.585 0 002.555-2.2c.46-1.136.07-1.989-.871-1.912a3.6 3.6 0 00-2.551 2.192z"
              />
              <path
                  data-name="Path 3509"
                  d="M359.015 23.29c-.495 1.145-.127 2.007.822 1.922a3.721 3.721 0 002.6-2.223c.485-1.141.115-2-.824-1.915a3.732 3.732 0 00-2.598 2.216z"
              />
              <path
                  data-name="Path 3510"
                  d="M353.571 23.779c-.508 1.146-.15 2.01.8 1.923a3.789 3.789 0 002.62-2.233c.5-1.145.14-2-.8-1.917a3.8 3.8 0 00-2.62 2.227z"
              />
              <path
                  data-name="Path 3511"
                  d="M348.128 24.281c-.52 1.15-.172 2.014.775 1.925a3.851 3.851 0 002.642-2.243c.511-1.146.164-2-.777-1.917a3.862 3.862 0 00-2.64 2.235z"
              />
              <path
                  data-name="Path 3512"
                  d="M342.687 24.794c-.531 1.153-.2 2.02.754 1.928a3.928 3.928 0 002.664-2.253c.523-1.151.184-2.007-.754-1.92a3.936 3.936 0 00-2.664 2.245z"
              />
              <path
                  data-name="Path 3513"
                  d="M337.247 25.32c-.543 1.156-.217 2.024.73 1.93a3.993 3.993 0 002.685-2.261c.536-1.155.207-2.012-.732-1.922a4 4 0 00-2.683 2.253z"
              />
              <path
                  data-name="Path 3514"
                  d="M331.812 25.861c-.556 1.161-.239 2.027.707 1.933a4.068 4.068 0 002.707-2.273c.546-1.158.229-2.015-.71-1.923a4.068 4.068 0 00-2.704 2.263z"
              />
              <path
                  data-name="Path 3515"
                  d="M326.376 26.409c-.566 1.163-.261 2.032.687 1.935a4.139 4.139 0 002.727-2.283c.558-1.16.251-2.02-.687-1.927a4.147 4.147 0 00-2.727 2.275z"
              />
              <path
                  data-name="Path 3516"
                  d="M779.678 16.679a4.115 4.115 0 002.719 2.216c.924.08 1.193-.787.607-1.932a4.118 4.118 0 00-2.716-2.207c-.914-.077-1.188.782-.61 1.923z"
              />
              <path
                  data-name="Path 3517"
                  d="M774.381 16.235a4.044 4.044 0 002.7 2.206c.924.077 1.2-.787.628-1.928a4.046 4.046 0 00-2.7-2.2c-.912-.072-1.195.783-.628 1.922z"
              />
              <path
                  data-name="Path 3518"
                  d="M769.083 15.801a3.982 3.982 0 002.679 2.2c.922.075 1.213-.789.65-1.925a3.979 3.979 0 00-2.675-2.187c-.915-.078-1.209.773-.654 1.912z"
              />
              <path
                  data-name="Path 3519"
                  d="M731.925 13.086a3.505 3.505 0 002.527 2.129c.927.062 1.29-.8.812-1.908a3.516 3.516 0 00-2.527-2.121c-.917-.06-1.281.793-.812 1.9z"
              />
              <path
                  data-name="Path 3520"
                  d="M582.291 7.851a2.015 2.015 0 001.842 1.938 1.641 1.641 0 001.529-1.922 2.022 2.022 0 00-1.841-1.928 1.644 1.644 0 00-1.53 1.912z"
              />
              <path
                  data-name="Path 3521"
                  d="M571.56 7.826a1.939 1.939 0 001.79 1.932 1.7 1.7 0 001.582-1.928 1.944 1.944 0 00-1.79-1.92 1.7 1.7 0 00-1.582 1.916z"
              />
              <path
                  data-name="Path 3522"
                  d="M566.192 7.833a1.9 1.9 0 001.763 1.927 1.735 1.735 0 001.609-1.932 1.907 1.907 0 00-1.763-1.917 1.737 1.737 0 00-1.609 1.922z"
              />
              <path
                  data-name="Path 3523"
                  d="M485.547 9.338c-.18 1.071.433 1.92 1.372 1.887a2.331 2.331 0 002.007-2c.17-1.071-.443-1.908-1.372-1.877a2.341 2.341 0 00-2.007 1.99z"
              />
              <path
                  data-name="Path 3524"
                  d="M480.165 9.537c-.2 1.073.408 1.92 1.345 1.887a2.383 2.383 0 002.034-2.012c.186-1.071-.418-1.908-1.345-1.875a2.389 2.389 0 00-2.034 2z"
              />
              <path
                  data-name="Path 3525"
                  d="M474.785 9.744c-.211 1.076.381 1.922 1.32 1.885a2.432 2.432 0 002.059-2.017c.2-1.075-.393-1.91-1.32-1.873a2.438 2.438 0 00-2.059 2.005z"
              />
              <path
                  data-name="Path 3526"
                  d="M469.403 9.964c-.224 1.076.356 1.923 1.3 1.883a2.48 2.48 0 002.086-2.024c.212-1.075-.366-1.912-1.3-1.873a2.49 2.49 0 00-2.086 2.014z"
              />
              <path
                  data-name="Path 3527"
                  d="M464.022 10.195c-.239 1.079.329 1.925 1.268 1.883a2.53 2.53 0 002.111-2.03c.229-1.076-.341-1.912-1.268-1.872a2.541 2.541 0 00-2.111 2.019z"
              />
              <path
                  data-name="Path 3528"
                  d="M458.639 10.44c-.252 1.081.3 1.927 1.243 1.883a2.588 2.588 0 002.137-2.037c.241-1.08-.316-1.915-1.243-1.873a2.6 2.6 0 00-2.137 2.027z"
              />
              <path
                  data-name="Path 3529"
                  d="M453.257 10.694c-.266 1.083.279 1.928 1.218 1.883a2.642 2.642 0 002.162-2.045c.256-1.079-.291-1.915-1.218-1.872a2.649 2.649 0 00-2.162 2.034z"
              />
              <path
                  data-name="Path 3530"
                  d="M447.876 10.962c-.281 1.085.252 1.93 1.191 1.883a2.694 2.694 0 002.187-2.052c.271-1.083-.264-1.918-1.193-1.872a2.7 2.7 0 00-2.185 2.041z"
              />
              <path
                  data-name="Path 3531"
                  d="M442.494 11.243c-.294 1.085.227 1.932 1.166 1.882a2.751 2.751 0 002.214-2.059c.284-1.086-.241-1.92-1.168-1.872a2.752 2.752 0 00-2.212 2.049z"
              />
              <path
                  data-name="Path 3532"
                  d="M437.111 11.533c-.308 1.088.2 1.933 1.141 1.882a2.8 2.8 0 002.239-2.065c.3-1.088-.216-1.922-1.143-1.872a2.811 2.811 0 00-2.237 2.055z"
              />
              <path
                  data-name="Path 3533"
                  d="M431.729 11.838c-.321 1.09.179 1.935 1.118 1.882a2.862 2.862 0 002.263-2.074c.313-1.09-.189-1.925-1.118-1.873a2.876 2.876 0 00-2.263 2.065z"
              />
              <path
                  data-name="Path 3534"
                  d="M426.348 12.152c-.336 1.093.154 1.938 1.091 1.883a2.925 2.925 0 002.289-2.082c.324-1.091-.165-1.927-1.093-1.872a2.925 2.925 0 00-2.287 2.071z"
              />
              <path
                  data-name="Path 3535"
                  d="M420.967 12.48c-.349 1.1.127 1.942 1.066 1.883a2.978 2.978 0 002.313-2.091c.339-1.093-.139-1.928-1.068-1.872a2.983 2.983 0 00-2.311 2.08z"
              />
              <path
                  data-name="Path 3536"
                  d="M415.584 12.818c-.361 1.1.105 1.945 1.043 1.885a3.041 3.041 0 002.338-2.1c.353-1.1-.115-1.932-1.044-1.873a3.047 3.047 0 00-2.337 2.088z"
              />
              <path
                  data-name="Path 3537"
                  d="M399.443 13.91c-.4 1.1.03 1.952.969 1.885a3.218 3.218 0 002.41-2.122c.393-1.1-.042-1.942-.971-1.877a3.232 3.232 0 00-2.408 2.114z"
              />
              <path
                  data-name="Path 3538"
                  d="M394.064 14.297c-.416 1.108.007 1.955.944 1.887a3.285 3.285 0 002.431-2.132c.406-1.106-.018-1.943-.948-1.878a3.294 3.294 0 00-2.427 2.123z"
              />
              <path
                  data-name="Path 3539"
                  d="M388.685 14.695c-.428 1.111-.017 1.96.921 1.89a3.353 3.353 0 002.458-2.141c.419-1.11.005-1.948-.922-1.88a3.362 3.362 0 00-2.457 2.131z"
              />
              <path
                  data-name="Path 3540"
                  d="M755.483 8.644a3.793 3.793 0 002.609 2.139c.912.069 1.218-.782.68-1.895a3.792 3.792 0 00-2.605-2.129c-.905-.066-1.211.776-.684 1.885z"
              />
              <path
                  data-name="Path 3541"
                  d="M729.28 6.855a3.459 3.459 0 002.5 2.09c.914.059 1.27-.785.794-1.882a3.467 3.467 0 00-2.5-2.08c-.901-.057-1.259.779-.794 1.872z"
              />
              <path
                  data-name="Path 3542"
                  d="M718.787 6.215a3.331 3.331 0 002.453 2.072c.916.055 1.292-.787.842-1.877a3.34 3.34 0 00-2.451-2.062c-.906-.052-1.284.785-.844 1.867z"
              />
              <path
                  data-name="Path 3543"
                  d="M692.526 4.806a3.03 3.03 0 002.338 2.03c.917.045 1.347-.794.963-1.868a3.028 3.028 0 00-2.336-2.02c-.906-.044-1.339.785-.965 1.858z"
              />
              <path
                  data-name="Path 3544"
                  d="M687.268 4.557a2.974 2.974 0 002.314 2.022c.917.043 1.359-.8.988-1.867a2.973 2.973 0 00-2.313-2.012c-.905-.041-1.35.786-.989 1.857z"
              />
              <path
                  data-name="Path 3545"
                  d="M682.012 4.318a2.908 2.908 0 002.289 2.015c.919.042 1.37-.8 1.013-1.867a2.918 2.918 0 00-2.288-2c-.907-.043-1.362.785-1.014 1.852z"
              />
              <path
                  data-name="Path 3546"
                  d="M676.751 4.092a2.858 2.858 0 002.266 2.007c.917.04 1.382-.8 1.038-1.867a2.86 2.86 0 00-2.264-2c-.909-.033-1.374.794-1.04 1.86z"
              />
              <path
                  data-name="Path 3547"
                  d="M671.49 3.876a2.8 2.8 0 002.241 2c.919.037 1.394-.8 1.063-1.865a2.8 2.8 0 00-2.239-1.989c-.909-.036-1.383.791-1.065 1.854z"
              />
              <path
                  data-name="Path 3548"
                  d="M660.963 3.478a2.688 2.688 0 002.194 1.985c.917.033 1.415-.8 1.113-1.865a2.691 2.691 0 00-2.192-1.974c-.908-.033-1.408.794-1.115 1.854z"
              />
              <path
                  data-name="Path 3549"
                  d="M655.698 3.294a2.632 2.632 0 002.169 1.979c.919.033 1.427-.807 1.138-1.865a2.638 2.638 0 00-2.167-1.967c-.908-.031-1.419.796-1.14 1.853z"
              />
              <path
                  data-name="Path 3550"
                  d="M650.428 3.123a2.578 2.578 0 002.144 1.97c.919.03 1.44-.807 1.165-1.865a2.59 2.59 0 00-2.142-1.96c-.907-.028-1.428.799-1.167 1.855z"
              />
              <path
                  data-name="Path 3551"
                  d="M645.161 2.961a2.527 2.527 0 002.119 1.965c.919.028 1.452-.81 1.192-1.865a2.537 2.537 0 00-2.119-1.953c-.91-.026-1.439.799-1.192 1.853z"
              />
              <path
                  data-name="Path 3552"
                  d="M608.244 2.148a2.191 2.191 0 001.943 1.923 1.476 1.476 0 001.372-1.873 2.2 2.2 0 00-1.942-1.912 1.477 1.477 0 00-1.373 1.862z"
              />
              <path
                  data-name="Path 3553"
                  d="M597.684 2.014a2.1 2.1 0 001.892 1.915 1.527 1.527 0 001.425-1.877 2.112 2.112 0 00-1.892-1.9 1.529 1.529 0 00-1.425 1.862z"
              />
              <path
                  data-name="Path 3554"
                  d="M587.12 1.927a2.024 2.024 0 001.84 1.905 1.582 1.582 0 001.479-1.882 2.028 2.028 0 00-1.84-1.893 1.583 1.583 0 00-1.479 1.87z"
              />
              <path
                  data-name="Path 3555"
                  d="M571.263 1.878a1.906 1.906 0 001.763 1.892 1.669 1.669 0 001.557-1.89A1.908 1.908 0 00572.822 0a1.673 1.673 0 00-1.559 1.878z"
              />
              <path
                  data-name="Path 3556"
                  d="M502.46 2.844a1.5 1.5 0 001.425 1.857 2.164 2.164 0 001.9-1.947A1.5 1.5 0 00504.36.909a2.165 2.165 0 00-1.9 1.935z"
              />
              <path
                  data-name="Path 3557"
                  d="M497.161 2.998a1.474 1.474 0 001.4 1.855 2.206 2.206 0 001.928-1.953 1.475 1.475 0 00-1.4-1.843 2.21 2.21 0 00-1.928 1.941z"
              />
              <path
                  data-name="Path 3558"
                  d="M486.562 3.339a1.427 1.427 0 001.349 1.852 2.3 2.3 0 001.98-1.963 1.428 1.428 0 00-1.349-1.842 2.307 2.307 0 00-1.98 1.953z"
              />
              <path
                  data-name="Path 3559"
                  d="M465.357 4.163c-.237 1.058.321 1.888 1.247 1.85a2.5 2.5 0 002.084-1.99c.226-1.056-.334-1.877-1.248-1.838a2.5 2.5 0 00-2.083 1.978z"
              />
              <path
                  data-name="Path 3560"
                  d="M460.054 4.391c-.251 1.059.3 1.89 1.22 1.848a2.547 2.547 0 002.111-2c.239-1.058-.308-1.878-1.222-1.838a2.557 2.557 0 00-2.109 1.99z"
              />
              <path
                  data-name="Path 3561"
                  d="M454.747 4.636c-.264 1.061.272 1.893 1.2 1.85a2.6 2.6 0 002.136-2.005c.252-1.059-.282-1.88-1.2-1.837a2.606 2.606 0 00-2.136 1.992z"
              />
              <path
                  data-name="Path 3562"
                  d="M449.449 4.891c-.279 1.064.246 1.895 1.17 1.85a2.652 2.652 0 002.161-2.012c.269-1.061-.257-1.882-1.171-1.837a2.659 2.659 0 00-2.16 1.999z"
              />
              <path
                  data-name="Path 3563"
                  d="M444.145 5.16c-.292 1.064.221 1.9 1.146 1.848a2.71 2.71 0 002.186-2.019c.281-1.064-.234-1.883-1.148-1.838a2.718 2.718 0 00-2.184 2.009z"
              />
              <path
                  data-name="Path 3564"
                  d="M438.841 5.439c-.306 1.068.2 1.9 1.121 1.85a2.769 2.769 0 002.211-2.027c.294-1.066-.209-1.887-1.123-1.838a2.768 2.768 0 00-2.209 2.015z"
              />
              <path
                  data-name="Path 3565"
                  d="M428.233 6.031c-.334 1.073.145 1.905 1.071 1.852a2.878 2.878 0 002.259-2.042c.324-1.071-.157-1.892-1.071-1.84a2.883 2.883 0 00-2.259 2.03z"
              />
              <path
                  data-name="Path 3566"
                  d="M422.927 6.345c-.346 1.076.122 1.907 1.048 1.852a2.938 2.938 0 002.284-2.05c.336-1.073-.134-1.9-1.048-1.842a2.948 2.948 0 00-2.284 2.04z"
              />
            </g>
          </g>
        </g>
        <g data-name="Component 2 \u2013 13" ref={referenceRef1} id="comp1"
           onMouseEnter={() => handleMouseEnter("Belgium", 1)} onMouseOut={() => handleMouseOut()} transform="translate(530, 69.535)"
           onClick={() => handleClick("Belgium", 1)}>
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 22 \u2013 13" ref={referenceRef11} id="comp11"
           onMouseEnter={() => handleMouseEnter("Belgium2", 11)} onMouseOut={() => handleMouseOut()} transform="translate(525, 89)"
           onClick={() => handleClick("Belgium2", 11)}>
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 14" ref={referenceRef2} id="comp2"
           onMouseEnter={() => handleMouseEnter("Germany", 2)} onMouseOut={() => handleMouseOut()} transform="translate(563.263 65.535)"
           onClick={() => handleClick("Germany", 2)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 14" ref={referenceRef30} id="comp2"
           onMouseEnter={() => handleMouseEnter("Croatia", 30)} onMouseOut={() => handleMouseOut()} transform="translate(565.263 85.535)"
           onClick={() => handleClick("Croatia", 30)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 93" ref={referenceRef3} id="comp3"
           onMouseEnter={() => handleMouseEnter("Romania", 3)} onMouseOut={() => handleMouseOut()} transform="translate(600 93.535)"
           onClick={() => handleClick("Romania", 3)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 16" ref={referenceRef5} id="comp5"
           onMouseEnter={() => handleMouseEnter("China", 5)} onMouseOut={() => handleMouseOut()} transform="translate(889 150.535)"
           onClick={() => handleClick("China", 5)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 18" ref={referenceRef7} id="comp7"
           onMouseEnter={() => handleMouseEnter("Mexico", 7)} onMouseOut={() => handleMouseOut()} transform="translate(181.263 211.535)"
           onClick={() => handleClick("Mexico", 7)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
        <g data-name="Component 2 \u2013 18" ref={referenceRef8} id="comp8"
           onMouseEnter={() => handleMouseEnter("USA", 8)} onMouseOut={() => handleMouseOut()} transform="translate(260 150)"
           onClick={() => handleClick("USA", 8)}
        >
          <ellipse id="Ellipse_15" data-name="Ellipse 15" className="bordeauDot" cx="34.8" cy="42.27" rx="34.8" ry="42.27" transform="scale(0.3)"/>
        </g>
      </g>
    </svg>
  </SVGContainer>

    {showPopper ?
        displayTooltip()
        : null
    }
  </>
  );
}

const ForwardRef = React.forwardRef(SvgBmtDriveSolutionsGlobe);
export default ForwardRef;

