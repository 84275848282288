import React, {useState, useEffect} from 'react';
import Header from './components/header';
import Footer from './components/footer'
import styles from './css/subpages.module.css';
import loc from './css/home.module.css';
import con from './css/contact.module.css'
import './css/bootstrap-overriding.css'
import getData from './utils/fetchdata'
import { useMediaQuery } from 'react-responsive';
import { useForm } from "react-hook-form";
import * as emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";


const Page = () => {
const recaptchaRef = React.useRef();

const { register, handleSubmit, errors, reset } = useForm();
const [send, setSend] = useState(false)

const onSubmit = async (data, e) => {
//const token = await recaptchaRef.current.executeAsync();    

emailjs.send("service_bk7n56q", "template_d5empni", data, "user_emxSBMPWRxg8uYuDpPHNm")
reset({
  name: "", company: "", function: "", country: "", phone: "", email: "", remarks: ""
})
setSend(true)
}

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
   		name:'', streetF:'', streetno:'', zip:'', cityF:'', countryF:'', emailF:'',
   		phoneF:'', bmt_aerospace_logoF:'', bmt_drive_logo:'', omco_logoF:'',
   		aerospaceAlt: '', driveAlt: '', omcoAlt:'', omcoLink: '', driveLink: '', aerospaceLink: ''	
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

useEffect(() => {
const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/contact.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    { 
    	return data[0].acf
    }
  })

  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/generaldata.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      return data[0].acf
    }})

  setState({...resp1, ...resp2})
}

fetchData();
setLoading(true)
}, [])

return(
<>
<Header isDesktop={isDesktop}/>
{ loading &&
<>
<section className={con.containerIpad}>
<div className="container-fluid mb-5">
	<div className={styles.fullpageContact}></div>
	<div className="row">
		<div className="col-md-12">
			<div className={` ${con.contact} d-flex flex-row justify-content-center mt-5`}>
				<h1 className={`${styles.title} ${styles.blue}`}>
					Contact us
				</h1>
			</div>

			<div className="mt-4">
				<div className={`row justify-content-md-center ${con.toColumn} ${con.formStyle}`}>
					<div className="col">
						<form>
						  <div className="form-group">
						    <input ref={register({ required: true })} type="text" className={`form-control`} name="name" id="name" placeholder="Name*"/>
						  </div>
						  <div className="form-group form-row">
						  	<div className="col-md-8">
						    	<input ref={register({ required: true })} type="text" className={`form-control ${con.adjustComp}`} name="company" id="company" placeholder="Company*"/>
						    </div>
						    <div className="col-md-4">
						    	<input ref={register} type="text" className={`form-control ${con.adjustFunc}`} name="function" id="function" placeholder="Function"/>
						  	</div>
						  </div>
						  <div className={`form-group`}>
						      <select ref={register({ required: true })} name="country" id="country" className="form-control customDropdown">
						        <option defaultValue="Country">Country*</option>
						        <option value="Afganistan">Afghanistan</option>
							   <option value="Albania">Albania</option>
							   <option value="Algeria">Algeria</option>
							   <option value="American Samoa">American Samoa</option>
							   <option value="Andorra">Andorra</option>
							   <option value="Angola">Angola</option>
							   <option value="Anguilla">Anguilla</option>
							   <option value="Antigua & Barbuda">Antigua & Barbuda</option>
							   <option value="Argentina">Argentina</option>
							   <option value="Armenia">Armenia</option>
							   <option value="Aruba">Aruba</option>
							   <option value="Australia">Australia</option>
							   <option value="Austria">Austria</option>
							   <option value="Azerbaijan">Azerbaijan</option>
							   <option value="Bahamas">Bahamas</option>
							   <option value="Bahrain">Bahrain</option>
							   <option value="Bangladesh">Bangladesh</option>
							   <option value="Barbados">Barbados</option>
							   <option value="Belarus">Belarus</option>
							   <option value="Belgium">Belgium</option>
							   <option value="Belize">Belize</option>
							   <option value="Benin">Benin</option>
							   <option value="Bermuda">Bermuda</option>
							   <option value="Bhutan">Bhutan</option>
							   <option value="Bolivia">Bolivia</option>
							   <option value="Bonaire">Bonaire</option>
							   <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
							   <option value="Botswana">Botswana</option>
							   <option value="Brazil">Brazil</option>
							   <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
							   <option value="Brunei">Brunei</option>
							   <option value="Bulgaria">Bulgaria</option>
							   <option value="Burkina Faso">Burkina Faso</option>
							   <option value="Burundi">Burundi</option>
							   <option value="Cambodia">Cambodia</option>
							   <option value="Cameroon">Cameroon</option>
							   <option value="Canada">Canada</option>
							   <option value="Canary Islands">Canary Islands</option>
							   <option value="Cape Verde">Cape Verde</option>
							   <option value="Cayman Islands">Cayman Islands</option>
							   <option value="Central African Republic">Central African Republic</option>
							   <option value="Chad">Chad</option>
							   <option value="Channel Islands">Channel Islands</option>
							   <option value="Chile">Chile</option>
							   <option value="China">China</option>
							   <option value="Christmas Island">Christmas Island</option>
							   <option value="Cocos Island">Cocos Island</option>
							   <option value="Colombia">Colombia</option>
							   <option value="Comoros">Comoros</option>
							   <option value="Congo">Congo</option>
							   <option value="Cook Islands">Cook Islands</option>
							   <option value="Costa Rica">Costa Rica</option>
							   <option value="Cote DIvoire">Cote DIvoire</option>
							   <option value="Croatia">Croatia</option>
							   <option value="Cuba">Cuba</option>
							   <option value="Curaco">Curacao</option>
							   <option value="Cyprus">Cyprus</option>
							   <option value="Czech Republic">Czech Republic</option>
							   <option value="Denmark">Denmark</option>
							   <option value="Djibouti">Djibouti</option>
							   <option value="Dominica">Dominica</option>
							   <option value="Dominican Republic">Dominican Republic</option>
							   <option value="East Timor">East Timor</option>
							   <option value="Ecuador">Ecuador</option>
							   <option value="Egypt">Egypt</option>
							   <option value="El Salvador">El Salvador</option>
							   <option value="Equatorial Guinea">Equatorial Guinea</option>
							   <option value="Eritrea">Eritrea</option>
							   <option value="Estonia">Estonia</option>
							   <option value="Ethiopia">Ethiopia</option>
							   <option value="Falkland Islands">Falkland Islands</option>
							   <option value="Faroe Islands">Faroe Islands</option>
							   <option value="Fiji">Fiji</option>
							   <option value="Finland">Finland</option>
							   <option value="France">France</option>
							   <option value="French Guiana">French Guiana</option>
							   <option value="French Polynesia">French Polynesia</option>
							   <option value="French Southern Ter">French Southern Ter</option>
							   <option value="Gabon">Gabon</option>
							   <option value="Gambia">Gambia</option>
							   <option value="Georgia">Georgia</option>
							   <option value="Germany">Germany</option>
							   <option value="Ghana">Ghana</option>
							   <option value="Gibraltar">Gibraltar</option>
							   <option value="Great Britain">Great Britain</option>
							   <option value="Greece">Greece</option>
							   <option value="Greenland">Greenland</option>
							   <option value="Grenada">Grenada</option>
							   <option value="Guadeloupe">Guadeloupe</option>
							   <option value="Guam">Guam</option>
							   <option value="Guatemala">Guatemala</option>
							   <option value="Guinea">Guinea</option>
							   <option value="Guyana">Guyana</option>
							   <option value="Haiti">Haiti</option>
							   <option value="Hawaii">Hawaii</option>
							   <option value="Honduras">Honduras</option>
							   <option value="Hong Kong">Hong Kong</option>
							   <option value="Hungary">Hungary</option>
							   <option value="Iceland">Iceland</option>
							   <option value="Indonesia">Indonesia</option>
							   <option value="India">India</option>
							   <option value="Iran">Iran</option>
							   <option value="Iraq">Iraq</option>
							   <option value="Ireland">Ireland</option>
							   <option value="Isle of Man">Isle of Man</option>
							   <option value="Israel">Israel</option>
							   <option value="Italy">Italy</option>
							   <option value="Jamaica">Jamaica</option>
							   <option value="Japan">Japan</option>
							   <option value="Jordan">Jordan</option>
							   <option value="Kazakhstan">Kazakhstan</option>
							   <option value="Kenya">Kenya</option>
							   <option value="Kiribati">Kiribati</option>
							   <option value="Korea North">Korea North</option>
							   <option value="Korea Sout">Korea South</option>
							   <option value="Kuwait">Kuwait</option>
							   <option value="Kyrgyzstan">Kyrgyzstan</option>
							   <option value="Laos">Laos</option>
							   <option value="Latvia">Latvia</option>
							   <option value="Lebanon">Lebanon</option>
							   <option value="Lesotho">Lesotho</option>
							   <option value="Liberia">Liberia</option>
							   <option value="Libya">Libya</option>
							   <option value="Liechtenstein">Liechtenstein</option>
							   <option value="Lithuania">Lithuania</option>
							   <option value="Luxembourg">Luxembourg</option>
							   <option value="Macau">Macau</option>
							   <option value="Macedonia">Macedonia</option>
							   <option value="Madagascar">Madagascar</option>
							   <option value="Malaysia">Malaysia</option>
							   <option value="Malawi">Malawi</option>
							   <option value="Maldives">Maldives</option>
							   <option value="Mali">Mali</option>
							   <option value="Malta">Malta</option>
							   <option value="Marshall Islands">Marshall Islands</option>
							   <option value="Martinique">Martinique</option>
							   <option value="Mauritania">Mauritania</option>
							   <option value="Mauritius">Mauritius</option>
							   <option value="Mayotte">Mayotte</option>
							   <option value="Mexico">Mexico</option>
							   <option value="Midway Islands">Midway Islands</option>
							   <option value="Moldova">Moldova</option>
							   <option value="Monaco">Monaco</option>
							   <option value="Mongolia">Mongolia</option>
							   <option value="Montserrat">Montserrat</option>
							   <option value="Morocco">Morocco</option>
							   <option value="Mozambique">Mozambique</option>
							   <option value="Myanmar">Myanmar</option>
							   <option value="Nambia">Nambia</option>
							   <option value="Nauru">Nauru</option>
							   <option value="Nepal">Nepal</option>
							   <option value="Netherland Antilles">Netherland Antilles</option>
							   <option value="Netherlands">Netherlands (Holland, Europe)</option>
							   <option value="Nevis">Nevis</option>
							   <option value="New Caledonia">New Caledonia</option>
							   <option value="New Zealand">New Zealand</option>
							   <option value="Nicaragua">Nicaragua</option>
							   <option value="Niger">Niger</option>
							   <option value="Nigeria">Nigeria</option>
							   <option value="Niue">Niue</option>
							   <option value="Norfolk Island">Norfolk Island</option>
							   <option value="Norway">Norway</option>
							   <option value="Oman">Oman</option>
							   <option value="Pakistan">Pakistan</option>
							   <option value="Palau Island">Palau Island</option>
							   <option value="Palestine">Palestine</option>
							   <option value="Panama">Panama</option>
							   <option value="Papua New Guinea">Papua New Guinea</option>
							   <option value="Paraguay">Paraguay</option>
							   <option value="Peru">Peru</option>
							   <option value="Phillipines">Philippines</option>
							   <option value="Pitcairn Island">Pitcairn Island</option>
							   <option value="Poland">Poland</option>
							   <option value="Portugal">Portugal</option>
							   <option value="Puerto Rico">Puerto Rico</option>
							   <option value="Qatar">Qatar</option>
							   <option value="Republic of Montenegro">Republic of Montenegro</option>
							   <option value="Republic of Serbia">Republic of Serbia</option>
							   <option value="Reunion">Reunion</option>
							   <option value="Romania">Romania</option>
							   <option value="Russia">Russia</option>
							   <option value="Rwanda">Rwanda</option>
							   <option value="St Barthelemy">St Barthelemy</option>
							   <option value="St Eustatius">St Eustatius</option>
							   <option value="St Helena">St Helena</option>
							   <option value="St Kitts-Nevis">St Kitts-Nevis</option>
							   <option value="St Lucia">St Lucia</option>
							   <option value="St Maarten">St Maarten</option>
							   <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
							   <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
							   <option value="Saipan">Saipan</option>
							   <option value="Samoa">Samoa</option>
							   <option value="Samoa American">Samoa American</option>
							   <option value="San Marino">San Marino</option>
							   <option value="Sao Tome & Principe">Sao Tome & Principe</option>
							   <option value="Saudi Arabia">Saudi Arabia</option>
							   <option value="Senegal">Senegal</option>
							   <option value="Seychelles">Seychelles</option>
							   <option value="Sierra Leone">Sierra Leone</option>
							   <option value="Singapore">Singapore</option>
							   <option value="Slovakia">Slovakia</option>
							   <option value="Slovenia">Slovenia</option>
							   <option value="Solomon Islands">Solomon Islands</option>
							   <option value="Somalia">Somalia</option>
							   <option value="South Africa">South Africa</option>
							   <option value="Spain">Spain</option>
							   <option value="Sri Lanka">Sri Lanka</option>
							   <option value="Sudan">Sudan</option>
							   <option value="Suriname">Suriname</option>
							   <option value="Swaziland">Swaziland</option>
							   <option value="Sweden">Sweden</option>
							   <option value="Switzerland">Switzerland</option>
							   <option value="Syria">Syria</option>
							   <option value="Tahiti">Tahiti</option>
							   <option value="Taiwan">Taiwan</option>
							   <option value="Tajikistan">Tajikistan</option>
							   <option value="Tanzania">Tanzania</option>
							   <option value="Thailand">Thailand</option>
							   <option value="Togo">Togo</option>
							   <option value="Tokelau">Tokelau</option>
							   <option value="Tonga">Tonga</option>
							   <option value="Trinidad & Tobago">Trinidad & Tobago</option>
							   <option value="Tunisia">Tunisia</option>
							   <option value="Turkey">Turkey</option>
							   <option value="Turkmenistan">Turkmenistan</option>
							   <option value="Turks & Caicos Is">Turks & Caicos Is</option>
							   <option value="Tuvalu">Tuvalu</option>
							   <option value="Uganda">Uganda</option>
							   <option value="United Kingdom">United Kingdom</option>
							   <option value="Ukraine">Ukraine</option>
							   <option value="United Arab Erimates">United Arab Emirates</option>
							   <option value="United States of America">United States of America</option>
							   <option value="Uraguay">Uruguay</option>
							   <option value="Uzbekistan">Uzbekistan</option>
							   <option value="Vanuatu">Vanuatu</option>
							   <option value="Vatican City State">Vatican City State</option>
							   <option value="Venezuela">Venezuela</option>
							   <option value="Vietnam">Vietnam</option>
							   <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
							   <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
							   <option value="Wake Island">Wake Island</option>
							   <option value="Wallis & Futana Is">Wallis & Futana Is</option>
							   <option value="Yemen">Yemen</option>
							   <option value="Zaire">Zaire</option>
							   <option value="Zambia">Zambia</option>
							   <option value="Zimbabwe">Zimbabwe</option>
						      </select>
						  </div>
						  <div className="form-group">
						    <input ref={register({ required: true })} type="text" className="form-control" name="phone" id="phone" placeholder="Phone number*"/>
						  </div>
						  <div className="form-group">
						    <input ref={register({ required: true })} type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp" placeholder="E-mail address*"/>
						  </div>
						</form>	
					</div>
					<div className="col">
						<div className="form-group">
						   <textarea ref={register({ required: true })} className="form-control" name="remarks" id="exampleFormControlTextarea1" rows="8" placeholder="Remarks*" style={{paddingBottom: "0px"}}></textarea>
						</div>
						<div className="form-group d-flex flex-row-reverse">
							<button onClick={handleSubmit(onSubmit)} type="submit" className={`${con.contactSend}`}>Verzenden</button>
						</div>
					</div>
				</div>

				<ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfRKt0ZAAAAABYE5vNszZ0yT5vylyka-L_8Ug_N"
       // onChange={onChange}
      />
      {
      	send && 
      	<div className="col-md-10 align-items-center justify-content-center" style={{marginRight: "15%", marginLeft: "15%"}}>
      		<p>Message sent!</p>
      	</div>
      }
				<div className="col-md-10 align-items-center justify-content-center" style={{marginRight: "15%", marginLeft: "15%"}}>
						{errors.name && <p><span>Name field is required</span></p>}
						{errors.company && <p><span>Company field is required</span></p>}
						{errors.country && <p><span>Country field is required</span></p>}
						{errors.name && <p><span>Name field is required</span></p>}
						{errors.phone && <p><span>Phone field is required</span></p>}
						{errors.email && <p><span>Email field is required</span></p>}
						{errors.remarks && <p><span>Remarks field is required</span></p>}
					</div>

				<div className={`row justify-content-md-center mt-5 ${con.contactInfo}`}>
					<div className="col">
						<h2 className={con.contactAddressTitle}>Contact information BMT Group</h2>
						<div className="d-flex flex-column mt-3">
						{ state.company_name &&
							<>
							<div className={con.contactAddressInfo}>{state.company_name}</div>
							<div className={`${con.contactAddressInfo} mt-3`}>{state.street_number}, {state.street}</div>
							<div className={con.contactAddressInfo}>{state.zipcode} {state.city}</div>
							<div className={con.contactAddressInfo}>{state.country}</div>
							<div className={`${con.contactAddressInfo} mt-3`}><a style={{color: "#000000"}} href={`tel:${state.phone}`}>{state.phone}</a></div>
							<div className={con.contactAddressInfo}><a style={{color: "#000000"}} href={`email:${state.email}`}>{state.email}</a></div>
							</>
						}
						</div>
					</div>
				</div>	
			</div>

		{!isDesktop &&
		<>
			<div className="row mt-3">
				<div className="col justify-content-start" style={{padding: "0px 30px 0px 30px"}}>
					<div className={`${styles.title} ${styles.blue}`}>
						Contact information
					</div>
				</div>
			</div>

			{ state.company_name &&
			<>
			<div className="row mt-1">
				<div className="col justify-content-start" style={{padding: "0px 30px 0px 30px"}}>
					<div className={`${con.companyName}`}>
						{state.company_name}
					</div>
				</div>
			</div>

			<div className="row mt-3">
				<div className="col justify-content-start" style={{padding: "0px 30px 0px 30px"}}>
					<div className={`${con.contactAddressInfo}`}>
						{state.street_number}, {state.street}, {state.city}, {state.country}
					</div>
				</div>
			</div>

			<div className="row mt-3" style={{padding: "0px 30px 0px 30px"}}>
				<div className="col justify-content-start pl-0">
					<a href={`tel:${state.phone}`} className={`${con.contactAddressInfo}`}>
						{state.phone}
					</a>
				</div>
				<div className="col justify-content-start">
					<a href={`mailto:${state.email}`} className={`${con.contactAddressInfo}`}>
						{state.email}
					</a>
				</div>
			</div>
			</>
			}

			<div className={`${con.departaments} d-flex flex-row justify-content-center mt-5`}>
				<h2 className={`${isDesktop ? con.contactAddressTitle : con.companyName} ${isDesktop ? null : styles.blue}`}>
					You can also reach out to our departments website
				</h2>
			</div>
		</>
		}

			<div className={`row fill d-flex mt-4 ${con.branches}`}>
				<div className={`${isDesktop ? "col" : "row w-100"} mr-2 d-flex flex-column align-items-center justify-content-center ${loc.column1}`} style={{minHeight: "275px", overflow: "hidden"}}>
					<div className={`${loc.chill} ${styles.logoContainer}`} style={{overflow: "hidden"}}>
						{ state.bmt_aerospace_logo &&
							<img className={`mx-auto d-block ${loc.logoColDrive}`} src={state.bmt_aerospace_logo.url} alt={state.bmt_aerospace_logo.alt} style={{minHeight: "53px", overflow: "hidden"}}/>  
						}
					</div>
					{ state.bmt_aerospace_link &&
						<a href={state.bmt_aerospace_link} target="_blank" rel="noopener noreferrer">
							<button className={styles.visitWebsite}>Visit website</button>
						</a>
					}
				</div>
				<div className={`${isDesktop ? "col" : "row w-100 mt-2"} mr-2 d-flex flex-column align-items-center justify-content-center ${loc.column2}`} style={{minHeight: "275px"}}>
					<div className={`${loc.chill} ${styles.logoContainer}`}>
					{	state.vest_logo &&
						<img className={`mx-auto d-block ${loc.logoColDrive}`} src={state.vest_logo.url} alt={state.vest_logo.alt} style={{minHeight: "53px", overflow: "hidden"}}/>  
					}
					</div>
					{	state.vest_link &&
						<a href={state.vest_link} target="_blank" rel="noopener noreferrer">
							<button className={styles.visitWebsite}>Visit website</button>
						</a>
					}
				</div>

				<div className={`${isDesktop ? "col" : "row w-100 mt-2"} mr-2 d-flex flex-column align-items-center justify-content-center ${loc.column4Colorized}`} style={{minHeight: "275px"}}>
					<div className={`${loc.chill} ${styles.logoContainer}`}>
					{	state["igw-i_logo"] &&
						<img className={`mx-auto d-block ${loc.logoColDrive}`} src={state["igw-i_logo"].url} alt={state["igw-i_logo"].alt} style={{minHeight: "53px"}}/>  
					}
					</div>
					{	state["igw-i_link"] &&
						<a href={state["igw-i_link"]} target="_blank" rel="noopener noreferrer">
							<button className={styles.visitWebsite}>Visit website</button>
						</a>
					}
				</div>
				<div className={`${isDesktop ? "col" : "row w-100 mt-2"} d-flex flex-column align-items-center justify-content-center ${loc.column3}`} style={{minHeight: "275px"}}>
					<div className={`${loc.chill} ${styles.logoContainer}`}>
					{	state.omco_logo &&
						<img className={`mx-auto d-block ${loc.logoColDrive}`} src={state.omco_logo.url} alt={state.omco_logo.alt} style={{minHeight: "53px"}}/>  
					}
					</div>
					{	state.omco_link &&
						<a href={state.omco_link} target="_blank" rel="noopener noreferrer">
							<button className={styles.visitWebsite}>Visit website</button>
						</a>
					}
				</div>
			</div>

			
				{/*<div className={`row ${con.reach} align-items-center mt-3`}>
					<div className="row d-flex justify-content-between">
					{
						state.branches && state.branches.slice(0, 2).map((x, i) => 
						<div key={i} className={`col ${con.colIpad} d-flex justify-content-center`}>
							<a href={x.link}><img className={`${con.fullImg} ${con.logo1}`} src={x.branch.url} alt={x.branch.alt && x.branch.alt}/></a>
						</div>
						)
					}
					</div>
					<div className="row mt-3 d-flex justify-content-between">
					{
						state.branches && state.branches.slice(2, 4).map((x, i) => 
						<div key={i} className={`col ${con.colIpad} d-flex justify-content-center`}>
							<a href={x.link}><img className={`${con.fullImg} ${con.logo1}`} src={x.branch.url} alt={x.branch.alt && x.branch.alt}/></a>
						</div>
						)
					}
					</div>
					<div className="row mt-3 d-flex justify-content-between align-items-center">
					{
						state.branches && state.branches.slice(4, 5).map((x, i) => 
						<div key={i} className={`col ${con.colIpad} d-flex align-items-center justify-content-center`}>
							<div style={{width: "50%", height: "100%"}}>
								<a href={x.link}>
									<img className={`${con.fullImg} ${con.logo1}`} src={x.branch.url} alt={x.branch.alt && x.branch.alt}/>
								</a>
							</div>
						</div>
						)
					}
					</div>
				</div>
			*/}

			

		</div>
	</div>
</div>
</section>
</>
}

<Footer color="dark"/>
</>

)}

export default Page;