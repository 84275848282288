import React, {useState, lazy, Suspense} from 'react';
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { RouteTransitionProvider } from 'react-route-transition';
import BmtAerospace from './bmt-aerospace'
import BmtAerospaceExplanation from './bmt-aerospace-explanation'
import BmtAerospaceExplanationAdditive from './bmt-aerospace-explanation-additive'
import BmtAerospaceProducts from './bmt-aerospace-products'
import BmtAerospaceMarkets from './bmt-aerospace-markets'
import BmtAerospaceDepartaments from './bmt-aerospace-departaments'
import BmtAerospaceReferences from './bmt-aerospace-references'
import BmtOmco from './bmt-omco'
import BmtOmcoExplanation from './bmt-omco-explanation'
import BmtOmcoProducts from './bmt-omco-products'
import BmtOmcoMarkets from './bmt-omco-markets' 
import BmtOmcoDepartaments from './bmt-omco-departaments'
import BmtOmcoReferences from './bmt-omco-references'
import AboutsUsVision from './about-us-vision'
import AboutUsStrategy from './about-us-strategy'
import AboutUsMission from './about-us-mission'
import History from './history'
import Contact from './contact'
import BmtGlobalPresence from './bmt-global-presence'
//import NotFound404 from './404'
import Privacy from './privacy'
import BmtHomepage from './bmt-homepage'
import AboutUsGroupStructure from './about-us-group-structure'
import Vest from './vest'
import IGW from './igw'
import IGWI from './igwi'
import VestMore from './vest-more'
import IGWMore from './igw-more'
import IGWIMore from './igwi-more'
import VestReferences from './vest-references'
import IGWReferences from './igw-references'
import IGWIReferences from './igwi-references'
import VestDepartments from './vest-departments'
import IGWDepartments from './igw-departments'
import IGWIDepartments from './igwi-departments'
import MobileChart from './mobilechart'
import useGoogleAnalytics from './utils/gahook'

/*import VCST from './vcst'
import IGWIPage from './igwi-page'
import IGWPage from './igw-page'
import BMTAerospace from './bmt-aerospace-page'
import BMTOmcoPage from './bmt-omco-page'
import AboutPage from './about-page'
import Home from './home'*/

/*const Home = lazy(() => import('./home'))*/
const VCST = lazy(() => import('./vcst'))
const IGWIPage = lazy(() => import('./igwi-page'))
const IGWPage = lazy(() => import('./igw-page'))
const BMTAerospace = lazy(() => import('./bmt-aerospace-page'))
const BMTOmcoPage = lazy(() => import('./bmt-omco-page'))
const AboutPage = lazy(() => import('./about-page'))

function Routes() {
  useGoogleAnalytics()
 
  return (
    <Switch>
      <Route exact path="/" component={BmtHomepage} />
      <Route exact path="/history" component={History} />
      <Route exact path="/bmt-omco-page" component={BMTOmcoPage} />
      <Route exact path="/bmt-omco" component={BmtOmco} />
      <Route exact path="/bmt-omco-explanation" component={BmtOmcoExplanation} />
      <Route exact path="/bmt-omco-products" component={BmtOmcoProducts} />
      <Route exact path="/bmt-omco-markets" component={BmtOmcoMarkets} />
      <Route exact path="/bmt-omco-departaments" component={BmtOmcoDepartaments} />
      <Route exact path="/bmt-omco-references" component={BmtOmcoReferences} />
      
      <Route exact path="/bmt-aerospace-page" component={BMTAerospace} />
      <Route exact path="/bmt-aerospace" component={BmtAerospace} />
      <Route exact path="/bmt-aerospace-explanation" component={BmtAerospaceExplanation} />
      <Route exact path="/bmt-aerospace-explanation-additive" component={BmtAerospaceExplanationAdditive} />
      <Route exact path="/bmt-aerospace-products" component={BmtAerospaceProducts} />
      <Route exact path="/bmt-aerospace-markets" component={BmtAerospaceMarkets} />
      <Route exact path="/bmt-aerospace-departaments" component={BmtAerospaceDepartaments} />
      <Route exact path="/bmt-aerospace-references" component={BmtAerospaceReferences} />  
     
      <Route exact path="/about-page" component={AboutPage} />
      <Route exact path="/about-us-vision" component={AboutsUsVision} />
      <Route exact path="/about-us-strategy" component={AboutUsStrategy} />
      <Route exact path="/about-us-mission" component={AboutUsMission} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/bmt-global-presence" component={BmtGlobalPresence} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/about-us-group-structure" component={AboutUsGroupStructure} />

      <Route exact path="/vcst" component={VCST} />
      <Route exact path="/vest" component={Vest} />
      <Route exact path="/vest-more" component={VestMore} />
      <Route exact path="/vest-references" component={VestReferences}/>
      <Route exact path="/vest-departments" component={VestDepartments} />
     
      <Route exact path="/igw-page" component={IGWPage} />
      <Route exact path="/igw" component={IGW} />
      <Route exact path="/igw-more" component={IGWMore} />
      <Route exact path="/igw-references" component={IGWReferences} />
      <Route exact path="/igw-departments" component={IGWDepartments} />
    
      <Route exact path="/igwi-page" component={IGWIPage} />
      <Route exact path="/igwi" component={IGWI} />
      <Route exact path="/igwi-more" component={IGWIMore} />
      <Route exact path="/igwi-references" component={IGWIReferences} />
      <Route exact path="/igwi-departments" component={IGWIDepartments} />

      {/*<Route component={NotFound404}/>*/}
      <Route exact path="/chart" component={MobileChart} />
      <Redirect to="/" />
    </Switch>
  )
}

function Container ({location})
{

return(
    <RouteTransitionProvider>
      <Suspense fallback={<span>Loading...</span>}>
        <Routes/>
      </Suspense>  
    </RouteTransitionProvider>    
)}

export default withRouter(Container);
