import React, {useState} from 'react'
import styles from '../../css/timeline.module.css'
import { useMediaQuery } from 'react-responsive';

const Comp = (props) =>{
	const {years} = props
	const [index, setIndex] = useState(0)
	const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

	function setI(index)
	{
		setIndex(index)
		if(props.func)
			if(index >= 2)
			{
				props.func(false)
			}
			else{
				props.func(true)
			}
	}

return(
<>	
{years &&
<>
<div className="d-flex flex-row mt-4">
	{ years && years[index - 2] &&
	<div className="d-flex align-items-center flex-column justify-content-center" 
	onClick={() => setI(index - 2)}
	style={{cursor: "pointer"}}>	
	 <div className={`${styles.dot} d-flex align-items-center`} style={{filter: "grayscale(80%)", visibility: index === 0 ? "hidden" : "visible"}}
	 >
	 	<div className={styles.year}>{years &&  years[index - 2].acf && years[index - 2].acf.year}</div>
	 </div>
	 <div style={{height: "70px", bottom: "44px", position: "relative", filter: "grayscale(80%)", transform: "scale(0.8)"}}>
		<div className={styles.line}/>
		<div className={styles.round}/>
		{ years && years[index - 2] && years[index - 2].acf &&
			<img src={years && years[index - 2].acf.logo.url} alt="golden years" className={styles.imgoverlay}/>
		}
	 </div>
	</div> 
	}
	<hr id="1" className={styles.axis} style={{visibility: index === 0 || index === 1 ? "hidden" : "visible"}}/>
	

	{ years && years[index - 1] &&
	<div className="d-flex align-items-center flex-column justify-content-center" 
	onClick={() => setI(index - 1)}
	style={{cursor: "pointer"}}>	
	 <div className={`${styles.dot} d-flex align-items-center`} style={{filter: "grayscale(80%)", visibility: index === 0 ? "hidden" : "visible"}}
	 >
	 	<div className={styles.year}>{years && years[index - 1].acf.year}</div>
	 </div>
	 <div style={{height: "70px", bottom: "44px", position: "relative", filter: "grayscale(80%)", transform: "scale(0.8)"}}>
		<div className={styles.line}/>
		<div className={styles.round}/>
		{ years && years[index - 1] && years[index - 1].acf &&
			<img src={years && years[index - 1].acf.logo.url} alt="golden years" className={styles.imgoverlay}/>
		}
	 </div>
	</div> 
	}
	<hr className={styles.axis} style={{visibility: index === 0 ? "hidden" : "visible"}}/>
	
	{/*
	<div className={`${styles.arrowReverse}`} style={{visibility: index === 0 ? "hidden" : "visible"}}
	onClick={() => setIndex(index - 1)}
	/>

	<hr className={styles.axis} style={{visibility: index === 0 ? "hidden" : "visible"}}/>
	*/}

	<div className="d-flex align-items-center flex-column justify-content-center">
		<div className={`${styles.dot} d-flex align-items-center`}>
			<div className={styles.year}>{years && years[index] && years[index].acf.year}</div>
		</div>
		<div style={{height: "70px", bottom: "44px", position: "relative"}}>
			<div className={styles.line}/>
			{/*<div className={styles.lineData}/>*/}
			<div className={styles.round}/>
			{ years && years[index] &&
				<img src={years && years[index].acf.logo.url} className={styles.imgoverlay} alt="golden years"/>
			}
		</div>
	</div>

	{/*
	<hr className={styles.axis} style={{visibility: (index === years.length - 1) ? "hidden" : "visible"}}/>

	<div className={`${styles.arrow}`} style={{visibility: index === years.length - 1 ? "hidden" : "visible"}}
	onClick={() => setIndex(index + 1)}
	/>*/}

	<hr className={styles.axis} style={{visibility: (index === years && years.length - 1) ? "hidden" : "visible"}}/>
	
	{years && years[index + 1] &&
	<div className="d-flex align-items-center flex-column justify-content-center"
	onClick={() => setI(index + 1)}
	style={{cursor: "pointer"}}
	>	
		<div className={`${styles.dot} d-flex align-items-center`} style={{filter: "grayscale(80%)", visibility: (index === years && years.length - 1) ? "hidden" : "visible"}}>
		 	<div className={styles.year}>{years && years[index + 1].acf.year}</div>
		 </div>
		 <div style={{height: "70px", bottom: "44px", position: "relative", filter: "grayscale(80%)", transform: "scale(0.8)"}}>
			<div className={styles.line}/>
			<div className={styles.round}/>
			{ years && years[index + 1] &&
				<img src={years && years[index + 1].acf.logo.url} className={styles.imgoverlay} alt="golden years"/>
			}
		 </div>
	 </div>
	}

	<hr id="antelast" className={styles.axis} style={{visibility: ((index === years && years.length - 1) || (index === years && years.length - 2)) ? "hidden" : "visible"}}/>
	
	{years && years[index + 2] &&
	<div className="d-flex align-items-center flex-column justify-content-center"
	onClick={() => setI(index + 2)}
	style={{cursor: "pointer"}}
	>	
		<div className={`${styles.dot} d-flex align-items-center`} style={{filter: "grayscale(80%)", visibility: (index === years && years.length - 1) ? "hidden" : "visible"}}>
		 	<div className={styles.year}>{years && years[index + 2].acf.year}</div>
		 </div>
		 <div style={{height: "70px", bottom: "44px", position: "relative", filter: "grayscale(80%)", transform: "scale(0.8)"}}>
			<div className={styles.line}/>
			<div className={styles.round}/>
			{ years[index + 2] &&
				<img src={years && years[index + 2].acf.logo.url} className={styles.imgoverlay} alt="golden years"/>
			}
		 </div>
	 </div>
	}

	<hr id="last" className={styles.axis} style={{width: "calc(1.5rem + 20px)", visibility: ((index === years && years.length - 1) || (index === years && years.length - 2)) ? "hidden" : "visible"}}/>
</div>

<div className={`${isDesktop? "col-md-6 mx-auto d-flex flex-column align-items-center justify-content-center w-25" : "col-md-6 mx-auto d-flex flex-column align-items-center justify-content-center w-50"}`}>
	<div className={`${styles.subtitle}`} style={{marginTop: "40px"}}>
		<div>{years && years[index] && years[index].acf.title}</div>
	</div>
	<div className={`mt-3`}>
		<h2 className={styles.descr}>{years && years[index] && years[index].acf.description}</h2>
	</div>
	<div className="mt-3">
		<div>
		{ years && years[index] && years[index].acf && years[index].acf.image && years[index].acf.image.url &&
			<img className={styles.img} src={`/images/${years[index].acf.image.url.split('wp-content/')[1]}`} alt={years[index].acf.alt} />
		}
		</div>
	</div>
</div>
</>
}
</>
)}

export default Comp