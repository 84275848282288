import React, { useEffect, useRef, useState } from 'react';
import loc from './css/about-us-vision.module.css';
import styles from './css/subpages.module.css';
import arrows from './images/down-arrows-navyblue.png';
import Header from './components/header';
import gsap from 'gsap';
import { useTransitionHistory } from 'react-route-transition';
import $ from 'jquery'; 
import getData from './utils/fetchdata'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'

const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const [index, setIndex] = useState("3")
const [loading, setLoading] = useState(false)

const isLarge = useMediaQuery({ query: '(min-width: 1600px)' });
const isXLarge = useMediaQuery({ query: '(min-width: 2000px)' });
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const [state, setState] = useState(
    {
    	titleL: '', subtitleL:'', contentTitleL:'', contentL:'', sliderF:'', lastEntry: null
    }
)

useEffect(() => {

const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/about.json`)
  .then(data => {
  	if(Array.isArray(data) && data.length)
	{
		let obj
		data.forEach(x => 
		{
			if(x.acf.content_title === "Vision"){
				obj = {...x.acf}
			}
		})
		return obj
	}
  })

  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let arr = []
      let years = []
      data.forEach(x => 
        years.push(x.acf.year)
      )

      data.forEach(x => 
        arr.push({...x.acf, years: years})
      )
      return arr[arr.length - 1]
    }
  })

  setState({...resp1, ...resp2})
}

fetchData();
setLoading(true)
}, []);

useEffect(() => {
	gsap.timeline().from(`.${loc.colTitle}`, {
        duration:1,
        stagger: 0.1,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
  .from(`.${loc.subtitle}`, {
        duration:1,
        stagger: 0.2,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
  .from(`.${loc.description}`, {
        duration:1,
        stagger: 0.3,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
  .from(`.${styles.title}`, {
        duration:1,
        stagger: 0.3,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
  	.from(`.${loc.rightimg}`, {
        duration:1,
        stagger: 0.3,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
    .from(`.${styles.slide1}`, {
        duration:1,
        stagger: 0.3,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
    .from(`.${styles.slide2}`, {
        duration:1,
        stagger: 0.4,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
    .from(`.${styles.slide3}`, {
        duration:1,
        stagger: 0.3,
        x: "random(-500, 500)",
        y: "random(-500, 500)",
        ease: "power4.out"
    }, 0)
}, [loading])

function handleKeyDown(e){
	if(e.keyCode === 39){
		e.preventDefault(); //right arrow
    	history.push('/about-us-strategy');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
		/*{	state && state.year &&
    		history.push(`/${state.year}`);
    	}*/
    	history.push('/about-us-group-structure')    	
	}
}

async function handleDown(e) {
    e.preventDefault();
    /*{	state && state.year &&
		history.push(`/${state.year}`);
	}*/
    history.push('/about-us-group-structure')	
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

useEffect(() => {
  $('#carouselIndicators').on('slid.bs.carousel', function (e) {
    let index = document.querySelector("#carouselIndicators > div > div.carousel-item.active").getAttribute('index');
    setIndex(index)
  });   
}, [loading])

function visitLink(e, link){
	e.preventDefault()
	if(link)
		history.push(link);
}

return(
<>
<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" style={{position: "relative", overflow: "hidden"}}>
{ loading &&
<>
<div className={`${loc.fullpage} d-flex flex-column`}>
<Header />

{isDesktop &&
<div className={`${bc.vertical2}`}> 
<a href="/history">History / </a>
<a href="/bmt-global-presence">Global Presence / </a>
<a href={`/${state.year}`}>Turnover / </a>
<a href="/about-us-group-structure">Group Structure / </a>
<span className={bc.destination}>Vision </span>
</div>
}

<div className={`container-fluid ${loc.container}`}>
	<div className="row align-items-center" style={{marginTop: '100px'}}>
		<div className={`col ${loc.colMobile} d-flex flex-column justify-content-center align-items-center`}>
			<h1 className={`${loc.title} ${loc.titleContainer} ${styles.blue}`}>{state.title}</h1>
			<div className={loc.subtitleContainer}>
				<h2 className={`${loc.subtitle} mt-4`}>{state.subtitle}</h2>
			</div>
		</div>
	</div>	

	<div className={`row align-items-center ${loc.reverseCol}`}>
		<div className={`col d-flex flex-column mt-4 ${loc.leftColMargin} ${loc.noPadding}`}>
			<div className={`${loc.colTitle}`}>
				{state.content_title}
			</div>
			<div className={`${loc.description} mt-4 w-75`}>
				{state.content}
			</div>
		</div>

		<div className={`col d-flex flex-column mt-4 ${loc.rightColMargin} ${loc.noPadding}`}>
			<div className={loc.rightimg}>
		      <div id="carouselIndicators" className="carousel slide" data-ride="carousel" data-interval="false">
		        <div className={`carousel-inner ${loc.carroussoHeight}`}>
		         {
		            state.slider && state.slider.gallery.map((x, i) => {
		              return(
		              <div key={i} className={`carousel-item ${i === 0 ? 'active' : ''}`} index={i + 1}>
		                { x.type === "image" ?
		                 <img className="d-block w-100" src={`/images/${x.url.split('wp-content/')[1]}`} alt={x.alt} style={{maxWidth: "100%", maxHeight: "100%"}}/>
		                  : 
		                  <video className="video-fluid" autoPlay loop muted style={{maxWidth: "100%"}} index={i + 1}>
		                    <source src={`/images/${x.url.split('wp-content/')[1]}`} type={x.mime_type} />
		                  </video>
		                }
		              </div>
		            )}
		            )
		          }
		          {/*
		            state.slider && state.slider.video_links && state.slider.video_links.map((x, i) => 
		              <div key={i} className={`carousel-item`} index={i + 1 + (state.slider.gallery ? state.slider.gallery.length : 0)}>
		                <video className="video-fluid" autoPlay loop muted style={{maxWidth: "100%"}} index={i + 1}>
		                  <source src={x.video_link} />
		                </video>
		              </div>
		            )*/
		          }
		        </div>

		        <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
		          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
		          <span className="sr-only">Previous</span>
		        </a>
		        <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
		          <span className="carousel-control-next-icon" aria-hidden="true"></span>
		          <span className="sr-only">Next</span>
		        </a>
		      
		      </div>
					
		      	<div style={{display: "flex"}}>
				{/*state.slider && state.slider.gallery &&
			  		[...Array(state.slider.gallery.length + (state.slider.video_links ? state.slider.video_links.length : 0))].map((_, i) => (
  						<div key={i} onClick={(e) => (visitLink(e, i < state.slider.gallery.length ? `/about-us-${state.slider.gallery[i].alt.toLowerCase()}` : ''))} data-target="#carouselIndicators" data-slide-to={i} className={`${styles.slide1} ${parseInt(index) === (i + 1) ? styles.active : null}`}></div>
					))
				*/		
			  	}
			  	<div onClick={(e) => (visitLink(e, `/about-us-strategy`))} data-target="#carouselIndicators" data-slide-to="1" className={`${styles.slide1} ${styles.greenSlide} ${parseInt(index) === 1 ? styles.active : null}`}></div>
			  	<div onClick={(e) => (visitLink(e, `/about-us-mission`))} data-target="#carouselIndicators" data-slide-to="2" className={`${styles.slide1} ${styles.greenSlide} ${parseInt(index) === 2 ? styles.active : null}`}></div>
			  	<div onClick={(e) => (visitLink(e, `/about-us-vision`))} data-target="#carouselIndicators" data-slide-to="3" className={`${styles.slide1} ${styles.greenSlide} ${parseInt(index) === 3 ? styles.active : null}`}></div>
					

				</div>

				<div style={{display: "flex"}}>
				  <div style={{flex: "30%", textAlign: "center", color: "#64AB80"}} className={styles.slideTitle}>Strategy</div>
				  <div style={{flex: "30%", textAlign: "center", color: "#64AB80"}} className={styles.slideTitle}>Mission</div>
				  <div style={{flex: "30%", textAlign: "center", color: "#64AB80"}} className={styles.slideTitle}>Vision</div>

			      {
		            /*state.slider && state.slider.video_links && state.slider.video_links.map((x, i) => 
		            <div key={i} style={{flex: "30%", textAlign: "center"}} className={styles.slideTitle}>Video</div>
		            )*/
		          }
				</div>

			  </div>
		</div>
	</div>
	
</div>

{	state && state.year &&
	<div className="col-md-12 d-flex align-items-center justify-content-center mt-auto mb-3">
		<a href={`/about-us-group-structure`} onClick={handleDown}>
			<img src={arrows} alt="down arrows" style={{height: "25px"}}/>		
		</a>
	</div>
    }

</div>
	
</>
}
</div>
</>
)}

export default Page;