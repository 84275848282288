import React, { useEffect, useRef, useState }  from 'react'
import styles from './css/subpages.module.css'
import './css/svg-styling.css'
import map from './css/maps.module.css'
import logo from './images/omco.png'
import arrows from './images/down-arrows-blue.png'
import gsap from 'gsap'
import { useTransition, useTransitionHistory } from 'react-route-transition';
import Globe from './omcomap'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'
import getData from './utils/fetchdata'

/*const entries = [
	{color: "blue", country: "Slovenija", company: "OMCO Metals Slovenia", street: "C. Zalskega tabora 10", city: "3310 Zalec", phones: ["T +386 37 131 300", "F +386 35 717 292"], email: ""},
	{color: "blue", country: "Belgium", company: "OMCO International", street: "Venecolaan 10", city: "9880 Aalter", phones: ["T +32 9 374 20 05", "F +32 9 374 34 69"], email: "sales@omcomould.com"},
	{color: "blue", country: "Belgium", company: "Omco Metals Hamme", street: "Zwaarveld 20", city: "9220 Hamme", phones: ["T +32 52 49 93 30", "F +32 52 49 93 31"], email: ""},
	{color: "blue", country: "Croatia", company: "OMCO Croatia d.o.o", street: "Hum na Sutli 107/5", city: "49231 Hum na Sutli", phones: ["T +385 49 327 327", "F +385 49 327 333"], email: ""},
	{color: "blue", country: "Romania", company: "OMCO Romania SRL.", street: "Calea Chisniaului Street 43A", city: "700179 Iasi", phones: ["T +40 232 23 23 03", "T +40 232 23 23 71"], email: ""},
	{color: "blue", country: "United Kingdom", company: "OMCO UK Ltd.", street: "New Street Mills Carlisle Rd", city: "Pudsey, Leeds LS28 8 LW", phones: ["T +44 113 25 73 172", "F +44 113 25 73 333"], email: ""},
	{color: "blue", country: "Turkey", company: "OMCO Istanbul Kalip San. Ve Tic A.S.", street: "Dilovasi OSB Mah. Dilovasi Org.San.Bol.", city: "4. Kisim, D-4013 Sok. No:6 41455 Gebze/Kocaeli", phones: ["T +90 262 744 4452", "F +90 262 744 4456"], email: ""},
	{color: "blue", country: "Hungary", company: "OMCO Hungary", street: "Besenyői u. 10,", city: "Miskolc BAZ 3527", phones: "", email: ""},
	{color: "blue", country: "South Africa", company: "OMCO South-Africa (Pty) Ltd", street: "8 Guthrie Road,", city: "Wadeville", phones: "", email: ""},
	{color: "blue", country: "USA", company: "OMCO USA LLC Glass Division", street: "", city: "", phones: "", email: ""},
]*/

const Page=() =>
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const [entries, setEntries] = useState([])

var random = gsap.utils.random(-500, 500, true);

const firstRef = useRef(null);
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useTransition({
    handlers: [
      {
        path: '/bmt-omco-departaments',
        onEnter: async () => {
		await gsap.timeline().from("#comp1", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp2", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp3", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp4", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp5", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp6", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp7", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp8", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		  .from("#comp9", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        ease: "power4.out"
		    }, 0)
		}
      },
    ],
  });

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/bmt-omco-products');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/bmt-omco-references');
	}
}

function handleDown(e) {
   e.preventDefault();
   history.push('/bmt-omco-references');
}

function handleUp(e) {
   e.preventDefault();
   history.push('/bmt-omco-products');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
  .then(data => {
    
    if(Array.isArray(data) && data.length)
    {
      let obj = {}
      data.forEach(x => 
      {
        const {title, department} = x.acf
        if(title === "OMCO"){
          obj.title = title
          obj.department = department
        }
      })
      return obj
    }
  })

  //setState({...resp1})
  const { country, company_name, address, postcode, phones, email} = resp1.department
  let arr = []
  if(resp1 && resp1.department.length > 1)
  {
    resp1.department.forEach((el) =>
      arr.push({color: "blue", country: el.country, company: el.company_name, street: el.address, city: el.postcode, phones: el.phones && [...el.phones.map(el => el.phone)], email: el.email})
    )
  }
  setEntries(arr)
}

fetchData();
}, []);

return(
<>
	<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" className={`${styles.globalMapContainer} ${styles.blueBorder}`} style={{borderStyle: isDesktop ? '' : 'none', position: isDesktop ? "fixed" : "relative", zIndex: isDesktop ? "-100" : "1"}}>	
		{isDesktop &&
		<>
		<Header />

		<div className={`${bc.vertical}`}> 
		<a href="/bmt-omco-references">References / </a>
		<span className={bc.destination}>Departments / </span>
		<a href="/bmt-omco-products">Products / </a>
		<a href="/bmt-omco-explanation">Explanation / </a>
		<a href="/bmt-omco">OMCO </a>
		</div>

		<a href='/bmt-omco-products' onClick={(e) => handleUp(e)}>
  			<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
		</a>

		<div className="d-flex flex-row justify-content-center" style={{zIndex: "-100", marginTop: "7.5rem"}}>
			<h1 className={styles.title}>OMCO Departments</h1>	
		</div>

		<div className="d-flex flex-row justify-content-center">	
			<Globe style={{marginTop: "50px"}} svgref={firstRef}/>
		</div>

		<div>
			<img src={logo} className={styles.refBottomLogo} alt="bmt drive solutions logo"/>
		</div>
		<a href="/bmt-omco-references" className={styles.refDownArrows} onClick={(e) => handleDown(e)}>
			<img src={arrows} alt="up arrows"/>		
		</a>
		</>
		}

		{
			!isDesktop &&
			<>
			<Header/>

			<div className="d-flex justify-content-center" style={{marginTop: "80px"}}>
				<a href='/bmt-omco-products' onClick={(e) => handleUp(e)}>
		  			<img src={arrows} style={{transform: "rotate(180deg)", height: isDesktop ? "35px" : "28px"}} alt="bmt aerospace arrows"/>
				</a>
			</div>

			<div className={`d-flex flex-row justify-content-center mb-2 ${map.titleMobile}`} style={{color: "white"}}>
				Global Presence
			</div>

			<div className="col-md-10 mx-auto d-flex flex-column text-center justify-content-center align-items-center">
	          {
	          	entries && entries.map((x, i) => (
	          		<div key={i} className={`${map.mapTooltip} ${x.color === "blue" ? map.blue : null}`}>
	          			<div className={map.mapTooltipText}>{x.country}</div>
	          			<div className={map.mapTooltipTitle}>{x.company}</div>
	          			<div className={map.mapTooltipText}>{x.street}</div>
	          			<div className={map.mapTooltipText}>{x.city}</div>
	          			{
	          				x.phones && x.phones.map((y, ii) => 
	          					<div key={ii} className={map.mapTooltipText}>{y}</div>
	          				)
	          			}
	          			<div className={map.mapTooltipText}>{x.email}</div>
	          		</div>
	          	))
	          }
	        </div>

			{/*}
			<div className={map.siteWrapper} style={{marginTop: "-100px"}}>
			<div className={map.fixed}>
				<div className={map.inner}>
					<Globe className={map.globeSpacing} 
					style={{minHeight: "100vh", minWidth: "300vw", overflow: "visible"}}/>
				</div>
			</div>
			</div>
			*/}

			<a href="/bmt-omco-references" className="col-md-12 d-flex align-items-center justify-content-center pt-2 pb-2">
				<img src={arrows} alt="up arrows" style={{maxHeight: "27px"}}/>		
			</a>
			</>
		}
	</div>
</>
)}

export default Page;