import React, { useEffect, useRef, useState }  from 'react'
import styles from './css/subpages.module.css'
import './css/svg-styling.css'
import map from './css/maps.module.css'
import logo from './images/IGWRail2.png'
import arrows from './images/down-arrows-red.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import Globe from './railmap'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'
import getData from './utils/fetchdata'


/*const entries = [
	{color: "red", country: "China - Suzhou", company: "IGW (Suzhou) Co., Ltd", street: "N° 1 Fenghe Road Fengting Avenue, SIP", city: "Suzhou 215122", phones: ["T +86 512 6287 5601"], email: "rail@igwpower.com"},
	{color: "red", country: "India - Pune", company: "Involute Technologies Pvt. Ltd.", street: "Regd. off. Gat No. 232 & 156-159 Alandi – Market Road Dhanore", city: "Tal. Khed Dist. Pune 412 105", phones: ["T +91 982 255 9845"], email: "rail@igwpower.com"},
	{color: "red", country: "USA - Zanesville", company: "IG Watteeuw USA LLC", street: "1000 Linden Avenue, Zanesville", city: "Ohio 43701 – 3098", phones: ["T +1 740 588 1722"], email: "rail@igwpower.com"},
    {color: "red", country: "Czech Republic - Brno", company: "IGW Czech Republic", street: "Vídeňská 231", city: "619 00 Brno-jih, Brno", phones: ["T +420 547 139 511"], email: "rail@igwpower.com"},
]*/

const Page=() =>
{

const mainRef = useRef(null);
const history = useTransitionHistory();
const [entries, setEntries] = useState([])

var random = gsap.utils.random(-500, 500, true);

//var t_x, t_y, d_x, d_y;

const firstRef = useRef(null);
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/igw-more');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/igw-references');
	}
}

function handleDown(e) {
    e.preventDefault();
    history.push('/igw-references');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/igw-more');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
	gsap.timeline()
  .from("#comp4", {
        duration:1.5,
        stagger: 0.1,
        x: random(),
        y: random(),
        //ease: "bounce.out"
        ease: "power4.out"
    }, 0)
  .from("#comp55", {
        duration:1.5,
        stagger: 0.1,
        x: random(),
        y: random(),
        //ease: "bounce.out"
        ease: "power4.out"
    }, 0)
  .from("#comp6", {
        duration:1.5,
        stagger: 0.1,
        x: random(),
        y: random(),
        //ease: "bounce.out"
        ease: "power4.out"
    }, 0)
  .from("#comp8", {
        duration:1.5,
        stagger: 0.1,
        x: random(),
        y: random(),
        ease: "power4.out"
    }, 0)
}, []);

useEffect(() => {
const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
  .then(data => {
    
    if(Array.isArray(data) && data.length)
    {
      let obj = {}
      data.forEach(x => 
      {
        const {title, department} = x.acf
        if(title === "IGW"){
          obj.title = title
          obj.department = department
        }
      })
      return obj
    }
  })

  //setState({...resp1})
  const { country, company_name, address, postcode, phones, email} = resp1.department
  let arr = []
  if(resp1 && resp1.department.length > 1)
  {
    resp1.department.forEach((el) =>
      arr.push({color: "red", country: el.country, company: el.company_name, street: el.address, city: el.postcode, phones: [...el.phones.map(el => el.phone)], email: el.email})
    )
  }
  setEntries(arr)
}

fetchData();
}, []);

return(
<>
	<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" className={styles.globalMapContainer} style={{borderStyle: isDesktop ? '' : 'none', position: isDesktop ? "fixed" : "relative", zIndex: isDesktop ? "-100" : "1"}}>	
		{ isDesktop &&
		<>
		<Header/>

		<div className={`${bc.vertical}`}> 
        <a href="/igw-references">References / </a>
        <span className={bc.destination}>Departments / </span>
        <a href="/igw-more">More / </a>
        <a href="/igw">IGW Rail </a>
        </div>

        <a href='/igw-more' onClick={(e) => handleUp(e)}>
    		<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
  		</a>

		<div className="d-flex flex-row justify-content-center" style={{zIndex: "-100", marginTop: "7.5rem"}}>
			<h1 className={styles.title}>Global Presence</h1>	
		</div>
		<div className="d-flex flex-row justify-content-center">	
			<Globe style={{marginTop: "50px"}} svgref={firstRef}/>
		</div>
		<div>
			<img src={logo} className={styles.refBottomLogo} alt="bmt drive solutions logo" style={{zIndex: "-1"}}/>
		</div>
		<a href="/igw-references" className={styles.refDownArrows} onClick={(e) => handleDown(e)}>
			<img src={arrows} alt="up arrows"/>		
		</a>
		</>
		}

		{
			!isDesktop &&
			<>
			<Header/>

			<div className="d-flex justify-content-center" style={{marginTop: "80px"}}>
				<a href='/igw-more' onClick={(e) => handleUp(e)}>
		    		<img src={arrows} style={{transform: "rotate(180deg)", height: "28px"}} alt="bmt aerospace arrows"/>
		  		</a>
			</div>

			<div className={`d-flex flex-row mb-2 ${map.titleMobile}`} style={{color: "white", textAlign: "center", justifyContent: "center"}}>
				Global Presence
			</div>

			<div className="col-md-10 mx-auto d-flex flex-column text-center justify-content-center align-items-center">
	          {
	          	entries && entries.map((x, i) => (
	          		<div key={i} className={`${map.mapTooltip} ${x.color === "red" ? map.red : null}`}>
	          			<div className={map.mapTooltipText}>{x.country}</div>
	          			<div className={map.mapTooltipTitle}>{x.company}</div>
	          			<div className={map.mapTooltipText}>{x.street}</div>
	          			<div className={map.mapTooltipText}>{x.city}</div>
	          			{
	          				x.phones.map((y, ii) => 
	          					<div key={ii} className={map.mapTooltipText}>{y}</div>
	          				)
	          			}
	          			<div className={map.mapTooltipText}>{x.email}</div>
	          		</div>
	          	))
	          }
	        </div>

			<a href="/igw-references" className="col-md-12 d-flex align-items-center justify-content-center pt-2 pb-2" onClick={(e) => handleDown(e)}>
				<img src={arrows} alt="up arrows" style={{maxHeight: "27px"}}/>		
			</a>
			</>
		}
	</div>
</>
)}

export default Page;