import React from 'react'
import styles from '../css/subpages.module.css'

const Comp = (props) =>{
	const {state, color, style} = props
  let styleButton;
  if(color === "orange"){
    styleButton = styles.orangeButton
  }
  else if(color === "blue")
  {
    styleButton = styles.blueButton
  }
  else if(color === "purple"){
    styleButton = styles.purpleButton
  }
  else if(color === "yellow"){
    styleButton = styles.yellowButton
  }
  else if(color === "bordeau"){
    styleButton = styles.bordeauButton
  }

	return(
	<div className="d-flex flex-row mt-5" style={style}>
    {
      state.buttonF ?
      <a href={state.buttonF.link} target="_blank" rel="noopener noreferrer">
        <button className={`${styleButton} ${styles.orangeButtonText}`}>{state.buttonF.content}</button>
      </a> : null
    }
    </div>
)}

export default Comp;