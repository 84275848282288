import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import arrows from "./images/down-arrows-blue.png"
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'

const Page = () => {
const mainRef = useRef(null);
const history = useTransitionHistory();

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', logoFAlt: '', branchesF: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
        const {title, content, branches, logo} = x.acf
		if(x.title.rendered === "OMCO Glass Moulds Explanation"){
			setState({
			titleF: title,
			contentF: content,
			logoF: `/images/${logo.url.split('wp-content/')[1]}`,
			logoFAlt: logo.alt,
			branchesF: branches	
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

useEffect(() => {
  	var tl = gsap.timeline();
    tl.from(`.${styles.logo}`, {
        duration: 0.5,
        width: "183px", 
        height: "131px",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    }, 0)
    .fromTo(`.${styles.fullpageOmco}`, 
	{scale: 1}, 
	{duration: 0.5, transformOrigin: "left", scale: 1.2, y:"-30px", ease: "expoScale(1, 1.2)"
}, 0)
}, [loading]);

function handleDown(e) {
  e.preventDefault();
  history.push('/bmt-omco-products');
}

function handleUp(e) {
  e.preventDefault();
  history.push('/bmt-omco');
}

function handleKeyDown(e){
	if(e.keyCode === 40){ //down arrow key
		e.preventDefault();
    	history.push('/bmt-omco-products');
	}
	else if(e.keyCode === 38){ //up arrow key
		e.preventDefault();
    	history.push('/bmt-omco');
	}
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

function createMarkup(content) {
    return {
       __html: content    
    };
 };

return(
<>
<Header/>

{isDesktop &&
<div className={`${bc.vertical}`}> 
<a href="/bmt-omco-references">References / </a>
<a href="/bmt-omco-departaments">Departments / </a>
<a href="/bmt-omco-products">Products / </a>
<span className={bc.destination}>Explanation / </span>
<a href="/bmt-omco">OMCO </a>
</div>
}

<div className={styles.fullpageOmcoContainerMobile} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">
</div>

{loading &&
<>
{isDesktop &&
  <div style={{overflow: "hidden", maxWidth: "100%"}}>
	<section className={styles.fullpageOmco}></section>
</div>
}

<a href='/bmt-omco' onClick={(e) => handleUp(e)}>
  <img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)", height: isDesktop ? "35px" : "28px"}} alt="bmt aerospace arrows"/>
</a>

<div className={`container-fluid d-flex flex-column ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>
		<div className={`${isDesktop ? 'col-md-6' : 'col-md-12'} d-flex flex-column ${styles.leftColumn}`} style={{marginTop: !isDesktop ? '50px' : null}}>
			<h1 className={`${styles.title} ${styles.omcoblue} ${!isDesktop ? 'pt-3' : ''}`}>
				{state.titleF}
			</h1>
			<div className={`${styles.description} ${styles.white} mt-4 w-75`} /*style={{wordWrap: "break-word"}}*/>
        <div dangerouslySetInnerHTML={createMarkup(state.contentF)} />
			</div>
			<div className="d-flex flex-row mt-5">
				{ state.branchesF ? state.branchesF.map( (x, i) => 
				<a href={x.branch_link} key={i} className={`${styles.features} ${styles.omcoblue} ${i === state.branchesF.length-1 ? styles.featuresLast : ''} mr-5`}>
					{x.branch}
				</a>)
				: null
				}
			</div>
		</div>
	</div>

	{ isDesktop && <>
  	{	state.logoF ?
  		<img src={state.logoF} className={styles.logo} alt={state.logoFAlt}/> : null
  	}
  	<a href="/bmt-omco-products" className={styles.downArrows} onClick={(e) => handleDown(e)}>
  		<img src={arrows} alt="down arrows"/>		
  	</a></>
  }

  { !isDesktop &&
      <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center`} style={{paddingTop: "30px"}}>
        <div className="col" style={{padding: "0px"}}>
        {state.logoF &&
          <img src={state.logoF} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "40%"}}/>
        }
        </div>
        <div className="col" style={{padding: "0px", position: "relative"}}>
          <a href="/bmt-aerospace-departaments" className={`${styles.downArrowsMobile} ${styles.genericPageArrows}`} 
         onClick={(e) => handleDown(e)}>
            <img src={arrows} alt="down arrows"/>   
          </a>
        </div>
      </div>
  }
  
</div>
</>
}

</>

)}

export default Page;