import React, {useEffect, useState} from 'react';
import styles from '../css/header.module.css';
import '../css/bootstrap-overriding.css'
import getData from '../utils/fetchdata'
import mobileLogo from '../images/BMT_GROUP_logo_white.png'
import logo from '../images/BMT_Group_Logo_horizontal_Blue_RGB.svg'
import { useMediaQuery } from 'react-responsive';

const Header = (props) => 
{
//const {isDesktop} = props
const [loading, setLoading] = useState(false)
const [collapsed, setCollapse] = useState(false)
const [state, setState] = useState(
    {
      logoF: '', logoFalt: '', menuItemsF: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/header.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {logo, menu_items} = x.acf
        setState({
        	logoF: `${process.env.PUBLIC_URL}/images/${logo.url.split('wp-content/')[1]}`,
    		logoFalt: logo.alt,
    		menuItemsF: menu_items
        })
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleClick(e){
	e.preventDefault();
	setCollapse(!collapsed)
}

return(
<>
{loading &&
<>
<div className="container-fluid" style={{padding: "0px", maxHeight: !isDesktop ? "56px" : null}}>
	<div className={`row ${styles.rowStyle}`} style={{boxShadow: !isDesktop ? "0px 3px 3px #00000017" : null}}>
		<div className={`col-md-12 ${styles.colStyle}`}>
			<nav className={`navbar fixed-top navbar-expand-lg navbar-light ${styles.navMinHeight}`}
			style={{padding: "0px", zIndex: "9999", backgroundColor: collapsed ? "#0073AB" : ""}}>	 
				<button className={`${!collapsed ? 'collapsed' : ''} navbar-toggler position-relative`} type="button" data-toggle="collapse" data-target="#navbarNavMobile"
				onClick={handleClick}>
					{/*<span className="navbar-toggler-icon"></span>*/}
					<span> </span>
        			<span> </span>
        			<span> </span>
				</button> 
				<a className={styles.navbarBrand} href="/">
				{	
					<img src={collapsed ? mobileLogo : logo} alt="bmt logo" className={styles.logo}/>
				}
				</a>
				<div className="collapse navbar-collapse" id="navbarNav">
				    <ul className={`navbar-nav ml-auto ${styles.navBar} ${styles.navItem}`}>
				    	{
				    		state.menuItemsF && state.menuItemsF.map((x, i) =>
				    		{ 
				    		  if(x.menu_item === "Our Brands")
				    		  {
				    		  	return (
					    		<li key={i} className="nav-item active mr-4">
					    			<div className="dropdown" style={{padding: "8px", cursor: "pointer"}}>
									  <div className="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									    {x.menu_item}
									  </div>
									  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
									    <a href="/bmt-aerospace-page" className="dropdown-item">BMT Aerospace</a>
									  	<a href="/vcst" className="dropdown-item">VCST</a> 
									    <a href="/igwi-page" className="dropdown-item">IGW</a>
									    <a href="/bmt-omco-page" className="dropdown-item">OMCO</a>
									  </div>
									</div>	
					    		</li>)
				    			}
				    		  /*else if(x.menu_item === "About Us")
				    		  {
				    		  	return (
					    		<li key={i} className="nav-item active mr-4">
					    			<div className="dropdown" style={{padding: "8px", cursor: "pointer"}}>
									  <div className="dropdown-toggle" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									    {x.menu_item}
									  </div>
									  <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
									    <a href="/about-page" className="dropdown-item">About</a>
									    {/*<a href="/about-us-vision" className="dropdown-item">About</a>
									  	<a href="/about-us-group-structure" className="dropdown-item">Group Structure</a> 
									    <a href="/2019" className="dropdown-item">Group Turnover</a>
									    <a href="/bmt-global-presence" className="dropdown-item">Global Presence</a>
									    <a href="/history" className="dropdown-item">History</a>
									  </div>
									</div>	
					    		</li>)
				    		  }*/	
				    		  else{
				    				return (
				    				<li key={i} className="nav-item active mr-4">
							      		<a className="nav-link" href={x.menu_item_link} target={x.menu_item === "Jobs" ? "_blank" : "_self"} rel="noopener noreferrer">{x.menu_item}<span className="sr-only">(current)</span></a>
							    	</li>
							    	)
					    	  }
					    	})	
				    	}  
				    </ul>
				</div>

				{!isDesktop &&
				<div className="collapse navbar-collapse" id="navbarNavMobile" style={{backgroundColor: "#0073AB", zIndex: "1000"}}>
				    <ul className={`navbar-nav d-flex align-items-center justify-content-center ${styles.navItemMobile}`} style={{margin: "0px", padding:"0px"}}>
				    	{
				    		state.menuItemsF && state.menuItemsF.map((x, i) =>
				    			<li key={i} className="nav-item">
							      <a className="nav-link" style={{color: "white"}} href={x.menu_item_link.includes("/about-page") ? "/about-us-vision" : x.menu_item_link} target={x.menu_item === "Jobs" ? "_blank" : "_self"} rel="noopener noreferrer">{x.menu_item}<span className="sr-only">(current)</span></a>
							    </li>
				    		)
				    	}  
				    </ul>
				</div>
				}
			</nav>
		</div>
	</div>
</div>
</>
}
</>
)}

export default Header;