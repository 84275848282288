import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import arrows from "./images/down-arrows-bordeau.png"
import logo from './images/vest.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import $ from 'jquery'; 
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import GButton from './components/gpagebutton'
import Slider from './components/gpageslider'
import { useSwipeable } from "react-swipeable";
import bc from './css/breadcrumb.module.css'

const Page = () => {
const [index, setIndex] = useState("1")
const mainRef = useRef(null);
const history = useTransitionHistory();
var tl = gsap.timeline();

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', logoFAlt: '', buttonF:'', sliderF: ''
    }
)

const handlers = useSwipeable({
    onSwipedRight: () => handleLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
        const {title, content, button, slider, logo} = x.acf
        if(x.title.rendered === "VCST"){
          setState({
          titleF: title,
          contentF: content,
          logoF: `/images/${logo.url.split('wp-content/')[1]}`,
          buttonF: button,
          sliderF: slider,
          logoFAlt: logo.alt
          })
        }
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

useEffect(() => {
    tl.from(`.${styles.title}`, {
          duration: 0.5,
          rotate: "-90deg",
          xPercent: -40,
          yPercent: 80,
          opacity: 0,
        }, 0)
    .from(`.${styles.description}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(".bottomLeftSlide", {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(`.${styles.slide1}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(`.${styles.slide2}`, {
        duration: 0.7,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(`.${styles.slide3}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
    .from(`.${styles.orangeButton}`, {
        duration: 0.6,
        x: "random(-400, 400)",
        y: "random(-400, 400)"
    }, 0)
}, [loading])


function handleKeyDown(e){
if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/vest');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/vest-departments');
	}
}

function handleDown(e) {
    e.preventDefault();
    history.push('/vest-departments');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/vest');
}

function handleLeft(e){
  if(e)
    e.preventDefault()
  history.push('/vest');
}

useEffect(() => {
  mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
  $('#carouselIndicators').on('slid.bs.carousel', function (e) {
    let index = document.querySelector("#carouselIndicators > div > div.carousel-item.active").getAttribute('index');
    setIndex(index)
  });   
}, [loading])

function createMarkup(content) {
    return {
       __html: content    
    };
 };


return(
<>
<div {...handlers}>
<Header />

{isDesktop &&
<div className={`${bc.vertical}`}> 
  <a href="/vest-references"> References / </a>
  <a href="/vest-departments"> Departments / </a>
  <span className={bc.destination}> Automotive / </span>
  <a href="/vest"> VCST</a>
</div>
}

<section ref={mainRef} className={styles.fullpageCarVest} onKeyDown={handleKeyDown} tabIndex="0" style={{overflow: "hidden"}}>
{loading &&
<>

{isDesktop &&
<a href='/vest' onClick={(e) => handleUp(e)}>
  <img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
</a>
}

<div className={`container-fluid d-flex align-items-center flex-column ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>
		<div className={`${isDesktop ? 'col' : 'w-100 align-items-center'} d-flex flex-column ${styles.leftColumn}`}>
			
      { !isDesktop &&
      <div className="d-flex flex-row justify-content-between align-items-center w-100" style={{marginTop: "70px"}}>
          <div>
            <a href="/#" onClick={(e) => handleLeft(e)}>
              <img src={arrows} style={{maxWidth: "25px", transform: "rotate(180deg)"}} alt="down arrows"/>
            </a>
          </div>
          <div><h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`}>
            {state.titleF}
          </h1></div>
      </div>
      }

      {
        isDesktop &&
          <h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`}>
            {state.titleF}
          </h1>
      }

			<div className={`${styles.description} mt-4 w-75`} /*style={{wordWrap: "break-word"}}*/>
          <div dangerouslySetInnerHTML={createMarkup(state.contentF)} />
			</div>

      { isDesktop && <GButton state={state} color="bordeau"/>}

      { !isDesktop &&
        <div className="d-flex flex-column-reverse align-items-center">  
    			<GButton state={state} color="bordeau"/>
          <Slider state={state} index={index} isDesktop={isDesktop} color="bordeau" stop={true}/>
        </div>
    	}	
    </div>

    {
      isDesktop && <Slider state={state} index={index} isDesktop={isDesktop} color="bordeau"/>
    }

	</div>

	{  isDesktop &&
    <>
    { state.logoF ?
  		<img src={state.logoF} className={styles.logo} alt={state.logoFAlt}/> : null
    }
    	<a href="/vest-departments" className={styles.downArrows} onClick={(e) => handleDown(e)}>
    		<img src={arrows} alt="down arrows"/>		
    	</a>
    </>
	}

  { !isDesktop &&
    <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center w-100`} style={{paddingTop: "30px"}}>
      <div className="col" style={{padding: "0px"}}>
        <img src={logo} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "60%"}}/>
      </div>
      <div className="col" style={{padding: "0px", position: "relative"}}>
        <a href="/vest-departments" className={`${styles.downArrowsMobile} ${styles.genericPageArrows}`} 
         onClick={(e) => handleDown(e)}>
          <img src={arrows} alt="down arrows"/>   
        </a>
      </div>
    </div>
  }

</div>
</>
}
</section>
</div>
</>

)}

export default Page;