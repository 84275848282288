import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import scrollLeft from './images/scroll-left-blue.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import $ from 'jquery'; 
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import GButton from './components/gpagebutton'
import Slider from './components/gpageslider'
//import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useSwipeable } from "react-swipeable";
//import bc from './css/breadcrumb.module.css'
import arrows from "./images/down-arrows-blue.png"


const Page = () => {
const mainRef = useRef(null);
const history = useTransitionHistory();
const [index, setIndex] = useState("1")

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', logoFAlt: '', buttonF:'', sliderF: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const handlers = useSwipeable({
    onSwipedRight: () => handleLeft(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
});

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
        const {title, content, button, slider, logo} = x.acf
	    if(x.title.rendered === "OMCO Glass Moulds Markets"){
	    	setState({
	    		titleF: title,
	      		contentF: content,
	      		logoF: `/images/${logo.url.split('wp-content/')[1]}`,
	      		logoFAlt: logo.alt,
	      		buttonF: button,
	      		sliderF: slider
	    	})
	    }
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

useEffect(() => {
  var tl = gsap.timeline();

  tl.from(`.${styles.title}`, {
    	duration: 0.7,
    	x: "10vw",
    	y: "-10vh",
    	rotation: "15deg"
    }, 0).from(`.${styles.description}`, {
    	duration: 0.7,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0).from(`.${styles.blueButton}`, {
    	duration: 0.7,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0).from(`.${styles.slide1}`, {
    	duration: 0.7,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0).from(`.${styles.slide2}`, {
    	duration: 0.8,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0).from(`.${styles.slide3}`, {
    	duration: 0.7,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0)
    .from(`.rightimg`, {
    	duration: 0.7,
    	x: "random(-500, 500)",
    	y: "random(-500, 500)",
    }, 0);
},[loading])

 function handleBack(e) {
    e.preventDefault();
    history.push('/bmt-omco-products');
  }

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/bmt-omco-products');
	}
	else if(e.keyCode === 37){
		e.preventDefault(); //left arrow
    	history.push('/bmt-omco-products');
	}
}

function handleLeft(e){
  if(e)
    e.preventDefault()
  history.push('/bmt-omco-products')
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

useEffect(() => {
  $('#carouselIndicators').on('slid.bs.carousel', function (e) {
    let index = document.querySelector("#carouselIndicators > div > div.carousel-item.active").getAttribute('index');
    setIndex(index)
  });   
}, [loading])

function createMarkup(content) {
    return {
       __html: content    
    };
 };


return(
<>
<div {...handlers}>

<Header/>

<div className={styles.fullpageOmcoContainerMobile} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">
</div>

{loading &&
<>
{isDesktop &&
<div style={{overflow: "hidden", maxWidth: "100%"}}>
  <section className={styles.fullpageOmcoMarkets}></section>
</div>
}
<div className={`container-fluid d-flex flex-column ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>

	<div className={styles.scrollLeftNoBorder}>
		<img src={scrollLeft} alt="scroll"/>
	</div>	
	<a className={styles.leftBlockContainerNoBorder} href="/bmt-omco-products" onClick={(e) => handleBack(e)}>
		<div className={`col-md-12 d-flex flex-row align-items-center justify-content-center ${styles.leftBlock}`}>
			<div className="">
				<div className={styles.rightBlockContent}>Mouldshops</div>
			</div>
		</div>
	</a>

		<div className={`col d-flex flex-column ${styles.leftColumn2}`}>
        { !isDesktop &&
          <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{marginTop: "70px"}}>
              <div className="d-flex flex-row w-100 align-items-center">
                <div className="mr-auto">
                  <a href="/#" onClick={(e) => handleLeft(e)}>
                    <img src={arrows} style={{maxWidth: "25px", transform: "rotate(90deg)"}} alt="down arrows"/>
                  </a>
                </div>
            
                <div className="mr-4 pb-2 d-flex flex-row align-items-end">
                  <div className="mr-2">
                    <h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px", marginBottom: "0px"}}>
                    Mouldshops
                    </h1>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row w-100 align-items-center justify-content-start">
                <div className="ml"><h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px"}}>
                  {state.titleF}
                </h1></div>
              </div>
          </div>
          }

        {
          isDesktop &&
            <h1 className={`${styles.title} ${styles.white} ${!isDesktop ? 'mt-3' : ''}`}>
              {state.titleF}
            </h1>
        }
			<div className={`${styles.description} ${styles.white} mt-4`} style={{maxWidth: "95%", /*wordWrap: "break-word"*/}}>
        <div dangerouslySetInnerHTML={createMarkup(state.contentF)} />
			</div>
			{ isDesktop && <GButton state={state} color="blue"/> }

			{ !isDesktop &&
	        <div className="d-flex flex-column-reverse align-items-center">  
	    		<GButton state={state} color="blue"/>
	          	<Slider state={state} index={index} isDesktop={isDesktop} color="blue" stop={true}/>
	        </div>
	    	}
		</div>

		{
			isDesktop && <Slider state={state} index={index} isDesktop={isDesktop} color="blue"/>
		}

	</div>

	{ isDesktop &&
		<div>
		{ state.logoF ?
			<img src={state.logoF} className={styles.logo} alt={state.logoFAlt}/> : null
		}
		</div>
	}

	{ !isDesktop &&
    <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center`} style={{paddingTop: "60px"}}>
      <div className="col" style={{padding: "0px"}}>
      { state.logoF &&
        <img src={state.logoF} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "35%"}}/>
      }
      </div>
      <div className="col" style={{padding: "0px", position: "relative"}}>
      </div>
    </div>
  	}
</div>
</>
}
</div>
</>

)}

export default Page;