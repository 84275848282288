import React, { useState, useEffect, useRef}  from 'react'
import styles from './css/subpages.module.css'
import './css/svg-styling.css'
import map from './css/maps.module.css'
import logo from './images/bmt-aerospace.png'
import arrows from './images/down-arrows.png'
import gsap from 'gsap'
import { useTransition, useTransitionHistory } from 'react-route-transition';
import Globe from './aerospacemap'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'
import getData from './utils/fetchdata'

//const entries = [
	//{color: "purple", country: "Belgium - Oostkamp", company: "BMT Aerospace International nv, BMT Eurair nv", street: "Handelsstraat 6", city: "8020 Oostkamp", phones: ["T +32 50 24 90 00", "F +32 50 24 90 10"], email: "info@bmtaerospace.com"},
	//{color: "purple", country: "USA - Michigan", company: "BMT Aerospace USA Inc.", street: "18559 Malyn Blvd.", city: "Fraser, Michigan 48026 USA", phones: ["T +1 586 285 7700", "F +1 586 285 9720"], email: "info@bmtaerospace.com"},
	//{color: "purple", country: "Romania - Iasi", company: "BMT Aerospace Romania srl", street: "Soseaua Iasi – Tomesti, 33B", city: "IASI 700292", phones: ["T +40 33 213 02 80"], email: "info@bmtaerospace.com"},
	//{color: "purple", country: "Belgium - Aalter", company: "BMT Additive Belgium", street: "Venecolaan 10", city: "9880 Aalter", phones: ["T +32 9 374 20 05"], email: "info@bmtaerospace.com"},
//]

const Page=() =>
{

const mainRef = useRef(null);
const history = useTransitionHistory();
var random = gsap.utils.random(-500, 500, true);
const [state, setState] = useState({})
const [entries, setEntries] = useState([])

const firstRef = useRef(null);
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useTransition({
    handlers: [
      {
        path: '/bmt-aerospace-departaments',
        onEnter: async () => {
		await gsap.timeline().from("#comp1", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp2", {
		        duration:1.5,
		        stagger: 0.2,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp3", {
		        duration:1.5,
		        stagger: 0.3,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp4", {
		        duration:1.5,
		        stagger: 0.3,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		}
      },
    ],
  });

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/bmt-aerospace-products');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/bmt-aerospace-references');
	}
}

function handleDown(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-references');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-products');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
  .then(data => {
    
    if(Array.isArray(data) && data.length)
    {
      let obj = {}
      data.forEach(x => 
      {
        const {title, department} = x.acf
        if(title === "BMT Aerospace"){
          obj.title = title
          obj.department = department
          console.log('x', x)
        }
      })
      return obj
    }
  })

  //setState({...resp1})
  const { country, company_name, address, postcode, phones, email} = resp1.department
  let arr = []
  if(resp1 && resp1.department.length > 1)
  {
  	resp1.department.forEach((el) =>
  		arr.push({color: "purple", country: el.country, company: el.company_name, street: el.address, city: el.postcode, phones: [...el.phones.map(el => el.phone)], email: el.email})
  	)
  }
  setEntries(arr)
}

fetchData();
}, []);

return(
<>
	<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" className={`${styles.globalMapContainer} ${styles.purpleBorder}`} style={{borderStyle: isDesktop ? '' : 'none', position: isDesktop ? "fixed" : "relative", zIndex: isDesktop ? "-100" : "1"}}>	
		{ isDesktop &&
		<>
		<Header/>

		<div className={`${bc.vertical}`}> 
		<a href="/bmt-aerospace-references">References / </a>
		<span className={bc.destination}>Departments / </span>
		<a href="/bmt-aerospace-products">Products / </a>
		<a href="/bmt-aerospace-explanation">Explanation / </a>
		<a href="/bmt-aerospace">BMT Aerospace </a>
		</div>

		<a href='/bmt-aerospace-products' onClick={(e) => handleUp(e)}>
			<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
		</a>

		<div className="d-flex flex-row justify-content-center" style={{zIndex: "-100", marginTop: "7rem"}}>
			<h1 className={styles.title}>BMT Aerospace Departments</h1>	
		</div>

		<div className="d-flex flex-row justify-content-center">	
			<Globe style={{marginTop: "50px"}} svgref={firstRef}/>
		</div>

		<div>
			<img src={logo} className={styles.refBottomLogo} alt="bmt drive solutions logo"/>
		</div>
		<a href="/bmt-aerospace-references" className={styles.refDownArrows} onClick={(e) => handleDown(e)}>
			<img src={arrows} alt="up arrows" />		
		</a>
		</>
		}

		{
			!isDesktop &&
			<>
			<Header/>

			<div className="d-flex justify-content-center" style={{marginTop: "80px"}}>
				<a href='/bmt-aerospace-products' onClick={(e) => handleUp(e)}>
					<img src={arrows} style={{transform: "rotate(180deg)", height: "35px"}} alt="bmt aerospace arrows"/>
				</a>
			</div>

			<div className={`d-flex flex-row ${map.titleMobile}`} style={{color: "white", textAlign: "center", justifyContent: "center"}}>
				Global Presence
			</div>

			<div className="col-md-10 mx-auto d-flex flex-column text-center justify-content-center align-items-center">
	          {
	          	entries && entries.map((x, i) => (
	          		<div key={i} className={`${map.mapTooltip} ${x.color === "purple" ? map.purple : null}`}>
	          			<div className={map.mapTooltipTitle}>{x.company}</div>
	          			<div className={map.mapTooltipText}>{x.country}</div>
	          			<div className={map.mapTooltipText}>{x.street}</div>
	          			<div className={map.mapTooltipText}>{x.city}</div>
	          			{
	          				x.phones.map((y, ii) => 
	          					<div key={ii} className={map.mapTooltipText}>{y}</div>
	          				)
	          			}
	          			<div className={map.mapTooltipText}>{x.email}</div>
	          		</div>
	          	))
	          }
	        </div>

			{/*
			<div className={map.siteWrapper} style={{marginTop: "-100px"}}>
			<div className={map.fixed}>
				<div className={map.inner}>
					<Globe className={map.globeSpacing} 
					style={{minHeight: "100vh", minWidth: "300vw", overflow: "visible"}}/>
				</div>
			</div>
			</div>
			*/}

			<a href="/bmt-aerospace-references" className="col-md-12 d-flex align-items-center justify-content-center pt-2 pb-2">
				<img src={arrows} alt="up arrows" style={{maxHeight: "35px"}}/>		
			</a>
			</>
		}
	</div>
</>
)}

export default Page;