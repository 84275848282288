import React, { useEffect, useRef, useState } from 'react';
import styles from './css/bmt-aerospace.module.css'
import arrows from "./images/down-arrows.png"
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import bc from './css/breadcrumb.module.css'
import { useMediaQuery } from 'react-responsive';


const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      description: '', logo: '', logoAlt: ''
    }
)

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/division.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {division_name, division_logo, division_description} = x.acf
		if(division_name === "BMT Aerospace"){
			setState({
			description: division_description,
			logo: `/images/${division_logo.url.split('wp-content/')[1]}`,
			logoAlt: division_logo.alt
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleKeyDown(e){
	if(e.keyCode === 40){ //Down arrow
		e.preventDefault();
    	history.push('/bmt-aerospace-explanation');
	}
	else if(e.keyCode === 38){ //Up arrow
		e.preventDefault();
    	history.push('/');
	}
}

 function handleDown(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-explanation');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

return(
<>
<div className={styles.fullpage} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">

{ loading &&
<>
<Header/>

{isDesktop &&
<>
<div className={`${bc.vertical}`}> 
<a href="/bmt-aerospace-references">References / </a>
<a href="/bmt-aerospace-departaments">Departments / </a>
<a href="/bmt-aerospace-products">Products / </a>
<a href="/bmt-aerospace-explanation">Explanation / </a>
<span className={bc.destination}>BMT Aerospace</span>
</div>

<a href='/' onClick={(e) => handleUp(e)}>
	<img src={arrows} className={styles.upArrows} alt="bmt aerospace arrows"/>
</a>
</>
}

<div className="container-fluid">
	<div className={`row align-items-center min-vh-100`} style={{marginTop: !isDesktop ? "56px" : "0px"}}>
		<div className="col-md-12">
		
			<div className={`d-flex flex-column justify-content-center align-items-center ${styles.container}`}>
				
				{!isDesktop &&
					<a href='/' onClick={(e) => handleUp(e)}>
						<img src={arrows} style={{transform: "rotate(180deg)", height: "34px", marginBottom: "20px"}} alt="bmt aerospace arrows"/>
					</a>
				}

				{ state.logo ?
					<>
						<div>
							<img src={state.logo} className={styles.logo} alt="bmt aerospace"/>
						</div>
						<h2 className={`${styles.subtitle} ${styles.subtitleContainer}`}>
							{state.description}
						</h2>
					</>
					: null
				}

				<a href='/bmt-aerospace-explanation' onClick={(e) => handleDown(e)}>
					<img src={arrows} className={styles.downArrowsMob} alt="bmt aerospace arrows"/>
				</a>
			</div>
		</div>
	</div>
</div>

{/*<a href='/bmt-aerospace-explanation' onClick={(e) => handleDown(e)}>
	<img src={arrows} className={styles.downArrows} alt="bmt aerospace arrows"/>
</a>*/}
</>
}
</div>
</>

)}

export default Page;