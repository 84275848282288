import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import arrows from "./images/down-arrows.png"
import arrowsMobile from './images/down-arrows.png'
import scroll from './images/scroll-right.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from "react-swipeable";
import bc from './css/breadcrumb.module.css'


const Page = () => {

const mainRef = useRef(null);
const history = useTransitionHistory();

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', branchesF:''
    }
)

const handlers = useSwipeable({
    onSwipedRight: () => handleLeft(),
    onSwipedLeft: () => handleRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
});

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {title, content, branches, logo} = x.acf
		if(title === "BMT Aerospace"){
			setState({
				titleF: title,
				contentF: content,
				logoF: `/images/${logo.url.split('wp-content/')[1]}`,
				branchesF: branches
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

useEffect(() => {
      	var tl = gsap.timeline();
	    tl.from(`.${styles.rightBlockContainer}`, {
	        duration: 0.5,
	        x: "-100vw",
	        opacity: 0,
	    }, 0).from(`.${styles.scroll}`, {
	        duration: 0.5,
	        x: "-100vw",
	        opacity: 0,
	    }, 0).from(`.${styles.title}`, {
	    	duration: 0.5,
	    	rotate: "-90deg",
	    	xPercent: -40,
	    	yPercent: 80,
	    	opacity: 0,
	    }, 0);
    }, [loading])

 function handleNext(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-explanation-additive');
  }

 function handleDown(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-products');
}

function handleUp(e) {
	e.preventDefault();
	history.push('/');
}

function handleKeyDown(e){
	if(e.keyCode === 39){ //right
		e.preventDefault();
    	history.push('/bmt-aerospace-explanation-additive');
	}
	else if(e.keyCode === 38){ //up
		e.preventDefault();
    	history.push('/bmt-aerospace');
	}
	else if(e.keyCode === 40){ //down
		e.preventDefault();
    	history.push('/bmt-aerospace-products');
	}
}

function handleRight(e){
	if(e)
		e.preventDefault()
    history.push('/bmt-aerospace-explanation-additive');
}

function handleLeft(e){
	if(e)
		e.preventDefault()
    history.push('/bmt-aerospace');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

function createMarkup(content) {
    return {
       __html: content    
    };
 };

return(
<>
<div {...handlers}>
<Header/>

{isDesktop &&
<div className={`${bc.vertical}`}> 
<a href="/bmt-aerospace-references">References / </a>
<a href="/bmt-aerospace-departaments">Departments / </a>
<a href="/bmt-aerospace-products">Products / </a>
<span className={bc.destination}>Explanation / </span>
<a href="/bmt-aerospace">BMT Aerospace </a>
</div>
}

{isDesktop &&
<a href='/' onClick={(e) => handleUp(e)}>
	<img src={arrows} className={styles.upArrows} alt="bmt aerospace arrows"/>
</a>
}

<section ref={mainRef} className={styles.fullpage} onKeyDown={handleKeyDown} tabIndex="0" style={{overflow: "hidden"}}>
{ loading && 
<>
<div className={`container-fluid ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>

		<div className={`${isDesktop ? 'col-md-6' : 'w-100 align-items-center'} d-flex flex-column ${styles.leftColumn}`}>
			{ !isDesktop &&
		      <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{marginTop: "70px"}}>
		          <div className="d-flex flex-row w-100 align-items-center">
		          	<div className="mr-auto">
			          	<a href="/#" onClick={(e) => handleLeft(e)}>
			          		<img src={arrowsMobile} style={{maxWidth: "25px", transform: "rotate(180deg)"}} alt="down arrows"/>
			          	</a>
		          	</div>
	          
		          	<div className="mr-4 pb-2 d-flex flex-row align-items-end">
	          			<div className="mr-2">
		          			<h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px", marginBottom: "0px"}}>
			            	BMT Additive
			          		</h1>
		          		</div>
		          		<div>
			          		<a href="/#" onClick={(e) => handleRight(e)}>
				          		<img src={arrowsMobile} style={{maxWidth: "25px", transform: "rotate(-90deg) translateX(20%)"}} alt="down arrows"/>
				          	</a>
			          	</div>
		          	</div>
		          
		          </div>

		          <div className="d-flex flex-row w-100 align-items-center justify-content-start">
		          	<div className="ml"><h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px"}}>
			            {state.titleF}
			          </h1></div>
		          </div>
		      </div>
		      }

		      {
		        isDesktop &&
		          <h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`}>
		            {state.titleF}
		          </h1>
		      }

			<div className={`${styles.description} mt-4 w-75`} dangerouslySetInnerHTML={createMarkup(state.contentF)} /*style={{wordWrap: "break-word"}}*/>
			</div>

			{ isDesktop &&
				<div className="d-flex flex-row mt-5">
					{ state.branchesF ? state.branchesF.map( (x, i) => 
					<a href={x.branch_link} key={i} className={`${styles.features} mr-5`}>
						{x.branch}
					</a>)
					: null
					}	
				</div>
			}	
		</div>
		
		<div className={styles.scroll}>
			<img src={scroll} alt="scroll"/>
		</div>
		<div className={styles.rightBlockContainer} onClick={(e) => handleNext(e)}>
			<div className={`col-md-12 d-flex flex-row align-items-center justify-content-center ${styles.rightBlock}`}>
				<div className="">
					<div className={styles.rightBlockContent}>BMT Additive</div>
				</div>
			</div>
		</div>
	</div>

	{ !isDesktop ?
    <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center`} style={{paddingTop: "60px", paddingBottom: "30px"}}>
      <div className="col" style={{padding: "0px"}}>
      { state.logoF &&
        <img src={state.logoF} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "35%"}}/>
      }
      </div>
      <div className="col" style={{padding: "0px", position: "relative"}}>
        <a href="/bmt-aerospace-products" className={`${styles.downArrowsMobile} ${styles.genericPageArrows}`} 
         onClick={(e) => handleDown(e)}>
          <img src={arrows} alt="down arrows"/>   
        </a>
      </div>
    </div>
    :
    <>
    <div>
		{ state.logoF ?
		<img src={state.logoF} className={styles.logo} alt="bmt logo"/> : null
		}
	</div>
	<a href="/bmt-aerospace-products" className={styles.downArrows} onClick={(e) => handleDown(e)}>
		<img src={isDesktop ? arrows : arrowsMobile} alt="down arrows"/>		
	</a>
	</>
  	}
	
</div>
</>
}
</section>
</div>
</>

)}

export default Page;