import React from 'react';
import Header from './components/header';
import Footer from './components/footer'
import { useMediaQuery } from 'react-responsive';

const Page = () => {

const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

return(
<>
<Header isDesktop={isDesktop}/>

<div className="col-md-10 mx-auto mt-4">
<p>PRIVACY STATEMENT</p>



<p>Last date of revision: 19/07/2019</p>



<h1>Who does this privacy statement apply to?</h1>



<p>BMT group (“<strong>BMT</strong>” or “<strong>We</strong>”) is an industrial, family-owned holding company with a glass and a gearwheel division, with various companies which are global market leaders. BMT Group’s aim is to achieve continuous profitable growth in all its divisions.</p>



<p>BMT group operates and manages a network of companies in various locations across Europe, Americas and Asia. Our group consists of several companies (“<strong>the BMT Companies</strong>”) that process personal data</p>



<p>This Privacy Statement applies to the processing of personal data:</p>



<ul><li>by all BMT companies established in the European Union, regardless of whether the processing takes place in the Union or not;</li><li>by all BMT companies established outside the European Union, insofar as they process personal data of data subjects who are in the Union in the framework of:</li><li>the offering of goods or services to such data subjects in the Union.</li><li>the monitoring of their behaviour as far as their behaviour takes place within the Union.</li></ul>



<p>All BMT Companies are separate and independent legal entities. Depending on the processing activity in the context of which your personal data are processed, they may be qualified as a separate data controller or a joint controller with one or more of the other BMT Companies. None of the BMT companies have any liability for the other BMT Companies’ acts or omissions.</p>



<p>We want to be a great company. In order to achieve that goal we are committed to work in the right way. We are committed to protect your privacy and to process your personal data in an open and transparent manner, in particular with respect of the General Data Protection Regulation 2016/679 of 27 April 2016 (“GDPR”).</p>



<h3><strong>Contents</strong></h3>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684022">Who DOES this privacy statement apply to?</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684023">What is covered by this Privacy Statement?</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684024">Why we use your data</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684025">I AM A WEBSITE VISITOR</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684026">I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684027">The legal grounds for processing your data</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684028">I AM A WEBSITE VISITOR</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684029">I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684030">Your Rights</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684031">How we obtain DATA</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684032">I AM A WEBSITE VISITOR</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684033">I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684034">Data we collect</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684035">I AM A WEBSITE VISITOR</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684036">I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684037">Disclosures</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684038">COOKIES</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684039">Security of your data</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684040">Data retention</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684041">I AM A WEBSITE VISITOR</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684042">I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684043">Automated Decision-making</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684044">How to contact us?</a></p>



<p><a href="https://gdpr.bmt.lu/privacy_statement_en.html#_Toc11684045">CHANGES TO this PRIVACY STATEMENT</a></p>



<h1>What is covered by this Privacy statement?</h1>



<p>With this Privacy Statement we would like to inform you about why and how we process your personal data when we perform our business activities or when you use our website (“<strong>the Site</strong>”) and any of the services we offer through the Sites, who we give that information to, what your rights are and who you can contact for more information or queries.</p>



<p>When we refer to “the Site”, we mean the following webpages:</p>



<figure className="wp-block-table"><table><tbody><tr><td>Company&nbsp;</td><td>Website</td></tr><tr><td>BMT Aerospace</td><td><a href="http://www.bmtaerospace.com/">http://www.bmtaerospace.com</a></td></tr><tr><td>BMT Additive</td><td><a href="http://www.bmtadditive.com/">http://www.bmtadditive.com/</a></td></tr><tr><td>OMCO</td><td><a href="http://www.omcomould.com/">http://www.omcomould.com/</a></td></tr><tr><td>CNUD-EFCO</td><td><a href="https://www.cnudefco.com/">https://www.cnudefco.com/</a></td></tr><tr><td>IGW</td><td><a href="http://www.igwpower.com/">http://www.igwpower.com/</a></td></tr><tr><td>VCST</td><td><a href="https://www.vcst.com/">https://www.vcst.com/</a></td></tr></tbody></table></figure>



<p>However, by way of derogation, this Privacy Statement does not apply to webpages of the Site allowing you to apply for job openings. These webpages are subject to our separate Privacy Notice for job applicants:&nbsp;<a href="https://remote.bmt.lu:4310/bmt-apps/gdpr/privacy_statement_job_applicant_en.html">Privacy Notice Job Applicants</a>.</p>



<p>The Sites may link to other sites provided by other affiliated companies entities or by third parties. Whilst we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content or the privacy practices of other websites. When linking to any such sites, we strongly recommend you to review the Privacy Statements on these sites, before disclosing any personal information.</p>



<p>Please note that when you are visiting our pages on Facebook, Twitter, Instagram or LinkedIn, cookies are processed by Facebook, Twitter, Instagram and LinkedIn. Based on these cookies, we can gather information - in a completely anonymous way - about the public visiting our social media pages. For further information about why and how personal data are processed in this context, we revert to the Privacy and Cookie Policies of these platforms:</p>



<ul><li><strong>Facebook:</strong></li><li><em>Cookie Policy Facebook:&nbsp;</em><a href="https://www.facebook.com/policies/cookies/"><em>https://www.facebook.com/policies/cookies/</em></a></li><li><em>Privacy Policy Facebook:&nbsp;</em><a href="https://www.facebook.com/about/privacy/"><em>https://www.facebook.com/about/privacy/</em></a></li><li><strong>Twitter:&nbsp;</strong></li><li><em>Cookie Policy Twitter:&nbsp;<a href="https://help.twitter.com/nl/rules-and-policies/twitter-cookies">https://help.twitter.com/nl/rules-and-policies/twitter-cookies</a></em></li><li><em>Privacy Policy Twitter:&nbsp;<a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a></em></li><li><strong>Instagram:&nbsp;</strong></li><li><em>Cookie Policy Instagram:&nbsp;<a href="https://help.instagram.com/1896641480634370">https://help.instagram.com/1896641480634370</a></em></li><li><em>Privacy Policy Instagram:&nbsp;<a href="https://help.instagram.com/155833707900388">https://help.instagram.com/155833707900388</a>)</em></li><li><strong>LinkedIn:&nbsp;</strong></li><li><em>Cookie Policy LinkedIn:&nbsp;<a href="https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a></em></li><li><em>Privacy Policy LinkedIn:&nbsp;<a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>)</em></li></ul>



<h1>Why we use your data</h1>



<h3>I AM A WEBSITE VISITOR</h3>



<p>We only process your personal data for legitimate business reasons. These purposes include, but are not limited to:</p>



<ul><li>the good organisation of our services;</li><li>dealing with enquiries, requests and complaints;</li><li>gathering statistics about the use of the Site (“<em>web audience measuring</em>”);</li><li>improving the Site’s performance and design.</li></ul>



<h3>I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</h3>



<p>We only process your personal data for legitimate business reasons. These purposes include, but are not limited to:</p>



<ul><li>respecting our legal obligations;</li><li>customer and supplier management;</li><li>management of business contacts;</li><li>visitor management;</li><li>order and supply management;</li><li>invoicing and accounting;</li><li>the provision of information on our company, services and activities;</li><li>the good organisation of our services (such as day-to-day business communication);</li><li>marketing and sales;</li><li>dealing with enquiries, requests and complaints;</li><li>dispute management;</li><li>public relations and press contacts;</li><li>statistics and market research;</li><li>access control;</li><li>security;</li><li>health and safety (QHSE).</li></ul>



<h1>The legal grounds for processing your data</h1>



<h3>I AM A WEBSITE VISITOR</h3>



<p>When you contact us through an online contact form, your personal data will in principle be processed for the purpose of our legitimate interests (namely the importance to treat your questions, requests and complaints in the best possible manner).</p>



<p>In this respect, we will always determine case by case whether our interests are not overridden by your interests, fundamental rights and freedoms. If you want more information about this, you can always contact us.</p>



<h3>I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</h3>



<p>We process your personal data for the purposes mentioned above:</p>



<ul><li>when necessary for the performance of contracts to which you are party or in order to take steps at your request prior to entering into a contract;</li><li>when necessary for compliance with our legal obligations;</li><li>for the purposes of the legitimate interests of the BMT Companies and/or of a third party, including (but not limited to) our business activities, customer and supplier management etc. In this respect, we will always determine case by case whether our interests are not overridden by your interests, fundamental rights and freedoms.</li></ul>



<p>If we have the legal obligation to obtain your free, informed, specific and unambiguous consent to process your personal data for certain purposes (e.g. specific direct marketing or market research activities), we will only process your data for such purposes to the extent that we have obtained such consent from you.</p>



<h1>Your Rights</h1>



<p>You have several rights concerning the information we hold about you. We would like to inform you that you have the right to:</p>



<ul><li>obtain confirmation that we are processing your personal data and request a copy of the personal data we hold about you;</li><li>ask that we update the personal data we hold about you, or correct such personal data that you think is incorrect or incomplete;</li><li>ask that we delete personal data that we hold about you, or restrict the way in which we use such personal data if you believe that there is no (longer a) lawful ground for us to process it;</li><li>withdraw consent to our processing of your personal data (to the extent such processing is based on consent);</li><li>receive a copy of the personal data concerning you, which you have provided to us, in a structured, commonly used and machine-readable format and to transmit such personal data to another party (to the extent the processing is based on consent or a contract);</li><li>object to our processing of your personal data for which we use legitimate interest as a legal basis, in which case we will cease the processing unless we have compelling legitimate grounds for the processing.</li></ul>



<p>You have also the right to object at any time to the processing of personal data for direct marketing. If you do not want to continue receiving any direct marketing from us, you can contact us (see below) or click on the unsubscribe function in any such communication. In that event, the personal data shall no longer be processed for such purposes.</p>



<p>In order to exercise any of your rights, you can send us a request, indicating the right you wish to exercise, by e-mailing us at&nbsp;<a href="mailto:gdpr@bmt.lu">gdpr@bmt.lu</a>.</p>



<p>You may also use these contact details if you wish to make a complaint to us relating to your privacy.</p>



<p>If you are unhappy with the way we have handled your personal data or any privacy query or request that you have raised with us, you have a right to complain to the Data Protection Authority (“DPA”) in your jurisdiction. If you would like to be directed to the appropriate DPA, please contact us.</p>



<h1>How we obtain data</h1>



<h3>I AM A WEBSITE VISITOR</h3>



<p>We may obtain your personal data when you use the Site and its services and applications. This may for instance be the case when you fill in a contact form on the Site.</p>



<p>The Sites may also uses cookies. For more information about the cookies we use and how you can control them, please consult the cookie policy of the website concerned.</p>



<h3>I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</h3>



<p>We may obtain you personal data in the framework of the execution of our business activities.</p>



<p>We may obtain such personal data because you give them to us (e.g. by contacting us, by completing online forms, ...), because others give them to us (e.g. your employer or third party service providers that we use in the framework of our business activities) or because they are publicly available.&nbsp;</p>



<p>We may further also obtain personal data from you by the way you interact with us or data about you may also be generated by us (e.g. camera surveillance records).</p>



<p>When we obtain personal data from external parties, we make reasonable efforts to enter into contractual clauses with these parties obliging them to respect the data protection legislation. This can be done by obliging this party to provide you with all necessary information or - if necessary - to obtain your consent for processing the personal data as described in this Privacy Statement.</p>



<h1>Data we collect</h1>



<h3>I AM A WEBSITE VISITOR</h3>



<p>The main personal data which we collect via the Sites, relate to the contact forms. Via the Sites you can contact us to obtain more information, to ask questions or to receive advice. In this context, we may process your message and your contact details (such as: your name, your company, your e-mail address and your phone number).</p>



<p>The Sites may also use cookies. For more information about the cookies we use and how you can control them, please consult the cookie policy of the website concerned.</p>



<h3>I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</h3>



<p>The personal data that we collect or obtain may, among other things, include:</p>



<h1>Disclosures</h1>



<p>We may disclose your personal data to affiliated companies, and to third parties that provide services to us that reasonably require access to personal data relating to you for one or more of the purposes outlined in the “Why we use your data” section above. The following external parties may for instance be involved:</p>



<ul><li>external service providers we rely on for various business services</li><li>law enforcement authorities in accordance with the relevant legislation</li><li>external professional advisors (e.g. attorneys or consultants of the BMT Companies).</li></ul>



<p>If our business enters into a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.</p>



<p>Please note that some of the recipients of your personal data referenced above may be based in countries outside of the European Economic Area whose laws may not provide the same level of data protection. In order for your personal data to be processed in accordance with data protection principles, Dentons has, where necessary, adopted appropriate safeguards in line with the GDPR. For this purpose all BMT Companies have concluded an intra-group agreement including standard contractual clauses in the sense of article 46 (c) of the GDPR. For more information regarding the appropriate safeguards adopted by BMT, you can contact us at&nbsp;<a href="mailto:gdpr@bmt.lu">gdpr@bmt.lu</a>.</p>



<p>In general, we will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Statement.</p>



<p>We reserve the right to disclose your personal information as required by law, or when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, request from a regulator or any other legal process served on us.</p>



<h1>COOKIES</h1>



<p>The Sites may use Cookies. Cookies are small text files that are stored by your browser onto your computer or mobile device when you visit this Site.</p>



<p>They are used to provide you with a good experience when you browse this Site and allow us to improve this Site. They can also be used to personalise the content of this Site based on your previous browsing behaviour and to gather statistics in order to improve this Site.</p>



<p>You can find more information about cookies by visiting the following website:&nbsp;<a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.</p>



<p>For more information about the specific cookies used by each of our Sites, we refer to the cookie policy of each Site.</p>



<p>You can enable, disable and/or delete cookies as you wish. You can delete all cookies that are already on your device and you can set most browsers to prevent them from being placed.</p>



<p>The ability to enable, disable and/or delete cookies can be completed at browser level. These settings are usually found in the “options” or “preferences” menu of you internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the “Help” option in your internet browser for more details:</p>



<ul><li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Cookie settings in Internet Explorer</a></li><li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">Cookie settings in Firefox</a></li><li><a href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647">Cookie settings in Chrome</a></li><li><a href="https://support.apple.com/kb/HT1677">Cookie settings in Safari</a></li></ul>



<h1>Security of your data</h1>



<p>We employ strict technical and organizational (security) measures to protect your information from access by unauthorized persons and against unlawful processing, accidental loss, destruction and damage both online and offline.</p>



<p>These measures include:</p>



<ul><li>training to relevant staff to ensure they are aware of our privacy obligations when handling personal data;</li><li>administrative and technical controls to restrict access to personal data on a ‘need to know’ basis (passwords);</li><li>technological security measures, including fire walls, encryption and anti-virus software;</li><li>login access blocks in case of loss or theft of devices ;</li><li>physical security measures, such as staff security badges to access our premises.</li></ul>



<p>Although we use appropriate security measures once we have received your personal data, the transmission of data - especially over the internet (including by e-mail) - is never completely secure. We endeavor to protect personal data, but we cannot guarantee the security of data transmitted to us or by us.</p>



<p>We limit access to your personal information to those who we believe reasonably need to come into contact with that information in order to carry out their jobs.</p>



<h1>Data retention</h1>



<h3>I AM A WEBSITE VISITOR</h3>



<p>Your personal data will not be retained longer than necessary for the purposes described above.</p>



<p>As a general rule, personal data obtained through the contact form on our website are stored for a period of 5 years.</p>



<p>Depending on the specific situation and the applicable national legislation, we may however retain your personal data for a longer period. This will in particular be the case if any of the following periods is longer : (i) as long as is necessary for our daily business; (ii) any retention period that is required by law; or (iii) the end of the period in which litigation or investigations might arise.</p>



<h3>I AM (A CONTACT PERSON OF) A SUPPLIER, A CUSTOMER OR A PROSPECT OR ANY OTHER INDIVIDUAL WHOSE PERSONAL DATA ARE PROCESSED (E.G. A VISITOR, A BUSINESS CONTACT ETC.)</h3>



<p>Your personal data will not be retained longer than necessary for the purposes described above.</p>



<p>As a general rule, records in the framework of our business activities that may contain personal data (e.g. contracts, orders, correspondance etc.) are stored for a period of 10 years.</p>



<p>Personal data processed for direct marketing purposes, are however stored for a period of 3 years as from the last contact with the individual concerned. If the company you are working for has become a client during this period, we may however retain your personal data for a longer period, namely 10 years as from the delivery of the products or services, or as from the latest contact with a BMT Company (if this contact would take place at a later date).</p>



<p>Depending on the specific situation and the applicable national legislation, we may however retain your personal data for a longer period. This will in particular be the case if any of the following periods is longer : (i) as long as is necessary for our daily business; (ii) any retention period that is required by law; or (iii) the end of the period in which litigation or investigations might arise.</p>



<h1>Automated Decision-making</h1>



<p>Automated decisions are defined as decisions about individuals that are based solely on the automated processing of data and that produce legal effects that significantly affect the individuals involved.</p>



<p>As a rule, your personal data will not be used for automated decision-making. We do not base any decisions about you solely on automated processing of your personal data.</p>



<h1>How to contact us?</h1>



<p>We hope that this Privacy Statement helps you understand, and feel more confident about, the way we process your data. If you have any further queries about this Privacy Statement and the Sites in general, please contact us by e-mailing at&nbsp;<a href="mailto:gdpr@bmt.lu">gdpr@bmt.lu</a></p>



<h1>Changes to this Privacy Statement</h1>



<p>We may modify or amend this Privacy Statement from time to time. Any changes we may make to this Privacy Statement in the future will be posted on this page. To let you know when we make changes to this Privacy Statement, we will amend the revision date at the top of this page. The new modified or amended Privacy Statement will apply from that revision date. Please check back periodically to see changes and additions.</p>

</div>
<Footer color="dark"/>
</>

)}

export default Page;