import React, { useEffect, useRef, useState} from 'react';
import styles from './css/bmt-omco.module.css'
import arrows from "./images/down-arrows-blue.png"
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import bc from './css/breadcrumb.module.css'
import { useMediaQuery } from 'react-responsive';


const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      description: '', logo: '', logoAlt: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/division.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {division_name, division_logo, division_description} = x.acf
		if(division_name === "BMT Omco"){
			setState({
			description: division_description,
			logo: `/images/${division_logo.url.split('wp-content/')[1]}`,
			logoAlt: division_logo.alt
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleKeyUp(e){
	e.preventDefault();
	if(e.keyCode === 38){ //Up arrow
    	history.push('/');
	}
	else if(e.keyCode === 40){ //down arrow
		history.push('/bmt-omco-explanation');
	}
}

function handleDown(e){
	e.preventDefault();
    history.push('/bmt-omco-explanation');
}

function handleUp(e){
	e.preventDefault();
    history.push('/');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

return(
<>
<div className={styles.fullpage} style={{overflow: "none"}} ref={mainRef} onKeyDown={handleKeyUp} tabIndex="0">
{loading &&
<>
<Header/>

{isDesktop &&
<div className={`${bc.vertical}`}> 
<a href="/bmt-omco-references">References / </a>
<a href="/bmt-omco-departaments">Departments / </a>
<a href="/bmt-omco-products">Products / </a>
<a href="/bmt-omco-explanation">Explanation / </a>
<span className={bc.destination}>OMCO</span>
</div>
}

<div className="container-fluid" style={{paddingTop: "30px"}}>
	<div className="row align-items-center min-vh-100">
		<div className="w-100 d-flex justify-content-center">
			<a href='/' onClick={(e) => handleUp(e)}>
				<img src={arrows} className={styles.upArrowsMob} style={{transform: "rotate(180deg)"}} alt="bmt aerospace arrows"/>
			</a>
		</div>

		<div className="col-md-12">
			<div className={`d-flex flex-column justify-content-center align-items-center ${styles.container}`}>
				{
					state.logo ? 
					<>
					<div>
						<img src={state.logo} className={styles.logo} alt="bmt OMCO"/>
					</div>
					<h2 className={styles.subtitle} style={{marginTop: "20px"}}>
						{state.description}
					</h2>
					</>
					: null
				}
			</div>
		</div>

		<div className="w-100 d-flex justify-content-center">
			<a href="/bmt-omco-explanation" onClick={(e) => handleDown(e)}>
				<img src={arrows} className={styles.downArrowsMob} alt="bmt OMCO arrows"/>
			</a>
		</div>
	</div>

	

</div>
</>
}
</div>
</>

)}

export default Page;