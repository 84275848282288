import React, { useEffect, useRef, useState } from 'react';
import styles from './css/bmt-drive-solutions.module.css'
import arrows from "./images/down-arrows-bordeau.png"
import arrowsMobile from './images/down-arrows-white.png'
import { useTransitionHistory } from 'react-route-transition';
import gsap from 'gsap'
import Header from './components/header'
import getData from './utils/fetchdata'
import { useMediaQuery } from 'react-responsive';
import { useSwipeable } from "react-swipeable";
import bc from './css/breadcrumb.module.css'

const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const isDesktop = useMediaQuery({ query: '(min-width: 991px)' });

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      description: '', logo: '', logoAlt: ''
    }
)

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/division.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {division_name, division_logo, division_description} = x.acf
		if(division_name === "Vcst"){
			setState({
			description: division_description,
			logo: `/images/${division_logo.url.split('wp-content/')[1]}`,
			logoAlt: division_logo.alt
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleDown(e) {
    e.preventDefault();
    history.push('/vest-more');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/');
}

function handleKeyDown(e){
	if(e.keyCode === 40){ //Down arrow
		e.preventDefault();
    	history.push('/vest-more');
	}
	else if(e.keyCode === 38){ //Up arrow
		e.preventDefault();
    	history.push('/');
	}
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
    var tl = gsap.timeline();
    tl
       .from(`.${styles.backgroundImg}`, {
        duration: 0.6,
        transformOrigin: "center bottom",
        scaleY: 0.7,
        scaleX: 0.2,
        opacity: 0,
        ease: "ease-in-out"
    }, 0)
	.from(`.${styles.logo}`, {
		duration: 0.6,
		transformOrigin: "center",
        scale: 0.1,
        opacity: 0
	}, 0)

}, [loading]);

const handlers = useSwipeable({
    //onSwipedUp: () => handleUp(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

return(
<>
<div {...handlers}>

<div style={{minHeight: "100vh", overflow: "none"}} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">
{ loading && state.logo &&
<>

<Header />

{isDesktop &&
<div className={`${bc.vertical}`}> 
<a href="/vest-references">References / </a>
<a href="/vest-departments">Departments / </a>
<a href="/vest-more">Automotive / </a>
<span className={bc.destination}>VCST</span>
</div>
}

<section className={styles.fullpageVCST}>
	<div className={styles.backgroundImgVCST}>
		<div className="container-fluid" style={{paddingTop: "56px"}}>
			<div className={`row align-items-center ${styles.minHeight}`}>
				<div className="d-flex w-100 justify-content-center align-items-center mt-3">
					<a href='/' onClick={(e) => handleUp(e)}>
						<img src={isDesktop ? arrows : arrowsMobile} className={styles.upArrowsMob} style={{transform: "rotate(180deg)", height: isDesktop ? "33px" : "28px"}} alt="bmt aerospace arrows"/>
					</a>
				</div>
				<div className="d-flex w-100 justify-content-center">
				{	!isDesktop &&
					<img className={`${styles.logoAbsMob} img-fluid`} src={state.logo} alt={state.logo.alt && state.logo.alt}/>
				}
				</div>
				<div className="col-md-12">
					<div className={`d-flex flex-column justify-content-center align-items-center ${styles.container}`}>
						{
						state.logo &&
						<>
						<img className={`${styles.logo} img-fluid`} src={state.logo} alt={state.alt} />
						{/*<img className={`${styles.logo} img-fluid`} src={vest} />*/}
						<h2 className={styles.subtitle} style={{marginTop: "20px"}}>
							{state.description}
						</h2>
						</>
						}
					</div>
				</div>
				<div className="d-flex w-100 justify-content-center mt-3 mb-3">
					<a href="/vest-more" onClick={(e) => handleDown(e)}>
						<img className={styles.downArrowsMob} src={isDesktop ? arrows : arrowsMobile} alt="down arrows"/>
					</a>
				</div>
			</div>
		</div>
		
	</div>	
</section>

</>
}
</div>
</div>
</>

)}

export default Page;