import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import refs from './css/bmt-drive-solutions-references.module.css'
import arrows from './images/upArrowsRed.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import Footer from './components/footer'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'

const Page = () => {
const mainRef = useRef(null);
const history = useTransitionHistory();

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      main_logo: '', main_logoAlt: '', firstColumn:'', secondColumn:'', thirdColumn:''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/references.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {division_logo, first_column, second_column, third_column} = x.acf
		if(x.title.rendered === "BMT Drive Solutions References"){
			setState({
				main_logo: division_logo && division_logo.url && `/images/${division_logo.url.split('wp-content/')[1]}`,
				main_logoAlt: division_logo.alt,
				firstColumn: first_column,
				secondColumn: second_column,
				thirdColumn: third_column
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);

function handleBack(e) {
    e.preventDefault();
    history.push('/igw-departments');
}

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/igw-departments');
	}
}

function chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index+chunk_size);
        tempArray.push(myChunk);
    }
    return tempArray;
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);    

useEffect(() => {
var tl = gsap.timeline();	
    tl
    .from(".animateme", {
    	duration: 1,
    	x: "random(-300, 300)",
    	y: "random(-300, 300)",
    	rotate: "10deg",
    	ease: "power2"
    }, 0)
	  .from(`.${styles.title}`, {
	    	duration: 0.5,
	    	y: "30vh",
	    	ease: "power2"
	    }, 0)
	  .from(`.${refs.colLogo}`, {
	    	duration: 0.7,
	    	y: "40vh",
	    	ease: "power2"
	    }, 0)
}, [loading])

return(
<>
<Header/>

{isDesktop &&
<div className={`${bc.vertical}`}> 
<span className={bc.destination}>References / </span>
<a href="/igw-departments">Departments / </a>
<a href="/igw-more">More / </a>
<a href="/igw">IGW Rail </a>
</div>
}

<div className={`container-fluid ${styles.referencesBackground} ${styles.redBorder}`} style={{overflow: "hidden", padding: "0px"}} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">
{ loading &&
<>
	<a href='/igw-departments' onClick={(e) => handleBack(e)}>
		<img src={arrows} className={styles.upArrows} style={{transform: "rotate(0deg) translateX(-50%)", height: isDesktop ? "35px" : "28px"}} alt="bmt aerospace arrows"/>
	</a>

	<div className="row" style={{marginTop: "7.5rem", paddingBottom: "5vh", marginLeft:"auto", marginRight: "auto"}}>
		<div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
			<h1 className={`${refs.title}`}>
				References
			</h1>
		</div>
	</div>

{ isDesktop &&
	<div className={`row d-flex align-items-start justify-content-center ${styles.secondRow}`}>			
		<div className={`col-md-4 d-flex flex-column justify-content-center ${styles.logosBranch}`}>
			{
				state.thirdColumn && state.thirdColumn.logo ?
				<div className="row d-flex justify-content-center mb-4">
					<img src={`/images/${state.thirdColumn.logo.url.split('wp-content/')[1]}`} alt={state.thirdColumn.logo.alt} className={`${refs.colLogo}`}/>
				</div> : null
			}
			{ state.thirdColumn &&
				chunkArray(state.thirdColumn.logos, 5).map((x, i) => (
					<div key={i} className="row mb-2">
						<div className={`col-md-12 d-flex justify-content-center ${styles.logosBranchCol}`}>
							{x.map((y, ii) => 
								<img key={ii} className={`animateme ${refs.logoBox} ${styles.boxedLogo}`} src={`/images/${y.url.split('wp-content/')[1]}`} alt={y.alt}/>
								)
							}
						</div>
					</div>	
				))
			}
		</div>		
	</div>
}

{!isDesktop &&
	<div className="mb-4" style={{marginLeft: "10%", marginRight:"10%"}}>
  		{
			state.thirdColumn && state.thirdColumn.logo ?
			<div className="row d-flex justify-content-center mb-4">
				<img src={`/images/${state.thirdColumn.logo.url.split('wp-content/')[1]}`} alt={state.thirdColumn.logo.alt} className={`${refs.colLogo}`}/>
			</div> : null
		}

  		{ state.thirdColumn &&
			chunkArray(state.thirdColumn.logos, 4).map((x, i) => (
				<div key={i} className="row d-flex flex-row justify-content-center align-items-center mt-3">	
					<div className="row align-items-center mt-3">
						{x.map((y, ii) => 
							<div key={ii} className={`col ${styles.brandCol}`} >
								<img src={`/images/${y.url.split('wp-content/')[1]}`} alt={y.alt} className={`${refs.logoBox} img-fluid`} style={{maxWidth: '64px', maxHeight: '64px'}}/>
							</div>	
						)}
					</div>		
				</div>
			))
		}

	</div>
}	

	{ isDesktop &&
	<div className="row" style={{position: "relative", marginTop: "100px"}}>
		{ state.main_logo ?
			<img src={state.main_logo} className={refs.refBottomLogo} alt={state.main_logoAlt}/> : null
		}
		<a href="/igw-departments" className={refs.refDownArrows} 
		 onClick={(e) => handleBack(e)}
		 style={{position:"absolute", bottom: "20px", marginBottom: "10px", left:"50%"}}
		 >
			<img src={arrows} alt="up arrows" style={{transform: "translateX(-50%)"}}/>		
		</a>
	</div>
	}

</>
}
</div>

<Footer color="white"/>
 	
</>

)}

export default Page;