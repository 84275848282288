import React, {useRef, useEffect, useState} from 'react';
import Footer from './components/footer'
import styles from './css/history.module.css';
import arrows from './images/upArrowsNavyBlue.png';
import { useTransitionHistory } from 'react-route-transition';
import getData from './utils/fetchdata'
import Header from './components/header'
import Timeline from './components/timeline/timeline'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'

const Page = () => 
{
const mainRef = useRef(null);
const history = useTransitionHistory();
const [loading, setLoading] = useState(false)
const [state, setState] = useState({})
const [years, setYears] = useState(null)
const [showSideNavigation, setShowSideNavigation] = useState(true)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/histories.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      setYears(data)
    }
  })

  const resp2 = await getData(`${process.env.PUBLIC_URL}/apidata/turnover.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      let arr = []
      let years = []
      data.forEach(x => 
        years.push(x.acf.year)
      )

      data.forEach(x => 
        arr.push({...x.acf, years: years})
      )
      return arr[arr.length - 1]
    }
  })
  setState({...resp2})
  setLoading(true)
}

fetchData();
}, []);

function handleKeyDown(e){
  if(e.keyCode === 38){ //up arrow
    e.preventDefault();
      history.push('/bmt-global-presence');
  }
}

useEffect(() => {
  mainRef.current.focus();
}, [mainRef]);

function handleUp(e) {
    e.preventDefault();
    history.push('/bmt-global-presence');
}


return(
<>
<Header/>

{ isDesktop && showSideNavigation &&
<div className={`${bc.vertical2}`}> 
  <span className={bc.destination}>History / </span>
  <a href="/bmt-global-presence">Global Presence / </a>
  <a href={`/${state.year}`}>Turnover / </a>
  <a href="/about-us-group-structure">Group Structure / </a>
  <a href="/about-us-vision">Vision </a>
</div>
}

<div className={`${styles.fullpage}`} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">
{loading &&
<>

  { isDesktop &&
  <a href={"/bmt-global-presence"} onClick={(e) => handleUp(e)}>
    <img src={arrows} className={styles.upArrows} style={{transform: "rotate(0deg) translateX(-50%)", height: "25px"}} alt="bmt aerospace arrows"/>
  </a>
  }

	<div className="container-fluid">
		<div className="row align-items-center" style={{paddingTop: "4rem"}}>
			<div className="col d-flex flex-column justify-content-center align-items-center">
				<div className={styles.titleContainer}>
					<h1 className={styles.title}>
						History
					</h1>
				</div>
			</div>	
		</div>	
	</div>	

{ isDesktop &&
<>
  <div className="d-flex flex-column" style={{marginTop: "70px"}}>
      <Timeline years={years} func={setShowSideNavigation}/>
  </div>

  <div className="d-flex flex-row mt-auto justify-content-center align-items-center mb-3" style={{paddingTop: "30px"}}>
    <a href="/bmt-global-presence" className={styles.downArrowsMobile} onClick={(e) => handleUp(e)}>
      <img src={arrows} alt="down arrows" style={{height: "25px"}}/>   
    </a>
  </div>
</>
}

  {
    !isDesktop &&
    <>
    <div className="d-flex flex-column" style={{marginTop: "70px"}}>
      <Timeline years={years}/>

      <div className="d-flex flex-row mt-auto justify-content-center align-items-center mb-3" style={{paddingTop: "30px"}}>
        <a href="/bmt-global-presence" className={styles.downArrowsMobile} onClick={(e) => handleUp(e)}>
          <img src={arrows} alt="down arrows"/>   
        </a>
      </div>
    </div>
    </>
  }

</>
}
</div>

<Footer />
</>
)
}

export default Page;