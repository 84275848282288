import React, { useEffect, useRef, useState }  from 'react';
import styles from './css/subpages.module.css'
import arrows from "./images/down-arrows.png"
import scroll from './images/scroll-right.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import $ from 'jquery';
import getData from './utils/fetchdata'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import GButton from './components/gpagebutton'
import Slider from './components/gpageslider'
//import { FaArrowUp, FaArrowRight } from 'react-icons/fa';
import { useSwipeable } from "react-swipeable";
import bc from './css/breadcrumb.module.css'
import arrowsMobile from './images/down-arrows.png'


const Page = () => {
const mainRef = useRef(null);
const history = useTransitionHistory();
var tl = gsap.timeline();
const [index, setIndex] = useState("1")

const [loading, setLoading] = useState(false)
const [state, setState] = useState(
    {
      titleF: '', contentF:'', logoF:'', logoFAlt: '', buttonF:'', sliderF: ''
    }
)

const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

const handlers = useSwipeable({
    onSwipedRight: () => handleLeft(),
    onSwipedLeft: () => handleRight(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
});

useEffect(() => {
const fetchData = async () => {
  await getData(`${process.env.PUBLIC_URL}/apidata/generic.json`)
  .then(data => {
    if(Array.isArray(data) && data.length)
    {
      data.forEach(x => 
      {
      	const {title, content, button, slider, logo} = x.acf
		if(x.title.rendered === "BMT Aerospace Products"){
			setState({
			titleF: title,
			contentF: content,
			logoF: `/images/${logo.url.split('wp-content/')[1]}`,
			buttonF: button,
			sliderF: slider,
			logoFAlt: logo.alt
			})
		}
      })
      setLoading(true)
    }
  })
}
fetchData();
}, []);


useEffect(() => {
	  tl.from(`.${styles.rightBlockContainerNoBorder}`, {
	        duration: 0.5,
	        x: "-100vw",
	        opacity: 0,
	    }, 0).from(`.${styles.scroll}`, {
	        duration: 0.5,
	        x: "-100vw",
	        opacity: 0,
	    }, 0).from(`.${styles.title}`, {
	    	duration: 0.5,
	    	rotate: "-90deg",
	    	xPercent: -40,
	    	yPercent: 80,
	    	opacity: 0,
	    }, 0)
	.fromTo(`.${styles.fullpageAerospaceProducts}`, 
	  {scale: 1.2}, 
	  {duration: 0.5, transformOrigin: "left", scale: 1, ease: "expoScale(1.2, 1)"
	}, 0);
}, [loading])


 function handleNext(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-markets');
  }

 function handleDown(e) {
    e.preventDefault();
    history.push('/bmt-aerospace-departaments');
  }  

function handleUp(e) {
	e.preventDefault();
	history.push('/bmt-aerospace-explanation');
} 

function handleKeyDown(e){
	if(e.keyCode === 39){
		e.preventDefault(); //right arrow
    	history.push('/bmt-aerospace-markets');
	}
	else if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/bmt-aerospace-explanation');
	}
  else if(e.keyCode === 40){
    e.preventDefault(); //down arrow
      history.push('/bmt-aerospace-departaments');
  }
}

function handleLeft(e){
	if(e)
		e.preventDefault()
	history.push('/bmt-aerospace-explanation');
}

function handleRight(e){
	if(e)
		e.preventDefault()
	history.push('/bmt-aerospace-markets');
}

useEffect(() => {
        mainRef.current.focus();
    }, [mainRef]);

useEffect(() => {
  $('#carouselIndicators').on('slid.bs.carousel', function (e) {
    let index = document.querySelector("#carouselIndicators > div > div.carousel-item.active").getAttribute('index');
    setIndex(index)
  });   
}, [loading])

function createMarkup(content) {
    return {
       __html: content    
    };
 };

return(
<>
<div {...handlers}>
<Header/>

{isDesktop &&
<div className={`${bc.vertical}`}> 
<a href="/bmt-aerospace-references">References / </a>
<a href="/bmt-aerospace-departaments">Departments / </a>
<span className={bc.destination}>Products / </span>
<a href="/bmt-aerospace-explanation">Explanation / </a>
<a href="/bmt-aerospace">BMT Aerospace </a>
</div>
}

<div className={styles.fullpageAerospaceContainer} ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0">

{loading &&
<>

{isDesktop &&
<>	
<div style={{overflow: "hidden", maxWidth: "100%"}}>
  <section className={styles.fullpageAerospaceProducts}></section>
</div>
<a href='/bmt-aerospace-explanation' onClick={(e) => handleUp(e)}>
	<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
</a>
</>
}

<div className={`container-fluid d-flex flex-column ${styles.genericPageContainer} ${styles.cfm}`}>
	<div className={`row align-items-center ${styles.rowMobile}`}>
		<div className={`${isDesktop ? 'col' : 'w-100 align-items-center'} d-flex flex-column ${styles.leftColumn2}`}>
		      { !isDesktop &&
		      <div className="d-flex flex-column justify-content-center align-items-center w-100" style={{marginTop: "70px"}}>
		          <div className="d-flex flex-row w-100 align-items-center">
		          	<div className="mr-auto">
			          	<a href="/#" onClick={(e) => handleLeft(e)}>
			          		<img src={arrowsMobile} style={{maxWidth: "25px", transform: "rotate(180deg)"}} alt="down arrows"/>
			          	</a>
		          	</div>
	          
		          	<div className="mr-4 pb-2 d-flex flex-row align-items-end">
	          			<div className="mr-2">
		          			<h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px", marginBottom: "0px"}}>
			            	Markets
			          		</h1>
		          		</div>
		          		<div>
			          		<a href="/#" onClick={(e) => handleRight(e)}>
				          		<img src={arrowsMobile} style={{maxWidth: "25px", transform: "rotate(-90deg) translateX(20%)"}} alt="down arrows"/>
				          	</a>
			          	</div>
		          	</div>
		          
		          </div>

		          <div className="d-flex flex-row w-100 align-items-center justify-content-start">
		          	<div className="ml"><h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`} style={{fontSize: "1.5rem", lineHeight: "40px"}}>
			            {state.titleF}
			          </h1></div>
		          </div>
		      </div>
		      }

		      {
		        isDesktop &&
		          <h1 className={`${styles.title} ${!isDesktop ? 'mt-3' : ''}`}>
		            {state.titleF}
		          </h1>
		      }
			<div className={`${styles.description} mt-4`} style={{maxWidth: isDesktop ? "95%" : "100%", /*wordWrap: "break-word"*/}}>
				<div dangerouslySetInnerHTML={createMarkup(state.contentF)} />
			</div>

			{ isDesktop && <GButton state={state} color="purple"/>}

	      { !isDesktop &&
	        <div className="d-flex flex-column-reverse align-items-center">  
	    			<GButton state={state} color="purple"/>
	          <Slider state={state} index={index} isDesktop={isDesktop} color="purple" stop={true}/>
	        </div>
	    	}
		</div>

		{
			isDesktop && <Slider state={state} index={index} isDesktop={isDesktop} color="purple"/>
		}
		
		<div className={styles.scrollNoBorder}>
			<img src={scroll} alt="scroll"/>
		</div>
		<a href="/bmt-aerospace-products" className={styles.rightBlockContainerNoBorder} onClick={handleNext}>
			<div className={`col-md-12 d-flex flex-row align-items-center justify-content-center ${styles.rightBlock}`}>
				<div className="">
					<div className={styles.rightBlockContent}>Markets</div>
				</div>
			</div>
		</a>

	</div>

	{ isDesktop && 
	<>
		{ state.logoF ?  
			<img src={state.logoF} className={styles.logo} alt={state.logoFAlt}/> : null
		}
		<a href="/bmt-aerospace-departaments" className={styles.downArrows} onClick={(e) => handleDown(e)}>
			<img src={arrows} alt="down arrows"/>		
		</a>
	</>
	}

	{ !isDesktop &&
    <div className={`d-flex flex-row mt-auto ${styles.genericPageBottomContainer} justify-content-center align-items-center`} style={{paddingTop: "60px"}}>
      <div className="col" style={{padding: "0px"}}>
        <img src={state.logoF} alt="bmt logo" className={styles.genericPageLogo} style={{maxWidth: "35%"}}/>
      </div>
      <div className="col" style={{padding: "0px", position: "relative"}}>
        <a href="/bmt-aerospace-departaments" className={`${styles.downArrowsMobile} ${styles.genericPageArrows}`} 
         onClick={(e) => handleDown(e)}>
          <img src={arrows} alt="down arrows"/>   
        </a>
      </div>
    </div>
  	}
	
</div>
</>
}
</div>
</div>
</>

)}

export default Page;