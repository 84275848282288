import React, { useState, useEffect, useRef }  from 'react'
import styles from './css/subpages.module.css'
import './css/svg-styling.css'
import map from './css/maps.module.css'
import logo from './images/vcst2.png'
import arrows from './images/down-arrows-bordeau.png'
import gsap from 'gsap'
import { useTransitionHistory } from 'react-route-transition';
import Globe from './drivemap'
import Header from './components/header'
import { useMediaQuery } from 'react-responsive';
import bc from './css/breadcrumb.module.css'
import getData from './utils/fetchdata'

//const entries = [
	/*{color: "red", country: "Belgium - Oostkamp", company: "IG Watteeuw International nv", street: "Kampveldstraat 51", city: "8020 Oostkamp", phones: ["T +32 50 82 69 07"], email: "info@bmtdrivesolutions.com"},*/
//	{color: "bordeau", country: "Belgium - Sint-Truiden", company: "VCST Industrial Products BV", street: "Rellestraat 5030", city: "3800 Sint-Truiden", phones: ["T + 32 (0) 11 67 02 11"], email: "sales@vcst.com"},
//	{color: "bordeau", country: "Belgium - Sint-Truiden", company: "Surface Treatment Company BV", street: "Nijverheidslaan 5420", city: "3800 Sint-Truiden", phones: ["T + 32 (0) 11 67 02 96"], email: "sales@vcst.com"},
//	{color: "bordeau", country: "China - Changzhou", company: "VCST Automotive Components (Changzhou) Co, Ltd.", street: "8 Fushan Road, Wujin Economic Zone", city: "Changzhou, 213149", phones: ["T +86 519 8169 0996"], email: "vcst.china@vcst.com"},
	/*{color: "red", country: "China - Suzhou", company: "IGW (Suzhou) Co., Ltd", street: "N° 1 Fenghe Road Fengting Avenue, SIP", city: "Suzhou 215122", phones: ["T +86 512 6287 5601"], email: "info@bmtdrivesolutions.com"},*/
	/*{color: "red", country: "Czech Republic - Brno", company: "IG Watteeuw ČR s.r.o.", street: "Vídeňská 130", city: "61900 Brno", phones: ["T +420 547 139 513"], email: "info@bmtdrivesolutions.com"},*/
//	{color: "bordeau", country: "Germany - Reichenbach", company: "VCST Reichenbach GmbH", street: "Am Fernblick 15", city: "08499 Mylau", phones: ["T + 49 (0) 37 65 795 – 0"], email: "sales@vcst.com"},
	/*{color: "red", country: "India - Pune", company: "Involute Technologies Pvt. Ltd.", street: "Regd. off. Gat No. 232 & 156-159 Alandi – Market Road Dhanore", city: "Tal. Khed Dist. Pune 412 105", phones: ["T +91 982 255 9845"], email: "info@bmtdrivesolutions.com"},*/
//	{color: "bordeau", country: "Mexico - Leon", company: "VCST De Mexico S De R.L De C.V.", street: "Boulevard Aeropuerto No. 3202 Loc. A", city: "San Juan de Otates, C.P. 37670, León, Gto.", phones: ["T +52 477 152 6100"], email: "info@vcst.com.mx"},
//	{color: "bordeau", country: "Romania - Alba", company: "SC VCST Automotive Production Alba SRL", street: "No. 9, Calea Ciugudului Street", city: "517240 Alba County, Alba Iulia", phones: ["T +40 372 133 700"], email: "officealba@vcst.be"},
	/*{color: "red", country: "Romania - Iasi", company: "IG Watteeuw Romania S.R.L.", street: "B-dul Chimiei nr. 6 Area B", city: "700291 Iasi", phones: ["T +40 374 100 235"], email: "info@bmtdrivesolutions.com"},*/
//	{color: "bordeau", country: "USA - Zanesville", company: "IG Watteeuw USA LLC", street: "1000 Linden Avenue, Zanesville", city: "Ohio 43701 – 3098", phones: ["T +1 740 588 1722"], email: "info@bmtdrivesolutions.com"}
//]

const Page=() =>
{

const mainRef = useRef(null);
const history = useTransitionHistory();

var random = gsap.utils.random(-500, 500, true);
const [entries, setEntries] = useState([])

//var t_x, t_y, d_x, d_y;

const firstRef = useRef(null);
const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

function handleKeyDown(e){
	if(e.keyCode === 38){
		e.preventDefault(); //up arrow
    	history.push('/vest-more');
	}
	else if(e.keyCode === 40){
		e.preventDefault(); //down arrow
    	history.push('/vest-references');
	}
}

function handleDown(e) {
    e.preventDefault();
    history.push('/vest-references');
}

function handleUp(e) {
    e.preventDefault();
    history.push('/vest-more');
}

useEffect(() => {
    mainRef.current.focus();
}, [mainRef]);

useEffect(() => {
gsap.timeline().from("#comp1", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp11", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp2", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp3", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp33", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp4", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp5", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp55", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp6", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp7", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
		  .from("#comp77", {
		        duration:1.5,
		        stagger: 0.1,
		        x: random(),
		        y: random(),
		        //ease: "bounce.out"
		        ease: "power4.out"
		    }, 0)
}, [])

useEffect(() => {
const fetchData = async () => {
  const resp1 = await getData(`${process.env.PUBLIC_URL}/apidata/maps.json`)
  .then(data => {
    
    if(Array.isArray(data) && data.length)
    {
      let obj = {}
      data.forEach(x => 
      {
        const {title, department} = x.acf
        if(title === "VCST"){
          obj.title = title
          obj.department = department
        }
      })
      return obj
    }
  })

  //setState({...resp1})
  const { country, company_name, address, postcode, phones, email} = resp1.department
  let arr = []
  if(resp1 && resp1.department.length > 1)
  {
  	resp1.department.forEach((el) =>
  		arr.push({color: "bordeau", country: el.country, company: el.company_name, street: el.address, city: el.postcode, phones: [...el.phones.map(el => el.phone)], email: el.email})
  	)
  }
  setEntries(arr)
}

fetchData();
}, []);

return(
<>
	<div ref={mainRef} onKeyDown={handleKeyDown} tabIndex="0" className={styles.globalMapContainerVCST} style={{borderStyle: isDesktop ? '' : 'none', position: isDesktop ? "fixed" : "relative", zIndex: isDesktop ? "-100" : "1"}}>	
		{ isDesktop &&
		<>
		<Header/>

		<div className={`${bc.vertical}`}> 
		  <a href="/vest-references"> References / </a>
		  <span className={bc.destination}> Departments / </span>
		  <a href="/vest-more"> Automotive / </a>
		  <a href="/vest"> VCST</a>
		</div>

		<a href='/vest-more' onClick={(e) => handleUp(e)}>
			<img src={arrows} className={styles.upArrows} style={{transform: "rotate(180deg) translateX(50%)"}} alt="bmt aerospace arrows"/>
		</a>

		<div className="d-flex flex-row justify-content-center" style={{zIndex: "-100", marginTop: "7.5rem"}}>
			<h1 className={styles.title}>Global Presence</h1>	
		</div>
		<div className="d-flex flex-row justify-content-center">	
			<Globe style={{marginTop: "50px"}} svgref={firstRef}/>
		</div>
		<div>
			<img src={logo} className={styles.refBottomLogo} alt="bmt drive solutions logo" style={{zIndex: "-1"}}/>
		</div>
		<a href="/vest-references" className={styles.refDownArrows} onClick={(e) => handleDown(e)}>
			<img src={arrows} alt="up arrows"/>		
		</a>
		</>
		}

		{
			!isDesktop &&
			<>
			<Header/>

			<div className="d-flex justify-content-center" style={{marginTop: "80px"}}>
				<a href='/vest-more' onClick={(e) => handleUp(e)}>
					<img src={arrows} style={{transform: "rotate(180deg)", height: "28px"}} alt="bmt aerospace arrows"/>
				</a>
			</div>

			<div className={`d-flex flex-row mb-2 ${map.titleMobile}`} style={{color: "white", textAlign: "center", justifyContent: "center"}}>
				Global Presence
			</div>

			<div className="col-md-10 mx-auto d-flex flex-column text-center justify-content-center align-items-center">
	          {
	          	entries && entries.map((x, i) => (
	          		<div key={i} className={`${map.mapTooltip} ${x.color === "bordeau" ? map.bordeau : null}`}>
	          			<div className={map.mapTooltipText}>{x.country}</div>
	          			<div className={map.mapTooltipTitle}>{x.company}</div>
	          			<div className={map.mapTooltipText}>{x.street}</div>
	          			<div className={map.mapTooltipText}>{x.city}</div>
	          			{
	          				x.phones.map((y, ii) => 
	          					<div key={ii} className={map.mapTooltipText}>{y}</div>
	          				)
	          			}
	          			<div className={map.mapTooltipText}>{x.email}</div>
	          		</div>
	          	))
	          }
	        </div>

			<a href="/vest-references" className="col-md-12 d-flex align-items-center justify-content-center pt-2 pb-2" onClick={handleDown}>
				<img src={arrows} alt="up arrows" style={{maxHeight: "27px"}}/>		
			</a>
			</>
		}
	</div>
</>
)}

export default Page;